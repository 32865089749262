import React from 'react';
import {
  ManageAccounts,
  Computer,
  Business,
  Gavel,
  Campaign,
  Subscriptions,
  Construction,
  Build,
  DirectionsCar,
  Group,
  BusinessCenter,
} from '@mui/icons-material';
import { IMC_EXPENSE_CODES } from './imcCodes';

interface AdminCategory {
  title: string;
  codes: readonly string[];  // Changed to readonly string[]
  icon: JSX.Element;
  description: string;
}

export const ADMIN_CATEGORIES: Record<string, AdminCategory> = {
  SALARIES: {
    title: 'Salaries & Benefits',
    codes: ['301', '302', '303', '304', '305', '306', '307', '310'],
    icon: React.createElement(ManageAccounts),
    description: 'Compensation and benefits for staff and executives.',
  },
  IT: {
    title: 'IT & Software',
    codes: Object.values(IMC_EXPENSE_CODES)
            .filter(code => (code.subCategories as unknown as string[]).includes('it'))
            .map(code => code.code),
    icon: React.createElement(Computer),
    description: 'Software subscriptions, cloud services, and IT-related expenses.',
  },
  OFFICE: {
    title: 'Office & Building',
    codes: Object.values(IMC_EXPENSE_CODES)
              .filter((code) =>
                Array.isArray(code.subCategories) &&
                ['office', 'building'].some((subCat) =>
                  (code.subCategories as unknown as string[]).includes(subCat)
                )
              )
              .map((code) => code.code),
    icon: React.createElement(Business),
    description: 'Expenses related to office supplies, building maintenance, and utilities.',
  },
  PROFESSIONAL_FEES: {
    title: 'Professional Services',
    codes: ['357', '360', '362', '363', '364'],
    icon: React.createElement(Gavel),
    description: 'Accounting, legal, consulting, and professional fees.',
  },
  MARKETING: {
    title: 'Marketing & Advertising',
    codes: ['318', '342-1', '342-2'],
    icon: React.createElement(Campaign),
    description: 'General marketing expenses, web design, and digital media.',
  },
  SUBSCRIPTIONS: {
    title: 'Subscriptions & Media',
    codes: ['361', '361-2', '361-3', '361-4', '361-5'],
    icon: React.createElement(Subscriptions),
    description: 'Subscription-based services and media platforms.',
  },
  EQUIPMENT: {
    title: 'Equipment & Leasing',
    codes: ['345-2', '345-3', '346', '347'],
    icon: React.createElement(Build),
    description: 'Leasing, maintenance, and disposal of equipment.',
  },
  TRANSPORTATION: {
    title: 'Transportation & Vehicles',
    codes: ['320', '326', '345-1'],
    icon: React.createElement(DirectionsCar),
    description: 'Vehicle leasing, fuel, and other transportation costs.',
  },
  STAFF_EXPENSES: {
    title: 'Staff Expenses',
    codes: ['315', '316', '317', '323', '324', '325', '327', '328'],
    icon: React.createElement(Group),
    description: 'Training, recruitment, and travel-related staff expenses.',
  },
  COMPANY_EXPENSES: {
    title: 'Company & General Expenses',
    codes: ['319', '330', '349', '350', '366', '374'],
    icon: React.createElement(BusinessCenter),
    description: 'General company expenses, gifts, and miscellaneous costs.',
  },
} as const;

export type AdminCategories = typeof ADMIN_CATEGORIES;