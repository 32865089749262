// src/components/budgets/sections/VenuePlanner.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Alert,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';
import axiosInstance from '../../../utils/axiosConfig';
import { VenueDetails, BudgetItem } from '../../../types/Budget';
import { Venue } from '../../../types/mongodb';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { updateVenueDetails, setVenues } from '../../../store/budgetSlice';

interface TechnicalCosts {
  pa: number;
  lighting: number;
  backline: number;
}

interface ExtendedBudgetItem extends BudgetItem {
  pa?: number;
  lighting?: number;
  backline?: number;
}

interface ExtendedVenueDetails extends Omit<VenueDetails, 'costs'> {
  costs: {
    venueHire: BudgetItem;
    technical: ExtendedBudgetItem;
    staffing: BudgetItem;
  };
}

const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : Number(parsed.toFixed(2));
  }
  return isFinite(value) ? Number(value.toFixed(2)) : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const DEFAULT_TECHNICAL_COSTS: TechnicalCosts = {
  pa: 0,
  lighting: 0,
  backline: 0
};

const VenuePlanner: React.FC = () => {
  const dispatch = useDispatch();
  const venueDetails = useSelector((state: RootState) => state.budget.venue) as ExtendedVenueDetails | null;
  
  const [venues, setVenues] = useState<Venue[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [useExistingVenue, setUseExistingVenue] = useState<boolean>(!!venueDetails?.venueId);

  const [formData, setFormData] = useState<ExtendedVenueDetails>(() => ({
    venueId: venueDetails?.venueId || '',
    manualCapacity: venueDetails?.manualCapacity || 0,  // Add this line
    setupRequirements: {
      needsPA: venueDetails?.setupRequirements?.needsPA || false,
      needsLighting: venueDetails?.setupRequirements?.needsLighting || false,
      needsBackline: venueDetails?.setupRequirements?.needsBackline || false
    },
    costs: {
      venueHire: {
        code: IMC_EXPENSE_CODES.VENUE_HIRE.code,
        label: IMC_EXPENSE_CODES.VENUE_HIRE.label,
        amount: safeNumber(venueDetails?.costs?.venueHire?.amount),
        isEstimate: true,
        status: 'draft',
        category: 'expense'
      },
      technical: {
        code: IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_HIRE.code,
        label: IMC_EXPENSE_CODES.PRODUCTION_EQUIPMENT_HIRE.label,
        amount: safeNumber(venueDetails?.costs?.technical?.amount),
        isEstimate: true,
        status: 'draft',
        category: 'expense',
        pa: safeNumber(venueDetails?.costs?.technical?.pa),
        lighting: safeNumber(venueDetails?.costs?.technical?.lighting),
        backline: safeNumber(venueDetails?.costs?.technical?.backline)
      },
      staffing: {
        code: IMC_EXPENSE_CODES.PRODUCTION_FRONT_OF_HOUSE_CREW.code,
        label: IMC_EXPENSE_CODES.PRODUCTION_FRONT_OF_HOUSE_CREW.label,
        amount: safeNumber(venueDetails?.costs?.staffing?.amount),
        isEstimate: true,
        status: 'draft',
        category: 'expense'
      }
    }
  }));

  const [technicalCosts, setTechnicalCosts] = useState<TechnicalCosts>(() => ({
    pa: safeNumber(venueDetails?.costs?.technical?.pa),
    lighting: safeNumber(venueDetails?.costs?.technical?.lighting),
    backline: safeNumber(venueDetails?.costs?.technical?.backline)
  }));

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await axiosInstance.get<Venue[]>('/api/venues');
        setVenues(response.data); // Local state for the component
        // Fix the dispatch by making it a proper action
        dispatch({ type: 'budget/setVenues', payload: response.data });
      } catch (err) {
        console.error('Error fetching venues:', err);
        setError('Failed to fetch venue data');
      }
    };

    fetchVenues();
}, [dispatch]);

  const debouncedDispatch = useCallback(
    debounce((updateData: ExtendedVenueDetails) => {
      dispatch(updateVenueDetails(updateData));
    }, 300),
    []
  );

  useEffect(() => {
    const totalTechnicalCost = safeNumber(
      technicalCosts.pa + technicalCosts.lighting + technicalCosts.backline
    );

    const updatedFormData: ExtendedVenueDetails = {
      ...formData,
      costs: {
        ...formData.costs,
        technical: {
          ...formData.costs.technical,
          amount: totalTechnicalCost,
          pa: technicalCosts.pa,
          lighting: technicalCosts.lighting,
          backline: technicalCosts.backline
        }
      }
    };

    if (JSON.stringify(venueDetails) !== JSON.stringify(updatedFormData)) {
      debouncedDispatch(updatedFormData);
    }
  }, [formData, technicalCosts, debouncedDispatch, venueDetails]);

  useEffect(() => {
    // This will run whenever any cost-related state changes
    const total = calculateTotalCost();
    
    // Force update of total display
    setFormData(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        technical: {
          ...prev.costs.technical,
          amount: safeNumber(
            technicalCosts.pa + 
            technicalCosts.lighting + 
            technicalCosts.backline
          )
        }
      }
    }));
  }, [technicalCosts, formData.costs.venueHire.amount, formData.costs.staffing.amount]);

  const handleCostChange = (type: keyof ExtendedVenueDetails['costs'], value: string) => {
    const amount = safeNumber(value);
    setFormData(prev => {
      const updated = {
        ...prev,
        costs: {
          ...prev.costs,
          [type]: {
            ...prev.costs[type],
            amount
          }
        }
      };
      
      // Immediate total calculation
      const newTotal = calculateTotalCost();
      
      return updated;
    });
  };
  
  const handleTechnicalCostChange = (type: keyof TechnicalCosts, value: string) => {
    const amount = safeNumber(value);
    setTechnicalCosts(prev => {
      const updated = {
        ...prev,
        [type]: amount
      };
      
      // Immediate total calculation
      const newTotal = calculateTotalCost();
      
      return updated;
    });
  };

  const handleVenueSelect = (event: SelectChangeEvent) => {
    const venueId = event.target.value;
    const selectedVenue = venues.find(v => v._id === venueId);
    if (selectedVenue) {
      setFormData(prev => ({
        ...prev,
        venueId: selectedVenue._id,
        costs: {
          ...prev.costs,
          venueHire: {
            ...prev.costs.venueHire,
            amount: safeNumber(selectedVenue.rentalCost)
          }
        }
      }));
    }
  };

  const handleRequirementChange = (requirement: keyof VenueDetails['setupRequirements']) => {
    setFormData(prev => ({
      ...prev,
      setupRequirements: {
        ...prev.setupRequirements,
        [requirement]: !prev.setupRequirements[requirement]
      }
    }));

    if (formData.setupRequirements[requirement]) {
      setTechnicalCosts(prev => ({
        ...prev,
        [requirement === 'needsPA' ? 'pa' : 
         requirement === 'needsLighting' ? 'lighting' : 
         'backline']: 0
      }));
    }
  };

  const calculateTotalCost = (): number => {
    const venueHireCost = safeNumber(formData.costs.venueHire.amount);
    const technicalTotal = safeNumber(
      technicalCosts.pa + 
      technicalCosts.lighting + 
      technicalCosts.backline
    );
    const staffingCost = safeNumber(formData.costs.staffing.amount);
  
    return safeNumber(venueHireCost + technicalTotal + staffingCost);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Venue & Technical Requirements
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useExistingVenue}
                onChange={(e) => setUseExistingVenue(e.target.checked)}
              />
            }
            label="Use existing venue from database?"
          />
        </Grid>

        {useExistingVenue && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Select Venue</InputLabel>
              <Select
                value={formData.venueId || ''}
                onChange={handleVenueSelect}
                label="Select Venue"
              >
                {venues.map((venue) => (
                  <MenuItem key={venue._id} value={venue._id}>
                    {venue.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Technical Requirements
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.setupRequirements.needsPA}
                    onChange={() => handleRequirementChange('needsPA')}
                  />
                }
                label="PA System Required"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.setupRequirements.needsLighting}
                    onChange={() => handleRequirementChange('needsLighting')}
                  />
                }
                label="Lighting Required"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.setupRequirements.needsBackline}
                    onChange={() => handleRequirementChange('needsBackline')}
                  />
                }
                label="Backline Required"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Cost Breakdown
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Venue Hire"
              type="number"
              value={formData.costs.venueHire.amount || ''}
              onChange={(e) => handleCostChange('venueHire', e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              inputProps={{ 
                min: 0,
                step: "0.01"
              }}
            />
          </Grid>

          {formData.setupRequirements.needsPA && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="PA System Cost"
                type="number"
                value={technicalCosts.pa || ''}
                onChange={(e) => handleTechnicalCostChange('pa', e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                inputProps={{ 
                  min: 0,
                  step: "0.01"
                }}
              />
            </Grid>
          )}

          {formData.setupRequirements.needsLighting && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Lighting Cost"
                type="number"
                value={technicalCosts.lighting || ''}
                onChange={(e) => handleTechnicalCostChange('lighting', e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                inputProps={{ 
                  min: 0,
                  step: "0.01"
                }}
              />
            </Grid>
          )}

          {formData.setupRequirements.needsBackline && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Backline Cost"
                type="number"
                value={technicalCosts.backline || ''}
                onChange={(e) => handleTechnicalCostChange('backline', e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                inputProps={{ 
                  min: 0,
                  step: "0.01"
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Staffing Cost"
              type="number"
              value={formData.costs.staffing.amount || ''}
              onChange={(e) => handleCostChange('staffing', e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              inputProps={{ 
                min: 0,
                step: "0.01"
              }}
            />
          </Grid>

          <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: 2 }}>
  Total Costs: {formatCurrency(calculateTotalCost())}
</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default VenuePlanner;