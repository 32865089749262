import React, { useState, useEffect } from 'react';
import { 
  DndContext, 
  DragEndEvent,
  DragStartEvent,
  useSensor,
  useSensors,
  PointerSensor,
  DragOverlay 
} from '@dnd-kit/core';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Paper,
  SelectChangeEvent,
  Button,
  Divider,
  Alert,
  Snackbar
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import { useQuery } from 'react-query';
import { useAuth } from '../contexts/AuthContext';
import AxiosInstance from '../utils/axiosConfig'; // Use the Axios instance
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { FormTemplate } from '../types/FormTemplate'; // Adjust the path as necessary
import FieldPalette from '../components/form/formbuilder/FieldPalette';
import FormCanvas from '../components/form/formbuilder/FormCanvas';
import FormPreviewDialog from '../components/form/shared/FormPreviewDialog';
import { FormField, FieldType, Strand, BaseField } from '../types/FormSchema';

interface FormMetadata {
  title: string;
  description: string;
  slug: string;
}

const FormBuilder: React.FC = () => {
  // DnD State
  const [fields, setFields] = useState<FormField[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [formType, setFormType] = useState<string>('navigator'); // Default form type
  const [openDialog, setOpenDialog] = useState(false);
  const [existingForm, setExistingForm] = useState<FormTemplate | null>(null); // Define FormTemplate type accordingly

  const [saveStatus, setSaveStatus] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info'; // Add 'info' to the type
  }>({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Form Metadata State
  const [strandId, setStrandId] = useState<string>('');
  const [selectedStrand, setSelectedStrand] = useState<Strand | null>(null);
  const [formMetadata, setFormMetadata] = useState<FormMetadata>({
    title: '',
    description: '',
    slug: ''
  });
  const [previewUrl, setPreviewUrl] = useState<string>('');
  
  const { getToken } = useAuth();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 8 },
    })
  );

  const handleReorderFields = (newFields: FormField[]) => {
    setFields(newFields);
  };

  // Example: Fetch strands
  const { data: strands, isLoading: isStrandsLoading, error: strandsError } = useQuery<Strand[], Error>(
    'strands',
    async () => {
      const response = await AxiosInstance.get('/api/strands');
      return response.data;
    }
  );

  // Update metadata when strand changes
  useEffect(() => {
    if (strandId && strands) {
      const strand = strands.find(s => s._id === strandId);
      if (strand) {
        setSelectedStrand(strand);
        setFormMetadata(prev => ({
          ...prev,
          title: `${strand.name} Form`,
          slug: strand.slug // Ensure slug is set
        }));
        setPreviewUrl(`${window.location.origin}/form/${strand.slug}`);
      }
    }
  }, [strandId, strands]);

// Update the useEffect to set existingForm and open the dialog
useEffect(() => {
  const checkExistingForm = async () => {
    if (strandId && formType) {
      try {
        const response = await AxiosInstance.get('/api/formTemplates', {
          params: { formId: formType, strand: strandId }
        });

        if (response.data.length > 0) {
          setExistingForm(response.data[0]);
          setOpenDialog(true);
        }
      } catch (error) {
        console.error('Error fetching existing form:', error);
        setSaveStatus({
          open: true,
          message: 'Error fetching existing form. Please try again.',
          severity: 'error'
        });
      }
    }
  };
  
  checkExistingForm();
}, [strandId, formType]);


  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over || over.id !== 'canvas') return;

    const dragData = active.data.current;
    if (!dragData) return;

    if (dragData.type === 'field' && dragData.fieldType) {
      addField(dragData.fieldType);
    } else if (dragData.type === 'block' && dragData.block?.fields) {
      const newFields = dragData.block.fields.map((field: FormField) => ({
        ...field,
        id: `${field.id}-${Date.now()}`,
        strandSpecific: true,
        strands: [strandId],
      }));
      setFields(prev => [...prev, ...newFields]);
    }
  };

  const handleStrandChange = (event: SelectChangeEvent<string>) => {
    setStrandId(event.target.value);
  };

  const handleMetadataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormMetadata(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const addField = (fieldType: string) => {
    if (!strandId) {
      alert('Please select a Strand before adding fields.');
      return;
    }

    const baseField: Omit<BaseField, 'type'> = {
      id: `field-${Date.now()}`,
      name: '',
      label: `New ${fieldType.charAt(0).toUpperCase() + fieldType.slice(1)} Field`,
      placeholder: '',
      helperText: '',
      validation: {
        required: false
      },
      strandSpecific: true,
      strands: [strandId],
      grid: { xs: 12 },
    };

    let newField: FormField;

    switch (fieldType.toLowerCase()) {
      case 'upload':
        newField = {
          ...baseField,
          type: FieldType.Upload,
          accept: '*/*',
          multiple: false
        };
        break;

      case 'select':
        newField = {
          ...baseField,
          type: FieldType.Select,
          options: []
        };
        break;

      case 'checkbox':
        newField = {
          ...baseField,
          type: FieldType.Checkbox,
          defaultValue: false
        };
        break;

      case 'text':
        newField = {
          ...baseField,
          type: FieldType.Text,
          defaultValue: ''
        };
        break;

      case 'textarea':
        newField = {
          ...baseField,
          type: FieldType.TextArea,
          defaultValue: ''
        };
        break;

      case 'email':
        newField = {
          ...baseField,
          type: FieldType.Email,
          defaultValue: ''
        };
        break;

      case 'url':
        newField = {
          ...baseField,
          type: FieldType.Url,
          defaultValue: ''
        };
        break;

      case 'sig':
        newField = {
          ...baseField,
          type: FieldType.Sig,
          signatureConfig: {
            penColor: '#000000',
            penSize: 2,
            canvasHeight: 200,
            backgroundColor: '#ffffff',
            clearable: true
          }
        };
        break;

      default:
        throw new Error(`Unhandled field type: ${fieldType}`);
    }

    setFields(prev => [...prev, newField]);
  };

  const updateField = (updatedField: FormField) => {
    setFields(prev =>
      prev.map(field => (field.id === updatedField.id ? updatedField : field))
    );
  };

  const removeField = (fieldId: string) => {
    setFields(prev => prev.filter(field => field.id !== fieldId));
  };

  const saveFormTemplate = async () => {
    try {
      if (!strandId) {
        setSaveStatus({
          open: true,
          message: 'Please select a strand first',
          severity: 'error'
        });
        return;
      }
  
      if (!formMetadata.title) {
        setSaveStatus({
          open: true,
          message: 'Please provide a form title',
          severity: 'error'
        });
        return;
      }
  
      const token = await getToken(); // If not using Axios interceptors, ensure token is set here

      const cleanFields = fields.map(field => ({
        ...field,
        strands: field.strandSpecific ? [strandId] : [],
      }));
  
      const formTemplate = {
        formId: formMetadata.slug, // Use slug as formId
        title: formMetadata.title,
        description: formMetadata.description,
        strand: strandId,
        slug: formMetadata.slug,
        fields: cleanFields
      };
  
      const response = await AxiosInstance.put(
        `/api/formTemplates/${formTemplate.formId}`,
        formTemplate
        // No need to set headers here if Axios instance handles it
      );
  
      setSaveStatus({
        open: true,
        message: 'Form template saved successfully!',
        severity: 'success'
      });
  
    } catch (error) {
      setSaveStatus({
        open: true,
        message: 'Error saving form template. Please try again.',
        severity: 'error'
      });
      console.error('Error saving form template:', error);
    }
  };


  return (
    <Box sx={{ p: 3 }}>
      {/* Form Setup Section */}
      <Paper sx={{ p: 3, mb: 4, bgcolor: 'background.paper' }}>
        <Typography variant="h5" gutterBottom>
          Form Setup
        </Typography>
        
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Select Strand</InputLabel>
          <Select
            value={strandId}
            onChange={handleStrandChange}
            label="Select Strand"
          >
            {isStrandsLoading && <MenuItem value=""><em>Loading...</em></MenuItem>}
            {strands?.map((strand: Strand) => (
              <MenuItem key={strand._id} value={strand._id}>
                   {`${strand.name} (${strand.year})`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Form Title"
          name="title"
          value={formMetadata.title}
          onChange={handleMetadataChange}
          sx={{ mb: 3 }}
        />

        <TextField
          fullWidth
          label="Form Description"
          name="description"
          value={formMetadata.description}
          onChange={handleMetadataChange}
          multiline
          rows={3}
          sx={{ mb: 3 }}
          helperText="Internal notes about this form's purpose"
        />

        {previewUrl && (
          <Alert severity="info" sx={{ mb: 3 }}>
            Form will be available at: {previewUrl}
          </Alert>
        )}
      </Paper>

      {/* Form Builder Section */}
      <DndContext 
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box sx={{ width: 300 }}>
            <FieldPalette />
          </Box>
          
          <Box sx={{ flex: 1 }}>
          <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        gap: 2,
        mb: 2 
      }}>
        <Button
          variant="outlined"
          startIcon={<PreviewIcon />}
          onClick={() => setOpenPreview(true)}
        >
          Preview
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={saveFormTemplate}
          disabled={!strandId || !formMetadata.title}
        >
          Save Form
        </Button>
      </Box>
           {/* FormPreviewDialog */}
           {openPreview && (
        <FormPreviewDialog
          open={openPreview}
          onClose={() => setOpenPreview(false)}
          fields={fields}
          formTitle={formMetadata.title}
          formDescription={formMetadata.description}
        />
      )}

      {/* Save Status Snackbar */}
      <Snackbar
        open={saveStatus.open}
        autoHideDuration={6000}
        onClose={() => setSaveStatus(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSaveStatus(prev => ({ ...prev, open: false }))}
          severity={saveStatus.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {saveStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
>
  <DialogTitle>Existing Form Found</DialogTitle>
  <DialogContent>
    <DialogContentText>
      A {formType.charAt(0).toUpperCase() + formType.slice(1)} form already exists for this strand. Do you want to edit it?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)}>No</Button>
    <Button onClick={() => {
      if (existingForm) {
        setFields(existingForm.fields);
        setFormMetadata({
          title: existingForm.title,
          description: existingForm.description,
          slug: existingForm.slug
        });
      }
      setOpenDialog(false);
    }} autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>

<FormCanvas 
  fields={fields}
  onUpdateField={updateField}
  onRemoveField={removeField}
  onReorderFields={handleReorderFields}
/>
          </Box>
        </Box>

        <DragOverlay>
          {activeId && (
            <Box
              sx={{
                p: '12px 16px',
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: 1,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                cursor: 'grabbing',
                color: '#fff'
              }}
            >
              {activeId.replace('field-', '').replace('block-', '').replace(/-/g, ' ')}
            </Box>
          )}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};

export default FormBuilder;