import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { X as CloseIcon, Edit2 } from 'lucide-react';
import { Task, TaskCategory, TaskPriority, TaskStatus } from '../types/taskTypes';
import TaskForm from '../forms/TaskForm';
import { TaskFormValues, TaskTool } from '../forms/TaskForm';
import axiosInstance from '../../../../utils/axiosConfig';

interface EditTaskDialogProps {
  open: boolean;
  onClose: () => void;
  task: Task;
  onRefresh?: () => void;
}

export const EditTaskDialog: React.FC<EditTaskDialogProps> = ({
  open,
  onClose,
  task,
  onRefresh
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Debug logs
  useEffect(() => {
    console.log('Task received in EditDialog:', task);
    console.log('Task subtasks:', task.subtasks);
  }, [task]);

  const handleSubmit = async (values: TaskFormValues) => {
    setIsSubmitting(true);
    setError(null);
    try {
      console.log('Submitting values:', values);
      await axiosInstance.put(`/api/taskboards/${task.id}`, values);
      onClose();
      onRefresh?.();
    } catch (err: unknown) {
      console.error('Failed to update task:', err);
      setError('Failed to update task. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const mapTaskTools = (taskTools: Task['tools']): TaskTool[] => {
    return taskTools.map(t => ({
      id: t.id,
      name: t.name,
      quantity: t.quantity,
      inventoryItem: t.inventoryItem?._id || "",
    }));
  };

  const getAssignedToValue = () => {
    if (!task.assignedTo) return '';
    return typeof task.assignedTo === 'object' ? task.assignedTo._id : task.assignedTo;
  };

  // Transform subtasks for the form
  const prepareSubtasks = () => {
    return task.subtasks.map(subtask => ({
      ...subtask,
      assignedTo: subtask.assignedTo || '', // Ensure we have a string value
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100% - 64px)',
          maxHeight: 'calc(100% - 64px)'
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Edit2 size={24} />
            Edit Task
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        
        <TaskForm
          initialValues={{
            title: task.title,
            description: task.description,
            status: task.status as TaskStatus,
            priority: task.priority as TaskPriority,
            category: task.category as TaskCategory,
            progress: task.progress,
            dueDate: task.dueDate,
            estimatedHours: task.estimatedHours,
            actualHours: task.actualHours,
            tools: mapTaskTools(task.tools),
            subtasks: prepareSubtasks(),
            assignedTo: getAssignedToValue(),
            watchers: [],
            linkedInventoryItems: task.linkedInventoryItems || [],
          }}
          onSubmit={handleSubmit}
          onCancel={onClose}
          isEditing={true}
          isSubmitting={isSubmitting}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditTaskDialog;