import React, { useState, useEffect } from 'react';
import { format, addDays, isSameDay, isWithinInterval, eachDayOfInterval, parseISO, isAfter, isBefore, getDay } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import axios from 'axios';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Chip,
  Grid,
  CircularProgress,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Type definitions
interface Performance {
  _id: string;
  band?: string | { artistName: string; _id: string } | { simpleBandName: string };
  simpleBandName?: string;
  event: string;
  startTime: string;
  endTime: string;
  fee: number;
  status: string;
  ticketPrice: number;
  sponsorship: number;
  notes: string;
}

interface Event {
  _id: string;
  name: string;
  eventType: string;
  description: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  status: string;
}

interface Strand {
  _id: string;
  name: string;
  description: string;
  type: string;
  year: number;
  startDate: string;
  endDate: string;
  eventType: string;
  status: string;
  published: boolean;
  slug: string;
}

interface Prospect {
  _id: string;
  strandId: string;
  date: string;
  eventConcept: string;
  potentialArtists: Array<{ artistName: string; _id: string } | string>;
  status: string;
  fees: number;
  notes: string;
}

interface CalendarEvent extends EventInput {
  type: 'event' | 'performance' | 'prospect' | 'strand';
  originalId: string;
  eventType?: string;
  status?: string;
  strandId?: string;
  assignedStaff?: Array<{ name: string; role: string }>;
}

interface Filters {
  showPerformances: boolean;
  showStrands: boolean;
  showEvents: boolean;
  showProspects: boolean;
  status: string;
  eventType: string;
  strandId: string;
}

const CalendarComponent: React.FC = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [filters, setFilters] = useState<Filters>({
    showPerformances: true, // Checked on page load
    showStrands: false, // Unchecked on page load
    showEvents: false, // Unchecked on page load
    showProspects: true, // Checked on page load
    status: 'all',
    eventType: 'all',
    strandId: 'all'
  });

  const getBandName = (band: Performance['band']): string => {
    try {
      console.log('getBandName input:', band);
      console.log('getBandName type:', typeof band);
      
      // Handle undefined/null case
      if (!band) {
        console.log('Band is null/undefined');
        return 'Unnamed Band';
      }

      // If band is a string, it might be a direct name
      if (typeof band === 'string') {
        console.log('Band is string:', band);
        return band;
      }

      // Log the object properties
      if (typeof band === 'object') {
        console.log('Band object properties:', Object.keys(band));
      }

      // If band has simpleBandName, use that
      if (typeof band === 'object' && 'simpleBandName' in band && band.simpleBandName) {
        console.log('Found simpleBandName:', band.simpleBandName);
        return band.simpleBandName;
      }

      // If band has artistName, use that
      if (typeof band === 'object' && 'artistName' in band && band.artistName) {
        console.log('Found artistName:', band.artistName);
        return band.artistName;
      }

      console.log('No name found, returning Unnamed Band');
      return 'Unnamed Band';
    } catch (error) {
      console.error('Error getting band name:', error, 'Band value:', band);
      return 'Unnamed Band';
    }
  };


  const fetchAllData = async () => {
    try {
      setLoading(true);
      const [strandsResponse, eventsResponse, performancesResponse, prospectsResponse] = await Promise.all([
        axios.get<Strand[]>(`${process.env.REACT_APP_API_URL}/api/strands`),
        axios.get<Event[]>(`${process.env.REACT_APP_API_URL}/api/events`),
        axios.get<Performance[]>(`${process.env.REACT_APP_API_URL}/api/performances`),
        axios.get<Prospect[]>(`${process.env.REACT_APP_API_URL}/api/strands/prospects`)
      ]);

      setStrands(strandsResponse.data);
      const mappedEvents: CalendarEvent[] = [];

      // Map Strands
      strandsResponse.data.forEach(strand => {
        mappedEvents.push({
          id: `strand-${strand._id}`,
          title: strand.name,
          start: strand.startDate,
          end: strand.endDate,
          type: 'strand',
          originalId: strand._id,
          eventType: strand.eventType,
          status: strand.status,
          backgroundColor: '#9C27B0',
          extendedProps: {
            description: strand.description,
            type: 'strand',
            published: strand.published
          }
        });
      });

      // Map Events
      eventsResponse.data.forEach(event => {
        mappedEvents.push({
          id: `event-${event._id}`,
          title: event.name,
          start: event.startDate || event.date,
          end: event.endDate || event.date,
          type: 'event',
          originalId: event._id,
          eventType: event.eventType,
          status: event.status,
          backgroundColor: '#4CAF50',
          extendedProps: {
            type: 'event',
            description: event.description
          }
        });
      });

      // Map Performances - Updated to use getBandName helper
      performancesResponse.data.forEach(performance => {
        console.log('Performance data:', performance);
        // Check for root-level simpleBandName first
        let bandName;
        if (performance.simpleBandName) {
          bandName = performance.simpleBandName;
        } else if (performance.band) {
          bandName = getBandName(performance.band);
        } else {
          bandName = 'Unnamed Band';
        }
        console.log('Resolved band name:', bandName);
        mappedEvents.push({
          id: `performance-${performance._id}`,
          title: `${bandName}`,
          start: performance.startTime,
          end: performance.endTime,
          type: 'performance',
          originalId: performance._id,
          status: performance.status,
          backgroundColor: '#2196F3',
          extendedProps: {
            type: 'performance',
            fee: performance.fee,
            ticketPrice: performance.ticketPrice,
            notes: performance.notes
          }
        });
      });

      // Map Prospects - Ensure safe access to artist names
      prospectsResponse.data.forEach(prospect => {
        let artistName = 'Unknown';
        if (Array.isArray(prospect.potentialArtists) && prospect.potentialArtists.length > 0) {
          const firstArtist = prospect.potentialArtists[0];
          if (typeof firstArtist === 'string') {
            artistName = firstArtist;
          } else if (typeof firstArtist === 'object' && firstArtist !== null) {
            artistName = firstArtist.artistName || 'Unknown';
          }
        }

        mappedEvents.push({
          id: `prospect-${prospect._id}`,
          title: `${artistName}`,
          start: prospect.date,
          type: 'prospect',
          originalId: prospect._id,
          status: prospect.status,
          backgroundColor: '#FF9800',
          extendedProps: {
            type: 'prospect',
            eventConcept: prospect.eventConcept,
            strandId: prospect.strandId,
            fees: prospect.fees,
            notes: prospect.notes
          }
        });
      });

      setEvents(mappedEvents);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch calendar data');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchAllData();
  }, []);


  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventData = clickInfo.event;
    setSelectedEvent({
      ...eventData.toPlainObject(),
      type: eventData.extendedProps.type,
      originalId: eventData.id.split('-')[1]
    });
    setIsEventDetailsOpen(true);
  };

  const handleViewDetails = () => {
    if (!selectedEvent) return;

    const baseRoute = selectedEvent.type === 'strand' ? '/strands' :
                     selectedEvent.type === 'event' ? '/events' :
                     selectedEvent.type === 'performance' ? '/performances' :
                     '/prospects';

    navigate(`${baseRoute}/${selectedEvent.originalId}`);
    setIsEventDetailsOpen(false);
  };

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return '#4CAF50';
      case 'cancelled':
        return '#F44336';
      case 'planned':
        return '#FFC107';
      default:
        return '#757575';
    }
  };

  const filteredEvents = events.filter(event => {
    if (!filters.showPerformances && event.type === 'performance') return false;
    if (!filters.showStrands && event.type === 'strand') return false;
    if (!filters.showEvents && event.type === 'event') return false;
    if (!filters.showProspects && event.type === 'prospect') return false;
    if (filters.status !== 'all' && event.status !== filters.status) return false;
    if (filters.eventType !== 'all' && event.eventType !== filters.eventType) return false;
    if (filters.strandId !== 'all' && event.extendedProps?.strandId !== filters.strandId) return false;
    return true;
  });

  const renderEventContent = (eventInfo: any) => (
    <Box sx={{ p: 1 }}>
      <Typography variant="body2" noWrap>
        {eventInfo.event.title}
      </Typography>
      {eventInfo.event.extendedProps.type && (
        <Chip
          label={eventInfo.event.extendedProps.type}
          size="small"
          sx={{
            mr: 1,
            backgroundColor: eventInfo.event.backgroundColor || 'default',
            color: '#fff', // Ensure text is visible
          }}
        />
      )}
    </Box>
  );

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Events Calendar
      </Typography>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Filters
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showPerformances}
                  onChange={(e) => setFilters({...filters, showPerformances: e.target.checked})}
                />
              }
              label="Performances"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showStrands}
                  onChange={(e) => setFilters({...filters, showStrands: e.target.checked})}
                />
              }
              label="Strands"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showEvents}
                  onChange={(e) => setFilters({...filters, showEvents: e.target.checked})}
                />
              }
              label="Events"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.showProspects}
                  onChange={(e) => setFilters({...filters, showProspects: e.target.checked})}
                />
              }
              label="Prospects"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status}
                onChange={(e) => setFilters({...filters, status: e.target.value})}
                label="Status"
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="planned">Planned</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Event Type</InputLabel>
              <Select
                value={filters.eventType}
                onChange={(e) => setFilters({...filters, eventType: e.target.value})}
                label="Event Type"
              >
                <MenuItem value="all">All Types</MenuItem>
                <MenuItem value="Workshop">Workshop</MenuItem>
                <MenuItem value="Concert">Concert</MenuItem>
                <MenuItem value="Festival">Festival</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel>Strand</InputLabel>
              <Select
                value={filters.strandId}
                onChange={(e) => setFilters({...filters, strandId: e.target.value})}
                label="Strand"
              >
                <MenuItem value="all">All Strands</MenuItem>
                {strands.map(strand => (
                  <MenuItem key={strand._id} value={strand._id}>
                    {strand.name} - ({strand.year})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        events={filteredEvents}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        height="800px"
      />

      <Dialog
        open={isEventDetailsOpen}
        onClose={() => setIsEventDetailsOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedEvent?.title}
          <Chip
            label={selectedEvent?.type}
            size="small"
            sx={{ ml: 1 }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography><strong>Status:</strong> {selectedEvent?.status}</Typography>
            </Grid>
            {selectedEvent?.extendedProps?.description && (
              <Grid item xs={12}>
                <Typography><strong>Description:</strong> {selectedEvent.extendedProps.description}</Typography>
              </Grid>
            )}
            {selectedEvent?.extendedProps?.eventConcept && (
              <Grid item xs={12}>
                <Typography><strong>Event Concept:</strong> {selectedEvent.extendedProps.eventConcept}</Typography>
              </Grid>
            )}
            {selectedEvent?.extendedProps?.fee && (
              <Grid item xs={12}>
                <Typography><strong>Fee:</strong> €{selectedEvent.extendedProps.fee}</Typography>
              </Grid>
            )}
            {selectedEvent?.extendedProps?.notes && (
              <Grid item xs={12}>
                <Typography><strong>Notes:</strong> {selectedEvent.extendedProps.notes}</Typography>
              </Grid>
            )}
            {selectedEvent?.start && (
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>Start:</strong> {format(new Date(selectedEvent.start.toString()), 'PPpp')}
                </Typography>
              </Grid>
            )}
            {selectedEvent?.end && (
              <Grid item xs={12} sm={6}>
                <Typography>
                  <strong>End:</strong> {format(new Date(selectedEvent.end.toString()), 'PPpp')}
                </Typography>
              </Grid>
            )}
            {selectedEvent?.type === 'performance' && selectedEvent.extendedProps?.ticketPrice && (
              <Grid item xs={12}>
                <Typography>
                  <strong>Ticket Price:</strong> €{selectedEvent.extendedProps.ticketPrice}
                </Typography>
              </Grid>
            )}
            {selectedEvent?.type === 'prospect' && selectedEvent.extendedProps?.fees && (
              <Grid item xs={12}>
                <Typography>
                  <strong>Proposed Fee:</strong> €{selectedEvent.extendedProps.fees}
                </Typography>
              </Grid>
            )}
            {selectedEvent?.type === 'strand' && selectedEvent.extendedProps?.published !== undefined && (
              <Grid item xs={12}>
                <Typography>
                  <strong>Published:</strong> {selectedEvent.extendedProps.published ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEventDetailsOpen(false)}>Close</Button>
          <Button 
            onClick={handleViewDetails} 
            variant="contained" 
            color="primary"
          >
            View Full Details
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Legend
        </Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: '#2196F3', mr: 1 }} />
              <Typography>Performances</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: '#9C27B0', mr: 1 }} />
              <Typography>Strands</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: '#4CAF50', mr: 1 }} />
              <Typography>Events</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 16, height: 16, backgroundColor: '#FF9800', mr: 1 }} />
              <Typography>Prospects</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CalendarComponent;