import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Card,
  Box,
  Typography,
  Chip,
  LinearProgress,
  Stack,
  IconButton,
  Checkbox,
  FormControlLabel,
  Collapse,
  Tooltip,
} from '@mui/material';
import {
  Construction as ConstructionIcon,
  Build as BuildIcon,
  Settings as SettingsIcon,
  TrendingUp as TrendingUpIcon,
  PushPin as PushPinIcon,
  SportsBar as SportsBarIcon,
  TheaterComedy as TheaterComedyIcon,
  Assignment as AssignmentIcon,
  AcUnit as AcUnitIcon,
  Announcement as AnnouncementIcon,
  Newspaper as NewspaperIcon,
  Warehouse as WarehouseIcon,
  Thermostat as ThermostatIcon,
  Mic as MicIcon,
  RocketLaunch as RocketLaunchIcon,
  Lightbulb as LightbulbIcon,
  VolumeUp as VolumeUpIcon,
  Movie as MovieIcon,
  Whatshot as WhatshotIcon,
  MusicNote as MusicNoteIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';
import {
  CheckCircle,
  Clock,
  ChevronRight,
  ChevronDown,
  Image as ImageIcon,
  Calendar,
  Wrench,
  User,
  Edit2, 
  Trash2,
} from 'lucide-react';
import axiosInstance from '../../../../utils/axiosConfig';
import { Task, TASK_PRIORITIES, TASK_CATEGORIES, TaskCategory } from '../types/taskTypes';
import SubtaskList from './SubtaskList';

interface KanbanCardProps {
  task: Task;
  index: number;
  onEdit?: (task: Task) => void;
  onDelete?: (taskId: string) => void;
  onRefresh?: () => void;
  setTasks?: React.Dispatch<React.SetStateAction<Task[]>>;
}

// Get category icon and color if available
const getCategoryIcon = (category: string) => {
  const iconMap = {
    repair: ConstructionIcon,
    maintenance: BuildIcon,
    setup: SettingsIcon,
    improvement: TrendingUpIcon,
    general: PushPinIcon,
    bar_area: SportsBarIcon,
    stage: TheaterComedyIcon,
    organisation: AssignmentIcon,
    cooler_general: AcUnitIcon,
    notice_board: AnnouncementIcon,
    outside_press: NewspaperIcon,
    presses_storage: WarehouseIcon,
    cooler_space: ThermostatIcon,
    audience_experience: MicIcon,
    throughput: RocketLaunchIcon,
    lighting: LightbulbIcon,
    sound: VolumeUpIcon,
    projectors: MovieIcon,
    security: SecurityIcon,
    heating: WhatshotIcon,
    rehearsals: MusicNoteIcon,
  };

  return iconMap[category as keyof typeof iconMap];
};

const KanbanCard: React.FC<KanbanCardProps> = ({
  task,
  index,
  onEdit,
  onDelete,
  onRefresh,
  setTasks
}) => {
  const [expanded, setExpanded] = useState(() => {
    const savedState = localStorage.getItem(`task-${task.id}-expanded`);
    return savedState ? JSON.parse(savedState) : false;
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [assigneeName, setAssigneeName] = useState<string>('Loading...');
  
  const IconComponent = task.category ? getCategoryIcon(task.category) : null;
  const iconColor = task.category ? TASK_CATEGORIES[task.category as TaskCategory]?.color : null;

  useEffect(() => {
    const fetchAssigneeName = async () => {
      if (!task.assignedTo) {
        setAssigneeName('Unassigned');
        return;
      }

      if (typeof task.assignedTo === 'object' && task.assignedTo.name) {
        setAssigneeName(task.assignedTo.name);
        return;
      }

      try {
        const response = await axiosInstance.post('/api/personnel/bulk', { 
          ids: [task.assignedTo]
        });
        if (response.data && response.data[0]) {
          setAssigneeName(response.data[0].name);
        } else {
          setAssigneeName('Unknown');
        }
      } catch (error) {
        console.error('Error fetching assignee name:', error);
        setAssigneeName('Error');
      }
    };

    fetchAssigneeName();
  }, [task.assignedTo]);

  useEffect(() => {
    localStorage.setItem(`task-${task.id}-expanded`, JSON.stringify(expanded));
  }, [expanded, task.id]);

  const handleProgressToggle = async () => {
    if (isUpdating) return;
    setIsUpdating(true);

    if (setTasks) {
      setTasks(currentTasks => 
        currentTasks.map(t => 
          t.id === task.id 
            ? { ...t, progress: t.progress === 100 ? 0 : 100 }
            : t
        )
      );
    }

    try {
      await axiosInstance.patch(`/api/taskboards/${task.id}/progress`, {
        progress: task.progress === 100 ? 0 : 100
      });
    } catch (err) {
      console.error('Failed to update progress:', err);
      if (setTasks) {
        setTasks(currentTasks => 
          currentTasks.map(t => 
            t.id === task.id 
              ? { ...t, progress: task.progress }
              : t
          )
        );
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubtaskToggle = async (subtaskId: string) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const subtask = task.subtasks.find(s => s.id === subtaskId);
    if (!subtask) return;

    if (setTasks) {
      setTasks(currentTasks => 
        currentTasks.map(t => {
          if (t.id === task.id) {
            return {
              ...t,
              subtasks: t.subtasks.map(s => 
                s.id === subtaskId 
                  ? { ...s, completed: !s.completed }
                  : s
              )
            };
          }
          return t;
        })
      );
    }

    try {
      const res = await axiosInstance.patch(`/api/taskboards/${task.id}/subtasks/${subtaskId}`);
      console.log('Task API Response:', res.data);
    } catch (err) {
      console.error('Failed to toggle subtask:', err);
      if (setTasks) {
        setTasks(currentTasks =>
          currentTasks.map(t => {
            if (t.id === task.id) {
              return {
                ...t,
                subtasks: t.subtasks.map(s =>
                  s.id === subtaskId
                    ? { ...s, completed: subtask.completed }
                    : s
                )
              };
            }
            return t;
          })
        );
      }
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="hover:shadow-lg transition-shadow"
          sx={{ 
            position: 'relative',
            bgcolor: task.progress === 100 ? 'action.hover' : 'background.paper',
          }}
        >
          {/* Compact Header */}
          <Box sx={{ p: 1.5 }}>
            <Stack spacing={1}>
              {/* Title Row with Controls */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Checkbox
                  checked={task.progress === 100}
                  onChange={handleProgressToggle}
                  disabled={isUpdating}
                  size="small"
                />
                
                <Stack flex={1} spacing={0.5}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      textDecoration: task.progress === 100 ? 'line-through' : 'none',
                      color: task.progress === 100 ? 'text.secondary' : 'text.primary',
                      lineHeight: 1.2,
                    }}
                  >
                    {task.title}
                  </Typography>
                  
                  {/* Progress and Priority */}
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Chip
                      size="small"
                      label={TASK_PRIORITIES[task.priority]}
                      sx={{ height: 20 }}
                    />
                    <LinearProgress
                      variant="determinate"
                      value={task.progress}
                      sx={{ 
                        flex: 1,
                        height: 4,
                        borderRadius: 2,
                      }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {task.progress}%
                    </Typography>
                  </Stack>
                </Stack>

                {/* Action Buttons */}
                <Stack direction="row" spacing={0.5}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit?.(task);
                    }}
                  >
                    <Edit2 size={14} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete?.(task.id);
                    }}
                  >
                    <Trash2 size={14} />
                  </IconButton>
                </Stack>
              </Stack>

              {/* Info Row */}
              <Stack direction="row" spacing={1} alignItems="center">
                {IconComponent && (
                  <IconComponent
                    sx={{
                      fontSize: 16,
                      color: iconColor || 'inherit'
                    }}
                  />
                )}
                <Tooltip title={assigneeName}>
                  <Chip
                    size="small"
                    icon={<User size={14} />}
                    label={assigneeName}
                    sx={{ 
                      maxWidth: 120,
                      '& .MuiChip-label': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }
                    }}
                  />
                </Tooltip>
                <Chip
                  size="small"
                  icon={<Calendar size={14} />}
                  label={new Date(task.dueDate).toLocaleDateString()}
                />
              </Stack>

              {/* Details Section */}
              {(task.description || task.subtasks.length > 0) && (
                <>
                  <IconButton
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    sx={{ alignSelf: 'flex-start', ml: -1 }}
                  >
                    {expanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  </IconButton>
                  
                  <Collapse in={expanded}>
                    <Stack spacing={1.5} sx={{ mt: 1 }}>
                      {task.description && (
                        <Typography variant="body2" color="text.secondary">
                          {task.description}
                        </Typography>
                      )}

                      {task.subtasks.length > 0 && (
                        <SubtaskList 
                          subtasks={task.subtasks}
                          onToggle={handleSubtaskToggle}
                          isUpdating={isUpdating}
                        />
                      )}

                      {task.tools && task.tools.length > 0 && (
                        <Stack spacing={0.5}>
                          <Typography variant="caption" color="text.secondary">
                            <Wrench size={14} className="inline mr-1" />
                            Tools
                          </Typography>
                          <Stack direction="row" spacing={0.5} flexWrap="wrap">
                            {task.tools.map((tool) => (
                              <Chip
                                key={tool.id}
                                size="small"
                                label={`${tool.quantity}x ${tool.inventoryItem.name}`}
                                sx={{ height: 20 }}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Collapse>
                </>
              )}
            </Stack>
          </Box>
        </Card>
      )}
    </Draggable>
  );
};

export default KanbanCard;