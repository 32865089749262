import React, { useState } from 'react';
import {
  Drawer,
  Box,
  Typography,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  Slider,
  Button,
  Chip,
  Stack,
  Divider,
  IconButton,
  InputAdornment
} from '@mui/material';
import {
  Filter,
  X as CloseIcon,
  RefreshCw,
  Tag as TagIcon,
  MapPin,
  Euro,
  Settings,
  Calendar,
  User,
  FileText,
  AlertTriangle,
  Power,
  Building,
} from 'lucide-react';

import { 
  CATEGORIES, 
  LOCATIONS, 
  SECOND_LOCATIONS, // Add this
  VENDORS 
} from '../constants/inventoryConstants';

interface FilterDrawerProps {
  open: boolean;
  onClose: () => void;
  onApplyFilters: (filters: any) => void;
}

export const FilterDrawer: React.FC<FilterDrawerProps> = ({
  open,
  onClose,
  onApplyFilters,
}) => {
    const [filters, setFilters] = useState<{
        [key: string]: any; // Allows arbitrary string indexing
        name: string;
        categories: string[];
        tags: string[];
        locations: string[];
        secondLocations: string; // Add this
        secondLocation: string;
        vendors: string[];
        purchaseDateRange: {
          start: string;
          end: string;
        };
        valueRange: {
          min: number;
          max: number;
        };
        requiresPower: boolean | null;
        powerLocation: string;
        isInsured: boolean | null;
        maintenanceStatus: string[];
        hasDocuments: boolean | null;
        hasRelatedEquipment: boolean | null;
      }>({
        name: '',
        categories: [],
        tags: [],
        locations: [],
        secondLocation: '',
        secondLocations: '',       // Add this
        vendors: [],
        purchaseDateRange: {
          start: '',
          end: '',
        },
        valueRange: {
          min: 0,
          max: 1000,
        },
        requiresPower: null,
        powerLocation: '',
        isInsured: null,
        maintenanceStatus: [],
        hasDocuments: null,
        hasRelatedEquipment: null,
      });
  
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const handleFilterChange = (field: string, value: any) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));

    // Update active filters
    if (value && !activeFilters.includes(field)) {
      setActiveFilters(prev => [...prev, field]);
    } else if (!value && activeFilters.includes(field)) {
      setActiveFilters(prev => prev.filter(f => f !== field));
    }
  };

  const handleRemoveFilter = (field: string) => {
    setFilters(prev => ({
      ...prev,
      [field]: Array.isArray(prev[field]) ? [] : null
    }));
    setActiveFilters(prev => prev.filter(f => f !== field));
  };

  const handleApply = () => {
    console.log('Raw filters before processing:', filters);
    
    const activeFilterValues = Object.entries(filters).reduce((acc, [key, value]) => {
        console.log(`Processing key: ${key}, value:`, value); // Log each key/value being processed
        
        if (
          value &&
          ((Array.isArray(value) && value.length > 0) ||
            (typeof value === 'object' && Object.values(value as Record<string, any>).some(v => v)) ||
            (typeof value === 'string' && value.trim() !== '') ||
            (typeof value === 'boolean'))
        ) {
          console.log(`Adding to activeFilters - key: ${key}, value:`, value); // Log what's being added
          (acc as Record<string, any>)[key] = value;
        }
        return acc;
      }, {} as Record<string, any>);

    console.log('Final activeFilterValues:', activeFilterValues);
    onApplyFilters(activeFilterValues);
};

const handleReset = () => {
    const initialState = {
      name: '',
      categories: [],
      tags: [],
      locations: [],
      secondLocation: '', 
      secondLocations: '',  // String, not array
      vendors: [],
      purchaseDateRange: {
        start: '',
        end: '',
      },
      valueRange: {
        min: 0,
        max: 1000,
      },
      requiresPower: null,
      powerLocation: '',
      isInsured: null,
      maintenanceStatus: [],
      hasDocuments: null,
      hasRelatedEquipment: null,
    };
  
    setFilters(initialState);
    setActiveFilters([]);
    onApplyFilters({});
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 380 }
      }}
    >
      <Box sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Filter size={24} />
          <Typography variant="h6" sx={{ ml: 1, flexGrow: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={handleReset} title="Reset filters">
            <RefreshCw size={20} />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon size={20} />
          </IconButton>
        </Box>

        {/* Active Filters */}
        {activeFilters.length > 0 && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle2" gutterBottom>
              Active Filters
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {activeFilters.map(filter => (
                <Chip
                  key={filter}
                  label={filter.replace(/([A-Z])/g, ' $1').trim()}
                  onDelete={() => handleRemoveFilter(filter)}
                  size="small"
                />
              ))}
            </Stack>
          </Box>
        )}

        {/* Filter Sections */}
        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          <Stack spacing={3}>
            {/* Basic Info */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Basic Info
              </Typography>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={filters.name}
                  onChange={(e) => handleFilterChange('name', e.target.value)}
                  size="small"
                />
                
                <Autocomplete
                  multiple
                  options={CATEGORIES}
                  value={filters.categories}
                  onChange={(_, newValue) => handleFilterChange('categories', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <TagIcon size={20} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={filters.tags}
                  onChange={(_, newValue) => handleFilterChange('tags', newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Tags" size="small" />
                  )}
                />
              </Stack>
            </Box>

            <Divider />

            {/* Location */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Location
              </Typography>
              <Stack spacing={2}>
                <Autocomplete
                  multiple
                  options={LOCATIONS}
                  value={filters.locations}
                  onChange={(_, newValue) => handleFilterChange('locations', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Location"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <MapPin size={20} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

            <Autocomplete
  multiple
  options={SECOND_LOCATIONS}
  value={filters.secondaryLocations}
  onChange={(_, newValue) => handleFilterChange('secondaryLocations', newValue)}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Shelf/Drawer Location"
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <>
            <InputAdornment position="start">
              <MapPin size={20} />
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        )
      }}
    />
  )}
/>

<Autocomplete
                  options={LOCATIONS}
                  value={filters.secondLocation}
                  onChange={(_, newValue) => handleFilterChange('secondLocation', newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Secondary Location" size="small" />
                  )}
                />
              </Stack>
            </Box>

            <Divider />

            {/* Purchase Info */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Purchase Info
              </Typography>
              <Stack spacing={2}>
                <Autocomplete
                  multiple
                  options={VENDORS}
                  value={filters.vendors}
                  onChange={(_, newValue) => handleFilterChange('vendors', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendors"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Building size={20} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Purchase Date Range
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      type="date"
                      label="From"
                      value={filters.purchaseDateRange.start}
                      onChange={(e) => handleFilterChange('purchaseDateRange', {
                        ...filters.purchaseDateRange,
                        start: e.target.value
                      })}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      type="date"
                      label="To"
                      value={filters.purchaseDateRange.end}
                      onChange={(e) => handleFilterChange('purchaseDateRange', {
                        ...filters.purchaseDateRange,
                        end: e.target.value
                      })}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Stack>
                </Box>

                <Box>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Value Range (€)
                  </Typography>
                  <Slider
                    value={[filters.valueRange.min, filters.valueRange.max]}
                    onChange={(_, newValue: number | number[]) => handleFilterChange('valueRange', {
                      min: newValue,
                      max: newValue
                    })}
                    valueLabelDisplay="auto"
                    min={0}
                    max={10000}
                    step={100}
                  />
                  <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                    <TextField
                      size="small"
                      label="Min"
                      value={filters.valueRange.min}
                      onChange={(e) => handleFilterChange('valueRange', {
                        ...filters.valueRange,
                        min: Number(e.target.value)
                      })}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                      }}
                    />
                    <TextField
                      size="small"
                      label="Max"
                      value={filters.valueRange.max}
                      onChange={(e) => handleFilterChange('valueRange', {
                        ...filters.valueRange,
                        max: Number(e.target.value)
                      })}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>
                      }}
                    />
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Divider />

            {/* Status & Configuration */}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Status & Configuration
              </Typography>
              <Stack spacing={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.requiresPower === true}
                      onChange={(e) => handleFilterChange('requiresPower', e.target.checked ? true : null)}
                    />
                  }
                  label="Requires Power"
                />

                {filters.requiresPower && (
                  <Autocomplete
                    options={LOCATIONS}
                    value={filters.powerLocation}
                    onChange={(_, newValue) => handleFilterChange('powerLocation', newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Power Location" size="small" />
                    )}
                  />
                )}

                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.isInsured === true}
                      onChange={(e) => handleFilterChange('isInsured', e.target.checked ? true : null)}
                    />
                  }
                  label="Insured"
                />

                <Autocomplete
                  multiple
                  options={['ok', 'due', 'overdue']}
                  value={filters.maintenanceStatus}
                  onChange={(_, newValue) => handleFilterChange('maintenanceStatus', newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Maintenance Status"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <AlertTriangle size={20} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.hasDocuments === true}
                      onChange={(e) => handleFilterChange('hasDocuments', e.target.checked ? true : null)}
                    />
                  }
                  label="Has Documents"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={filters.hasRelatedEquipment === true}
                      onChange={(e) => handleFilterChange('hasRelatedEquipment', e.target.checked ? true : null)}
                    />
                  }
                  label="Has Related Equipment"
                />
              </Stack>
            </Box>
          </Stack>
        </Box>

        {/* Apply Button */}
        <Box sx={{ pt: 2 }}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleApply}
            disabled={activeFilters.length === 0}
          >
            Apply Filters
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;