// src/components/cooler/inventory/CoolerInventoryManager.tsx

import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
  Chip,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Stack,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material';
import {
  Search,
  Upload as UploadIcon,
  Download,
  Settings,
  ArrowLeft,
  Plus,
  Filter,
} from 'lucide-react';
import axiosInstance from '../../../utils/axiosConfig';

import {
  CATEGORIES,
  Category,
  LOCATIONS,
  SECOND_LOCATIONS,
  shouldShowSerialNumber
} from '../constants/inventoryConstants';
import type { 
  InventoryItem,
  FilterOptions,
  MaintenanceRecord,
  NewInventoryItem,
  InventoryUpdateData,
  ImageReference,
  FilterChangeHandler
} from '../types/inventoryTypes';

import { FilterDrawer } from './FilterDrawer';

import { ItemGrid } from './ItemGrid';
import { AddItemDialog } from './AddItemDialog';
import { MaintenanceDialog } from './MaintenanceDialog';
import { ItemDetailsDialog } from './components/ItemDetails';


const CoolerInventoryManager: React.FC = () => {
  // Existing state
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<FilterOptions>({});
  const [inventory, setInventory] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Dialog states
  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);
  const [maintenanceDialogOpen, setMaintenanceDialogOpen] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  
  // Selected item state
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);

  // Notification state
  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error';
    open: boolean;
  }>({
    message: '',
    type: 'success',
    open: false,
  });

  // Debounce helper
const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};


  // Fetch inventory data on mount and when filters change
  useEffect(() => {
    fetchInventory();
  }, [filters]);

  const fetchInventory = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      console.log('Current filters:', filters);
  
      // Your existing filter logic
      if (searchTerm) params.append('search', searchTerm);
      if (filters.category?.length) {
        params.append('categories', filters.category.join(','));
      }
      if (filters.location?.length) {
        params.append('location', filters.location.join(','));
      }
      if (filters.secondLocation) {
        params.append('secondLocation', filters.secondLocation);
      }
  
      // Value filters
      if (filters.valueRange?.min !== undefined) {
        params.append('minValue', filters.valueRange.min.toString());
      }
      if (filters.valueRange?.max !== undefined) {
        params.append('maxValue', filters.valueRange.max.toString());
      }
  
      // Boolean filters
      if (filters.requiresPower !== undefined) {
        params.append('requiresPower', filters.requiresPower.toString());
      }
      if (filters.isInsured !== undefined) {
        params.append('isInsured', filters.isInsured.toString());
      }
  
      // Array filters
      if (filters.maintenanceStatus?.length) {
        params.append('maintenanceStatus', filters.maintenanceStatus.join(','));
      }
      if (filters.vendor?.length) {
        params.append('vendors', filters.vendor.join(','));
      }
      if (filters.tags?.length) {
        params.append('tags', filters.tags.join(','));
      }
  
      // Special filters
      if (filters.hasDocuments !== undefined) {
        params.append('hasDocuments', filters.hasDocuments.toString());
      }
      if (filters.hasRelatedEquipment !== undefined) {
        params.append('hasRelatedEquipment', filters.hasRelatedEquipment.toString());
      }
  
      // Sort params
      if (filters.sortBy) params.append('sortBy', filters.sortBy);
      if (filters.sortOrder) params.append('sortOrder', filters.sortOrder);
  
      console.log('Params before API call:', params.toString());
  
      const response = await axiosInstance.get(`/api/inventory?${params.toString()}`);
      
      // Client-side filtering
      let filteredData = response.data;
      filteredData = filteredData.filter((item: InventoryItem) => {
        const locationMatch = !filters.location?.length || 
          filters.location.includes(item.primaryLocation);
        const shelfMatch = !filters.secondLocation || 
          item.secondLocation === filters.secondLocation;
        return locationMatch && shelfMatch;
      });
  
      setInventory(filteredData);
    } catch (error) {
      console.error('Failed to fetch inventory:', error);
      setError('Failed to load inventory items');
    } finally {
      setLoading(false);
    }
  };



  const handleAddItem = async (newItem: NewInventoryItem) => {
    setLoading(true);
    try {
      const imageReferences = (newItem.images || []).map(image => {
        if (typeof image === 'string') {
          return {
            url: image,
            uploadedAt: new Date(),
            uploadedBy: '670561ef3fef62cdc14cf5c8',
            originalFilename: image.split('/').pop() || ''
          };
        }
        return image;
      });
  
      // Match the working pattern exactly
      const formData = new FormData();
      const { images, proofOfSale, ...itemData } = newItem;
      
      const dataToSend = {
        ...itemData,
        images: imageReferences
      };
  
      formData.append('data', JSON.stringify(dataToSend));
  
      const response = await axiosInstance.post('/api/Inventory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setInventory(prev => [...prev, response.data]);
      setAddItemDialogOpen(false);
      showNotification('Item added successfully', 'success');
    } catch (error) {
      console.error('Add item error:', error);
      showNotification('Failed to add item', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateItem = async (updatedItem: InventoryUpdateData) => {
    setLoading(true);
    try {
      // Clean up data before sending
      const { 
        newImages, 
        newDocuments, 
        imagesToKeep,
        ...itemData 
      } = updatedItem;
  
      // Convert image references to proper objects if they're strings
      const formattedImages = itemData.images.map(image => {
        if (typeof image === 'string') {
          return {
            url: image,
            uploadedAt: new Date(),
            uploadedBy: '670561ef3fef62cdc14cf5c8',
            originalFilename: (image as string).split('/').pop() || ''
          };
        }
        return image;
      });
  
      const dataToSend = {
        ...itemData,
        images: formattedImages
      };
  
      const formData = new FormData();
      formData.append('data', JSON.stringify(dataToSend));
  
      const response = await axiosInstance.put(
        `/api/Inventory/${updatedItem._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      setInventory(prev => 
        prev.map(item => item._id === updatedItem._id ? response.data : item)
      );
      setDetailsDialogOpen(false);
      showNotification('Item updated successfully', 'success');
  
    } catch (error: unknown) {
      console.error('Failed to update item:', error);
      if (error instanceof Error) {
        showNotification(error.message || 'Failed to update item', 'error');
      } else {
        showNotification('Failed to update item', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddMaintenance = async (itemId: string, record: Omit<MaintenanceRecord, 'id'>) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/api/Inventory/${itemId}/maintenance`, record);
      setInventory(prev =>
        prev.map(item => item._id === itemId ? response.data : item)
      );
      setMaintenanceDialogOpen(false);
      showNotification('Maintenance record added successfully', 'success');
    } catch (error) {
      console.error('Failed to add maintenance record:', error);
      showNotification('Failed to add maintenance record', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleMoveItem = async (itemId: string, newLocation: string) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/api/Inventory/${itemId}/move`, {
        newLocation,
      });
      setInventory(prev =>
        prev.map(item => item._id.toString() === itemId.toString() ? response.data : item)
      );
      showNotification('Item moved successfully', 'success');
    } catch (error) {
      console.error('Failed to move item:', error);
      showNotification('Failed to move item', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get('/api/Inventory/export', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `inventory-${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      showNotification('Export completed successfully', 'success');
    } catch (error) {
      console.error('Failed to export inventory:', error);
      showNotification('Failed to export inventory', 'error');
    }
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      await axiosInstance.post('/api/Inventory/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchInventory(); // Refresh the inventory
      showNotification('Import completed successfully', 'success');
    } catch (error) {
      console.error('Failed to import inventory:', error);
      showNotification('Failed to import inventory', 'error');
    }
  };

  // Handlers
const handleSearch = useCallback((term: string) => {
  setSearchTerm(term);
  handleFilterChange({ 
    searchTerm: term,
    searchFields: ['name', 'description', 'tags', 'serialNumber']
  });
}, []);

  const handleFilterChange: FilterChangeHandler = (newFilters) => {
    setFilters(prev => {
      const updatedFilters = { ...prev } as FilterOptions;
  
      // Handle each filter type separately to ensure type safety
      Object.entries(newFilters).forEach(([key, value]) => {
        if (value === undefined || (Array.isArray(value) && value.length === 0)) {
          delete updatedFilters[key as keyof FilterOptions];
        } else {
          (updatedFilters as any)[key] = value;
        }
      });
  
      return updatedFilters;
    });
  };



// Enhanced category selection with multiple support
const handleCategorySelect = useCallback((category: Category) => {
  setFilters(prev => {
    const currentCategories = prev.category || [];
    const updatedCategories = currentCategories.includes(category)
      ? currentCategories.filter(c => c !== category)
      : [...currentCategories, category];

    return {
      ...prev,
      category: updatedCategories,
    };
  });
}, []);

  const showNotification = (message: string, type: 'success' | 'error') => {
    setNotification({
      message,
      type,
      open: true,
    });
  };


  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`/api/Inventory/${id}`);
  
      // Update inventory state
      setInventory((prev) => prev.filter((item) => item._id !== id));
      setSelectedItem(null);
      setDetailsDialogOpen(false);
  
      showNotification('Item deleted successfully', 'success');
    } catch (error: any) {
      console.error('Error deleting item:', error);
      showNotification(
        error.response?.data?.message || 'Failed to delete item',
        'error'
      );
    }
  };

  return (
    <Box>
      {/* Header */}
      <Box sx={{ 
        bgcolor: 'background.paper', 
        borderBottom: 1, 
        borderColor: 'divider',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
      }}>
        <Box sx={{ px: 3, py: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton onClick={() => window.history.back()}>
            <ArrowLeft />
          </IconButton>
          
          <Typography variant="h6" component="h1" sx={{ flexGrow: 1 }}>
            Cooler Inventory
          </Typography>
  
          <Stack direction="row" spacing={1.5} alignItems="center">
            {/* Filter Button */}
            <Button
              variant="outlined"
              startIcon={<Filter size={18} />}
              onClick={() => setFilterDrawerOpen(true)}
              sx={{ 
                borderRadius: 'pill',
                position: 'relative'
              }}
            >
              Filters
              {Object.keys(filters).length > 0 && (
                <Chip
                  label={Object.keys(filters).length}
                  size="small"
                  color="primary"
                  sx={{ ml: 1, height: 20, minWidth: 20 }}
                />
              )}
            </Button>
  
            <input
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
              id="import-file"
              onChange={handleImport}
            />
            
            <Button 
              startIcon={<UploadIcon size={18} />}
              onClick={() => document.getElementById('import-file')?.click()}
            >
              Import
            </Button>
            
            <Button 
              startIcon={<Download size={18} />}
              onClick={handleExport}
            >
              Export
            </Button>
  
            <IconButton>
              <Settings size={18} />
            </IconButton>
          </Stack>
        </Box>
  
        {/* Search and Quick Filters */}
        <Box sx={{ px: 3, py: 2 }}>
          <TextField
            fullWidth
            placeholder="Find equipment..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
  
          {/* Active Filters Display */}
          {Object.keys(filters).length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                {Object.entries(filters).map(([key, value]) => {
                  if (!value || (Array.isArray(value) && value.length === 0)) return null;
                  const label = Array.isArray(value) 
                    ? `${key}: ${value.join(', ')}`
                    : `${key}: ${value}`;
                  return (
                    <Chip
                      key={key}
                      label={label}
                      onDelete={() => handleFilterChange({ [key]: undefined })}
                      size="small"
                    />
                  );
                })}
                {Object.keys(filters).length > 0 && (
                  <Chip
                    label="Clear All"
                    onDelete={() => handleFilterChange({})}
                    color="error"
                    size="small"
                  />
                )}
              </Stack>
            </Box>
          )}
  
          {/* Category Quick Filters */}
          <Box sx={{ display: 'flex', gap: 1, overflowX: 'auto', pb: 1 }}>
            {CATEGORIES.map((cat) => (
              <Chip
                key={cat}
                label={cat}
                onClick={() => handleCategorySelect(cat)}
                color={filters.category?.includes(cat) ? 'primary' : 'default'}
                sx={{ flexShrink: 0 }}
              />
            ))}
          </Box>
  
          {/* Quick Status Filters */}
          <Box sx={{ display: 'flex', gap: 1, mt: 2, overflowX: 'auto' }}>
            <Chip
              label="Needs Maintenance"
              size="small"
              color={filters.maintenanceStatus?.includes('due') ? 'error' : 'default'}
              onClick={() => handleFilterChange({
                maintenanceStatus: filters.maintenanceStatus?.includes('due')
                  ? filters.maintenanceStatus.filter(s => s !== 'due')
                  : [...(filters.maintenanceStatus || []), 'due']
              })}
            />
            <Chip
              label="Requires Power"
              size="small"
              color={filters.requiresPower ? 'warning' : 'default'}
              onClick={() => handleFilterChange({
                requiresPower: !filters.requiresPower
              })}
            />
            <Chip
              label="Insured"
              size="small"
              color={filters.isInsured ? 'success' : 'default'}
              onClick={() => handleFilterChange({
                isInsured: !filters.isInsured
              })}
            />
          </Box>
        </Box>
      </Box>
  
      {/* Main Content */}
      <Box sx={{ mt: 2, px: 3, pb: 6 }}>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        )}
  
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
  
        <ItemGrid
          items={inventory}
          onItemClick={(item) => {
            setSelectedItem(item);
            setDetailsDialogOpen(true);
          }}
          onMaintenanceClick={(item) => {
            setSelectedItem(item);
            setMaintenanceDialogOpen(true);
          }}
        />
      </Box>
  
      {/* Add Item FAB */}
      <Box sx={{ position: 'fixed', bottom: 100, right: 16 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddItemDialogOpen(true)}
          sx={{
            borderRadius: '28px',
            padding: '12px 24px',
            fontSize: '1.1rem',
            fontWeight: 500,
            boxShadow: (theme) => theme.shadows[8],
            textTransform: 'none',
            '&:hover': {
              boxShadow: (theme) => theme.shadows[12],
              transform: 'scale(1.02)',
            },
            transition: 'all 0.2s ease-in-out',
            backgroundColor: '#F6BE00',
            minWidth: '160px',
            height: '56px',
            gap: 1,
          }}
        >
          <Plus size={24} />
          <span>Add Item</span>
        </Button>
      </Box>
  
      {/* Dialogs */}
      <FilterDrawer
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        onApplyFilters={handleFilterChange}
      />
  
      <AddItemDialog
        open={addItemDialogOpen}
        onClose={() => setAddItemDialogOpen(false)}
        onSave={handleAddItem}
      />
  
      <MaintenanceDialog
        open={maintenanceDialogOpen}
        onClose={() => setMaintenanceDialogOpen(false)}
        item={selectedItem}
        onSave={(record) => {
          if (selectedItem) {
            handleAddMaintenance(selectedItem._id.toString(), record);
          }
        }}
      />
  
      <ItemDetailsDialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        item={selectedItem}
        onEdit={handleUpdateItem}
        onDelete={(id) => handleDelete(id)}
        onMaintenanceClick={(item) => {
          setSelectedItem(item);
          setMaintenanceDialogOpen(true);
          setDetailsDialogOpen(false);
        }}
      />
  
      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={notification.type} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CoolerInventoryManager;