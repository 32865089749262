import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress, Box } from '@mui/material';

// Define allowed roles
type Role = 'admin' | 'domain_user' | 'artist' | 'guest';

interface WithAuthProps {
  allowedRoles: Role[];
}

const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  { allowedRoles }: WithAuthProps
): React.FunctionComponent<P> => {
  const WithAuthComponent: React.FunctionComponent<P> = (props) => {
    const { user, isAuthenticated } = useAuth();
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
      // Add a small delay to ensure auth state is properly loaded
      const timer = setTimeout(() => {
        setIsChecking(false);
      }, 500);

      return () => clearTimeout(timer);
    }, [user, isAuthenticated]);

    // Show loading state while checking auth
    if (isChecking) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }

    // Not authenticated
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    // Check role
    if (user && allowedRoles.includes(user.role as Role)) {
      return <WrappedComponent {...props} />;
    }

    // If the user is authenticated but doesn't have the required role
    return <Navigate to="/unauthorized" replace />;
  };

  return WithAuthComponent;
};

export default withAuth;