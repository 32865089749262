// store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import budgetReducer from './budgetSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['artist', 'venue', 'production', 'marketing', 'otherExpenses', 'income']
};

const persistedReducer = persistReducer(persistConfig, budgetReducer);

export const store = configureStore({
  reducer: {
    budget: persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;