// ExpenseForm.tsx

import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  Button, 
  Checkbox,
  SelectChangeEvent,
  CircularProgress,
  Box,
  ListSubheader,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Divider,
  Alert,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  TextFieldProps,
  Autocomplete
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import locale for European date format
import { Camera, Upload, X as ClearIcon } from 'lucide-react';
import axios from 'axios';
import { getIMCCodeOptions } from '../constants/imcCodes';
import { EXPENSE_CATEGORIES, getRelatedIMCCodes, CATEGORY_GROUPS, ExpenseCategory } from '../constants/expenseCategories';
import { PAYMENT_METHODS, isPersonalPayment } from '../constants/paymentMethods';
import { DropdownOption, dropdownOptions } from '../constants/dropdownOptions';
import ShowRunnrCalc from './ShowRunnrCalc'; // Adjust the import path as necessary
import heic2any from "heic2any";
import PDFPreview from '../components/PDFPreview'; // Adjust the import path as necessary
  
// Add this line here
dayjs.locale('en-gb');

interface Staff {
  _id: string;
  name: string;
}

interface ExpenseFormData {
  staffId: string;
  category: string;
  amount: string;
  description: string;
  location: string;
  date: Dayjs | null; // Use Dayjs instead of Date
  paymentMethod: string;
  activityId: string;
  imcCode: string;
  vat: boolean;
  vatAmount: string;
  businessName: string; // Add this

}

const ExpenseForm: React.FC = () => {
  // Form data state
  const [formData, setFormData] = useState<ExpenseFormData>({
    staffId: '',
    category: '',
    amount: '',
    description: '',
    location: '',
    date: dayjs(), // Initialize with Dayjs object
    paymentMethod: '',
    activityId: '',
    imcCode: '',
    vat: false,
    vatAmount: '',
    businessName: '', // Add this

  });

  // File and preview states
  const [receiptPhoto, setReceiptPhoto] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  
  // Data loading states
  const [staff, setStaff] = useState<Staff[]>([]);
  const [relatedIMCCodes, setRelatedIMCCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Form submission status
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string | null;
  }>({ type: null, message: null });

  // Calculator Modal State
  const [isCalcOpen, setIsCalcOpen] = useState<boolean>(false);

  // Get IMC code options
  const imcCodeOptions = getIMCCodeOptions();

  const getGroupedCategories = () => {
    return EXPENSE_CATEGORIES.reduce((acc, category) => {
      if (!acc[category.group]) {
        acc[category.group] = [];
      }
      acc[category.group].push(category);
      return acc;
    }, {} as Record<string, ExpenseCategory[]>);
  };

  useEffect(() => {
    // Debug logging
    console.log('Current API URL:', process.env.REACT_APP_API_URL);
    console.log('Auth Token:', localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get<Staff[]>(`${process.env.REACT_APP_API_URL}/api/personnel`);
        setStaff(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching staff:', err);
        setError('Failed to fetch staff members. Please try again later.');
        setLoading(false);
      }
    };
    fetchStaff();

    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormData(prevState => ({
            ...prevState,
            location: `${position.coords.latitude}, ${position.coords.longitude}`
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, []);

  // Handle input changes for text fields and selects
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'category') {
      setRelatedIMCCodes(getRelatedIMCCodes(value));
      setFormData(prevState => ({
        ...prevState,
        imcCode: ''
      }));
    }
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  // Handle date changes
  const handleDateChange = (date: Dayjs | null) => {
    setFormData(prevState => ({ ...prevState, date }));
  };

  // Handle file input changes
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Handle different file types
      if (file.type === 'application/pdf') {
        setReceiptPhoto(file);
      } else if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
        try {
          const convertedBlob = await heic2any({ 
            blob: file, 
            toType: "image/jpeg", 
            quality: 0.85 
          });
          
          const convertedFile = new File([convertedBlob as BlobPart], file.name.replace(/\.heic$/i, '.jpg'), { type: "image/jpeg" });
          setReceiptPhoto(convertedFile);
        } catch (error) {
          console.error('Error converting HEIC:', error);
        }
      } else if (file.type.startsWith('image/')) {
        setReceiptPhoto(file);
      }
    }
  };

  // Handle Activity Select changes
  const handleActivityChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      activityId: value
    }));
  };

  // Handle IMC code changes
  const handleIMCCodeChange = (value: string) => {
    setFormData(prevState => ({
      ...prevState,
      imcCode: value
    }));
  };

  // Handle image preview
  useEffect(() => {
    if (receiptPhoto) {
      const url = URL.createObjectURL(receiptPhoto);
      setPreviewUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [receiptPhoto]);

  const handleCameraCapture = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*,.pdf';
    input.capture = 'environment';
    input.onchange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setReceiptPhoto(file);
      }
    };
    input.click();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitStatus({ type: null, message: null });
  
    const token = localStorage.getItem('token');
    console.log('Submitting with token:', token);
  
    try {
      // Validate required fields before submission
      if (!formData.staffId || !formData.amount || !formData.category || !formData.date) {
        setSubmitStatus({
          type: 'error',
          message: 'Please fill in all required fields'
        });
        return;
      }
  
      const expenseData = new FormData();
      
      // Handle each field carefully
      Object.entries(formData).forEach(([key, value]) => {
        console.log(`Processing field - ${key}:`, value);
        
        if (key === 'date' && value) {
          // Format date as DD/MM/YYYY for backend processing
          const dateStr = (value as Dayjs).format('YYYY-MM-DD');
          console.log('Formatted date for submission:', dateStr);
          expenseData.append(key, dateStr);
        } else if (key === 'amount') {
          // Ensure amount is a number
          const numAmount = parseFloat(value as string);
          if (!isNaN(numAmount)) {
            expenseData.append(key, numAmount.toString());
          }
        } else {
          expenseData.append(key, value?.toString() || '');
        }
      });
  
      if (receiptPhoto) {
        console.log('Adding receipt photo:', receiptPhoto.name);
        expenseData.append('receiptPhoto', receiptPhoto);
        expenseData.append('receiptPhotoName', receiptPhoto.name);
      }
  
      // Log all FormData entries for debugging
      for (let pair of expenseData.entries()) {
        console.log('FormData entry:', pair[0], pair[1]);
      }
  
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
        withCredentials: true
      };
  
      console.log('Sending request to:', `${process.env.REACT_APP_API_URL}/api/expenses`);
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/expenses`,
        expenseData,
        config
      );
  
      if (response.status === 201 && response.data) {
        console.log('Expense created successfully:', response.data);
        
        setSubmitStatus({
          type: 'success',
          message: 'Expense submitted successfully!'
        });
  
        // Reset form after confirmed success
        setFormData({
          staffId: '',
          category: '',
          amount: '',
          description: '',
          location: '',
          date: dayjs(),
          paymentMethod: '',
          activityId: '',
          imcCode: '',
          vat: false,
          vatAmount: '',
          businessName: '', // Add this

        });
        setReceiptPhoto(null);
        setPreviewUrl(null);
      } else {
        throw new Error('Invalid response from server');
      }
  
    } catch (error: any) {
      console.error('Error submitting expense:', error);
      console.error('Error details:', error.response?.data);
  
      const errorMessage = error.response?.data?.message || 
                          'Failed to submit expense. Please check all fields and try again.';
      
      setSubmitStatus({
        type: 'error',
        message: errorMessage
      });
    }
  };

  // Handle Calculator Submission
  const handleCalcSubmit = (value: number) => {
    setFormData(prevState => ({
      ...prevState,
      amount: value.toString()
    }));
    setIsCalcOpen(false);
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
      <CircularProgress />
    </Box>
  );

  if (error) return (
    <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Paper sx={{ p: { xs: 2, sm: 3 }, maxWidth: 600, mx: 'auto', my: 4 }}>
        <Typography variant="h5" gutterBottom>
          Submit Expense
        </Typography>
        
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* Date Section */}
            <Box>
              
              <DesktopDatePicker
                label="Date"
                value={formData.date}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
              /> 
            </Box>

            {/* Receipt Photo Section */}
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Receipt Photo
              </Typography>
              
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                border: '2px dashed',
                borderColor: 'divider',
                borderRadius: 1,
                p: 3,
                mb: 2
              }}>
                {previewUrl ? (
  receiptPhoto?.type === 'application/pdf' ? (
    <PDFPreview 
      url={previewUrl}
      onClear={() => {
        setReceiptPhoto(null);
        setPreviewUrl(null);
      }}
    />
  ) : (
    <Box sx={{ position: 'relative', width: '100%', maxWidth: 300 }}>
      <img
        src={previewUrl}
        alt="Receipt preview"
        style={{ width: '100%', borderRadius: 8 }}
      />
      <IconButton
        onClick={() => {
          setReceiptPhoto(null);
          setPreviewUrl(null);
        }}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          bgcolor: 'background.paper'
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  )
) : (
  <Box sx={{ textAlign: 'center' }}>
    <Upload size={48} strokeWidth={1} />
    <Typography variant="body2" color="text.secondary" mt={1}>
      No receipt uploaded
    </Typography>
    <Typography variant="caption" color="text.secondary">
      Supported formats: Images and PDF
    </Typography>
  </Box>
)}

<Box sx={{ display: 'flex', gap: 2 }}>
  <Button
    variant="outlined"
    startIcon={<Camera />}
    onClick={handleCameraCapture}
    disabled={!!receiptPhoto?.type?.includes('pdf')}
  >
    Take Photo
  </Button>
  <Button
    variant="outlined"
    startIcon={<Upload />}
    component="label"
  >
    Upload
    <input
      type="file"
      hidden
      accept="image/*,.pdf"
      onChange={handleFileChange}
    />
  </Button>
</Box>
              </Box>
            </Box>

            {/* Amount and VAT Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                fullWidth
                label="Amount"
                type="number"
                required
              />
              <Button variant="outlined" onClick={() => setIsCalcOpen(true)}>
                Calculate
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    name="vat"
                    checked={formData.vat}
                    onChange={handleCheckboxChange}
                  />
                }
                label="VAT"
              />
            </Box>
            
            {formData.vat && (
              <TextField
                name="vatAmount"
                value={formData.vatAmount}
                onChange={handleInputChange}
                fullWidth
                label="VAT Amount"
                type="number"
              />
            )}
            {/* Business Name Section */}
<Box>
  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
    Business/Supplier
  </Typography>
  
  <TextField
    name="businessName"
    value={formData.businessName}
    onChange={handleInputChange}
    fullWidth
    label="Business Name"
    placeholder="e.g., Lidl, Tesco, etc."
    required
    sx={{ mb: 2 }}
  />
</Box>

{/* Activity Select Section */}
<Box>
  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
    Activity Select
  </Typography>
  
  <Autocomplete
    fullWidth
    options={dropdownOptions}
    getOptionLabel={(option) => option.label}
    value={dropdownOptions.find(option => option.value === formData.activityId) || null}
    onChange={(event, newValue) => {
      setFormData(prevState => ({
        ...prevState,
        activityId: newValue?.value || ''
      }));
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Activity"
        required
        error={!formData.activityId}
      />
    )}
    sx={{ mb: 2 }}
  />
</Box>

            {/* Staff Member Section */}
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Staff Member
              </Typography>
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Staff Member</InputLabel>
                <Select
                  name="staffId"
                  value={formData.staffId}
                  onChange={handleInputChange}
                  label="Staff Member"
                  required
                >
                  {staff.map((member) => (
                    <MenuItem key={member._id} value={member._id}>
                      {member.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Category Section */}
<Box>
  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
    Category
  </Typography>
  
  <FormControl fullWidth sx={{ mb: 2 }}>
    <InputLabel>Category</InputLabel>
    <Select
      name="category"
      value={formData.category}
      onChange={handleInputChange}
      label="Category"
      required
    >
      {Object.entries(getGroupedCategories()).map(([group, categories]) => [
        <ListSubheader 
          key={group} 
          sx={{
            backgroundColor: 'background.paper',
            color: 'primary.main',
            fontWeight: 'bold',
            py: 1
          }}
        >
          {CATEGORY_GROUPS[group as keyof typeof CATEGORY_GROUPS]}
        </ListSubheader>,
        ...categories.map((category: ExpenseCategory) => (
          <MenuItem 
            key={category.name} 
            value={category.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              pl: 4
            }}
          >
            <category.icon 
              size={18} 
              color={category.color} 
            />
            <Box
              component="span"
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="body2">
                {category.name}
              </Typography>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ fontSize: '0.7rem' }}
              >
                {category.description}
              </Typography>
            </Box>
          </MenuItem>
        ))
      ]).flat()}
    </Select>
  </FormControl>
</Box>

            {/* IMC Code Section */}
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                IMC Code
              </Typography>
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>IMC Code</InputLabel>
                <Select
  name="imcCode"
  value={formData.imcCode}
  onChange={(e: SelectChangeEvent<string>) => handleInputChange(e)}
  label="IMC Code"
  required
>
  {imcCodeOptions  // Use this instead of dropdownOptions
    .filter(option => relatedIMCCodes.includes(option.value))
    .map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
</Select>
              </FormControl>
            </Box>

            {/* Description Section */}
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Description
              </Typography>
              
              <TextField
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                fullWidth
                label="Description"
                multiline
                rows={3}
                required
              />
            </Box>

            {/* Payment Method Section */}
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Payment Method
              </Typography>
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Payment Method</InputLabel>
                <Select
                  name="paymentMethod"
                  value={formData.paymentMethod}
                  onChange={handleInputChange}
                  label="Payment Method"
                  required
                >
                  {PAYMENT_METHODS.map((method) => (
                    <MenuItem key={method.id} value={method.id}>
                      {method.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Submit Status Messages */}
            {submitStatus.message && (
              <Alert severity={submitStatus.type || 'info'} sx={{ mb: 2 }}>
                {submitStatus.message}
              </Alert>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Submit Expense
            </Button>
          </Stack>
        </form>

        {/* Calculator Modal */}
        <Dialog open={isCalcOpen} onClose={() => setIsCalcOpen(false)} maxWidth="xs" fullWidth>
          <DialogTitle>ShowRunnr Calc-O-Matic</DialogTitle>
          <DialogContent>
            <ShowRunnrCalc 
              initialValue={formData.amount ? parseFloat(formData.amount) : 0} 
              onSubmit={handleCalcSubmit} 
              onClose={() => setIsCalcOpen(false)} 
            />
          </DialogContent>
        </Dialog>
      </Paper>
    </LocalizationProvider>
  );
};

export default ExpenseForm;