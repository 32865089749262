// src/components/cooler/inventory/components/MaintenanceDialog.tsx

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  Paper,
  Stack,
  Grid,
  Divider,
  Alert,
  Chip,
} from '@mui/material';
import {
  WrenchIcon,
  Calendar,
  AlertTriangle,
  CheckCircle,
  Clock,
} from 'lucide-react';

import { MAINTENANCE_TYPES } from '../constants/inventoryConstants';
import type { 
  InventoryItem, 
  MaintenanceRecord 
} from '../types/inventoryTypes';

interface MaintenanceDialogProps {
  open: boolean;
  onClose: () => void;
  item: InventoryItem | null;
  onSave: (record: Omit<MaintenanceRecord, 'id'>) => void;
}

export const MaintenanceDialog: React.FC<MaintenanceDialogProps> = ({
  open,
  onClose,
  item,
  onSave,
}) => {
  const [newRecord, setNewRecord] = useState<Omit<MaintenanceRecord, 'id'>>({
    date: new Date().toISOString().split('T')[0],
    type: 'routine',
    description: '',
    performedBy: '', // This would be populated with current user
    status: 'completed'
  });

  const getStatusChip = (status: "completed" | "scheduled" | "due" | "overdue" | "ok") => {
    console.log('Status:', status); // Debugging
    const config: { [key: string]: { color: string; icon: JSX.Element } } = {
      ok: { color: 'success', icon: <CheckCircle size={16} /> },
      due: { color: 'info', icon: <Clock size={16} /> },
      overdue: { color: 'error', icon: <AlertTriangle size={16} /> },
      completed: { color: 'success', icon: <CheckCircle size={16} /> },
    };
  
    if (!config[status]) {
      console.warn(`Invalid status: ${status}`);
      return (
        <Chip
          size="small"
          color="default"
          label="UNKNOWN"
        />
      );
    }

    return (
      <Chip
        size="small"
        icon={config[status].icon}
        color={config[status].color as any}
        label={status.toUpperCase()}
      />
    );
  };

  const handleSave = () => {
    onSave(newRecord);
    setNewRecord({
      date: new Date().toISOString().split('T')[0],
      type: 'routine',
      description: '',
      performedBy: '',
      status: 'completed'
    });
  };

  if (!item) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <WrenchIcon size={24} />
          <Typography>Maintenance Record - {item.name}</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Current Status */}
          <Paper sx={{ p: 2, bgcolor: 'background.default' }}>
            <Typography variant="subtitle2" gutterBottom>Current Status</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  Maintenance Status
                </Typography>
                {getStatusChip(item.maintenanceStatus)}
              </Grid>
              {item.nextMaintenanceDate && (
                <Grid item xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    Next Scheduled
                  </Typography>
                  <Typography>
                    {new Date(item.nextMaintenanceDate).toLocaleDateString()}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Paper>

{/* Maintenance History */}
<Typography variant="subtitle1">Maintenance History</Typography>
{item?.maintenanceRecords && item.maintenanceRecords.length > 0 ? (
  <Stack spacing={2}>
    {item.maintenanceRecords.map((record) => (
      <Paper key={record.id} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2">
              {new Date(record.date).toLocaleDateString()}
            </Typography>
            {getStatusChip(record.status)}
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="text.secondary">
              Type
            </Typography>
            <Typography>
              {record.type.charAt(0).toUpperCase() + record.type.slice(1)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="text.secondary">
              Performed By
            </Typography>
            <Typography>{record.performedBy}</Typography>
          </Grid>
          {record.description && (
            <Grid item xs={12}>
              <Typography variant="body2">
                {record.description}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    ))}
  </Stack>
) : (
  <Alert severity="info">No maintenance records found</Alert>
)}

          <Divider sx={{ my: 2 }} />

          {/* Add New Record */}
          <Typography variant="subtitle1">Add New Record</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="date"
                label="Date"
                value={newRecord.date}
                onChange={(e) => setNewRecord({ ...newRecord, date: e.target.value })}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: <Calendar size={20} className="mr-2" />
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={newRecord.type}
                  label="Type"
                  onChange={(e) => setNewRecord({
                    ...newRecord,
                    type: e.target.value as MaintenanceRecord['type']
                  })}
                >
                  {MAINTENANCE_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                value={newRecord.description}
                onChange={(e) => setNewRecord({ ...newRecord, description: e.target.value })}
                placeholder="Describe the maintenance performed..."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="date"
                label="Next Maintenance Due"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setNewRecord({
                  ...newRecord,
                  nextMaintenanceDate: e.target.value
                })}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          disabled={!newRecord.description || !newRecord.date}
        >
          Save Record
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaintenanceDialog;