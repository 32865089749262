import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Alert,
  LinearProgress,
  Button,
  Stack,
} from '@mui/material';
import {
  Download,
  Upload,
  Settings
} from 'lucide-react';
import CoolerInventoryManager from '../components/cooler/inventory/CoolerInventoryManager';
import axiosInstance from '../utils/axiosConfig';

const CoolerInventoryPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get('/api/inventory/export', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cooler-inventory-${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Failed to export inventory');
      console.error('Export error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsLoading(true);
      await axiosInstance.post('/api/inventory/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      window.location.reload();
    } catch (err) {
      setError('Failed to import inventory');
      console.error('Import error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', maxWidth: '100vw' }}>
        

      {isLoading && (
        <LinearProgress sx={{ position: 'absolute', top: '48px', left: 0, right: 0, zIndex: 1199 }} />
      )}

      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{
            position: 'absolute',
            top: '48px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1199,
            mt: 1
          }}
        >
          {error}
        </Alert>
      )}

      <Box sx={{ flex: 1, overflowY: 'auto', mt: 1 }}>
        <CoolerInventoryManager />
      </Box>
    </Box>
  );
};

export default CoolerInventoryPage;