// TitoApiTestPage.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  LinearProgress,
  useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import PastEventsAnalysis from '../components/PastEventsAnalysis';
import EventSalesChart from '../components/EventSalesChart';
import SalesInterpretation from '../components/SalesInterpretation';
import GlobalSalesComparison from '../components/GlobalSalesComparison';
import CurrentEventsSalesOverview from '../components/CurrentEventsSalesOverview';
import { getTitoPastEvents, TitoPastEventsResponse } from '../services/titoApi';
import { PastEvent } from '../types/Event';
import RecentSalesMetrics from '../components/RecentSalesMetrics';


// Ensure REACT_APP_API_URL is set in your .env file, e.g., http://localhost:5000
const API_BASE_URL = process.env.REACT_APP_API_URL;

interface TicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
}

interface Release {
  title: string;
  quantity: number;
  tickets_count: number; // from API
  price: number;
}

interface Registration {
  completed_at: string;
  tickets_count: number;
}

interface TitoEvent {
  id: string;
  title: string;
  slug: string;
  start_date: string;
  end_date: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  releases: Release[];
  registrations: Registration[];
  ticketTypes: TicketType[];
  banner_url?: string; // Optional field for banner image
}

const TitoApiTestPage: React.FC = () => {
  const [currentEvents, setCurrentEvents] = useState<TitoEvent[]>([]);
  const [pastEvents, setPastEvents] = useState<PastEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  // Define the default sales period (in days) if onSaleDate is not available
  const DEFAULT_SALES_PERIOD_DAYS = 30;

  // Fetch Event Details Function
  const fetchEventDetails = useCallback(async (eventSlug: string): Promise<TitoEvent | null> => {
    try {
      const [eventResponse, registrationsResponse, releasesResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/api/tito/events/${eventSlug}`, {
          headers: {
            'Accept': 'application/json'
          }
        }),
        axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/registrations`, {
          headers: {
            'Accept': 'application/json'
          }
        }),
        axios.get(`${API_BASE_URL}/api/tito/${eventSlug}/releases`, {
          headers: {
            'Accept': 'application/json'
          }
        })
      ]);

      console.log('Event details response:', eventResponse.data);
      console.log('Registrations response:', registrationsResponse.data);
      console.log('Releases response:', releasesResponse.data);

      // Adjust this if your API structure differs
      const eventData = eventResponse.data.event || eventResponse.data; 
      const registrations = registrationsResponse.data.registrations || [];
      const releases = releasesResponse.data.releases || [];

      if (!eventData.start_date || !eventData.end_date) {
        console.warn('Event missing start_date or end_date:', eventData);
      }

      const ticketTypes: TicketType[] = releases.map((release: Release) => ({
        name: release.title,
        quantity: release.quantity,
        quantity_sold: release.tickets_count,
        price: release.price,
        revenue: release.tickets_count * release.price
      }));

      const totalSold = ticketTypes.reduce((sum, type) => sum + type.quantity_sold, 0);
      const totalAvailable = ticketTypes.reduce((sum, type) => sum + type.quantity, 0);
      const totalRevenue = ticketTypes.reduce((sum, type) => sum + type.revenue, 0);

      return {
        id: eventData.id,
        title: eventData.title,
        slug: eventData.slug,
        start_date: eventData.start_date,
        end_date: eventData.end_date,
        totalSold,
        totalAvailable,
        totalRevenue,
        releases,
        registrations,
        ticketTypes,
        banner_url: eventData.banner_url || '' // Include banner_url if available
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data || error.message);
        setError(error.response?.data?.message || 'Axios error occurred');
      } else if (error instanceof Error) {
        console.error('Error:', error.message);
        setError(error.message);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
      return null;
    }
  }, [API_BASE_URL]);

  // Fetch Current Events
  const fetchCurrentEvents = useCallback(async () => {
    try {
      // Fetch all current events from the server
      const response = await axios.get(`${API_BASE_URL}/api/tito/events`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      console.log('Raw events response:', response.data);
      const fetchedEvents = response.data.events || [];

      console.log(`Total events fetched: ${fetchedEvents.length}`);
      const currentDate = new Date();
      const currentEventsData: TitoEvent[] = [];

      for (const evt of fetchedEvents) {
        const eventWithDetails = await fetchEventDetails(evt.slug);
        if (eventWithDetails) {
          const eventEndDate = new Date(eventWithDetails.end_date);
          if (isNaN(eventEndDate.getTime())) {
            console.warn('Invalid event end_date, skipping event:', eventWithDetails.end_date);
            // Optionally, handle as past event if needed
          } else if (eventEndDate >= currentDate) {
            currentEventsData.push(eventWithDetails);
          }
        }
      }

      setCurrentEvents(currentEventsData);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data || error.message);
        setError(error.response?.data?.message || 'Axios error occurred');
      } else if (error instanceof Error) {
        console.error('Error:', error.message);
        setError(error.message);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  }, [API_BASE_URL, fetchEventDetails]);

  // Fetch Past Events
// Fetch Past Events
const fetchPastEventsData = useCallback(async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/tito/past`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    
    console.log('Past events response:', response.data);
    
    // Make sure we're getting the correct data structure
    if (response.data && response.data.past_events) {
      const fetchedPastEvents = response.data.past_events.map((event: any) => ({
        id: event.id,
        title: event.title,
        start_date: event.start_date,
        totalSold: event.totalSold || 0,
        totalAvailable: event.totalAvailable || 0,
        totalRevenue: event.totalRevenue || 0,
        ticketTypes: event.ticketTypes || []
      }));

      console.log('Processed past events:', fetchedPastEvents);
      setPastEvents(fetchedPastEvents);
    } else {
      console.warn('Unexpected past events data structure:', response.data);
      setPastEvents([]);
    }
  } catch (error) {
    console.error('Error fetching past events:', error);
    if (axios.isAxiosError(error) && error.response?.status === 429) {
      // Handle rate limiting
      setTimeout(() => fetchPastEventsData(), 2000); // Retry after 2 seconds
      return;
    }
    setError('Failed to fetch past events data');
  }
}, [API_BASE_URL]);


  // Fetch All Events (Current, Past, and Archived)
  const fetchAllEvents = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchCurrentEvents(),
        fetchPastEventsData()
      ]);
    } catch (err) {
      console.error('Error fetching all events:', err);
      setError('Failed to fetch events');
    } finally {
      setLoading(false);
    }
  }, [fetchCurrentEvents, fetchPastEventsData]);

  useEffect(() => {
    fetchAllEvents();
  }, [fetchAllEvents]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Function to determine progress bar color based on sales performance
  const getProgressColor = (
    totalSold: number,
    totalAvailable: number,
    startDate: string,
    endDate: string
  ): 'success' | 'warning' | 'error' => {
    const currentDate = new Date();
    const eventStartDate = new Date(startDate);
    const eventEndDate = new Date(endDate);

    // Define onSaleDate as 30 days before startDate
    const onSaleDate = new Date(eventStartDate);
    onSaleDate.setDate(onSaleDate.getDate() - DEFAULT_SALES_PERIOD_DAYS);

    // Calculate total sales period in days
    const totalSalesPeriod = Math.ceil((eventStartDate.getTime() - onSaleDate.getTime()) / (1000 * 60 * 60 * 24));

    // Calculate days on sale
    const daysOnSale = Math.max(1, Math.ceil((currentDate.getTime() - onSaleDate.getTime()) / (1000 * 60 * 60 * 24)));

    // Ensure daysOnSale does not exceed totalSalesPeriod
    const effectiveDaysOnSale = Math.min(daysOnSale, totalSalesPeriod);

    // Calculate expected sales up to now
    const expectedSales = (effectiveDaysOnSale / totalSalesPeriod) * totalAvailable;

    // Calculate sales ratio
    const salesRatio = expectedSales > 0 ? totalSold / expectedSales : 0;

    if (salesRatio >= 0.9) return 'success'; // On or ahead of target
    if (salesRatio >= 0.5) return 'warning'; // Behind target
    return 'error'; // Significantly behind
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ color: 'error.main', textAlign: 'center', mt: 4 }}>
        <Alert severity="error">Error: {error}</Alert>
      </Box>
    );
  }

  console.log('Current Events:', currentEvents);

  return (
    <Box sx={{ p: 3 }}>
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Current Events" />
        <Tab label="Past Events Analysis" />
      </Tabs>

      {tabValue === 0 && (
        <>
            <RecentSalesMetrics events={currentEvents} />
            <CurrentEventsSalesOverview events={currentEvents} />
            <GlobalSalesComparison events={currentEvents} />
          <Typography variant="h4" sx={{ mb: 3 }}>Current Events</Typography>
          {currentEvents.length === 0 ? (
            <Typography>No current events available.</Typography>
          ) : (
            <>
              {currentEvents.map((event: TitoEvent, index: number) => {              
                const progressColor = getProgressColor(event.totalSold, event.totalAvailable, event.start_date, event.end_date);

                // Calculate sales percentage for the progress bar
                const salesPercentage = event.totalAvailable > 0 ? (event.totalSold / event.totalAvailable) * 100 : 0;

                return (
                  <Accordion key={event.id} sx={{ mb: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="subtitle1">{event.title}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {event.totalSold} / {event.totalAvailable} Sold
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          <LinearProgress 
                            variant="determinate" 
                            value={salesPercentage > 100 ? 100 : salesPercentage} 
                            color={progressColor}
                            sx={{ height: 10, borderRadius: 5 }}
                            aria-label={`${salesPercentage.toFixed(1)}% of tickets sold for ${event.title}`}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {salesPercentage.toFixed(1)}% Sold
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography><strong>Total Sold:</strong> {event.totalSold}</Typography>
                      <Typography><strong>Total Available:</strong> {event.totalAvailable}</Typography>
                      <Typography><strong>Total Revenue:</strong> €{event.totalRevenue.toFixed(2)}</Typography>
                      {event.ticketTypes.map((type: TicketType, typeIndex: number) => (
                        <Box key={typeIndex} sx={{ mt: 2 }}>
                          <Typography><strong>{type.name}:</strong></Typography>
                          <Typography>Sold: {type.quantity_sold} / {type.quantity}</Typography>
                          <Typography>Revenue: €{type.revenue.toFixed(2)}</Typography>
                        </Box>
                      ))}

                      {/* Add the visualization components */}
                      <Box sx={{ mt: 3 }}>
                        <EventSalesChart event={event} />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <SalesInterpretation event={event} />
                      </Box>
                    </AccordionDetails>
                    {/* After listing all events, add global comparison */}
                      <Box sx={{ mt: 2 }}>
                        <GlobalSalesComparison events={currentEvents} />
                      </Box>
            
                  </Accordion>
                );
              })}
            </>
          )}
        </>
      )}

      {tabValue === 1 && (
        <>
          {pastEvents.length === 0 ? (
            <Box sx={{ color: 'warning.main', textAlign: 'center', mt: 4 }}>
              <Alert severity="warning">
                No valid past event data available. Total events received: {pastEvents.length}. 
                Please check the data format and ensure all required fields are present.
              </Alert>
              <Typography variant="h6" sx={{ mt: 2 }}>Debug Information:</Typography>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {JSON.stringify(pastEvents, null, 2)}
              </pre>
            </Box>
          ) : (
            <PastEventsAnalysis pastEvents={pastEvents} />
          )}
        </>
      )}
    </Box>
  );
};

export default TitoApiTestPage;