import React, { useMemo } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import { Box, useTheme } from '@mui/material';
import type { InventoryItem } from '../../types/inventoryTypes';
import type { RelationshipType } from '../../types/relationshipTypes';

interface RelationshipMapProps {
  centralItem: InventoryItem;
  relatedItems: Array<{
    item: InventoryItem;
    relationType: RelationshipType;
  }>;
  onNodeClick: (itemId: string) => void;
}

interface GraphData {
  nodes: Array<{
    id: string;
    name: string;
    category: string;
    val: number;
  }>;
  links: Array<{
    source: string;
    target: string;
    type: RelationshipType;
  }>;
}

const RelationshipMap: React.FC<RelationshipMapProps> = ({
  centralItem,
  relatedItems,
  onNodeClick,
}) => {
  const theme = useTheme();

  const graphData = useMemo<GraphData>(() => {
    const nodes = [
      {
        id: centralItem._id,
        name: centralItem.name,
        category: centralItem.category,
        val: 20, // Central node is larger
      },
      ...relatedItems.map(({ item }) => ({
        id: item._id,
        name: item.name,
        category: item.category,
        val: 15,
      })),
    ];

    const links = relatedItems.map(({ item, relationType }) => ({
      source: centralItem._id,
      target: item._id,
      type: relationType,
    }));

    return { nodes, links };
  }, [centralItem, relatedItems]);

  return (
    <Box sx={{ height: '400px', bgcolor: 'background.paper', borderRadius: 1 }}>
      <ForceGraph2D
        graphData={graphData}
        nodeLabel="name"
        nodeColor={(node: any) => {
          const isCenter = node.id === centralItem._id;
          return isCenter ? theme.palette.primary.main : theme.palette.secondary.main;
        }}
        linkColor={(link: any) => {
          switch (link.type) {
            case 'required':
              return theme.palette.error.main;
            case 'optional':
              return theme.palette.warning.main;
            case 'set':
              return theme.palette.success.main;
            case 'compatible':
              return theme.palette.info.main;
            default:
              return theme.palette.text.secondary;
          }
        }}
        onNodeClick={(node: any) => onNodeClick(node.id)}
        nodeCanvasObject={(node: any, ctx, globalScale) => {
          const label = node.name;
          const fontSize = node.val / 2;
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = theme.palette.text.primary;
          ctx.fillText(label, node.x, node.y);
        }}
        linkDirectionalArrowLength={3.5}
        linkDirectionalArrowRelPos={1}
        linkCurvature={0.25}
        width={800}
        height={400}
      />
    </Box>
  );
};

export default RelationshipMap;