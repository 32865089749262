import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  Chip,
  CircularProgress,
  IconButton,
  Alert,
  Tabs,
  Tab,
  Button,
  Tooltip,
} from '@mui/material';
import {
  Search,
  Link as LinkIcon,
  Unlink,
  Info,
} from 'lucide-react';

import type { InventoryItem,RelatedItemResponse } from '../../../types/inventoryTypes';
import type { RelationshipType } from '../../../types/relationshipTypes';
import { RELATIONSHIP_DESCRIPTIONS } from '../../../types/relationshipTypes';
import RelationshipMap from '../RelationshipMap';
import RelationshipSelector from '../RelationshipSelector';
import TabPanel from './TabPanel';
import axiosInstance from '../../../../../utils/axiosConfig';

interface RelatedEquipmentProps {
  item: InventoryItem;
  isEditing: boolean;
  onUpdate: (relations: RelatedItemResponse[]) => void;
}

const RelatedEquipment: React.FC<RelatedEquipmentProps> = ({
  item,
  isEditing,
  onUpdate,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [relatedItems, setRelatedItems] = useState<Array<{
    item: InventoryItem;
    relationType: RelationshipType;
    notes?: string;
    isBidirectional?: boolean; // Add the isBidirectional property
  }>>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedForRelation, setSelectedForRelation] = useState<InventoryItem | null>(null);
  const [relationSelectorOpen, setRelationSelectorOpen] = useState(false);

  const fetchRelatedItems = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/api/Inventory/${item._id}/related`);
      console.log("Fetched related items:", response.data); // Debug log
  
      // Ensure response data is an array and correctly mapped
      const filteredItems: Array<{ item: InventoryItem; relationType: RelationshipType; notes?: string; isBidirectional?: boolean }> =
        Array.isArray(response.data)
          ? response.data
              .filter((rel: any) => rel.item) // ✅ Ensure rel.item exists before mapping
              .map((rel: any) => ({
                item: rel.item as InventoryItem,  
                relationType: rel.relationType as RelationshipType,
                notes: rel.notes ?? "", // ✅ Default to an empty string to satisfy TS
                isBidirectional: rel.isBidirectional ?? false, // ✅ Default to false if undefined
              }))
          : [];
  
      setRelatedItems(filteredItems);
      setError(null);
    } catch (err) {
      console.error('Failed to fetch related items:', err);
      setError('Failed to load related equipment');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchRelatedItems = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`/api/inventory/${item._id}/related`);
        
        // Debug log the response
        debugRelatedItems(response.data);
        
        // Ensure all required properties exist
        const validatedItems = response.data.filter((rel: any) => 
          rel.item && 
          rel.item._id && 
          rel.relationType
        ).map((rel: any) => ({
          item: {
            _id: rel.item._id,
            name: rel.item.name || 'Unnamed Item',
            category: rel.item.category || 'Uncategorized',
            images: rel.item.images || [],
            tags: rel.item.tags || []
          },
          relationType: rel.relationType,
          notes: rel.notes || '',
          isBidirectional: rel.isBidirectional || false
        }));
  
        setRelatedItems(validatedItems);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch related items:', err);
        setError('Failed to load related equipment');
      } finally {
        setLoading(false);
      }
    };
  
    fetchRelatedItems();
  }, [item._id]);

  const debugRelatedItems = (items: any[]) => {
    console.log('Related Items Debug:');
    items.forEach((item, index) => {
      console.log(`Item ${index + 1}:`, {
        id: item.item?._id,
        name: item.item?.name,
        type: item.relationType,
        isBidirectional: item.isBidirectional
      });
    });
  };
  
  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }
  
    try {
      const response = await axiosInstance.get('/api/Inventory/search', {
        params: { 
          query: term,
          exclude: [item._id, ...relatedItems.map(r => r.item._id)]
        }
      });
      setSearchResults(response.data);
    } catch (err) {
      console.error('Search failed:', err);
      setError('Failed to search for items');
    }
  };
  
  const removeRelation = async (relatedId: string) => {
    try {
      setLoading(true);
      // Find the relationship to check if it's bidirectional
      const relationship = relatedItems.find(r => r.item._id === relatedId);
      
      await axiosInstance.delete(`/api/Inventory/${item._id}/related/${relatedId}`);
      
      // If relationship was bidirectional, remove the reverse relationship too
      if (relationship?.isBidirectional) {
        await axiosInstance.delete(`/api/Inventory/${relatedId}/related/${item._id}`);
      }
      
      await fetchRelatedItems();
      setError(null);
    } catch (err) {
      console.error('Failed to remove relation:', err);
      setError('Failed to remove relationship');
    } finally {
      setLoading(false);
    }
  };
  
  // Add useEffect to fetch related items on mount
  useEffect(() => {
    fetchRelatedItems();
  }, [item._id]); // Re-fetch when item changes

  const addRelation = async (relatedItem: InventoryItem, relationshipDetails: {
    type: RelationshipType;
    isBidirectional: boolean;
    notes?: string;
  }) => {
    try {
      await axiosInstance.post(`/api/Inventory/${item._id}/related`, {
        relatedId: relatedItem._id,
        ...relationshipDetails,
      });
      
      // If bidirectional, create reverse relationship
      if (relationshipDetails.isBidirectional) {
        await axiosInstance.post(`/api/Inventory/${relatedItem._id}/related`, {
          relatedId: item._id,
          type: relationshipDetails.type,
          notes: relationshipDetails.notes,
          isBidirectional: true,
        });
      }
      
      await fetchRelatedItems();
      setSearchResults(prev => prev.filter(result => result._id !== relatedItem._id));
    } catch (err) {
      console.error('Failed to add relation:', err);
      setError('Failed to create relationship');
    }
  };

  const handleRelationClick = (selectedItem: InventoryItem) => {
    setSelectedForRelation(selectedItem);
    setRelationSelectorOpen(true);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
        <Tab label="List View" />
        <Tab label="Visual Map" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        {/* List View Content */}
        {isEditing && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Add Related Equipment
            </Typography>
            <TextField
              fullWidth
              placeholder="Search for equipment..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={20} />
                  </InputAdornment>
                ),
              }}
            />

            {searchResults.length > 0 && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {searchResults.map((result) => (
                  <Grid item xs={12} md={6} key={result._id}>
                    <Paper 
                      sx={{ 
                        p: 2, 
                        display: 'flex', 
                        alignItems: 'center',
                        bgcolor: 'background.default'
                      }}
                    >
                      {result.images?.[0] && (
                        <Box
                          component="img"
                          src={result.images[0].url}
                          alt={result.name}
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            borderRadius: 1,
                            objectFit: 'cover',
                            mr: 2 
                          }}
                        />
                      )}
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          {result.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {result.category}
                        </Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        startIcon={<LinkIcon size={20} />}
                        onClick={() => handleRelationClick(result)}
                      >
                        Add Relation
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}

        <Typography variant="h6" gutterBottom>
          Related Equipment
        </Typography>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        )
        : relatedItems.length === 0 ? (
            <Paper sx={{ p: 3, textAlign: 'center', bgcolor: 'background.default' }}>
              <Typography color="text.secondary">
                No related equipment found
              </Typography>
            </Paper>
          ) : (
            <Grid container spacing={2}>
              {relatedItems.map(({ item: related, relationType, notes }) => (
                <Grid item xs={12} md={6} key={related._id}>
                  <Paper 
                    sx={{ 
                      p: 2, 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 1,
                      bgcolor: 'background.default'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      {related.images?.[0] && (
                        <Box
                          component="img"
                          src={related.images[0].url}
                          alt={related.name}
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            borderRadius: 1,
                            objectFit: 'cover',
                            mr: 2 
                          }}
                        />
                      )}
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          {related.name}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 0.5 }}>
                          <Chip
                            size="small"
                            label={relationType}
                            color={getRelationshipColor(relationType)}
                          />
                          <Chip
                            size="small"
                            label={related.category}
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      {isEditing && (
                        <IconButton
                          size="small"
                          onClick={() => removeRelation(related._id)}
                          color="error"
                        >
                          <Unlink size={20} />
                        </IconButton>
                      )}
                    </Box>
                    
                    {notes && (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        mt: 1 
                      }}>
                        <Info size={16} />
                        <Typography variant="body2" color="text.secondary">
                          {notes}
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </TabPanel>
  
        <TabPanel value={activeTab} index={1}>
          {/* Visual Map View */}
          <RelationshipMap
            centralItem={item}
            relatedItems={relatedItems.filter(r => r.item)}  // ✅ Only pass valid items
            onNodeClick={(itemId) => {
              const selectedItem = relatedItems.find(r => r.item._id === itemId)?.item;
              if (selectedItem && isEditing) {
                handleRelationClick(selectedItem);
              }
            }}
          />
        </TabPanel>
  
        {/* Relationship Selector Dialog */}
        <RelationshipSelector
          open={relationSelectorOpen}
          onClose={() => {
            setRelationSelectorOpen(false);
            setSelectedForRelation(null);
          }}
          onSelect={(relationshipDetails) => {
            if (selectedForRelation) {
              addRelation(selectedForRelation, relationshipDetails);
              setRelationSelectorOpen(false);
              setSelectedForRelation(null);
            }
          }}
        />
  
        {error && (
          <Alert 
            severity="error" 
            sx={{ mt: 2 }}
            onClose={() => setError(null)}
          >
            {error}
          </Alert>
        )}
      </Box>
    );
  };
  
  // Helper function for relationship colors
  const getRelationshipColor = (type: RelationshipType): 'success' | 'warning' | 'error' | 'info' | 'default' => {
    switch (type) {
      case 'required':
        return 'error';
      case 'optional':
        return 'warning';
      case 'set':
        return 'success';
      case 'compatible':
        return 'info';
      default:
        return 'default';
    }
  };
  
  export default RelatedEquipment;