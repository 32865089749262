// store/selectors.ts
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';

export const selectBudgetState = (state: RootState) => {
    console.log('Full redux state:', state);
    console.log('Budget state:', state.budget);
    return state.budget;
  };
  
export const selectArtistDetails = createSelector(
  selectBudgetState,
  budget => budget.artist
);

export const selectVenueDetails = createSelector(
  selectBudgetState,
  budget => budget.venue
);

export const selectProductionDetails = createSelector(
  selectBudgetState,
  budget => budget.production
);

export const selectMarketingDetails = createSelector(
  selectBudgetState,
  budget => budget.marketing
);

export const selectOtherExpenses = createSelector(
  selectBudgetState,
  budget => budget.otherExpenses
);

export const selectIncomeDetails = createSelector(
  selectBudgetState,
  budget => budget.income
);

export const selectUI = createSelector(
  selectBudgetState,
  budget => budget.ui
);

export const selectTotalCosts = createSelector(
  [selectArtistDetails, selectVenueDetails, selectProductionDetails, 
   selectMarketingDetails, selectOtherExpenses],
  (artist, venue, production, marketing, otherExpenses) => {
    let total = 0;
    
    if (artist?.costs) {
      total += Object.values(artist.costs).reduce((sum, item) => sum + (item.amount || 0), 0);
    }
    
    if (venue?.costs) {
      total += Object.values(venue.costs).reduce((sum, item) => sum + (item.amount || 0), 0);
    }
    
    if (production?.costs) {
      Object.values(production.costs).forEach(costArray => {
        total += costArray.reduce((sum, item) => sum + (item.amount || 0), 0);
      });
    }
    
    if (marketing?.costs) {
      total += Object.values(marketing.costs).reduce((sum, item) => sum + (item.amount || 0), 0);
    }
    
    if (otherExpenses?.costs) {
      Object.values(otherExpenses.costs).forEach(costArray => {
        if (Array.isArray(costArray)) {
          total += costArray.reduce((sum, item) => sum + (item.amount || 0), 0);
        }
      });
    }
    
    return total;
  }
);

export const selectTotalIncome = createSelector(
  selectIncomeDetails,
  income => {
    if (!income) return 0;
    
    const ticketingTotal = income.ticketing?.totalProjected || 0;
    const fundingTotal = income.funding?.sources?.reduce((sum, source) => 
      sum + (source.amount || 0), 0) || 0;
      
    return ticketingTotal + fundingTotal;
  }
);