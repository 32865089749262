import React, { useState, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Container,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { ListTodo, LayoutGrid, Plus, GroupIcon, UserIcon, TagIcon, AlertTriangleIcon } from 'lucide-react';
import ListView from '../../components/cooler/taskboard/views/ListView';
import KanbanView from '../../components/cooler/taskboard/views/KanbanView';
import TaskBoardFilters from '../../components/cooler/taskboard/core/TaskBoardFilters';
import { useTaskFilters } from '../../components/cooler/taskboard/hooks/useTaskFilters';
import AddTaskDialog from '../../components/cooler/taskboard/dialogs/AddTaskDialog';
import EditTaskDialog from '../../components/cooler/taskboard/dialogs/EditTaskDialog';
import DeleteConfirmDialog from '../../components/cooler/taskboard/dialogs/DeleteConfirmDialog';
import { Task, TaskStatus } from '../../components/cooler/taskboard/types/taskTypes';
import axiosInstance from '../../utils/axiosConfig';
import { Personnel } from '../../types/Personnel';

// Define grouping types
type GroupBy = 'none' | 'category' | 'assignedTo' | 'priority' | 'status';

const TaskBoardPage: React.FC = () => {
  // View state
  const [currentView, setCurrentView] = useState<'list' | 'kanban'>('list');
  const [groupBy, setGroupBy] = useState<GroupBy>('none');
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());
  
  // Task state
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Use the task filters hook which includes personnel management
  const {
    filters,
    filteredTasks,
    personnel,
    isLoadingPersonnel,
    activeFilterCount,
    handleFilterChange,
    handleClearFilters,
  } = useTaskFilters(tasks);
  
  // Dialog states
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [deletingTaskId, setDeletingTaskId] = useState<string | null>(null);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/api/taskboards');
      setTasks(response.data.tasks);
      setError(null);
    } catch (err) {
      console.error('Failed to fetch tasks:', err);
      setError('Failed to load tasks');
    } finally {
      setLoading(false);
    }
  };

  const toggleGroupExpansion = (groupKey: string) => {
    const newExpanded = new Set(expandedGroups);
    if (newExpanded.has(groupKey)) {
      newExpanded.delete(groupKey);
    } else {
      newExpanded.add(groupKey);
    }
    setExpandedGroups(newExpanded);
  };

  const handleTaskMove = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    try {
      const newTasks = Array.from(tasks);
      const [movedTask] = newTasks.splice(source.index, 1);
      
      if (currentView === 'kanban') {
        let newStatus: TaskStatus;
        
        if (groupBy === 'none') {
          newStatus = destination.droppableId as TaskStatus;
        } else {
          // Extract status from combined droppableId (e.g., "category-todo")
          newStatus = destination.droppableId.split('-')[1] as TaskStatus;
        }
        
        movedTask.status = newStatus;
      }
      
      newTasks.splice(destination.index, 0, movedTask);
      setTasks(newTasks);
      
      await axiosInstance.patch(`/api/taskboards/${draggableId}`, {
        status: movedTask.status,
        order: destination.index
      });
    } catch (err) {
      console.error('Failed to move task:', err);
      fetchTasks();
    }
  };

  const handleEditTask = (taskId: string) => {
    const taskToEdit = tasks.find(t => t.id === taskId);
    if (taskToEdit) {
      setEditingTask(taskToEdit);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await axiosInstance.delete(`/api/taskboards/${taskId}`);
      setTasks(tasks.filter(task => task.id !== taskId));
      setError(null);
    } catch (err) {
      console.error('Failed to delete task:', err);
      setError('Failed to delete task');
    }
  };

  // Group tasks based on selected grouping
  const groupedTasks = React.useMemo(() => {
    if (groupBy === 'none') {
      return { all: filteredTasks };
    }

    return filteredTasks.reduce((groups: Record<string, Task[]>, task) => {
      let groupKey = 'Unspecified';

      switch (groupBy) {
        case 'category':
          groupKey = task.category 
            ? task.category
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            : 'Uncategorized';
          break;
        case 'assignedTo':
          if (typeof task.assignedTo === 'string') {
            const person = personnel.find(p => p.id === task.assignedTo || p._id === task.assignedTo);
            groupKey = person ? person.name : 'Unassigned';
          } else {
            groupKey = task.assignedTo?.name || 'Unassigned';
          }
          break;
        case 'priority':
          groupKey = task.priority.charAt(0).toUpperCase() + task.priority.slice(1);
          break;
        case 'status':
          groupKey = task.status.replace('_', ' ').charAt(0).toUpperCase() + task.status.slice(1);
          break;
      }

      if (!groups[groupKey]) {
        groups[groupKey] = [];
      }
      groups[groupKey].push(task);
      return groups;
    }, {});
  }, [filteredTasks, groupBy, personnel]);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Header */}
      <Box 
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'stretch', md: 'center' },
          gap: 2,
          mb: 4
        }}
      >
        <Box>
          <Typography variant="h4" gutterBottom>
            Cooler Task Board
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and track venue tasks, maintenance, and improvements
          </Typography>
        </Box>

        <Stack direction="row" spacing={2} alignItems="center">
          {/* View Toggle */}
          <Box sx={{ borderRadius: 1, bgcolor: 'background.paper', p: 0.5 }}>
            <Button
              size="small"
              variant={currentView === 'list' ? 'contained' : 'text'}
              onClick={() => setCurrentView('list')}
              startIcon={<ListTodo size={18} />}
            >
              List
            </Button>
            <Button
              size="small"
              variant={currentView === 'kanban' ? 'contained' : 'text'}
              onClick={() => setCurrentView('kanban')}
              startIcon={<LayoutGrid size={18} />}
            >
              Kanban
            </Button>
          </Box>

          {/* Group By Controls */}
          <ToggleButtonGroup
            value={groupBy}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                setGroupBy(value);
                setExpandedGroups(new Set());
              }
            }}
            size="small"
          >
            <ToggleButton value="none" aria-label="no grouping">
              <GroupIcon size={18} />
            </ToggleButton>
            <ToggleButton value="category" aria-label="group by category">
              <TagIcon size={18} />
            </ToggleButton>
            <ToggleButton value="assignedTo" aria-label="group by assignee">
              <UserIcon size={18} />
            </ToggleButton>
            <ToggleButton value="priority" aria-label="group by priority">
              <AlertTriangleIcon size={18} />
            </ToggleButton>
          </ToggleButtonGroup>

          <Button
            variant="contained"
            startIcon={<Plus size={18} />}
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add Task
          </Button>
        </Stack>
      </Box>

      {/* Filters */}
      <TaskBoardFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onClearFilters={handleClearFilters}
        personnel={personnel}
        isLoading={isLoadingPersonnel}
        activeFilterCount={activeFilterCount}
      />

      {/* Error Display */}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {/* Main Content */}
      {loading ? (
        <Box sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box sx={{ mt: 4 }}>
          <DragDropContext onDragEnd={handleTaskMove}>
            {currentView === 'list' ? (
              <ListView
                tasks={filteredTasks}
                groupBy={groupBy}
                groupedTasks={groupedTasks}
                expandedGroups={expandedGroups}
                toggleGroupExpansion={toggleGroupExpansion}
                onTaskMove={handleTaskMove}
                onEditTask={handleEditTask}
                onDeleteTask={(taskId) => setDeletingTaskId(taskId)}
                onRefresh={fetchTasks}
              />
            ) : (
              <KanbanView
                tasks={filteredTasks}
                groupBy={groupBy}
                groupedTasks={groupedTasks}
                expandedGroups={expandedGroups}
                toggleGroupExpansion={toggleGroupExpansion}
                onTaskMove={handleTaskMove}
                onEditTask={handleEditTask}
                onDeleteTask={(taskId) => setDeletingTaskId(taskId)}
                onRefresh={fetchTasks}
              />
            )}
          </DragDropContext>
        </Box>
      )}

      {/* Empty State */}
      {filteredTasks.length === 0 && !loading && (
        <Box 
          sx={{ 
            textAlign: 'center',
            py: 8,
            bgcolor: 'background.paper',
            borderRadius: 1,
            mt: 4
          }}
        >
          <Typography color="text.secondary">
            {activeFilterCount > 0 
              ? 'No tasks match your current filters'
              : 'No tasks found. Create a new task to get started.'}
          </Typography>
          {activeFilterCount > 0 && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleClearFilters}
              sx={{ mt: 2 }}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      )}

      {/* Dialogs */}
      <AddTaskDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onRefresh={fetchTasks}
      />
      {editingTask && (
        <EditTaskDialog
          open={!!editingTask}
          task={editingTask}
          onClose={() => setEditingTask(null)}
          onRefresh={fetchTasks}
        />
      )}
      {deletingTaskId && (
        <DeleteConfirmDialog
          open={!!deletingTaskId}
          taskTitle={tasks.find(t => t.id === deletingTaskId)?.title || ''}
          onClose={() => setDeletingTaskId(null)}
          onConfirm={() => {
            if (deletingTaskId) {
              handleDeleteTask(deletingTaskId);
              setDeletingTaskId(null);
            }
          }}
          isDeleting={false}
        />
      )}
    </Container>
  );
};

export default TaskBoardPage;