import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Radio,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
} from '@mui/material';
import {
  Link2,
  Link,
  PackagePlus,
  Replace,
  Plug, // Add this import
} from 'lucide-react';
import { RELATIONSHIP_TYPES, RELATIONSHIP_DESCRIPTIONS, type RelationshipType } from '../../types/relationshipTypes';

interface RelationshipSelectorProps {
  open: boolean;
  onClose: () => void;
  onSelect: (relationship: {
    type: RelationshipType;
    isBidirectional: boolean;
    notes?: string;
  }) => void;
}

const RELATIONSHIP_ICONS = {
  [RELATIONSHIP_TYPES.REQUIRED]: Link2,
  [RELATIONSHIP_TYPES.OPTIONAL]: Plug,
  [RELATIONSHIP_TYPES.SET]: PackagePlus,
  [RELATIONSHIP_TYPES.COMPATIBLE]: Link,
  [RELATIONSHIP_TYPES.REPLACEMENT]: Replace,
};

const RelationshipSelector: React.FC<RelationshipSelectorProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [selectedType, setSelectedType] = React.useState<RelationshipType>(RELATIONSHIP_TYPES.REQUIRED);
  const [isBidirectional, setIsBidirectional] = React.useState(true);
  const [notes, setNotes] = React.useState('');

  const handleConfirm = () => {
    onSelect({
      type: selectedType,
      isBidirectional,
      notes: notes.trim() || undefined,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Relationship Type</DialogTitle>
      <DialogContent>
        <List>
          {Object.entries(RELATIONSHIP_TYPES).map(([key, value]) => {
            const Icon = RELATIONSHIP_ICONS[value];
            return (
              <ListItem
                key={key}
                button
                onClick={() => setSelectedType(value)}
                selected={selectedType === value}
              >
                <ListItemIcon>
                  <Radio checked={selectedType === value} />
                </ListItemIcon>
                <Icon size={20} style={{ marginRight: 8 }} />
                <ListItemText
                  primary={key.charAt(0) + key.slice(1).toLowerCase()}
                  secondary={RELATIONSHIP_DESCRIPTIONS[value]}
                />
              </ListItem>
            );
          })}
        </List>

        <FormControlLabel
          control={
            <Switch
              checked={isBidirectional}
              onChange={(e) => setIsBidirectional(e.target.checked)}
            />
          }
          label="Bidirectional Relationship"
        />

        <TextField
          fullWidth
          multiline
          rows={2}
          label="Relationship Notes (Optional)"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm Relationship
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RelationshipSelector;