// src/components/cooler/utils/locationHelpers.ts
import { LOCATIONS, type Location } from '../constants/inventoryConstants';

// Locations that have shelves
const SHELF_LOCATIONS = [
  'Main Press Left',
  'Main Press Right',
  'Outdoor Press',
  'Elton Case Left',
  'Elton Case Right',
  'Shelves Beside Door',
] as const;

// Locations that have drawers
const DRAWER_LOCATIONS = [
  'Sound Desk',
  'Videography Desk',
  'Venue Rack',
  'Rehearsal Rack',
  'Sound Desk Drawers',
  'Videography Desk Drawers',
] as const;

// Locations that don't have second locations
const NO_SECOND_LOCATIONS = [
  'Cooler Venue',
  'Cooler Stage',
  'Cooler Bar',
] as const;

// Does this primary location have shelves or drawers?
export const hasSecondLocation = (primaryLocation: Location): boolean => {
  return [...SHELF_LOCATIONS, ...DRAWER_LOCATIONS].includes(primaryLocation as any) &&
         !NO_SECOND_LOCATIONS.includes(primaryLocation as any);
};

// Get the available shelves/drawers for this primary location
export const getSecondLocations = (primaryLocation: Location): string[] => {
  if (SHELF_LOCATIONS.includes(primaryLocation as any)) {
    return [
      'Shelf 1', 'Shelf 2', 'Shelf 3', 'Shelf 4', 'Shelf 5',
      'Shelf 6', 'Shelf 7', 'Shelf 8', 'Shelf 9', 'Shelf 10'
    ];
  }
  if (DRAWER_LOCATIONS.includes(primaryLocation as any)) {
    return [
      'Drawer 1', 'Drawer 2', 'Drawer 3', 'Drawer 4', 'Drawer 5'
    ];
  }
  return [];
};

// Get valid alternative locations (excluding the primary location)
export const getValidAlternateLocations = (primaryLocation: Location): Location[] => {
  return LOCATIONS.filter(loc => loc !== primaryLocation);
};