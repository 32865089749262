// components/PersonnelDetail.tsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

// Adjust your Personnel type here if needed (for multiple roles).
// E.g. if you have an enum, you'd do `role: PersonnelRole[];`
interface Personnel {
  _id: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];   // now an array
  skillLevel: string;
  experience: number;
  profileImage?: string;
  bio?: string;
  availability?: {
    from: string | Date;
    to: string | Date;
  };
fee: string;
}

interface PersonnelDetailProps {
  id: string;
}

const PersonnelDetail: React.FC<PersonnelDetailProps> = ({ id }) => {
  const [person, setPerson] = useState<Personnel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPerson = async () => {
      try {
        const response = await axios.get<Personnel>(
          `${process.env.REACT_APP_API_URL}/api/personnel/${id}`
        );
        setPerson(response.data);
      } catch (err) {
        console.error('Error fetching personnel details:', err);
        setError('Failed to fetch personnel details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchPerson();
  }, [id]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!person) return <Typography>No personnel found.</Typography>;

  // Join the roles array into a comma-separated string
  const rolesDisplay = person.roles && person.roles.length > 0
    ? person.roles.join(', ')
    : 'No role(s)';

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar src={person.profileImage} alt={person.name} />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="h5" component="div">
                  {person.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {rolesDisplay} | {person.skillLevel}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Experience: {person.experience} years
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="body1" component="p" mt={2}>
          {person.bio}
        </Typography>

        <Typography variant="body2" color="text.secondary" mt={2}>
          Email: {person.email}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Phone: {person.phone}
        </Typography>

        {person.availability && (
          <Typography variant="body2" color="text.secondary" mt={2}>
            Available from: {new Date(person.availability.from).toLocaleDateString()} 
            {' '}to: {new Date(person.availability.to).toLocaleDateString()}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PersonnelDetail;