import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { FileText, X as ClearIcon } from 'lucide-react';

interface PDFPreviewProps {
  url: string;
  onClear: () => void;
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ url, onClear }) => {
  return (
    <Box sx={{ 
      position: 'relative',
      width: '100%',
      maxWidth: 300,
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      p: 2,
      display: 'flex',
      alignItems: 'center',
      gap: 2
    }}>
      <FileText size={32} />
      <Box sx={{ flex: 1 }}>
        <Typography variant="body2" noWrap>
          PDF Document
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Click to open
        </Typography>
      </Box>
      <IconButton
        onClick={onClear}
        size="small"
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <ClearIcon size={16} />
      </IconButton>
    </Box>
  );
};

export default PDFPreview;