import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  IconButton,
  Stack,
  Alert,
} from '@mui/material';
import {
  Upload as UploadIcon,
  X as CloseIcon,
  Image as ImageIcon,
} from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';

interface UploadProps {
  onUploadComplete: (urls: string[]) => void;
  maxFiles?: number;
  accept?: string;
  maxSize?: number;
  type?: 'images' | 'equipmentDocs';
}

interface FileUpload {
  file: File;
  progress: number;
  error?: string;
  url?: string;
}

export const Upload: React.FC<UploadProps> = ({
  onUploadComplete,
  maxFiles = 5,
  accept = 'image/*',
  maxSize = 5 * 1024 * 1024,
  type = 'images'
}) => {
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('equipmentImages', file);
  
    try {
      console.log('Sending file to upload:', file.name);
      const response = await axiosInstance.post('/api/Inventory/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = progressEvent.loaded / progressEvent.total! * 100;
          setUploads(prevUploads => 
            prevUploads.map(upload => 
              upload.file === file 
                ? { ...upload, progress } 
                : upload
            )
          );
        },
      });
  
      console.log('Upload successful:', response.data);
  
      if (response.data.urls) {
        // Update local state with url
        setUploads(prevUploads =>
          prevUploads.map(upload =>
            upload.file === file
              ? { ...upload, url: response.data.urls[0] }
              : upload
          )
        );
  
        // Pass back just the URLs as before
        onUploadComplete(response.data.urls);
      }
    } catch (err: any) {
      console.error('Upload error details:', {
        error: err,
        response: err.response?.data,
        request: err.request,
        message: err.message
      });
  
      setUploads(prevUploads =>
        prevUploads.map(upload =>
          upload.file === file
            ? { ...upload, error: 'Upload failed' }
            : upload
        )
      );
      setError('Upload failed. Please try again.');
    }
  };
  
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setError(null);
  
    if (files.length + uploads.length > maxFiles) {
      setError(`Maximum ${maxFiles} files allowed`);
      return;
    }
  
    const newUploads = files.map(file => {
      if (file.size > maxSize) {
        setError(`File ${file.name} exceeds maximum size of ${maxSize / 1024 / 1024}MB`);
        return null;
      }
      return {
        file,
        progress: 0,
      };
    }).filter((upload): upload is FileUpload => upload !== null);
  
    setUploads([...uploads, ...newUploads]);
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  
    newUploads.forEach(upload => handleUpload(upload.file));
  };

  const removeUpload = (fileToRemove: File) => {
    setUploads(uploads.filter(upload => upload.file !== fileToRemove));
  };

  return (
    <Box>
      <input
        type="file"
        ref={fileInputRef}
        accept={accept}
        multiple={maxFiles > 1}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
      />

      <Button
        variant="outlined"
        startIcon={<UploadIcon />}
        onClick={() => fileInputRef.current?.click()}
        disabled={uploads.length >= maxFiles}
        fullWidth
        sx={{ mb: 2 }}
      >
        Select {type === 'images' ? 'Images' : 'Documents'}
      </Button>

      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Stack spacing={2}>
        {uploads.map((upload, index) => (
          <Box
            key={upload.file.name + index}
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              p: 2,
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <ImageIcon size={24} />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body2" noWrap>
                  {upload.file.name}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={upload.progress}
                  sx={{ mt: 1 }}
                />
                {upload.url && (
                  <Typography variant="caption" color="success.main">
                    Upload complete
                  </Typography>
                )}
              </Box>
              <IconButton 
                size="small" 
                onClick={() => removeUpload(upload.file)}
                disabled={upload.progress > 0 && upload.progress < 100}
              >
                <CloseIcon size={18} />
              </IconButton>
            </Stack>
            {upload.error && (
              <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                {upload.error}
              </Typography>
            )}
          </Box>
        ))}
      </Stack>

      <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
        {uploads.length} of {maxFiles} files selected
        {maxSize && ` (Max size: ${maxSize / 1024 / 1024}MB per file)`}
      </Typography>
    </Box>
  );
};

export default Upload;