// src/components/budgets/sections/MarketingPlanner.tsx
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Paper,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  IconButton,
  Modal
} from '@mui/material';
import { MarketingDetails, BudgetItem } from '../../../types/Budget';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import ShowRunnrCalc from '../../../components/ShowRunnrCalc';
import CalculateIcon from '@mui/icons-material/Calculate';
import { RootState } from '../../../store/store';
import { updateMarketingDetails } from '../../../store/budgetSlice';

const PRESET_COSTS: Record<string, number> = {
  facebook: 20,
  instagram: 20,
  twitter: 20,
  posters: 50,
  flyers: 30,
  programs: 40
};

const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : Number(parsed.toFixed(2));
  }
  return isFinite(value) ? Number(value.toFixed(2)) : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const DEFAULT_MARKETING_DETAILS: MarketingDetails = {
  channels: [],
  materials: [],
  timeline: [],
  costs: {
    design: {
      code: IMC_EXPENSE_CODES.MARKETING_DESIGN.code,
      label: IMC_EXPENSE_CODES.MARKETING_DESIGN.label,
      amount: 0,
      isEstimate: true,
      status: 'draft',
      category: 'expense'
    },
    advertising: {
      code: IMC_EXPENSE_CODES.MARKETING_PR_ADVERTISING.code,
      label: IMC_EXPENSE_CODES.MARKETING_PR_ADVERTISING.label,
      amount: 0,
      isEstimate: true,
      status: 'draft',
      category: 'expense'
    },
    printing: {
      code: IMC_EXPENSE_CODES.MARKETING_PRINT_MATERIALS.code,
      label: IMC_EXPENSE_CODES.MARKETING_PRINT_MATERIALS.label,
      amount: 0,
      isEstimate: true,
      status: 'draft',
      category: 'expense'
    },
    distribution: {
      code: IMC_EXPENSE_CODES.MARKETING_POSTER_FLYER_DISTRIBUTION.code,
      label: IMC_EXPENSE_CODES.MARKETING_POSTER_FLYER_DISTRIBUTION.label,
      amount: 0,
      isEstimate: true,
      status: 'draft',
      category: 'expense'
    }
  }
};

const COST_FIELDS = {
  design: {
    code: IMC_EXPENSE_CODES.MARKETING_DESIGN.code,
    label: 'Design',
    fullLabel: IMC_EXPENSE_CODES.MARKETING_DESIGN.label
  },
  advertising: {
    code: IMC_EXPENSE_CODES.MARKETING_PR_ADVERTISING.code,
    label: 'PR & Advertising',
    fullLabel: IMC_EXPENSE_CODES.MARKETING_PR_ADVERTISING.label
  },
  printing: {
    code: IMC_EXPENSE_CODES.MARKETING_PRINT_MATERIALS.code,
    label: 'Print Materials',
    fullLabel: IMC_EXPENSE_CODES.MARKETING_PRINT_MATERIALS.label
  },
  distribution: {
    code: IMC_EXPENSE_CODES.MARKETING_POSTER_FLYER_DISTRIBUTION.code,
    label: 'Distribution',
    fullLabel: IMC_EXPENSE_CODES.MARKETING_POSTER_FLYER_DISTRIBUTION.label
  }
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

const MarketingPlanner: React.FC = () => {
  const dispatch = useDispatch();
  const marketingDetails = useSelector((state: RootState) => 
    state.budget.marketing || DEFAULT_MARKETING_DETAILS
  );

  const [activeCalculator, setActiveCalculator] = useState<keyof MarketingDetails['costs'] | null>(null);
  const [localDetails, setLocalDetails] = useState<MarketingDetails>(marketingDetails);

  const debouncedDispatch = useCallback(
    debounce((updateData: MarketingDetails) => {
      dispatch(updateMarketingDetails(updateData));
    }, 300),
    []
  );

  useEffect(() => {
    if (JSON.stringify(marketingDetails) !== JSON.stringify(localDetails)) {
      debouncedDispatch(localDetails);
    }
  }, [localDetails, marketingDetails, debouncedDispatch]);

  const handleCostChange = (field: keyof MarketingDetails['costs'], value: string) => {
    const amount = safeNumber(value);
    setLocalDetails(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        [field]: {
          ...prev.costs[field],
          amount
        }
      }
    }));
  };

  const handleChannelChange = useCallback((channelKey: string, isMaterial: boolean = false) => {
    setLocalDetails(prev => {
      const currentList = isMaterial ? prev.materials : prev.channels;
      const isChecked = currentList.includes(channelKey);
      const newList = isChecked
        ? currentList.filter(item => item !== channelKey)
        : [...currentList, channelKey];

      const preset = safeNumber(PRESET_COSTS[channelKey]);
      const currentAdvertising = safeNumber(prev.costs.advertising.amount);
      const newAdvertising = isChecked
        ? currentAdvertising - preset
        : currentAdvertising + preset;

      return {
        ...prev,
        channels: isMaterial ? prev.channels : newList,
        materials: isMaterial ? newList : prev.materials,
        costs: {
          ...prev.costs,
          advertising: {
            ...prev.costs.advertising,
            amount: newAdvertising
          }
        }
      };
    });
  }, []);

  const handleCalculatorSubmit = useCallback((value: number) => {
    if (activeCalculator) {
      handleCostChange(activeCalculator, String(value));
      setActiveCalculator(null);
    }
  }, [activeCalculator]);

  const calculateTotalCost = useCallback((): number => {
    return Object.values(localDetails.costs).reduce(
      (sum, costItem) => sum + safeNumber(costItem.amount),
      0
    );
  }, [localDetails.costs]);

  const openCalculator = useCallback((field: keyof MarketingDetails['costs']) => {
    setActiveCalculator(field);
  }, []);

  // Return JSX
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Marketing Plan
      </Typography>

      <Grid container spacing={3}>
        {/* Marketing Channels Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Marketing Channels
            </Typography>
            <Grid container spacing={3}>
              {/* Social Media Section */}
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Social Media ({formatCurrency(20)} each)
                </Typography>
                <FormGroup>
                  {['facebook', 'instagram', 'twitter'].map(channel => (
                    <FormControlLabel
                      key={channel}
                      control={
                        <Checkbox
                          checked={localDetails.channels.includes(channel)}
                          onChange={() => handleChannelChange(channel, false)}
                        />
                      }
                      label={channel.charAt(0).toUpperCase() + channel.slice(1)}
                    />
                  ))}
                </FormGroup>
              </Grid>

              {/* Print Materials Section */}
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Print Materials
                </Typography>
                <FormGroup>
                  {[
                    ['posters', 'Posters', 50],
                    ['flyers', 'Flyers', 30],
                    ['programs', 'Programs', 40]
                  ].map(([key, label, cost]) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          checked={localDetails.materials.includes(String(key))}
                          onChange={() => handleChannelChange(String(key), true)}
                        />
                      }
                      label={`${label} (${formatCurrency(cost as number)})`}
                    />
                  ))}
                </FormGroup>
              </Grid>

              {/* Digital Marketing Section */}
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  Digital Marketing
                </Typography>
                <FormGroup>
                  {[
                    'emailMarketing',
                    'googleAds',
                    'contentMarketing'
                  ].map(channel => (
                    <FormControlLabel
                      key={channel}
                      control={
                        <Checkbox
                          checked={localDetails.channels.includes(channel)}
                          onChange={() => handleChannelChange(channel, false)}
                        />
                      }
                      label={channel
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, str => str.toUpperCase())}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Marketing Costs Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Marketing Costs
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(COST_FIELDS).map(([key, field]) => (
                <Grid item xs={12} md={6} key={key}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <TextField
                      fullWidth
                      label={field.label}
                      type="number"
                      value={localDetails.costs[key as keyof MarketingDetails['costs']].amount || ''}
                      onChange={(e) => handleCostChange(key as keyof MarketingDetails['costs'], e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      inputProps={{
                        min: 0,
                        step: "0.01"
                      }}
                    />
                    <IconButton
                      onClick={() => openCalculator(key as keyof MarketingDetails['costs'])}
                      color="primary"
                      sx={{ mt: 1 }}
                    >
                      <CalculateIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Total Cost Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Total Marketing Cost: {formatCurrency(calculateTotalCost())}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Calculator Modal */}
      <Modal
        open={activeCalculator !== null}
        onClose={() => setActiveCalculator(null)}
        aria-labelledby="calculator-modal"
      >
        <Box sx={modalStyle}>
          {activeCalculator && (
            <ShowRunnrCalc
              initialValue={localDetails.costs[activeCalculator].amount}
              onSubmit={handleCalculatorSubmit}
              onClose={() => setActiveCalculator(null)}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default MarketingPlanner;