import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

interface CategoryTotal {
  name: string;
  amount: number;
  percentage: number;
}

const BudgetSummary: React.FC = () => {
    const expenseGroups = useSelector((state: RootState) => {
      console.log('Budget Summary - Expense Groups:', state.budget.expenseGroups);
      return state.budget.expenseGroups || {};
    });
    const incomeGroups = useSelector((state: RootState) => {
      console.log('Budget Summary - Income Groups:', state.budget.incomeGroups);
      return state.budget.incomeGroups || {};
    });
  
    // NEW - Add totals selector
    const totalsFromRedux = useSelector((state: RootState) => {
      console.log('Budget Summary - Totals:', state.budget.totals);
      return state.budget.totals;
    });


  if (Object.keys(expenseGroups).length === 0 && Object.keys(incomeGroups).length === 0) {
    return <Alert severity="info">No budget data available. Start adding details to see the summary.</Alert>;
  }

  const calculateCategories = (groups: Record<string, any>) => {
    const total = Object.values(groups).reduce((sum, group) => sum + (group.total || 0), 0);

    return Object.entries(groups).map(([name, group]) => ({
      name,
      amount: group.total || 0,
      percentage: total > 0 ? ((group.total || 0) / total) * 100 : 0,
    }));
  };

  const expenseCategories = calculateCategories(expenseGroups);
  const incomeCategories = calculateCategories(incomeGroups);

  const totals = {
    totalExpenses: Object.values(expenseGroups).reduce((sum, group) => sum + (group.total || 0), 0),
    totalIncome: Object.values(incomeGroups).reduce((sum, group) => sum + (group.total || 0), 0),
  };

  const profitLoss = totals.totalIncome - totals.totalExpenses;
  const breakEvenProgress =
    totals.totalExpenses > 0 ? (totals.totalIncome / totals.totalExpenses) * 100 : 0;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Budget Summary
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
            <Typography variant="subtitle2">Total Income</Typography>
            <Typography variant="h4">{formatCurrency(totals.totalIncome)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, bgcolor: 'error.light', color: 'error.contrastText' }}>
            <Typography variant="subtitle2">Total Expenses</Typography>
            <Typography variant="h4">{formatCurrency(totals.totalExpenses)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, bgcolor: profitLoss >= 0 ? 'success.light' : 'error.light', color: 'white' }}>
            <Typography variant="subtitle2">Profit/Loss</Typography>
            <Typography variant="h4">{formatCurrency(profitLoss)}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Break-even Progress
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={Math.min(breakEvenProgress, 100)}
              color={breakEvenProgress >= 100 ? 'success' : 'warning'}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              {breakEvenProgress.toFixed(1)}%
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Expense Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">% of Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expenseCategories.map((category) => (
                    <TableRow key={category.name}>
                      <TableCell>{category.name.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                      <TableCell align="right">{formatCurrency(category.amount)}</TableCell>
                      <TableCell align="right">{category.percentage.toFixed(1)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Income Breakdown
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Source</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">% of Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {incomeCategories.map((category) => (
                    <TableRow key={category.name}>
                      <TableCell>{category.name.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                      <TableCell align="right">{formatCurrency(category.amount)}</TableCell>
                      <TableCell align="right">{category.percentage.toFixed(1)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BudgetSummary;