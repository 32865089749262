import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  LinearProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { IMC_INCOME_CODES, IMC_EXPENSE_CODES } from '../../../constants/imcCodes';

interface BudgetSummaryProps {
  incomeLines: any[];
  expenseLines: any[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const BudgetSummary: React.FC<BudgetSummaryProps> = ({ incomeLines, expenseLines }) => {
  const {
    totalIncome,
    totalExpenses,
    costToOrganisation,
    fundingCoveragePercent,
    incomeByCategory,
    expenseByCategory,
  } = useMemo(() => {
    const calcIncome = incomeLines.reduce(
      (sum, line) => sum + ((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)),
      0
    );

    const calcExpenses = expenseLines.reduce(
      (sum, line) => sum + ((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)),
      0
    );

    const incomeCategories = incomeLines.reduce((acc, line) => {
      const code = Object.values(IMC_INCOME_CODES).find((c) => c.code === line.code);
      const category = code?.subCategories[0] || 'Other';
      const amount = (line.quantity || 0) * (line.value || 0) * (line.multiplier || 0);
      acc[category] = (acc[category] || 0) + amount;
      return acc;
    }, {});

    const expenseCategories = expenseLines.reduce((acc, line) => {
      const code = Object.values(IMC_EXPENSE_CODES).find((c) => c.code === line.code);
      const category = code?.subCategories[0] || 'Other';
      const amount = (line.quantity || 0) * (line.value || 0) * (line.multiplier || 0);
      acc[category] = (acc[category] || 0) + amount;
      return acc;
    }, {});

    return {
      totalIncome: calcIncome,
      totalExpenses: calcExpenses,
      costToOrganisation: calcExpenses - calcIncome,
      fundingCoveragePercent: calcIncome > 0 ? (calcIncome / calcExpenses) * 100 : 0,
      incomeByCategory: Object.entries(incomeCategories).map(([name, value]) => ({ name, value })),
      expenseByCategory: Object.entries(expenseCategories).map(([name, value]) => ({ name, value })),
    };
  }, [incomeLines, expenseLines]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  return (
    <Grid container spacing={3}>
      {/* Overview Cards */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
          <Typography variant="subtitle2">Total Income</Typography>
          <Typography variant="h4">{formatCurrency(totalIncome)}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2, bgcolor: 'error.light', color: 'error.contrastText' }}>
          <Typography variant="subtitle2">Total Costs</Typography>
          <Typography variant="h4">{formatCurrency(totalExpenses)}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          sx={{
            p: 2,
            bgcolor: costToOrganisation <= 0 ? 'success.light' : 'warning.light',
            color: 'white',
          }}
        >
          <Typography variant="subtitle2">Cost to Organisation</Typography>
          <Typography variant="h4">{formatCurrency(costToOrganisation)}</Typography>
        </Paper>
      </Grid>

      {/* Funding Progress */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>Funding Efficiency</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={Math.min(fundingCoveragePercent, 100)}
                color={fundingCoveragePercent >= 100 ? 'success' : 'warning'}
                sx={{ height: 10, borderRadius: 5 }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {fundingCoveragePercent.toFixed(1)}%
              </Typography>
            </Box>
          </Box>
          {fundingCoveragePercent < 100 && (
            <Alert severity="warning" sx={{ mt: 1 }}>
              Funding currently covers {fundingCoveragePercent.toFixed(1)}% of costs. Additional support of {formatCurrency(
                totalExpenses - totalIncome
              )} is needed.
            </Alert>
          )}
        </Paper>
      </Grid>

      {/* Budget Health Indicators */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Budget Sustainability Insights</Typography>
          <Grid container spacing={2}>
            {costToOrganisation > 0 && (
              <Grid item xs={12}>
                <Alert severity="info">
                  Current operations are partially offset by external funding. Offset: {formatCurrency(costToOrganisation)}.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Alert severity="info">
                Suggested contingency fund: {formatCurrency(totalExpenses * 0.1)} (10% of operational costs).
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="success">
                Ensure alignment with funding goals and long-term sustainability objectives.
              </Alert>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BudgetSummary;