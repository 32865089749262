import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Tooltip,
  IconButton,
  Stack,
  Alert,
  Button
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { IMC_EXPENSE_CODES, IMCSubCategory } from '../../../constants/imcCodes';

type EmploymentType = 'ia-employ' | 'a-employ' | 'o-employ' | 'i-employ';

interface EmploymentTrackerProps {
  code: string;
  employmentCount: number;
  employmentType: EmploymentType | null;
  employmentNotes: string;
  onChange: (updates: {
    employmentCount?: number;
    employmentType?: EmploymentType | null;
    employmentNotes?: string;
  }) => void;
  existingEmployment?: Array<{
    /** Possibly add an IMC code so we can display it */
    code?: string;
    count: number;
    type: string;
  }>;
}

/**
 * EmploymentTracker allows specifying the number of people (employmentCount)
 * and the type of employment (employmentType), plus notes. 
 * If the code’s subCategories indicate an employment category,
 * we show this UI; otherwise, we return null.
 */
const EmploymentTracker: React.FC<EmploymentTrackerProps> = ({
  code,
  employmentCount = 0,
  employmentType = null,
  employmentNotes = '',
  onChange,
  existingEmployment = []
}) => {
  // State to dismiss the "similar" warning
  const [dismissSimilarWarning, setDismissSimilarWarning] = React.useState(false);

  // Find this code in IMC_EXPENSE_CODES
  const codeDetails = Object.values(IMC_EXPENSE_CODES).find((c) => c.code === code);

  // Check if this code has at least one of our special subCategories
  const hasEmployment = codeDetails?.subCategories.some((cat: IMCSubCategory) =>
    ['ia-employ', 'a-employ', 'o-employ', 'i-employ'].includes(cat)
  );
  if (!hasEmployment) return null;

  // Filter the subCategories to find the “default” one on this code
  const subCategories = codeDetails?.subCategories as IMCSubCategory[] | undefined;
  if (!subCategories) return null;

  const employmentCategories = subCategories.filter(
    (cat): cat is string => typeof cat === 'string'
  );

  /**
   * If this code has, say, 'a-employ', we’ll use that as a fallback;
   * otherwise we default to 'a-employ' if user hasn’t selected anything.
   */
  const codeDefinedType = (employmentCategories.find((cat) =>
    ['ia-employ', 'a-employ', 'o-employ', 'i-employ'].includes(cat)
  ) as EmploymentType | undefined) || null;

  // Final chosen type: if user has selected something, use it. 
  // Else use code-defined type. If that’s absent, use 'a-employ' by default.
  const finalEmploymentType = 
    employmentType ?? codeDefinedType ?? 'a-employ';

  // A helper to produce a label from our four special subcategory strings
  const getEmploymentTypeLabel = (type: string) => {
    const labels: Record<string, string> = {
      'ia-employ': 'International Artist',
      'a-employ': 'Artist',
      'o-employ': 'Other Staff/Crew',
      'i-employ': 'Associated Employment'
    };
    return labels[type] || type;
  };

  // Potential double-count check
  // Compare code + type or just type, depending on what you want.
  const similarEmployment = existingEmployment.filter(
    (emp) => emp.type === finalEmploymentType && emp.count > 0
  );

  // If we also want to check the same code, you could do:
  // existingEmployment.filter(emp => emp.code === code && ...)

  // Render a more descriptive message showing the code, too
  const similarMessage = similarEmployment
    .map(
      (emp) => 
        `${emp.count} ${getEmploymentTypeLabel(emp.type)}${emp.code ? ` (Code ${emp.code})` : ''}`
    )
    .join(', ');

  return (
    <Paper
      sx={{
        p: 2,
        mt: 1,
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Stack spacing={2}>
        {/* 
          Example instruction for the user about 
          “make sure you’re not double-counting,” etc.
        */}
        <Alert severity="info" variant="outlined">
          Please ensure this reflects the total actual people employed for this code. 
          If quantity or multiplier were used, confirm the correct total number of 
          distinct individuals is set here.
        </Alert>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" color="text.primary">
            Employment Details
          </Typography>
          <Tooltip title="Track employment numbers for Arts Council reporting">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {!dismissSimilarWarning && similarEmployment.length > 0 && (
          <Alert
            severity="warning"
            sx={{ mb: 2, whiteSpace: 'pre-wrap' }}
            action={
              <Button color="inherit" size="small" onClick={() => setDismissSimilarWarning(true)}>
                Dismiss
              </Button>
            }
          >
            Similar employment already recorded. Is this intentional?
            <br />
            {similarMessage}
          </Alert>
        )}

        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            type="number"
            label="Number of People"
            value={employmentCount}
            onChange={(e) =>
              onChange({
                employmentCount: parseInt(e.target.value) || 0
              })
            }
            size="small"
            // Increase the spinner size:
            InputProps={{
              inputProps: {
                style: {
                  fontSize: '1rem',
                  height: '2.2rem'
                }
              },
              sx: {
                // Remove default tiny spinner in Chrome, enlarge if needed
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                    margin: 0,
                    WebkitAppearance: 'none'
                  }
                }
              }
            }}
            sx={{
              width: 150,
              '& .MuiOutlinedInput-root': {
                bgcolor: 'background.paper'
              }
            }}
          />

          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="employment-type-label">Employment Type</InputLabel>
            <Select
              labelId="employment-type-label"
              value={finalEmploymentType}
              label="Employment Type"
              onChange={(e) =>
                onChange({
                  employmentType: e.target.value as EmploymentType
                })
              }
              sx={{
                bgcolor: 'background.paper'
              }}
            >
              {(['ia-employ', 'a-employ', 'o-employ', 'i-employ'] as const).map((type) => (
                <MenuItem key={type} value={type}>
                  {getEmploymentTypeLabel(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TextField
          multiline
          rows={2}
          fullWidth
          label="Employment Notes"
          placeholder="Add any notes about employment details..."
          value={employmentNotes}
          onChange={(e) => onChange({ employmentNotes: e.target.value })}
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: 'background.paper'
            }
          }}
        />

        {finalEmploymentType === 'i-employ' && (
          <Alert
            severity="info"
            sx={{
              '& .MuiAlert-message': {
                color: 'text.primary'
              }
            }}
          >
            Associated employment refers to indirect or incidental roles created by this activity.
            Please provide details in the notes if needed.
          </Alert>
        )}
      </Stack>
    </Paper>
  );
};

export default EmploymentTracker;