import React, { useState, useMemo } from 'react';
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  InputAdornment
} from '@mui/material';
import { Search as SearchIcon } from 'lucide-react';
import { StageElement, SearchResult, StageElementsStructure } from './types';

interface StageElementsSearchProps {
  stageElements: StageElementsStructure;
  onElementSelect: (element: StageElement) => void;
}

const StageElementsSearch: React.FC<StageElementsSearchProps> = ({ 
  stageElements, 
  onElementSelect 
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredElements = useMemo(() => {
    if (!searchQuery.trim()) return [];

    const results: SearchResult[] = [];
    const query = searchQuery.toLowerCase();

    Object.entries(stageElements).forEach(([category, subcategories]) => {
      Object.entries(subcategories).forEach(([subcategory, elements]) => {
        elements.forEach(element => {
          if (element.name.toLowerCase().includes(query)) {
            results.push({
              ...element,
              category,
              subcategory: subcategory || category
            });
          }
        });
      });
    });

    return results;
  }, [stageElements, searchQuery]);

  return (
    <Box sx={{ p: 2 }}>
      <TextField
        fullWidth
        size="small"
        placeholder="Search elements..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon size={20} />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />

      {searchQuery.trim() && (
        <Box>
          {filteredElements.length === 0 ? (
            <Typography variant="body2" color="text.secondary" align="center">
              No elements found
            </Typography>
          ) : (
            <List dense disablePadding>
              {filteredElements.map((element, index) => (
                <React.Fragment key={element.id}>
                  <ListItem
                    button
                    onClick={() => onElementSelect(element)}
                    sx={{
                      borderRadius: 1,
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      {React.cloneElement(element.icon, {
                        fontSize: 'small',
                        color: element.type === 'microphone' ? 'error' :
                               element.type === 'instrument' ? 'primary' :
                               'warning'
                      })}
                    </ListItemIcon>
                    <ListItemText 
                      primary={element.name}
                      secondary={element.subcategory}
                      primaryTypographyProps={{
                        variant: 'body2',
                        fontWeight: 500
                      }}
                      secondaryTypographyProps={{
                        variant: 'caption',
                        color: 'text.secondary'
                      }}
                    />
                  </ListItem>
                  {index < filteredElements.length - 1 && (
                    <Divider variant="middle" sx={{ my: 0.5 }} />
                  )}
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StageElementsSearch;