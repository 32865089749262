import React from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Stack
} from '@mui/material';
import {
  Download as DownloadIcon,
  Print as PrintIcon
} from '@mui/icons-material';
import AARTable from '../components/budgets/AAR/AARTable';

const AARPage = () => {
  const handleExportCSV = () => {
    // Implement CSV export functionality
    console.log('Export to CSV');
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box sx={{ 
      maxWidth: '100vw', 
      p: 3,
      '@media print': {
        p: 0
      }
    }}>
      <Paper sx={{ 
        p: 3, 
        mb: 3,
        '@media print': {
          boxShadow: 'none',
          p: 2
        }
      }}>
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center" 
          sx={{ 
            mb: 3,
            '@media print': {
              display: 'none'
            }
          }}
        >
          <Typography variant="h4">
            Annual Activity Report 2025
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleExportCSV}
            >
              Export CSV
            </Button>
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print Report
            </Button>
          </Stack>
        </Stack>

        <Typography variant="body1" paragraph>
          This report summarizes all activities for the year 2025, including financial and engagement metrics
          for submission to the Arts Council.
        </Typography>
      </Paper>

      <AARTable />
    </Box>
  );
};

export default AARPage;