import React, { useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Alert,
  Slider,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from 'recharts';
import { IMC_INCOME_CODES } from '../../../constants/imcCodes';
import _ from 'lodash';

const COLORS = ['#2196f3', '#4caf50', '#ff9800', '#f44336', '#9c27b0'];

interface TicketLine {
  code: string;
  quantity: number;
  value: number;
  multiplier: number;
  notes?: string;
}

interface GroupBudget {
  _id: string;
  name: string;
  totalIncome: number;
  totalExpenses: number;
  expenseLines: Array<{
    code: string;
    quantity: number;
    value: number;
    multiplier: number;
  }>;
  incomeLines: Array<{
    code: string;
    quantity: number;
    value: number;
    multiplier: number;
    notes?: string;
  }>;
  status: string;
}

interface TicketAnalysisProps {
  groupBudgets: GroupBudget[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ bgcolor: 'white', color: 'text.primary', p: 2, minWidth: 200 }}>
        <Typography variant="subtitle2">{label}</Typography>
        {payload.map((entry: any) => (
          <Typography key={entry.dataKey} variant="body2" sx={{ color: entry.color }}>
            {entry.name}: {entry.dataKey.includes('revenue') ? formatCurrency(entry.value) : entry.value}
          </Typography>
        ))}
      </Card>
    );
  }
  return null;
};

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(value);
};

const TicketAnalysis: React.FC<TicketAnalysisProps> = ({ groupBudgets }) => {
  const [priceIncrease, setPriceIncrease] = useState<number>(0);
  const [fixedIncrease, setFixedIncrease] = useState<number>(0);

  // Filter ticket codes
  const TICKET_CODES = useMemo(() => 
    Object.entries(IMC_INCOME_CODES)
      .filter(([_, value]) => value.subCategories.includes('ticketing'))
      .map(([_, value]) => value.code),
    []
  );

  // Process ticket data with separate regular and promo analysis
  const ticketAnalysis = useMemo(() => {
    const ticketLines = groupBudgets.flatMap(budget => 
      budget.incomeLines.filter(line => TICKET_CODES.includes(line.code))
    );

    // Separate regular tickets from performer promo
    const regularTicketLines = ticketLines.filter(line => 
      !line.notes?.toLowerCase().includes('performer promo')
    );
    
    const promoTicketLines = ticketLines.filter(line => 
      line.notes?.toLowerCase().includes('performer promo')
    );

    // Calculate total tickets
    const totalTickets = ticketLines.reduce((sum, line) => 
      sum + (line.quantity * line.multiplier), 0
    );
    // Calculate regular ticket metrics
    const regularTotalTickets = _.sumBy(regularTicketLines, line => line.quantity * line.multiplier);
    const regularTotalRevenue = _.sumBy(regularTicketLines, line => line.quantity * line.value * line.multiplier);
    const regularAveragePrice = regularTicketLines.length ? 
      regularTotalRevenue / regularTotalTickets : 0;

    // Calculate promo ticket metrics
    const promoTotalTickets = _.sumBy(promoTicketLines, line => line.quantity * line.multiplier);
    const promoTotalRevenue = _.sumBy(promoTicketLines, line => line.quantity * line.value * line.multiplier);
    const promoAveragePrice = promoTicketLines.length ?
      promoTotalRevenue / promoTotalTickets : 0;

    // Calculate baseline channel analysis
    const baselineAnalysis = {
      revenueByChannel: _.chain(ticketLines)
        .groupBy(line => {
          const code = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code);
          return code?.label || 'Unknown';
        })
        .mapValues(lines => ({
          revenue: _.sumBy(lines, line => line.quantity * line.value * line.multiplier),
          tickets: _.sumBy(lines, line => line.quantity * line.multiplier),
          averagePrice: _.sumBy(lines, line => line.quantity * line.value * line.multiplier) / 
            _.sumBy(lines, line => line.quantity * line.multiplier)
        }))
        .value(),

      pricingTiers: _.chain(regularTicketLines) // Only analyze regular tickets for tiers
        .filter(line => line.notes !== undefined && line.notes !== '')
        .groupBy(line => line.notes?.toLowerCase().trim())
        .mapValues(lines => ({
          tickets: _.sumBy(lines, line => line.quantity * line.multiplier),
          revenue: _.sumBy(lines, line => line.quantity * line.value * line.multiplier),
          averagePrice: _.sumBy(lines, line => line.quantity * line.value * line.multiplier) / 
            _.sumBy(lines, line => line.quantity * line.multiplier)
        }))
        .value()
    };

    // Calculate modified prices with increases
    const getModifiedPrice = (originalPrice: number) => {
      const percentageIncrease = originalPrice * (priceIncrease / 100);
      return originalPrice + percentageIncrease + fixedIncrease;
    };

    // Calculate modified analysis
    const modifiedAnalysis = {
      revenueByChannel: _.mapValues(baselineAnalysis.revenueByChannel, (data, channel) => ({
        ...data,
        modifiedRevenue: data.tickets * getModifiedPrice(data.averagePrice),
        revenueIncrease: (data.tickets * getModifiedPrice(data.averagePrice)) - data.revenue,
      })),

      pricingTiers: _.mapValues(baselineAnalysis.pricingTiers, (data, tier) => ({
        ...data,
        modifiedRevenue: data.tickets * getModifiedPrice(data.averagePrice),
        revenueIncrease: (data.tickets * getModifiedPrice(data.averagePrice)) - data.revenue,
      }))
    };

    // Calculate overall metrics
    const totalBaselineRevenue = regularTotalRevenue + promoTotalRevenue;
    const modifiedRegularRevenue = regularTotalTickets * getModifiedPrice(regularAveragePrice);
    const totalModifiedRevenue = modifiedRegularRevenue + promoTotalRevenue;
    const ticketsPerEvent = totalTickets / groupBudgets.length;
    
    const totalGroupIncome = _.sumBy(groupBudgets, 'totalIncome');
    const ticketingPercentage = (totalBaselineRevenue / totalGroupIncome) * 100;
    const modifiedTicketingPercentage = (totalModifiedRevenue / totalGroupIncome) * 100;

    return {
      // Overall totals
      totalTickets,
      baselineRevenue: totalBaselineRevenue,
      modifiedRevenue: totalModifiedRevenue,
      revenueIncrease: totalModifiedRevenue - totalBaselineRevenue,

      // Regular ticket metrics
      regularTickets: regularTotalTickets,
      regularRevenue: regularTotalRevenue,
      regularAveragePrice,
      regularModifiedPrice: getModifiedPrice(regularAveragePrice),

      // Promo ticket metrics
      promoTickets: promoTotalTickets,
      promoRevenue: promoTotalRevenue,
      promoAveragePrice,

      // Other metrics
      ticketsPerEvent,
      ticketingPercentage,
      modifiedTicketingPercentage,
      baselineAnalysis,
      modifiedAnalysis
    };
  }, [groupBudgets, TICKET_CODES, priceIncrease, fixedIncrease]);
  // Prepare chart data
  const channelData = Object.entries(ticketAnalysis.baselineAnalysis.revenueByChannel).map(([name, data]) => ({
    name: name.replace('IMC - ', ''),
    revenue: data.revenue,
    projectedRevenue: ticketAnalysis.modifiedAnalysis.revenueByChannel[name].modifiedRevenue,
    tickets: data.tickets
  }));

  const tierData = Object.entries(ticketAnalysis.baselineAnalysis.pricingTiers).map(([name, data]) => ({
    name: _.startCase(name),
    tickets: data.tickets,
    revenue: data.revenue,
    averagePrice: data.averagePrice
  }));

  return (
    <Box sx={{ p: 4, width: '100%' }}>
      <Typography variant="h5" gutterBottom>
        Ticket Sales Analysis & Price Modeling
      </Typography>

      {/* Original Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Projected Tickets
              </Typography>
              <Typography variant="h4">
                {ticketAnalysis.totalTickets.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Regular Ticket Revenue
              </Typography>
              <Typography variant="h4">
                {formatCurrency(ticketAnalysis.regularRevenue)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Regular Ticket Price (Avg.)
              </Typography>
              <Typography variant="h4">
                {formatCurrency(ticketAnalysis.regularAveragePrice)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Excludes Performer Promo
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Tickets per Event
              </Typography>
              <Typography variant="h4">
                {Math.round(ticketAnalysis.ticketsPerEvent)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Price Modeling Controls */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Price Adjustment Scenarios
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>Percentage Increase</Typography>
            <Slider
              value={priceIncrease}
              onChange={(_, value) => setPriceIncrease(value as number)}
              min={0}
              max={20}
              step={0.5}
              marks={[
                { value: 0, label: '0%' },
                { value: 5, label: '5%' },
                { value: 10, label: '10%' },
                { value: 15, label: '15%' },
                { value: 20, label: '20%' }
              ]}
              valueLabelDisplay="auto"
              valueLabelFormat={value => `${value}%`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>Fixed Price Increase</Typography>
            <TextField
              fullWidth
              type="number"
              value={fixedIncrease}
              onChange={(e) => setFixedIncrease(Number(e.target.value))}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
              inputProps={{
                min: 0,
                step: 0.5
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Price Impact Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                New Regular Price
              </Typography>
              <Typography variant="h4" color="success.main">
                {formatCurrency(ticketAnalysis.regularModifiedPrice)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Increase: {formatCurrency(ticketAnalysis.regularModifiedPrice - ticketAnalysis.regularAveragePrice)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Projected Revenue
              </Typography>
              <Typography variant="h4" color="success.main">
                {formatCurrency(ticketAnalysis.modifiedRevenue)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Additional: {formatCurrency(ticketAnalysis.revenueIncrease)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Income Percentage
              </Typography>
              <Typography variant="h4" color="success.main">
                {ticketAnalysis.modifiedTicketingPercentage.toFixed(1)}%
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Current: {ticketAnalysis.ticketingPercentage.toFixed(1)}%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Channel Revenue Chart */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Revenue by Channel - Price Impact
        </Typography>
        <Box sx={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={channelData}
              margin={{ top: 20, right: 30, left: 60, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-35}
                textAnchor="end"
                height={100}
                interval={0}
              />
              <YAxis tickFormatter={formatCurrency} />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="revenue" name="Current Revenue" fill="#2196f3" />
              <Bar dataKey="projectedRevenue" name="Projected Revenue" fill="#4caf50" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      {/* Pricing Tiers Chart */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Sales by Pricing Tier
        </Typography>
        <Box sx={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={tierData}
                dataKey="tickets"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={150}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(1)}%)`}
              >
                {tierData.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
      {/* Comprehensive Insights */}
      <Alert severity="info" sx={{ p: 3 }}>
        <Typography variant="subtitle1" gutterBottom fontWeight="medium">
          Regular Ticket Sales Analysis
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 0 }}>
          <li>Regular tickets: {ticketAnalysis.regularTickets.toLocaleString()} ({((ticketAnalysis.regularTickets / ticketAnalysis.totalTickets) * 100).toFixed(1)}% of total)</li>
          <li>Regular ticket revenue: {formatCurrency(ticketAnalysis.regularRevenue)}</li>
          <li>Current average price: {formatCurrency(ticketAnalysis.regularAveragePrice)}</li>
          <li>Projected price with increase: {formatCurrency(ticketAnalysis.regularModifiedPrice)}</li>
          <li>Additional revenue from increase: {formatCurrency(ticketAnalysis.revenueIncrease)}</li>
        </Box>

        <Typography variant="subtitle1" gutterBottom fontWeight="medium" sx={{ mt: 3 }}>
          Performer Promo Analysis
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 0 }}>
          <li>Promo tickets: {ticketAnalysis.promoTickets.toLocaleString()} ({((ticketAnalysis.promoTickets / ticketAnalysis.totalTickets) * 100).toFixed(1)}% of total)</li>
          <li>Promo ticket revenue: {formatCurrency(ticketAnalysis.promoRevenue)}</li>
          <li>Promo average price: {formatCurrency(ticketAnalysis.promoAveragePrice)}</li>
          <li>Discount vs regular price: {((1 - (ticketAnalysis.promoAveragePrice / ticketAnalysis.regularAveragePrice)) * 100).toFixed(1)}%</li>
        </Box>

        <Typography variant="subtitle1" gutterBottom fontWeight="medium" sx={{ mt: 3 }}>
          Channel Distribution
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 0 }}>
          {Object.entries(ticketAnalysis.baselineAnalysis.revenueByChannel).map(([channel, data]) => (
            <li key={channel}>
              {channel.replace('IMC - ', '')}: {data.tickets.toLocaleString()} tickets 
              ({((data.tickets / ticketAnalysis.totalTickets) * 100).toFixed(1)}% of total) at {formatCurrency(data.averagePrice)} average price
            </li>
          ))}
        </Box>

        <Typography variant="subtitle1" gutterBottom fontWeight="medium" sx={{ mt: 3 }}>
          Price Adjustment Impact
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 0 }}>
          <li>Applied increase: {priceIncrease > 0 ? `${priceIncrease}% percentage` : ''} {fixedIncrease > 0 ? `plus €${fixedIncrease.toFixed(2)} fixed` : ''}</li>
          <li>Current ticketing revenue: {formatCurrency(ticketAnalysis.baselineRevenue)} ({ticketAnalysis.ticketingPercentage.toFixed(1)}% of total income)</li>
          <li>Projected ticketing revenue: {formatCurrency(ticketAnalysis.modifiedRevenue)} ({ticketAnalysis.modifiedTicketingPercentage.toFixed(1)}% of total income)</li>
          <li>Revenue increase: {formatCurrency(ticketAnalysis.revenueIncrease)} ({((ticketAnalysis.revenueIncrease / ticketAnalysis.baselineRevenue) * 100).toFixed(1)}% increase)</li>
        </Box>
      </Alert>
    </Box>
  );
};

export default TicketAnalysis;