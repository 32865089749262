import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface CloneDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (year: number) => void;
  strandName: string;
  currentYear: number;
}

const CloneDialog: React.FC<CloneDialogProps> = ({
  open,
  onClose,
  onConfirm,
  strandName,
  currentYear
}) => {
  const [step, setStep] = useState<'confirm' | 'year'>('confirm');
  const [selectedYear, setSelectedYear] = useState<number>(currentYear + 1);
  
  // Generate year options (current year to current + 3)
  const yearOptions = Array.from(
    { length: 4 }, 
    (_, i) => currentYear + i
  );

  const handleConfirmClick = () => {
    setStep('year');
  };

  const handleYearConfirm = () => {
    onConfirm(selectedYear);
    setStep('confirm');
    onClose();
  };

  const handleClose = () => {
    setStep('confirm');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {step === 'confirm' ? (
        <>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ContentCopyIcon sx={{ fontSize: 20 }} />
            Clone Strand
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to duplicate "{strandName}"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmClick} variant="contained" color="primary">
              Continue
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Select Year</DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 1 }}>
              <Typography gutterBottom>
                Which year would you like to clone this strand to?
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
                Suggested: {currentYear + 1}
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(Number(e.target.value))}
                >
                  {yearOptions.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button 
              onClick={handleYearConfirm} 
              variant="contained" 
              color="primary"
              disabled={selectedYear === currentYear}
            >
              Clone to {selectedYear}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CloneDialog;