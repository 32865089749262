import React, { useState } from 'react';
import { Treemap, ResponsiveContainer } from 'recharts';
import { Typography, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { IMC_EXPENSE_CODES } from '../../../../constants/imcCodes';
import { GroupBudgetLine } from '../../../../types/groupBudget';

interface ExpenseDistributionProps {
  expenseLines: GroupBudgetLine[];
  onFilterChange: (filter: { type: string; value: string | null }) => void;
  selectedFilter: { type: string; value: string | null };
}

type ViewMode = 'imc' | 'subcategory' | 'tags' | 'ac';

const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', 
  '#FFBE0B', '#9B5DE5', '#4A4E69', '#845EC2'
];

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(amount);
};

const ExpenseDistributionTreemap: React.FC<ExpenseDistributionProps> = ({
  expenseLines,
  onFilterChange,
  selectedFilter
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>('imc');
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  const data = React.useMemo(() => {
    const groupedData = new Map<string, {
      value: number;
      codes: string[];
      details: string;
    }>();
    
    expenseLines.forEach(line => {
      const codeDetails = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      if (!codeDetails) return;

      const amount = (line.quantity || 0) * (line.value || 0) * (line.multiplier || 0);
      let key: string;
      let details: string;

      switch (viewMode) {
        case 'imc':
          key = codeDetails.label.split(' - ').slice(1).join(' - ');
          details = codeDetails.label;
          break;
        case 'subcategory':
          key = codeDetails.subCategories[0];
          details = codeDetails.subCategories.join(', ');
          break;
        case 'tags':
          key = codeDetails.tags[0];
          details = codeDetails.tags.join(', ');
          break;
        case 'ac':
          key = codeDetails.acCode || 'Uncategorized';
          details = codeDetails.acCode || 'Uncategorized';
          break;
        default:
          key = codeDetails.label;
          details = codeDetails.label;
      }

      const existing = groupedData.get(key) || { value: 0, codes: [], details };
      existing.value += amount;
      if (!existing.codes.includes(codeDetails.code)) {
        existing.codes.push(codeDetails.code);
      }
      groupedData.set(key, existing);
    });

    // If we have a focused item, return only that item
    if (focusedItem && groupedData.has(focusedItem)) {
      const item = groupedData.get(focusedItem)!;
      return [{
        name: focusedItem,
        value: item.value,
        codes: item.codes,
        details: item.details,
        color: COLORS[0]
      }];
    }

    return Array.from(groupedData.entries())
      .map(([name, data], index) => ({
        name,
        value: data.value,
        codes: data.codes,
        details: data.details,
        color: COLORS[index % COLORS.length]
      }))
      .sort((a, b) => b.value - a.value);
  }, [expenseLines, viewMode, focusedItem]);

  const handleItemClick = (name: string, codes: string[]) => {
    if (focusedItem === name) {
      setFocusedItem(null);
      onFilterChange({ type: viewMode, value: null });
    } else {
      setFocusedItem(name);
      // When clicking, filter by all related IMC codes
      onFilterChange({ type: 'imc', value: codes[0] });
    }
  };

  const CustomContent = (props: any) => {
    const { x, y, width, height, name, value, color, codes, details } = props;
    if (!name || !value) return null;

    const total = data.reduce((sum, item) => sum + item.value, 0);
    const percentage = (value / total) * 100;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: color || '#000',
            stroke: '#fff',
            strokeWidth: selectedFilter.value && codes.includes(selectedFilter.value) ? 2 : 1,
            cursor: 'pointer',
          }}
          onClick={() => handleItemClick(name, codes)}
        />
        {width > 50 && height > 30 && (
          <>
            <text
              x={x + width / 2}
              y={y + height / 2 - 16}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={12}
              style={{ 
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial'
              }}
            >
              {name}
            </text>
            <text
              x={x + width / 2}
              y={y + height / 2 + 4}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={11}
              style={{ 
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial'
              }}
            >
              {formatCurrency(value)}
            </text>
            <text
              x={x + width / 2}
              y={y + height / 2 + 20}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={10}
              style={{ 
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial'
              }}
            >
              {percentage.toFixed(1)}%
            </text>
          </>
        )}
      </g>
    );
  };

  const handleViewChange = (newMode: ViewMode) => {
    setViewMode(newMode);
    setFocusedItem(null);
    onFilterChange({ type: newMode, value: null });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2 
      }}>
        <Typography variant="h6">
          Expense Distribution
          {focusedItem && (
            <Typography 
              component="span"
              variant="body2" 
              sx={{ 
                ml: 2,
                cursor: 'pointer', 
                color: 'primary.main',
                '&:hover': { textDecoration: 'underline' }
              }}
              onClick={() => {
                setFocusedItem(null);
                onFilterChange({ type: viewMode, value: null });
              }}
            >
              (Click to zoom out)
            </Typography>
          )}
        </Typography>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>View By</InputLabel>
          <Select
            value={viewMode}
            label="View By"
            onChange={(e) => handleViewChange(e.target.value as ViewMode)}
          >
            <MenuItem value="imc">IMC Codes</MenuItem>
            <MenuItem value="subcategory">Subcategories</MenuItem>
            <MenuItem value="tags">Tags</MenuItem>
            <MenuItem value="ac">AC Codes</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      <Box sx={{ width: '100%', height: '240px' }}>
        <ResponsiveContainer>
          <Treemap
            data={data}
            dataKey="value"
            stroke="#fff"
            fill="#8884d8"
            content={<CustomContent />}
            aspectRatio={4/3}
          />
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ExpenseDistributionTreemap;