// src/components/cooler/inventory/components/ItemDetails/index.tsx
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Stack,
  IconButton,
  Tab,
  Tabs,
  Alert,
  AlertTitle,
  Dialog as ConfirmDialog,
  DialogContent as ConfirmDialogContent,
  DialogActions as ConfirmDialogActions,
} from '@mui/material';
import {
  Edit as EditIcon,
  X as CloseIcon,
  Save as SaveIcon,
  Trash2 as DeleteIcon,
  WrenchIcon,
} from 'lucide-react';

import type { InventoryItem, InventoryUpdateData } from '../../../types/inventoryTypes';
import EditForm from './EditForm';
import ViewDetails from './ViewDetails';
import ImageGallery from './ImageGallery';
import MaintenanceHistory from './UsageHistory';
import RelatedEquipment from './RelatedEquipment';
import Documents from './Documents';
import TabPanel from './TabPanel';
import axiosInstance from '../../../../../utils/axiosConfig';

interface ItemDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  item: InventoryItem | null;
  onEdit: (updatedItem: InventoryItem) => void;
  onMaintenanceClick?: (item: InventoryItem) => void;  // Add this
  onDelete?: (id: string) => void; // Accept the item ID to delete
}

export const ItemDetailsDialog: React.FC<ItemDetailsDialogProps> = ({
  open,
  onClose,
  item,
  onEdit,
  onDelete,
  onMaintenanceClick, // Add this
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState<InventoryUpdateData | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Reset state when item changes
  React.useEffect(() => {
    setEditedItem(item);
    setIsEditing(false);
    setActiveTab(0);
    setDeleteError(null);
  }, [item]);

  if (!item || !editedItem) return null;

  const handleSave = async () => {
    if (!editedItem) return;
    const { newImages, newDocuments, ...itemToSave } = editedItem;
    await onEdit(itemToSave);
    onClose();
  };

  const getMaintenanceStatusColor = (status?: 'ok' | 'due' | 'overdue'): 'success' | 'warning' | 'error' | 'default' => {
    switch (status) {
      case 'ok':
        return 'success';
      case 'due':
        return 'warning';
      case 'overdue':
        return 'error';
      default:
        return 'default';
    }
  };

  const handleDelete = async () => {
    if (!item?._id || !onDelete) return;
  
    setIsDeleting(true);
    setDeleteError(null);
  
    try {
      await onDelete(item._id); // Delegate deletion to the parent
      setDeleteDialogOpen(false);
      onClose();
    } catch (error) {
      console.error('Error in handleDelete:', error);
      setDeleteError('Failed to delete item. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
<DialogTitle>
  <Stack 
    direction="row" 
    alignItems="center" 
    justifyContent="space-between"
  >
    <Typography variant="h6">
      {isEditing ? 'Edit Item' : item.name}
    </Typography>
    <Box>
      {!isEditing && (
        <>
          <IconButton 
            onClick={() => setDeleteDialogOpen(true)}
            color="error"
            sx={{ mr: 1 }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            onClick={() => onMaintenanceClick?.(item)}
            color={getMaintenanceStatusColor(item.maintenanceStatus)}
            sx={{ mr: 1 }}
          >
            <WrenchIcon size={20} />
          </IconButton>
          <IconButton 
            onClick={() => setIsEditing(true)}
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  </Stack>
</DialogTitle>

        <DialogContent>
          {deleteError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Delete Failed</AlertTitle>
              {deleteError}
            </Alert>
          )}

          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="scrollable"
          >
            <Tab label="Details" />
            <Tab label="Images" />
            <Tab label="Maintenance History" />
            <Tab label="Documents" />
            <Tab label="Related Equipment" />
          </Tabs>

          <TabPanel value={activeTab} index={0}>
            {isEditing ? (
              <EditForm
                item={editedItem}
                onChange={setEditedItem}
              />
            ) : (
              <ViewDetails item={editedItem} />
            )}
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <ImageGallery 
              images={item.images}
              isEditing={isEditing}
              onUpdate={(newImages) => {
                if (editedItem) {
                  setEditedItem({
                    ...editedItem,
                    images: newImages
                  });
                }
              }}
              setEditedItem={setEditedItem}
            />
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <MaintenanceHistory item={item} />
          </TabPanel>

          <TabPanel value={activeTab} index={3}>
            <Documents
              item={item}
              isEditing={isEditing}
              onUpdate={(updatedDocuments) => {
                if (editedItem) {
                  setEditedItem({
                    ...editedItem,
                    documents: updatedDocuments
                  });
                }
              }}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
  <RelatedEquipment
    item={item}
    isEditing={isEditing}
    onUpdate={(relations) => {
      if (editedItem) {
        const relatedEquipmentIds = relations.map((relation) => relation.id);
        setEditedItem({
          ...editedItem,
          relatedEquipment: relatedEquipmentIds
        });
      }
    }}
  />
</TabPanel>
        </DialogContent>

        <DialogActions>
          {isEditing ? (
            <>
              <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </>
          ) : (
            <Button onClick={onClose}>Close</Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Confirm Delete
        </DialogTitle>
        <ConfirmDialogContent>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Are you sure you want to delete "{item.name}"? This action will:
            <ul>
              <li>Remove all associated images</li>
              <li>Delete all attached documents</li>
              <li>Delete all maintenance records</li>
              <li>This action cannot be undone</li>
            </ul>
          </Alert>
        </ConfirmDialogContent>
        <ConfirmDialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            disabled={isDeleting}
            startIcon={<DeleteIcon />}
          >
            {isDeleting ? 'Deleting...' : 'Delete Item'}
          </Button>
        </ConfirmDialogActions>
      </ConfirmDialog>
    </>
  );
};

export default ItemDetailsDialog;