// components/budgets/BudgetPlanner.tsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  Alert, 
  CircularProgress, 
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel 
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BuildIcon from '@mui/icons-material/Build';
import CampaignIcon from '@mui/icons-material/Campaign';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { RootState } from '../../store/store';
import { selectUI, selectBudgetState } from '../../store/selectors';
import { setActiveTab, setError, saveBudgetSuccess, setBudget, clearBudget } from '../../store/budgetSlice';
import axiosInstance from '../../utils/axiosConfig';

import ArtistTravelPlanner from './sections/ArtistTravelPlanner';
import VenuePlanner from './sections/VenuePlanner';
import ProductionPlanner from './sections/ProductionPlanner';
import MarketingPlanner from './sections/MarketingPlanner';
import IncomePlanner from './sections/IncomePlanner';
import BudgetSummary from './sections/BudgetSummary';
import OtherExpensesPlanner from './sections/OtherExpensesPlanner';
import BudgetDebugVisualizer from './sections/BudgetDebugVisualizer';
import { useParams } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface CreateBudgetDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: { name: string; type: string; strandId: string }) => void;
    strands: Array<{ _id: string; name: string }>;
  }

  const CreateBudgetDialog: React.FC<CreateBudgetDialogProps> = ({ open, onClose, onSubmit, strands }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('event');
    const [strandId, setStrandId] = useState('');
    const [error, setError] = useState('');
  
    const handleSubmit = () => {
      if (!name.trim()) {
        setError('Name is required');
        return;
      }
      if (!strandId) {
        setError('Strand is required');
        return;
      }
      onSubmit({ name, type, strandId });
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Create New Budget</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the following information to create a new budget.
          </DialogContentText>
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Budget Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Type</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="project">Project</MenuItem>
              <MenuItem value="festival">Festival</MenuItem>
              <MenuItem value="tour">Tour</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Strand</InputLabel>
            <Select value={strandId} onChange={(e) => setStrandId(e.target.value)}>
              {strands.map((strand) => (
                <MenuItem key={strand._id} value={strand._id}>
                  {strand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  };  

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`budget-tabpanel-${index}`}
      aria-labelledby={`budget-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `budget-tab-${index}`,
    'aria-controls': `budget-tabpanel-${index}`,
  };
};

const BudgetPlanner: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams(); // Get ID from URL
    const { activeTab, loading, error, unsavedChanges } = useSelector(selectUI);
    const budgetState = useSelector(selectBudgetState);
    const [isNewBudget, setIsNewBudget] = useState(true);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [strands, setStrands] = useState<Array<{ _id: string; name: string }>>([]);
    const [showOverwriteDialog, setShowOverwriteDialog] = useState(false);
    const [pendingBudgetData, setPendingBudgetData] = useState(null);
  
    useEffect(() => {
      const fetchStrands = async () => {
        try {
          const response = await axiosInstance.get('/api/strands');
          setStrands(response.data);
        } catch (err) {
          console.error('Error fetching strands:', err);
        }
      };
  
      fetchStrands();
    }, []);

    useEffect(() => {
        const fetchBudgetById = async () => {
          if (id) {
            try {
              console.log('Fetching budget with ID:', id);
              dispatch(clearBudget());
              const response = await axiosInstance.get(`/api/budgets/${id}`);
              console.log('Received budget data:', response.data);
              dispatch(setBudget(response.data));
              setIsNewBudget(false);
            } catch (err) {
              console.error('Error fetching budget:', err);
              dispatch(setError('Failed to fetch budget'));
            }
          }
        };
      
        fetchBudgetById();
      }, [id]);
  
  
    const handleCreateBudget = async (data: { name: string; type: string; strandId: string }) => {
      try {
        const response = await axiosInstance.post('/api/budgets', {
          ...budgetState,
          ...data,
          status: 'draft'
        });
        
        dispatch(setBudget(response.data));
        setIsNewBudget(false);
        setShowCreateDialog(false);
        dispatch(saveBudgetSuccess());
      } catch (err) {
        console.error('Error creating budget:', err);
        dispatch(setError('Failed to create budget'));
      }
    };
  
    const handleSave = async () => {
      try {
        if (isNewBudget) {
          setShowCreateDialog(true);
        } else {
          await axiosInstance.patch(`/api/budgets/${budgetState._id}`, budgetState);
          dispatch(saveBudgetSuccess());
        }
      } catch (err) {
        console.error('Error saving budget:', err);
        dispatch(setError('Failed to save budget'));
      }
    };

    const handleBudgetData = (data: any) => {
        if (unsavedChanges) {
          setPendingBudgetData(data);
          setShowOverwriteDialog(true);
        } else {
          dispatch(setBudget(data));
        }
      };
  
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      dispatch(setActiveTab(newValue));
    };
  
    if (loading) return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab 
            icon={<AccountCircleIcon />} 
            label="Artist & Travel" 
            {...a11yProps(0)}
          />
          <Tab 
            icon={<LocationOnIcon />} 
            label="Venue" 
            {...a11yProps(1)}
          />
          <Tab 
            icon={<BuildIcon />} 
            label="Production" 
            {...a11yProps(2)}
          />
          <Tab 
            icon={<CampaignIcon />} 
            label="Marketing" 
            {...a11yProps(3)}
          />
          <Tab 
            icon={<MoneyOffIcon />} 
            label="Other Expenses" 
            {...a11yProps(4)}
          />
          <Tab 
            icon={<AttachMoneyIcon />} 
            label="INCOME" 
            {...a11yProps(5)}
          />
        </Tabs>

        {error && (
          <Alert 
            severity="error" 
            sx={{ m: 3 }}
            onClose={() => dispatch(setError(null))}
          >
            {error}
          </Alert>
        )}

        <TabPanel value={activeTab} index={0}>
          <ArtistTravelPlanner />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <VenuePlanner />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <ProductionPlanner />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <MarketingPlanner />
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <OtherExpensesPlanner />
        </TabPanel>
        <TabPanel value={activeTab} index={5}>
          <IncomePlanner />
        </TabPanel>
      </Paper>

      {/* Budget Summary */}
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          <BudgetSummary />
          <BudgetDebugVisualizer /> {/* Temporary, for debugging */}
        </Paper>
      </Box>

      {/* Create Budget Dialog */}
      <CreateBudgetDialog
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        onSubmit={handleCreateBudget}
        strands={strands}
      />



      {/* Modified Save Button */}
      {(unsavedChanges || isNewBudget) && (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave}
          sx={{ 
            position: 'fixed', 
            bottom: { xs: 16, sm: 32 }, 
            right: { xs: 16, sm: 32 },
            zIndex: 1000
          }}
        >
          {isNewBudget ? 'Create Budget' : 'Save Changes'}
        </Button>
      )}
    </Box>
  );
};

export default BudgetPlanner;