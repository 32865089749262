import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Typography, Box } from '@mui/material';
import { GroupBudgetLine } from '../../../../types/groupBudget';

interface ExpenseIncomePieChartProps {
  expenseLines: GroupBudgetLine[];
  incomeLines: GroupBudgetLine[];
}

const ExpenseIncomePieChart: React.FC<ExpenseIncomePieChartProps> = ({
  expenseLines,
  incomeLines,
}) => {
  const totalExpenses = React.useMemo(() => {
    return expenseLines.reduce((sum, line) => {
      const amt = (line.quantity || 0) * (line.value || 0) * (line.multiplier || 0);
      return sum + amt;
    }, 0);
  }, [expenseLines]);

  const totalIncome = React.useMemo(() => {
    return incomeLines.reduce((sum, line) => {
      const amt = (line.quantity || 0) * (line.value || 0) * (line.multiplier || 0);
      return sum + amt;
    }, 0);
  }, [incomeLines]);

  const data = React.useMemo(() => {
    return [
      {
        name: 'Expenses',
        value: totalExpenses,
        color: '#FF6B6B',
      },
      {
        name: 'Income',
        value: totalIncome,
        color: '#4ECDC4',
      },
    ];
  }, [totalExpenses, totalIncome]);

  const total = data.reduce((acc, cur) => acc + cur.value, 0);

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload || !payload[0]) return null;
    const item = payload[0].payload;
    const ratio = ((item.value / total) * 100).toFixed(1);
    return (
      <Box sx={{ p: 1, bgcolor: 'rgba(0,0,0,0.8)', borderRadius: 1 }}>
        <Typography variant="body2" color="white">
          {item.name}
        </Typography>
        <Typography variant="body2" color="white">
          €{Math.round(item.value).toLocaleString()} ({ratio}%)
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Expenses vs Income
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={100}
          >
            {data.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default ExpenseIncomePieChart;