import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  Container, Typography, Box, Grid, Card, CardContent, CircularProgress,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete,
  Snackbar,
  Alert
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import ProgrammingProspects from '../components/ProgrammingProspects';
import StrandDateManager from '../components/strands/StrandDateManager';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloneDialog from '../components/strands/CloneDialog';  // adjust path as needed
import ArchiveIcon from '@mui/icons-material/Archive';
import EventPlannerModal from '../components/strands/EventPlannerModal';
import axiosinstance from '../utils/axiosConfig';

import axios from 'axios';

// Import types
import { Venue, Performance, Personnel, BandRecommendation } from '../types/mongodb';
import axiosInstance from '../utils/axiosConfig';

export interface StrandDate {
  id: string;
  date: Date;
  status: 'tentative' | 'confirmed' | 'cancelled';
}

interface Strand {
  _id: string;
  name: string;
  year: number;
  slug: string;
  description: string;
  type: 'domestic' | 'international';
  startDate: string;
  endDate: string;
  eventType: string;
  status: 'planned' | 'confirmed' | 'cancelled';
  dates: StrandDate[]; // Changed from StrandDates to StrandDate[] (array)
  published: boolean;
  mainImage: string;
  artsCouncilActivityType: string;
  artsCouncilActivityTarget: string;
  artsCouncilStatus: string;
  overallFinance: {
    totalIncome: number;
    totalExpenditure: number;
  };
  associations: {
    venues: string[];
    performances: string[];
    strands: string[];
    personnel: string[];
    bands: string[];
    applications: string[];
  };
  employmentFigures: {
    artists: number;
    others: number;
    associatedEmploy: number;
    volunteers: number;
  };
  audiences: {
    venuesCaps: number;
    paying: number;
    free: number;
    total: number;
    ratio: number;
  };
  numberOfProposedEvents: number;
  archived: boolean;
  archiveDate?: Date;
  archiveReason?: string;
}

const StrandDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [strand, setStrand] = useState<Strand | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
    const [isEventPlannerOpen, setIsEventPlannerOpen] = useState(false);
    const [plannerOpen, setPlannerOpen] = useState(false);
    const [allStrands, setAllStrands] = useState<Strand[]>([]);
    const [existingEvents, setExistingEvents] = useState<any[]>([]);
    const handleOpenEventPlanner = () => {
      setIsEventPlannerOpen(true);
    };
  
    const handleCloseEventPlanner = () => {
      setIsEventPlannerOpen(false);
      // Optionally trigger a reload of StrandDateManager to get the latest data
    };
    const handlePlannerOpen = () => {
      setPlannerOpen(true);
    };
  
    const handlePlannerClose = () => {
      setPlannerOpen(false);
    };
    const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
      open: false,
      message: '',
      severity: 'success'
    });
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [archiveReason, setArchiveReason] = useState('');

  // State for available associations
  const [availableVenues, setAvailableVenues] = useState<Venue[]>([]);
  const [availablePerformances, setAvailablePerformances] = useState<Performance[]>([]);
  const [availablePersonnel, setAvailablePersonnel] = useState<Personnel[]>([]);
  const [availableBands, setAvailableBands] = useState<BandRecommendation[]>([]);

  // State for selected associations
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);
  const [selectedPerformances, setSelectedPerformances] = useState<Performance[]>([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState<Personnel[]>([]);
  const [selectedBands, setSelectedBands] = useState<BandRecommendation[]>([]);

 
  const fetchData = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);

        // Fetch strand details
        const strandResponse = await axiosInstance.get<Strand>(`/api/strands/${id}`);
        const strandData = strandResponse.data;
        setStrand(strandData);

        // Fetch available associations
        const [venuesRes, performancesRes, personnelRes, bandsRes] = await Promise.all([
          axiosInstance.get<Venue[]>(`/api/venues`),
          axiosInstance.get<Performance[]>(`/api/performances`),
          axiosInstance.get<Personnel[]>(`/api/personnel`),
          axiosInstance.get<BandRecommendation[]>(`/api/bandRecommendations`),
        ]);

        setAvailableVenues(venuesRes.data);
        setAvailablePerformances(performancesRes.data);
        setAvailablePersonnel(personnelRes.data);
        setAvailableBands(bandsRes.data);

        // Map IDs to objects for selected associations
        const selectedVenues = venuesRes.data.filter(venue => strandData.associations.venues.includes(venue._id));
        const selectedPerformances = performancesRes.data.filter(performance => strandData.associations.performances.includes(performance._id));
        const selectedPersonnel = personnelRes.data.filter(person => strandData.associations.personnel.includes(person._id));
        const selectedBands = bandsRes.data.filter(band => band._id && strandData.associations.bands.includes(band._id));

        setSelectedVenues(selectedVenues);
        setSelectedPerformances(selectedPerformances);
        setSelectedPersonnel(selectedPersonnel);
        setSelectedBands(selectedBands);

        setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An unexpected error occurred while fetching data.');
      setSnackbar({
        open: true,
        message: 'Failed to fetch strand data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, [id]);


  const fetchAllDatesAndPerformances = useCallback(async () => {
    try {
      const [strandsResponse, provisionalDatesResponse, performancesResponse] = await Promise.all([
        axiosInstance.get('/api/strands'),
        axiosInstance.get('/api/provisional-dates'),
        axiosInstance.get('/api/performances')  // Remove status filter
      ]);
  
      setAllStrands(strandsResponse.data);
  
      // Convert provisional dates to events
      const provisionalEvents = provisionalDatesResponse.data
        .filter((pd: any) => pd.strandId !== id)
        .flatMap((pd: any) => {
          const strand = strandsResponse.data.find((s: Strand) => s._id === pd.strandId);
          return pd.planningDates.map((date: any) => ({
            id: date.id,
            title: `${strand?.name || 'Unknown Strand'} (${date.status})`,
            start: date.date,
            strandId: pd.strandId,
            strandName: strand?.name || 'Unknown Strand',
            color: strand?.type === 'domestic' ? '#4CAF50' : '#2196F3',
            status: date.status,
            type: 'provisional'
          }));
        });
  
      // Convert confirmed performances to events
      const performanceEvents = performancesResponse.data.map((performance: any) => ({
        id: performance._id,
        title: `${performance.simpleBandName || performance.band} - CONFIRMED`,
        start: performance.date,
        color: '#f44336',
        type: 'performance',
        performanceDetails: performance
      }));
  
      setExistingEvents([...provisionalEvents, ...performanceEvents]);
    } catch (error) {
      console.error('Error fetching dates and performances:', error);
      // Maybe set an error state here
    }
  }, [id]); // Add id to dependencies
  

  useEffect(() => {
    fetchData();
    fetchAllDatesAndPerformances();
  }, [fetchData, fetchAllDatesAndPerformances]);

  
  const handleEdit = () => {
    navigate(`/strands/edit/${id}`);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (strand) {
      try {
        await axiosInstance.delete(`/api/strands/${strand._id}`);
        setOpenDeleteDialog(false);
        navigate('/admin/strands');
      } catch (error) {
        console.error('Error deleting strand:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  const handlePublish = () => {
    setOpenPublishDialog(true);
  };

  const confirmPublish = async () => {
    if (strand) {
      try {
        await axiosInstance.patch(`/api/strands/${strand._id}`, { published: !strand.published });
        setStrand({ ...strand, published: !strand.published });
        setOpenPublishDialog(false);
      } catch (error) {
        console.error('Error publishing strand:', error);
        // Handle error (e.g., show error message)
      }
    }
  };

  const handleSaveAssociations = async () => {
    if (strand) {
      try {
        setLoading(true);

        // Prepare the data
        const updatedAssociations = {
          venues: selectedVenues.map((venue) => venue._id),
          performances: selectedPerformances.map((performance) => performance._id),
          personnel: selectedPersonnel.map((person) => person._id),
          bands: selectedBands
          .map((band) => band._id)
          .filter((id): id is string => id !== undefined),
         applications: strand.associations.applications,
          strands: strand.associations.strands,
        };

        // Send the update request to the API
        await axiosInstance.patch(`/api/strands/${strand._id}`, {
          associations: updatedAssociations,
        });

        // Update the strand state with new associations
        setStrand({
          ...strand,
          associations: updatedAssociations,
        });

        // Optionally, display a success message
        alert('Associations updated successfully.');
      } catch (error) {
        console.error('Error updating associations:', error);
        // Handle error (e.g., show error message)
        alert('Failed to update associations. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleProspectsChange = () => {
    fetchData();
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!strand) return <Typography>Strand not found.</Typography>;

  const employmentData = [
    { name: 'Artists', value: strand.employmentFigures.artists, color: '#8884d8' },
    { name: 'Others', value: strand.employmentFigures.others, color: '#82ca9d' },
    { name: 'Associated', value: strand.employmentFigures.associatedEmploy, color: '#ffc658' },
    { name: 'Volunteers', value: strand.employmentFigures.volunteers, color: '#ff8042' },
  ];

  const handleArchive = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`/api/strands/${strand._id}/archive`, {
        archived: !strand.archived,
        archiveReason: archiveReason
      });
         // Update local state
    setStrand(prev => prev ? {
      ...prev,
      archived: !prev.archived,
      archiveDate: !prev.archived ? new Date() : undefined,
      archiveReason: !prev.archived ? archiveReason : undefined
    } : null);
    
    setSnackbar({
      open: true,
      message: `Strand successfully ${strand.archived ? 'unarchived' : 'archived'}`,
      severity: 'success'
    });
    setOpenArchiveDialog(false);
  } catch (error) {
    console.error('Error archiving strand:', error);
    setSnackbar({
      open: true,
      message: 'Failed to archive strand',
      severity: 'error'
    });
  } finally {
    setLoading(false);
  }
};


  const handleClone = async (year: number) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/api/strands/${strand._id}/clone`, {
        year
      });
          // Navigate to the new strand
    navigate(`/admin/strands/${response.data._id}`);
    
    setSnackbar({
      open: true,
      message: 'Strand cloned successfully',
      severity: 'success'
    });
  } catch (error) {
    console.error('Error cloning strand:', error);
    setSnackbar({
      open: true,
      message: 'Failed to clone strand',
      severity: 'error'
    });
  } finally {
    setLoading(false);
  }
};


  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>{strand.name} ({strand.year})</Typography>
        <Button 
        variant="contained" 
        color="primary" 
        onClick={handlePlannerOpen}
      >
        Plan Events
      </Button>

      {/* Event Planner Modal */}
      <EventPlannerModal
  open={plannerOpen}
  onClose={handlePlannerClose}
  currentStrand={strand ? {
    id: strand._id,
    name: strand.name,
    targetDates: strand.numberOfProposedEvents,
    type: strand.type,
    dates: strand.dates?.map(date => ({
      id: date.id,
      startDate: new Date(date.date).toISOString(), // Set both startDate and endDate to the same value
      endDate: new Date(date.date).toISOString()
    })) || []
  } : null}
  allStrands={allStrands.map(s => ({
    id: s._id,
    name: s.name,
    targetDates: s.numberOfProposedEvents,
    type: s.type
  }))}
  existingEvents={existingEvents}
/>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Basic Information</Typography>
                <Typography><strong>Type:</strong> {strand.type}</Typography>
                <Typography><strong>Year:</strong> {strand.year}</Typography>
                <Typography><strong>Event Type:</strong> {strand.eventType}</Typography>
                <Typography><strong>Number of Proposed Events:</strong> {strand.numberOfProposedEvents}</Typography>
                <Typography><strong>Status:</strong> {strand.status}</Typography>
                <Typography><strong>Published:</strong> {strand.published ? 'Yes' : 'No'}</Typography>
                <Typography><strong>Start Date:</strong> {new Date(strand.startDate).toLocaleDateString()}</Typography>
                <Typography><strong>End Date:</strong> {new Date(strand.endDate).toLocaleDateString()}</Typography>
              </CardContent>
            </Card>

            <Box mt={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Description</Typography>
                  <Typography>{strand.description}</Typography>
                </CardContent>
              </Card>
            </Box>

            <Box mt={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Arts Council Information</Typography>
                  <Typography><strong>Activity Type:</strong> {strand.artsCouncilActivityType}</Typography>
                  <Typography><strong>Activity Target:</strong> {strand.artsCouncilActivityTarget}</Typography>
                  <Typography><strong>Status:</strong> {strand.artsCouncilStatus}</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
          {/* Actions */}
<Card sx={{ mb: 2 }}>
  <CardContent>
    <Typography variant="h6" gutterBottom>Actions</Typography>
    <Button variant="contained" color="primary" onClick={handleEdit} fullWidth sx={{ mb: 1 }}>
      <EditIcon sx={{ mr: 1 }} />
      Edit
    </Button>
    <Button variant="contained" color="secondary" onClick={handlePublish} fullWidth sx={{ mb: 1 }}>
      <PublishIcon sx={{ mr: 1 }} />
      {strand.published ? 'Unpublish' : 'Publish'}
    </Button>
    <Button 
  variant="contained" 
  color="info" 
  onClick={() => setCloneDialogOpen(true)}
  fullWidth 
  sx={{ mb: 1 }}
>
  <ContentCopyIcon sx={{ mr: 1 }} />
  Clone Strand
</Button>
    <Button 
      variant="contained" 
      component={Link} 
      to="/strands/guidelines/new" 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Create Guidelines
    </Button>
    <Button 
      variant="contained" 
      color="info" 
      onClick={() => alert('Application creation coming soon!')} 
      fullWidth 
      sx={{ mb: 1 }}
    >
      Create Application
    </Button>
    <Button 
  variant="contained" 
  color={strand.archived ? "warning" : "secondary"}
  onClick={() => setOpenArchiveDialog(true)} 
  fullWidth 
  sx={{ mb: 1 }}
>
  <ArchiveIcon sx={{ mr: 1 }} />
  {strand.archived ? 'Unarchive' : 'Archive'} Strand
</Button>

    <Button variant="contained" color="error" onClick={handleDelete} fullWidth>
      <DeleteIcon sx={{ mr: 1 }} />
      Delete
    </Button>
  </CardContent>
</Card>

            {/* Financial Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Financial Information</Typography>
                <Typography><strong>Total Income:</strong> €{strand.overallFinance.totalIncome}</Typography>
                <Typography><strong>Total Expenditure:</strong> €{strand.overallFinance.totalExpenditure}</Typography>
              </CardContent>
            </Card>

            {/* Audience Information */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Audience Information</Typography>
                <Typography><strong>Venue Capacity:</strong> {strand.audiences.venuesCaps}</Typography>
                <Typography><strong>Paying Audience:</strong> {strand.audiences.paying}</Typography>
                <Typography><strong>Free Audience:</strong> {strand.audiences.free}</Typography>
                <Typography><strong>Total Audience:</strong> {strand.audiences.total}</Typography>
                <Typography><strong>Audience Ratio:</strong> {strand.audiences.ratio}%</Typography>
              </CardContent>
            </Card>

            {/* Employment Figures */}
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>Employment Figures</Typography>
                <Box height={200}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={employmentData}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={70}
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {employmentData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Associations */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Associations</Typography>
                <Grid container spacing={2}>
                  {/* Venues Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availableVenues}
                      getOptionLabel={(option) => option.name}
                      value={selectedVenues}
                      onChange={(event, newValue) => {
                        setSelectedVenues(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Venues" placeholder="Select Venues" />
                      )}
                    />
                  </Grid>

                  {/* Performances Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
  multiple
  options={availablePerformances}
  getOptionLabel={(option) => {
    // Determine the band name
    const bandName =
      typeof option.simpleBandName === 'string'
        ? option.simpleBandName
        : typeof option.band === 'string'
        ? option.band
        : (option.band as BandRecommendation)?.artistName || 'Unknown Band';

    // Determine the event name if it exists
    const eventName =
      typeof option.event === 'string'
        ? option.event
        : (option.event as any)?.name || null;

    // Return label with or without event name
    return eventName ? `${bandName} at ${eventName}` : bandName;
  }}
  value={selectedPerformances}
  onChange={(event, newValue) => {
    setSelectedPerformances(newValue);
  }}
  renderInput={(params) => (
    <TextField {...params} variant="outlined" label="Performances" placeholder="Select Performances" />
  )}
/>
                  </Grid>

                  {/* Personnel Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availablePersonnel}
                      getOptionLabel={(option) => option.name}
                      value={selectedPersonnel}
                      onChange={(event, newValue) => {
                        setSelectedPersonnel(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Personnel" placeholder="Select Personnel" />
                      )}
                    />
                  </Grid>

                  {/* Bands Multi-Select */}
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      multiple
                      options={availableBands}
                      getOptionLabel={(option) => option.artistName}
                      value={selectedBands}
                      onChange={(event, newValue) => {
                        setSelectedBands(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Bands" placeholder="Select Bands" />
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveAssociations}
                  sx={{ mt: 2 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Save Associations'}
                </Button>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </Box>
      <CloneDialog
  open={cloneDialogOpen}
  onClose={() => setCloneDialogOpen(false)}
  onConfirm={handleClone}
  strandName={strand.name}
  currentYear={strand.year}
/>
      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete <strong>{strand.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

          {/* Programming Prospects */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <ProgrammingProspects 
                  strandId={strand._id}
                  strandStartDate={strand.startDate}
                  strandEndDate={strand.endDate}
                  onProspectsChange={handleProspectsChange}
                />
<StrandDateManager
  strandId={strand._id}
  strandStartDate={strand.startDate}
  strandEndDate={strand.endDate}
  initialDates={strand.dates} // Now an array
  onDatesChange={(newDates: StrandDate[]) => { // Changed type to StrandDate[]
    setStrand({ ...strand, dates: newDates });
  }}
/>
              </CardContent>
            </Card>
</Grid>
<Dialog open={openArchiveDialog} onClose={() => setOpenArchiveDialog(false)}>
  <DialogTitle>{strand.archived ? 'Unarchive' : 'Archive'} Strand</DialogTitle>
  <DialogContent>
    <Typography gutterBottom>
      {strand.archived 
        ? 'Are you sure you want to unarchive this strand?' 
        : 'Are you sure you want to archive this strand?'}
    </Typography>
    {!strand.archived && (
      <TextField
        fullWidth
        label="Archive Reason"
        multiline
        rows={3}
        value={archiveReason}
        onChange={(e) => setArchiveReason(e.target.value)}
        margin="normal"
        required
      />
    )}
    <Typography color="warning.main" sx={{ mt: 2 }}>
      {strand.archived ? (
        'Unarchiving will make this strand visible in regular searches and lists again.'
      ) : (
        'Archiving will:'
        + '\n• Hide this strand from regular searches and lists'
        + '\n• Maintain all historical data and associations'
        + '\n• Keep related performances and events intact'
        + '\n• Not affect any existing reports or analytics'
      )}
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenArchiveDialog(false)}>Cancel</Button>
    <Button 
      onClick={handleArchive} 
      variant="contained" 
      color={strand.archived ? "warning" : "info"}
      disabled={!strand.archived && !archiveReason}
    >
      {strand.archived ? 'Unarchive' : 'Archive'}
    </Button>
  </DialogActions>
</Dialog>
      {/* Publish Confirmation Dialog */}
      <Dialog open={openPublishDialog} onClose={() => setOpenPublishDialog(false)}>
        <DialogTitle>{strand.published ? 'Unpublish' : 'Publish'} Strand</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to {strand.published ? 'unpublish' : 'publish'} <strong>{strand.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPublishDialog(false)}>Cancel</Button>
          <Button onClick={confirmPublish} variant="contained" color="primary">
            {strand.published ? 'Unpublish' : 'Publish'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}

        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StrandDetailPage;