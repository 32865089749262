import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  Card,
  Box,
  Typography,
  Chip,
  LinearProgress,
  Stack,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
    Construction as ConstructionIcon,
    Build as BuildIcon,
    Settings as SettingsIcon,
    TrendingUp as TrendingUpIcon,
    PushPin as PushPinIcon,
    SportsBar as SportsBarIcon,
    TheaterComedy as TheaterComedyIcon,
    Assignment as AssignmentIcon,
    AcUnit as AcUnitIcon,
    Announcement as AnnouncementIcon,
    Newspaper as NewspaperIcon,
    Warehouse as WarehouseIcon,
    Thermostat as ThermostatIcon,
    Mic as MicIcon,
    RocketLaunch as RocketLaunchIcon,
    Lightbulb as LightbulbIcon,
    VolumeUp as VolumeUpIcon,
    Movie as MovieIcon,
    Whatshot as WhatshotIcon,
    MusicNote as MusicNoteIcon,
    Security as SecurityIcon,
  } from '@mui/icons-material';
import {
  CheckCircle,
  Clock,
  ChevronRight,
  ChevronDown,
  Image as ImageIcon,
  Calendar,
  Wrench,
  User,
} from 'lucide-react';
import { Edit2, Trash2 } from 'lucide-react';
import axiosInstance from '../../../../utils/axiosConfig';
import { Task, TASK_PRIORITIES, TASK_CATEGORIES, TaskCategory } from '../types/taskTypes';
import { Personnel } from '../../../../types/Personnel';
import SubtaskList from './SubtaskList';

interface TaskCardProps {
  task: Task;
  index: number;
  onEdit?: (task: Task) => void;
  onDelete?: (taskId: string) => void;
  onRefresh?: () => void;
  setTasks?: React.Dispatch<React.SetStateAction<Task[]>>;
  personnel?: Array<{ id: string; name: string }>;  // Add this if not already present
}

  // Get category icon and color if available
  const getCategoryIcon = (category: string) => {
    const iconMap = {
      repair: ConstructionIcon,
      maintenance: BuildIcon,
      setup: SettingsIcon,
      improvement: TrendingUpIcon,
      general: PushPinIcon,
      bar_area: SportsBarIcon,
      stage: TheaterComedyIcon,
      organisation: AssignmentIcon,
      cooler_general: AcUnitIcon,
      notice_board: AnnouncementIcon,
      outside_press: NewspaperIcon,
      presses_storage: WarehouseIcon,
      cooler_space: ThermostatIcon,
      audience_experience: MicIcon,
      throughput: RocketLaunchIcon,
      lighting: LightbulbIcon,
      sound: VolumeUpIcon,
      projectors: MovieIcon,
      security: SecurityIcon,
      heating: WhatshotIcon,
      rehearsals: MusicNoteIcon,
    };
  
    return iconMap[category as keyof typeof iconMap];
  };

  const TaskCard: React.FC<TaskCardProps> = ({
    task,
    index,
    onEdit,
    onDelete,
    onRefresh,
    setTasks,
    personnel = []
  }) => {
    const [expanded, setExpanded] = useState(() => {
      const savedState = localStorage.getItem(`task-${task.id}-expanded`);
      return savedState ? JSON.parse(savedState) : false;
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [assigneeName, setAssigneeName] = useState<string>('Loading...');
  const IconComponent = task.category ? getCategoryIcon(task.category) : null;
const iconColor = task.category ? TASK_CATEGORIES[task.category as TaskCategory]?.color : null;


useEffect(() => {
    const fetchAssigneeName = async () => {
      if (!task.assignedTo) {
        setAssigneeName('Unassigned');
        return;
      }

      if (typeof task.assignedTo === 'object' && task.assignedTo.name) {
        setAssigneeName(task.assignedTo.name);
        return;
      }

      try {
        const response = await axiosInstance.post('/api/personnel/bulk', { 
          ids: [task.assignedTo]
        });
        if (response.data && response.data[0]) {
          setAssigneeName(response.data[0].name);
        } else {
          setAssigneeName('Unknown');
        }
      } catch (error) {
        console.error('Error fetching assignee name:', error);
        setAssigneeName('Error');
      }
    };

    fetchAssigneeName();
  }, [task.assignedTo]);

  useEffect(() => {
    localStorage.setItem(`task-${task.id}-expanded`, JSON.stringify(expanded));
  }, [expanded, task.id]);

  const handleProgressToggle = async () => {
    if (isUpdating) return;
    setIsUpdating(true);

    if (setTasks) {
      setTasks(currentTasks => 
        currentTasks.map(t => 
          t.id === task.id 
            ? { ...t, progress: t.progress === 100 ? 0 : 100 }
            : t
        )
      );
    }

    try {
      await axiosInstance.patch(`/api/taskboards/${task.id}/progress`, {
        progress: task.progress === 100 ? 0 : 100
      });
    } catch (err) {
      console.error('Failed to update progress:', err);
      if (setTasks) {
        setTasks(currentTasks => 
          currentTasks.map(t => 
            t.id === task.id 
              ? { ...t, progress: task.progress }
              : t
          )
        );
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSubtaskToggle = async (subtaskId: string) => {
    if (isUpdating) return;
    setIsUpdating(true);

    const subtask = task.subtasks.find(s => s.id === subtaskId);
    if (!subtask) return;

    if (setTasks) {
      setTasks(currentTasks => 
        currentTasks.map(t => {
          if (t.id === task.id) {
            return {
              ...t,
              subtasks: t.subtasks.map(s => 
                s.id === subtaskId 
                  ? { ...s, completed: !s.completed }
                  : s
              )
            };
          }
          return t;
        })
      );
    }

    try {
        const res = await axiosInstance.patch(`/api/taskboards/${task.id}/subtasks/${subtaskId}`);
        console.log('Task API Response:', res.data);  // ✅ Now `res` is defined
    } catch (err) {
      console.error('Failed to toggle subtask:', err);
      if (setTasks) {
        setTasks(currentTasks =>
          currentTasks.map(t => {
            if (t.id === task.id) {
              return {
                ...t,
                subtasks: t.subtasks.map(s =>
                  s.id === subtaskId
                    ? { ...s, completed: subtask.completed }
                    : s
                )
              };
            }
            return t;
          })
        );
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const assignedUserName = task.assignedTo 
  ? typeof task.assignedTo === 'object' 
    ? (task.assignedTo as { name: string }).name
    : typeof task.assignedTo === 'string'
      ? task.assignedTo 
      : 'Loading...'
  : 'Unassigned';

// Debug logging
console.log('Task category debug:', {
    taskId: task.id,
    category: task.category,
    categoryIcon: task.category ? TASK_CATEGORIES[task.category as TaskCategory] : null,
    availableCategories: Object.keys(TASK_CATEGORIES)
  });
  
  // Get category icon and color
  const categoryInfo = task.category ? TASK_CATEGORIES[task.category as TaskCategory] : null;
  const Icon = categoryInfo?.icon;

  
  return (
<Draggable draggableId={task.id} index={index}>
  {(provided) => (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="hover:shadow-lg transition-shadow"
      sx={{ 
        position: 'relative', // This is crucial
        overflow: 'visible'
      }}
    >
      <Box p={2} sx={{ position: 'relative' }}> {/* Also make this relative */}
      <Stack spacing={2}>
              {/* Header */}
              <Stack direction="row" spacing={2} alignItems="center">
                <IconButton size="small" onClick={() => setExpanded(!expanded)}>
                  {expanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                </IconButton>
                
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={task.progress === 100}
                      onChange={handleProgressToggle}
                      disabled={isUpdating}
                    />
                  }
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        variant="h5"
                        sx={{
                          textDecoration: task.progress === 100 ? 'line-through' : 'none',
                          color: task.progress === 100 ? 'text.secondary' : 'text.primary'
                        }}
                      >
                        {task.title}
                      </Typography>
                      <Chip
            size="small"
            icon={<User size={18} />}
            label={assigneeName}
            color="default"
          />
                    </Stack>
                  }
                  sx={{ flex: 1, margin: 0 }}
                />

                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEdit?.(task);
                    }}
                  >
                    <Edit2 size={16} />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete?.(task.id);
                    }}
                  >
                    <Trash2 size={16} />
                  </IconButton>
                  <Chip
  size="small"
  icon={task.progress === 100 ? <CheckCircle size={20} /> : <Clock size={20} />}
  label={
    <Typography variant="h5" fontWeight="bold">
      {`${task.progress}%`}
    </Typography>
  }
  color={task.progress === 100 ? 'success' : 'default'}
/>
                </Stack>
              </Stack>

              <Stack direction="row" spacing={1} flexWrap="wrap">
                <Chip
                  size="small"
                  label={TASK_PRIORITIES[task.priority]}
                />
                <Chip
                  size="small"
                  icon={<Calendar size={18} />}
                  label={new Date(task.dueDate).toLocaleDateString()}
                />
              </Stack>

              {expanded && (
                <Stack spacing={2}>
                  {task.description && (
                    <Typography variant="body2" color="text.secondary">
                      {task.description}
                    </Typography>
                  )}

{task.subtasks && task.subtasks.length > 0 && (
  <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <Typography variant="h6" gutterBottom>
      Subtasks
    </Typography>
    <SubtaskList 
      subtasks={task.subtasks}
      onToggle={handleSubtaskToggle}
      isUpdating={isUpdating}
    />
  </Box>
)}

                  {task.tools && task.tools.length > 0 && (
                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        <Wrench size={16} className="inline mr-2" />
                        Tools
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {task.tools.map((tool) => (
                          <Chip
                            key={tool.id}
                            size="small"
                            label={`${tool.quantity}x ${tool.inventoryItem.name}`}
                          />
                        ))}
                      </Stack>
                    </Box>
                  )}

                  {task.images && task.images.length > 0 && (
                    <Box>
                      <Typography variant="subtitle2" gutterBottom>
                        <ImageIcon size={16} className="inline mr-2" />
                        Images
                      </Typography>
                      <Stack direction="row" spacing={1} sx={{ overflowX: 'auto' }}>
                        {task.images.map((image) => (
                          <img
                            key={image.id}
                            src={image.url}
                            alt="Task attachment"
                            className="h-24 w-24 object-cover rounded"
                          />
                        ))}
                      </Stack>
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>

          {/* Category Icon */}
          {task.category && IconComponent && (
  <Box
    sx={{
      position: 'absolute',
      bottom: 8,  // Changed from top to bottom
      right: 8,
      width: 48,
      height: 48,
      borderRadius: '50%',
      backgroundColor: 'background.paper',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      border: '1px solid',
      borderColor: 'divider',
      zIndex: 2,
    }}
  >
    <IconComponent
      sx={{
        fontSize: 24,
        color: iconColor || 'inherit'
      }}
    />
  </Box>
)}
        </Card>
      )}
    </Draggable>
  );
};

export default TaskCard;