// src/constants/imcCodes.ts
import { SvgIconComponent } from '@mui/icons-material';

import {
  // Artist/Performance
  MusicNote,
  TheaterComedy,
  Mic,
  
  // Ticketing/Sales
  LocalActivity,
  ConfirmationNumber,
  PointOfSale,
  
  // Financial
  AccountBalance,
  AccountBalanceWallet,
  Receipt,
  Payments,
  AttachMoney,
  Payment,
  LocalOffer,
  
  // Venue/Facilities
  Store,
  Business,
  LocationOn,
  
  // Marketing
  Campaign,
  Brush,
  PhotoCamera,
  Videocam,
  Web,
  
  // Production/Technical
  Build,
  Speaker,
  LightbulbOutlined,
  Engineering,
  SettingsInputComponent,
  
  // Personnel
  People,
  Group,
  ManageAccounts,
  Engineering as EngineeringPeople,
  Construction,
  
  // Travel/Logistics 
  LocalShipping,
  FlightTakeoff,
  DirectionsCar,
  Hotel,
  Restaurant,
  
  // Administrative
  BusinessCenter,
  Folder,
  Storage,
  Cloud,
  School,
  
  // Insurance/Legal
  Security,
  Gavel,
  
  // IT/Software
  Computer,
  DevicesOther,
  PhoneIphone,
  Wifi
} from '@mui/icons-material';

export interface IMCIconMap {
  // Artist/Performance
  ARTIST_FEES: typeof MusicNote;
  PERFORMANCE: typeof TheaterComedy;
  RECORDING: typeof Mic;
  
  // Ticketing
  TICKETS: typeof LocalActivity;
  BOX_OFFICE: typeof PointOfSale;
  
  // Financial
  GRANTS: typeof AccountBalance;
  INCOME: typeof AttachMoney;
  EXPENSES: typeof Receipt;
  BANKING: typeof AccountBalanceWallet;
  
  // Venue
  VENUE_HIRE: typeof Store;
  LOCATION: typeof LocationOn;
  
  // Marketing
  MARKETING: typeof Campaign;
  DESIGN: typeof Brush;
  PHOTO: typeof PhotoCamera;
  VIDEO: typeof Videocam;
  WEBSITE: typeof Web;
  
  // Production
  EQUIPMENT: typeof Build;
  SOUND: typeof Speaker;
  LIGHTING: typeof LightbulbOutlined;
  STAGING: typeof Engineering;
  TECHNICAL: typeof SettingsInputComponent;
  
  // Personnel
  STAFF: typeof People;
  CREW: typeof Group;
  MANAGEMENT: typeof ManageAccounts;
  ENGINEERS: typeof EngineeringPeople;
  CONSTRUCTION: typeof Construction;
  
  // Travel
  SHIPPING: typeof LocalShipping;
  FLIGHTS: typeof FlightTakeoff;
  TRANSPORT: typeof DirectionsCar;
  ACCOMMODATION: typeof Hotel;
  CATERING: typeof Restaurant;
  
  // Administrative
  OFFICE: typeof BusinessCenter;
  DOCUMENTS: typeof Folder;
  STORAGE: typeof Storage;
  CLOUD: typeof Cloud;
  TRAINING: typeof School;
  
  // Insurance/Legal
  INSURANCE: typeof Security;
  LEGAL: typeof Gavel;
  
  // IT/Software
  COMPUTER: typeof Computer;
  DEVICES: typeof DevicesOther;
  MOBILE: typeof PhoneIphone;
  INTERNET: typeof Wifi;
}

export type IMCCategory = 'income' | 'expenses';

export type IMCSubCategory = 
  // Main Categories
  | 'artist'
  | 'venue'
  | 'sponsorship'
  | 'fundraising' 
  | 'production'
  | 'marketing'
  | 'grants'
  | 'ticketing'
  | 'merchandise'
  | 'services'
  | 'administrative'
  // Sub Categories
  | 'artist_fees'
  | 'staff'
  | 'benefits'
  | 'tax'
  | 'international'
  | 'domestic'
  | 'local' 
  | 'travel'
  | 'accommodation'
  | 'technical'
  | 'equipment'
  | 'transportation' 
  | 'recordings'
  | 'crew'
  | 'venue_hire'
  | 'digital'
  | 'print'
  | 'advertising'
  | 'design'
  | 'media'
  | 'hospitality'
  | 'communication'
  | 'cloud_services'
  | 'software'
  | 'subscriptions'
  | 'professional'
  | 'legal'
  | 'financial'
  | 'insurance'
  | 'facilities'
  | 'maintenance'
  | 'storage'
  | 'shipping'
  | 'office'
  | 'it'
  | 'staff'
  | 'management'
  | 'development'
  | 'collaboration'
  | 'productivity'
  | 'write_off'
  | 'taxes'
  | 'safety'
  | 'sales'
  | 'catering'
  | 'compensation'
  | 'lodging'
  | 'infrastructure'
  | 'public_relations'
  | 'charitable'
  | 'capital_expenditure'
  | 'set_design'
  | 'distribution'
  | 'consulting'
  | 'membership'
  | 'executive'
  | 'hr'
  | 'events'
  | 'supplies'
  | 'printing'
  | 'utilities'
  | 'building'
  | 'non-monetary'
  | 'operational'
  | 'business'
  | 'fees'
  | 'documentation'
  | 'research'
  | 'booking'
  | 'recordings'
  | 'transport'
  | 'commission'  
  | 'cultural' 
  | 'staff_welfare' 
  | 'publications' 
  | 'reimbursement' 
  | 'leasing' 
  | 'scheduling'
  | 'miscellaneous'
  | 'ia-employ'
  | 'a-employ'
  | 'o-employ'
  | 'i-employ'
  | string;

  export interface IMCCodeMetadata {
    code: string;
    label: string;
    category: IMCCategory;
    subCategories: readonly IMCSubCategory[];
    acCode: string;
    icon: SvgIconComponent; 
    tags: readonly string[];
    description: string; 
  }

export const IMC_INCOME_CODES: Record<string, IMCCodeMetadata> = {
  GRANTS_ARTS_COUNCIL: {
    code: '2',
    label: '002 - Grants - Arts Council',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Grant funding - other (including other Arts council funding)',
    icon: AccountBalance,
    tags: ['grant', 'institutional', 'funding'],
    description: 'Core funding from Arts Council'
  },
  IMC_WALK_UP_SALES: {
    code: '20', 
    label: '020 - IMC - Walk Up Sales',
    category: 'income',
    subCategories: ['ticketing'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: PointOfSale,
    tags: ['tickets', 'box-office', 'direct-sales'],
    description: 'Door sales and walk-up ticket purchases'
  },
  IMC_TICKET_SALES: {
    code: '21',
    label: '021 - IMC - Ticket Sales', 
    category: 'income',
    subCategories: ['ticketing'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: LocalActivity,
    tags: ['tickets', 'advance-sales', 'online'],
    description: 'Advance ticket sales through online platforms'
  },
  IMC_TICKET_AGENTS_VENUES: {
    code: '23',
    label: '023 - IMC - Ticket Agents - Venues',
    category: 'income', 
    subCategories: ['ticketing'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Store,
    tags: ['tickets', 'agents', 'venues'],
    description: 'Ticket sales through venue box offices'
  },
  IMC_TICKET_AGENTS_EVENTBRITE: {
    code: '24',
    label: '024 - IMC - Ticket Agents - Tito',
    category: 'income',
    subCategories: ['ticketing'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Web,
    tags: ['tickets', 'tito', 'online'],
    description: 'Ticket sales through tito platform'
  },
  IMC_TICKET_AGENTS_EARLYBIRD: {
    code: '24-1',
    label: '024-1 Ticket Agents - Tito - Earlybird',
    category: 'income',
    subCategories: ['ticketing', 'earlybird'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Web,
    tags: ['tickets', 'tito', 'online', 'earlybird'],
    description: 'Early Bird ticket sales through tito platform'
  },
  IMC_TICKET_AGENTS_STANDARD: {
    code: '24-2',
    label: '024-2 Ticket Agents - Tito - Standard',
    category: 'income',
    subCategories: ['ticketing', 'standard'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Web,
    tags: ['tickets', 'tito', 'online', 'standard'],
    description: 'Standard ticket sales through tito platform'
  },
  IMC_TICKET_AGENTS_PERFORMER_PROMO: {
    code: '24-3',
    label: '024-3 Ticket Agents - Tito - Performer Promo',
    category: 'income',
    subCategories: ['ticketing', 'performer_promo'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Web,
    tags: ['tickets', 'tito', 'online', 'promo'],
    description: 'Performer Promo ticket sales through tito platform'
  },
  SERVICES_PROGRAMME_PRODUCTION_FEES: {
    code: '26',
    label: '026 - Services Programme & Production Fees',
    category: 'income',
    subCategories: ['services'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Build,
    tags: ['services', 'production', 'fees'],
    description: 'Income from production services'
  },
  CD_SALES_MERCHANDISE_OTHER_INCOME: {
    code: '30',
    label: '030 - CD Sales - Merchandise - Other Income',
    category: 'income',
    subCategories: ['merchandise'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: LocalOffer,
    tags: ['merchandise', 'cd', 'sales'],
    description: 'Income from merchandise and CD sales'
  },
  SERVICES_EQUIPMENT_HIRE_OTHER_INCOME: {
    code: '31',
    label: '031 - Services - Equipment Hire - Other Income',
    category: 'income',
    subCategories: ['services'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: Build,
    tags: ['equipment', 'hire', 'rental'],
    description: 'Income from equipment rental'
  },
  MASTERCLASS_SERVICES_OTHER_INCOME: {
    code: '33',
    label: '033 - Masterclass / Services - Other Income',
    category: 'income',
    subCategories: ['services'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: School,
    tags: ['masterclass', 'education', 'services'],
    description: 'Income from masterclasses and educational services'
  },
  TOURING_ARTISTS_SALE_COMMISSION: {
    code: '37',
    label: '037 - Touring Artists - Sale Commission',
    category: 'income',
    subCategories: ['artist'],
    acCode: 'Earned income (Income from ticket sales or sales of publications or art objects)',
    icon: MusicNote, 
    tags: ['commission', 'touring', 'artist'],
    description: 'Commission from touring artist sales'
  },
  GRANTS_OTHER_ARTS_COUNCIL_FUNDING: {
    code: '5',
    label: '005 - Grants - Other Arts Council Funding',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Grant funding - other (including other Arts council funding)',
    icon: AccountBalance,
    tags: ['grants', 'arts-council', 'funding'],
    description: 'Additional Arts Council funding streams'
  },
  GRANTS_LOCAL_AUTHORITY_FUNDING: {
    code: '7',
    label: '007 - Grants - Local Authority Funding',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Local authority funding',
    icon: AccountBalance,
    tags: ['grants', 'local', 'authority'],
    description: 'Funding from local government authorities'
  },
  GRANTS_OTHER_NATIONAL_FUNDING: {
    code: '6',
    label: '006 - Grants - Other National Funding',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Grant funding - national (excluding Arts Council)',
    icon: AccountBalance,
    tags: ['grants', 'national', 'funding'],
    description: 'Other national funding sources'
  },
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_DOMESTIC: {
    code: '8',
    label: '008 - Grants/Sponsorship - Cultural Agency - Domestic',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Grant funding - national (excluding Arts Council)',
    icon: AccountBalance,
    tags: ['sponsorship', 'cultural', 'domestic'],
    description: 'Domestic cultural agency sponsorship'
  },
  GRANTS_SPONSORSHIP_CULTURAL_AGENCY_INTERNATIONAL: {
    code: '9',
    label: '009 - Grants/Sponsorship - Cultural Agency - International',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Other Income (please specify)',
    icon: AccountBalance,
    tags: ['sponsorship', 'cultural', 'international'],
    description: 'International cultural agency sponsorship'
  },
  SPONSORSHIP_CORPORATE: {
    code: '10',
    label: '010 - Sponsorship - Corporate',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Sponsorship',
    icon: BusinessCenter,
    tags: ['sponsorship', 'corporate'],
    description: 'Corporate sponsorship income'
  },
  SPONSORSHIP_IN_KIND: {
    code: '11',
    label: '011 - Sponsorship - In-Kind',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Sponsorship',
    icon: BusinessCenter,
    tags: ['sponsorship', 'in-kind'],
    description: 'In-kind sponsorship contributions'
  },
  INCOME_FROM_EXPENSES: {
    code: '25',
    label: '025 - Income From Expenses',
    category: 'income',
    subCategories: ['administrative'],
    acCode: 'fees or other income from presenting partners',
    icon: Receipt,
    tags: ['expenses', 'reimbursement'],
    description: 'Income from expense reimbursements'
  },
  TOURING_CONCERTS_PROMOTERS_GUARANTEES: {
    code: '34',
    label: '034 - Touring Concerts - Promoters Guarantees',
    category: 'income',
    subCategories: ['artist'],
    acCode: 'fees or other income from presenting partners',
    icon: MusicNote,
    tags: ['touring', 'guarantees', 'promoters'],
    description: 'Guaranteed income from concert promoters'
  },
  ONCE_OFF_PROJECTS_PROMOTERS_GUARANTEES: {
    code: '27',
    label: '027 - Once Off Projects - Promoters Guarantees',
    category: 'income',
    subCategories: ['artist'],
    acCode: 'fees or other income from presenting partners',
    icon: MusicNote,
    tags: ['projects', 'guarantees', 'promoters'],
    description: 'Guaranteed income from one-off projects'
  },
  FUNDRAISING_DEVELOPMENT_INCOME: {
    code: '13',
    label: '013 - Fundraising & Development Income',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Fundraising / Development',
    icon: AttachMoney,
    tags: ['fundraising', 'development'],
    description: 'Income from fundraising activities'
  },
  FUNDRAISED_DONATIONS_FRIENDS_SCHEME: {
    code: '12',
    label: '012 - Fundraised Donations / Friends Scheme',
    category: 'income',
    subCategories: ['grants'],
    acCode: 'Philanthropic donations',
    icon: People,
    tags: ['donations', 'friends', 'fundraising'],
    description: 'Income from friends scheme and donations'
  }
} as const;

 export const IMC_EXPENSE_CODES = {
  ARTISTS_FEES_INTERNATIONAL_JAZZ: {
    code: '110',
    label: '110 - Artist Fees - International Jazz',
    category: 'expenses',
    subCategories: ['artist_fees', 'international', 'ia-employ'],
    acCode: 'Artist fees / wages',
    icon: MusicNote,
    tags: ['jazz', 'international', 'artist_fees'],
    description: 'Artist fees for international jazz performances'
  },
  ARTISTS_FEES_INTERNATIONAL_OTHER: {
    code: '111',
    label: '111 - Artist Fees - International Other',
    category: 'expenses',
    subCategories: ['artist_fees', 'international','ia-employ'],
    acCode: 'Artist fees / wages',
    icon: TheaterComedy,
    tags: ['other', 'international', 'artist_fees'],
    description: 'Artist fees for other international performances'
  },
  ARTISTS_FEES_INTERNATIONAL_COLLABORATION: {
    code: '112',
    label: '112 - Artists Fees - International Collaboration',
    category: 'expenses',
    subCategories: ['artist_fees', 'international', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Mic,
    tags: ['collaboration', 'international', 'artist_fees'],
    description: 'Artist fees for international collaborative projects'
  },
  VAT_ON_ARTISTS_FEES: {
    code: '113',
    label: '113 - VAT on Artists Fees',
    category: 'expenses',
    subCategories: ['taxes', 'artist_fees'],
    acCode: 'Artist fees / wages',
    icon: Receipt,
    tags: ['vat', 'tax', 'artist_fees'],
    description: 'Value Added Tax on artist fees'
  },
  ARTISTS_FEES_DOMESTIC_JAZZ: {
    code: '114',
    label: '114 - Artists Fees - Domestic Jazz',
    category: 'expenses',
    subCategories: ['artist_fees', 'domestic', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: MusicNote,
    tags: ['jazz', 'domestic', 'artist_fees'],
    description: 'Artist fees for domestic jazz performances'
  },
  ARTISTS_FEES_DOMESTIC_OTHER: {
    code: '115',
    label: '115 - Artists Fees - Domestic Other',
    category: 'expenses',
    subCategories: ['artist_fees', 'domestic', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: TheaterComedy,
    tags: ['other', 'domestic', 'artist_fees'],
    description: 'Artist fees for other domestic performances'
  },
  DOMESTIC_ARTISTS_ENGAGEMENT: {
    code: '154',
    label: '154 - Artists Fees - Domestic Artists Engagement',
    category: 'expenses',
    subCategories: ['artist_fees', 'domestic', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: People,
    tags: ['engagement', 'domestic', 'artist_fees'],
    description: 'Expenses for domestic artist engagement'
  },
  ARTISTS_DEVELOPMENT_PROJECT_SUBVENTION: {
    code: '160',
    label: '160 - Artists Fees - Development & Project Subvention',
    category: 'expenses',
    subCategories: ['artist_fees', 'development', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Brush,
    tags: ['development', 'project', 'subvention'],
    description: 'Subventions for artist development and project support'
  },
  PRODUCTION_FRONT_OF_HOUSE_CREW: {
    code: '127',
    label: '127 - Production - Front of House Crew',
    category: 'expenses',
    subCategories: ['production', 'crew', 'o-employ'],
    acCode: 'Artist fees / wages',
    icon: Store,
    tags: ['front_of_house', 'crew', 'production'],
    description: 'Expenses for front of house production crew'
  },
  PRODUCTION_PA_ENGINEERS: {
    code: '131',
    label: '131 - Production - PA Engineers',
    category: 'expenses',
    subCategories: ['production', 'technical', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Speaker,
    tags: ['pa', 'engineers', 'production'],
    description: 'Expenses for PA (Public Address) engineers'
  },
  PRODUCTION_LIGHTING_ENGINEERS: {
    code: '134',
    label: '134 - Production - Lighting Engineers',
    category: 'expenses',
    subCategories: ['production', 'technical', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: LightbulbOutlined,
    tags: ['lighting', 'engineers', 'production'],
    description: 'Expenses for lighting engineers'
  },
  PRODUCTION_PHOTOGRAPHY_VIDEOGRAPHY_PERSONNEL: {
    code: '149',
    label: '149 - Production - Photography/Videography Personnel',
    category: 'expenses',
    subCategories: ['production', 'media', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: PhotoCamera,
    tags: ['photography', 'videography', 'personnel'],
    description: 'Expenses for photography and videography personnel'
  },
  RECORDINGS_EXPENSES_ARTISTS_RECORDING_FEES: {
    code: '150',
    label: '150 - Recordings Exps - Software',
    category: 'expenses',
    subCategories: ['recordings', 'software', 'artist_sundry'],
    acCode: 'Artist fees / wages',
    icon: Computer,
    tags: ['recording', 'software', 'fees'],
    description: 'Expenses for recording software and related fees'
  },
  RECORDING_EXPENSES_RECORDING_TECHNICIANS: {
    code: '152',
    label: '152 - Recording Expenses - Recording Technicians',
    category: 'expenses',
    subCategories: ['recordings', 'technical','artist_sundry', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Engineering,
    tags: ['recording', 'technicians', 'personnel'],
    description: 'Expenses for recording technicians'
  },
  RECORDING_EXPENSES_DESIGN: {
    code: '153',
    label: '153 - Recording Expenses - Design Personnel',
    category: 'expenses',
    subCategories: ['recordings', 'design', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Brush,
    tags: ['recording', 'design', 'personnel'],
    description: 'Expenses for recording design personnel'
  },
  PRODUCTION_STAGE_CREW: {
    code: '135',
    label: '135 - Production - Stage Crew',
    category: 'expenses',
    subCategories: ['production', 'crew', 'o-employ'],
    acCode: 'Non-artist fees / wages',
    icon: Construction,
    tags: ['stage', 'crew', 'production'],
    description: 'Expenses for stage production crew'
  },
  PRODUCTION_SPECIALIST_CREW: {
    code: '147',
    label: '147 - Production - Specialist Crew',
    category: 'expenses',
    subCategories: ['production', 'crew', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: SettingsInputComponent,
    tags: ['specialist', 'crew', 'production'],
    description: 'Expenses for specialist production crew'
  },
  PRODUCTION_EMP_SAFETY_OFFICERS: {
    code: '142',
    label: '142 - Production - EMP / Safety Officers',
    category: 'expenses',
    subCategories: ['production', 'safety', 'o-employ'],
    acCode: 'Non-artist fees / wages',
    icon: Security,
    tags: ['emp', 'safety', 'officers', 'production'],
    description: 'Expenses for EMP and safety officers'
  },
  PRODUCTION_OTHER_CREW: {
    code: '126',
    label: '126 - Production - Other Crew',
    category: 'expenses',
    subCategories: ['production', 'crew', 'o-employ'],
    acCode: 'Non-artist fees / wages',
    icon: Group,
    tags: ['other', 'crew', 'production'],
    description: 'Expenses for other production crew members'
  },
  ARTISTS_INTERNATIONAL_TRAVEL: {
    code: '116',
    label: '116 - Artists - International Travel',
    category: 'expenses',
    subCategories: ['travel', 'international', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: FlightTakeoff,
    tags: ['international', 'travel', 'artists'],
    description: 'Travel expenses for international artists'
  },
  ARTISTS_LOCAL_TRANSPORT: {
    code: '117',
    label: '117 - Artists - Local Transport',
    category: 'expenses',
    subCategories: ['travel', 'local', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: DirectionsCar,
    tags: ['local', 'transport', 'artists'],
    description: 'Local transportation expenses for artists'
  },
  ARTISTS_ACCOMMODATION: {
    code: '118',
    label: '118 - Artists - Accommodation',
    category: 'expenses',
    subCategories: ['lodging', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Hotel,
    tags: ['accommodation', 'lodging', 'artists'],
    description: 'Accommodation expenses for artists'
  },
  ARTISTS_CATERING_HOSPITALITY: {
    code: '119',
    label: '119 - Artists - Catering & Hospitality',
    category: 'expenses',
    subCategories: ['catering', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Restaurant,
    tags: ['catering', 'hospitality', 'artists'],
    description: 'Catering and hospitality expenses for artists'
  },
  ARTISTS_PER_DIEMS: {
    code: '120',
    label: '120 - Artists - Per Diems',
    category: 'expenses',
    subCategories: ['compensation', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: AttachMoney,
    tags: ['per_diem', 'daily_allowance', 'artists'],
    description: 'Daily allowance expenses for artists'
  },
  ARTISTS_MERCHANDISE: {
    code: '122',
    label: '122 - Artists - Merchandise',
    category: 'expenses',
    subCategories: ['sales', 'artist', 'artist_sundry'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: LocalOffer,
    tags: ['merchandise', 'sales', 'artists'],
    description: 'Expenses related to artist merchandise'
  },
  SUNDRY_PRODUCTION_EXPENSES_CONTINGENCIES: {
    code: '141',
    label: '141 - Sundry Production Expenses',
    category: 'expenses',
    subCategories: ['production', 'miscellaneous'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: BusinessCenter,
    tags: ['sundry', 'contingencies', 'production'],
    description: 'Miscellaneous and contingency production expenses'
  },
  VENUE_HIRE: {
    code: '143',
    label: '143 - Venue Hire',
    category: 'expenses',
    subCategories: ['venue', 'facilities', 'administrative', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: LocationOn,
    tags: ['venue', 'hire', 'location'],
    description: 'Expenses for hiring performance venues'
  },
  RECORDING_EXPENSES_STUDIO_RENTAL: {
    code: '151',
    label: '151 - Recording Exps - Audio Equipment',
    category: 'expenses',
    subCategories: ['recordings', 'equipment', 'administrative', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: DevicesOther,
    tags: ['studio', 'rental', 'audio_equipment'],
    description: 'Expenses for audio equipment and studio rental'
  },
  PRODUCTION_PIANO_HIRE: {
    code: '128',
    label: '128 - Production - Piano Hire',
    category: 'expenses',
    subCategories: ['production', 'equipment', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: LightbulbOutlined,
    tags: ['piano', 'hire', 'production'],
    description: 'Expenses for piano hire in production'
  },
  PRODUCTION_BACKLINE_HIRE: {
    code: '129',
    label: '129 - Production - Backline Hire',
    category: 'expenses',
    subCategories: ['production', 'equipment', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: SettingsInputComponent,
    tags: ['backline', 'hire', 'production'],
    description: 'Expenses for backline equipment hire'
  },
  PRODUCTION_PA_HIRE: {
    code: '130',
    label: '130 - Production - PA Hire',
    category: 'expenses',
    subCategories: ['production', 'technical', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Speaker,
    tags: ['pa', 'hire', 'production'],
    description: 'Expenses for Public Address (PA) system hire'
  },
  PRODUCTION_BACKLINE_TRANSPORT: {
    code: '148',
    label: '148 - Production - Backline Transport',
    category: 'expenses',
    subCategories: ['production', 'transport', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: LocalShipping,
    tags: ['backline', 'transport', 'production'],
    description: 'Transportation expenses for backline equipment'
  },
  PRODUCTION_EQUIPMENT_HIRE: {
    code: '132',
    label: '132 - Production - Equipment Hire',
    category: 'expenses',
    subCategories: ['production', 'equipment', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Build,
    tags: ['equipment', 'hire', 'production'],
    description: 'Expenses for general production equipment hire'
  },
  PRODUCTION_LIGHTING_HIRE: {
    code: '133',
    label: '133 - Production - Lighting Hire',
    category: 'expenses',
    subCategories: ['production', 'technical', 'i-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: LightbulbOutlined,
    tags: ['lighting', 'hire', 'production'],
    description: 'Expenses for lighting equipment hire'
  },
  PRODUCTION_PROPS_STAGING: {
    code: '136',
    label: '136 - Production - Props & Staging',
    category: 'expenses',
    subCategories: ['production', 'set_design', 'o-employ'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: SettingsInputComponent,
    tags: ['props', 'staging', 'production'],
    description: 'Expenses for production props and staging equipment'
  },
  PRODUCTION_EQUIPMENT_PURCHASES: {
    code: '161',
    label: '161 - Production - Equipment Purchases',
    category: 'expenses',
    subCategories: ['production', 'equipment', 'capital_expenditure'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Payment,
    tags: ['equipment', 'purchase', 'production'],
    description: 'Capital expenditure for purchasing production equipment'
  },
  ADVERTISING_WEBSITE_DESIGN_EXPENSES: {
    code: '165',
    label: '165 - Advertising - Website Design Expenses',
    category: 'expenses',
    subCategories: ['advertising', 'digital', 'design', 'a-employ'],
    acCode: 'Marketing / PR costs',
    icon: Web,
    tags: ['website', 'design', 'digital'],
    description: 'Expenses for website design and development'
  },
  ADVERTISING_WEBSITE_MAINTENANCE: {
    code: '166',
    label: '166 - Advertising - Website Maintenance',
    category: 'expenses',
    subCategories: ['advertising', 'digital', 'maintenance', 'i-employ'],
    acCode: 'Marketing / PR costs',
    icon: Computer,
    tags: ['website', 'maintenance', 'digital'],
    description: 'Ongoing expenses for website maintenance and updates'
  },
  ADVERTISING_WEBSITE_HOSTING_DOMAINS: {
    code: '167',
    label: '167 - Advertising - Website Hosting & Domains',
    category: 'expenses',
    subCategories: ['advertising', 'digital', 'infrastructure'],
    acCode: 'Marketing / PR costs',
    icon: Cloud,
    tags: ['hosting', 'domain', 'digital'],
    description: 'Expenses for website hosting and domain registration'
  },
  MARKETING_POSTER_ADVERTISING: {
    code: '169',
    label: '169 - Marketing - Poster Advertising',
    category: 'expenses',
    subCategories: ['marketing', 'advertising', 'print'],
    acCode: 'Marketing / PR costs',
    icon: Campaign,
    tags: ['poster', 'advertising', 'marketing'],
    description: 'Expenses for poster advertising campaigns'
  },
  MARKETING_DESIGN: {
    code: '170',
    label: '170 - Marketing - Design',
    category: 'expenses',
    subCategories: ['marketing', 'design', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Brush,
    tags: ['design', 'creative', 'marketing'],
    description: 'Design-related expenses for marketing materials'
  },
  MARKETING_PRINT_MATERIALS: {
    code: '171',
    label: '171 - Marketing - Print Materials',
    category: 'expenses',
    subCategories: ['marketing', 'print', 'i-employ'],
    acCode: 'Marketing / PR costs',
    icon: PhotoCamera,
    tags: ['print', 'materials', 'marketing'],
    description: 'Expenses for printed marketing materials'
  },
  MARKETING_POSTER_FLYER_DISTRIBUTION: {
    code: '172',
    label: '172 - Marketing - Poster / Flyer Distribution',
    category: 'expenses',
    subCategories: ['marketing', 'distribution', 'i-employ'],
    acCode: 'Marketing / PR costs',
    icon: LocalShipping,
    tags: ['poster', 'flyer', 'distribution'],
    description: 'Expenses for distributing marketing posters and flyers'
  },
  MARKETING_PHOTOGRAPHY: {
    code: '173',
    label: '173 - Marketing - Photography',
    category: 'expenses',
    subCategories: ['marketing', 'media', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: PhotoCamera,
    tags: ['photography', 'marketing', 'media'],
    description: 'Photography expenses for marketing purposes'
  },
  MARKETING_VIDEOGRAPHY: {
    code: '174',
    label: '174 - Marketing - Videography',
    category: 'expenses',
    subCategories: ['marketing', 'media', 'a-employ'],
    acCode: 'Artist fees / wages',
    icon: Videocam,
    tags: ['videography', 'marketing', 'media'],
    description: 'Videography expenses for marketing purposes'
  },
  MARKETING_PUBLICATIONS: {
    code: '175',
    label: '175 - Marketing - Publications',
    category: 'expenses',
    subCategories: ['marketing', 'print'],
    acCode: 'Marketing / PR costs',
    icon: School,
    tags: ['publications', 'marketing', 'print'],
    description: 'Expenses for marketing publications'
  },
  MARKETING_PR_ADVERTISING: {
    code: '176',
    label: '176 - Marketing - PR - Advertising',
    category: 'expenses',
    subCategories: ['marketing', 'public_relations', 'advertising'],
    acCode: 'Marketing / PR costs',
    icon: Campaign,
    tags: ['pr', 'advertising', 'marketing'],
    description: 'Public relations and advertising expenses'
  },
  MARKETING_ONLINE_ADVERTISING: {
    code: '177',
    label: '177 - Marketing - Online Advertising',
    category: 'expenses',
    subCategories: ['marketing', 'digital', 'advertising'],
    acCode: 'Marketing / PR costs',
    icon: Web,
    tags: ['online', 'advertising', 'digital'],
    description: 'Expenses for online digital advertising'
  },
  MARKETING_MERCHANDISE: {
    code: '178',
    label: '178 - Marketing - Merchandise',
    category: 'expenses',
    subCategories: ['marketing', 'sales', 'i-employ'],
    acCode: 'Marketing / PR costs',
    icon: LocalOffer,
    tags: ['merchandise', 'marketing', 'sales'],
    description: 'Expenses for marketing-related merchandise'
  },
  MARKETING_DIGITAL_NEWS_LETTER: {
    code: '179',
    label: '179 - Marketing - Digital Newsletter',
    category: 'expenses',
    subCategories: ['marketing', 'digital', 'communication'],
    acCode: 'Marketing / PR costs',
    icon: Web,
    tags: ['newsletter', 'digital', 'marketing'],
    description: 'Expenses for digital newsletter creation and distribution'
  },
  MARKETING_SUBSCRIPTIONS: {
    code: '187',
    label: '187 - Marketing - Subscriptions & Publications',
    category: 'expenses',
    subCategories: ['marketing', 'subscriptions'],
    acCode: 'Marketing / PR costs',
    icon: Folder,
    tags: ['subscriptions', 'publications', 'marketing'],
    description: 'Expenses for marketing-related subscriptions and publications'
  },
  MARKETING_CATERING_HOSPITALITY: {
    code: '146',
    label: '146 - Marketing - Catering & Hospitality',
    category: 'expenses',
    subCategories: ['marketing', 'hospitality', 'i-employ'],
    acCode: 'Marketing / PR costs',
    icon: Restaurant,
    tags: ['catering', 'hospitality', 'marketing'],
    description: 'Catering and hospitality expenses for marketing events'
  },
  TICKET_AGENTS_COMMISSION: {
    code: '144',
    label: '144 - Ticket Agents Commission',
    category: 'expenses',
    subCategories: ['sales', 'commission'],
    acCode: 'Admin costs directly related to activity',
    icon: PointOfSale,
    tags: ['ticket', 'commission', 'sales'],
    description: 'Commissions paid to ticket agents'
  },
  ARTISTS_VISAS_WORK_PERMITS: {
    code: '121',
    label: '121 - Artists - Visas & Work Permits',
    category: 'expenses',
    subCategories: ['artist', 'legal', 'travel'],
    acCode: 'Production / making / publishing / exhibition / logistical costs',
    icon: Gavel,
    tags: ['visa', 'work_permit', 'artists'],
    description: 'Expenses for artist visas and work permits'
  },
  PRODUCTION_STAFF_TRAVEL: {
    code: '137',
    label: '137 - Production - Staff Travel',
    category: 'expenses',
    subCategories: ['production', 'travel'],
    acCode: 'Admin costs directly related to activity',
    icon: FlightTakeoff,
    tags: ['staff', 'travel', 'production'],
    description: 'Travel expenses for production staff'
  },
  PRODUCTION_STAFF_SUBSISTENCE_PER_DIEMS: {
    code: '138',
    label: '138 - Production - Staff Subsistence / Per Diems',
    category: 'expenses',
    subCategories: ['production', 'compensation'],
    acCode: 'Admin costs directly related to activity',
    icon: AttachMoney,
    tags: ['subsistence', 'per_diem', 'production'],
    description: 'Daily allowance and subsistence expenses for production staff'
  },
  PRODUCTION_STAFF_ACCOMMODATION: {
    code: '139',
    label: '139 - Production - Staff Accommodation',
    category: 'expenses',
    subCategories: ['production', 'lodging'],
    acCode: 'Admin costs directly related to activity',
    icon: Hotel,
    tags: ['accommodation', 'staff', 'production'],
    description: 'Accommodation expenses for production staff'
  },
  PRODUCTION_COURIER_POSTAGE: {
    code: '140',
    label: '140 - Production - Courier / Postage',
    category: 'expenses',
    subCategories: ['production', 'shipping'],
    acCode: 'Admin costs directly related to activity',
    icon: LocalShipping,
    tags: ['courier', 'postage', 'production'],
    description: 'Expenses for courier services and postage in production'
  },
  IMRO_PUBLIC_LIABILITY_INSURANCE: {
    code: '145',
    label: '145 - IMRO & Public Liability Insurance',
    category: 'expenses',
    subCategories: ['insurance', 'legal'],
    acCode: 'Admin costs directly related to activity',
    icon: Security,
    tags: ['imro', 'insurance', 'liability'],
    description: 'Insurance expenses for public liability and IMRO'
  },
  OTHER_EXPENSES_NATIONAL_CAMPAIGN_FOR_THE_ARTS: {
    code: '181',
    label: '181 - Other Expenses - National Campaign for the Arts',
    category: 'expenses',
    subCategories: ['marketing', 'cultural'],
    acCode: 'Admin costs directly related to activity',
    icon: Campaign,
    tags: ['national', 'campaign', 'arts'],
    description: 'Expenses related to national arts campaign'
  },
  OTHER_EXPENSES_RESEARCH: {
    code: '182',
    label: '182 - Other Expenses - Research',
    category: 'expenses',
    subCategories: ['research', 'development'],
    acCode: 'Admin costs directly related to activity',
    icon: School,
    tags: ['research', 'development', 'study'],
    description: 'Expenses for research and development activities'
  },
  OTHER_EXPENSES_SPONSORSHIP_FUNDRAISING_EXPENSES: {
    code: '183',
    label: '183 - Other Expenses - Sponsorship & Fundraising Expenses',
    category: 'expenses',
    subCategories: ['fundraising', 'sponsorship'],
    acCode: 'Admin costs directly related to activity',
    icon: BusinessCenter,
    tags: ['sponsorship', 'fundraising', 'development'],
    description: 'Expenses related to sponsorship and fundraising efforts'
  },
  OTHER_EXPENSES_INSTRUMENT_BACKLINE_MAINTENANCE: {
    code: '184',
    label: '184 - Other Expenses - Instrument / Backline Maintenance',
    category: 'expenses',
    subCategories: ['maintenance', 'equipment', 'administrative', 'i-employ'],
    acCode: 'Admin costs directly related to activity',
    icon: Build,
    tags: ['instrument', 'backline', 'maintenance'],
    description: 'Maintenance expenses for instruments and backline equipment'
  },
  EVENT_COLLABORATION_REIMBURSEMENTS: {
    code: '125',
    label: '125 - Event Collaboration Reimbursements',
    category: 'expenses',
    subCategories: ['collaboration', 'reimbursement'],
    acCode: 'Other costs (please specify)',
    icon: Payment,
    tags: ['event', 'collaboration', 'reimbursement'],
    description: 'Reimbursement expenses for event collaborations'
  },
  OTHER_EXPENSES_IN_KIND_CONTRA: {
    code: '180',
    label: '180 - Other Exps - In Kind / Contra',
    category: 'expenses',
    subCategories: ['miscellaneous', 'non-monetary'],
    acCode: 'Other costs (please specify)',
    icon: BusinessCenter,
    tags: ['in_kind', 'contra', 'non-monetary'],
    description: 'Non-monetary or in-kind expense transactions'
  },
SALARY_CEO: {
  code: '301',
  label: '301 - Salaries - Director/CEO',
  category: 'expenses',
  subCategories: ['staff', 'executive'],
  acCode: 'Salary - Director / Artistic Director / CEO',
 icon: ManageAccounts,
  tags: ['salary', 'executive', 'director'],
  description: 'Salary for Chief Executive Officer/Director'
},
SALARY_MARKETING_DIRECTOR: {
  code: '302',
  label: '302 - Salaries - Marketing Director',
  category: 'expenses',
  subCategories: ['staff', 'marketing'],
  acCode: 'Salaries - all other staff',
 icon: ManageAccounts,
  tags: ['salary', 'marketing', 'director'],
  description: 'Salary for Marketing Director (Part-Time)'
},
SALARY_PRODUCTION_COORDINATOR: {
  code: '303-1',
  label: '303-1 - Salaries - Production Coordinator',
  category: 'expenses', 
  subCategories: ['staff', 'production'],
  acCode: 'Salaries - all other staff',
  icon: ManageAccounts,
  tags: ['salary', 'production', 'coordinator'],
  description: 'Salary for Production Coordinator (Part-Time)'
},
SALARY_COOLER_MANAGER: {
  code: '303-2',
  label: '303-2 - Salaries - Cooler Manager',
  category: 'expenses',
  subCategories: ['staff', 'venue'],
  acCode: 'Salaries - all other staff',
  icon: ManageAccounts,
  tags: ['salary', 'venue', 'manager'],
  description: 'Salary for Cooler Venue Manager'
},
SALARY_PENSION: {
  code: '304',
  label: '304 - Salaries - Pension Contributions',
  category: 'expenses',
  subCategories: ['staff', 'benefits'],
  acCode: 'Miscellaneous expenses',
  icon: AccountBalanceWallet,
  tags: ['salary', 'pension', 'benefits'],
  description: 'Employee pension contributions'
},
SALARY_GENERAL_MANAGER: {
  code: '305',
  label: '305 - Salaries - General Manager',
  category: 'expenses',
  subCategories: ['staff', 'management'],
  acCode: 'Salary - Artistic Director / Executive Director / Executive Producer / General Manager',
  icon: ManageAccounts,
  tags: ['salary', 'management', 'general'],
  description: 'Salary for General Manager'
},
SALARY_ARTIST_DEV: {
  code: '306',
  label: '306 - Salaries - Artist Development Officer',
  category: 'expenses',
  subCategories: ['staff', 'artist'],
  acCode: 'Salaries - all other staff',
  icon: ManageAccounts,
  tags: ['salary', 'artist', 'development'],
  description: 'Salary for Artist Development Officer (Part-Time)'
},
SALARY_COMMS: {
  code: '307',
  label: '307 - Salaries - Communications Officer',
  category: 'expenses',
  subCategories: ['staff', 'marketing'],
  acCode: 'Salaries - all other staff',
  icon: ManageAccounts,
  tags: ['salary', 'communications', 'marketing'],
  description: 'Salary for Communications Officer (Part-Time)'
},
SALARY_PRSI: {
  code: '310',
  label: '310 - Salaries - Employer PRSI',
  category: 'expenses',
  subCategories: ['staff', 'tax'],
  acCode: 'Salaries - all other staff',
  icon: Receipt,
  tags: ['salary', 'prsi', 'tax'],
  description: 'Employer PRSI contributions'
},
  DIRECTOR_EXPENSES_ACCOMMODATION: {
    code: '315',
    label: '315 - Director Expenses - Accommodation',
    category: 'expenses',
    subCategories: ['management', 'lodging'],
    acCode: 'General fundraising / Development costss',
    icon: Hotel,
    tags: ['director', 'accommodation', 'travel'],
    description: 'Accommodation expenses for directors'
  },
  DIRECTOR_EXPENSES_TRAVEL: {
    code: '316',
    label: '316 - Director Expenses - Travel',
    category: 'expenses',
    subCategories: ['management', 'travel'],
    acCode: 'General fundraising / Development costss',
    icon: FlightTakeoff,
    tags: ['director', 'travel', 'transportation'],
    description: 'Travel expenses for directors'
  },
  DIRECTOR_EXPENSES_SUBSISTENCE: {
    code: '317',
    label: '317 - Director Expenses - Subsistence & Conference',
    category: 'expenses',
    subCategories: ['management', 'compensation'],
    acCode: 'General fundraising / Development costss',
    icon: AttachMoney,
    tags: ['director', 'subsistence', 'conference'],
    description: 'Subsistence and conference expenses for directors'
  },
  MARKETING_EXPENSES_GENERAL: {
    code: '318',
    label: '318 - Marketing Expenses - General',
    category: 'expenses',
    subCategories: ['marketing', 'miscellaneous'],
    acCode: 'General marketing / PR',
    icon: Campaign,
    tags: ['marketing', 'general', 'miscellaneous'],
    description: 'General marketing-related expenses'
  },
  COMPANY_EXPENSES_BOARD: {
    code: '319',
    label: '319 - Company Expenses - Board',
    category: 'expenses',
    subCategories: ['management', 'administrative'],
    acCode: 'Other costs (please specify)',
    icon: BusinessCenter,
    tags: ['board', 'company', 'administrative'],
    description: 'Expenses related to board activities'
  },
  VEHICLE_EXPENSES: {
    code: '320',
    label: '320 - Vehicle Expenses',
    category: 'expenses',
    subCategories: ['transportation', 'operational'],
    acCode: 'Utilities',
    icon: DirectionsCar,
    tags: ['vehicle', 'transportation', 'operational'],
    description: 'Expenses related to company vehicles'
  },
  STAFF_EXPENSES_WFH_ALLOWANCE: {
    code: '323',
    label: '323 - Staff Expenses - Working From Home Allowance',
    category: 'expenses',
    subCategories: ['staff', 'compensation'],
    acCode: 'Other costs (please specify)',
    icon: Computer,
    tags: ['wfh', 'allowance', 'staff'],
    description: 'Working from home allowance for staff'
  },
  STAFF_EXPENSES_RECRUITMENT: {
    code: '324',
    label: '324 - Staff Expenses - Recruitment Costs',
    category: 'expenses',
    subCategories: ['staff', 'hr'],
    acCode: 'Other costs (please specify)',
    icon: ManageAccounts,
    tags: ['recruitment', 'hiring', 'staff'],
    description: 'Expenses related to staff recruitment'
  },
  STAFF_EXPENSES_TRAINING: {
    code: '325',
    label: '325 - Staff Expenses - Training & Development',
    category: 'expenses',
    subCategories: ['staff', 'development'],
    acCode: 'Other costs (please specify)',
    icon: School,
    tags: ['training', 'development', 'staff'],
    description: 'Training and professional development expenses'
  },
  STAFF_EXPENSES_TRAVEL: {
    code: '326',
    label: '326 - Staff Expenses - Travel',
    category: 'expenses',
    subCategories: ['staff', 'travel'],
    acCode: 'Other costs (please specify)',
    icon: FlightTakeoff,
    tags: ['travel', 'staff', 'transportation'],
    description: 'Travel expenses for staff members'
  },
  STAFF_EXPENSES_SUBSISTENCE: {
    code: '327',
    label: '327 - Staff Expenses - Subsistence & Conference',
    category: 'expenses',
    subCategories: ['staff', 'compensation'],
    acCode: 'Other costs (please specify)',
    icon: AttachMoney,
    tags: ['subsistence', 'conference', 'staff'],
    description: 'Subsistence and conference expenses for staff'
  },
  STAFF_EXPENSES_POST_EVENTS: {
    code: '328',
    label: '328 - Staff Expenses - Post Events',
    category: 'expenses',
    subCategories: ['staff', 'events'],
    acCode: 'Other costs (please specify)',
    icon: Group,
    tags: ['post_events', 'staff', 'events'],
    description: 'Staff expenses related to post-event activities'
  },
  COMPANY_EXPENSES_GIFTS: {
    code: '330',
    label: '330 - Company Expenses - Gifts',
    category: 'expenses',
    subCategories: ['administrative', 'miscellaneous'],
    acCode: 'Other costs (please specify)',
    icon: LocalOffer,
    tags: ['gifts', 'company', 'administrative'],
    description: 'Expenses for company gifts'
  },
  OFFICE_EXPENSES_STATIONERY: {
    code: '331',
    label: '331 - Office Expenses - Stationery',
    category: 'expenses',
    subCategories: ['office', 'supplies'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Folder,
    tags: ['stationery', 'office', 'supplies'],
    description: 'Expenses for office stationery and supplies'
  },
  OFFICE_EXPENSES_CLEANING: {
    code: '332',
    label: '332 - Office Expenses - Cleaning',
    category: 'expenses',
    subCategories: ['office', 'maintenance'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Construction,
    tags: ['cleaning', 'office', 'maintenance'],
    description: 'Expenses for office cleaning services'
  },
  OFFICE_EXPENSES_COLOR_PRINTING: {
    code: '333',
    label: '333 - Office Expenses - Color Printing',
    category: 'expenses',
    subCategories: ['office', 'printing'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhotoCamera,
    tags: ['color_printing', 'office', 'printing'],
    description: 'Expenses for color printing in the office'
  },
  OFFICE_EXPENSES_LANDLINE: {
    code: '334',
    label: '334 - Office Expenses - Landline/Broadband',
    category: 'expenses',
    subCategories: ['office', 'communication'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Wifi,
    tags: ['landline', 'broadband', 'communication'],
    description: 'Expenses for office landline and broadband services'
  },
  MOBILE_EXPENSES_CEO: {
    code: '336-1',
    label: '336 - Office Expenses - Telephone - Mobiles - CEO',
    category: 'expenses',
    subCategories: ['office', 'communication', 'executive'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'ceo', 'telephone'],
    description: 'Mobile telephone expenses for CEO'
  },
  MOBILE_EXPENSES_GM: {
    code: '336-2',
    label: '336 - Office Expenses - Telephone - Mobiles - GM',
    category: 'expenses',
    subCategories: ['office', 'communication', 'management'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'gm', 'telephone'],
    description: 'Mobile telephone expenses for General Manager'
  },
  MOBILE_EXPENSES_MARKETING: {
    code: '336-3',
    label: '336 - Office Expenses - Telephone - Mobiles - Marketing',
    category: 'expenses',
    subCategories: ['office', 'communication', 'marketing'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'marketing', 'telephone'],
    description: 'Mobile telephone expenses for Marketing department'
  },
  MOBILE_EXPENSES_PRODUCTION: {
    code: '336-4',
    label: '336 - Office Expenses - Telephone - Mobiles - Production',
    category: 'expenses',
    subCategories: ['office', 'communication', 'production'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'production', 'telephone'],
    description: 'Mobile telephone expenses for Production department'
  },
  MOBILE_EXPENSES_ARTIST_DEV: {
    code: '336-5',
    label: '336 - Office Expenses - Telephone - Mobiles - Artist Dev',
    category: 'expenses',
    subCategories: ['office', 'communication', 'artist'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'artist_development', 'telephone'],
    description: 'Mobile telephone expenses for Artist Development'
  },
  MOBILE_EXPENSES_COMMS: {
    code: '336-6',
    label: '336 - Office Expenses - Telephone - Mobiles - Comms',
    category: 'expenses',
    subCategories: ['office', 'communication'],
    acCode: 'Office admin / postage / IT / phone',
    icon: PhoneIphone,
    tags: ['mobile', 'communications', 'telephone'],
    description: 'Mobile telephone expenses for Communications department'
  },
  OFFICE_EXPENSES_POSTAGE: {
    code: '337',
    label: '337 - Office Expenses - Postage/Couriers',
    category: 'expenses',
    subCategories: ['office', 'shipping'],
    acCode: 'Office admin / postage / IT / phone',
    icon: LocalShipping,
    tags: ['postage', 'couriers', 'shipping'],
    description: 'Expenses for office postage and courier services'
  },
  OFFICE_EXPENSES_COURIERS: {
    code: '338',
    label: '338 - Office Expenses - Couriers (Non-Production)',
    category: 'expenses',
    subCategories: ['office', 'shipping'],
    acCode: 'Office admin / postage / IT / phone',
    icon: LocalShipping,
    tags: ['couriers', 'non-production', 'shipping'],
    description: 'Courier expenses not related to production'
  },
  OFFICE_EXPENSES_CANTEEN: {
    code: '339',
    label: '339 - Office Expenses - Canteen',
    category: 'expenses',
    subCategories: ['office', 'staff_welfare'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Restaurant,
    tags: ['canteen', 'food', 'staff'],
    description: 'Expenses related to office canteen and staff food services'
  },
  OFFICE_EXPENSES_PUBLICATIONS_IRISH_TIMES: {
    code: '340-1',
    label: '340 - Office Expenses - Publications - Irish Times',
    category: 'expenses',
    subCategories: ['office', 'publications'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Folder,
    tags: ['publications', 'irish_times', 'media'],
    description: 'Expenses for Irish Times publications'
  },
  OFFICE_EXPENSES_PUBLICATIONS_OTHER: {
    code: '340-2',
    label: '340 - Office Expenses - Publications - Other',
    category: 'expenses',
    subCategories: ['office', 'publications'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Folder,
    tags: ['publications', 'other', 'media'],
    description: 'Expenses for other publications'
  },
  OFFICE_EXPENSES_SUNDRY: {
    code: '341',
    label: '341 - Office Expenses - Sundry Expenses Small',
    category: 'expenses',
    subCategories: ['office', 'miscellaneous'],
    acCode: 'Office admin / postage / IT / phone',
    icon: BusinessCenter,
    tags: ['sundry', 'small_expenses', 'miscellaneous'],
    description: 'Small miscellaneous office expenses'
  },
  MARKETING_EXPENSES_WEB_DESIGN: {
    code: '342-1',
    label: '342 - Marketing Expenses - Web Design',
    category: 'expenses',
    subCategories: ['marketing', 'digital', 'design'],
    acCode: 'General marketing / PR',
    icon: Web,
    tags: ['web_design', 'marketing', 'digital'],
    description: 'Expenses for web design in marketing'
  },
  MARKETING_EXPENSES_VIMEO: {
    code: '342-2',
    label: '342 - Marketing Expenses - Vimeo Pro',
    category: 'expenses',
    subCategories: ['marketing', 'digital', 'media'],
    acCode: 'General marketing / PR',
    icon: Videocam,
    tags: ['vimeo', 'video', 'marketing'],
    description: 'Expenses for Vimeo Pro service'
  },
  COMPANY_EXPENSES_IT_HOSTING: {
    code: '344',
    label: '344 - Company Expenses - IT Web Hosting',
    category: 'expenses',
    subCategories: ['it', 'digital', 'infrastructure'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Cloud,
    tags: ['hosting', 'web', 'it'],
    description: 'Expenses for IT web hosting services'
  },
  EQUIPMENT_EXPENSES_LEASING_VAN: {
    code: '345-1',
    label: '345 - Equipment Expenses - Leasing - Van',
    category: 'expenses',
    subCategories: ['equipment', 'transportation'],
    acCode: 'Utilities',
    icon: DirectionsCar,
    tags: ['leasing', 'van', 'vehicle'],
    description: 'Leasing expenses for company van'
  },
  EQUIPMENT_EXPENSES_LEASING_2: {
    code: '345-2',
    label: '345 - Equipment Expenses - Leasing #2',
    category: 'expenses',
    subCategories: ['equipment', 'leasing'],
    acCode: 'Miscellaneous expenses',
    icon: Build,
    tags: ['leasing', 'equipment', 'misc'],
    description: 'Leasing expenses for additional equipment'
  },
  EQUIPMENT_EXPENSES_LEASING_3: {
    code: '345-3',
    label: '345 - Equipment Expenses - Leasing #3',
    category: 'expenses',
    subCategories: ['equipment', 'leasing'],
    acCode: 'Miscellaneous expenses',
    icon: Build,
    tags: ['leasing', 'equipment', 'misc'],
    description: 'Leasing expenses for additional equipment'
  },
  EQUIPMENT_MAINTENANCE: {
    code: '346',
    label: '346 - Equipment Expenses - Maintenance & Repairs',
    category: 'expenses',
    subCategories: ['equipment', 'maintenance'],
    acCode: 'Maintenance/repairs',
    icon: Construction,
    tags: ['maintenance', 'repairs', 'equipment'],
    description: 'Expenses for equipment maintenance and repairs'
  },
  EQUIPMENT_FIXTURES: {
    code: '347',
    label: '347 - Equipment Expenses - Misc Fixtures & Fittings (Small)',
    category: 'expenses',
    subCategories: ['equipment', 'miscellaneous'],
    acCode: 'Maintenance/repairs',
    icon: Build,
    tags: ['fixtures', 'fittings', 'small_items'],
    description: 'Expenses for miscellaneous small fixtures and fittings'
  },
  IT_EXPENSES_GOOGLE: {
    code: '348-1',
    label: '348 - IT Expenses - Cloud Services - Google Workspace / YT',
    category: 'expenses',
    subCategories: ['it', 'cloud_services'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Computer,
    tags: ['google', 'workspace', 'cloud'],
    description: 'Expenses for Google Workspace and YouTube services'
  },
  IT_EXPENSES_MONDAY: {
    code: '348-2',
    label: '348 - IT Expenses - Cloud Services - Monday (Work Platform)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'productivity'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['monday', 'work_platform', 'cloud'],
    description: 'Expenses for Monday work management platform'
  },
  IT_EXPENSES_MIRO: {
    code: '348-3',
    label: '348 - IT Expenses - Cloud Services - Miro',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'collaboration'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['miro', 'collaboration', 'cloud'],
    description: 'Expenses for Miro collaborative platform'
  },
  IT_EXPENSES_EVERNOTE: {
    code: '348-4',
    label: '348 - IT Expenses - Cloud Services - Evernote (Archive)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'documentation'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Storage,
    tags: ['evernote', 'archive', 'cloud'],
    description: 'Expenses for Evernote archiving service'
  },
  IT_EXPENSES_JOTFORM: {
    code: '348-5',
    label: '348 - IT Expenses - Cloud Services - Jotform (Forms)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'productivity'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['jotform', 'forms', 'cloud'],
    description: 'Expenses for Jotform online form service'
  },
  IT_EXPENSES_BUZZSPROUT: {
    code: '348-6',
    label: '348 - IT Expenses - Cloud Services - Buzzsprout (Podcast)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'media'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Wifi,
    tags: ['buzzsprout', 'podcast', 'cloud'],
    description: 'Expenses for Buzzsprout podcast hosting service'
  },
  IT_EXPENSES_SURVEYMONKEY: {
    code: '348-7',
    label: '348 - IT Expenses - Cloud Services - Survey Monkey (Surveys)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'research'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['surveymonkey', 'surveys', 'cloud'],
    description: 'Expenses for Survey Monkey online survey platform'
  },
  IT_EXPENSES_CALENDLY: {
    code: '348-8',
    label: '348 - IT Expenses - Cloud Services - Calendly',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'scheduling'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['calendly', 'scheduling', 'cloud'],
    description: 'Expenses for Calendly scheduling platform'
  },
  IT_EXPENSES_SANEBOX: {
    code: '348-9',
    label: '348 - IT Expenses - Cloud Services - Sanebox (Email Mgmt)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'communication'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Wifi,
    tags: ['sanebox', 'email', 'cloud'],
    description: 'Expenses for Sanebox email management service'
  },
  IT_EXPENSES_SKEDDA: {
    code: '348-10',
    label: '348 - IT Expenses - Cloud Services - Skedda (Booking System)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'booking'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['skedda', 'booking', 'cloud'],
    description: 'Expenses for Skedda booking system'
  },
  IT_EXPENSES_MICROSOFT: {
    code: '348-11',
    label: '348 - IT Expenses - Microsoft',
    category: 'expenses',
    subCategories: ['it', 'software'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Computer,
    tags: ['microsoft', 'software', 'it'],
    description: 'Expenses for Microsoft software and services'
  },
  IT_EXPENSES_DROPBOX: {
    code: '348-12',
    label: '348 - IT Expenses - Cloud Services - Dropbox (Sync/Storage)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'storage'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Cloud,
    tags: ['dropbox', 'storage', 'cloud'],
    description: 'Expenses for Dropbox cloud storage and sync service'
  },
  IT_EXPENSES_SETAPP: {
    code: '348-13',
    label: '348 - IT Expenses - Cloud Services - Setapp (Software)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'software'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['setapp', 'software', 'cloud'],
    description: 'Expenses for Setapp software subscription service'
  },
  IT_EXPENSES_ZOOM: {
    code: '348-14',
    label: '348 - IT Expenses - Cloud Services - Zoom (Virtual Office)',
    category: 'expenses',
    subCategories: ['it', 'cloud_services', 'communication'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['zoom', 'virtual_office', 'cloud'],
    description: 'Expenses for Zoom virtual office and communication platform'
  },
  BUILDING_EXPENSES_RENT: {
    code: '351',
    label: '351 - Building Expenses - Rent',
    category: 'expenses',
    subCategories: ['building', 'facilities'],
    acCode: 'Rent/Mortgage',
    icon: Business,
    tags: ['rent', 'building', 'facilities'],
    description: 'Expenses for building or office space rent'
  },
  BUILDING_EXPENSES_STORAGE: {
    code: '352',
    label: '352 - Building Expenses - Document Storage',
    category: 'expenses',
    subCategories: ['building', 'storage'],
    acCode: 'Rent/Mortgage',
    icon: Storage,
    tags: ['document', 'storage', 'archiving'],
    description: 'Expenses for document storage and archiving'
  },
  BUILDING_EXPENSES_REPAIRS: {
    code: '353',
    label: '353 - Building Expenses - Repairs & Maintenance',
    category: 'expenses',
    subCategories: ['building', 'maintenance'],
    acCode: 'Maintenance/repairs',
    icon: Construction,
    tags: ['repairs', 'maintenance', 'building'],
    description: 'Expenses for building repairs and maintenance'
  },
  BUILDING_EXPENSES_UTILITIES: {
    code: '354',
    label: '354 - Building Expenses - Heating & Lighting',
    category: 'expenses',
    subCategories: ['building', 'utilities'],
    acCode: 'Utilities',
    icon: LightbulbOutlined,
    tags: ['heating', 'lighting', 'utilities'],
    description: 'Expenses for building heating and lighting utilities'
  },
  BUILDING_EXPENSES_CLEANING: {
    code: '355',
    label: '355 - Building Expenses - Cleaning',
    category: 'expenses',
    subCategories: ['building', 'maintenance'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Construction,
    tags: ['cleaning', 'building', 'maintenance'],
    description: 'Expenses for building cleaning services'
  },
  COMPANY_EXPENSES_INSURANCE: {
    code: '356',
    label: '356 - Company Expenses - Insurance & Employers Liability',
    category: 'expenses',
    subCategories: ['insurance', 'legal'],
    acCode: 'Insurance',
    icon: Security,
    tags: ['insurance', 'liability', 'company'],
    description: 'Expenses for company insurance and employers liability'
  },
  PROFESSIONAL_EXPENSES_ACCOUNTING_SOFTWARE: {
    code: '357',
    label: '357 - Professional Expenses - Accounting Software(s)',
    category: 'expenses',
    subCategories: ['professional', 'it', 'software'],
    acCode: 'Accounting / professional service fees',
    icon: Computer,
    tags: ['accounting', 'software', 'professional'],
    description: 'Expenses for accounting software and tools'
  },
  PROFESSIONAL_EXPENSES_CONSULTANTS: {
    code: '360',
    label: '360 - Professional Expenses - Consultants',
    category: 'expenses',
    subCategories: ['professional', 'consulting'],
    acCode: 'Accounting / professional service fees',
    icon: BusinessCenter,
    tags: ['consultants', 'professional', 'advisory'],
    description: 'Expenses for professional consultancy services'
  },
  SUBSCRIPTION_APPLE_MUSIC: {
    code: '361',
    label: '361 - Subscriptions - Apple Music',
    category: 'expenses',
    subCategories: ['subscriptions', 'media'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Wifi,
    tags: ['apple_music', 'subscription', 'media'],
    description: 'Subscription expenses for Apple Music'
  },
  SUBSCRIPTION_SPOTIFY: {
    code: '361-2',
    label: '361 - Subscriptions - Spotify',
    category: 'expenses',
    subCategories: ['subscriptions', 'media'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Wifi,
    tags: ['spotify', 'subscription', 'media'],
    description: 'Subscription expenses for Spotify'
  },
  SUBSCRIPTION_AMAZON: {
    code: '361-3',
    label: '361 - Subscriptions - Amazon Prime B2B',
    category: 'expenses',
    subCategories: ['subscriptions', 'business'],
    acCode: 'Office admin / postage / IT / phone',
    icon: Cloud,
    tags: ['amazon', 'prime', 'b2b'],
    description: 'Subscription expenses for Amazon Prime Business'
  },
  SUBSCRIPTION_OTHER_1: {
    code: '361-4',
    label: '361 - Subscriptions - Other 1',
    category: 'expenses',
    subCategories: ['subscriptions', 'miscellaneous'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['subscription', 'other', 'miscellaneous'],
    description: 'Expenses for miscellaneous subscription 1'
  },
  SUBSCRIPTION_OTHER_2: {
    code: '361-5',
    label: '361 - Subscriptions - Other 2',
    category: 'expenses',
    subCategories: ['subscriptions', 'miscellaneous'],
    acCode: 'Office admin / postage / IT / phone',
    icon: DevicesOther,
    tags: ['subscription', 'other', 'miscellaneous'],
    description: 'Expenses for miscellaneous subscription 2'
  },
  PROFESSIONAL_EXPENSES_LEGAL: {
    code: '362',
    label: '362 - Professional Expenses - Legal & Professional',
    category: 'expenses',
    subCategories: ['professional', 'legal'],
    acCode: 'Accounting / professional service fees',
    icon: Gavel,
    tags: ['legal', 'professional', 'services'],
    description: 'Expenses for legal and professional services'
  },
  PROFESSIONAL_EXPENSES_BOOKKEEPING: {
    code: '363',
    label: '363 - Professional Expenses - Book-Keeping',
    category: 'expenses',
    subCategories: ['professional', 'financial'],
    acCode: 'Accounting / professional service fees',
    icon: AccountBalanceWallet,
    tags: ['bookkeeping', 'accounting', 'professional'],
    description: 'Expenses for bookkeeping and accounting services'
  },
  PROFESSIONAL_EXPENSES_AUDIT: {
    code: '364',
    label: '364 - Professional Expenses - Audit',
    category: 'expenses',
    subCategories: ['professional', 'financial'],
    acCode: 'Accounting / professional service fees',
    icon: AccountBalance,
    tags: ['audit', 'financial', 'professional'],
    description: 'Expenses for professional audit services'
  },
  PROFESSIONAL_EXPENSES_EJN: {
    code: '365-1',
    label: '365 - Professional Expenses - Membership Fees - EJN',
    category: 'expenses',
    subCategories: ['professional', 'membership'],
    acCode: 'Miscellaneous expenses',
    icon: Group,
    tags: ['ejn', 'membership', 'professional'],
    description: 'Membership fees for European Jazz Network (EJN)'
  },
  PROFESSIONAL_EXPENSES_JPN: {
    code: '365-2',
    label: '365 - Professional Expenses - Membership Fees - JPN',
    category: 'expenses',
    subCategories: ['professional', 'membership'],
    acCode: 'Miscellaneous expenses',
    icon: Group,
    tags: ['jpn', 'membership', 'professional'],
    description: 'Membership fees for Jazz Promotion Network (JPN)'
  },
  SMALL_DIFFERENCES: {
    code: '366',
    label: '366 - Small Differences Write Off',
    category: 'expenses',
    subCategories: ['financial', 'miscellaneous'],
    acCode: 'Bank Charges / Interest',
    icon: Payment,
    tags: ['write_off', 'small_differences', 'accounting'],
    description: 'Write-offs for small financial discrepancies'
  },
  BANK_CHARGES: {
    code: '371',
    label: '371 - Bank charges',
    category: 'expenses',
    subCategories: ['financial', 'fees'],
    acCode: 'Bank Charges / Interest',
    icon: AccountBalanceWallet,
    tags: ['bank', 'charges', 'fees'],
    description: 'Expenses for bank service charges'
  },
  BAD_DEBTS: {
    code: '373',
    label: '373 - Specific Bad Debts Write Off',
    category: 'expenses',
    subCategories: ['financial', 'write_off'],
    acCode: 'Bank Charges / Interest',
    icon: AttachMoney,
    tags: ['bad_debts', 'write_off', 'financial'],
    description: 'Write-offs for specific bad debts'
  },
  DONATIONS: {
    code: '374',
    label: '374 - Donations',
    category: 'expenses',
    subCategories: ['charitable', 'miscellaneous'],
    acCode: 'General fundraising / Development costss',
    icon: BusinessCenter,
    tags: ['donation', 'charitable', 'contribution'],
    description: 'Expenses for charitable donations'
  },
  EQUIPMENT_DEPRECIATION: {
    code: '349',
    label: '349 - Equipment Expenses - Depreciation',
    category: 'expenses',
    subCategories: ['equipment', 'financial'],
    acCode: 'Other costs (please specify)',
    icon: Build,
    tags: ['depreciation', 'equipment', 'accounting'],
    description: 'Depreciation expenses for company equipment'
  },
  EQUIPMENT_DISPOSAL: {
    code: '350',
    label: '350 - Equipment Expenses - Disposal of Equipment',
    category: 'expenses',
    subCategories: ['equipment', 'miscellaneous'],
    acCode: 'Other costs (please specify)',
    icon: Construction,
    tags: ['disposal', 'equipment', 'write_off'],
    description: 'Expenses related to disposal of company equipment'
  }
} as const;


// Helper functions
export const getIMCCodeByCode = (
  searchCode: string,
  allCodes: Record<string, IMCCodeMetadata>
): IMCCodeMetadata | undefined => {
  return Object.values(allCodes).find(item => item.code === searchCode);
};

export const getIMCCodesByTag = (
  tag: string,
  allCodes: Record<string, IMCCodeMetadata>
): IMCCodeMetadata[] => {
  return Object.values(allCodes).filter(item => 
    item.tags.includes(tag.toLowerCase())
  );
};

export const getIMCCodesByACCode = (
  acCode: string,
  allCodes: Record<string, IMCCodeMetadata>
): IMCCodeMetadata[] => {
  return Object.values(allCodes).filter(item => 
    item.tags.includes(acCode.toLowerCase())
  );
};


export const getIMCCodesBySubCategory = (
  subCategory: IMCSubCategory,
  allCodes: Record<string, IMCCodeMetadata>
): IMCCodeMetadata[] => {
  return Object.values(allCodes).filter(item => 
    item.subCategories.includes(subCategory)
  );
};

export const getIMCCodesByCategory = (
  category: IMCCategory,
  allCodes: Record<string, IMCCodeMetadata>
): IMCCodeMetadata[] => {
  return Object.values(allCodes).filter(item => 
    item.category === category
  );
};

// Helper functions
export const getIMCCodeOptions = (): { value: string; label: string }[] => {
  const allCodes = { ...IMC_INCOME_CODES, ...IMC_EXPENSE_CODES };
  return Object.values(allCodes).map(item => ({
    value: item.code,
    label: item.label
  }));
};

// Optional overload if we want to pass specific codes
export const getIMCCodeOptionsFromCodes = (
  allCodes: Record<string, IMCCodeMetadata>
): { value: string; label: string }[] => {
  return Object.values(allCodes).map(item => ({
    value: item.code,
    label: item.label
  }));
};