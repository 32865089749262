import React, { useState } from 'react';
import { 
  Avatar,
  Box,
  Menu,
  MenuItem,
  Divider,
  Typography,
  Tooltip,
  Badge
} from '@mui/material';
import {
  User,
  Settings,
  LogOut,
  Shield
} from 'lucide-react';
import { UserMenuProps } from '../types/navigation';


const UserMenu: React.FC<UserMenuProps> = ({
  userEmail,
  role = 'User',
  onLogout,
  onProfileClick,
  onSettingsClick
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Get initials for avatar
  const getInitials = (email: string) => {
    return email.split('@')[0].slice(0, 2).toUpperCase();
  };

  return (
    <>
      <Tooltip title={userEmail}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant="dot"
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: 'success.main',
              color: 'success.main',
              boxShadow: '0 0 0 2px #1B2A41', // Match your app background
              '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                border: '1px solid currentColor',
                content: '""',
              },
            },
          }}
        >
          <Avatar 
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ 
              width: 32, 
              height: 32,
              backgroundColor: 'primary.main',
              fontSize: '0.875rem',
              fontWeight: 600,
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.9
              }
            }}
          >
            {getInitials(userEmail)}
          </Avatar>
        </Badge>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        sx={{ mt: 1 }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle2">
            {userEmail}
          </Typography>
          <Typography 
            variant="caption" 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: 'text.secondary'
            }}
          >
            <Shield className="w-3 h-3" />
            {role}
          </Typography>
        </Box>
        
        <Divider />
        
        {onProfileClick && (
          <MenuItem onClick={() => { setAnchorEl(null); onProfileClick(); }}>
            <User className="w-4 h-4 mr-2" />
            Profile
          </MenuItem>
        )}
        
        {onSettingsClick && (
          <MenuItem onClick={() => { setAnchorEl(null); onSettingsClick(); }}>
            <Settings className="w-4 h-4 mr-2" />
            Settings
          </MenuItem>
        )}

        <Divider />
        
        <MenuItem 
          onClick={() => { setAnchorEl(null); onLogout(); }}
          sx={{ color: 'error.main' }}
        >
          <LogOut className="w-4 h-4 mr-2" />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;