// src/components/cooler/constants/inventoryConstants.ts
export const VENDORS = [
    'Shure', 'Neumann', 'Sennheiser', 'Fun Generation', 'pro snake',
    'Gravity', 'Flyht Pro', 'Presonus', 'Mackie', 'Fluid Audio',
    'Zoom', 'Rockboard', 'Klark Teknik', 'tc electronic', 'beyerdynamic',
    'iConnectivity', 'ADAM Audio', 't.akustik', 'myVolts', 'Magma',
    'Libec', 'Seetronic', 'Hosa', 'Empress Effects', 'Blackmagic Design',
    'Rockbag', 'Doughty', 'Rolls', 'Line6', 'Ableton',
    'Palmer', 'Enttec', 'Showtec', 'K&M', 'Millenium',
    'Roland', 'Varytec', 'Ortega', 'Harley Benton', 'Roadworx',
    'Rode', 'the t.racks', 'Korg', 'the t.bone', 'On-Stage',
    'Soundcraft', 'the t.amp', 'Cordial', 'the sssnake', 'Zultan',
    'Thon', 'Midas', 'Behringer', 'Kenton', 'Moog',
    'Baxxtar', 'Analog Cases', 'Thomann', 'Boss', 'Yamaha',
    'Kawai','Generic', 'Other', 'N/A'
  ] as const;
  
  export const CATEGORIES = [
    'Sound',
    'Lighting',
    'Video',
    'Power',
    'Office',
    'Stage',
    'Cables',
    'Cases',
    'Microphones',
    'Cameras',
    'Other',
    'FOH',
    'Rigging'
  ] as const;
  
  export const LOCATIONS = [
    'Main Press Left',
    'Main Press Right',
    'Outdoor Press',
    'Venue Rack',
    'Rehearsal Rack',
    'Sound Desk',
    'Sound Desk Drawers',
    'Elton Case Left',
    'Elton Case Right',
    'Cooler Venue',
    'Cooler Stage',
    'Cooler Bar',
    'Videography Desk Drawers',
    'Videography Desk',
    'Shelves Beside Door',
    'Area to the left of Sound Desk',
    'N/A',
    'In Situ'
  ] as const;
  
  export const SECOND_LOCATIONS = [
      'Shelf 1', 'Shelf 2', 'Shelf 3', 'Shelf 4', 'Shelf 5',
      'Shelf 6', 'Shelf 7', 'Shelf 8', 'Shelf 9', 'Shelf 10',
      'Drawer 1', 'Drawer 2', 'Drawer 3', 'Drawer 4', 'Drawer 5',
  ] as const;
  
  export const MAINTENANCE_TYPES = [
    'routine',
    'repair',
    'inspection'
  ] as const;
  
  // Categories that typically have serial numbers
  export const SERIAL_NUMBER_CATEGORIES = [
    'Sound',
    'Lighting',
    'Video',
    'Cameras',
    'Microphones'
  ] as const;
  
  // Helper functions
  export const shouldShowSerialNumber = (category: string): boolean => {
    return SERIAL_NUMBER_CATEGORIES.includes(category as any);
  };
  
  // Types
  export type Vendor = typeof VENDORS[number];
  export type Category = typeof CATEGORIES[number];
  export type Location = typeof LOCATIONS[number];
  export type SecondLocation = typeof SECOND_LOCATIONS[number];
  export type MaintenanceType = typeof MAINTENANCE_TYPES[number];
  
  // Status types
  export const STATUS_TYPES = {
    maintenance: {
      ok: 'ok',
      due: 'due',
      overdue: 'overdue'
    },
    equipment: {
      available: 'available',
      inUse: 'inUse',
      maintenance: 'maintenance',
      broken: 'broken'
    }
  } as const;