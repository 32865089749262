import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Alert,
  Chip,
  Button,
  Stack,
  TextField,
  TableSortLabel,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Download as DownloadIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import axiosInstance from '../../../utils/axiosConfig';

interface AdminBudget {
  _id: string;
  year: number;
  totalValue: number;
  adminExpenseLines: Array<{
    code: string;
    frequency: string;
    qty: number;
    cost: number;
    sub: number;
    total: number;
    notes?: string;
  }>;
  status: string;
}

interface GroupBudget {
  _id: string;
  name: string;
  totalIncome: number;
  totalExpenses: number;
  status: string;
  strandId?: string;
  eventCount: number; // New property
}

type SortKey = 'name' | 'totalExpenses' | 'totalIncome' | 'netPosition' | 'grantPercentage';
type SortDirection = 'asc' | 'desc';

const BudgetOverview: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [adminBudget, setAdminBudget] = useState<AdminBudget | null>(null);
  const [groupBudgets, setGroupBudgets] = useState<GroupBudget[]>([]);
  const [grantAmount, setGrantAmount] = useState<number>(350000);
  const [sortConfig, setSortConfig] = useState<{key: SortKey; direction: SortDirection}>({
    key: 'totalExpenses',
    direction: 'desc'
  });

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        setLoading(true);
        const adminResponse = await axiosInstance.get<AdminBudget>('/api/adminbudget/2025');
        setAdminBudget(adminResponse.data);
        const groupResponse = await axiosInstance.get<GroupBudget[]>('/api/group-budgets');
        setGroupBudgets(groupResponse.data);
      } catch (err) {
        console.error('Error fetching budgets:', err);
        setError('Failed to load budget data');
      } finally {
        setLoading(false);
      }
    };

    fetchBudgets();
  }, []);

  const calculateTotals = () => {
    const adminExpenses = adminBudget?.totalValue || 0;
    
    const tradingTotals = groupBudgets.reduce((acc, budget) => ({
      income: acc.income + (budget.totalIncome || 0),
      expenditure: acc.expenditure + (budget.totalExpenses || 0)
    }), { income: 0, expenditure: 0 });

    const tradingPosition = tradingTotals.income - tradingTotals.expenditure;
    const totalCostToAC = -tradingPosition + adminExpenses;
    const percentageOfGrant = (totalCostToAC / grantAmount) * 100;
    const overrun = totalCostToAC - grantAmount;

    return {
      tradingIncome: tradingTotals.income,
      tradingExpenditure: tradingTotals.expenditure,
      tradingPosition,
      adminExpenses,
      totalCostToAC,
      percentageOfGrant,
      overrun
    };
  };

  const sortedBudgets = [...groupBudgets].sort((a, b) => {
    let comparison = 0;
    switch (sortConfig.key) {
      case 'name':
        comparison = a.name.localeCompare(b.name);
        break;
      case 'totalExpenses':
        comparison = a.totalExpenses - b.totalExpenses;
        break;
      case 'totalIncome':
        comparison = a.totalIncome - b.totalIncome;
        break;
      case 'netPosition':
        comparison = (a.totalIncome - a.totalExpenses) - (b.totalIncome - b.totalExpenses);
        break;
      case 'grantPercentage':
        const aPercent = ((a.totalExpenses - a.totalIncome) / grantAmount) * 100;
        const bPercent = ((b.totalExpenses - b.totalIncome) / grantAmount) * 100;
        comparison = aPercent - bPercent;
        break;
    }
    return sortConfig.direction === 'asc' ? comparison : -comparison;
  });

  const handleSort = (key: SortKey) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'desc' ? 'asc' : 'desc'
    });
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const formatPercentage = (value: number): string => {
    return `${value.toFixed(1)}%`;
  };

  const handleRowClick = (budgetId: string) => {
    navigate(`/admin/group-budget/${budgetId}`);
  };

  if (loading) return <LinearProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const totals = calculateTotals();

  return (
    <Box sx={{ p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Budget Overview 2025</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField 
            label="Arts Council Grant"
            type="number"
            value={grantAmount}
            onChange={(e) => setGrantAmount(Number(e.target.value))}
            size="small"
            InputProps={{
              startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>
            }}
            sx={{ width: 200 }}
          />
          <Button
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => {/* Add export functionality */}}
          >
            Export Report
          </Button>
        </Stack>
      </Stack>

      {/* Trading Position Cards */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Trading Position
      </Typography>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Trading Income
              </Typography>
              <Typography variant="h4" color="success.main">
                {formatCurrency(totals.tradingIncome)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Trading Expenditure
              </Typography>
              <Typography variant="h4" color="error.main">
                {formatCurrency(totals.tradingExpenditure)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: totals.tradingPosition >= 0 ? 'success.light' : 'error.light' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Trading Position
              </Typography>
              <Typography variant="h4" color="white">
                {formatCurrency(totals.tradingPosition)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Arts Council Position */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
        Arts Council Position
      </Typography>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Administrative Costs
              </Typography>
              <Typography variant="h4">
                {formatCurrency(totals.adminExpenses)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: 'primary.light' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Total Cost to AC
              </Typography>
              <Typography variant="h4" color="white">
                {formatCurrency(totals.totalCostToAC)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Grant Usage
              </Typography>
              <Typography variant="h4" color={totals.percentageOfGrant > 95 ? 'error.main' : 'inherit'}>
                {formatPercentage(totals.percentageOfGrant)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ bgcolor: totals.overrun > 0 ? 'error.light' : 'success.light' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Budget Overrun
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                {totals.overrun > 0 && <WarningIcon color="inherit" />}
                <Typography variant="h4" color="white">
                  {formatCurrency(totals.overrun)}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Detailed Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'name'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('name')}
                >
                  Events
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Event Count</TableCell> {/* New column */}
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'totalExpenses'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('totalExpenses')}
                >
                  Expenditure
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'totalIncome'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('totalIncome')}
                >
                  Income
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'netPosition'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('netPosition')}
                >
                  Net Position
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortConfig.key === 'grantPercentage'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('grantPercentage')}
                >
                  % of Grant
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBudgets.map((budget) => (
              <TableRow 
                key={budget._id}
                onClick={() => handleRowClick(budget._id)}
                sx={{ 
                  cursor: 'pointer',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <TableCell>{budget.name}</TableCell>
                <TableCell align="right">{budget.eventCount}</TableCell> {/* Display event count */}
                <TableCell align="right">{formatCurrency(budget.totalExpenses)}</TableCell>
                <TableCell align="right">{formatCurrency(budget.totalIncome)}</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                    {budget.totalExpenses - budget.totalIncome >= 0 ? (
                      <TrendingUpIcon color="error" fontSize="small" />
                    ) : (
                      <TrendingDownIcon color="success" fontSize="small" />
                    )}
                    {formatCurrency(budget.totalIncome - budget.totalExpenses)}
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <Chip
                    label={formatPercentage(((budget.totalExpenses - budget.totalIncome) / grantAmount) * 100)}
                    color={((budget.totalExpenses - budget.totalIncome) / grantAmount) * 100 > 50 ? 'warning' : 'default'}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}

            {/* Trading Subtotal Row */}
            <TableRow sx={{ '& td': { fontWeight: 'bold', backgroundColor: 'grey.800' } }}>
        <TableCell>Trading Position</TableCell>
        <TableCell /> {/* Placeholder for Event Count */}
        <TableCell align="right">{formatCurrency(totals.tradingExpenditure)}</TableCell>
        <TableCell align="right">{formatCurrency(totals.tradingIncome)}</TableCell>
        <TableCell align="right">{formatCurrency(totals.tradingPosition)}</TableCell>
        <TableCell align="right">{formatPercentage(-totals.tradingPosition / grantAmount * 100)}</TableCell>
      </TableRow>

      {/* Admin Row */}
      {adminBudget && (
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">Administrative</Typography>
          </TableCell>
          <TableCell /> {/* Placeholder for Event Count */}
          <TableCell align="right">{formatCurrency(adminBudget.totalValue)}</TableCell>
          <TableCell align="right">-</TableCell>
          <TableCell align="right">{formatCurrency(-adminBudget.totalValue)}</TableCell>
          <TableCell align="right">
            {formatPercentage((adminBudget.totalValue / grantAmount) * 100)}
          </TableCell>
        </TableRow>
      )}

      {/* Final Total Row */}
      <TableRow sx={{ '& td': { fontWeight: 'bold', backgroundColor: 'primary.light', color: 'white' } }}>
        <TableCell>TOTAL COST TO AC</TableCell>
        <TableCell /> {/* Placeholder for Event Count */}
        <TableCell align="right">{formatCurrency(totals.tradingExpenditure + totals.adminExpenses)}</TableCell>
        <TableCell align="right">{formatCurrency(totals.tradingIncome)}</TableCell>
        <TableCell align="right">{formatCurrency(-totals.totalCostToAC)}</TableCell>
        <TableCell align="right">{formatPercentage(totals.percentageOfGrant)}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

      {/* Grant Usage Warning */}
      {totals.percentageOfGrant > 95 && (
        <Alert severity="warning" sx={{ mt: 3 }}>
          Warning: Total cost to Arts Council ({formatCurrency(totals.totalCostToAC)}) is using {formatPercentage(totals.percentageOfGrant)} of available grant funding ({formatCurrency(grantAmount)}).
        </Alert>
      )}
    </Box>
  );
};

export default BudgetOverview;