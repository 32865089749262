import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  Chip,
  Stack,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import {
  Wrench,
  Plus,
  Trash2,
  PackageSearch,
  AlertTriangle,
} from 'lucide-react';
import { debounce } from 'lodash';
import axiosInstance from '../../../../utils/axiosConfig';

interface InventoryItem {
  _id: string;
  name: string;
  category: string;
  quantity: number;
  primaryLocation: string;
  images?: Array<{ url: string }>;
}

interface TaskTool {
  inventoryItem: string;
  quantity: number;
  notes?: string;
}

interface TaskToolsListProps {
  value: TaskTool[];
  onChange: (tools: TaskTool[]) => void;
  error?: string;
}

export const TaskToolsList: React.FC<TaskToolsListProps> = ({
  value,
  onChange,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);

  // Debounced search function
  const searchInventory = debounce(async (term: string) => {
    if (!term) {
      setInventoryItems([]);
      return;
    }

    setLoading(true);
    setSearchError(null);

    try {
      const response = await axiosInstance.get('/api/inventory/search', {
        params: { 
          query: term,
          exclude: value.map(tool => tool.inventoryItem)
        }
      });
      setInventoryItems(response.data);
    } catch (error) {
      console.error('Failed to search inventory:', error);
      setSearchError('Failed to search inventory items');
      setInventoryItems([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  useEffect(() => {
    return () => {
      searchInventory.cancel();
    };
  }, []);

  const handleAddTool = (item: InventoryItem) => {
    const newTool: TaskTool = {
      inventoryItem: item._id,
      quantity: 1
    };
    onChange([...value, newTool]);
    setSearchTerm('');
    setInventoryItems([]);
  };

  const handleRemoveTool = (toolId: string) => {
    onChange(value.filter(tool => tool.inventoryItem !== toolId));
  };

  const handleUpdateQuantity = (toolId: string, newQuantity: number) => {
    onChange(
      value.map(tool =>
        tool.inventoryItem === toolId
          ? { ...tool, quantity: newQuantity }
          : tool
      )
    );
  };

  const handleUpdateNotes = (toolId: string, notes: string) => {
    onChange(
      value.map(tool =>
        tool.inventoryItem === toolId
          ? { ...tool, notes }
          : tool
      )
    );
  };

  return (
    <Box>
      <Stack spacing={2}>
        {/* Search and Add Tools */}
        <Autocomplete
          fullWidth
          freeSolo
          options={inventoryItems}
          loading={loading}
          inputValue={searchTerm}
          onInputChange={(_, newValue) => {
            setSearchTerm(newValue);
            searchInventory(newValue);
          }}
          getOptionLabel={(option) => 
            typeof option === 'string' ? option : option.name
          }
          onChange={(_, newValue) => {
            if (newValue && typeof newValue !== 'string') {
              handleAddTool(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search for tools..."
              error={!!searchError}
              helperText={searchError}
              InputProps={{
                ...params.InputProps,
                startAdornment: <PackageSearch size={20} className="mr-2" />
              }}
            />
          )}
          renderOption={(props, option) => {
            if (typeof option === 'string') return null;
            
            return (
              <Box
                component="li"
                {...props}
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2,
                  p: 2
                }}
              >
                {option.images?.[0] ? (
                  <img
                    src={option.images[0].url}
                    alt={option.name}
                    style={{
                      width: 40,
                      height: 40,
                      objectFit: 'cover',
                      borderRadius: 4
                    }}
                  />
                ) : (
                  <Wrench size={40} />
                )}
                <Box>
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {option.category} • {option.primaryLocation} • 
                    {option.quantity} available
                  </Typography>
                </Box>
              </Box>
            );
          }}
        />

        {/* Selected Tools List */}
        {value.length > 0 ? (
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tool</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell>Notes</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.map((tool) => (
                  <TableRow key={tool.inventoryItem}>
                    <TableCell>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Wrench size={20} />
                        {/* We would typically want to load and cache the tool names */}
                        <Typography>{tool.inventoryItem}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        size="small"
                        value={tool.quantity}
                        onChange={(e) => {
                          const qty = parseInt(e.target.value);
                          if (!isNaN(qty) && qty > 0) {
                            handleUpdateQuantity(tool.inventoryItem, qty);
                          }
                        }}
                        sx={{ width: 80 }}
                        inputProps={{ min: 1 }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Add notes..."
                        value={tool.notes || ''}
                        onChange={(e) => handleUpdateNotes(tool.inventoryItem, e.target.value)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveTool(tool.inventoryItem)}
                      >
                        <Trash2 size={20} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              p: 3,
              textAlign: 'center',
              border: '2px dashed',
              borderColor: 'divider',
              borderRadius: 1,
            }}
          >
            <Wrench size={32} className="mb-2 text-gray-400" />
            <Typography color="text.secondary">
              No tools selected. Search and add tools from inventory.
            </Typography>
          </Box>
        )}

        {error && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'error.main' }}>
            <AlertTriangle size={20} />
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default TaskToolsList;