// src/components/budgets/sections/BudgetDebugVisualizer.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { 
  Paper, 
  Typography, 
  Grid, 
  Box,
  Divider 
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { RootState } from '../../../store/store';

const BudgetDebugVisualizer: React.FC = () => {
  const budgetState = useSelector((state: RootState) => state.budget);
  
  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ mb: 3, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Budget State Overview
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Artist Details Present:
              </Typography>
              {budgetState.artist ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Venue Details Present:
              </Typography>
              {budgetState.venue ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Production Details Present:
              </Typography>
              {budgetState.production ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Marketing Details Present:
              </Typography>
              {budgetState.marketing ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Income Details Present:
              </Typography>
              {budgetState.income ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                Budget Object Present:
              </Typography>
              {budgetState.budget ? 
                <CheckCircleIcon color="success" /> : 
                <CancelIcon color="error" />
              }
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Current Budget Structure
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ 
          bgcolor: 'grey.100', 
          p: 2, 
          borderRadius: 1,
          maxHeight: '400px',
          overflow: 'auto'
        }}>
          <pre style={{ margin: 0 }}>
            {JSON.stringify(budgetState, null, 2)}
          </pre>
        </Box>
      </Paper>
    </Box>
  );
};

export default BudgetDebugVisualizer;