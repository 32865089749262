import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import { InfoOutlined as InfoIcon, Edit as EditIcon } from '@mui/icons-material';

interface ExpenseLine {
  code: string;
  employmentCount?: number;
  employmentType?: 'ia-employ' | 'a-employ' | 'o-employ' | 'i-employ' | null;
}

interface GroupBudgetEmploymentProps {
  expenseLines: ExpenseLine[];
  onUpdate?: (field: string, value: number) => void;
}

const GroupBudgetEmployment: React.FC<GroupBudgetEmploymentProps> = ({
  expenseLines,
  onUpdate
}) => {
  // Local state for manual inputs
  const [manualStaffCount, setManualStaffCount] = useState<number>(0);
  const [manualAssociatedCount, setManualAssociatedCount] = useState<number>(0);
  const {
    internationalArtistEmployment,
    artistEmployment,
    otherEmployment,
    incidentalEmployment
  } = useMemo(() => {
    let internationalArtistEmployment = 0;
    let artistEmployment = 0;
    let otherEmployment = 0;
    let incidentalEmployment = 0;

    expenseLines.forEach(line => {
      if (!line.employmentCount) return;
      const effectiveType = line.employmentType || 'a-employ';

      switch(effectiveType) {
        case 'ia-employ':
          internationalArtistEmployment += line.employmentCount;
          break;
        case 'a-employ':
          artistEmployment += line.employmentCount;
          break;
        case 'o-employ':
          otherEmployment += line.employmentCount;
          break;
        case 'i-employ':
          incidentalEmployment += line.employmentCount;
          break;
        default:
          artistEmployment += line.employmentCount;
      }
    });

    return {
      internationalArtistEmployment,
      artistEmployment,
      otherEmployment,
      incidentalEmployment
    };
  }, [expenseLines]);

  const totalArtists = internationalArtistEmployment + artistEmployment;
  const totalEmployment = totalArtists + otherEmployment + incidentalEmployment;

  const handleOtherEmploymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, parseInt(event.target.value) || 0);
    setManualStaffCount(value);
    onUpdate?.('otherEmployment', value);
  };

  const handleIncidentalEmploymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(0, parseInt(event.target.value) || 0);
    setManualAssociatedCount(value);
    onUpdate?.('incidentalEmployment', value + incidentalEmployment); // Combine with calculated
  };

  return (
    <Paper 
      sx={{ 
        p: 3, 
        mb: 3,
        bgcolor: 'background.paper',
        borderRadius: 2
      }}
    >
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6">Employment Summary</Typography>
        <Tooltip title="Track employment numbers for reporting">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Artists Section */}
      <Typography 
        variant="subtitle1" 
        sx={{ mb: 2, color: 'text.secondary' }}
      >
        Artists
      </Typography>

      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
        gap: 2,
        mb: 4
      }}>
        {/* International Artists */}
        <Box sx={{ 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'rgba(64, 196, 255, 0.1)',
          border: '1px solid',
          borderColor: 'primary.dark',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="text.secondary">International Artists</Typography>
            <Tooltip title="Number of international artists">
              <IconButton size="small"><InfoIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h3" sx={{ color: 'primary.light' }}>
            {internationalArtistEmployment}
          </Typography>
        </Box>

        {/* Domestic Artists */}
        <Box sx={{ 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'rgba(64, 196, 255, 0.1)',
          border: '1px solid',
          borderColor: 'primary.dark',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="text.secondary">Domestic Artists</Typography>
            <Tooltip title="Number of domestic artists">
              <IconButton size="small"><InfoIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h3" sx={{ color: 'primary.light' }}>
            {artistEmployment}
          </Typography>
        </Box>

        {/* Total Artists */}
        <Box sx={{ 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'rgba(76, 175, 80, 0.1)',
          border: '1px solid',
          borderColor: 'success.dark',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="text.secondary">Total Artists</Typography>
            <Tooltip title="Total number of artists">
              <IconButton size="small"><InfoIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h3" sx={{ color: 'success.light' }}>
            {totalArtists}
          </Typography>
        </Box>
      </Box>

      {/* Other Employment Section */}
      <Typography 
        variant="subtitle1" 
        sx={{ mb: 2, color: 'text.secondary' }}
      >
        Other Employment
      </Typography>

      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', 
        gap: 2,
        mb: 4
      }}>
        {/* Staff Employed */}
        <Box sx={{ 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'rgba(255, 167, 38, 0.1)',
          border: '1px solid',
          borderColor: 'warning.dark',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="text.secondary">Staff Employed</Typography>
            <Tooltip title="Edit number of staff">
              <IconButton size="small"><EditIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            type="number"
            value={manualStaffCount}
            onChange={handleOtherEmploymentChange}
            inputProps={{ min: 0 }}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                fontSize: '2rem',
                height: 'auto',
                '& input': {
                  color: 'warning.light',
                  padding: '8px 14px',
                }
              }
            }}
          />
        </Box>

        {/* Associated Employment */}
        <Box sx={{ 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'rgba(255, 167, 38, 0.1)',
          border: '1px solid',
          borderColor: 'warning.dark',
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography color="text.secondary">Associated Employment</Typography>
            <Tooltip title="Edit associated employment">
              <IconButton size="small"><EditIcon fontSize="small" /></IconButton>
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            type="number"
            value={manualAssociatedCount}
            onChange={handleIncidentalEmploymentChange}
            inputProps={{ min: 0 }}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                fontSize: '2rem',
                height: 'auto',
                '& input': {
                  color: 'warning.light',
                  padding: '8px 14px',
                }
              }
            }}
          />
        </Box>
      </Box>

      {/* Total Employment */}
      <Box sx={{ 
        p: 3, 
        borderRadius: 2,
        bgcolor: 'rgba(76, 175, 80, 0.1)',
        border: '1px solid',
        borderColor: 'success.dark',
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          <Typography variant="h6" color="text.secondary">
            Total Employment
          </Typography>
          <Typography variant="h2" sx={{ color: 'success.light' }}>
            {totalEmployment}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default GroupBudgetEmployment;