// src/pages/admin/BudgetManager.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Grid,
  Alert,
  Stack,
  InputAdornment,
  AlertTitle
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  CalendarToday as CalendarIcon,
  Label as LabelIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import axiosInstance from '../utils/axiosConfig';
import { Budget } from '../types/Budget';
import { EventType } from '../types/EventTypes';

interface Strand {
    _id: string;
    name: string;
    year: number | string;
  }

const BudgetManager = () => {
  const [strands, setStrands] = useState<Strand[]>([]); // Instead of Array<{ _id: string; name: string }>
  const navigate = useNavigate();
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');


  // Form state
  const [formData, setFormData] = useState({
    name: '',
    type: 'event',
    strandId: ''
  });

  useEffect(() => {
    const fetchStrands = async () => {
      try {
        const response = await axiosInstance.get('/api/strands');
        setStrands(response.data);
      } catch (err) {
        console.error('Error fetching strands:', err);
        setError('Failed to fetch strands');
      }
    };
  
    fetchStrands();
  }, []);

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        setLoading(true);
        // Construct query params based on filters
        const params = new URLSearchParams();
        if (filterType) params.append('type', filterType);
        if (filterStatus) params.append('status', filterStatus);
  
        const response = await axiosInstance.get(`/api/budgets?${params.toString()}`);
        setBudgets(response.data);
      } catch (err) {
        console.error('Error fetching budgets:', err);
        setError('Failed to fetch budgets');
      } finally {
        setLoading(false);
      }
    };
  
    fetchBudgets();
  }, [filterType, filterStatus]); // Re-fetch wh

  const handleCreateBudget = async () => {
    try {
      const response = await axiosInstance.post('/api/budgets', formData);
      setShowCreateDialog(false);
      navigate(`/admin/budget/${response.data._id}`);
    } catch (err) {
      setError('Failed to create budget');
    }
  };

  const getStatusChipColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'success';
      case 'draft':
        return 'primary';
      default:
        return 'default';
    }
  };

  const handleCreateAndContinue = async (data: any) => {
    try {
      const response = await axiosInstance.post('/api/budgets', {
        ...data,
        status: 'draft'
      });
      // Directly navigate to the budget with the new ID
      navigate(`/admin/budget/${response.data._id}`);
    } catch (err) {
      setError('Failed to create budget');
    }
  };

  const getStrandName = (strandId: string) => {
    const strand = strands.find(s => s._id === strandId);
    return strand?.name || 'Unknown Strand';
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box>
          <Typography variant="h4" gutterBottom>
            Budget Management
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Create and manage your event budgets
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setShowCreateDialog(true)}
        >
          Create New Budget
        </Button>
      </Box>

      {/* Search and Filters */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              placeholder="Search budgets..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={filterType}
                label="Type"
                onChange={(e) => setFilterType(e.target.value)}
              >
                <MenuItem value="">All Types</MenuItem>
                <MenuItem value="event">Event</MenuItem>
                <MenuItem value="festival">Festival</MenuItem>
                <MenuItem value="project">Project</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={filterStatus}
                label="Status"
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <MenuItem value="">All Status</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Budgets Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Strand</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {budgets.map((budget) => (
    <TableRow key={budget._id}>
      <TableCell>{budget.name}</TableCell> {/* Test Budget */}
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LabelIcon fontSize="small" />
          <Typography>{budget.type}</Typography> {/* event */}
        </Stack>
      </TableCell>
      <TableCell>
        <Chip 
          label={budget.status} // draft
          color={getStatusChipColor(budget.status)}
          size="small"
        />
      </TableCell>
      <TableCell>
        {getStrandName(budget.strandId)} {/* Cooler Piano Series 2025 */}
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CalendarIcon fontSize="small" />
          <Typography>
            {new Date(budget.metadata.lastModified.date).toLocaleDateString()}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => navigate(`/admin/budget/${budget._id}`)}
        >
          Open
        </Button>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>

      {/* Create Budget Dialog */}
      <Dialog open={showCreateDialog} onClose={() => setShowCreateDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create New Budget</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            Please provide the following information to create a new budget.
          </DialogContentText>
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Budget Name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
<FormControl fullWidth>
  <InputLabel>Type</InputLabel>
  <Select
    value={formData.type}
    label="Type"
    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
  >
    {Object.values(EventType).map((type) => (
      <MenuItem key={type} value={type}>
        {type}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl fullWidth>
  <InputLabel>Strand</InputLabel>
  <Select
    value={formData.strandId}
    label="Strand"
    onChange={(e) => setFormData({ ...formData, strandId: e.target.value })}
  >
    <MenuItem value="">Select a strand...</MenuItem>
    {strands.map((strand) => (
      <MenuItem key={strand._id} value={strand._id}>
        {`${strand.name} (${strand.year})`}
      </MenuItem>
    ))}
  </Select>
</FormControl>
            <Alert severity="info">
              <AlertTitle>Next Steps</AlertTitle>
              After creating your budget, you'll be taken to the budget planner where you can:
              <ul>
                <li>Add artist and travel details</li>
                <li>Plan venue requirements</li>
                <li>Set up production needs</li>
                <li>Configure marketing budget</li>
              </ul>
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCreateDialog(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCreateBudget}
          >
            Create & Continue to Planner
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BudgetManager;