export const RELATIONSHIP_TYPES = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  SET: 'set',
  COMPATIBLE: 'compatible',
  REPLACEMENT: 'replacement',
} as const;

export type RelationshipType = typeof RELATIONSHIP_TYPES[keyof typeof RELATIONSHIP_TYPES];

export interface EquipmentRelationship {
  itemId: string;
  type: RelationshipType;
  isBidirectional: boolean;
  notes?: string;
  createdAt: Date;
  createdBy: string;
}

// Helper for relationship descriptions
export const RELATIONSHIP_DESCRIPTIONS = {
  [RELATIONSHIP_TYPES.REQUIRED]: 'Required for operation',
  [RELATIONSHIP_TYPES.OPTIONAL]: 'Optional accessory',
  [RELATIONSHIP_TYPES.SET]: 'Part of equipment set',
  [RELATIONSHIP_TYPES.COMPATIBLE]: 'Compatible with',
  [RELATIONSHIP_TYPES.REPLACEMENT]: 'Can replace/backup for',
} as const;