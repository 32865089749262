// src/components/cooler/inventory/components/AddItemDialog.tsx

import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Autocomplete,
  FormControlLabel,
  Switch,
  InputAdornment,
  Typography,
  Tooltip,
} from '@mui/material';
import {
  Upload as UploadIcon,
  Tag as CategoryIcon,
  MapPin as LocationIcon,
  Euro,
  Calendar,
  FileText,
  Info,
} from 'lucide-react';

import { Upload } from '../../shared/Upload';
import {
  CATEGORIES,
  LOCATIONS,
  SecondLocation,
  VENDORS,
  shouldShowSerialNumber,
  type Category,
  type Location,
  type Vendor
} from '../constants/inventoryConstants';
import { 
    hasSecondLocation, 
    getSecondLocations,
    getValidAlternateLocations 
  } from '../utils/locationHelpers';
import type { NewInventoryItem } from '../types/inventoryTypes';
import { SmartNameField } from './SmartNameField';
import { PRODUCTION_ITEMS } from '../constants/NamesConstants';

interface AddItemDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (item: NewInventoryItem) => void;
}

export const AddItemDialog: React.FC<AddItemDialogProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [newItem, setNewItem] = useState<NewInventoryItem>({
    _id: undefined,
    name: '',
    description: '',
    category: 'Sound' as Category,
    primaryLocation: 'Main Press Left' as Location,
    secondLocation: undefined, // shelf/drawer within primary location
    secondaryLocation: 'N/A' as Location,
    serialNumber: '',
    purchaseDate: '',
    purchaseValue: 0,
    currentValue: 0,
    vendor: 'Thon' as Vendor,
    requiresPower: false,
    powerLocation: 'Main Press Left' as Location,
    relatedEquipment: [],
    quantity: 1,
    tags: [],
    notes: '',
    images: [],
    isInsured: false,
  });
  const [availableSecondLocations, setAvailableSecondLocations] = useState<string[]>([]);
  const [availableSecondaryLocations, setAvailableSecondaryLocations] = useState<Location[]>([]);

  // Update available locations when primary location changes
  useEffect(() => {
    // Update shelf/drawer options
    if (hasSecondLocation(newItem.primaryLocation)) {
      setAvailableSecondLocations(getSecondLocations(newItem.primaryLocation));
    } else {
      setAvailableSecondLocations([]);
      // Clear second location if primary doesn't support it
      setNewItem(prev => ({
        ...prev,
        secondLocation: undefined
      }));
    }

    // Update alternative location options
    setAvailableSecondaryLocations(getValidAlternateLocations(newItem.primaryLocation));
  }, [newItem.primaryLocation]);
    
      const handlePrimaryLocationChange = (value: Location) => {
        setNewItem(prev => ({
          ...prev,
          primaryLocation: value,
          // Reset second location when primary changes
          secondLocation: undefined,
          // Reset secondary location if it's the same as the new primary
          secondaryLocation: prev.secondaryLocation === value ? 'N/A' as Location : prev.secondaryLocation
        }));
      };
    


  const handleSave = () => {
    onSave(newItem);
    setNewItem({
      _id: undefined,
      name: '',
      description: '',
      category: 'Sound' as Category,
      primaryLocation: 'Main Press Left' as Location,
      secondLocation: 'Shelf 1' as SecondLocation,
      secondaryLocation: 'N/A' as Location,
      serialNumber: '',
      purchaseDate: '',
      purchaseValue: 0,
      currentValue: 0,
      vendor: 'Thon' as Vendor,
      requiresPower: false,
      powerLocation: 'Main Press Left' as Location,
      relatedEquipment: [],
      quantity: 1,
      tags: [],
      notes: '',
      images: [],
      isInsured: false,
    });
  };

  const toTitleCase = (str: string) => {
    return str
      .toLowerCase() // Ensure the string is in lowercase first
      .split(' ')    // Split by spaces
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ');    // Join back into a single string
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Add New Item</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Grid container spacing={2}>
  {/* Images */}
  <Grid item xs={12} md={6}>
    <Typography variant="subtitle2" gutterBottom>Image</Typography>
    <Upload
  onUploadComplete={(urls) => {
    const newImageRefs = urls.map((url) => ({
      url,
      uploadedAt: new Date(),
      uploadedBy: '670561ef3fef62cdc14cf5c8',  // Use correct ID here
      originalFilename: url.split('/').pop() || '',
    }));
    setNewItem((prev) => ({
      ...prev,
      images: [...(prev.images || []), ...newImageRefs],
    }));
  }}
  maxFiles={5}
  accept=".pdf,image/*"
/>
  </Grid>

  {/* Proof of Sale */}
  <Grid item xs={12} md={6}>
    <Typography variant="subtitle2" gutterBottom>Proof Of Sale</Typography>
    <Upload
      onUploadComplete={(urls) => {
        setNewItem(prev => ({
          ...prev,
          proofOfSale: urls[0],
        }));
      }}
      maxFiles={1}
      accept=".pdf,image/*"
    />
  </Grid>

  {/* Name Field - Spanning Full Width */}
  <Grid item xs={12}>
  <SmartNameField
  value={toTitleCase(newItem.name)}
  onChange={(name, productionItem) => {
    if (productionItem) {
      // If a predefined item is selected
      setNewItem({
        ...newItem,
        name: toTitleCase(name),
        category: productionItem.categories[0] as Category,
        tags: [...productionItem.tags],
        requiresPower: productionItem.requiresPower || false,
      });
    } else {
      // If a custom name is entered, ensure category is set
      setNewItem(prev => ({
        ...prev,
        name: toTitleCase(name),
        category: prev.category || 'Other' as Category, // Ensure default category
      }));
    }
  }}
/>
  </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={newItem.category}
                  label="Category"
                  onChange={(e) => setNewItem({ ...newItem, category: e.target.value as Category })}
                  startAdornment={
                    <InputAdornment position="start">
                      <CategoryIcon size={20} />
                    </InputAdornment>
                  }
                >
                  {CATEGORIES.map((cat) => (
                    <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            multiline
            rows={2}
            label="Description"
            value={newItem.description}
            onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
          />

        {/* Location Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Location Information
            <Tooltip title="Select a primary location and optionally a shelf/drawer within it. Secondary location is for alternate storage.">
              <Info size={16} />
            </Tooltip>
          </Typography>
          
          <Grid container spacing={2}>
            {/* Primary Location */}
            <Grid item xs={12} md={hasSecondLocation(newItem.primaryLocation) ? 6 : 12}>
              <FormControl fullWidth required>
                <InputLabel>Primary Location</InputLabel>
                <Select
                  value={newItem.primaryLocation}
                  label="Primary Location"
                  onChange={(e) => handlePrimaryLocationChange(e.target.value as Location)}
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationIcon size={20} />
                    </InputAdornment>
                  }
                >
                  {LOCATIONS.map((loc) => (
                    <MenuItem key={loc} value={loc}>{loc}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Second Location (Shelf/Drawer) */}
            {hasSecondLocation(newItem.primaryLocation) && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Shelf/Drawer</InputLabel>
                  <Select
                    value={newItem.secondLocation || ''}
                    label="Shelf/Drawer"
                    onChange={(e) => setNewItem({ 
                      ...newItem, 
                      secondLocation: e.target.value as SecondLocation 
                    })}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableSecondLocations.map((loc) => (
                      <MenuItem key={loc} value={loc}>{loc}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Secondary Location */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Secondary Location</InputLabel>
                <Select
                  value={newItem.secondaryLocation}
                  label="Secondary Location"
                  onChange={(e) => setNewItem({ 
                    ...newItem, 
                    secondaryLocation: e.target.value as Location 
                  })}
                >
                  <MenuItem value="N/A">N/A</MenuItem>
                  {availableSecondaryLocations.map((loc) => (
                    <MenuItem 
                      key={loc} 
                      value={loc}
                      disabled={loc === newItem.primaryLocation}
                    >
                      {loc}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>


          {/* Purchase Info */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="date"
                label="Purchase Date"
                value={newItem.purchaseDate}
                onChange={(e) => setNewItem({ ...newItem, purchaseDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Calendar size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                freeSolo
                options={VENDORS}
                value={newItem.vendor}
                onChange={(_, newValue) => setNewItem({ ...newItem, vendor: newValue as Vendor })}
                renderInput={(params) => <TextField {...params} label="Vendor" />}
              />
            </Grid>
          </Grid>

          {/* Value and Insurance */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="number"
                label="Purchase Value"
                value={newItem.purchaseValue}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setNewItem({ 
                    ...newItem, 
                    purchaseValue: value,
                    currentValue: value,
                    isInsured: value >= 500
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Euro size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="number"
                label="Current Value"
                value={newItem.currentValue}
                onChange={(e) => setNewItem({ 
                  ...newItem, 
                  currentValue: parseFloat(e.target.value) 
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Euro size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {(newItem.purchaseValue || 0) >= 500 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newItem.isInsured}
                      onChange={(e) => setNewItem({ ...newItem, isInsured: e.target.checked })}
                    />
                  }
                  label="Insured"
                />
              )}
            </Grid>
          </Grid>

          {/* Serial Number - Only shown for relevant categories */}
          {shouldShowSerialNumber(newItem.category) && (
            <TextField
              fullWidth
              label="Serial Number"
              value={newItem.serialNumber}
              onChange={(e) => setNewItem({ ...newItem, serialNumber: e.target.value })}
            />
          )}

          {/* Power Requirements */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newItem.requiresPower}
                    onChange={(e) => setNewItem({ ...newItem, requiresPower: e.target.checked })}
                  />
                }
                label="Requires Power"
              />
            </Grid>
            {newItem.requiresPower && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Power Location</InputLabel>
                  <Select
                    value={newItem.powerLocation}
                    label="Power Location"
                    onChange={(e) => setNewItem({ ...newItem, powerLocation: e.target.value as Location })}
                  >
                    {LOCATIONS.map((loc) => (
                      <MenuItem key={loc} value={loc}>{loc}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {/* Additional Info */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Quantity"
                value={newItem.quantity}
                onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) })}
                inputProps={{ min: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tags"
                value={newItem.tags.join(', ')}
                onChange={(e) => setNewItem({ 
                  ...newItem, 
                  tags: e.target.value.split(',').map(tag => tag.trim()) 
                })}
                helperText="Separate tags with commas"
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            multiline
            rows={3}
            label="Notes / Instructions"
            value={newItem.notes}
            onChange={(e) => setNewItem({ ...newItem, notes: e.target.value })}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          disabled={!newItem.name || !newItem.category || !newItem.primaryLocation}
        >
          Save Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;