import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Stack,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { Package, ClipboardList, Plus, RefreshCw } from 'lucide-react';
import axiosInstance from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';

const CoolerDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const [inventoryCount, setInventoryCount] = useState<number | null>(null);
  const [taskCount, setTaskCount] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch counts separately to better identify which request fails
      const inventoryRes = await axiosInstance.get('/api/Inventory/count');
      console.log('Inventory response:', inventoryRes.data);
      setInventoryCount(inventoryRes.data.count);

      const tasksRes = await axiosInstance.get('/api/taskboards/count');
      console.log('Tasks response:', tasksRes.data);
      setTaskCount(tasksRes.data.count);

    } catch (err: any) {
      console.error('Failed to fetch dashboard data:', err);
      const errorMessage = err.response?.data?.error || 'Failed to load dashboard data';
      setError(errorMessage);
      
      // Reset counts on error
      setInventoryCount(null);
      setTaskCount(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Cooler Venue Dashboard
      </Typography>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        Manage venue tasks, maintenance, and inventory all in one place.
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={3} direction={{ xs: 'column', md: 'row' }}>
          {/* Inventory Overview */}
          <Paper sx={{ p: 3, flex: 1 }}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <Package size={48} />
              <Typography variant="h6">Inventory Items</Typography>
              <Typography variant="h4">{inventoryCount ?? '—'}</Typography>
              <Button
                variant="contained"
                startIcon={<ClipboardList />}
                onClick={() => navigate('/admin/inventory')}
              >
                View Inventory
              </Button>
            </Stack>
          </Paper>
          
          {/* Task Board Overview */}
          <Paper sx={{ p: 3, flex: 1 }}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <ClipboardList size={48} />
              <Typography variant="h6">Tasks</Typography>
              <Typography variant="h4">{taskCount ?? '—'}</Typography>
              <Button
                variant="contained"
                startIcon={<ClipboardList />}
                onClick={() => navigate('/admin/taskboard')}
              >
                View Tasks
              </Button>
            </Stack>
          </Paper>
        </Stack>
      )}

      <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
        <Button
          variant="contained"
          startIcon={<Plus />}
          onClick={() => navigate('/admin/taskboard')}
        >
          Add Task
        </Button>
        <Button
          variant="outlined"
          startIcon={<RefreshCw />}
          onClick={fetchDashboardData}
          disabled={loading}
        >
          Refresh Data
        </Button>
      </Stack>
    </Container>
  );
};

export default CoolerDashboardPage;