// src/components/cooler/constants/productionItems.ts

import {
    Mic,
    MicOff,
    HeadsetMic,
    MusicNote,
    Speaker,
    SurroundSound,
    CameraAlt,
    TripOrigin,
    TableChart,
    Power,
    Cable,
    LightMode,
    Computer,
    Memory,
    Battery20,
    SdCard,
    PowerSettingsNew,
    Keyboard,
    Monitor,
    VideoCall,
    Print,
    Laptop,
    LibraryMusic,
    AudiotrackOutlined,
    Queue,
    DeviceHub,
    Settings,
  } from '@mui/icons-material';
    import { Category } from './inventoryConstants';
    import { SvgIconComponent } from '@mui/icons-material';

export interface ProductionItem {
  readonly name: string;
  readonly categories: ReadonlyArray<Category>;  // Changed from string[] to ReadonlyArray<Category>
  readonly icon: SvgIconComponent;  // More specific type for Material-UI icons
  readonly tags: ReadonlyArray<string>;
  readonly requiresPower?: boolean;
  readonly hasSerial?: boolean;
}
  
  export const PRODUCTION_ITEMS: ReadonlyArray<ProductionItem> = [
        // Microphones
    {
      name: 'vocal microphone',
      categories: ['Microphones'],
      icon: Mic,
      tags: ['vocal', 'pa', 'mics'],
      hasSerial: true
    },
    {
      name: 'instrument microphone',
      categories: [ 'Microphones'],
      icon: MicOff,
      tags: ['instrument', 'mics', 'recording'],
      hasSerial: true
    },
    {
      name: 'condenser microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['condenser', 'studio', 'recording'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'dynamic microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['dynamic', 'live', 'robust'],
      hasSerial: true
    },
    {
      name: 'wireless microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['wireless', 'radio', 'battery', 'interview'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'lavalier microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['lavalier', 'clip', 'wireless', 'interview'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'headset microphone',
      categories: [ 'Microphones'],
      icon: HeadsetMic,
      tags: ['headset', 'wireless', 'performance'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'shotgun microphone',
      categories: ['Microphones'],
      icon: Mic,
      tags: ['shotgun', 'directional', 'video', 'film', 'camera', 'boom'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'boundary microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['boundary', 'conference', 'table'],
      hasSerial: true
    },
    {
      name: 'ribbon microphone',
      categories: [ 'Microphones'],
      icon: Mic,
      tags: ['ribbon', 'studio', 'vintage'],
      hasSerial: true
    },
  
    // Stands
    {
      name: 'mic stand-small',
      categories: ['Stage'],
      icon: TripOrigin,
      tags: ['stand', 'microphone', 'short']
    },
    {
      name: 'mic stand-tall',
      categories: ['Stage'],
      icon: TripOrigin,
      tags: ['stand', 'microphone', 'tall']
    },
    {
      name: 'mic stand-boom',
      categories: ['Stage'],
      icon: TripOrigin,
      tags: ['stand', 'microphone', 'boom']
    },
    {
      name: 'mic stand-table',
      categories: ['Stage'],
      icon: TripOrigin,
      tags: ['stand', 'microphone', 'table']
    },
  
    // Cables
    {
      name: '3.5mm jack cable',
      categories: ['Cables'],
      icon: Cable,
      tags: ['cable', 'audio', 'mini']
    },
    {
      name: '1/4" jack cable',
      categories: ['Cables'],
      icon: Cable,
      tags: ['cable', 'audio', 'instrument']
    },
    {
      name: 'XLR cable',
      categories: ['Cables'],
      icon: Cable,
      tags: ['cable', 'audio', 'microphone']
    },
    {
      name: 'DMX cable',
      categories: ['Cables'],
      icon: Cable,
      tags: ['cable', 'lighting', 'control']
    },
    {
      name: 'power cable',
      categories: [ 'Cables'],
      icon: Power,
      tags: ['cable', 'power', 'electrical']
    },
  
    // Music Equipment
    {
      name: 'guitar amplifier',
      categories: ['Sound', 'Stage'],
      icon: SurroundSound,
      tags: ['amplifier', 'guitar', 'instrument'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'guitar strings',
      categories: ['Sound'],
      icon: MusicNote,
      tags: ['strings', 'guitar', 'spare']
    },
    {
      name: 'music stand',
      categories: ['Stage'],
      icon: LibraryMusic,
      tags: ['stand', 'sheet music', 'performance']
    },
    {
      name: 'music stand light',
      categories: ['Stage', 'Lighting'],
      icon: LightMode,
      tags: ['light', 'music stand', 'performance'],
      requiresPower: true
    },
    {
      name: 'music stand clips',
      categories: ['Stage'],
      icon: Queue,
      tags: ['clips', 'music stand', 'accessory']
    },
  
    // Speakers
    {
      name: 'PA speaker',
      categories: ['Sound'],
      icon: Speaker,
      tags: ['speaker', 'pa', 'sound'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'monitor speaker',
      categories: ['Sound'],
      icon: Speaker,
      tags: ['speaker', 'monitor', 'stage'],
      requiresPower: true,
      hasSerial: true
    },
  
    // Office Equipment
    {
      name: 'printer',
      categories: ['Office'],
      icon: Print,
      tags: ['printer', 'office', 'documents'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'laptop',
      categories: ['Office'],
      icon: Laptop,
      tags: ['computer', 'portable', 'workstation'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'monitor',
      categories: ['Office'],
      icon: Monitor,
      tags: ['display', 'screen', 'computer'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'keyboard',
      categories: ['Office'],
      icon: Keyboard,
      tags: ['input', 'computer', 'typing'],
      hasSerial: true
    },
    {
      name: 'walkie talkie',
      categories: ['Office'],
      icon: Keyboard,
      tags: ['FOH', 'communication', 'radio'],
      hasSerial: false
    },
  
    // Camera Equipment
    {
      name: 'camera',
      categories: [ 'Cameras'],
      icon: CameraAlt,
      tags: ['camera', 'video', 'recording'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'camera tripod',
      categories: ['Video'],
      icon: TripOrigin,
      tags: ['support', 'camera', 'stabilization']
    },
    {
      name: 'camera slider',
      categories: ['Video'],
      icon: TripOrigin,
      tags: ['movement', 'camera', 'cinematic']
    },
    {
      name: 'camera lens',
      categories: [ 'Cameras'],
      icon: CameraAlt,
      tags: ['optics', 'camera', 'glass'],
      hasSerial: true
    },
    {
      name: 'camera battery',
      categories: ['Video', 'Cameras'],
      icon: Battery20,
      tags: ['power', 'camera', 'portable'],
      hasSerial: true
    },
    {
      name: 'camera memory card',
      categories: ['Video', 'Cameras'],
      icon: SdCard,
      tags: ['storage', 'camera', 'memory']
    },
    {
      name: 'camera light',
      categories: ['Video', 'Lighting'],
      icon: LightMode,
      tags: ['lighting', 'camera', 'video'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'camera monitor',
      categories: ['Video', 'Cameras'],
      icon: Monitor,
      tags: ['display', 'camera', 'preview'],
      requiresPower: true,
      hasSerial: true
    },
    {
      name: 'camera rig',
      categories: ['Video'],
      icon: VideoCall,
      tags: ['support', 'camera', 'stabilization']
    },
    {
      name: 'Rigging',
      categories: ['Video', 'Cameras'],
      icon: Settings,
      tags: ['video', 'camera', 'rigging'],
    },
  
    // Power & Accessories
    {
      name: 'power strip',
      categories: ['Power'],
      icon: DeviceHub,
      tags: ['power', 'distribution', 'electrical']
    },
    {
      name: 'power adapter',
      categories: ['Power'],
      icon: PowerSettingsNew,
      tags: ['power', 'converter', 'electrical'],
      hasSerial: true
    },
  
    // Furniture
    {
      name: 'stage table',
      categories: ['Stage'],
      icon: TableChart,
      tags: ['furniture', 'stage', 'surface']
    }
  ] as const;
  
  // Helper functions
  export const getItemByName = (name: string) => {
    return PRODUCTION_ITEMS.find(item => item.name === name);
  };
  
  // Change this function to use Category type
  export const getItemsByCategory = (category: Category) => {
    return PRODUCTION_ITEMS.filter(item => item.categories.includes(category));
  };
  
  export const getItemsByTag = (tag: string) => {
    return PRODUCTION_ITEMS.filter(item => item.tags.includes(tag));
  };
  
  // All available names for autocomplete
  export const ITEM_NAMES = PRODUCTION_ITEMS.map(item => item.name);

  // Update this to explicitly return Category array
  export const CATEGORIES = Array.from(
    new Set(PRODUCTION_ITEMS.flatMap(item => item.categories))
  ) as Category[];
  
  export const TAGS = Array.from(
    new Set(PRODUCTION_ITEMS.flatMap(item => item.tags))
  );