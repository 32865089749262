import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Stack
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ArrowUpward,
  ArrowDownward,
  ReceiptLong,
  SvgIconComponent
} from '@mui/icons-material';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import _ from 'lodash';
import { alpha } from '@mui/material/styles';
import { IMCCodeMetadata } from '../../../constants/imcCodes';
import EmploymentTracker from './EmploymentTracker';

interface ExpenseLineData {
  code: string;
  quantity: number;
  value: number;
  multiplier: number;
  notes: string;
    // New fields
  employmentCount?: number;
  employmentType?: 'ia-employ' | 'a-employ' | 'o-employ' | 'i-employ' | null;
  employmentNotes?: string;
}

interface ExpenseLine extends ExpenseLineData {
  id: string;
}

interface ExpenseLinesManagerProps {
  lines: ExpenseLineData[];
  onUpdate: (lines: ExpenseLineData[]) => void;
}

type SortField = 'code' | 'quantity' | 'value' | 'multiplier' | 'notes';
type SortDirection = 'asc' | 'desc';

const ExpenseLinesManager: React.FC<ExpenseLinesManagerProps> = ({ lines, onUpdate }) => {
  const [localLines, setLocalLines] = useState<ExpenseLine[]>(
    lines.map(line => ({
      ...line,
      id: _.uniqueId('line_')
    }))
  );
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState<SortField | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  // Added for filtering
  const [filterSubCat, setFilterSubCat] = useState<string>('');

  // Added for color coding
  const getBackgroundColorSubtle = (subCategories: readonly string[]) => {
    if (subCategories.includes('artist_fees')) return 'rgba(55, 48, 107, 0.75)';    // Muted Purple
    if (subCategories.includes('production')) return 'rgba(48, 107, 98, 0.75)';     // Muted Teal
    if (subCategories.includes('artist_sundry')) return 'rgba(107, 48, 55, 0.75)';  // Muted Rose
    if (subCategories.includes('marketing')) return 'rgba(48, 76, 107, 0.75)';      // Muted Blue
    if (subCategories.includes('technical')) return 'rgba(55, 63, 107, 0.75)';      // Muted Indigo
    if (subCategories.includes('administrative')) return 'rgba(107, 48, 76, 0.75)'; // Muted Burgundy
    return 'rgba(45, 45, 50, 0.75)'; // Dark neutral for default
  };

  const getHoverStyles = {
    subtle: (baseColor: string) => alpha(baseColor, 0.9),
  };
  
  // Border styles to enhance row separation
  const getBorderStyles = {
    subtle: '1px solid rgba(255, 255, 255, 0.08)',
  };
  
  useEffect(() => {
    const newTotal = localLines.reduce(
      (sum, line) => sum + (line.quantity * line.value * line.multiplier),
      0
    );
    setTotal(newTotal);
  }, [localLines]);

  useEffect(() => {
    const cleanedLines: ExpenseLineData[] = localLines.map(({ id, ...rest }) => ({
      ...rest,
      quantity: rest.quantity || 0,
      value: rest.value || 0,
      multiplier: rest.multiplier || 0
    }));

    if (JSON.stringify(cleanedLines) !== JSON.stringify(lines)) {
      onUpdate(cleanedLines);
    }
  }, [localLines, lines, onUpdate]);

  const sortedLines = useMemo(() => {
    // First filter the lines
    let filteredLines = filterSubCat 
      ? localLines.filter(line => {
          const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
          const subCategories: string[] = Array.from(code?.subCategories || []);
          return subCategories.includes(filterSubCat);
        })
      : localLines;

    // Then apply existing sort
    if (!sortField) return filteredLines;

    return [...filteredLines].sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      if (sortField === 'code') {
        const aCode = Object.values(IMC_EXPENSE_CODES).find(c => c.code === a.code)?.label || '';
        const bCode = Object.values(IMC_EXPENSE_CODES).find(c => c.code === b.code)?.label || '';
        aValue = aCode;
        bValue = bCode;
      }

      const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  }, [localLines, sortField, sortDirection, filterSubCat]);

  const handleSort = (field: SortField) => {
    setSortField(field);
    setSortDirection(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const addLine = () => {
    const newLine: ExpenseLine = {
      id: _.uniqueId('line_'),
      code: Object.values(IMC_EXPENSE_CODES)[0].code,
      quantity: 1,
      value: 0,
      multiplier: 1,
      notes: ''
    };
    setLocalLines(prevLines => [...prevLines, newLine]);
  };

  const updateLine = (id: string, field: keyof ExpenseLine, value: string | number) => {
    setLocalLines(lines =>
      lines.map(line => {
        if (line.id !== id) return line;

        if (field === 'value' || field === 'quantity' || field === 'multiplier') {
          return {
            ...line,
            [field]: value === '' ? 0 : Number(value)
          };
        }
        return { ...line, [field]: value };
      })
    );
  };

  const deleteLine = (id: string) => {
    setLocalLines(lines => lines.filter(line => line.id !== id));
  };

  const renderSortIcon = (field: SortField) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />;
  };

  return (
    <Box sx={{ maxWidth: '95vw', margin: '0 auto' }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Expense Lines</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small" sx={{ width: 200 }}>
            <Select
              value={filterSubCat}
              displayEmpty
              onChange={(e) => setFilterSubCat(e.target.value)}
            >
              <MenuItem value=""><em>All Categories</em></MenuItem>
              <MenuItem value="artist_fees">Artist Fees</MenuItem>
              <MenuItem value="artist_sundry">Artist Sundry</MenuItem>
              <MenuItem value="production">Production</MenuItem>
              <MenuItem value="marketing">Marketing</MenuItem>
              <MenuItem value="technical">Technical</MenuItem>
              <MenuItem value="administrative">Administrative</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" startIcon={<AddIcon />} onClick={addLine}>
            Add Line
          </Button>
        </Box>
      </Box>
  
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 900 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSort('code')}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ReceiptLong />
                  <Typography>Expense Type</Typography>
                  {renderSortIcon('code')}
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '80px', padding: '8px', cursor: 'pointer' }}
                onClick={() => handleSort('quantity')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                  <Typography>Qty</Typography>
                  {renderSortIcon('quantity')}
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '15%', cursor: 'pointer' }}
                onClick={() => handleSort('value')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                  <Typography>Value (€)</Typography>
                  {renderSortIcon('value')}
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{ width: '80px', padding: '8px', cursor: 'pointer' }}
                onClick={() => handleSort('multiplier')}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                  <Typography>Multi</Typography>
                  {renderSortIcon('multiplier')}
                </Box>
              </TableCell>
              <TableCell align="right" sx={{ width: '15%' }}>
                <Typography>Line Total</Typography>
              </TableCell>
              <TableCell sx={{ width: '48px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLines.map(line => {
              const codeDetails = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
              const rowBgColor = getBackgroundColorSubtle(codeDetails?.subCategories || []);
  
              return (
                <React.Fragment key={line.id}>
<TableRow sx={{ 
  backgroundColor: getBackgroundColorSubtle(codeDetails?.subCategories || []),
  borderBottom: getBorderStyles.subtle,
  // Add transition for smooth hover effect
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: getHoverStyles.subtle(getBackgroundColorSubtle(codeDetails?.subCategories || []))
  }
}}>                    <TableCell>
                      <Autocomplete<IMCCodeMetadata>
                        size="small"
                        options={Object.values(IMC_EXPENSE_CODES)}
                        getOptionLabel={option => option.label}
                        value={
                          Object.values(IMC_EXPENSE_CODES).find(code => code.code === line.code) || null
                        }
                        onChange={(_, newValue) => {
                          updateLine(
                            line.id,
                            'code',
                            newValue?.code || Object.values(IMC_EXPENSE_CODES)[0].code
                          );
                        }}
                        renderOption={(props, option) => {
                          const Icon = option.icon as SvgIconComponent;
                          const optionBgColor = getBackgroundColorSubtle(option.subCategories);
  
                          return (
                            <Box
                              component="li"
                              {...props}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: optionBgColor,
                                borderBottom: getBorderStyles.subtle,
                                '&:hover': {
                                  backgroundColor: getHoverStyles.subtle(optionBgColor)
                                },
                                my: 0.5,
                                borderRadius: 1,
                                // Enhanced text contrast
                                '& .MuiTypography-root': {
                                  color: 'rgba(255, 255, 255, 0.95)'
                                },
                                '& .MuiTypography-caption': {
                                  color: 'rgba(255, 255, 255, 0.7)'
                                }
                              }}
                            >
                              <Icon sx={{ fontSize: 20, color: 'text.secondary' }} />
                              <Box>
                                <Typography variant="body2">{option.label}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {option.tags.join(' • ')}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }}
                        renderInput={params => <TextField {...params} placeholder="Select expense type..." />}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        size="small"
                        value={line.quantity || ''}
                        onChange={e => updateLine(line.id, 'quantity', e.target.value)}
                        sx={{ width: '70px' }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        size="small"
                        value={line.value || ''}
                        onChange={e => updateLine(line.id, 'value', e.target.value)}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>
                        }}
                        sx={{ width: '120px' }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        size="small"
                        value={line.multiplier || ''}
                        onChange={e => updateLine(line.id, 'multiplier', e.target.value)}
                        sx={{ width: '70px' }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Typography fontWeight="bold" sx={{ fontSize: '1.2rem' }}>
                        €{(line.quantity * line.value * line.multiplier).toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => deleteLine(line.id)} color="error" size="small">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: rowBgColor }}>
                    <TableCell
                      colSpan={6}
                      sx={{
                        position: 'relative',
                        overflow: 'visible',
                        pt: 2,
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: '16px',
                          width: 0,
                          height: 0,
                          borderLeft: '8px solid transparent',
                          borderRight: '8px solid transparent',
                          borderBottom: '8px solid #999',
                          transform: 'translateY(-50%)',
                          zIndex: 2
                        }
                      }}
                    >
                      <Stack spacing={2}>
                        <TextField
                          multiline
                          minRows={1}
                          fullWidth
                          placeholder="Add notes..."
                          value={line.notes ?? ''}
                          onChange={(e) => updateLine(line.id, 'notes', e.target.value)}
                        />
                        
                        {Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code)?.subCategories.some(cat => 
                          ['ia-employ', 'a-employ', 'o-employ', 'i-employ'].includes(cat)
                        ) && (
                          <EmploymentTracker
                            code={line.code}
                            employmentCount={line.employmentCount || 0}
                            employmentType={line.employmentType || null}
                            employmentNotes={line.employmentNotes || ''}
                            onChange={(updates) => {
                              Object.entries(updates).forEach(([field, value]) => {
                                if (value !== null) {
                                  updateLine(line.id, field as keyof ExpenseLine, value);
                                }
                              });
                            }}
                            existingEmployment={localLines
                              .filter(l => l.id !== line.id && l.employmentCount && l.employmentType)
                              .map(l => ({
                                count: l.employmentCount || 0,
                                type: l.employmentType || ''
                              }))}
                          />
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            <TableRow>
              <TableCell colSpan={6} align="right">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addLine}
                >
                  Add Line
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  
      <Paper sx={{ mt: 3, p: 2, bgcolor: 'error.main', color: 'error.contrastText' }}>
        <Typography variant="h5" align="right">
          Total Expenses: €{total.toFixed(2)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default ExpenseLinesManager;