import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import { X as CloseIcon, Plus } from 'lucide-react';
import { type AxiosError } from 'axios';
import TaskForm from '../forms/TaskForm';
import { TaskFormValues } from '../forms/TaskForm';
import axiosInstance from '../../../../utils/axiosConfig';
import { Task } from '../types/taskTypes';

interface AddTaskDialogProps {
  open: boolean;
  onClose: () => void;
  onRefresh?: () => void;
}

export const AddTaskDialog: React.FC<AddTaskDialogProps> = ({
  open,
  onClose,
  onRefresh
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values: TaskFormValues) => {
    setIsSubmitting(true);
    setError(null);

    // Transform the subtasks to ensure assignedTo is properly formatted
    const transformedValues = {
      ...values,
      subtasks: values.subtasks.map(subtask => ({
        ...subtask,
        assignedTo: subtask.assignedTo || null, // Ensure we send null if no assignee
        title: subtask.title.trim(),
        completed: subtask.completed || false
      }))
    };

    console.log('Submitting task with transformed values:', transformedValues);

    try {
      const response = await axiosInstance.post('/api/taskboards', transformedValues);
      console.log('Task creation response:', response.data);
      onClose();
      onRefresh?.();
    } catch (err: unknown) {
      console.error('Failed to create task:', err);
      if (err instanceof Error) {
        setError(`Failed to create task: ${err.message}`);
      } else {
        setError('Failed to create task');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Provide initial values for the form
  const initialValues: TaskFormValues = {
    title: '',
    description: '',
    status: 'todo',
    priority: 'medium',
    progress: 0,
    category: 'general',
    dueDate: new Date().toISOString(),
    estimatedHours: 0,
    actualHours: 0,
    assignedTo: '',
    subtasks: [],
    tools: [],
    watchers: [],
    linkedInventoryItems: []
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: 'calc(100% - 64px)',
          maxHeight: 'calc(100% - 64px)'
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Plus size={24} />
            Add New Task
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon size={20} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}
        
        <TaskForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onCancel={onClose}
          isSubmitting={isSubmitting}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddTaskDialog;