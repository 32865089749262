import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
  Checkbox,
  ListItemText,
  FormHelperText,
  Alert
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { addMonths, format, isAfter, parseISO, isValid } from 'date-fns';
import { SelectChangeEvent } from '@mui/material';

interface GroupBudgetDatesLocationsProps {
  onUpdate?: (field: string, value: any) => void;
  eventCount?: number;
  initialDates?: DateConfig;
  location: string;
  dateOption: string;
  geoFocus: string;
}

interface DateConfig {
  period: string;
  dateType: string;
  startDate?: Date | null;
  endDate?: Date | null;
  dates?: (Date | null)[];
  selectedMonths?: string[];
  selectedQuarter?: string;
  biMonthlyPeriod?: string;
  reviewDate?: Date | null;
}

interface ValidationError {
  field: string;
  message: string;
}

// Constants
const PERIOD_OPTIONS = [
  'Single Date',
  'Date Range',
  'Monthly',
  'Bi-Monthly',
  'Quarterly',
  'Multiple Dates',
  'All Year Round'
] as const;

const MONTH_OPTIONS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
] as const;

const BIMONTHLY_OPTIONS = [
  'Jan-Feb',
  'Mar-Apr',
  'May-Jun',
  'Jul-Aug',
  'Sep-Oct',
  'Nov-Dec'
] as const;

const QUARTER_OPTIONS = [
  'Q1 (Jan-Mar)',
  'Q2 (Apr-Jun)',
  'Q3 (Jul-Sep)',
  'Q4 (Oct-Dec)'
] as const;

const LOCATION_OPTIONS = [
  // Counties
  'Antrim', 'Armagh', 'Carlow', 'Cavan', 'Clare', 'Cork', 'Derry',
  'Donegal', 'Down', 'Dublin', 'Fermanagh', 'Galway', 'Kerry', 'Kildare',
  'Kilkenny', 'Laois', 'Leitrim', 'Limerick', 'Longford', 'Louth', 'Mayo',
  'Meath', 'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary',
  'Tyrone', 'Waterford', 'Westmeath', 'Wexford', 'Wicklow',
  // Special options
  'National',
  'Digital',
  'Online',
  'Export',
  'International'
] as const;

const GroupBudgetDatesLocations: React.FC<GroupBudgetDatesLocationsProps> = ({
  onUpdate,
  eventCount = 0,
  initialDates
}) => {
  // State
  const [dateConfig, setDateConfig] = useState<DateConfig>({
    period: '',
    dateType: '',
    dates: [],
    selectedMonths: [],
    selectedQuarter: '',
    reviewDate: addMonths(new Date(), 3)
  });
  
  const [location, setLocation] = useState<string>('');
  const [geoFocus, setGeoFocus] = useState<string>('');
  const [volunteers, setVolunteers] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  // Initialize with initial dates if provided
  useEffect(() => {
    if (initialDates) {
      setDateConfig(initialDates);
    }
  }, [initialDates]);

  // Validate dates when they change
  useEffect(() => {
    validateDates();
  }, [dateConfig]);

  // Effect to handle event count changes
  useEffect(() => {
    if (eventCount > 0 && dateConfig.period === 'Multiple Dates') {
      setDateConfig(prev => ({
        ...prev,
        dates: Array(eventCount).fill(null)
      }));
    }
  }, [eventCount, dateConfig.period]);

  // Validation
  const validateDates = () => {
    const errors: ValidationError[] = [];

    switch (dateConfig.period) {
      case 'Date Range':
        if (dateConfig.startDate && dateConfig.endDate) {
          if (isAfter(dateConfig.startDate, dateConfig.endDate)) {
            errors.push({
              field: 'dateRange',
              message: 'End date must be after start date'
            });
          }
        }
        break;

      case 'Multiple Dates':
        if (dateConfig.dates) {
          // Check if we have the correct number of dates
          if (dateConfig.dates.length !== eventCount) {
            errors.push({
              field: 'multipleDates',
              message: `Please provide all ${eventCount} event dates`
            });
          }
          // Check if dates are in order
          for (let i = 1; i < dateConfig.dates.length; i++) {
            if (dateConfig.dates[i-1] !== null && dateConfig.dates[i] !== null && 
                isAfter(dateConfig.dates[i-1]!, dateConfig.dates[i]!)) {
              errors.push({
                field: 'multipleDates',
                message: `Event ${i} date must be after Event ${i-1} date`
              });
            }
          }
        }
        break;

      case 'Monthly':
        if (!dateConfig.selectedMonths?.length) {
          errors.push({
            field: 'months',
            message: 'Please select at least one month'
          });
        }
        break;
    }

    setValidationErrors(errors);
    return errors.length === 0;
  };

  // Date formatting helper
  const formatDate = (date: Date | null): string => {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  };

  // Parse date string helper
  const parseDate = (dateString: string): Date | null => {
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate) ? parsedDate : null;
  };

  // Handlers
  const handlePeriodChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const newPeriod = event.target.value;
    setDateConfig(prev => ({
      period: newPeriod,
      dateType: '',
      dates: newPeriod === 'Multiple Dates' ? Array(eventCount).fill(null) : [],
      selectedMonths: [],
      selectedQuarter: '',
      biMonthlyPeriod: '',
      reviewDate: addMonths(new Date(), 3)
    }));

    if (onUpdate) {
      onUpdate('period', newPeriod);
    }
  };

  const handleDateChange = (type: string, value: string) => {
    const date = parseDate(value);
    
    setDateConfig(prev => {
      const newConfig = { ...prev };
      
      switch (type) {
        case 'single':
          newConfig.dates = [date];
          break;
        case 'start':
          newConfig.startDate = date;
          break;
        case 'end':
          newConfig.endDate = date;
          break;
      }
      
      return newConfig;
    });

    if (onUpdate) {
      onUpdate('dateConfig', { ...dateConfig, [type === 'single' ? 'dates' : type + 'Date']: date });
    }
  };

  const handleMultipleDatesChange = (index: number, value: string) => {
    const date = parseDate(value);
    
    setDateConfig(prev => ({
      ...prev,
      dates: prev.dates?.map((d, i) => i === index ? date : d) || []
    }));

    if (onUpdate) {
      onUpdate('dateConfig', { ...dateConfig, dates: dateConfig.dates?.map((d, i) => i === index ? date : d) });
    }
  };

  const handleMonthsChange = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => {
    const selectedMonths = event.target.value as string[];
    
    setDateConfig(prev => ({
      ...prev,
      selectedMonths
    }));

    if (onUpdate) {
      onUpdate('dateConfig', { ...dateConfig, selectedMonths });
    }
  };

  const handleQuarterChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const quarter = event.target.value;
    
    setDateConfig(prev => ({
      ...prev,
      selectedQuarter: quarter
    }));

    if (onUpdate) {
      onUpdate('dateConfig', { ...dateConfig, selectedQuarter: quarter });
    }
  };

  const handleBiMonthlyChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const period = event.target.value;
    
    setDateConfig(prev => ({
      ...prev,
      biMonthlyPeriod: period
    }));

    if (onUpdate) {
      onUpdate('dateConfig', { ...dateConfig, biMonthlyPeriod: period });
    }
  };

  const handleLocationChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const newLocation = event.target.value;
    setLocation(newLocation);
    if (onUpdate) onUpdate('location', newLocation);
  };

  const handleGeoFocusChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const newGeoFocus = event.target.value;
    setGeoFocus(newGeoFocus);
    if (onUpdate) onUpdate('geoFocus', newGeoFocus);
  };

  const handleVolunteersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
    setVolunteers(newValue);
    if (onUpdate) onUpdate('volunteers', newValue);
  };

  // Get date fields based on period
  const getDateFields = () => {
    switch (dateConfig.period) {
      case 'Single Date':
        return (
          <Grid item xs={12} md={6}>
            <TextField
              type="date"
              fullWidth
              label="Event Date"
              InputLabelProps={{ shrink: true }}
              value={dateConfig.dates?.[0]?.toISOString().split('T')[0] || ''}
              onChange={(e) => handleDateChange('single', e.target.value)}
              helperText="Select the event date"
            />
          </Grid>
        );

      case 'Date Range':
        return (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                type="date"
                fullWidth
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                value={dateConfig.startDate?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateChange('start', e.target.value)}
                error={validationErrors.some(e => e.field === 'dateRange')}
                helperText="Select the start date"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="date"
                fullWidth
                label="End Date"
                InputLabelProps={{ shrink: true }}
                value={dateConfig.endDate?.toISOString().split('T')[0] || ''}
                onChange={(e) => handleDateChange('end', e.target.value)}
                error={validationErrors.some(e => e.field === 'dateRange')}
                helperText="Select the end date"
              />
            </Grid>
          </>
        );

      case 'Multiple Dates':
        return Array(eventCount).fill(null).map((_, index) => (
          <Grid item xs={12} md={6} key={index}>
            <TextField
              type="date"
              fullWidth
              label={`Event Date ${index + 1}`}
              InputLabelProps={{ shrink: true }}
              value={dateConfig.dates?.[index]?.toISOString().split('T')[0] || ''}
              onChange={(e) => handleMultipleDatesChange(index, e.target.value)}
              error={validationErrors.some(e => e.field === 'multipleDates')}
              helperText={`Select date for event ${index + 1}`}
            />
          </Grid>
        ));

      case 'Monthly':
        return (
          <Grid item xs={12}>
            <FormControl fullWidth error={validationErrors.some(e => e.field === 'months')}>
              <InputLabel>Select Months</InputLabel>
              <Select
                multiple
                value={dateConfig.selectedMonths || []}
                onChange={handleMonthsChange}
                renderValue={(selected) => (selected as string[]).join(', ')}
              >
                {MONTH_OPTIONS.map((month) => (
                  <MenuItem key={month} value={month}>
                    <Checkbox checked={dateConfig.selectedMonths?.includes(month)} />
                    <ListItemText primary={month} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select all applicable months</FormHelperText>
            </FormControl>
          </Grid>
        );

      case 'Bi-Monthly':
        return (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Select Period</InputLabel>
              <Select
                value={dateConfig.biMonthlyPeriod || ''}
                label="Select Period"
                onChange={handleBiMonthlyChange}
              >
                {BIMONTHLY_OPTIONS.map((period) => (
                  <MenuItem key={period} value={period}>{period}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a bi-monthly period</FormHelperText>
            </FormControl>
          </Grid>
        );

      case 'Quarterly':
        return (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Select Quarter</InputLabel>
              <Select
                value={dateConfig.selectedQuarter || ''}
                label="Select Quarter"
                onChange={handleQuarterChange}
              >
                {QUARTER_OPTIONS.map((quarter) => (
                  <MenuItem key={quarter} value={quarter}>{quarter}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a quarter</FormHelperText>
            </FormControl>
          </Grid>
        );

      default:
        return null;
    }
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6">Dates and Locations</Typography>
        <Tooltip title="Specify timing, location, and volunteer information">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      {validationErrors.length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {validationErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Period Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Period</InputLabel>
            <Select
              value={dateConfig.period}
              label="Period"
              onChange={handlePeriodChange}
            >
              {PERIOD_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select how the events will be distributed
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Dynamic Date Fields */}
        {getDateFields()}

        {/* Review Date */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Review Date"
            value={dateConfig.reviewDate ? format(dateConfig.reviewDate, 'dd/MM/yyyy') : ''}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Automatically set to 3 months from last edit">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Location */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Location</InputLabel>
            <Select
              value={location}
              label="Location"
              onChange={handleLocationChange}
            >
              {LOCATION_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select the primary location
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Geographic Focus */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Geographic Focus</InputLabel>
            <Select
              value={geoFocus}
              label="Geographic Focus"
              onChange={handleGeoFocusChange}
            >
              {LOCATION_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Select the target geographic area
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Volunteers */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Number of Volunteers"
            type="number"
            value={volunteers}
            onChange={handleVolunteersChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Enter the number of volunteers involved">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            helperText="Enter the expected number of volunteers"
          />
        </Grid>

        {/* Summary of dates if multiple are selected */}
        {dateConfig.period === 'Multiple Dates' && dateConfig.dates && dateConfig.dates.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, bgcolor: 'background.default' }}>
              <Typography variant="subtitle2" gutterBottom>
                Selected Dates Summary
              </Typography>
              {dateConfig.dates.map((date, index) => (
                <Typography key={index} variant="body2">
                  Event {index + 1}: {date ? format(date, 'dd/MM/yyyy') : 'Not set'}
                </Typography>
              ))}
            </Paper>
          </Grid>
        )}

        {/* Monthly Summary */}
        {dateConfig.period === 'Monthly' && dateConfig.selectedMonths && dateConfig.selectedMonths.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, bgcolor: 'background.default' }}>
              <Typography variant="subtitle2" gutterBottom>
                Selected Months
              </Typography>
              <Typography variant="body2">
                {dateConfig.selectedMonths.join(', ')}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default GroupBudgetDatesLocations;