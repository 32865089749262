import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  ListItemButton,
  Divider,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronDown,
  ChevronUp,
  User,
  LogOut,
} from 'lucide-react';
import { BaseNavProps } from '../../types/navigation';


// Import the same interfaces used by HoverNav for consistency
interface MenuItem {
  label: string;
  path?: string;
  icon?: React.ReactNode;
  children?: MenuItem[];
}

interface NavigationSection {
  mainItems: MenuItem[];
  dropdowns?: Record<string, MenuItem[]>;
}

interface UserMenuProps {
  userEmail: string;
  role: string;
  onLogout: () => void;
}

interface MobileNavProps {
  navigation: NavigationSection;
  userEmail: string;
  logo: React.ReactNode;
  UserMenu: React.ComponentType<UserMenuProps>;
  role: string;
  onLogout: () => void;
}

const MobileNav: React.FC<BaseNavProps> = ({
  navigation,
  userEmail,
  logo,
  role,
  onLogout
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [expandedSubSection, setExpandedSubSection] = useState<string | null>(null);

  const handleMobileSectionClick = (section: string) => {
    setExpandedSection(expandedSection === section ? null : section);
    setExpandedSubSection(null);
  };

  const handleMobileSubSectionClick = (section: string) => {
    setExpandedSubSection(expandedSubSection === section ? null : section);
  };

  // Render the icon safely
  const renderIcon = (icon: React.ReactNode | React.ComponentType<any>) => {
    if (!icon) return null; // If icon is falsy, return null
    if (typeof icon === 'function') {
      const IconComponent = icon; // Treat it as a component
      return <IconComponent />;
    }
    if (React.isValidElement(icon)) {
      return icon; // Already a valid React element
    }
    console.warn('Invalid icon passed:', icon); // Debugging invalid icons
    return null; // Return null for unsupported cases
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMobileMenuOpen(true)}
          >
            <MenuIcon className="w-6 h-6" />
          </IconButton>
          {logo}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ width: 280 }}>
        <List>
      {navigation.mainItems.map((item) => (
        <ListItemButton
          key={item.path}
          component={RouterLink}
          to={item.path || ''}
          onClick={() => setMobileMenuOpen(false)}
        >
          <ListItemIcon>{renderIcon(item.icon)}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItemButton>
      ))}
    </List>

          {navigation.dropdowns && (
            <>
              <Divider />
              <List>
                {Object.entries(navigation.dropdowns).map(([section, items]) => (
                  <Box key={section}>
                    <ListItemButton onClick={() => handleMobileSectionClick(section)}>
                      <ListItemText primary={section} />
                      {expandedSection === section ? 
                        <ChevronUp className="w-4 h-4" /> : 
                        <ChevronDown className="w-4 h-4" />
                      }
                    </ListItemButton>
                    <Collapse in={expandedSection === section}>
                      <List sx={{ pl: 2 }}>
                        {items.map((item) => 
                          item.children ? (
                            <Box key={item.label}>
                              <ListItemButton
                                onClick={() => handleMobileSubSectionClick(item.label)}
                                sx={{ pl: 2 }}
                              >
                                <ListItemText primary={item.label} />
                                {expandedSubSection === item.label ?
                                  <ChevronUp className="w-4 h-4" /> :
                                  <ChevronDown className="w-4 h-4" />
                                }
                              </ListItemButton>
                              <Collapse in={expandedSubSection === item.label}>
                                <List sx={{ pl: 2 }}>
                                  {item.children.map((child) => (
                                    <ListItemButton
                                      key={child.path}
                                      component={RouterLink}
                                      to={child.path || ''}
                                      onClick={() => setMobileMenuOpen(false)}
                                      sx={{ pl: 4 }}
                                    >
                                      <ListItemText primary={child.label} />
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Collapse>
                            </Box>
                          ) : (
                            <ListItemButton
                              key={item.path}
                              component={RouterLink}
                              to={item.path || ''}
                              onClick={() => setMobileMenuOpen(false)}
                              sx={{ pl: 4 }}
                            >
                              <ListItemText primary={item.label} />
                            </ListItemButton>
                          )
                        )}
                      </List>
                    </Collapse>
                  </Box>
                ))}
              </List>
            </>
          )}

          {userEmail && (
            <>
              <Divider />
              <List>
                <ListItem>
                  <ListItemIcon><User className="w-4 h-4" /></ListItemIcon>
                  <ListItemText primary={userEmail} secondary={role} />
                </ListItem>
                <ListItemButton onClick={onLogout}>
                  <ListItemIcon><LogOut className="w-4 h-4" /></ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </List>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export type { MobileNavProps, NavigationSection, MenuItem, UserMenuProps };
export default MobileNav;