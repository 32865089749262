import React from 'react';
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Performance, Venue } from '../types/mongodb';
import type { Strand } from '../types/Strand';

interface PerformanceFiltersProps {
  events: string[];
  bands: string[];
  strands: Strand[];
  venues: Venue[];
  filters: {
    event: string;
    band: string;
    strand: string;
    venue: string;
    status: string;
    dateRange: {
      start: Date | null;
      end: Date | null;
    };
    timeframe: 'all' | 'past' | 'upcoming' | 'thisMonth' | 'thisYear';
  };
  onFilterChange: (filterName: string, value: any) => void;
  activeFilters: string[];
  onClearFilter: (filterName: string) => void;
}

const PerformanceFilters: React.FC<PerformanceFiltersProps> = ({
  events,
  bands,
  strands,
  venues,
  filters,
  onFilterChange,
  activeFilters,
  onClearFilter
}) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Filters
      </Typography>
      
      {/* Active Filters Display */}
      {activeFilters.length > 0 && (
        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
          {activeFilters.map((filter) => (
            <Chip
              key={filter}
              label={filter}
              onDelete={() => onClearFilter(filter)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Stack>
      )}

      <Grid container spacing={2}>
        {/* Time Period Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Time Period</InputLabel>
            <Select
              value={filters.timeframe}
              onChange={(e) => onFilterChange('timeframe', e.target.value)}
              label="Time Period"
            >
              <MenuItem value="all">All Performances</MenuItem>
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="past">Past Performances</MenuItem>
              <MenuItem value="thisMonth">This Month</MenuItem>
              <MenuItem value="thisYear">This Year</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Custom Date Range */}
        <Grid item xs={12} md={3}>
          <DatePicker
            label="Start Date"
            value={filters.dateRange.start}
            onChange={(date) => onFilterChange('dateRange', { ...filters.dateRange, start: date })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            label="End Date"
            value={filters.dateRange.end}
            onChange={(date) => onFilterChange('dateRange', { ...filters.dateRange, end: date })}
          />
        </Grid>

        {/* Event Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Event</InputLabel>
            <Select
              value={filters.event}
              onChange={(e) => onFilterChange('event', e.target.value)}
              label="Event"
            >
              <MenuItem value="">All Events</MenuItem>
              {events.map((event) => (
                <MenuItem key={event} value={event}>{event}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Band Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Band</InputLabel>
            <Select
              value={filters.band}
              onChange={(e) => onFilterChange('band', e.target.value)}
              label="Band"
            >
              <MenuItem value="">All Bands</MenuItem>
              {bands.map((band) => (
                <MenuItem key={band} value={band}>{band}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Strand Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Strand</InputLabel>
            <Select
              value={filters.strand}
              onChange={(e) => onFilterChange('strand', e.target.value)}
              label="Strand"
            >
              <MenuItem value="">All Strands</MenuItem>
              {strands.map((strand) => (
                <MenuItem key={strand._id} value={strand._id}>{strand.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Venue Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Venue</InputLabel>
            <Select
              value={filters.venue}
              onChange={(e) => onFilterChange('venue', e.target.value)}
              label="Venue"
            >
              <MenuItem value="">All Venues</MenuItem>
              {venues.map((venue) => (
                <MenuItem key={venue._id} value={venue._id}>{venue.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Status Filter */}
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              onChange={(e) => onFilterChange('status', e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Statuses</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="confirmed">Confirmed</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PerformanceFilters;