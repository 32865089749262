// src/components/ideas/QuickIdeaButton.tsx
import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  Box, 
  IconButton, 
  Typography,
  Alert,
  Snackbar,
} from '@mui/material';
import { Plus, X } from 'lucide-react';
import QuickIdeaForm from './QuickIdeaForm';
import { useNavigate } from 'react-router-dom';

const QuickIdeaButton = () => {
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });
  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    setNotification({
      open: true,
      message: 'Idea saved successfully!',
      severity: 'success'
    });
    setOpen(false);
    
    // Redirect to ideas page and force a refresh
    navigate('/admin/ideas', { replace: true });
  };

  const handleSubmitError = (error: string) => {
    setNotification({
      open: true,
      message: error || 'Failed to save idea',
      severity: 'error'
    });
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          borderRadius: '50%',
          width: 64,
          height: 64,
          minWidth: 64,
          zIndex: 1000
        }}
        onClick={() => setOpen(true)}
      >
        <Plus />
      </Button>

      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box p={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Quick Save Idea</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <X />
            </IconButton>
          </Box>

          <QuickIdeaForm 
            onSubmit={handleSubmitSuccess}
            onError={handleSubmitError}
          />
        </Box>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default QuickIdeaButton;