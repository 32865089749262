import React, { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Button, Typography } from '@mui/material';
import axiosInstance from '../../utils/axiosConfig';
import { getSizeCategory } from './SizedStageElement';

interface StageElement {
  id: string;
  name: string;
  type: string;
  x: number;
  y: number;
  rotation: number;
}

interface PlacedElement extends StageElement {
  instanceId: string;
}

interface StagePlotData {
  name: string;
  description?: string;
  elements: PlacedElement[];
  createdAt?: Date;
  updatedAt?: Date;
}

const StagePlotPDF: React.FC = () => {
  const { id } = useParams();
  const [plotData, setPlotData] = useState<StagePlotData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPlotData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stageplots/${id}`);
        console.log('Raw plot data:', response.data);
        console.log('Elements:', response.data.elements);
        setPlotData(response.data);
      } catch (err) {
        setError('Failed to load stage plot');
        console.error('Error loading stage plot:', err);
      } finally {
        setLoading(false);
      }
    };
    loadPlotData();
  }, [id]);

  const generatePDF = () => {
    if (!plotData) return;
  
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
  
    type RGBColor = [number, number, number];
  
    // Function to get element size based on category
    const getElementSize = (elementId: string) => {
      const category = getSizeCategory(elementId);
      switch(category) {
        case 'xs': return 10;
        case 'sm': return 12;
        case 'md': return 15;
        case 'lg': return 20;
        case 'xl': return 25;
        default: return 15;
      }
    };
  
    // First Page - Stage Plot
    doc.setFontSize(24);
    doc.text(plotData.name || 'Untitled Stage Plot', 20, 20);
    
    doc.setFontSize(12);
    const dateStr = new Date(plotData.updatedAt || Date.now()).toLocaleDateString();
    doc.text(`Last updated: ${dateStr}`, 20, 30);
    
    if (plotData.description) {
      doc.text(`Description: ${plotData.description}`, 20, 40, { maxWidth: 180 });
    }
  
    // Draw stage area with scaled dimensions
    doc.setDrawColor(200);
    doc.setLineWidth(0.5);
    doc.rect(20, 50, 250, 140);
  
    // Draw elements with size consideration
    doc.setFontSize(8);
    plotData.elements.forEach(element => {
      if (!element) return;
  
      const elementSize = getElementSize(element.id);
      
      // Scale coordinates, adjusting for element size to prevent edge overlapping
      const x = 20 + (element.x * 250/1000);
      const y = 50 + (element.y * 140/600);
      
      // Adjust position based on size to prevent edge cases
      const adjustedX = Math.min(x, 270 - elementSize); // Keep within right boundary
      const adjustedY = Math.min(y, 190 - elementSize); // Keep within bottom boundary
  
      // Set color based on type
      let fillColor: RGBColor;
      switch (element.type) {
        case 'instrument':
          fillColor = [200, 230, 255];
          break;
        case 'microphone':
          fillColor = [255, 200, 200];
          break;
        case 'equipment':
          fillColor = [255, 230, 200];
          break;
        default:
          fillColor = [240, 240, 240];
      }
      
      // Draw element box with color
      doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);
      doc.rect(adjustedX, adjustedY, elementSize, elementSize, 'F');
      
      // Add black border
      doc.setDrawColor(0);
      doc.rect(adjustedX, adjustedY, elementSize, elementSize);
  
      // Add element name
      doc.setTextColor(0);
      const words = element.id
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1));
      
      // Adjust text position based on element size
      words.slice(0, 2).forEach((word, index) => {
        const textY = adjustedY + (elementSize/2) + (index * 3) - 2;
        doc.text(word, adjustedX + elementSize/2, textY, { align: 'center' });
      });
  
      // Add rotation if any
      if (element.rotation) {
        doc.text(`${element.rotation}°`, adjustedX + elementSize/2, adjustedY + elementSize - 2, { align: 'center' });
      }
    });
  
    // Add "FRONT OF STAGE" text at bottom
    doc.setFontSize(14);
    doc.setTextColor(0);
    doc.text('FRONT OF STAGE (AUDIENCE)', 150, 200, { align: 'center' });
  
    // Second Page - Technical Summary
    doc.addPage();
    doc.setFontSize(16);
    doc.text('Technical Requirements Summary', 20, 20);
  
    // Calculate technical summary
    const getTechnicalSummary = (elements: PlacedElement[]) => {
      const summary = {
        power: {
          drops: elements.filter(e => e.id === 'power-drop').length,
          strips: elements.filter(e => e.id === 'power-strip').length,
          total: 0,
        },
        audio: {
          diBoxes: elements.filter(e => e.id.includes('di-box')).length,
          monitors: elements.filter(e => 
            e.id === 'floor-monitor' || 
            e.id === 'drum-monitor' || 
            e.id.includes('side-fill')
          ).length,
          mics: elements.filter(e => e.id.includes('mic')).length,
        },
        backline: {
          amps: elements.filter(e => e.id.includes('amp')).length,
          stands: elements.filter(e => e.id.includes('stand')).length,
        },
        instruments: {
          keyboards: elements.filter(e => e.id.includes('piano') || e.id.includes('organ')).length,
          guitars: elements.filter(e => e.id.includes('guitar')).length,
          bass: elements.filter(e => e.id.includes('bass')).length,
          drums: elements.filter(e => e.id === 'drum-kit').length,
          brass: elements.filter(e => ['trumpet', 'trombone', 'saxophone'].some(item => e.id.includes(item))).length,
          list: elements
            .filter(e => e.type === 'instrument')
            .map(e => e.id.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
        },
        effects: {
          hasEffectsRack: elements.some(e => e.id === 'effects-rack'),
          count: elements.filter(e => e.id === 'effects-rack').length
        }
      };
  
      // Calculate total power requirements
      const powerNeeds = {
        'electric-piano': 1,
        'keyboard-amp': 1,
        'bass-amp': 1,
        'guitar-amp': 1,
        'effects-rack': 2,
        'mixer': 1,
        'laptop': 1,
        'pa-speaker-l': 1,
        'pa-speaker-r': 1,
        'subwoofer': 1,
        'monitor-mixer': 1,
        'digital-stage-box': 1,
      };
  
      summary.power.total = elements.reduce((total, element) => {
        return total + (powerNeeds[element.id as keyof typeof powerNeeds] || 0);
      }, 0);
  
      return summary;
    };
  
    const summary = getTechnicalSummary(plotData.elements);
    let yPos = 40;
  
    // Band Setup
    doc.setFontSize(14);
    doc.text('Band Setup:', 20, yPos);
    doc.setFontSize(10);
    yPos += 10;
  
    // List all instruments
    doc.text('Instruments Present:', 25, yPos);
    yPos += 7;
    summary.instruments.list.forEach(instrument => {
      doc.text(`• ${instrument}`, 30, yPos);
      yPos += 7;
    });
  
    yPos += 8;
    doc.text('Instrument Counts:', 25, yPos);
    yPos += 7;
    if (summary.instruments.keyboards) doc.text(`• Keyboards: ${summary.instruments.keyboards}`, 30, yPos);
    yPos += 7;
    if (summary.instruments.guitars) doc.text(`• Guitars: ${summary.instruments.guitars}`, 30, yPos);
    yPos += 7;
    if (summary.instruments.bass) doc.text(`• Bass: ${summary.instruments.bass}`, 30, yPos);
    yPos += 7;
    if (summary.instruments.drums) doc.text(`• Drums: ${summary.instruments.drums}`, 30, yPos);
    yPos += 7;
    if (summary.instruments.brass) doc.text(`• Brass: ${summary.instruments.brass}`, 30, yPos);
    yPos += 15;
  
    if (summary.effects.hasEffectsRack) {
      doc.text('Effects:', 25, yPos);
      yPos += 7;
      doc.text(`• Effects Rack: ${summary.effects.count}`, 30, yPos);
      yPos += 15;
    }
  
    // Power Requirements
    doc.setFontSize(14);
    doc.text('Power Requirements:', 20, yPos);
    doc.setFontSize(10);
    yPos += 10;
    doc.text(`• Power Drops Required: ${summary.power.drops}`, 25, yPos);
    yPos += 7;
    doc.text(`• Power Strips Required: ${summary.power.strips}`, 25, yPos);
    yPos += 7;
    doc.text(`• Total Power Outlets Needed: ${summary.power.total}`, 25, yPos);
    yPos += 15;
  
    // Audio Requirements
    doc.setFontSize(14);
    doc.text('Audio Requirements:', 20, yPos);
    doc.setFontSize(10);
    yPos += 10;
    doc.text(`• DI Boxes Required: ${summary.audio.diBoxes}`, 25, yPos);
    yPos += 7;
    doc.text(`• Stage Monitors Required: ${summary.audio.monitors}`, 25, yPos);
    yPos += 7;
    doc.text(`• Microphones Required: ${summary.audio.mics}`, 25, yPos);
    yPos += 15;
  
    // Backline Requirements
    doc.setFontSize(14);
    doc.text('Backline Requirements:', 20, yPos);
    doc.setFontSize(10);
    yPos += 10;
    doc.text(`• Amplifiers: ${summary.backline.amps}`, 25, yPos);
    yPos += 7;
    doc.text(`• Stands Required: ${summary.backline.stands}`, 25, yPos);
  
    // Save the PDF
    doc.save(`${plotData.name}-stage-plot.pdf`);
  };


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !plotData) {
    return (
      <Box p={3}>
        <Typography color="error">Error loading stage plot</Typography>
      </Box>    
    );
  }

  return (
    <Box p={3}>
      <Box mb={4}>
        <Typography variant="h4" gutterBottom>{plotData.name} - Stage Plot</Typography>
        {plotData.description && (
          <Typography variant="body1" color="textSecondary">
            {plotData.description}
          </Typography>
        )}
      </Box>
      <Button 
        variant="contained" 
        onClick={generatePDF}
        size="large"
      >
        Download Stage Plot PDF
      </Button>
    </Box>
  );
};

export default StagePlotPDF;