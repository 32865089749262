import React from 'react';
import {
  Box,
  TextField,
  Stack,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Subtask } from '../types/taskTypes';
import { User } from 'lucide-react';

interface SubtaskFormProps {
  subtask: Subtask;
  onChange: (data: Partial<Subtask>) => void;
  users?: Array<{ id: string; name: string }>;
}

const SubtaskForm: React.FC<SubtaskFormProps> = ({ 
  subtask, 
  onChange,
  users = []
}) => {
  const handleAssigneeChange = (_: any, newValue: { id: string; name: string } | null) => {
    console.log('New assignee selected:', newValue);
    onChange({ assignedTo: newValue?.id || undefined });
  };

  const currentAssignee = users.find(user => user.id === subtask.assignedTo);
  const dueDate = subtask.dueDate ? new Date(subtask.dueDate) : null;

  return (
    <Box sx={{ py: 1 }}>
      <Grid container spacing={2} alignItems="center">
        {/* Checkbox and Title */}
        <Grid item xs={12} md={5} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={subtask.completed}
                onChange={(e) => onChange({ completed: e.target.checked })}
              />
            }
            label=""
            sx={{ m: 0 }}
          />
          <TextField
            size="small"
            fullWidth
            placeholder="Subtask title"
            value={subtask.title}
            onChange={(e) => onChange({ title: e.target.value })}
            sx={{
              flex: 1,
              '& .MuiInputBase-root': {
                backgroundColor: 'background.paper'
              }
            }}
          />
        </Grid>

        {/* Assignee */}
        <Grid item xs={12} md={3.5}>
          <Autocomplete
            size="small"
            options={users}
            getOptionLabel={(option) => option.name}
            value={currentAssignee || null}
            onChange={handleAssigneeChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Assign to"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <User size={16} className="mr-1" />
                      {params.InputProps.startAdornment}
                    </>
                  )
                }}
                sx={{
                  backgroundColor: 'background.paper',
                  borderRadius: 1
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <User size={16} />
                  <span>{option.name}</span>
                </Stack>
              </li>
            )}
          />
        </Grid>

        {/* Due Date */}
        <Grid item xs={12} md={3.5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Due Date"
              value={dueDate}
              onChange={(newValue) => {
                onChange({ 
                  dueDate: newValue ? newValue.toISOString() : undefined 
                });
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  sx: {
                    backgroundColor: 'background.paper',
                    borderRadius: 1
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubtaskForm;