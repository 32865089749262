import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Breadcrumbs,
  Link,
  Tab,
  Tabs,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  CircularProgress,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import {
  Home as HomeIcon,
  AccountBalance as AccountBalanceIcon,
  Print as PrintIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import BudgetOverview from '../components/budgets/overview/BudgetOverview';
import BudgetCostAnalysis from '../components/budgets/overview/BudgetCostAnalysis';
import TicketAnalysis from '../components/budgets/overview/TicketAnalysis';
import axiosInstance from '../utils/axiosConfig';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface GroupBudget {
    _id: string;
    name: string;
    totalIncome: number;
    totalExpenses: number;
    expenseLines: Array<{
      code: string;
      quantity: number;
      value: number;
      multiplier: number;
    }>;
    incomeLines: Array<{
      code: string;
      quantity: number;
      value: number;
      multiplier: number;
      notes?: string;
    }>;
    status: string;
  }

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`budget-tabpanel-${index}`}
      aria-labelledby={`budget-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

const BudgetOverviewPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedYear, setSelectedYear] = useState<string>('2025');
  const [groupBudgets, setGroupBudgets] = useState<GroupBudget[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get<GroupBudget[]>('/api/group-budgets');
        setGroupBudgets(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching group budgets:', err);
        setError('Failed to load budgets');
      } finally {
        setLoading(false);
      }
    };

    fetchBudgets();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleExport = () => {
    // Implement export functionality
    console.log('Export functionality to be implemented');
  };

  return (
    <Container maxWidth="xl">
      {/* Header Section */}
      <Box sx={{ mb: 4, mt: 2 }}>
        <Breadcrumbs sx={{ mb: 2 }}>
          <Link
            color="inherit"
            href="/dashboard"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            color="inherit"
            href="/admin"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <AccountBalanceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Admin
          </Link>
          <Typography color="text.primary">Budget Overview</Typography>
        </Breadcrumbs>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" component="h1">
            Budget Overview
          </Typography>
          
          <Stack direction="row" spacing={2}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Year</InputLabel>
              <Select
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
                <MenuItem value="2026">2026</MenuItem>
              </Select>
            </FormControl>
            
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={handlePrint}
            >
              Print
            </Button>
            
            <Button
              variant="outlined"
              startIcon={<FileDownloadIcon />}
              onClick={handleExport}
            >
              Export
            </Button>
          </Stack>
        </Box>
      </Box>

      {/* Main Content */}
      <Paper sx={{ mb: 4 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderColor: 'divider', px: 2 }}
        >
          <Tab label="Overview" />
          <Tab label="Detailed Analysis" />
          <Tab label="Historical Comparison" />
          <Tab label="Cost Analysis" />
          <Tab label="Ticketing Analysis" />
        </Tabs>

        {loading && (
          <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Box sx={{ p: 3 }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        {!loading && !error && (
          <>
            <TabPanel value={activeTab} index={0}>
              <BudgetOverview />
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Typography variant="body1" color="text.secondary">
                Detailed analysis coming soon...
              </Typography>
            </TabPanel>

            <TabPanel value={activeTab} index={2}>
              <Typography variant="body1" color="text.secondary">
                Historical comparison coming soon...
              </Typography>
            </TabPanel>

            <TabPanel value={activeTab} index={3}>
              <BudgetCostAnalysis groupBudgets={groupBudgets} />
            </TabPanel>

            <TabPanel value={activeTab} index={4}>
              <TicketAnalysis groupBudgets={groupBudgets} />
            </TabPanel>
          </>
        )}
      </Paper>

      {/* Style for print media */}
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            #budget-tabpanel-0, #budget-tabpanel-0 * {
              visibility: visible;
            }
            #budget-tabpanel-0 {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        `}
      </style>
    </Container>
  );
};

export default BudgetOverviewPage;