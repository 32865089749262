import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Alert
} from '@mui/material';
import { Download } from 'lucide-react';
import { IMC_EXPENSE_CODES, IMC_INCOME_CODES } from '../../../constants/imcCodes';

interface BudgetLine {
  code: string;
  quantity: number;
  value: number;
  multiplier: number;
  notes?: string;
}

interface Budget {
  _id: string;
  strandId: string;
  name: string;
  eventType: string;
  targetAudience: string;
  budgetType: string;
  vatStatus: string;
  eventCount: number;
  status: string;
  incomeLines: BudgetLine[];
  expenseLines: BudgetLine[];
  totalIncome: number;
  totalExpenses: number;
  payingAttendance: number;
  freeAttendance: number;
  artistEmployment: number;
  otherEmployment: number;
}

interface IMCCode {
  code: string;
  label: string;
  category: string;
  subCategories: readonly string[];
  acCode: string;
  icon: any;
  tags: readonly string[];
  description: string;
}

interface OverrideableValues {
  payingAttendance: number;
  freeAttendance: number;
  artistEmployment: number;
  otherEmployment: number;
  eventCount: number;
}

type BoxOfficeSystem = 'yes' | 'no' | 'none';

type ExpenseTotals = {
  'Artist fees / wages': number;
  'Non-artist fees / wages': number;
  'Production / making / publishing / exhibition / logistical costs': number;
  'Marketing / PR costs': number;
  'Admin costs directly related to activity': number;
  'Other costs (please specify)': number;
};

type IncomeTotals = {
  'Earned income (Income from ticket sales or sales of publications or art objects)': number;
  'Guarantees': number;
  'Fees or other income from presenting partners': number;
  'Sponsorship': number;
  'Fundraising / Development': number;
  'Philanthropic donations': number;
  'Local authority funding': number;
  'Grant funding - national (excluding Arts Council)': number;
  'Grant funding - other (including other Arts council funding)': number;
  'Other Income (please specify)': number;
};

interface OtherCost {
  code: string;
  amount: number;
  notes?: string;
  label?: string;
}

interface AARAnalysisProps {
  budget: Budget;
}

const AARAnalysis: React.FC<AARAnalysisProps> = ({ budget }) => {
  const [overrides, setOverrides] = useState<OverrideableValues>({
    payingAttendance: budget.payingAttendance || 0,
    freeAttendance: budget.freeAttendance || 0,
    artistEmployment: budget.artistEmployment || 0,
    otherEmployment: budget.otherEmployment || 0,
    eventCount: budget.eventCount || 0
  });

  const [boxOfficeSystem, setBoxOfficeSystem] = useState<BoxOfficeSystem>('none');

  useEffect(() => {
    console.log('Budget data:', budget);
    console.log('Income lines:', budget.incomeLines);
    console.log('Expense lines:', budget.expenseLines);
  }, [budget]);

  const calculateExpenseTotals = (): ExpenseTotals => {
    const totals: ExpenseTotals = {
      'Artist fees / wages': 0,
      'Non-artist fees / wages': 0,
      'Production / making / publishing / exhibition / logistical costs': 0,
      'Marketing / PR costs': 0,
      'Admin costs directly related to activity': 0,
      'Other costs (please specify)': 0
    };

    budget.expenseLines.forEach((line) => {
      const matchingCodeEntry = Object.entries(IMC_EXPENSE_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );

      if (matchingCodeEntry) {
        const [_, codeData] = matchingCodeEntry;
        const total = line.quantity * line.value * line.multiplier;
        if (codeData.acCode in totals) {
          totals[codeData.acCode as keyof ExpenseTotals] += total;
        }
      }
    });

    return totals;
  };

  const calculateIncomeTotals = (): IncomeTotals => {
    const totals: IncomeTotals = {
      'Earned income (Income from ticket sales or sales of publications or art objects)': 0,
      'Guarantees': 0,
      'Fees or other income from presenting partners': 0,
      'Sponsorship': 0,
      'Fundraising / Development': 0,
      'Philanthropic donations': 0,
      'Local authority funding': 0,
      'Grant funding - national (excluding Arts Council)': 0,
      'Grant funding - other (including other Arts council funding)': 0,
      'Other Income (please specify)': 0
    };

    budget.incomeLines.forEach((line) => {
      const matchingCodeEntry = Object.entries(IMC_INCOME_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );

      if (matchingCodeEntry) {
        const [_, codeData] = matchingCodeEntry;
        const total = line.quantity * line.value * line.multiplier;
        if (codeData.acCode in totals) {
          totals[codeData.acCode as keyof IncomeTotals] += total;
        }
      }
    });

    return totals;
  };

  const getOtherCosts = (): OtherCost[] => {
    return budget.expenseLines.filter(line => {
      const matchingCodeEntry = Object.entries(IMC_EXPENSE_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return matchingCodeEntry && matchingCodeEntry[1].acCode === 'Other costs (please specify)';
    }).map(line => {
      const matchingCode = Object.entries(IMC_EXPENSE_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return {
        code: line.code,
        amount: line.quantity * line.value * line.multiplier,
        notes: line.notes,
        label: matchingCode ? matchingCode[1].label : undefined
      };
    });
  };

  const getOtherIncome = (): OtherCost[] => {
    return budget.incomeLines.filter(line => {
      const matchingCodeEntry = Object.entries(IMC_INCOME_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return matchingCodeEntry && matchingCodeEntry[1].acCode === 'Other Income (please specify)';
    }).map(line => {
      const matchingCode = Object.entries(IMC_INCOME_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return {
        code: line.code,
        amount: line.quantity * line.value * line.multiplier,
        notes: line.notes,
        label: matchingCode ? matchingCode[1].label : undefined
      };
    });
  };

  const expenseTotals = calculateExpenseTotals();
  const incomeTotals = calculateIncomeTotals();
  const otherCosts = getOtherCosts();
  const otherIncome = getOtherIncome();

  const expenseTotal = Object.values(expenseTotals).reduce((a, b) => a + b, 0);
  const incomeTotal = Object.values(incomeTotals).reduce((a, b) => a + b, 0);

  const hasTotalMismatch = 
    Math.abs(expenseTotal - (budget.totalExpenses || 0)) > 0.01 || 
    Math.abs(incomeTotal - (budget.totalIncome || 0)) > 0.01;

  const exportToCSV = () => {
    const rows = [
      ['Activity Description'],
      ['Activity name', budget.name.replace(' budget', '')],
      ['Artform', 'Music'],
      ['Activity type', budget.eventType],
      ['Primary target of activity', budget.targetAudience],
      [''],
      ['Activity Data'],
      ['Box Office System', boxOfficeSystem],
      ['Paying Attendance', overrides.payingAttendance],
      ['Free Attendance', overrides.freeAttendance],
      ['Artists Employed', overrides.artistEmployment],
      ['Others Employed', overrides.otherEmployment],
      ['Event Count', overrides.eventCount],
      [''],
      ['Activity Costs'],
      ...Object.entries(expenseTotals).map(([category, amount]) => [
        category,
        amount.toFixed(2)
      ]),
      ['Total Expenses (Calculated)', expenseTotal.toFixed(2)],
      ['Total Expenses (Budget)', budget.totalExpenses.toFixed(2)],
      [''],
      ['Activity Income'],
      ...Object.entries(incomeTotals).map(([category, amount]) => [
        category,
        amount.toFixed(2)
      ]),
      ['Total Income (Calculated)', incomeTotal.toFixed(2)],
      ['Total Income (Budget)', budget.totalIncome.toFixed(2)]
    ];

    if (otherCosts.length > 0) {
      rows.push(
        [''],
        ['Other Costs Breakdown'],
        ['Code', 'Label', 'Amount', 'Notes'],
        ...otherCosts.map(cost => [
          cost.code,
          cost.label || '',
          cost.amount.toFixed(2),
          cost.notes || ''
        ])
      );
    }
    if (otherIncome.length > 0) {
        rows.push(
          [''],
          ['Other Income Breakdown'],
          ['Code', 'Label', 'Amount', 'Notes'],
          ...otherIncome.map(cost => [
            cost.code,
            cost.label || '',
            cost.amount.toFixed(2),
            cost.notes || ''
          ])
        );
      }

    const csvContent = rows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${budget.name}_AAR.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Box sx={{ p: 3 }}>
      {hasTotalMismatch && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          The AAR totals don't match the budget totals. This may be due to unmapped codes.
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableBody>
            {/* Activity Description */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Activity Description</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Activity name</TableCell>
              <TableCell>{budget.name?.replace(' budget', '') || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Artform</TableCell>
              <TableCell>Music</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Activity type</TableCell>
              <TableCell>{budget.eventType || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary target of activity</TableCell>
              <TableCell>{budget.targetAudience || 'N/A'}</TableCell>
            </TableRow>

            {/* Activity Data */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" sx={{ mt: 3 }}>Activity Data</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Box Office System</TableCell>
              <TableCell>
                <RadioGroup
                  value={boxOfficeSystem}
                  onChange={(e) => setBoxOfficeSystem(e.target.value as BoxOfficeSystem)}
                  row
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                  <FormControlLabel value="none" control={<Radio />} label="Don't have a box office system" />
                </RadioGroup>
              </TableCell>
            </TableRow>

            {/* Overrideable Values */}
            {Object.entries(overrides).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={value}
                    onChange={(e) => setOverrides(prev => ({
                      ...prev,
                      [key]: Number(e.target.value)
                    }))}
                    sx={{ width: 200 }}
                  />
                </TableCell>
              </TableRow>
            ))}

            {/* Activity Costs */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" sx={{ mt: 3 }}>Activity Costs</Typography>
              </TableCell>
            </TableRow>
            {Object.entries(expenseTotals).map(([category, amount]) => (
              <TableRow key={category}>
                <TableCell>
                  {category}
                  {category === 'Other costs (please specify)' && otherCosts.length > 0 && (
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Codes: {otherCosts.map(c => `${c.code} (${c.label})`).join(', ')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>€{amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell><strong>Total Costs (Calculated)</strong></TableCell>
              <TableCell><strong>€{expenseTotal.toFixed(2)}</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Costs (Budget)</strong></TableCell>
              <TableCell><strong>€{budget.totalExpenses.toFixed(2)}</strong></TableCell>
            </TableRow>

            {/* Activity Income */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" sx={{ mt: 3 }}>Activity Income</Typography>
              </TableCell>
            </TableRow>
            {Object.entries(incomeTotals).map(([category, amount]) => (
  <TableRow key={category}>
    <TableCell>
      {category}
      {category === 'Other Income (please specify)' && otherIncome.length > 0 && (
        <Typography variant="caption" display="block" sx={{ mt: 1 }}>
          Codes: {otherIncome.map(c => `${c.code} (${c.label})`).join(', ')}
        </Typography>
      )}
    </TableCell>
    <TableCell>€{amount.toFixed(2)}</TableCell>
  </TableRow>
))}
            <TableRow>
              <TableCell><strong>Total Income (Calculated)</strong></TableCell>
              <TableCell><strong>€{incomeTotal.toFixed(2)}</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Income (Budget)</strong></TableCell>
              <TableCell><strong>€{budget.totalIncome.toFixed(2)}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Download size={18} />}
          onClick={exportToCSV}
        >
          Export to CSV
        </Button>
      </Box>
    </Box>
  );
};

export default AARAnalysis;