import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import { GroupBudgetLine } from '../../../types/groupBudget';

type FilterType = 'imc' | 'subcategory' | 'tags' | 'ac';

interface DetailedCostTableProps {
  expenseLines: GroupBudgetLine[];
  filter?: {
    type: FilterType;
    value: string | null;
  };
}

interface ProcessedLine {
  code: string;
  label: string;
  amount: number;
  percentage: number;
  acCode?: string;
}

const DetailedCostTable: React.FC<DetailedCostTableProps> = ({ 
  expenseLines,
  filter 
}) => {
  const processedData = React.useMemo(() => {
    // Apply filters
    const filteredLines = expenseLines.filter(line => {
      if (!filter?.value) return true;
      
      const codeDetails = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      if (!codeDetails) return false;

      switch (filter.type) {
        case 'imc':
          return line.code === filter.value;
        case 'subcategory':
          return (codeDetails.subCategories as unknown as string[]).includes(filter.value);
        case 'tags':
          return codeDetails.tags.includes(filter.value as never);
        case 'ac':
          return codeDetails.acCode === filter.value;
        default:
          return true;
      }
    });

    // Calculate total from filtered lines
    const total = filteredLines.reduce((sum, line) => 
      sum + (line.quantity * line.value * line.multiplier), 0);

    // Process filtered lines
    const lines = filteredLines.map(line => {
      const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      const amount = line.quantity * line.value * line.multiplier;
      
      return {
        code: line.code,
        label: code?.label || 'Unknown',
        amount,
        percentage: (amount / total) * 100,
        acCode: code?.acCode
      };
    });

    return lines.sort((a, b) => b.amount - a.amount);
  }, [expenseLines, filter]);

  // Calculate grand total for comparison
  const grandTotal = React.useMemo(() => 
    expenseLines.reduce((sum, line) => 
      sum + (line.quantity * line.value * line.multiplier), 0),
    [expenseLines]
  );

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Detailed Cost Breakdown
        {filter?.value && (
          <Typography 
            component="span" 
            variant="body2" 
            color="text.secondary" 
            sx={{ ml: 2 }}
          >
            (Filtered by {filter.type}: {filter.value})
          </Typography>
        )}
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>IMC Code</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">% of Selection</TableCell>
            <TableCell align="right">% of Total</TableCell>
            <TableCell>Accounting Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processedData.map(line => {
            const totalPercentage = (line.amount / grandTotal) * 100;
            return (
              <TableRow key={line.code}>
                <TableCell>
                  {line.code} - {line.label}
                </TableCell>
                <TableCell align="right">
                  €{line.amount.toLocaleString()}
                </TableCell>
                <TableCell align="right">
                  {line.percentage.toFixed(1)}%
                </TableCell>
                <TableCell align="right">
                  {totalPercentage.toFixed(1)}%
                </TableCell>
                <TableCell>{line.acCode}</TableCell>
              </TableRow>
            );
          })}
          {processedData.length > 0 && (
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                €{processedData.reduce((sum, line) => sum + line.amount, 0).toLocaleString()}
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>100%</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                {((processedData.reduce((sum, line) => sum + line.amount, 0) / grandTotal) * 100).toFixed(1)}%
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default DetailedCostTable;