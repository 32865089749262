// src/routes/artistRoutes.tsx

import React from 'react';
import { RouteObject } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import ArtistLayout from '../components/layout/ArtistLayout';
import ArtistDashboard from '../pages/Artists/ArtistDashboard';
import ArtistProfileEditPage from '../pages/Artists/ArtistProfileEditPage';
import PublicStrandsPage from '../pages/PublicStrandsPage';
import PublishedBandListPage from '../pages/PublishedBandListPage';
import StrandBandView from '../components/bandpage/StrandBandView';
import BandRecommendationPage from '../pages/BandRecommendationPage';
import BandContainer from '../components/bandpage/BandContainer';
import BanBamPage from '../pages/BanBamPage';
import FormPage from '../pages/FormPage';
import GuidelineViewPage from '../pages/GuidelineViewPage';
import MusicAdminPage from '../pages/Artists/MusicAdminPage';
import StageDesignerPage from '../pages/StageDesignerPage';
import ArtistSignupPage from '../pages/Artists/ArtistSignupPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import SubmissionSuccess from '../pages/SubmissionSuccess';
import StagePlotPDF from '../components/artists/StagePlotPDF';


// Protected Components
const ProtectedArtistDashboard = withAuth(ArtistDashboard, { allowedRoles: ['artist'] });
const ProtectedArtistProfileEditPage = withAuth(ArtistProfileEditPage, { allowedRoles: ['artist'] });
const ProtectedBandContainer = withAuth(BandContainer, { allowedRoles: ['artist', 'admin'] });
const ProtectedBandRecommendationPage = withAuth(BandRecommendationPage, { allowedRoles: ['artist'] });
const ProtectedStageDesignerPage = withAuth(StageDesignerPage, { allowedRoles: ['artist'] });
const ProtectedStagePlotPDF = withAuth(StagePlotPDF, { allowedRoles: ['artist'] });

export const artistRoutes: RouteObject[] = [
  {
    element: <ArtistLayout />,
    children: [
      { path: '/artist/dashboard', element: <ProtectedArtistDashboard /> },
      { path: '/artist/signup', element: <ArtistSignupPage /> },
      { path: '/artist/profile', element: <ProtectedArtistProfileEditPage /> },
      { path: '/publicstrands', element: <PublicStrandsPage /> },
      { path: '/strands/:strandSlug', element: <PublishedBandListPage /> },
      { path: '/strands/:strandSlug/band/:bandId', element: <StrandBandView /> },
      { path: '/guidelines/strand/:strandSlug', element: <GuidelineViewPage /> },
      { path: '/band/:id', element: <ProtectedBandContainer /> },
      { path: '/submit', element: <ProtectedBandRecommendationPage /> },
      { path: '/band/:id/edit', element: <ProtectedBandRecommendationPage /> },
      { path: '/banbam', element: <BanBamPage /> },
      { path: '/navigator', element: <FormPage /> },
      { path: '/musicadmin', element: <MusicAdminPage /> },
      { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
      { path: '/navigator/apply', element: <FormPage /> },
      { path: '/navigator/apply/:draftId', element: <FormPage /> },
      { path: '/submission-success', element: <SubmissionSuccess /> },
      
      { 
        path: '/artist/stage-plots', 
        element: <ProtectedStageDesignerPage /> 
      },
      { 
        path: '/artist/stage-plots/:id/view', 
        element: <ProtectedStagePlotPDF /> 
      },
      { 
        path: '/artist/stage-plots/:id/edit', 
        element: <ProtectedStageDesignerPage /> 
      }

    ],
  },
];

export default artistRoutes;