// Countries Of The World scr/types/Country.ts
export enum EuropeanCountry {
  Albania = 'Albania',
  Andorra = 'Andorra',
  Armenia = 'Armenia',
  Austria = 'Austria',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Bulgaria = 'Bulgaria',
  Croatia = 'Croatia',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech Republic',
  Denmark = 'Denmark',
  Estonia = 'Estonia',
  Finland = 'Finland',
  France = 'France',
  Germany = 'Germany',
  Greece = 'Greece',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  Ireland = 'Ireland',
  Italy = 'Italy',
  Latvia = 'Latvia',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Malta = 'Malta',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Montenegro = 'Montenegro',
  Netherlands = 'Netherlands',
  NorthMacedonia = 'North Macedonia',
  Norway = 'Norway',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Romania = 'Romania',
  SanMarino = 'San Marino',
  Serbia = 'Serbia',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  Spain = 'Spain',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Turkey = 'Turkey',
  Ukraine = 'Ukraine',
  UnitedKingdom = 'United Kingdom',
}

export enum NonEuropeanCountry {
    Afghanistan = 'Afghanistan',
    Algeria = 'Algeria',
    Argentina = 'Argentina',
    Australia = 'Australia',
    Brazil = 'Brazil',
    Canada = 'Canada',
    China = 'China',
    Egypt = 'Egypt',
    India = 'India',
    Indonesia = 'Indonesia',
    Iran = 'Iran',
    Iraq = 'Iraq',
    Israel = 'Israel',
    Japan = 'Japan',
    Kenya = 'Kenya',
    Mexico = 'Mexico',
    Morocco = 'Morocco',
    NewZealand = 'New Zealand',
    Nigeria = 'Nigeria',
    Pakistan = 'Pakistan',
    Peru = 'Peru',
    Philippines = 'Philippines',
    Russia = 'Russia',
    SaudiArabia = 'Saudi Arabia',
    SouthAfrica = 'South Africa',
    SouthKorea = 'South Korea',
    Thailand = 'Thailand',
    Tunisia = 'Tunisia',
    UnitedArabEmirates = 'United Arab Emirates',
    UnitedStates = 'United States',
    Venezuela = 'Venezuela',
    Vietnam = 'Vietnam',
  }

  export const TravelCostsToIreland: Record<EuropeanCountry | NonEuropeanCountry, number> = {
    [NonEuropeanCountry.Afghanistan]: 600,
    [NonEuropeanCountry.Algeria]: 300,
    [NonEuropeanCountry.Argentina]: 1_000,
    [NonEuropeanCountry.Australia]: 1_300,
    [NonEuropeanCountry.Brazil]: 900,
    [NonEuropeanCountry.Canada]: 600,
    [NonEuropeanCountry.China]: 800,
    [NonEuropeanCountry.Egypt]: 400,
    [NonEuropeanCountry.India]: 700,
    [NonEuropeanCountry.Indonesia]: 1_000,
    [NonEuropeanCountry.Iran]: 600,
    [NonEuropeanCountry.Iraq]: 600,
    [NonEuropeanCountry.Israel]: 400,
    [NonEuropeanCountry.Japan]: 900,
    [NonEuropeanCountry.Kenya]: 700,
    [NonEuropeanCountry.Mexico]: 800,
    [NonEuropeanCountry.Morocco]: 300,
    [NonEuropeanCountry.NewZealand]: 1_400,
    [NonEuropeanCountry.Nigeria]: 500,
    [NonEuropeanCountry.Pakistan]: 700,
    [NonEuropeanCountry.Peru]: 1_000,
    [NonEuropeanCountry.Philippines]: 1_200,
    [NonEuropeanCountry.Russia]: 400,
    [NonEuropeanCountry.SaudiArabia]: 600,
    [NonEuropeanCountry.SouthAfrica]: 800,
    [NonEuropeanCountry.SouthKorea]: 1_000,
    [NonEuropeanCountry.Thailand]: 900,
    [NonEuropeanCountry.Tunisia]: 300,
    [NonEuropeanCountry.UnitedArabEmirates]: 500,
    [NonEuropeanCountry.UnitedStates]: 600,
    [NonEuropeanCountry.Venezuela]: 1_000,
    [NonEuropeanCountry.Vietnam]: 1_200,
    [EuropeanCountry.Albania]: 250,
    [EuropeanCountry.Andorra]: 200,
    [EuropeanCountry.Armenia]: 300,
    [EuropeanCountry.Austria]: 200,
    [EuropeanCountry.Belarus]: 300,
    [EuropeanCountry.Belgium]: 150,
    [EuropeanCountry.BosniaAndHerzegovina]: 250,
    [EuropeanCountry.Bulgaria]: 250,
    [EuropeanCountry.Croatia]: 200,
    [EuropeanCountry.Cyprus]: 250,
    [EuropeanCountry.CzechRepublic]: 150,
    [EuropeanCountry.Denmark]: 150,
    [EuropeanCountry.Estonia]: 250,
    [EuropeanCountry.Finland]: 250,
    [EuropeanCountry.France]: 150,
    [EuropeanCountry.Germany]: 150,
    [EuropeanCountry.Greece]: 220,
    [EuropeanCountry.Hungary]: 200,
    [EuropeanCountry.Iceland]: 300,
    [EuropeanCountry.Ireland]: 0, // Already in Ireland
    [EuropeanCountry.Italy]: 200,
    [EuropeanCountry.Latvia]: 200,
    [EuropeanCountry.Liechtenstein]: 200,
    [EuropeanCountry.Lithuania]: 200,
    [EuropeanCountry.Luxembourg]: 150,
    [EuropeanCountry.Malta]: 300,
    [EuropeanCountry.Moldova]: 200,
    [EuropeanCountry.Monaco]: 200,
    [EuropeanCountry.Montenegro]: 250,
    [EuropeanCountry.Netherlands]: 150,
    [EuropeanCountry.NorthMacedonia]: 250,
    [EuropeanCountry.Norway]: 250,
    [EuropeanCountry.Poland]: 150,
    [EuropeanCountry.Portugal]: 150,
    [EuropeanCountry.Romania]: 150,
    [EuropeanCountry.SanMarino]: 200,
    [EuropeanCountry.Serbia]: 250,
    [EuropeanCountry.Slovakia]: 200,
    [EuropeanCountry.Slovenia]: 200,
    [EuropeanCountry.Spain]: 150,
    [EuropeanCountry.Sweden]: 250,
    [EuropeanCountry.Switzerland]: 150,
    [EuropeanCountry.Turkey]: 200,
    [EuropeanCountry.Ukraine]: 200,
    [EuropeanCountry.UnitedKingdom]: 80,
  };
