import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import HoverNav from './HoverNav';
import MobileNav from './MobileNav';
import UserMenu from '../UserMenu'; // Use your actual user menu component
import { getNavigationItems } from './constants/navigationItems';
import type { NavigationSection, BaseNavProps, UserMenuProps } from '../../types/navigation';

/** Navigation items might have an `icon` that’s not a valid React component.
    This function safely builds a real React node if the icon is a component. */
function safeIcon(icon: any) {
  if (typeof icon === 'function') {
    // If it’s a Lucide icon or other component, instantiate it:
    return React.createElement(icon, { size: 16 });
  }
  // Otherwise return undefined so we never pass an invalid child
  return undefined;
}

// Recursively convert each item’s icon (and children’s icons)
function convertMenuItem(item: any): any {
  return {
    ...item,
    icon: safeIcon(item.icon),
    children: item.children?.map(convertMenuItem),
  };
}

interface ResponsiveNavProps {
  role: string;
  logo: React.ReactNode;
  userEmail?: string;
  onLogout?: () => void;
  UserMenu?: React.ComponentType<UserMenuProps>; // Make it optional
}

const ResponsiveNav: React.FC<ResponsiveNavProps> = ({
  role,
  logo,
  userEmail,
  onLogout = () => {},
  UserMenu: CustomUserMenu, // If you want to override the user menu
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Build the nav data
  const rawNavigation = getNavigationItems(role);

  // Convert every item’s icon to a real React element (or undefined)
  const processedNavigation: NavigationSection = {
    mainItems: rawNavigation.mainItems.map(convertMenuItem),
    dropdowns: rawNavigation.dropdowns
      ? Object.fromEntries(
          Object.entries(rawNavigation.dropdowns).map(([key, items]) => [
            key,
            items.map(convertMenuItem),
          ])
        )
      : undefined,
  };

  // Must provide UserMenu to satisfy BaseNavProps
  const FinalUserMenu = CustomUserMenu || UserMenu;

  // Build the shared props for MobileNav / HoverNav
  const commonProps: BaseNavProps = {
    navigation: processedNavigation,
    userEmail: userEmail || '',
    logo,
    role,
    onLogout,
    UserMenu: FinalUserMenu,
  };

  // Render mobile or desktop version
  return isMobile ? <MobileNav {...commonProps} /> : <HoverNav {...commonProps} />;
};

export default ResponsiveNav;