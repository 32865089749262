import { InstrumentWithPerformer } from './shared';

// Band Activity Status
export enum BandActivityStatus {
  ACTIVE = 'active',
  DORMANT = 'dormant',
  HIATUS = 'hiatus',
  DISBANDED = 'disbanded',
  RECORDING = 'recording',
  TOURING = 'touring',
  REFORMING = 'reforming',
  PROJECT = 'project',
  UNKNOWN = 'unknown'
}

// Activity Status History Entry
export interface ActivityStatusEntry {
  status: BandActivityStatus;
  note: string;
  date: Date;
}

// European Countries
export enum EuropeanCountry {
  Albania = 'Albania',
  Andorra = 'Andorra',
  Armenia = 'Armenia',
  Austria = 'Austria',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Bulgaria = 'Bulgaria',
  Croatia = 'Croatia',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech Republic',
  Denmark = 'Denmark',
  Estonia = 'Estonia',
  Finland = 'Finland',
  France = 'France',
  Germany = 'Germany',
  Greece = 'Greece',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  Ireland = 'Ireland',
  Italy = 'Italy',
  Latvia = 'Latvia',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Malta = 'Malta',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Montenegro = 'Montenegro',
  Netherlands = 'Netherlands',
  NorthMacedonia = 'North Macedonia',
  Norway = 'Norway',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Romania = 'Romania',
  SanMarino = 'San Marino',
  Serbia = 'Serbia',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  Spain = 'Spain',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Turkey = 'Turkey',
  Ukraine = 'Ukraine',
  UnitedKingdom = 'United Kingdom',
}

// Irish Counties
export enum IrishCounty {
  // Republic of Ireland (26 counties)
  Carlow = 'Carlow',
  Cavan = 'Cavan',
  Clare = 'Clare',
  Cork = 'Cork',
  Donegal = 'Donegal',
  Dublin = 'Dublin',
  Galway = 'Galway',
  Kerry = 'Kerry',
  Kildare = 'Kildare',
  Kilkenny = 'Kilkenny',
  Laois = 'Laois',
  Leitrim = 'Leitrim',
  Limerick = 'Limerick',
  Longford = 'Longford',
  Louth = 'Louth',
  Mayo = 'Mayo',
  Meath = 'Meath',
  Monaghan = 'Monaghan',
  Offaly = 'Offaly',
  Roscommon = 'Roscommon',
  Sligo = 'Sligo',
  Tipperary = 'Tipperary',
  Waterford = 'Waterford',
  Westmeath = 'Westmeath',
  Wexford = 'Wexford',
  Wicklow = 'Wicklow',
  // Northern Ireland (6 counties)
  Antrim = 'Antrim',
  Armagh = 'Armagh',
  Derry = 'Derry',
  Down = 'Down',
  Fermanagh = 'Fermanagh',
  Tyrone = 'Tyrone'
}

// Main Band Recommendation Interface
export interface BandRecommendation {
  // Recommender Information
  _id?: string;
  recommenderName: string;
  recommenderEmail: string;
  recommenderCountry: string;
  recommendedFee: number;
  county?: string;
  isRecommenderApplicant: boolean;
  publishedTo?: string[];

  // Strand Information
  selectedStrand?: string;

  // Band/Artist Information
  artistName: string;
  maleMembers: number;
  femaleMembers: number;
  totalMembers: number;
  genderRatio: string;
  performances: string[];
  instrumentation: InstrumentWithPerformer[];
  about: string;

  // Activity Status Fields
  activityStatus: BandActivityStatus;
  activityStatusNote?: string;
  activityStatusDate: Date;
  previousStatus?: BandActivityStatus;
  statusHistory: ActivityStatusEntry[];

  // Media Links
  performanceLink: string;
  youtubeEmbeds: string[];
  spotifyEmbeds: string[];
  soundcloudEmbeds: string[];
  bandcampEmbeds: string[];

  // Additional Media
  instagramHandle?: string;
  twitterHandle?: string;
  facebookPage?: string;
  tikTokHandle?: string;

  // Contact Information
  contactName: string;
  contactEmail: string;
  contactPhone?: string;

  // Recommendation Details
  recommendationNotes: string;
  genres: string[];
  similarArtists?: string[];

  // Availability
  availability: {
    from: Date;
    to: Date;
    excludedDates?: Date[];
  };

  // Images
  mainImage: string;
  additionalImages?: string[];

  // Technical Requirements
  technicalRider?: {
    stageSize?: string;
    audioRequirements?: string;
    lightingRequirements?: string;
    additionalEquipment?: string[];
    stagePlot?: string;
    techSpecs?: string;
    lightingSpec?: string;
    stageImage?: string;
  };

  // Financial Information
  financialDetails?: {
    minimumFee?: number;
    preferredCurrency?: string;
    accommodationNeeded?: boolean;
    transportationNeeded?: boolean;
  };

  // Metadata
  createdAt: Date;
  updatedAt: Date;
  status: 'pending' | 'approved' | 'rejected';
  adminNotes?: string;
}

// Form Data Type (excludes computed and admin-only fields)
export type BandRecommendationFormData = Omit<
  BandRecommendation,
  'totalMembers' | 
  'genderRatio' | 
  'createdAt' | 
  'updatedAt' | 
  'status' | 
  'adminNotes' | 
  'statusHistory' | 
  'previousStatus'
>;

// Re-export types from shared
export type { Performer, InstrumentWithPerformer } from './shared';

// Utility type for activity status display information
export interface ActivityStatusInfo {
  label: string;
  description: string;
  color: 'success' | 'warning' | 'error' | 'info' | 'default';
}

// Utility function to get activity status display information
export const getActivityStatusInfo = (status: BandActivityStatus): ActivityStatusInfo => {
  const statusInfo: Record<BandActivityStatus, ActivityStatusInfo> = {
    [BandActivityStatus.ACTIVE]: {
      label: 'Active',
      description: 'Currently performing and available for bookings',
      color: 'success'
    },
    [BandActivityStatus.DORMANT]: {
      label: 'Dormant',
      description: 'Temporarily inactive but may return',
      color: 'warning'
    },
    [BandActivityStatus.HIATUS]: {
      label: 'On Hiatus',
      description: 'Officially announced break',
      color: 'warning'
    },
    [BandActivityStatus.DISBANDED]: {
      label: 'Disbanded',
      description: 'No longer together',
      color: 'error'
    },
    [BandActivityStatus.RECORDING]: {
      label: 'Recording',
      description: 'Currently focused on studio work',
      color: 'info'
    },
    [BandActivityStatus.TOURING]: {
      label: 'Touring',
      description: 'Actively touring',
      color: 'success'
    },
    [BandActivityStatus.REFORMING]: {
      label: 'Reforming',
      description: 'In process of getting back together',
      color: 'info'
    },
    [BandActivityStatus.PROJECT]: {
      label: 'Project',
      description: 'One-off or temporary project',
      color: 'info'
    },
    [BandActivityStatus.UNKNOWN]: {
      label: 'Unknown',
      description: 'Status not confirmed',
      color: 'default'
    }
  };

  return statusInfo[status];
};