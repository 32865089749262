// src/components/budgets/sections/OtherExpensesPlanner.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { OtherExpensesDetails, BudgetItem } from '../../../types/Budget';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import { RootState } from '../../../store/store';
import { updateOtherExpenses } from '../../../store/budgetSlice';

type IMCExpenseCodeKey = keyof typeof IMC_EXPENSE_CODES;
type MainCategory = 'administrative' | 'professional' | 'itSubscriptions' | 'other';
type CostCategory = MainCategory | 'productionEquipment' | 'productionPersonnel' | 'artistMisc';

interface CategoryConfig {
  title: string;
  codes: IMCExpenseCodeKey[];
  type: 'main' | 'costs';
}

const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : Number(parsed.toFixed(2));
  }
  return isFinite(value) ? Number(value.toFixed(2)) : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const EXPENSE_CATEGORIES: Record<CostCategory, CategoryConfig> = {
  productionEquipment: {
    title: 'Production Equipment',
    codes: [
      'PRODUCTION_EQUIPMENT_PURCHASES',
      'PRODUCTION_PROPS_STAGING',
      'PRODUCTION_BACKLINE_TRANSPORT',
      'PRODUCTION_PIANO_HIRE',
      'RECORDING_EXPENSES_STUDIO_RENTAL',
      'SUNDRY_PRODUCTION_EXPENSES_CONTINGENCIES'
    ] as IMCExpenseCodeKey[],
    type: 'costs'
  },
  productionPersonnel: {
    title: 'Production Personnel',
    codes: [
      'PRODUCTION_OTHER_CREW',
      'PRODUCTION_EMP_SAFETY_OFFICERS',
      'PRODUCTION_SPECIALIST_CREW',
      'RECORDING_EXPENSES_DESIGN',
      'RECORDING_EXPENSES_RECORDING_TECHNICIANS',
      'RECORDINGS_EXPENSES_ARTISTS_RECORDING_FEES'
    ] as IMCExpenseCodeKey[],
    type: 'costs'
  },
  artistMisc: {
    title: 'Artist Miscellaneous',
    codes: [
      'ARTISTS_MERCHANDISE',
      'ARTISTS_CATERING_HOSPITALITY',
      'ARTISTS_DEVELOPMENT_PROJECT_SUBVENTION'
    ] as IMCExpenseCodeKey[],
    type: 'costs'
  },
  administrative: {
    title: 'Administrative Expenses',
    codes: [
      'STAFF_EXPENSES_WFH_ALLOWANCE',
      'STAFF_EXPENSES_RECRUITMENT',
      'STAFF_EXPENSES_TRAINING',
      'STAFF_EXPENSES_TRAVEL',
      'OFFICE_EXPENSES_STATIONERY',
      'OFFICE_EXPENSES_CLEANING',
      'OFFICE_EXPENSES_COLOR_PRINTING',
      'BUILDING_EXPENSES_RENT',
      'BUILDING_EXPENSES_UTILITIES'
    ] as IMCExpenseCodeKey[],
    type: 'main'
  },
  professional: {
    title: 'Professional Services',
    codes: [
      'PROFESSIONAL_EXPENSES_LEGAL',
      'PROFESSIONAL_EXPENSES_BOOKKEEPING',
      'PROFESSIONAL_EXPENSES_AUDIT',
      'PROFESSIONAL_EXPENSES_CONSULTANTS'
    ] as IMCExpenseCodeKey[],
    type: 'main'
  },
  itSubscriptions: {
    title: 'IT & Subscriptions',
    codes: [
      'IT_EXPENSES_GOOGLE',
      'IT_EXPENSES_MONDAY',
      'IT_EXPENSES_DROPBOX',
      'IT_EXPENSES_ZOOM',
      'SUBSCRIPTION_SPOTIFY',
      'SUBSCRIPTION_APPLE_MUSIC'
    ] as IMCExpenseCodeKey[],
    type: 'main'
  },
  other: {
    title: 'Other Expenses',
    codes: [
      'BANK_CHARGES',
      'BAD_DEBTS',
      'EQUIPMENT_DEPRECIATION',
      'COMPANY_EXPENSES_INSURANCE'
    ] as IMCExpenseCodeKey[],
    type: 'main'
  }
};

const DEFAULT_OTHER_EXPENSES: OtherExpensesDetails = {
    administrative: [],
    professional: [],
    itSubscriptions: [],
    other: [],
    costs: {
      administrative: [],
      professional: [],
      productionEquipment: [],
      productionPersonnel: [],
      artistMisc: [],
      itSubscriptions: [],
      other: [],
      total: 0
    }
  };
  
  const OtherExpensesPlanner: React.FC = () => {
    const dispatch = useDispatch();
    const otherExpenses = useSelector((state: RootState) => 
      state.budget.otherExpenses || DEFAULT_OTHER_EXPENSES
    );
    
    const [localExpenses, setLocalExpenses] = useState<OtherExpensesDetails>(DEFAULT_OTHER_EXPENSES);
  
    useEffect(() => {
      if (otherExpenses) {
        setLocalExpenses(otherExpenses);
      }
    }, [otherExpenses]);
  
    const debouncedDispatch = useCallback(
      debounce((updateData: OtherExpensesDetails) => {
        dispatch(updateOtherExpenses(updateData));
      }, 300),
      []
    );
  
    useEffect(() => {
      if (JSON.stringify(otherExpenses) !== JSON.stringify(localExpenses)) {
        debouncedDispatch(localExpenses);
      }
    }, [localExpenses, otherExpenses, debouncedDispatch]);
  
    const createNewBudgetItem = useCallback((code: IMCExpenseCodeKey): BudgetItem => ({
      code: IMC_EXPENSE_CODES[code].code,
      label: IMC_EXPENSE_CODES[code].label,
      amount: 0,
      description: '',
      isEstimate: true,
      status: 'draft',
      category: 'expense'
    }), []);
  
    const addExpense = useCallback((category: CostCategory) => {
      const defaultCode = EXPENSE_CATEGORIES[category].codes[0];
      const newBudgetItem = createNewBudgetItem(defaultCode);
  
      setLocalExpenses(prev => {
        const updated = { ...prev };
        const newExpenseItem = {
          code: defaultCode,
          amount: 0,
          description: '',
          budgetItem: newBudgetItem
        };
  
        // Update costs array
        updated.costs[category] = [...updated.costs[category], newBudgetItem];
  
        // For main categories, also update top-level array
        if (EXPENSE_CATEGORIES[category].type === 'main' && category in updated) {
          (updated[category as MainCategory]).push(newExpenseItem);
        }
  
        return updated;
      });
    }, [createNewBudgetItem]);
  
    const updateExpense = useCallback((
      category: CostCategory,
      index: number,
      field: keyof BudgetItem,
      value: string | number
    ) => {
      setLocalExpenses(prev => {
        const updated = { ...prev };
        const processedValue = field === 'amount' ? safeNumber(value) : value;
  
        // Update the budget item in costs
        updated.costs[category] = [...updated.costs[category]];
        updated.costs[category][index] = {
          ...updated.costs[category][index],
          [field]: processedValue
        };
  
        // Update the main category if it exists
        if (EXPENSE_CATEGORIES[category].type === 'main' && category in updated) {
          const mainCategory = updated[category as MainCategory];
          mainCategory[index] = {
            ...mainCategory[index],
            [field]: processedValue,
            budgetItem: updated.costs[category][index]
          };
        }
  
        if (field === 'code') {
          const codeKey = Object.entries(IMC_EXPENSE_CODES)
            .find(([_, info]) => info.code === value)?.[0] as IMCExpenseCodeKey;
          if (codeKey) {
            updated.costs[category][index].label = IMC_EXPENSE_CODES[codeKey].label;
          }
        }
  
        return updated;
      });
    }, []);
  
    const removeExpense = useCallback((category: CostCategory, index: number) => {
      setLocalExpenses(prev => {
        const updated = { ...prev };
        
        // Remove from costs
        updated.costs[category] = updated.costs[category].filter((_, i) => i !== index);
  
        // Remove from main category if it exists
        if (EXPENSE_CATEGORIES[category].type === 'main' && category in updated) {
          const mainCategory = updated[category as MainCategory];
          updated[category as MainCategory] = mainCategory.filter((_, i) => i !== index);
        }
  
        return updated;
      });
    }, []);
  
    const getCategoryTotal = useCallback((category: CostCategory): number => {
      return safeNumber(
        localExpenses.costs[category]?.reduce(
          (sum, item) => sum + safeNumber(item.amount),
          0
        )
      );
    }, [localExpenses.costs]);
  
    const getTotalExpenses = useCallback((): number => {
      if (!localExpenses.costs) return 0;
      
      return safeNumber(
        Object.keys(EXPENSE_CATEGORIES).reduce(
          (sum, category) => sum + getCategoryTotal(category as CostCategory),
          0
        )
      );
    }, [localExpenses.costs, getCategoryTotal]);

  const renderExpenseCategory = useCallback((category: CostCategory) => {
    const config = EXPENSE_CATEGORIES[category];
    const expenses = localExpenses.costs[category] || [];

    return (
      <Paper sx={{ p: 2, mb: 3 }} key={category}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="subtitle1">{config.title}</Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => addExpense(category)}
            variant="contained"
            size="small"
          >
            Add Expense
          </Button>
        </Box>

        {expenses.map((item, index) => (
          <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Expense Type</InputLabel>
                <Select
                  value={item.code}
                  onChange={(e: SelectChangeEvent) => 
                    updateExpense(category, index, 'code', e.target.value)
                  }
                  label="Expense Type"
                >
                  {config.codes.map(code => (
                    <MenuItem key={code} value={IMC_EXPENSE_CODES[code].code}>
                      {IMC_EXPENSE_CODES[code].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Amount"
                type="number"
                value={item.amount || ''}
                onChange={(e) => updateExpense(category, index, 'amount', e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
                inputProps={{
                  min: 0,
                  step: "0.01"
                }}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Description"
                value={item.description || ''}
                onChange={(e) => updateExpense(category, index, 'description', e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={1}>
              <IconButton 
                onClick={() => removeExpense(category, index)} 
                color="error"
                sx={{ mt: 1 }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Category Total: {formatCurrency(getCategoryTotal(category))}
        </Typography>
      </Paper>
    );
  }, [localExpenses.costs, addExpense, updateExpense, removeExpense, getCategoryTotal]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Other Expenses
      </Typography>

      {(Object.keys(EXPENSE_CATEGORIES) as CostCategory[]).map(category => 
        renderExpenseCategory(category)
      )}

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Total Other Expenses
        </Typography>
        <Typography variant="h5">
          {formatCurrency(getTotalExpenses())}
        </Typography>
      </Paper>
    </Box>
  );
};

export default OtherExpensesPlanner;