// types/Personnel.ts

export enum PersonnelRole {
  SoundEngineer = 'Sound Engineer',
  LightingEngineer = 'Lighting Engineer',
  VisionMixEngineer = 'Vision Mix Engineer',
  StageManager = 'Stage Manager',
  foh = 'Front of House',
  ProductionManager = 'Production Manager',
  Volunteer = 'Volunteer',
  Supplier = 'Supplier',
  BacklineSupplier = 'Backline Supplier',
  StageHand = 'Stage Hand',
  Runner = 'Runner',
  StructuralEngineer = 'Structural Engineer',
  Staff = 'Staff',
  Other = 'Other'
}

export enum SkillLevel {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
  Expert = 'Expert'
}

export interface Personnel {
  _id: string;
  id: string;
  name: string;
  email: string;
  phone: string;
  roles: PersonnelRole[]
  skillLevel: SkillLevel;
  specialization?: string;
  availability: {
    from: Date;
    to: Date;
  };
  equipment?: string[];
  certifications?: string[];
  experience: number; // in years
  bio?: string;
  profileImage?: string;
  socialMedia?: {
    linkedin?: string;
    twitter?: string;
    instagram?: string;
  };
  rating?: number;
  performances: string[];
  reviews?: {
    reviewerId: string;
    comment: string;
    rating: number;
    date: Date;
  }[];
  fee: number;
  createdAt: Date;
  updatedAt: Date;
}

export type PersonnelFormData = Omit<Personnel, '_id' | 'createdAt' | 'updatedAt' | 'reviews'>;