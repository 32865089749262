import React, { useState, useEffect } from 'react';
import {
  Box,
  LinearProgress,
  Alert,
  Button,
  Stack
} from '@mui/material';
import {
  Save as SaveIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import TicketingLinesManager from '../components/budgets/groups/TicketingLinesManager';
import axiosInstance from '../utils/axiosConfig';
import { IMC_INCOME_CODES } from '../constants/imcCodes';

interface IncomeLine {
    id: string;
    code: string;
    quantity: number;
    value: number;
    multiplier: number;
    notes: string;
    budgetId: string; // Added to track which budget this line belongs to
  }
  
  interface Budget {
    _id: string;
    name: string;
    incomeLines: IncomeLine[];
  }
const TicketingBudgetPage = () => {
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const fetchBudgets = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.get<Budget[]>('/api/group-budgets');
      setBudgets(response.data);
    } catch (err) {
      console.error('Error fetching budgets:', err);
      setError('Failed to load budgets. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const handleUpdate = async (budgetId: string, updatedLines: IncomeLine[]) => {
    const updatedBudgets = budgets.map(budget => {
      if (budget._id !== budgetId) return budget;

      const nonTicketingLines = budget.incomeLines.filter((line: IncomeLine) => {
        const code = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code);
        return !code?.subCategories?.some(cat => cat === 'ticketing');
      });

      return {
        ...budget,
        INCOMELines: [...nonTicketingLines, ...updatedLines]
      };
    });

    setBudgets(updatedBudgets);
    setHasUnsavedChanges(true);
  };

  const handleSaveAll = async () => {
    try {
      setLoading(true);
      setError(null);

      await Promise.all(budgets.map(async (budget) => {
        const hasTicketingLines = budget.incomeLines.some((line: IncomeLine) => {
          const code = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code);
          return code?.subCategories?.some(cat => cat === 'ticketing');
        });

        if (hasTicketingLines) {
          await axiosInstance.patch(`/api/group-budgets/${budget._id}`, budget);
        }
      }));

      setHasUnsavedChanges(false);
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving budgets:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to refresh?')) {
        fetchBudgets();
      }
    } else {
      fetchBudgets();
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stack 
        direction="row" 
        spacing={2} 
        alignItems="center" 
        justifyContent="flex-end"
        sx={{ mb: 3 }}
      >
        <Button
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          disabled={loading}
        >
          Refresh
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSaveAll}
          disabled={!hasUnsavedChanges || loading}
        >
          Save All Changes
        </Button>
      </Stack>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {saveSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          All changes saved successfully
        </Alert>
      )}

      {hasUnsavedChanges && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You have unsaved changes
        </Alert>
      )}

      <TicketingLinesManager 
        budgets={budgets}
        onUpdate={handleUpdate}
      />
    </Box>
  );
};

export default TicketingBudgetPage;