import React, { useState, useEffect } from 'react';
import {
  Box, Typography, IconButton, TextField, Select, MenuItem, 
  FormControl, Button, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, InputAdornment
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IMC_INCOME_CODES } from '../../../constants/imcCodes';
import _ from 'lodash';
import { alpha } from '@mui/material/styles';

interface IncomeLine {
  id: string;
  code: string;
  quantity: number | null;
  value: number | null;
  multiplier: number | null;
  notes: string;
}

interface IncomeLinesManagerProps {
  lines: IncomeLine[];
  onUpdate: (lines: IncomeLine[]) => void;
}

const IncomeLinesManager: React.FC<IncomeLinesManagerProps> = ({ lines, onUpdate }) => {
    const [localLines, setLocalLines] = useState<IncomeLine[]>(
        lines.map(line => ({
          ...line,
          id: _.uniqueId('line_')  // Add unique ID if missing
        }))
      );
  const [total, setTotal] = useState(0);
  const [filterSubCat, setFilterSubCat] = useState<string>('');

  // Background color function with 0.20 opacity as requested
  const getBackgroundColor = (subCategories: string[]) => {
    if (subCategories.includes('grants')) {
      return 'rgba(48, 76, 107, 0.75)';    // Muted Blue
    }
    if (subCategories.includes('ticketing')) {
      return 'rgba(55, 48, 107, 0.75)';    // Muted Purple
    }
    if (subCategories.includes('services')) {
      return 'rgba(48, 107, 98, 0.75)';    // Muted Teal
    }
    if (subCategories.includes('merchandise')) {
      return 'rgba(107, 48, 76, 0.75)';    // Muted Burgundy
    }
    if (subCategories.includes('artist')) {
      return 'rgba(107, 48, 55, 0.75)';    // Muted Rose
    }
    return 'rgba(45, 45, 50, 0.75)';       // Dark neutral for default
  };

  useEffect(() => {
    const newTotal = localLines.reduce(
      (sum, line) => sum + ((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)),
      0
    );
    setTotal(newTotal);
    onUpdate(localLines);
  }, [localLines, onUpdate]);

  const addLine = () => {
    const newLine: IncomeLine = {
      id: _.uniqueId('line_'),  // Ensure this is included
      code: Object.values(IMC_INCOME_CODES)[0].code,
      quantity: 1,
      value: null,
      multiplier: 1,
      notes: ''
    };
    setLocalLines([...localLines, newLine]);
  };

  const updateLine = (
    id: string,
    field: keyof IncomeLine,
    value: string | number | null
  ) => {
    console.log('Updating line:', id, field, value);
    console.log('Current lines:', localLines);
    setLocalLines(prevLines => {
      const newLines = prevLines.map(line => {
        if (line.id === id) {
          console.log('Matching line found:', line.id);
          return { ...line, [field]: value };
        }
        return line;
      });
      console.log('New lines:', newLines);
      return newLines;
    });
  };

  const deleteLine = (id: string) => {
    setLocalLines(prevLines => prevLines.filter(line => line.id !== id));
  };

  const filteredLines = localLines.filter(line => {
    if (!filterSubCat) return true;
    const subCategories = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code)?.subCategories || [];
    return subCategories.includes(filterSubCat);
  });

  return (
    <Box sx={{ bgcolor: 'transparent' }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'common.white' }}>Income Lines</Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FormControl size="small" sx={{ width: 200 }}>
            <Select
              value={filterSubCat}
              displayEmpty
              onChange={(e) => setFilterSubCat(e.target.value as string)}
              sx={{ color: 'common.white' }}
            >
              <MenuItem value=""><em>All Categories</em></MenuItem>
              <MenuItem value="grants">Grants</MenuItem>
              <MenuItem value="ticketing">Ticketing</MenuItem>
              <MenuItem value="services">Services</MenuItem>
              <MenuItem value="merchandise">Merchandise</MenuItem>
              <MenuItem value="artist">Artist</MenuItem>
            </Select>
          </FormControl>

          <Button 
            variant="contained" 
            startIcon={<AddIcon />} 
            onClick={addLine}
          >
            Add Line
          </Button>
        </Box>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '400px', maxWidth: '400px', color: 'common.white' }}>Income Type</TableCell>
              <TableCell sx={{ width: '80px', color: 'common.white' }}>Quantity</TableCell>
              <TableCell sx={{ width: '120px', color: 'common.white' }}>Value (€)</TableCell>
              <TableCell sx={{ width: '80px', color: 'common.white' }}>Multiplier</TableCell>
              <TableCell sx={{ width: '120px', color: 'common.white' }}>Line Total</TableCell>
              <TableCell sx={{ width: '48px' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLines.map(line => {
              const codeDetails = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code);
              const rowBgColor = getBackgroundColor(Array.from(codeDetails?.subCategories || []));

              return (
                <React.Fragment key={line.id}>
                  {/* Main row with inputs */}
                  <TableRow sx={{ 
                    backgroundColor: rowBgColor,
                    '&:hover': {
                      backgroundColor: alpha(rowBgColor, 0.3)
                    }
                  }}>
                    <TableCell sx={{ 
                      width: '400px',
                      maxWidth: '400px',
                      color: 'common.white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2
                    }}>
                      {codeDetails?.icon && <codeDetails.icon sx={{ fontSize: 20, flexShrink: 0 }} />}
                      <FormControl size="small" sx={{ width: '360px', flexShrink: 0 }}>
                        <Select
                          value={line.code}
                          onChange={(e) => updateLine(line.id, 'code', e.target.value)}
                          sx={{ 
                            color: 'common.white',
                            '.MuiSelect-select': {
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2
                            }
                          }}
                        >
                                           {Object.values(IMC_INCOME_CODES).map((code) => {
                            const Icon = code.icon;
                            const bgColor = getBackgroundColor([...code.subCategories]);
                            
                            return (
                              <MenuItem
                                key={code.code}
                                value={code.code}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                  backgroundColor: bgColor,
                                  color: 'common.white',
                                  '&:hover': {
                                    backgroundColor: alpha(bgColor, 0.3)
                                  }
                                }}
                              >
                                <Icon sx={{ fontSize: 20 }} />
                                {code.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        size="small"
                        value={line.quantity ?? 0}
                        onChange={(e) => updateLine(line.id, 'quantity', parseFloat(e.target.value) || 0)}
                        sx={{ 
                          width: '70px',
                          input: { color: 'common.white' }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        size="small"
                        value={line.value ?? ''}
                        onChange={(e) => {
                          const newValue = e.target.value === '' ? null : parseFloat(e.target.value);
                          updateLine(line.id, 'value', newValue);
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        sx={{ 
                          width: '130px',
                          input: { color: 'common.white' },
                          '& .MuiInputAdornment-root': {
                            color: 'common.white'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        size="small"
                        value={line.multiplier ?? 1}
                        onChange={(e) => updateLine(line.id, 'multiplier', parseFloat(e.target.value) || 0)}
                        sx={{ 
                          width: '70px',
                          input: { color: 'common.white' }
                        }}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ color: 'common.white', fontWeight: 'bold' }}>
                      €{((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={() => deleteLine(line.id)} 
                        size="small"
                        sx={{ color: 'error.light' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  {/* Notes row */}
                  <TableRow sx={{ 
                    backgroundColor: rowBgColor,
                    '&:hover': {
                      backgroundColor: alpha(rowBgColor, 0.3)
                    }
                  }}>
                    <TableCell colSpan={6} sx={{ position: 'relative' }}>
                      <Box sx={{
                        position: 'relative',
                        width: '100%',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: '-8px',
                          left: '24px',
                          width: 0,
                          height: 0,
                          borderLeft: '6px solid transparent',
                          borderRight: '6px solid transparent',
                          borderBottom: '6px solid rgba(255, 255, 255, 0.2)'
                        }
                      }}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={1}
                          placeholder="Add notes..."
                          value={line.notes ?? ''}
                          onChange={(e) => updateLine(line.id, 'notes', e.target.value)}
                          sx={{
                            '& .MuiInputBase-input': {
                              color: 'common.white',
                              '&::placeholder': {
                                color: 'rgba(255, 255, 255, 0.5)',
                                opacity: 1
                              }
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.1)'
                              },
                              '&:hover fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.2)'
                              }
                            }
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
                        <TableRow>
              <TableCell colSpan={6} align="right">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={addLine}
                >
                  Add Line
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 3, p: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}>
        <Typography variant="h6" align="right" sx={{ color: 'common.white' }}>
          Total Income: €{total.toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default IncomeLinesManager;