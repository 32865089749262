// src/components/budgets/sections/ProductionPlanner.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Typography, Grid, FormControl, InputLabel, Select,
  MenuItem, TextField, Paper, Divider, Chip, Alert, InputAdornment
} from '@mui/material';
import { ProductionDetails, BudgetItem } from '../../../types/Budget';
import { Personnel, PersonnelRole } from '../../../types/Personnel';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import axiosInstance from '../../../utils/axiosConfig';
import { RootState } from '../../../store/store';
import { updateProductionDetails } from '../../../store/budgetSlice';

const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : Number(parsed.toFixed(2));
  }
  return isFinite(value) ? Number(value.toFixed(2)) : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const ProductionPlanner: React.FC = () => {
  const dispatch = useDispatch();
  const productionDetails = useSelector((state: RootState) => state.budget.production);
  
  const [personnel, setPersonnel] = useState<Personnel[]>([]);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState<ProductionDetails>(() => ({
    personnel: {
      soundEngineer: productionDetails?.personnel.soundEngineer || '',
      lightingTech: productionDetails?.personnel.lightingTech || '',
      stageHands: productionDetails?.personnel.stageHands || [],
      stageManagers: productionDetails?.personnel.stageManagers || [],
      frontOfHouse: productionDetails?.personnel.frontOfHouse || []
    },
    costs: {
      personnel: productionDetails?.costs.personnel || [],
      equipment: productionDetails?.costs.equipment || [],
      other: productionDetails?.costs.other || []
    }
  }));

  useEffect(() => {
    const fetchPersonnel = async () => {
      try {
        const response = await axiosInstance.get<Personnel[]>('/api/personnel');
        setPersonnel(response.data);
      } catch (err) {
        console.error('Error fetching personnel:', err);
        setError('Failed to fetch personnel data');
      }
    };
    fetchPersonnel();
  }, []);


  const debouncedDispatch = useCallback(
    debounce((updateData: ProductionDetails) => {
      dispatch(updateProductionDetails(updateData));
    }, 300),
    []
  );

  const createBudgetItem = (code: keyof typeof IMC_EXPENSE_CODES, amount: number): BudgetItem => ({
    code: IMC_EXPENSE_CODES[code].code,
    label: IMC_EXPENSE_CODES[code].label,
    amount: safeNumber(amount),
    isEstimate: true,
    status: 'draft',
    category: 'expense'
  });

  const calculatePersonnelCosts = useCallback((): BudgetItem[] => {
    const costs: BudgetItem[] = [];
  
    // Sound Engineer - immediate calculation
    if (formData.personnel.soundEngineer) {
      const engineer = personnel.find(p => p._id === formData.personnel.soundEngineer);
      if (engineer) {
        console.log('Engineer fee:', engineer.fee); // Debug log
        costs.push(createBudgetItem('PRODUCTION_PA_ENGINEERS', safeNumber(engineer.fee)));
      }
    }
    // Lighting Tech
    if (formData.personnel.lightingTech) {
      const tech = personnel.find(p => p._id === formData.personnel.lightingTech);
      if (tech) {
        costs.push(createBudgetItem('PRODUCTION_LIGHTING_ENGINEERS', tech.fee));
      }
    }

    // Stage Managers
    if (formData.personnel.stageManagers?.length) {
      const totalManagerCost = formData.personnel.stageManagers.reduce((acc, id) => {
        const manager = personnel.find(p => p._id === id);
        return acc + safeNumber(manager?.fee);
      }, 0);
      if (totalManagerCost > 0) {
        costs.push(createBudgetItem('PRODUCTION_STAGE_CREW', totalManagerCost));
      }
    }

    // Stage Hands
    if (formData.personnel.stageHands.length) {
      const totalHandsCost = formData.personnel.stageHands.reduce((acc, id) => {
        const hand = personnel.find(p => p._id === id);
        return acc + safeNumber(hand?.fee);
      }, 0);
      if (totalHandsCost > 0) {
        costs.push(createBudgetItem('PRODUCTION_STAGE_CREW', totalHandsCost));
      }
    }

    // Front of House
    if (formData.personnel.frontOfHouse.length) {
      const totalFOHCost = formData.personnel.frontOfHouse.reduce((acc, id) => {
        const foh = personnel.find(p => p._id === id);
        return acc + safeNumber(foh?.fee);
      }, 0);
      if (totalFOHCost > 0) {
        costs.push(createBudgetItem('PRODUCTION_FRONT_OF_HOUSE_CREW', totalFOHCost));
      }
    }

    return costs;
  }, [formData.personnel, personnel]);

  useEffect(() => {
    if (personnel.length > 0) {
      const costs = calculatePersonnelCosts();
      setFormData(prev => ({
        ...prev,
        costs: {
          ...prev.costs,
          personnel: costs
        }
      }));
    }
  }, [personnel, formData.personnel, calculatePersonnelCosts]);

  useEffect(() => {
    const updatedFormData = {
      ...formData,
      costs: {
        ...formData.costs,
        personnel: calculatePersonnelCosts()
      }
    };

    if (JSON.stringify(productionDetails) !== JSON.stringify(updatedFormData)) {
      debouncedDispatch(updatedFormData);
    }
  }, [formData, calculatePersonnelCosts, debouncedDispatch, productionDetails]);

  // Handler functions remain mostly the same but with safeNumber
  const handlePersonnelSelect = (role: keyof ProductionDetails['personnel'], value: string | string[]) => {
    setFormData(prev => {
      const updated = {
        ...prev,
        personnel: {
          ...prev.personnel,
          [role]: value
        }
      };
  
      // Immediately calculate new costs
      const newCosts = calculatePersonnelCosts();
      return {
        ...updated,
        costs: {
          ...updated.costs,
          personnel: newCosts
        }
      };
    });
  };

  const handleAddPersonnel = (role: 'stageManagers' | 'stageHands' | 'frontOfHouse', id: string) => {
    setFormData(prev => ({
      ...prev,
      personnel: {
        ...prev.personnel,
        [role]: [...(prev.personnel[role] || []), id]
      }
    }));
  };

  const handleRemovePersonnel = (role: 'stageManagers' | 'stageHands' | 'frontOfHouse', id: string) => {
    setFormData(prev => ({
      ...prev,
      personnel: {
        ...prev.personnel,
        [role]: prev.personnel[role]?.filter(itemId => itemId !== id) || []
      }
    }));
  };

  const getPersonnelByRole = (role: PersonnelRole) => {
    return personnel.filter(p => p.roles.includes(role));
  };

  const getTotalCost = useCallback((): number => {
    const total = formData.costs.personnel.reduce((sum, item) => {
      const amount = safeNumber(item.amount);
      console.log(`Cost item: ${item.label}, Amount: ${amount}`); // Debug log
      return sum + amount;
    }, 0);
    
    console.log('Final total:', total); // Debug log
    return total;
  }, [formData.costs.personnel]);

  
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Production Staff Planning
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Sound Engineer</InputLabel>
            <Select
              value={formData.personnel.soundEngineer || ''}
              onChange={(e) => handlePersonnelSelect('soundEngineer', e.target.value)}
              label="Sound Engineer"
            >
              {getPersonnelByRole(PersonnelRole.SoundEngineer).map((person) => (
                <MenuItem key={person._id} value={person._id}>
                  {person.name} - €{person.fee}/day
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Lighting Technician</InputLabel>
            <Select
              value={formData.personnel.lightingTech || ''}
              onChange={(e) => handlePersonnelSelect('lightingTech', e.target.value)}
              label="Lighting Technician"
            >
              {getPersonnelByRole(PersonnelRole.LightingEngineer).map((person) => (
                <MenuItem key={person._id} value={person._id}>
                  {person.name} - €{person.fee}/day
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Stage Managers
          </Typography>
          <Box sx={{ mb: 2 }}>
            {formData.personnel.stageManagers?.map((id) => {
              const person = personnel.find(p => p._id === id);
              return (
                <Chip
                  key={id}
                  label={`${person?.name} - €${person?.fee}/day`}
                  onDelete={() => handleRemovePersonnel('stageManagers', id)}
                  sx={{ m: 0.5 }}
                />
              );
            })}
          </Box>
          <FormControl fullWidth>
            <Select
              value=""
              onChange={(e) => handleAddPersonnel('stageManagers', e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Add Stage Manager</MenuItem>
              {getPersonnelByRole(PersonnelRole.StageManager).map((person) => (
                <MenuItem key={person._id} value={person._id}>
                  {person.name} - €{person.fee}/day
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Stage Hands
          </Typography>
          <Box sx={{ mb: 2 }}>
            {formData.personnel.stageHands.map((id) => {
              const person = personnel.find(p => p._id === id);
              return (
                <Chip
                  key={id}
                  label={`${person?.name} - €${person?.fee}/day`}
                  onDelete={() => handleRemovePersonnel('stageHands', id)}
                  sx={{ m: 0.5 }}
                />
              );
            })}
          </Box>
          <FormControl fullWidth>
            <Select
              value=""
              onChange={(e) => handleAddPersonnel('stageHands', e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Add Stage Hand</MenuItem>
              {getPersonnelByRole(PersonnelRole.StageHand).map((person) => (
                <MenuItem key={person._id} value={person._id}>
                  {person.name} - €{person.fee}/day
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Front of House Staff
          </Typography>
          <Box sx={{ mb: 2 }}>
            {formData.personnel.frontOfHouse.map((id) => {
              const person = personnel.find(p => p._id === id);
              return (
                <Chip
                  key={id}
                  label={`${person?.name} - €${person?.fee}/day`}
                  onDelete={() => handleRemovePersonnel('frontOfHouse', id)}
                  sx={{ m: 0.5 }}
                />
              );
            })}
          </Box>
          <FormControl fullWidth>
            <Select
              value=""
              onChange={(e) => handleAddPersonnel('frontOfHouse', e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Add Front of House Staff</MenuItem>
              {getPersonnelByRole(PersonnelRole.foh).map((person) => (
                <MenuItem key={person._id} value={person._id}>
                  {person.name} - €{person.fee}/day
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
        <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Cost Breakdown
        </Typography>
        <Grid container spacing={2}>
          {formData.costs.personnel.map((cost, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="subtitle1">
                {cost.label}
              </Typography>
              <Typography variant="h6">
                {formatCurrency(cost.amount)}
              </Typography>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h5">
  Total Production Staff Cost: {formatCurrency(getTotalCost())}
</Typography>
          </Grid>
        </Grid>
      </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductionPlanner;