import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const redirectTo = params.get('redirectTo') || '/';

        if (!token) {
          console.error('No token received');
          navigate('/');
          return;
        }

        await setToken(token); // Wait for token to be set
        navigate(decodeURIComponent(redirectTo)); // Decode the URL
      } catch (error) {
        console.error('Error during authentication:', error);
        navigate('/');
      }
    };

    handleCallback();
  }, [navigate, setToken]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <p>Authenticating...</p>
    </div>
  );
};

export default AuthCallback;