import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper
} from '@mui/material';

// Arts Council enums
const ARTS_COUNCIL_ACTIVITY_TYPES = [
    'Ticketed Performance or Event',
    'Non-Ticketed Performance or Event',
    'Book Publication',
    'Production and / or Distribution of an Artefact',
    'Broadcast / online / digital distribution of work',
    'Exhibition',
    'Participatory / education / outreach activity',
    'New work development activity',
    'Research/archiving/digitisation',
    'Artist-focused / artform development activity'
  ]

const ARTS_COUNCIL_TARGETS = [
    'General audiences',
    'Families',
    'Children (0-15)',
    'Young People (16-23)',
    'Targeted communities',
    'Artists / arts professionals'
  ]

  interface FilterProps {
    filters: {
      year: string;
      type: string;
      status: string;
      eventType: string;
      published: string;
      artsCouncilActivityType: string;
      artsCouncilActivityTarget: string;
    };
    uniqueTypes: string[];
    uniqueStatuses: string[];
    uniqueEventTypes: string[];
    onFilterChange: (field: keyof FilterProps['filters'], value: string) => void;
    onReset: () => void;
  }

const StrandFilters: React.FC<FilterProps> = ({
  filters,
  uniqueTypes,
  uniqueStatuses,
  uniqueEventTypes,
  onFilterChange,
  onReset
}) => {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const years = Array.from(
      { length: nextYear - 2019 }, 
      (_, i) => (nextYear - i).toString()
    );

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
              value={filters.year}
              onChange={(e) => onFilterChange('year', e.target.value)}
              label="Year"
            >
              <MenuItem value="">All Years</MenuItem>
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={filters.type}
              onChange={(e) => onFilterChange('type', e.target.value)}
              label="Type"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueTypes.map(type => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              onChange={(e) => onFilterChange('status', e.target.value)}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              {uniqueStatuses.map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Arts Council Activity</InputLabel>
            <Select
              value={filters.artsCouncilActivityType}
              onChange={(e) => onFilterChange('artsCouncilActivityType', e.target.value)}
              label="Arts Council Activity"
            >
              <MenuItem value="">All</MenuItem>
              {ARTS_COUNCIL_ACTIVITY_TYPES.map(type => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Target Audience</InputLabel>
            <Select
              value={filters.artsCouncilActivityTarget}
              onChange={(e) => onFilterChange('artsCouncilActivityTarget', e.target.value)}
              label="Target Audience"
            >
              <MenuItem value="">All</MenuItem>
              {ARTS_COUNCIL_TARGETS.map(target => (
                <MenuItem key={target} value={target}>{target}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Published</InputLabel>
            <Select
              value={filters.published}
              onChange={(e) => onFilterChange('published', e.target.value)}
              label="Published"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="true">Published</MenuItem>
              <MenuItem value="false">Not Published</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button 
            onClick={onReset} 
            variant="outlined"
            sx={{
              color: 'primary.main',
              borderColor: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StrandFilters;