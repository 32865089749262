import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosConfig';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  LinearProgress,
  Alert,
  Button,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import {
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
  Add as AddIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon
} from '@mui/icons-material';

// Define types
type Budget = {
  _id: string;
  name: string;
  status: string;
  totalIncome: number;
  totalExpenses: number;
  netPosition: number;
  createdAt: string;
};

const BudgetList = () => {
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBudgets = async () => {
    try {
      const response = await axiosInstance.get<Budget[]>('/api/group-budgets');
      setBudgets(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching budgets:', err);
      setError('Failed to load budgets. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this budget?')) return;
    
    try {
      await axiosInstance.delete(`/api/group-budgets/${id}`);
      setBudgets(budgets.filter((budget) => budget._id !== id));
      setError(null);
    } catch (err) {
      console.error('Error deleting budget:', err);
      setError('Failed to delete budget. Please try again later.');
    }
  };

  const handleLoad = (id: string) => {
    window.location.href = `/admin/group-budget/${id}`;  // Changed from edit/${id}
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  const getStatusChip = (status: string) => {
    const statusProps = {
      draft: { color: 'default', label: 'Draft' },
      active: { color: 'success', label: 'Active' },
      completed: { color: 'primary', label: 'Completed' },
      archived: { color: 'warning', label: 'Archived' }
    }[status.toLowerCase()] || { color: 'default', label: status };

    return (
      <Chip 
        label={statusProps.label}
        color={statusProps.color as any}
        size="small"
        sx={{ minWidth: 80 }}
      />
    );
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <LinearProgress />
      </Box>
    );
  }

  const totalNetPosition = budgets.reduce((sum, b) => sum + b.netPosition, 0);
  const averageBudgetSize = budgets.length 
    ? budgets.reduce((sum, b) => sum + b.totalExpenses, 0) / budgets.length 
    : 0;

  return (
    <Box sx={{ maxWidth: '95vw', margin: '0 auto', p: 3 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Budget Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          href="/admin/group-budget/new"
        >
          Create New Budget
        </Button>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 3 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} md={4}>
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        Total Budgets
      </Typography>
      <Typography variant="h4">
        {budgets.length}
      </Typography>
    </CardContent>
  </Card>
</Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Net Position
              </Typography>
              <Typography 
                variant="h4"
                color={totalNetPosition >= 0 ? 'success.main' : 'error.main'}
              >
                {formatCurrency(totalNetPosition)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Budget Size
              </Typography>
              <Typography variant="h4">
                {formatCurrency(averageBudgetSize)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {budgets.length === 0 ? (
        <Alert severity="info">No budgets available. Create your first budget to get started.</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Total Income</TableCell>
                <TableCell align="right">Total Expenses</TableCell>
                <TableCell align="right">Net Position</TableCell>
                <TableCell>Created</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgets.map((budget) => (
                <TableRow 
                  key={budget._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body1">{budget.name}</Typography>
                  </TableCell>
                  <TableCell>{getStatusChip(budget.status)}</TableCell>
                  <TableCell align="right">
                    <Typography color="success.main">
                      {formatCurrency(budget.totalIncome)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color="error.main">
                      {formatCurrency(budget.totalExpenses)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                      {budget.netPosition >= 0 ? (
                        <TrendingUpIcon color="success" />
                      ) : (
                        <TrendingDownIcon color="error" />
                      )}
                      <Typography
                        color={budget.netPosition >= 0 ? 'success.main' : 'error.main'}
                        fontWeight="bold"
                      >
                        {formatCurrency(budget.netPosition)}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(budget.createdAt).toLocaleDateString('en-IE', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleLoad(budget._id)}
                      color="primary"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(budget._id)}
                      color="error"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default BudgetList;