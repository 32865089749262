// src/services/groupBudgetService.ts
import axiosInstance from '../utils/axiosConfig';
import { 
  GroupBudgetData, 
  GroupBudgetResponse, 
  GroupBudgetListResponse 
} from '../types/groupBudget';

export const groupBudgetService = {
  // Get all budgets with optional filters
  async getAllBudgets(filters?: { 
    status?: string; 
    budgetType?: string; 
    strandId?: string 
  }): Promise<GroupBudgetListResponse> {
    const params = new URLSearchParams(filters);
    const response = await axiosInstance.get(`/api/group-budgets?${params}`);
    return response.data;
  },

  // Get a specific budget by ID
  async getBudgetById(id: string): Promise<{success: boolean, data?: GroupBudgetData, error?: string}> {
    try {
      const response = await axiosInstance.get(`/api/group-budgets/${id}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      console.error('Error fetching budget:', error);
      return {
        success: false,
        error: 'Failed to load budget'
      };
    }
  },

  // Get current budget for a strand
  async getCurrentBudget(strandId: string): Promise<GroupBudgetResponse> {
    const response = await axiosInstance.get(`/api/group-budgets/current?strandId=${strandId}`);
    return response.data;
  },

  // Create new budget
  async createBudget(budgetData: GroupBudgetData): Promise<GroupBudgetResponse> {
    const response = await axiosInstance.post('/api/group-budgets', budgetData);
    return response.data;
  },

  // Update existing budget
  async updateBudget(id: string, budgetData: Partial<GroupBudgetData>): Promise<{success: boolean, data?: GroupBudgetData, error?: string}> {
    console.log('Service updateBudget - Request payload:', { id, budgetData });
    try {
      const response = await axiosInstance.patch(`/api/group-budgets/${id}`, budgetData);
      console.log('Service updateBudget - Response:', response);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      console.error('Service updateBudget - Error:', error);
      return {
        success: false,
        error: 'Failed to update budget'
      };
    }
  },

  // Archive (soft delete) a budget
  async archiveBudget(id: string): Promise<GroupBudgetResponse> {
    const response = await axiosInstance.delete(`/api/group-budgets/${id}`);
    return response.data;
  },

  // Review a budget
  async reviewBudget(id: string): Promise<GroupBudgetResponse> {
    const response = await axiosInstance.post(`/api/group-budgets/${id}/review`);
    return response.data;
  },

  // Get budgets by status
  async getBudgetsByStatus(status: string): Promise<GroupBudgetListResponse> {
    const response = await axiosInstance.get(`/api/group-budgets/status/${status}`);
    return response.data;
  }
};

