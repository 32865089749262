import React, { useMemo } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box,
  useTheme
} from '@mui/material';
import { 
  TrendingUp, 
  TrendingDown, 
  Clock, 
  Calendar 
} from 'lucide-react';

interface TicketType {
  name: string;
  quantity_sold: number;
  price: number;
}

interface TitoEvent {
  registrations: {
    completed_at: string;
    tickets_count: number;
  }[];
  ticketTypes: TicketType[];
}

interface RecentSalesMetricsProps {
  events: TitoEvent[];
}

const RecentSalesMetrics: React.FC<RecentSalesMetricsProps> = ({ events }) => {
  const theme = useTheme();

  const metrics = useMemo(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const weekAgo = new Date(today);
    weekAgo.setDate(weekAgo.getDate() - 7);

    let todayCount = 0;
    let yesterdayCount = 0;
    let weekCount = 0;
    let todayRevenue = 0;
    let yesterdayRevenue = 0;
    let weekRevenue = 0;

    events.forEach(event => {
      event.registrations.forEach(reg => {
        const saleDate = new Date(reg.completed_at);
        const ticketRevenue = event.ticketTypes.reduce((sum, type) => 
          sum + (type.price * (type.quantity_sold / event.registrations.length)), 0);

        if (saleDate >= today) {
          todayCount += reg.tickets_count;
          todayRevenue += ticketRevenue;
        } else if (saleDate >= yesterday && saleDate < today) {
          yesterdayCount += reg.tickets_count;
          yesterdayRevenue += ticketRevenue;
        }
        if (saleDate >= weekAgo) {
          weekCount += reg.tickets_count;
          weekRevenue += ticketRevenue;
        }
      });
    });

    const percentChange = yesterdayCount > 0 
      ? ((todayCount - yesterdayCount) / yesterdayCount) * 100 
      : 0;

    return {
      today: { count: todayCount, revenue: todayRevenue },
      yesterday: { count: yesterdayCount, revenue: yesterdayRevenue },
      week: { count: weekCount, revenue: weekRevenue },
      percentChange
    };
  }, [events]);

  const MetricCard = ({ 
    title, 
    count, 
    revenue, 
    icon: Icon, 
    subtitle, 
    trendColor 
  }: { 
    title: string;
    count: number;
    revenue: number;
    icon: React.ElementType;
    subtitle?: string;
    trendColor?: string;
  }) => (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
        <Typography variant="h6" component="h2" sx={{ fontSize: '1rem', fontWeight: 500 }}>
          {title}
        </Typography>
        <Icon size={20} color={trendColor || theme.palette.text.secondary} />
      </Box>
      
      <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
        {count}
      </Typography>
      
      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
        €{revenue.toFixed(2)}
      </Typography>
      
      {subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </Paper>
  );

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Recent Sales Activity
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Today's Sales"
            count={metrics.today.count}
            revenue={metrics.today.revenue}
            icon={metrics.percentChange >= 0 ? TrendingUp : TrendingDown}
            trendColor={metrics.percentChange >= 0 ? theme.palette.success.main : theme.palette.error.main}
            subtitle={`${metrics.percentChange > 0 ? '+' : ''}${metrics.percentChange.toFixed(1)}% from yesterday`}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Yesterday's Sales"
            count={metrics.yesterday.count}
            revenue={metrics.yesterday.revenue}
            icon={Clock}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Past Week Sales"
            count={metrics.week.count}
            revenue={metrics.week.revenue}
            icon={Calendar}
            subtitle={`${(metrics.week.count / 7).toFixed(1)} tickets/day`}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Weekly Average"
            count={Math.round(metrics.week.count / 7)}
            revenue={metrics.week.revenue / 7}
            icon={TrendingUp}
            subtitle="Based on last 7 days"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecentSalesMetrics;