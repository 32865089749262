import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  LinearProgress,
  CircularProgress
} from '@mui/material';
import {
  Save as SaveIcon,
  Add as AddIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { debounce } from 'lodash';


// Components
import GroupBudgetNamingSummary from '../components/budgets/groups/GroupBudgetNamingSummary';
import GroupBudgetEmployment from '../components/budgets/groups/GroupBudgetEmployment';
import GroupBudgetAudiences from '../components/budgets/groups/GroupBudgetAudiences';
import GroupBudgetDatesLocations from '../components/budgets/groups/GroupBudgetDatesLocations';
import IncomeLinesManager from '../components/budgets/groups/IncomeLinesManager';
import ExpenseLinesManager from '../components/budgets/groups/ExpenseLinesManager';
import BudgetSummary from '../components/budgets/groups/BudgetSummary';
import ProductionBudgetSummary from '../components/budgets/groups/ProductionBudgetSummary';
import AARAnalysis from '../components/budgets/sections/AARAnalysis';

// Service and Types
import { groupBudgetService } from '../services/groupBudgetService';
import { GroupBudgetData, DateConfig } from '../types/groupBudget';



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Strand {
    _id: string;
    name: string;
    type?: string;
    status?: string;
    year: number;
  }

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`budget-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

// Initial state for the budget
const initialBudgetData: GroupBudgetData = {
  strandId: '',
  name: '',
  eventType: '',
  targetAudience: '',
  budgetType: '',
  vatStatus: '',
  eventCount: 0,
  status: '',
  incomeLines: [],
  expenseLines: [],
// Audience fields
  venueCapacity: 0,
  payingAttendance: 0,
  freeAttendance: 0,
  totalAttendance: 0,
  attendanceRatio: 0,
  staffCount: 0,

// Employment fields
  artistEmployment: 0,
  otherEmployment: 0,
  associatedEmployment: 0,
  totalEmployment: 0,

  // Dates and Location
  period: 'All Year Round',
  dateOption: 'Flexible',
  reviewDate: new Date(),
  location: 'Dublin',
  geoFocus: 'Dublin',
  volunteers: 0,

  dateConfig: {
    period: 'All Year Round',
    dateType: 'Flexible',
    startDate: null,
    endDate: null,
    dates: [],
    selectedMonths: [],
    selectedQuarter: '',
    biMonthlyPeriod: '',
    reviewDate: null,
  },
};

const GroupBudgetPage = () => {
  // State
  const [activeTab, setActiveTab] = useState(0);
  const [budgetData, setBudgetData] = useState<GroupBudgetData>(initialBudgetData);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState(false);
  const [pendingTabChange, setPendingTabChange] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState<'idle' | 'saving' | 'success' | 'error'>('idle');


  // Function to get the page title
  const getPageTitle = () => {
    if (!budgetData._id && !budgetData.name) {
      return 'New Group Budget';
    }
  
    if (budgetData.name) {
      return budgetData.name;  // The name will already include the year from GroupBudgetNamingSummary
    }
  
    return 'Group Budget';
  };


  // Load budget on component mount if ID is in URL
  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const budgetId = pathSegments[pathSegments.length - 1];
    
    if (budgetId && budgetId !== 'new') {
      loadBudget(budgetId);
    }
  }, []);

  // Calculate totals
  const totalIncome = budgetData?.incomeLines?.reduce((sum, line) => 
    sum + ((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)), 0
  ) || 0;
  
  const totalExpenses = budgetData?.expenseLines?.reduce((sum, line) => 
    sum + ((line.quantity || 0) * (line.value || 0) * (line.multiplier || 0)), 0
  ) || 0;

  // Load budget
  const loadBudget = async (id: string) => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await groupBudgetService.getBudgetById(id);
      
      if (response.success && response.data) {
        const budget = {
          ...response.data,
          strandId: response.data.strandId && 
            typeof response.data.strandId === 'object' &&
            'name' in response.data.strandId &&
            '_id' in response.data.strandId
              ? (response.data.strandId as Strand)._id 
              : response.data.strandId
        };
        setBudgetData(budget);
      } else {
        setError(response.error || 'Failed to load budget');
      }
    } catch (err) {
      setError('Error loading budget');
      console.error('Error loading budget:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle tab changes
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
      setPendingTabChange(newValue);
    } else {
      setActiveTab(newValue);
    }
  };

  const debouncedSave = useMemo(
    () => 
      debounce(async (data: GroupBudgetData) => {
        try {
          setAutoSaveStatus('saving');
          const response = data._id
            ? await groupBudgetService.updateBudget(data._id, data)
            : await groupBudgetService.createBudget(data);
  
          if (response.success) {
            setAutoSaveStatus('success');
            setTimeout(() => setAutoSaveStatus('idle'), 2000); // Clear success after 2s
          }
        } catch (err) {
          console.error('Error auto-saving budget:', err);
          setAutoSaveStatus('error');
          setHasUnsavedChanges(true); // Ensure manual save is available
        }
      }, 2000),
    []
  );

  const handleDataUpdate = (section: string, field: keyof GroupBudgetData, value: any) => {
    setBudgetData(prev => {
      let newData = { ...prev };
  
      switch (section) {
        case 'audiences':
          // Handle audience-specific updates
          newData = {
            ...newData,
            [field]: value,
            // Recalculate total attendance when relevant fields change
            totalAttendance: field === 'freeAttendance' ? 
              value + (prev.payingAttendance || 0) + (prev.staffCount || 0) :
              prev.totalAttendance,
            // Recalculate ratio when relevant fields change
            attendanceRatio: field === 'venueCapacity' && value > 0 ?
              ((prev.totalAttendance || 0) / value) * 100 :
              prev.attendanceRatio
          };
          break;
  
        case 'employment':
          // Handle employment-specific updates
          newData = {
            ...newData,
            [field]: value,
            // Recalculate total employment
            totalEmployment: 
              (field === 'artistEmployment' ? value : (prev.artistEmployment || 0)) +
              (field === 'otherEmployment' ? value : (prev.otherEmployment || 0)) +
              (field === 'associatedEmployment' ? value : (prev.associatedEmployment || 0))
          };
          break;
  
        case 'dates':
          // Handle dates and location updates
          newData = {
            ...newData,
            [field]: value,
            // If it's a date config update, merge it with existing config
            dateConfig: field === 'dateConfig' ? 
              { ...prev.dateConfig, ...value } : 
              prev.dateConfig
          };
          break;
  
        case 'lines':
          // Handle income/expense lines updates
          newData = {
            ...newData,
            [field]: value
          };
          break;
  
        default:
          // Handle all other updates
          newData = {
            ...newData,
            [field]: value
          };
      }
  
      // Trigger auto-save
      debouncedSave(newData);
      return newData;
    });
  };


  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
  
      // Prepare the payload without requiring incomeLines or expenseLines
      const payload = {
        ...budgetData,
        incomeLines: budgetData.incomeLines || [], // Default to empty array if not provided
        expenseLines: budgetData.expenseLines || [], // Default to empty array if not provided
      };
  
      // Save or update the budget
      const response = budgetData._id
        ? await groupBudgetService.updateBudget(budgetData._id, payload)
        : await groupBudgetService.createBudget(payload);
  
      if (response.success && response.data) {
        setBudgetData(response.data);
        setHasUnsavedChanges(false);
        setSaveSuccess(true);
  
        // Update URL if this was a new budget
        if (!budgetData._id && response.data._id) {
          window.history.replaceState(
            {}, 
            '', 
            `${window.location.pathname}?id=${response.data._id}`
          );
        }
      } else {
        setError(response.error || 'Failed to save budget');
      }
    } catch (err) {
      setError('Error saving budget');
      console.error('Error saving budget:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle create new
  const handleCreateNew = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedDialog(true);
    } else {
      setBudgetData(initialBudgetData);
      window.history.replaceState({}, '', window.location.pathname);
      setSaveSuccess(false);
    }
  };

  // Handle review
  const handleReview = async () => {
    if (!budgetData._id) return;

    try {
      setIsLoading(true);
      setError(null);
      
      const response = await groupBudgetService.reviewBudget(budgetData._id);
      
      if (response.success && response.data) {
        setBudgetData(response.data);
        setSaveSuccess(true);
      } else {
        setError(response.error || 'Failed to review budget');
      }
    } catch (err) {
      setError('Error reviewing budget');
      console.error('Error reviewing budget:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: '95%', margin: '0 auto', p: 3 }}>
      {/* Header with actions */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
        <Typography variant="h4" sx={{
          transition: 'all 0.3s ease',
          color: hasUnsavedChanges ? 'text.secondary' : 'text.primary'
        }}>
          {getPageTitle()}
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
      {/* Auto-save status indicator */}
      {autoSaveStatus !== 'idle' && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {autoSaveStatus === 'saving' && (
            <>
              <CircularProgress size={20} />
              <Typography variant="caption" color="text.secondary">
                Auto-saving...
              </Typography>
            </>
          )}
          {autoSaveStatus === 'success' && (
            <Typography variant="caption" color="success.main">
              Auto-saved
            </Typography>
          )}
          {autoSaveStatus === 'error' && (
            <Typography variant="caption" color="error.main">
              Auto-save failed
            </Typography>
          )}
        </Box>
      )}
      
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleCreateNew}
      >
        Create New
      </Button>
      
      {/* Manual save button - always available for user confidence */}
      <Button
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={handleSave}
        disabled={!hasUnsavedChanges && autoSaveStatus !== 'error'}
      >
        Save
      </Button>
    </Stack>
      </Box>

      {/* Status indicators */}
      {isLoading && <LinearProgress sx={{ mb: 2 }} />}
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {saveSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Budget saved successfully
        </Alert>
      )}

      {/* Warning for unsaved changes */}
      {hasUnsavedChanges && (
        <Paper sx={{ 
          p: 2, 
          mb: 2, 
          bgcolor: 'warning.light',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <WarningIcon color="warning" />
          <Typography>You have unsaved changes. Please save before leaving.</Typography>
        </Paper>
      )}

      {/* Main content */}
      <Paper>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="Budget Info" />
          <Tab label="AAR" />
          <Tab label="Income" />
          <Tab label="Expenses" />
          <Tab label="Employment" />
          <Tab label="Audiences" />
          <Tab label="Dates & Location" />
          <Tab label="Review" />
          <Tab label="Summary" />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <GroupBudgetNamingSummary
            {...budgetData}
            totalIncome={totalIncome}
            totalExpenditure={totalExpenses}
            onUpdate={(field, value) => handleDataUpdate('info', field as keyof GroupBudgetData, value)}
          />
        </TabPanel>
 
        <TabPanel value={activeTab} index={1}>
  <AARAnalysis 
    budget={{
      _id: budgetData._id || '',
      strandId: budgetData.strandId || '', // Added
      name: budgetData.name,
      eventType: budgetData.eventType,
      targetAudience: budgetData.targetAudience,
      budgetType: budgetData.budgetType || '', // Added
      vatStatus: budgetData.vatStatus || '', // Added
      status: budgetData.status || '', // Added
      payingAttendance: budgetData.payingAttendance || 0,
      freeAttendance: budgetData.freeAttendance || 0,
      artistEmployment: budgetData.artistEmployment || 0,
      otherEmployment: budgetData.otherEmployment || 0,
      eventCount: budgetData.eventCount || 0,
      incomeLines: budgetData.incomeLines || [],
      expenseLines: budgetData.expenseLines || [],
      totalIncome: totalIncome,
      totalExpenses: totalExpenses
    }}
  />
</TabPanel>

        <TabPanel value={activeTab} index={2}>
          <IncomeLinesManager
            lines={budgetData.incomeLines}
            onUpdate={(lines) => handleDataUpdate('lines', 'incomeLines', lines)}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={3}>
          <ExpenseLinesManager
            lines={budgetData.expenseLines}
            onUpdate={(lines) => handleDataUpdate('lines', 'expenseLines', lines)}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={4}>
        <GroupBudgetEmployment
        expenseLines={budgetData.expenseLines}
        onUpdate={(field: string, value: number) =>
          handleDataUpdate('employment', field as keyof GroupBudgetData, value)
        }
        />
        </TabPanel>

        <TabPanel value={activeTab} index={5}>
          <GroupBudgetAudiences
            incomeLines={budgetData.incomeLines}
            onUpdate={(field, value) => handleDataUpdate('audiences', field as keyof GroupBudgetData, value)}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={6}>
        <GroupBudgetDatesLocations
  location={budgetData.location}
  dateOption={budgetData.dateOption}
  geoFocus={budgetData.geoFocus}
  onUpdate={(field, value) => handleDataUpdate('dates', field as keyof GroupBudgetData, value)}
/>
        </TabPanel>

        <TabPanel value={activeTab} index={7}>
          <ProductionBudgetSummary
            {...budgetData}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={8}>
  <BudgetSummary 
    incomeLines={budgetData.incomeLines}
    expenseLines={budgetData.expenseLines}
  />
</TabPanel>
      </Paper>

      {/* Unsaved Changes Dialog */}
      <Dialog
        open={showUnsavedDialog}
        onClose={() => setShowUnsavedDialog(false)}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <Typography>
            You have unsaved changes. Would you like to save them before continuing?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setShowUnsavedDialog(false);
              if (pendingTabChange !== null) {
                setHasUnsavedChanges(false);
                setActiveTab(pendingTabChange);
                setPendingTabChange(null);
              }
            }}
            color="error"
          >
            Discard Changes
          </Button>
          <Button
            onClick={async () => {
              await handleSave();
              setShowUnsavedDialog(false);
              if (pendingTabChange !== null) {
                setActiveTab(pendingTabChange);
                setPendingTabChange(null);
              }
            }}
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default GroupBudgetPage;