import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  SelectChangeEvent,
  CircularProgress,
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import IdeaCard from '../components/ideas/IdeaCard';
import QuickIdeaButton from '../components/ideas/QuickIdeaButton';
import { Idea } from '../types/Idea';


interface Strand {
  _id: string;
  name: string;
  year: string;
}

type IdeaStatus = 'new' | 'reviewing' | 'converted' | 'archived';
type SourceType = 'email' | 'website' | 'spotify' | 'social' | 'other';


const IdeasPage = () => {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingIdea, setEditingIdea] = useState<Idea | null>(null);
  const location = useLocation();
  const [filters, setFilters] = useState({
    status: '',
    sourceType: '',
    search: '',
    strandId: ''
  });

  const fetchStrands = async () => {
    try {
      const response = await axiosInstance.get('/api/strands');
      setStrands(response.data.sort((a: Strand, b: Strand) => {
        if (b.year !== a.year) {
          return b.year.localeCompare(a.year);
        }
        return a.name.localeCompare(b.name);
      }));
    } catch (error) {
      console.error('Error fetching strands:', error);
    }
  };

  const fetchIdeas = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.sourceType) queryParams.append('sourceType', filters.sourceType);
      if (filters.search) queryParams.append('search', filters.search);
      if (filters.strandId) queryParams.append('strandId', filters.strandId);

      const response = await axiosInstance.get(`/api/ideas?${queryParams}`);
      setIdeas(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching ideas:', error);
      setError('Failed to fetch ideas');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchStrands();
    fetchIdeas();
  }, [fetchIdeas, location.key]);

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setFilters(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleStrandChange = (_: any, newValue: Strand | null) => {
    setFilters(prev => ({
      ...prev,
      strandId: newValue?._id || ''
    }));
  };

  const handleEdit = (idea: Idea) => {
    setEditingIdea(idea);
  };

  const handleDelete = async (idea: Idea) => {
    if (!window.confirm('Are you sure you want to delete this idea?')) return;

    try {
      await axiosInstance.delete(`/api/ideas/${idea._id}`);
      await fetchIdeas();
    } catch (error) {
      console.error('Error deleting idea:', error);
      setError('Failed to delete idea');
    }
  };

  const handleConvert = async (idea: Idea) => {
    try {
      await axiosInstance.post(`/api/ideas/${idea._id}/convert`);
      await fetchIdeas();
    } catch (error) {
      console.error('Error converting idea:', error);
      setError('Failed to convert idea to prospect');
    }
  };

  const handleUpdateIdea = async (updatedData: Partial<Idea>) => {
    if (!editingIdea?._id) return;

    try {
      await axiosInstance.patch(`/api/ideas/${editingIdea._id}`, updatedData);
      setEditingIdea(null);
      await fetchIdeas();
    } catch (error) {
      console.error('Error updating idea:', error);
      setError('Failed to update idea');
    }
  };

  if (loading && ideas.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Ideas & Leads
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="search"
              label="Search Ideas"
              value={filters.search}
              onChange={handleTextFieldChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={filters.status}
                onChange={handleSelectChange}
                label="Status"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="new">New</MenuItem>
                <MenuItem value="reviewing">Reviewing</MenuItem>
                <MenuItem value="converted">Converted</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Source</InputLabel>
              <Select
                name="sourceType"
                value={filters.sourceType}
                onChange={handleSelectChange}
                label="Source"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="website">Website</MenuItem>
                <MenuItem value="spotify">Spotify</MenuItem>
                <MenuItem value="social">Social Media</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              options={strands}
              groupBy={(option) => option.year}
              getOptionLabel={(option) => option.name}
              value={strands.find(s => s._id === filters.strandId) || null}
              onChange={handleStrandChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter by Strand"
                />
              )}
              renderGroup={(params) => (
                <div key={params.key}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ pl: 2, fontWeight: 'bold' }}
                  >
                    {params.group}
                  </Typography>
                  {params.children}
                </div>
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        {ideas.length === 0 ? (
          <Grid item xs={12}>
            <Alert severity="info">
              No ideas found. Use the Quick Add button to create one!
            </Alert>
          </Grid>
        ) : (
          ideas.map((idea) => (
            <Grid item xs={12} sm={6} md={4} key={idea._id}>
            <IdeaCard
              idea={idea}
              strandName={idea.associations && typeof idea.associations.strandId === 'object' ? idea.associations.strandId.name : strands.find(s => s._id === idea.associations?.strandId)?.name}
               onEdit={handleEdit}
              onDelete={handleDelete}
              onConvert={handleConvert}
            />
          </Grid>
          ))
        )}
      </Grid>

      <QuickIdeaButton />

      <Dialog
        open={!!editingIdea}
        onClose={() => setEditingIdea(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Idea</DialogTitle>
        <DialogContent>
          {editingIdea && (
            <Box sx={{ pt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    value={editingIdea.title}
                    onChange={(e) => setEditingIdea({ ...editingIdea, title: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    label="Notes"
                    value={editingIdea.notes}
                    onChange={(e) => setEditingIdea({ ...editingIdea, notes: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={editingIdea.status}
                      onChange={(e) => setEditingIdea({ 
                        ...editingIdea, 
                        status: e.target.value as IdeaStatus 
                      })}
                      label="Status"
                    >
                      <MenuItem value="new">New</MenuItem>
                      <MenuItem value="reviewing">Reviewing</MenuItem>
                      <MenuItem value="converted">Converted</MenuItem>
                      <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingIdea(null)}>Cancel</Button>
          <Button 
            onClick={() => editingIdea && handleUpdateIdea(editingIdea)} 
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IdeasPage;