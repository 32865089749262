// src/components/cooler/inventory/components/ItemDetails/MaintenanceHistory.tsx

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Chip,
  CircularProgress,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@mui/lab';
import {
  WrenchIcon,
  AlertTriangle,
  ClipboardCheck,
  User,
} from 'lucide-react';

import type { InventoryItem, MaintenanceRecord } from '../../../types/inventoryTypes';
import axiosInstance from '../../../../../utils/axiosConfig';

interface User {
  _id: string;
  name: string;
  email: string;
}

interface MaintenanceHistoryProps {
  item: InventoryItem;
}

const MaintenanceHistory: React.FC<MaintenanceHistoryProps> = ({ item }) => {
  const [users, setUsers] = useState<Record<string, User>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const userIds = new Set(item.maintenanceRecords?.map(record => record.performedBy));
    
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.post('/api/users/batch', {
          userIds: Array.from(userIds)
        });
        
        const userMap = response.data.reduce((acc: Record<string, User>, user: User) => {
          acc[user._id] = user;
          return acc;
        }, {});
        
        setUsers(userMap);
        setError(null);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setError('Failed to load user information');
      } finally {
        setLoading(false);
      }
    };

    if (userIds.size > 0) {
      fetchUsers();
    }
  }, [item.maintenanceRecords]);

  const getUserName = (userId: string) => {
    return users[userId]?.name || 'Unknown User';
  };

  const getMaintenanceIcon = (type: MaintenanceRecord['type']) => {
    switch (type) {
      case 'routine':
        return <ClipboardCheck size={20} />;
      case 'repair':
        return <WrenchIcon size={20} />;
      case 'inspection':
        return <AlertTriangle size={20} />;
      default:
        return <WrenchIcon size={20} />;
    }
  };

  const getMaintenanceColor = (type: MaintenanceRecord['type']): 'success' | 'warning' | 'info' | 'default' => {
    switch (type) {
      case 'routine':
        return 'success';
      case 'repair':
        return 'warning';
      case 'inspection':
        return 'info';
      default:
        return 'default';
    }
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (!item.maintenanceRecords?.length) {
    return (
      <Box sx={{ p: 3 }}>
        <Paper
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            bgcolor: 'background.default',
          }}
        >
          <WrenchIcon size={32} color="action" />
          <Typography color="text.secondary">
            No maintenance history available
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Paper sx={{ p: 2, mb: 2, bgcolor: 'error.light' }}>
          <Typography color="error">{error}</Typography>
        </Paper>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h6" gutterBottom>
            Maintenance History
          </Typography>

          <Timeline position="right">
            {item.maintenanceRecords
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
              .map((record, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent sx={{ flex: 0.5 }}>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(record.date)}
                    </Typography>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot sx={{ 
                      bgcolor: `${getMaintenanceColor(record.type)}.main`,
                      p: 1 
                    }}>
                      {getMaintenanceIcon(record.type)}
                    </TimelineDot>
                    {index < item.maintenanceRecords.length - 1 && <TimelineConnector />}
                  </TimelineSeparator>

                  <TimelineContent>
                    <Paper
                      sx={{
                        p: 2,
                        bgcolor: 'background.default',
                      }}
                    >
                      <Box sx={{ mb: 1 }}>
                        <Chip
                          size="small"
                          label={record.type.toUpperCase()}
                          color={getMaintenanceColor(record.type)}
                          sx={{ mb: 1 }}
                        />
                      </Box>

                      <Typography variant="body2">
                        {record.description}
                      </Typography>

                      <Box sx={{ 
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        color: 'text.secondary'
                      }}>
                        <User size={14} />
                        <Typography variant="caption">
                          {getUserName(record.performedBy)}
                        </Typography>
                      </Box>

                      {record.nextMaintenanceDate && (
                        <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                          Next maintenance due: {formatDate(record.nextMaintenanceDate)}
                        </Typography>
                      )}
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </>
      )}
    </Box>
  );
};

export default MaintenanceHistory;