import React from 'react';
import { Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Paper,
  Stack,
  Typography,
  Chip,
  IconButton,
  Collapse,
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'lucide-react';
import KanbanCard from '../core/KanbanCard';
import { Task, TaskStatus, TASK_STATUSES } from '../types/taskTypes';

interface KanbanViewProps {
  tasks: Task[];
  groupBy: 'none' | 'category' | 'assignedTo' | 'priority' | 'status';
  groupedTasks: Record<string, Task[]>;
  expandedGroups: Set<string>;
  toggleGroupExpansion: (groupKey: string) => void;
  onTaskMove: (result: DropResult) => void;
  onEditTask: (taskId: string) => void;
  onDeleteTask: (taskId: string) => void;
  onRefresh?: () => Promise<void>;
}

type Column = {
  id: TaskStatus;
  title: string;
  color: 'default' | 'info' | 'error' | 'success';
};

const COLUMNS: Column[] = [
  { id: 'todo', title: TASK_STATUSES.todo, color: 'default' },
  { id: 'in_progress', title: TASK_STATUSES.in_progress, color: 'info' },
  { id: 'blocked', title: TASK_STATUSES.blocked, color: 'error' },
  { id: 'done', title: TASK_STATUSES.done, color: 'success' },
];

export const KanbanView: React.FC<KanbanViewProps> = ({
  tasks,
  groupBy,
  groupedTasks,
  expandedGroups,
  toggleGroupExpansion,
  onTaskMove,
  onEditTask,
  onDeleteTask,
  onRefresh,
}) => {
  const getTasksByStatus = (tasks: Task[], status: TaskStatus) => 
    tasks.filter(task => task.status === status);

  if (groupBy === 'none') {
    return (
      <Stack 
        direction="row" 
        spacing={2}
        sx={{ 
          height: 'calc(100vh - 200px)',
          overflowX: 'auto',
          pb: 2
        }}
      >
        {COLUMNS.map((column) => (
          <Box
            key={column.id}
            sx={{
              width: 350,
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Column Header */}
            <Paper 
              sx={{ 
                p: 2, 
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: 'background.paper',
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle1" fontWeight="medium">
                  {column.title}
                </Typography>
                <Chip
                  size="small"
                  label={getTasksByStatus(tasks, column.id).length}
                  color={column.color}
                />
              </Stack>
            </Paper>
            {/* Column Content */}
            <Droppable droppableId={column.id}>
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{
                    flex: 1,
                    p: 1,
                    borderRadius: 1,
                    bgcolor: 'background.default',
                    minHeight: 200,
                  }}
                >
                  <Stack spacing={2}>
                    {getTasksByStatus(tasks, column.id).map((task, index) => (
                      <KanbanCard
                        key={task.id}
                        task={task}
                        index={index}
                        onEdit={() => onEditTask(task.id)}
                        onDelete={() => onDeleteTask(task.id)}
                        onRefresh={onRefresh}
                      />
                    ))}
                  </Stack>
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        ))}
      </Stack>
    );
  }

  // Grouped view
  return (
    <Stack spacing={2} sx={{ pb: 2 }}>
      {Object.entries(groupedTasks).map(([groupKey, groupTasks]) => (
        <Box
          key={groupKey}
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          {/* Group Header */}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={() => toggleGroupExpansion(groupKey)}
            sx={{
              p: 2,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <IconButton size="small" sx={{ p: 0 }}>
              {expandedGroups.has(groupKey) ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronRight size={20} />
              )}
            </IconButton>
            <Typography variant="h6">
              {groupKey}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              ({groupTasks.length})
            </Typography>
          </Stack>

          {/* Group Content */}
          <Collapse in={expandedGroups.has(groupKey)}>
            <Box sx={{ p: 2 }}>
              <Stack 
                direction="row" 
                spacing={2}
                sx={{ 
                  overflowX: 'auto',
                  pb: 2
                }}
              >
                {COLUMNS.map((column) => (
                  <Box
                    key={`${groupKey}-${column.id}`}
                    sx={{
                      width: 350,
                      flexShrink: 0,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* Column Header */}
                    <Paper 
                      sx={{ 
                        p: 2, 
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        bgcolor: 'background.default',
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="subtitle1" fontWeight="medium">
                          {column.title}
                        </Typography>
                        <Chip
                          size="small"
                          label={getTasksByStatus(groupTasks, column.id).length}
                          color={column.color}
                        />
                      </Stack>
                    </Paper>
                    {/* Column Content */}
                    <Droppable droppableId={`${groupKey}-${column.id}`}>
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            flex: 1,
                            p: 1,
                            borderRadius: 1,
                            bgcolor: 'background.default',
                            minHeight: 100,
                          }}
                        >
                          <Stack spacing={2}>
                            {getTasksByStatus(groupTasks, column.id).map((task, index) => (
                              <KanbanCard
                                key={task.id}
                                task={task}
                                index={index}
                                onEdit={() => onEditTask(task.id)}
                                onDelete={() => onDeleteTask(task.id)}
                                onRefresh={onRefresh}
                              />
                            ))}
                          </Stack>
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </Box>
      ))}
    </Stack>
  );
};

export default KanbanView;