import { useState, useEffect, useMemo } from 'react';
import { Task } from '../types/taskTypes';
import type { TaskFilters } from '../core/TaskBoardFilters';
import { Personnel } from '../../../../types/Personnel';
import axiosInstance from '../../../../utils/axiosConfig';

export const initialFilters: TaskFilters = {
  search: '',
  assignedTo: '',
  status: '',
  priority: '',
  category: '',
  estimatedHours: {
    min: '',
    max: '',
  },
  dueDate: null,
};

export const useTaskFilters = (tasks: Task[]) => {
  const [filters, setFilters] = useState<TaskFilters>(initialFilters);
  const [personnel, setPersonnel] = useState<Personnel[]>([]);
  const [isLoadingPersonnel, setIsLoadingPersonnel] = useState(false);

  useEffect(() => {
    const fetchPersonnel = async () => {
      setIsLoadingPersonnel(true);
      try {
        const response = await axiosInstance.get('/api/personnel');
        const formattedPersonnel = response.data.map((person: any) => ({
          id: person._id, 
          _id: person._id,
          name: person.name,
          email: person.email
        }));
        setPersonnel(formattedPersonnel);
      } catch (err) {
        console.error('Failed to fetch personnel:', err);
      } finally {
        setIsLoadingPersonnel(false);
      }
    };

    fetchPersonnel();
  }, []);

  const filteredTasks = useMemo(() => {
    return tasks.filter(task => {
      // Debug logging for category filtering
      if (filters.category) {
        console.log('Category Filtering Debug:', {
          taskId: task.id,
          taskCategory: task.category,
          filterCategory: filters.category,
          matches: task.category === filters.category,
          taskData: task
        });
      }

      if (filters.assignedTo) {
        const assignedTo = task.assignedTo as Personnel;
        if (!assignedTo?._id || (assignedTo._id !== filters.assignedTo)) {
          return false;
        }
      }

      if (filters.status && task.status !== filters.status) {
        return false;
      }

      if (filters.priority && task.priority !== filters.priority) {
        return false;
      }

      if (filters.category && task.category !== filters.category) {
        return false;
      }

      if (filters.estimatedHours.min !== '' && 
          (!task.estimatedHours || task.estimatedHours < filters.estimatedHours.min)) {
        return false;
      }
      if (filters.estimatedHours.max !== '' && 
          (!task.estimatedHours || task.estimatedHours > filters.estimatedHours.max)) {
        return false;
      }

      if (filters.dueDate) {
        const taskDate = new Date(task.dueDate);
        const filterDate = new Date(filters.dueDate);
        if (taskDate.toDateString() !== filterDate.toDateString()) {
          return false;
        }
      }

      return true;
    });
  }, [tasks, filters]);

  const activeFilterCount = useMemo(() => {
    let count = 0;
    if (filters.search) count++;
    if (filters.assignedTo) count++;
    if (filters.status) count++;
    if (filters.priority) count++;
    if (filters.category) count++;
    if (filters.estimatedHours.min !== '') count++;
    if (filters.estimatedHours.max !== '') count++;
    if (filters.dueDate) count++;
    return count;
  }, [filters]);

  const handleFilterChange = (newFilters: TaskFilters | Partial<TaskFilters>) => {
    console.log('Filter change called with:', newFilters);
    
    if ('category' in newFilters) {
      console.log('Category being updated to:', newFilters.category);
    }
    
    setFilters(current => ({
      ...current,
      ...newFilters
    }));
  };

  const handleClearFilters = () => {
    setFilters(initialFilters);
  };

  return {
    filters,
    filteredTasks,
    personnel,
    isLoadingPersonnel,
    activeFilterCount,
    handleFilterChange,
    handleClearFilters,
  };
};

export default useTaskFilters;