// src/utils/typeGuards.ts

import { Event, BandRecommendation } from '../types/mongodb';
import type { Strand } from '../types/Strand';


export const isEvent = (event: string | Event): event is Event => {
  return typeof event === 'object' && event !== null && 'name' in event;
};

export const isBandRecommendation = (band: string | BandRecommendation): band is BandRecommendation => {
  return typeof band === 'object' && band !== null && 'artistName' in band;
};

export const isStrand = (strand: string | Strand): strand is Strand => {
  return typeof strand === 'object' && strand !== null && '_id' in strand;
};