import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  IconButton, 
  CircularProgress, 
  Alert, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Box,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Stack,
  Divider,
  Card,
  CardContent,
  CardActions,
  Paper,
  Switch
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import FilterListIcon from '@mui/icons-material/FilterList';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import axiosInstance from '../utils/axiosConfig';
import StrandFilters from '../components/strands/StrandFilters';  // Adjust path as needed

// Interfaces
interface Strand {
  _id: string; // Ensure this is a string
  name: string;
  year?: number;  // Ensure this is a number
  type: string;
  status: string;
  eventType: string;
  startDate: string;
  numberOfProposedEvents: number;
  associations: {
    venues: string[];
    performances: string[];
    strands: string[];
    personnel: string[];
    bands: string[];
    applications: string[];
  };
  endDate: string;
  published: boolean;
  slug: string;
  artsCouncilActivityType: string;
  artsCouncilActivityTarget: string;
  archived?: boolean;  // Ensure this field exists and is optional
}

interface Filters {
  year: string;
  type: string;
  status: string;
  eventType: string;
  published: string;
  artsCouncilActivityType: string;
  artsCouncilActivityTarget: string;
}

interface Budget {
  _id: string;
  strandId: string | { $oid?: string; id?: string; _id?: string; [key: string]: any };
  name: string;
  eventType: string;
  targetAudience: string;
  // ... any other fields you need to reference
}

type SortDirection = 'asc' | 'desc';

interface SortConfig {
  field: keyof Strand | '';
  direction: SortDirection;
}

const API_BASE_URL = process.env.REACT_APP_API_URL;

const StrandListPage: React.FC = () => {
  // State Variables
  const [strands, setStrands] = useState<Strand[]>([]);
  const [filteredStrands, setFilteredStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [strandToDelete, setStrandToDelete] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ field: '', direction: 'asc' });
  const navigate = useNavigate();
  const [strandBudgets, setStrandBudgets] = useState<Record<string, Budget>>({});
  
  // State for Selected Year
  const [selectedYear, setSelectedYear] = useState<number>(2025); // Default to 2024

  // Filters State
  const [filters, setFilters] = useState<Filters>({
    year: '',
    type: '',
    status: '',
    eventType: '',
    published: '',
    artsCouncilActivityType: '',
    artsCouncilActivityTarget: ''
  });

  // Helper Function to Extract Strand ID as String
  const extractStrandId = (strandIdObj: any): string | null => {
    if (typeof strandIdObj === 'string') {
      return strandIdObj;
    } else if (typeof strandIdObj === 'object' && strandIdObj !== null) {
      // Check for common patterns where the ID might be nested
      if (strandIdObj.$oid) {
        return strandIdObj.$oid;
      } else if (strandIdObj.id) {
        return strandIdObj.id;
      } else if (strandIdObj._id) {
        return strandIdObj._id;
      } else {
        // Fallback: Attempt to stringify
        console.warn('Unexpected strandId object structure:', strandIdObj);
        return JSON.stringify(strandIdObj);
      }
    }
    return null;
  };

  // Fetch Strands
  const fetchStrands = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get<Strand[]>(`/api/strands`);
      setStrands(response.data);
      console.log('Strands successfully fetched:', response.data);
    } catch (error) {
      console.error('Error fetching strands:', error);
      setError('Failed to fetch strands. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch Budgets and Map to Strand IDs
  const fetchBudgets = async () => {
    try {
      const response = await axiosInstance.get(`/api/group-budgets`);
      const budgetsMap: Record<string, Budget> = {};

      response.data.forEach((budget: Budget) => {
        if (budget.strandId) {
          console.log('Raw strandId:', budget.strandId); // Detailed log
          const strandId = extractStrandId(budget.strandId);
          console.log('Extracted strandId:', strandId); // Confirm extraction

          if (strandId) {
            budgetsMap[strandId] = budget;
          } else {
            console.warn(`Unable to extract strandId for budget ID: ${budget._id}`);
          }
        }
      });

      setStrandBudgets(budgetsMap);
      console.log('Budgets successfully fetched and mapped:', budgetsMap);
      
      // Optional: Check if all strands have budgets
      strands.forEach(strand => {
        if (!budgetsMap[strand._id.toString()]) {
          console.warn(`No budget found for strand ID: ${strand._id}`);
        }
      });
    } catch (error) {
      console.error('Error fetching budgets:', error);
    }
  };

  // Handle Sorting
  const handleSort = (field: keyof Strand) => {
    setSortConfig(prevConfig => ({
      field,
      direction: prevConfig.field === field && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  // Handle Filter Changes
  const handleFilterChange = (field: keyof Filters, value: string) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Reset Filters
  const resetFilters = () => {
    setFilters({
      year: '',
      type: '',
      status: '',
      eventType: '',
      published: '',
      artsCouncilActivityType: '',
      artsCouncilActivityTarget: ''
    });
  };

  // Apply Filters and Sorting
  const applyFiltersAndSort = () => {
    let filtered = [...strands];
    
    // **Exclude archived strands**
    filtered = filtered.filter(strand => {
      if (strand.archived) {
        console.log(`Excluding archived strand: ${strand.name}`);
        return false;
      }
      return true;
    });

    // **Define the target year based on selectedYear state**
    const targetYear = selectedYear;
    console.log(`Filtering strands for year: ${targetYear}`);

    // **Filter by the target year only using the year field**
    filtered = filtered.filter(strand => {
      const isMatch = strand.year === targetYear;
      if (!isMatch) {
        console.log(`Excluding strand: ${strand.name} (Year: ${strand.year})`);
      }
      return isMatch;
    });

    // **Apply other existing filters**
    if (filters.type) {
      filtered = filtered.filter(strand => {
        const isMatch = strand.type === filters.type;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Type: ${strand.type})`);
        }
        return isMatch;
      });
    }
    if (filters.status) {
      filtered = filtered.filter(strand => {
        const isMatch = strand.status === filters.status;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Status: ${strand.status})`);
        }
        return isMatch;
      });
    }
    if (filters.eventType) {
      filtered = filtered.filter(strand => {
        const isMatch = strand.eventType === filters.eventType;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Event Type: ${strand.eventType})`);
        }
        return isMatch;
      });
    }
    if (filters.published) {
      filtered = filtered.filter(strand => {
        const isMatch = filters.published === 'true' ? strand.published : !strand.published;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Published: ${strand.published})`);
        }
        return isMatch;
      });
    }
    if (filters.artsCouncilActivityType) {
      filtered = filtered.filter(strand => {
        const isMatch = strand.artsCouncilActivityType === filters.artsCouncilActivityType;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Arts Council Activity Type: ${strand.artsCouncilActivityType})`);
        }
        return isMatch;
      });
    }
    if (filters.artsCouncilActivityTarget) {
      filtered = filtered.filter(strand => {
        const isMatch = strand.artsCouncilActivityTarget === filters.artsCouncilActivityTarget;
        if (!isMatch) {
          console.log(`Excluding strand: ${strand.name} (Arts Council Activity Target: ${strand.artsCouncilActivityTarget})`);
        }
        return isMatch;
      });
    }

    // **Apply sorting logic**
    if (sortConfig.field) {
      filtered.sort((a, b) => {
        const aValue = a[sortConfig.field as keyof Strand];
        const bValue = b[sortConfig.field as keyof Strand];
        if (sortConfig.field === 'year') {
          const aYear = a.year ?? 0;
          const bYear = b.year ?? 0;
          return sortConfig.direction === 'asc' ? aYear - bYear : bYear - aYear;
        }
        if (aValue && bValue && aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue && bValue && aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      console.log(`Applied sorting on field: ${sortConfig.field} in ${sortConfig.direction} order`);
    }

    setFilteredStrands(filtered);
    console.log(`Filtered strands count: ${filtered.length}`);
  };

  // Handle Delete Click
  const handleDeleteClick = (id: string) => {
    setStrandToDelete(id);
    setDeleteDialogOpen(true);
  };

  // Confirm Deletion
  const handleDeleteConfirm = async () => {
    if (strandToDelete) {
      setLoading(true);
      setError(null);
      try {
        await axiosInstance.delete(`/api/strands/${strandToDelete}`);
        fetchStrands();
      } catch (error) {
        console.error('Error deleting strand:', error);
        setError('Failed to delete strand. Please try again.');
      } finally {
        setLoading(false);
        setDeleteDialogOpen(false);
        setStrandToDelete(null);
      }
    }
  };

  // Toggle Publish Status
  const handlePublishToggle = async (strand: Strand) => {
    setLoading(true);
    setError(null);
    try {
      const updatedStrand = { ...strand, published: !strand.published };
      const response = await axiosInstance.put(`/api/strands/${strand._id}`, updatedStrand);
      setStrands(prevStrands => prevStrands.map(s => s._id === strand._id ? response.data : s));
    } catch (error) {
      console.error('Error updating strand:', error);
      setError('Failed to update strand. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle Budget Button Click
  const handleBudgetClick = (strandId: string) => {
    console.log('Clicked strand ID:', strandId);
    console.log('Available budgets:', strandBudgets);
    const budget = strandBudgets[strandId];
    console.log('Found budget:', budget);
    
    if (budget) {
      navigate(`/admin/group-budget/${budget._id}`);
    } else {
      // Add visual feedback
      setError(`No budget found for strand ${strandId}`);
    }
  };

  // Navigate to Strand Details
  const handleStrandClick = (strand: Strand) => {
    navigate(`/admin/strands/${strand._id}`);
  };

  // Navigate to Published Bands
  const handlePublishedBandsClick = (strand: Strand) => {
    navigate(`/strands/${strand.slug}`);
  };

  // Fetch Data on Component Mount
  useEffect(() => {
    const fetchData = async () => {
      await fetchStrands();
      await fetchBudgets();
    };
    fetchData();
  }, []);

  // Apply Filters and Sorting When Dependencies Change
  useEffect(() => {
    applyFiltersAndSort();
  }, [strands, filters, sortConfig, selectedYear]);

  // Handle Loading State
  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  // Unique Filter Options
  const uniqueTypes = Array.from(new Set(strands.map(strand => strand.type)));
  const uniqueStatuses = Array.from(new Set(strands.map(strand => strand.status)));
  const uniqueEventTypes = Array.from(new Set(strands.map(strand => strand.eventType)));
  const strandsWithoutYear = strands.filter(strand => strand.year === undefined || strand.year === null);

  return (
    <Container maxWidth="xl">
      {/* Header */}
      <Typography variant="h4" gutterBottom>Strands</Typography>
      
      {/* Error Handling */}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      {/* Warning for Strands Without Year */}
      {strandsWithoutYear.length > 0 && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {strandsWithoutYear.length} strand(s) found with no year assigned. Please update these strands:
          <Box component="ul" sx={{ mt: 1, mb: 0 }}>
            {strandsWithoutYear.map(strand => (
              <li key={strand._id}>{strand.name}</li>
            ))}
          </Box>
        </Alert>
      )}

      {/* Action Buttons and Year Selector */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Stack direction="row" spacing={2}>
          <Button 
            component={Link} 
            to="/strands/new" 
            variant="contained" 
            color="primary"
          >
            Create New Strand
          </Button>
          <Button 
            component={Link} 
            to="/publicstrands" 
            variant="outlined" 
          >
            View Published Strands
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          {/* Year Selector */}
          <FormControl variant="outlined" size="small">
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              onChange={(e) => setSelectedYear(Number(e.target.value))}
              label="Year"
              sx={{ minWidth: 120 }}
            >
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
            </Select>
          </FormControl>

          <Button
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(!showFilters)}
            variant="outlined"
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Stack>
      </Box>

      {/* Filters */}
      {showFilters && (
        <Paper sx={{ p: 2, mb: 3 }}>
          <StrandFilters
            filters={filters}
            uniqueTypes={uniqueTypes}
            uniqueStatuses={uniqueStatuses}
            uniqueEventTypes={uniqueEventTypes}
            onFilterChange={handleFilterChange}
            onReset={resetFilters}
          />
        </Paper>
      )}

      {/* Strand Cards */}
      {filteredStrands.length === 0 ? (
        <Typography>No strands found for the year {selectedYear}.</Typography>
      ) : (
        <Grid container spacing={3}>
          {filteredStrands.map((strand) => {
            const strandId = strand._id.toString(); // Ensure strandId is a string
            return (
              <Grid item xs={12} md={6} lg={4} key={strand._id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'column',
                    '&:hover': { boxShadow: 6 }
                  }}
                >
                  <CardContent sx={{ flex: 1 }}>
                    {/* Header with Name and Status */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Button
                        onClick={() => handleStrandClick(strand)}
                        sx={{ 
                          textAlign: 'left', 
                          textTransform: 'none',
                          p: 0,
                          '&:hover': { backgroundColor: 'transparent' }
                        }}
                      >
                        <Typography variant="h6" component="div">
                          {strand.name}
                        </Typography>
                      </Button>
                      <Stack direction="row" spacing={1}>
                        {strand.year && (
                          <Chip 
                            label={strand.year}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                        <Chip 
                          label={strand.status}
                          size="small"
                          color={strand.status === 'confirmed' ? 'success' : 'default'}
                        />
                      </Stack>
                    </Box>

                    {/* Type and Event Type */}
                    <Stack direction="row" spacing={1} mb={2}>
                      <Chip 
                        label={strand.type}
                        size="small"
                        variant="outlined"
                      />
                      <Chip 
                        label={strand.eventType}
                        size="small"
                        variant="outlined"
                      />
                    </Stack>

                    {/* Event Stats */}
                    <Box sx={{ mb: 2, p: 1, bgcolor: 'background.default', borderRadius: 1 }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <EventIcon color="action" />
                        <Typography variant="body2">
                          {strand.numberOfProposedEvents} total / {strand.associations.performances?.length || 0} confirmed / {' '}
                          {strand.numberOfProposedEvents - (strand.associations.performances?.length || 0)} remaining
                        </Typography>
                      </Stack>
                    </Box>

                    {/* Dates */}
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      {new Date(strand.startDate).toLocaleDateString()} - {new Date(strand.endDate).toLocaleDateString()}
                    </Typography>
                  </CardContent>

                  <Divider />

                  {/* Actions Section */}
                  <CardActions sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title={strandBudgets[strandId] ? "View Budget" : "No Budget Found"}>
                        <IconButton 
                          size="small"
                          onClick={() => handleBudgetClick(strandId)}
                          disabled={!strandBudgets[strandId]}
                        >
                          <AccountBalanceWalletIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Events">
                        <IconButton 
                          size="small"
                          onClick={() => navigate(`/admin/events?strand=${strand._id}`)}
                        >
                          <EventIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View Published Bands">
                        <IconButton 
                          size="small" 
                          onClick={() => handlePublishedBandsClick(strand)}
                        >
                          <GroupsIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Strand">
                        <IconButton
                          size="small"
                          component={Link}
                          to={`/strands/edit/${strand._id}`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Strand">
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteClick(strand._id)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Tooltip title={strand.published ? 'Published' : 'Not Published'}>
                        <PublicIcon 
                          color={strand.published ? 'primary' : 'action'} 
                          fontSize="small"
                        />
                      </Tooltip>
                      <Switch
                        size="small"
                        checked={strand.published}
                        onChange={() => handlePublishToggle(strand)}
                      />
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this strand? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StrandListPage;