import React, { useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Tooltip,
  IconButton,
  Alert,
  LinearProgress
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { IMC_INCOME_CODES } from '../../../constants/imcCodes';

interface GroupBudgetAudiencesProps {
  incomeLines: Array<{
    code: string;
    quantity: number;
    multiplier: number;
  }>;
  staffCount?: number;
  onUpdate?: (field: string, value: number) => void;
}

const GroupBudgetAudiences: React.FC<GroupBudgetAudiencesProps> = ({
  incomeLines,
  staffCount = 0,
  onUpdate
}) => {
  // State for manual entries
  const [venueCapacity, setVenueCapacity] = useState<number>(0);
  const [freeAttendance, setFreeAttendance] = useState<number>(0);

  // Calculate paying audience from income lines
  const payingAttendance = useMemo(() => {
    return incomeLines.reduce((total, line) => {
      const incomeCode = Object.values(IMC_INCOME_CODES).find(
        code => code.code === line.code
      );

      // Check if the income is ticket-related
      const isTicketIncome = incomeCode?.subCategories.includes('ticketing');

      if (isTicketIncome) {
        return total + (line.quantity || 0) * (line.multiplier || 1);
      }
      return total;
    }, 0);
  }, [incomeLines]);

  // Calculate total audience
  const totalAttendance = useMemo(() => {
    return payingAttendance + freeAttendance + staffCount;
  }, [payingAttendance, freeAttendance, staffCount]);

  // Calculate attendance ratio
  const attendanceRatio = useMemo(() => {
    if (!venueCapacity) return 0;
    return (totalAttendance / venueCapacity) * 100;
  }, [totalAttendance, venueCapacity]);

  // Get ratio status and message
  const getRatioStatus = (ratio: number) => {
    if (ratio === 0) return { severity: 'info', message: 'Please enter venue capacity' };
    if (ratio > 90) return { 
      severity: 'warning', 
      message: 'Projection may be optimistic. Consider if these attendance figures are realistic.' 
    };
    if (ratio < 50) return { 
      severity: 'warning', 
      message: 'Low attendance projection. This may impact financial viability.' 
    };
    if (ratio >= 70 && ratio <= 80) return { 
      severity: 'success', 
      message: 'Attendance projection is within recommended range (70-80%)' 
    };
    return { 
      severity: 'info', 
      message: 'Consider aiming for 70-80% capacity for optimal budgeting' 
    };
  };

  const ratioStatus = getRatioStatus(attendanceRatio);

  // Handle manual input changes
  const handleVenueCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
    setVenueCapacity(value);
    if (onUpdate) onUpdate('venueCapacity', value);
  };

  const handleFreeAttendanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
    setFreeAttendance(value);
    if (onUpdate) onUpdate('freeAttendance', value);
  };

  return (
    <Paper sx={{ p: 3, mb: 3 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6">Audience Projections</Typography>
        <Tooltip title="Track and analyze projected attendance figures">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Grid container spacing={3}>
        {/* Venue Capacity */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Venue Capacity"
            type="number"
            value={venueCapacity}
            onChange={handleVenueCapacityChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Total capacity of the venue">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Paying Attendance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Paying Attendance"
            value={payingAttendance}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Calculated from ticket sales in income lines">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Free Attendance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Free/Complimentary Attendance"
            type="number"
            value={freeAttendance}
            onChange={handleFreeAttendanceChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Enter expected number of free tickets and guests">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Total Attendance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Total Attendance"
            value={totalAttendance}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Total of paying, free, and staff attendance">
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            sx={{ '& .MuiInputBase-input': { fontWeight: 'bold' } }}
          />
        </Grid>

        {/* Attendance Ratio */}
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Attendance Ratio: {attendanceRatio.toFixed(1)}%
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={Math.min(attendanceRatio, 100)}
            sx={{ 
              height: 10, 
              borderRadius: 5,
              mb: 1,
              backgroundColor: 'grey.300',
              '& .MuiLinearProgress-bar': {
                backgroundColor: 
                  attendanceRatio >= 70 && attendanceRatio <= 80 
                    ? 'success.main'
                    : attendanceRatio > 90 || attendanceRatio < 50
                      ? 'warning.main'
                      : 'primary.main'
              }
            }}
          />
          <Alert 
            severity={ratioStatus.severity as 'success' | 'info' | 'warning' | 'error'}
            sx={{ mt: 1 }}
          >
            {ratioStatus.message}
          </Alert>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GroupBudgetAudiences;