// types/taskTypes.ts
import { Personnel } from '../../../../types/Personnel';
import ConstructionIcon from '@mui/icons-material/Construction';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PushPinIcon from '@mui/icons-material/PushPin';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import MicIcon from '@mui/icons-material/Mic';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MovieIcon from '@mui/icons-material/Movie';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SecurityIcon from '@mui/icons-material/Security';


export type TaskStatus = 'todo' | 'in_progress' | 'blocked' | 'done';
export type TaskPriority = 'low' | 'medium' | 'high' | 'urgent';

export interface Subtask {
  id: string;
  title: string;
  completed: boolean;
  assignedTo?: string; 
  dueDate?: string;
}

export interface TaskImage {
  id: string;
  url: string;
  uploadedAt: string;
  uploadedBy: string;
}

export interface InventoryItem {
    _id: string;
    name: string;
    category: string;
  }

export interface TaskTool {
  id: string;
  name: string;
  quantity: number;
  inventoryItem: InventoryItem; 
}

export interface TaskLog {
  id: string;
  timestamp: string;
  action: string;
  userId: string;
  details?: string;
}

export type TaskCategory =
  | 'repair'
  | 'maintenance'
  | 'setup'
  | 'improvement'
  | 'general'
  | 'bar_area'
  | 'stage'
  | 'organisation'
  | 'cooler_general'
  | 'notice_board'
  | 'outside_press'
  | 'presses_storage'
  | 'cooler_space'
  | 'audience_experience'
  | 'throughput'
  | 'lighting'
  | 'sound'
  | 'projectors'
  | 'heating'
  | 'security'
  | 'rehearsals';


  export const TASK_CATEGORIES: Record<
  TaskCategory,
  { icon: typeof ConstructionIcon; color: string }
> = {
  repair: { icon: ConstructionIcon, color: '#FF5733' },
  maintenance: { icon: BuildIcon, color: '#3498DB' },
  setup: { icon: SettingsIcon, color: '#2ECC71' },
  improvement: { icon: TrendingUpIcon, color: '#9B59B6' },
  general: { icon: PushPinIcon, color: '#34495E' },
  bar_area: { icon: SportsBarIcon, color: '#E67E22' },
  stage: { icon: TheaterComedyIcon, color: '#8E44AD' },
  organisation: { icon: AssignmentIcon, color: '#1ABC9C' },
  cooler_general: { icon: AcUnitIcon, color: '#3498DB' },
  notice_board: { icon: AnnouncementIcon, color: '#F1C40F' },
  outside_press: { icon: NewspaperIcon, color: '#E74C3C' },
  presses_storage: { icon: WarehouseIcon, color: '#7F8C8D' },
  cooler_space: { icon: ThermostatIcon, color: '#2980B9' },
  audience_experience: { icon: MicIcon, color: '#F39C12' },
  throughput: { icon: RocketLaunchIcon, color: '#27AE60' },
  lighting: { icon: LightbulbIcon, color: '#F5B041' },
  sound: { icon: VolumeUpIcon, color: '#8E44AD' },
  projectors: { icon: MovieIcon, color: '#34495E' },
  heating: { icon: WhatshotIcon, color: '#E74C3C' },
  rehearsals: { icon: MusicNoteIcon, color: '#2E4053' },
  security: { icon: SecurityIcon, color: '#34495E' }
};

export interface Task {
  id: string;
  title: string;
  description: string;
  status: TaskStatus;
  priority: TaskPriority;
  progress: number;
  tools: TaskTool[];
  subtasks: Subtask[];
  images: TaskImage[];
  dueDate: string;
  assignedTo: string | Personnel; // ✅ Allow both string (ID) and populated Personnel object
  logs: TaskLog[];
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  category?: string;
  estimatedHours?: number;
  actualHours?: number;
  linkedInventoryItems?: string[]; // References to inventory
}

export const TASK_STATUSES: Record<TaskStatus, string> = {
  todo: 'To Do',
  in_progress: 'In Progress',
  blocked: 'Blocked',
  done: 'Done'
};

export const TASK_PRIORITIES: Record<TaskPriority, string> = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  urgent: 'Urgent'
};