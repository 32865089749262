import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Stack,
  Chip,
  Box,
  Tooltip,
} from '@mui/material';
import {
  Tag as CategoryIcon,
  MapPin as LocationIcon,
  WrenchIcon,
  Power,
  Euro,
  AlertTriangle,
  Image as ImageIcon,
  Navigation as SecondaryLocationIcon,
  ChevronDown,
} from 'lucide-react';
import type { InventoryItem } from '../types/inventoryTypes';

// Category color mappings
const CATEGORY_COLORS: Record<string, { color: string; background: string }> = {
  Sound: { color: '#2563eb', background: '#dbeafe' },        // Blue
  Lighting: { color: '#7c3aed', background: '#ede9fe' },     // Purple
  Video: { color: '#be185d', background: '#fce7f3' },        // Pink
  Power: { color: '#b45309', background: '#fef3c7' },        // Amber
  Office: { color: '#047857', background: '#d1fae5' },       // Emerald
  Stage: { color: '#4f46e5', background: '#e0e7ff' },        // Indigo
  Cables: { color: '#dc2626', background: '#fee2e2' },       // Red
  Cases: { color: '#0369a1', background: '#e0f2fe' },        // Light Blue
  Microphones: { color: '#059669', background: '#ecfdf5' },  // Green
  Cameras: { color: '#9333ea', background: '#f3e8ff' },      // Violet
  Other: { color: '#374151', background: '#f4f4f5' },         // Gray
  FOH: { color: '#d97706', background: '#fffaf0' },          // Orange
};

interface ItemCardProps {
  item: InventoryItem;
  onClick: () => void;
  onMaintenanceClick: (e: React.MouseEvent) => void;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  item,
  onClick,
  onMaintenanceClick,
}) => {
  const getMaintenanceColor = (): "success" | "warning" | "error" => {
    switch (item.maintenanceStatus) {
      case 'ok':
        return 'success';
      case 'due':
        return 'warning';
      case 'overdue':
        return 'error';
      default:
        return 'success';
    }
  };

  const renderImageSection = () => {
    if (!item.images?.length) {
      return (
        <Box
          sx={{
            height: 140,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'action.hover',
          }}
        >
          <ImageIcon size={40} color="gray" />
        </Box>
      );
    }
  
    const imageUrl = item.images[0].url;
  
    return (
      <Box
        sx={{
          height: 140,
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.1)',
            opacity: 0,
            transition: 'opacity 0.2s',
          },
          '&:hover::before': {
            opacity: 1,
          },
        }}
      >
        <Box
          component="img"
          src={imageUrl}
          alt={item.name}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.style.display = 'none';
            const parent = target.parentElement;
            if (parent) {
              parent.style.backgroundColor = 'action.hover';
              parent.innerHTML = '<div style="display: flex; justify-content: center; align-items: center; height: 100%;"><svg width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="gray" stroke-width="2"><path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/><path d="M9 10h.01M15 10h.01M9.5 15.5c1.333-1 3.667-1 5 0"/></svg></div>';
            }
          }}
        />
      </Box>
    );
  };

  const renderLocationInfo = () => {
    const locations = [];

    // Primary location (always show)
    locations.push(
      <Tooltip 
        key="primary"
        title="Primary Location"
      >
        <Chip
          size="small"
          icon={<LocationIcon size={14} />}
          label={item.primaryLocation}
          sx={{ 
            fontWeight: 500,
            '& .MuiChip-icon': { 
              color: 'inherit' 
            }
          }}
        />
      </Tooltip>
    );

    // Second location (shelf/drawer)
    if (item.secondLocation) {
      locations.push(
        <Tooltip 
          key="second"
          title={`${item.primaryLocation} - ${item.secondLocation}`}
        >
          <Chip
            size="small"
            icon={<ChevronDown size={14} />}
            label={item.secondLocation}
            variant="outlined"
            sx={{ 
              '& .MuiChip-icon': { 
                color: 'inherit'
              }
            }}
          />
        </Tooltip>
      );
    }

    // Secondary location (alternative location)
    if (item.secondaryLocation && item.secondaryLocation !== 'N/A') {
      locations.push(
        <Tooltip 
          key="secondary"
          title="Alternative Location"
        >
          <Chip
            size="small"
            icon={<SecondaryLocationIcon size={14} />}
            label={item.secondaryLocation}
            variant="outlined"
            color="info"
            sx={{ 
              '& .MuiChip-icon': { 
                color: 'inherit'
              }
            }}
          />
        </Tooltip>
      );
    }

    return (
      <Stack 
        direction="row" 
        spacing={1} 
        sx={{ 
          mb: 2,
          flexWrap: 'wrap',
          gap: 0.5
        }}
      >
        <Chip
          size="small"
          icon={<CategoryIcon size={14} />}
          label={item.category}
          sx={{
            color: CATEGORY_COLORS[item.category]?.color || 'text.primary',
            bgcolor: CATEGORY_COLORS[item.category]?.background || 'default',
            fontWeight: 500,
            '& .MuiChip-icon': {
              color: 'inherit'
            }
          }}
        />
        {locations}
      </Stack>
    );
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Card 
      onClick={onClick}
      sx={{ 
        cursor: 'pointer',
        '&:hover': {
          boxShadow: 6,
          transform: 'translateY(-2px)',
        },
        transition: 'all 0.2s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {renderImageSection()}
      <CardContent>
        {/* Header with Title, Quantity, and Maintenance */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          mb: 2
        }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" noWrap sx={{ mb: 1 }}>
              {item.name}
            </Typography>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                color: 'primary.main',
                lineHeight: 1
              }}
            >
              {item.quantity}
              <Typography 
                component="span" 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary',
                  ml: 1
                }}
              >
                units
              </Typography>
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              stopPropagation(e);
              onMaintenanceClick(e);
            }}
            color={getMaintenanceColor()}
            sx={{ ml: 1 }}
          >
            <WrenchIcon size={20} />
          </IconButton>
        </Box>

        {/* Category and All Locations */}
        {renderLocationInfo()}

        {/* Status Chips */}
        <Stack direction="row" spacing={1} sx={{ mb: 1.5, flexWrap: 'wrap', gap: 0.5 }}>
          {item.requiresPower && (
            <Chip
              size="small"
              icon={<Power size={14} />}
              label="Requires Power"
              color="warning"
              sx={{ 
                '& .MuiChip-icon': { 
                  color: 'inherit'
                }
              }}
            />
          )}
          {item.isInsured && (
            <Chip
              size="small"
              icon={<Euro size={14} />}
              label="Insured"
              color="success"
              sx={{ 
                '& .MuiChip-icon': { 
                  color: 'inherit'
                }
              }}
            />
          )}
          {item.maintenanceStatus === 'overdue' && (
            <Chip
              size="small"
              icon={<AlertTriangle size={14} />}
              label="Maintenance Overdue"
              color="error"
              sx={{ 
                '& .MuiChip-icon': { 
                  color: 'inherit'
                }
              }}
            />
          )}
        </Stack>

        {/* Additional Info */}
        {item.serialNumber && (
          <Typography variant="caption" display="block" color="text.secondary">
            S/N: {item.serialNumber}
          </Typography>
        )}
        
        {item.currentValue && (
          <Typography variant="caption" display="block" color="text.secondary">
            Current Value: €{item.currentValue.toLocaleString()}
          </Typography>
        )}

        {item.lastUsedBy && (
          <Typography 
            variant="caption" 
            display="block" 
            color="text.secondary"
            sx={{ mt: 1 }}
          >
            Last used by {item.lastUsedBy.userName} on{' '}
            {new Date(item.lastUsedBy.timestamp).toLocaleDateString()}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ItemCard;