import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Autocomplete,
  Divider
} from '@mui/material';
import {
  Delete as DeleteIcon,
  SvgIconComponent
} from '@mui/icons-material';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import { alpha } from '@mui/material/styles';
import { IMCCodeMetadata } from '../../../constants/imcCodes';

interface ExpenseLine {
  id: string;
  code: string;
  quantity: number;
  value: number;
  multiplier: number;
  notes: string;
  budgetId: string; // Tracks which budget this line belongs to
}

interface Budget {
  _id: string;
  name: string;
  expenseLines: ExpenseLine[];
}

interface MarketingLinesManagerProps {
  budgets: Budget[];
  onUpdate: (budgetId: string, updatedLines: ExpenseLine[]) => void;
}

const MarketingLinesManager: React.FC<MarketingLinesManagerProps> = ({ budgets, onUpdate }) => {
  // Define table columns with fixed widths
  const columns = [
    { label: 'Marketing Item', width: 800 },
    { label: 'Quantity', width: 100 },
    { label: 'Value (€)', width: 150 },
    { label: 'Multiplier', width: 120 },
    { label: 'Line Total', width: 180 },
    { label: 'Actions', width: 80 }, // For the delete button
  ];

  // Filter for only marketing-related expense codes
  const marketingCodes = useMemo(() => 
    Object.values(IMC_EXPENSE_CODES).filter((code: IMCCodeMetadata) => 
      code.subCategories.includes('marketing')
    ),
  []);

  const getBackgroundColor = (subCategories: readonly string[]) => {
    if (subCategories.includes('marketing')) {
      return 'rgba(48, 76, 107, 0.75)'; // Light Purple tint for marketing
    }
    return 'rgba(255, 255, 255, 0.05)';
  };

  const handleUpdateLine = (budgetId: string, lineId: string, field: keyof ExpenseLine, value: string | number) => {
    const budget = budgets.find(b => b._id === budgetId);
    if (!budget) return;
  
    const updatedLines = budget.expenseLines.map(line => {
      // Add debug logging first
      console.log('Updating line:', { lineId, currentLineId: line.id });
      
      if (line.id !== lineId) return line;
  
      if (field === 'value' || field === 'quantity' || field === 'multiplier') {
        return {
          ...line,
          [field]: value === '' ? 0 : Number(value)
        };
      }
      return { ...line, [field]: value };
    });
  
    onUpdate(budgetId, updatedLines);
  };

  const handleDeleteLine = (budgetId: string, lineId: string) => {
    const budget = budgets.find(b => b._id === budgetId);
    if (!budget) return;

    const updatedLines = budget.expenseLines.filter(line => line.id !== lineId);
    onUpdate(budgetId, updatedLines);
  };

  const renderBudgetLines = (budget: Budget) => {
    const marketingLines = budget.expenseLines.filter(line => {
      const code: IMCCodeMetadata | undefined = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      return code?.subCategories.includes('marketing');
    });

    if (marketingLines.length === 0) return null;

    // Calculate total marketing spend for the budget
    const totalMarketingSpend = marketingLines.reduce((sum, line) => 
      sum + (line.quantity * line.value * line.multiplier), 
    0);

    return (
      <Box key={budget._id} sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ color: 'common.white', mb: 2 }}>
          {budget.name} -   Total = €{totalMarketingSpend.toFixed(2)}
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.label}
                    align="left"
                    sx={{ width: `${column.width}px`, padding: '8px' }}
                  >
                    <Typography variant="subtitle2">{column.label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {marketingLines.map(line => {
                const codeDetails = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
                const rowBgColor = getBackgroundColor(codeDetails?.subCategories || []);

                return (
                  <React.Fragment key={line.id}>
                    <TableRow sx={{ backgroundColor: rowBgColor }}>
                      {/* Marketing Item */}
                      <TableCell sx={{ width: `${columns[0].width}px`, padding: '8px' }}>
                        <Autocomplete<IMCCodeMetadata>
                          size="small"
                          options={marketingCodes}
                          getOptionLabel={option => option.label}
                          value={marketingCodes.find(code => code.code === line.code) || null}
                          onChange={(_, newValue) => {
                            handleUpdateLine(
                              budget._id,
                              line.id,
                              'code',
                              newValue?.code || marketingCodes[0].code
                            );
                          }}
                          renderOption={(props, option) => {
                            const Icon = option.icon as SvgIconComponent;
                            const optionBgColor = getBackgroundColor(option.subCategories);

                            return (
                              <Box
                                component="li"
                                {...props}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  backgroundColor: optionBgColor,
                                  '&:hover': {
                                    backgroundColor: alpha(optionBgColor, 0.3)
                                  },
                                  my: 0.5,
                                  borderRadius: 1
                                }}
                              >
                                <Icon sx={{ fontSize: 20, color: 'text.secondary' }} />
                                <Box>
                                  <Typography variant="body2">{option.label}</Typography>
                                  <Typography variant="caption" color="text.secondary">
                                    {option.tags.join(' • ')}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          }}
                          renderInput={params => <TextField {...params} placeholder="Select marketing item..." />}
                          isOptionEqualToValue={(option, value) => option.code === value.code}
                        />
                      </TableCell>

                      {/* Quantity */}
                      <TableCell sx={{ width: `${columns[1].width}px`, padding: '8px', textAlign: 'right' }}>
                        <TextField
                          type="number"
                          size="small"
                          value={line.quantity || ''}
                          onChange={e => handleUpdateLine(budget._id, line.id, 'quantity', e.target.value)}
                          sx={{ width: '100%' }}
                        />
                      </TableCell>

                      {/* Value (€) */}
                      <TableCell sx={{ width: `${columns[2].width}px`, padding: '8px', textAlign: 'right' }}>
                      <TextField
  type="number"
  size="small"
  value={line.value || ''}
  onChange={e => {
    // Add debug logging
    console.log('Updating value for line:', line.id);
    handleUpdateLine(budget._id, line.id, 'value', e.target.value);
  }}
  InputProps={{
    startAdornment: <InputAdornment position="start">€</InputAdornment>
  }}
  sx={{ width: '100%' }}
/>
                      </TableCell>

                      {/* Multiplier */}
                      <TableCell sx={{ width: `${columns[3].width}px`, padding: '8px', textAlign: 'right' }}>
                        <TextField
                          type="number"
                          size="small"
                          value={line.multiplier || ''}
                          onChange={e => handleUpdateLine(budget._id, line.id, 'multiplier', e.target.value)}
                          sx={{ width: '100%' }}
                        />
                      </TableCell>

                      {/* Line Total */}
                      <TableCell sx={{ width: `${columns[4].width}px`, padding: '8px', textAlign: 'right' }}>
                        <Typography fontWeight="bold" sx={{ fontSize: '1.2rem' }}>
                          €{(line.quantity * line.value * line.multiplier).toFixed(2)}
                        </Typography>
                      </TableCell>

                      {/* Actions */}
                      <TableCell sx={{ width: `${columns[5].width}px`, padding: '8px' }}>
                        <IconButton color="error" size="small" onClick={() => handleDeleteLine(budget._id, line.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    {/* Notes Row */}
                    <TableRow sx={{ backgroundColor: rowBgColor }}>
                      <TableCell
                        colSpan={6}
                        sx={{
                          position: 'relative',
                          overflow: 'visible',
                          pt: 2,
                          px: '8px',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: '16px',
                            width: 0,
                            height: 0,
                            borderLeft: '8px solid transparent',
                            borderRight: '8px solid transparent',
                            borderBottom: '8px solid #999',
                            transform: 'translateY(-50%)',
                            zIndex: 2
                          }
                        }}
                      >
                        <TextField
                          multiline
                          minRows={1}
                          fullWidth
                          placeholder="Add notes..."
                          value={line.notes ?? ''}
                          onChange={(e) => handleUpdateLine(budget._id, line.id, 'notes', e.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
                })}
              </TableBody>
            </Table>
          </TableContainer>

{/* Total Marketing Spend Summary */}
<Box
  sx={{
    mt: 2,
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
    alignItems: 'flex-end',  // Align items to the right
  }}
>
  {/* Label */}
  <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
    Total Marketing Spend for {budget.name}:
  </Typography>

  {/* Amount */}
  <Typography variant="h6" sx={{ color: 'common.white', fontWeight: 'bold' }}>
    €{totalMarketingSpend.toFixed(2)}
  </Typography>
</Box>

          <Divider sx={{ mt: 4, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
        </Box>
      );
    };

    const totalMarketingSpend = useMemo(() => {
      return budgets.reduce((total, budget) => {
        const marketingLines = budget.expenseLines.filter(line => {
          const code: IMCCodeMetadata | undefined = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
          return code?.subCategories.includes('marketing');
        });

        const budgetTotal = marketingLines.reduce((sum, line) => 
          sum + (line.quantity * line.value * line.multiplier), 
        0);

        return total + budgetTotal;
      }, 0);
    }, [budgets]);

    return (
      <Box sx={{ maxWidth: '95vw', margin: '0 auto', p: 3 }}>
        <Typography variant="h4" sx={{ mb: 4, color: 'common.white' }}>
          Marketing Budget Overview - Total: €{totalMarketingSpend.toFixed(2)}
        </Typography>

        {budgets.map(budget => renderBudgetLines(budget))}

        <Paper sx={{ mt: 3, p: 2, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
          <Typography variant="h5" align="right">
            Total Marketing Spend: €{totalMarketingSpend.toFixed(2)}
          </Typography>
        </Paper>
      </Box>
    );
  };

  export default MarketingLinesManager;