import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Stack,
  Chip,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMC_INCOME_CODES } from '../../../constants/imcCodes';
import axiosInstance from '../../../utils/axiosConfig';
import { GroupBudgetData, GroupBudgetLine } from '../../../types/groupBudget';

const AARTable = () => {
    interface IncomeLine {
    code: string;
    quantity: number;
    value: number;
    multiplier: number;
  }
  
  interface Budget extends GroupBudgetData {
    _id: string;
    name: string;
    eventType: string;
    targetAudience: string;
    totalExpenses: number;
    totalIncome: number;
    incomeLines: GroupBudgetLine[]; // Change the type to GroupBudgetLine[]
    payingAttendance: number;
    freeAttendance: number;
    artistEmployment: number;
    otherEmployment: number;
    eventCount: number;
  }
  
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchBudgets = async () => {
        try {
          const response = await axiosInstance.get('/api/group-budgets');
          setBudgets(response.data);
        } catch (err) {
          setError('Failed to fetch budget data');
          console.error('Error:', err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchBudgets();
    }, []);
  
    const calculateEarnedIncome = (incomeLines: IncomeLine[]): number => {
        return incomeLines.reduce((sum, line) => {
          const codeDetails = Object.values(IMC_INCOME_CODES).find(code => code.code === line.code);
          // Check if it's one of these specific income types
          if (codeDetails?.acCode?.includes('Earned income') || 
              codeDetails?.acCode?.includes('fees or other income from presenting partners')) {
            return sum + (line.quantity * line.value * line.multiplier);
          }
          return sum;
        }, 0);
      };
      
      // For debugging
      console.log('Income codes:', IMC_INCOME_CODES);
  
      const calculateOtherIncome = (incomeLines: IncomeLine[]): number => {
        return incomeLines.reduce((sum, line) => {
          const codeDetails = Object.values(IMC_INCOME_CODES).find(code => code.code === line.code);
          // If it's not earned income or fees from presenting partners, it's "other income"
          if (!codeDetails?.acCode?.includes('Earned income') && 
              !codeDetails?.acCode?.includes('fees or other income from presenting partners')) {
            return sum + (line.quantity * line.value * line.multiplier);
          }
          return sum;
        }, 0);
      };
  
    if (loading) return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  
    if (error) return (
      <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>
    );
  
    // Calculate totals
    const totals = budgets.reduce((acc, budget) => ({
      totalCost: acc.totalCost + budget.totalExpenses,
      earnedIncome: acc.earnedIncome + calculateEarnedIncome(budget.incomeLines),
      otherIncome: acc.otherIncome + calculateOtherIncome(budget.incomeLines),
      subsidyRequired: acc.subsidyRequired + (budget.totalExpenses - budget.totalIncome),
      payingAttendance: acc.payingAttendance + budget.payingAttendance,
      freeAttendance: acc.freeAttendance + budget.freeAttendance,
      artistsEmployed: acc.artistsEmployed + budget.artistEmployment,
      othersEmployed: acc.othersEmployed + budget.otherEmployment,
      eventCount: acc.eventCount + budget.eventCount
    }), {
      totalCost: 0,
      earnedIncome: 0,
      otherIncome: 0,
      subsidyRequired: 0,
      payingAttendance: 0,
      freeAttendance: 0,
      artistsEmployed: 0,
      othersEmployed: 0,
      eventCount: 0
    });

  const DataCell = ({ label, value, color = 'primary.dark' }: { label: string, value: string | number, color?: string }) => (
    <Box 
      sx={{ 
        p: 2, 
        backgroundColor: color, 
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography variant="caption" color="grey.300">
        {label}
      </Typography>
      <Typography variant="h6" color="white">
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: '95vw', margin: '20px auto' }}>
      {/* Summary Cards */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={3}>
          <DataCell 
            label="Total Cost" 
            value={`€${totals.totalCost.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
            color="error.dark"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataCell 
            label="Total Income" 
            value={`€${(totals.earnedIncome + totals.otherIncome).toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
            color="success.dark"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataCell 
            label="Total Subsidy Required" 
            value={`€${totals.subsidyRequired.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
            color="warning.dark"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DataCell 
            label="Total Engagement" 
            value={totals.payingAttendance + totals.freeAttendance}
            color="info.dark"
          />
        </Grid>
      </Grid>

      {/* Accordions */}
      {budgets.map((budget) => {
        const earnedIncome = calculateEarnedIncome(budget.incomeLines);
        const otherIncome = calculateOtherIncome(budget.incomeLines);
        const subsidyRequired = budget.totalExpenses - budget.totalIncome;

        return (
          <Accordion 
            key={budget._id}
            sx={{
              mb: 1,
              backgroundColor: 'grey.900',
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              sx={{
                backgroundColor: 'primary.dark',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography color="white">{budget.name}</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography color="grey.300" variant="body2">
                    Cost: €{budget.totalExpenses.toLocaleString('en-IE', { minimumFractionDigits: 2 })}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Chip 
                    label={`Subsidy: €${subsidyRequired.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
                    color={subsidyRequired > 0 ? "warning" : "success"}
                    size="small"
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            
            <AccordionDetails sx={{ backgroundColor: 'grey.800', p: 3 }}>
              <Grid container spacing={3}>
                {/* Basic Info Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="grey.300" gutterBottom>
                    Basic Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DataCell 
                        label="Activity Type" 
                        value={budget.eventType}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <DataCell 
                        label="Target Audience" 
                        value={budget.targetAudience}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2, backgroundColor: 'grey.700' }} />
                </Grid>

                {/* Financial Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="grey.300" gutterBottom>
                    Financial Metrics
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Total Cost" 
                        value={`€${budget.totalExpenses.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
                        color="error.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Earned Income" 
                        value={`€${earnedIncome.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
                        color="success.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Other Income" 
                        value={`€${otherIncome.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
                        color="success.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Subsidy Required" 
                        value={`€${subsidyRequired.toLocaleString('en-IE', { minimumFractionDigits: 2 })}`}
                        color="warning.dark"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2, backgroundColor: 'grey.700' }} />
                </Grid>

                {/* Engagement Section */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="grey.300" gutterBottom>
                    Engagement Metrics
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Paying Audience" 
                        value={budget.payingAttendance}
                        color="info.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Free Audience" 
                        value={budget.freeAttendance}
                        color="info.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Artists Employed" 
                        value={budget.artistEmployment}
                        color="secondary.dark"
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <DataCell 
                        label="Others Employed" 
                        value={budget.otherEmployment}
                        color="secondary.dark"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default AARTable;