import axios from 'axios';

const titoService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/tito`,
});

export const getTitoEvents = () => titoService.get('/events');
export const getTitoRegistrations = (eventSlug: string) => titoService.get(`/${eventSlug}/registrations`);
export const getTitoReleases = (eventSlug: string) => titoService.get(`/${eventSlug}/releases`);

// New endpoints
export const getTitoPastEvents = () => titoService.get('/past');
export const getTitoEventDetails = (eventSlug: string) => titoService.get(`/events/${eventSlug}`);
export const getTitoTickets = (eventSlug: string) => titoService.get(`/${eventSlug}/tickets`);

// Response types
export interface TitoTicketType {
  name: string;
  quantity: number;
  quantity_sold: number;
  price: number;
  revenue: number;
  start_date?: string;
  end_date?: string;
}

export interface TitoPastEvent {
  id: string;
  title: string;
  slug: string;
  start_date: string;
  end_date: string;
  totalSold: number;
  totalAvailable: number;
  totalRevenue: number;
  ticketTypes: TitoTicketType[];
  archived: boolean;
}

export interface TitoPastEventsResponse {
  past_events: TitoPastEvent[];
  meta: {
    total_count: number;
    past_count: number;
    archived_count: number;
  };
}

export default titoService;