// FINAL: A 2‑Level Hover Nav That Lets You Click Submenu Items
// Uses one Popper for the parent dropdown and simple absolute positioning
// for the submenus so there’s no gap/flicker. Paste this file verbatim.

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  MenuItem as MuiMenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { BaseNavProps } from '../../types/navigation';

// Safe icon creation
function safeIcon(icon: any) {
  if (!icon) return null;
  if (typeof icon === 'function') {
    return React.createElement(icon, { size: 16 });
  }
  return null;
}

export default function HoverNav({
  navigation,
  userEmail,
  logo,
  UserMenu,
  role,
  onLogout
}: BaseNavProps) {
  // Top-level menu open states
  const [openMenus, setOpenMenus] = useState<Record<string, boolean>>({});
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({});

  // Submenu open states (absolute-positioned)
  const [openSubs, setOpenSubs] = useState<Record<string, boolean>>({});

  // Convert icons for main / dropdown
  const mainItems = navigation.mainItems.map(item => ({
    ...item,
    icon: safeIcon(item.icon),
    children: item.children?.map(child => ({ ...child, icon: safeIcon(child.icon) }))
  }));
  let dropdowns: Record<string, any[]> | undefined;
  if (navigation.dropdowns) {
    dropdowns = {};
    for (const [section, items] of Object.entries(navigation.dropdowns)) {
      dropdowns[section] = items.map(item => ({
        ...item,
        icon: safeIcon(item.icon),
        children: item.children?.map(child => ({
          ...child,
          icon: safeIcon(child.icon)
        }))
      }));
    }
  }

  // ---------- Parent Hover Handlers ----------
  const handleParentEnter = (section: string, e: React.MouseEvent<HTMLElement>) => {
    setAnchorEls(prev => ({ ...prev, [section]: e.currentTarget }));
    setOpenMenus(prev => ({ ...prev, [section]: true }));
  };
  const handleParentLeave = (section: string) => {
    setOpenMenus(prev => ({ ...prev, [section]: false }));
    setOpenSubs({});
  };

  // ---------- Submenu Hover Handlers ----------
  const handleSubEnter = (subKey: string) => {
    setOpenSubs(prev => ({ ...prev, [subKey]: true }));
  };
  const handleSubLeave = (subKey: string) => {
    setOpenSubs(prev => ({ ...prev, [subKey]: false }));
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between', minHeight: 64, px: 2 }}>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{logo}</Box>

        {/* Nav Items */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Main (no dropdown) */}
          {mainItems.map(item => (
            <Button
              key={item.path || item.label}
              component={RouterLink}
              to={item.path || ''}
              color="inherit"
              startIcon={item.icon}
              sx={{ textTransform: 'none' }}
            >
              {item.label}
            </Button>
          ))}

          {/* Dropdowns */}
          {dropdowns &&
            Object.entries(dropdowns).map(([section, items]) => (
              <Box
                key={section}
                onMouseEnter={e => handleParentEnter(section, e)}
                onMouseLeave={() => handleParentLeave(section)}
              >
                <Button
                  color="inherit"
                  endIcon={<ChevronDown size={16} />}
                  sx={{ textTransform: 'none' }}
                >
                  {section}
                </Button>

                {/* PARENT DROPDOWN */}
                <Popper
                  open={!!openMenus[section]}
                  anchorEl={anchorEls[section]}
                  transition
                  placement="bottom-start"
                  sx={{ zIndex: 1300 }}
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper elevation={3}>
                        <ClickAwayListener onClickAway={() => handleParentLeave(section)}>
                          <MenuList>
                            {items.map(item => {
                              const subKey = `${section}-${item.label}`;
                              if (item.children) {
                                return (
                                  <Box key={subKey} sx={{ position: 'relative' }}>
                                    {/* Parent Menu Item */}
                                    <MuiMenuItem
                                      onMouseEnter={() => handleSubEnter(subKey)}
                                      onMouseLeave={() => handleSubLeave(subKey)}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                        }}
                                      >
                                        {item.icon}
                                        {item.label}
                                        <ChevronRight size={16} />
                                      </Box>
                                    </MuiMenuItem>

                                    {/* ABSOLUTE SUBMENU */}
                                    {openSubs[subKey] && (
                                      <Paper
                                        sx={{
                                          position: 'absolute',
                                          top: 0,
                                          left: '100%',
                                          minWidth: 180,
                                          zIndex: 1301
                                        }}
                                        onMouseEnter={() => handleSubEnter(subKey)}
                                        onMouseLeave={() => handleSubLeave(subKey)}
                                      >
                                        <MenuList>
                                          {item.children.map((child: any) => (
                                            <MuiMenuItem
                                              key={child.path || child.label}
                                              component={RouterLink}
                                              to={child.path || ''}
                                              onClick={() => {
                                                handleSubLeave(subKey);
                                                handleParentLeave(section);
                                              }}
                                            >
                                              {child.icon}
                                              {child.label}
                                            </MuiMenuItem>
                                          ))}
                                        </MenuList>
                                      </Paper>
                                    )}
                                  </Box>
                                );
                              }
                              // A simple item
                              return (
                                <MuiMenuItem
                                  key={item.path || item.label}
                                  component={RouterLink}
                                  to={item.path || ''}
                                  onClick={() => handleParentLeave(section)}
                                >
                                  {item.icon}
                                  {item.label}
                                </MuiMenuItem>
                              );
                            })}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            ))}

          {/* User menu */}
          {userEmail && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UserMenu userEmail={userEmail} role={role} onLogout={onLogout} />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}