import React, { useState, useEffect, useRef, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { EventInput, DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import moment from 'moment';
import { parseISO, format, isValid } from 'date-fns';
import { StrandDate, Prospect, ProspectFormData, SnackbarState } from './types';

// Interfaces
interface StrandInfo {
  _id: string;
  name: string;
  color: string;
}

interface ProspectWithStrand extends Prospect {
  strandInfo: StrandInfo;
}

interface StrandDateManagerProps {
  strandId: string;
  strandStartDate: string;
  strandEndDate: string;
  initialDates: StrandDate[];
  onDatesChange: (newDates: StrandDate[]) => void;
  onProspectsChange?: () => void;
}

const StrandDateManager: React.FC<StrandDateManagerProps> = ({
  strandId,
  strandStartDate,
  strandEndDate,
  onDatesChange,
  initialDates,
  onProspectsChange
}) => {
  // State declarations
  const [dates, setDates] = useState<StrandDate[]>([]);
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [otherStrandDates, setOtherStrandDates] = useState<StrandDate[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isProspectDialogOpen, setIsProspectDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<StrandDate['status']>('tentative');
  const [currentProspect, setCurrentProspect] = useState<ProspectFormData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'success'
  });

  const calendarRef = useRef<FullCalendar>(null);

  const emptyProspect: ProspectFormData = {
    date: null,
    eventConcept: '',
    potentialArtists: [],
    venueIdeas: [],
    fees: 0,
    boxOffice: 0,
    expenses: 0,
    sponsorship: 0,
    net: 0,
    notes: '',
    status: 'idea'
  };

  // Effects
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        // Parse initialDates string to array of StrandDate objects
        const parsedDates = typeof initialDates === 'string' ? JSON.parse(initialDates) : initialDates;
        setDates(parsedDates || []);
        await Promise.all([
          fetchAllProspects(),
          fetchOtherStrandDates()
        ]);
      } catch (error) {
        console.error('Error initializing:', error);
        showSnackbar('Failed to initialize calendar', 'error');
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, [strandId, initialDates]);

  useEffect(() => {
    if (initialDates) {
      setDates(initialDates);
    }
  }, [initialDates]);


  // Helper Functions
  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  function getEventColor(status: StrandDate['status']) {
    switch (status) {
      case 'confirmed':
        return '#4caf50';
      case 'tentative':
        return '#ff9800';
      case 'cancelled':
        return '#f44336';
      default:
        return '#9e9e9e';
    }
  }

  function getProspectColor(status: Prospect['status']) {
    switch (status) {
      case 'confirmed':
        return '#4caf50';
      case 'negotiation':
        return '#ff9800';
      case 'prospective':
        return '#2196f3';
      case 'idea':
        return '#9e9e9e';
      default:
        return '#9e9e9e';
    }
  }

  const getStatusOpacity = (status: Prospect['status']): number => {
    switch (status) {
      case 'confirmed':
        return 1;
      case 'negotiation':
        return 0.8;
      case 'prospective':
        return 0.6;
      case 'idea':
        return 0.4;
      default:
        return 1;
    }
  };

  // API Functions
  const fetchAllProspects = async () => {
    try {
      const strandsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/strands`
      );
      
      const strands: StrandInfo[] = strandsResponse.data.map((strand: any, index: number) => ({
        _id: strand._id,
        name: strand.name,
        color: `hsl(${index * (360 / strandsResponse.data.length)}, 70%, 50%)`
      }));

      const prospectsPromises = strands.map(strand =>
        axios.get(`${process.env.REACT_APP_API_URL}/api/strands/${strand._id}/prospects`)
      );
      
      const prospectsResponses = await Promise.all(prospectsPromises);
      
      const allProspects = prospectsResponses.flatMap((response, index) =>
        response.data.map((prospect: any) => ({
          ...prospect,
          date: prospect.date ? parseISO(prospect.date) : null,
          strandInfo: strands[index]
        }))
      );

      setProspects(allProspects);
    } catch (error) {
      console.error('Error fetching prospects:', error);
      showSnackbar('Failed to fetch prospects', 'error');
      throw error;
    }
  };

  const fetchOtherStrandDates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/strands/dates?excludeStrand=${strandId}`
      );
      setOtherStrandDates(response.data);
    } catch (error) {
      console.error('Error fetching other strand dates:', error);
      throw error;
    }
  };
  // Event Handlers
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setSelectedDate(moment(selectInfo.start).format('YYYY-MM-DD'));
    setIsAddDialogOpen(true);
  };

  const handleAddDate = async () => {
    if (!selectedDate) return;
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/dates`,
        {
          date: selectedDate,
          status: selectedStatus,
        }
      );
      const newDates = [...dates, response.data];
      setDates(newDates);
      onDatesChange(newDates); // Pass array directly
      setIsAddDialogOpen(false);
      showSnackbar('Date added successfully', 'success');
    } catch (error) {
      console.error('Error adding date:', error);
      showSnackbar('Failed to add date', 'error');
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const eventType = clickInfo.event.extendedProps?.type;
    
    if (eventType === 'prospect') {
      const prospect = clickInfo.event.extendedProps?.prospect;
      handleOpenProspectDialog(prospect);
    } else {
      const dateId = clickInfo.event.id;
      if (window.confirm(`Are you sure you want to delete this event?`)) {
        handleDeleteDate(dateId);
      }
    }
  };

  const handleDeleteDate = async (dateId: string) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/dates/${dateId}`
      );
      const updatedDates = dates.filter(date => date.id !== dateId);
      setDates(updatedDates);
      onDatesChange(updatedDates); // Pass array directly
      showSnackbar('Date deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting date:', error);
      showSnackbar('Failed to delete date', 'error');
    }
  };

  const handleOpenProspectDialog = (prospect: Prospect | null = null) => {
    setCurrentProspect(prospect ? {
      ...prospect,
      date: prospect.date ? new Date(prospect.date) : null
    } : emptyProspect);
    setIsProspectDialogOpen(true);
  };

  const handleSaveProspect = async () => {
    if (!currentProspect) return;

    try {
      const prospectToSave = {
        ...currentProspect,
        date: currentProspect.date ? 
          format(currentProspect.date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null
      };

      const url = currentProspect._id
        ? `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects/${currentProspect._id}`
        : `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects`;

      const response = await axios({
        method: currentProspect._id ? 'PUT' : 'POST',
        url,
        data: prospectToSave
      });

      await fetchAllProspects(); // Refresh all prospects to maintain consistency
      showSnackbar('Prospect saved successfully', 'success');
      setIsProspectDialogOpen(false);
      if (onProspectsChange) onProspectsChange();
    } catch (error) {
      console.error('Error saving prospect:', error);
      showSnackbar('Failed to save prospect', 'error');
    }
  };

  const handleDeleteProspect = async (id: string) => {
    if (!window.confirm('Are you sure you want to delete this prospect?')) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects/${id}`
      );
      await fetchAllProspects(); // Refresh all prospects
      showSnackbar('Prospect deleted successfully', 'success');
      if (onProspectsChange) onProspectsChange();
    } catch (error) {
      console.error('Error deleting prospect:', error);
      showSnackbar('Failed to delete prospect', 'error');
    }
  };

  // Calendar Events
  const calendarEvents: EventInput[] = useMemo(() => {
    if (!dates || !prospects || !otherStrandDates) return [];

    return [
      // Strand Dates
      ...dates.map(date => ({
        id: date.id,
        title: `Event (${date.status})`,
        start: date.date,
        allDay: true,
        backgroundColor: getEventColor(date.status),
        extendedProps: { type: 'strand', status: date.status },
      })),
      // All Strands' Prospects
      ...prospects
        .filter(prospect => prospect?.date !== null)
        .map(prospect => {
          const isCurrentStrand = prospect.strandInfo?._id === strandId;
          const baseColor = prospect.strandInfo?.color || '#9e9e9e';
          const statusOpacity = getStatusOpacity(prospect.status);
          
          return {
            id: `prospect-${prospect._id}`,
            title: `[${prospect.strandInfo?.name || 'Unknown'}] ${prospect.eventConcept} (${prospect.status})`,
            start: prospect.date instanceof Date 
              ? format(prospect.date, "yyyy-MM-dd")
              : format(parseISO(prospect.date as unknown as string), "yyyy-MM-dd"),
            allDay: true,
            backgroundColor: baseColor,
            opacity: statusOpacity,
            borderColor: isCurrentStrand ? '#000' : 'transparent',
            borderWidth: isCurrentStrand ? 2 : 0,
            classNames: isCurrentStrand ? 'current-strand-prospect' : 'other-strand-prospect',
            extendedProps: { 
              type: 'prospect',
              prospect,
              strandId: prospect.strandInfo?._id
            },
          };
        }),
      // Other Strand Dates
      ...otherStrandDates.map(date => ({
        id: date.id,
        title: 'Other Strand Event',
        start: date.date,
        allDay: true,
        backgroundColor: '#9e9e9e',
        extendedProps: { type: 'otherStrand' },
      })),
    ];
  }, [dates, prospects, otherStrandDates, strandId]);

  // Render
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Legend */}
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
  <Typography variant="h6" gutterBottom>Strands</Typography>
  <Grid container spacing={1}>
    {Array.from(new Set(prospects.map(p => p.strandInfo)))
      .filter((strandInfo): strandInfo is StrandInfo => strandInfo !== undefined)
      .map(strandInfo => (
        <Grid item key={strandInfo._id}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: strandInfo.color,
                borderRadius: '50%',
                border: strandInfo._id === strandId ? '2px solid black' : 'none'
              }}
            />
            <Typography variant="body2">
              {strandInfo.name}
            </Typography>
          </Box>
        </Grid>
    ))}
  </Grid>
</Paper>

            {/* Calendar */}
            <Paper elevation={3} style={{ padding: '20px' }}>
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                events={calendarEvents}
                select={handleDateSelect}
                eventClick={handleEventClick}
                validRange={{
                  start: strandStartDate,
                  end: strandEndDate,
                }}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek'
                }}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* Add Date Dialog */}
        <Dialog 
          open={isAddDialogOpen} 
          onClose={() => setIsAddDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Add Event Date</DialogTitle>
          <DialogContent>
            <TextField
              label="Date"
              type="date"
              value={selectedDate || ''}
              onChange={(e) => setSelectedDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value as StrandDate['status'])}
              >
                <MenuItem value="tentative">Tentative</MenuItem>
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleAddDate} color="primary">Add</Button>
          </DialogActions>
        </Dialog>

        {/* Prospect Dialog */}
        <Dialog 
          open={isProspectDialogOpen} 
          onClose={() => setIsProspectDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {currentProspect?._id ? 'Edit Prospect' : 'Add Prospect'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <DatePicker
                  label="Date"
                  value={currentProspect?.date}
                  onChange={(newValue) => {
                    if (currentProspect) {
                      setCurrentProspect({
                        ...currentProspect,
                        date: newValue
                      });
                    }
                  }}
                  sx={{ width: '100%' }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Event Concept"
                  fullWidth
                  value={currentProspect?.eventConcept ?? ''}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    eventConcept: e.target.value
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Fees"
                  type="number"
                  fullWidth
                  value={currentProspect?.fees ?? 0}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    fees: Number(e.target.value)
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Box Office"
                  type="number"
                  fullWidth
                  value={currentProspect?.boxOffice ?? 0}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    boxOffice: Number(e.target.value)
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Expenses"
                  type="number"
                  fullWidth
                  value={currentProspect?.expenses ?? 0}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    expenses: Number(e.target.value)
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Sponsorship"
                  type="number"
                  fullWidth
                  value={currentProspect?.sponsorship ?? 0}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    sponsorship: Number(e.target.value)
                  })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  label="Status"
                  fullWidth
                  value={currentProspect?.status ?? 'idea'}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    status: e.target.value as Prospect['status']
                  })}
                >
                  <MenuItem value="idea">Loose Idea</MenuItem>
                  <MenuItem value="prospective">Prospective</MenuItem>
                  <MenuItem value="negotiation">In Negotiation</MenuItem>
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  multiline
                  rows={4}
                  fullWidth
                  value={currentProspect?.notes ?? ''}
                  onChange={(e) => currentProspect && setCurrentProspect({
                    ...currentProspect,
                    notes: e.target.value
                  })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsProspectDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveProspect} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default StrandDateManager;