// src/components/cooler/inventory/components/ItemDetails/EditForm.tsx

import React from 'react';
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@mui/material';
import {
  Tag as CategoryIcon,
  MapPin as LocationIcon,
} from 'lucide-react';

import { 
    CATEGORIES, 
    LOCATIONS, 
    SECOND_LOCATIONS, // Add this
    VENDORS,
    shouldShowSerialNumber,
    type SecondLocation, // Add this
  } from '../../../constants/inventoryConstants';
import { hasSecondLocation } from '../../../utils/locationHelpers'; // Add this
import type { InventoryItem } from '../../../types/inventoryTypes';

interface EditFormProps {
  item: InventoryItem;
  onChange: (item: InventoryItem) => void;
}

const EditForm: React.FC<EditFormProps> = ({ item, onChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label="Name"
            value={item.name}
            onChange={(e) => onChange({ ...item, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Category</InputLabel>
            <Select
              value={item.category}
              label="Category"
              onChange={(e) => onChange({ ...item, category: e.target.value as typeof CATEGORIES[number] })}
              startAdornment={
                <InputAdornment position="start">
                  <CategoryIcon size={20} />
                </InputAdornment>
              }
            >
              {CATEGORIES.map((cat) => (
                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Description"
            value={item.description}
            onChange={(e) => onChange({ ...item, description: e.target.value })}
          />
        </Grid>

{/* Location Fields */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth required>
    <InputLabel>Primary Location</InputLabel>
    <Select
      value={item.primaryLocation}
      label="Primary Location"
      onChange={(e) => onChange({ ...item, primaryLocation: e.target.value as typeof LOCATIONS[number] })}
      startAdornment={
        <InputAdornment position="start">
          <LocationIcon size={20} />
        </InputAdornment>
      }
    >
      {LOCATIONS.map((loc) => (
        <MenuItem key={loc} value={loc}>{loc}</MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

{/* Second Location (Shelf/Drawer) - Only show if primary location supports it */}
{hasSecondLocation(item.primaryLocation) && (
  <Grid item xs={12} md={6}>
    <FormControl fullWidth>
      <InputLabel>Shelf/Drawer</InputLabel>
      <Select
        value={item.secondLocation || ''}
        label="Shelf/Drawer"
        onChange={(e) => onChange({
          ...item,
          secondLocation: e.target.value as SecondLocation
        })}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {SECOND_LOCATIONS.map((loc: SecondLocation) => (
          <MenuItem key={loc} value={loc}>{loc}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>
)}

{/* Secondary Location (Alternative Location) */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel>Alternative Location</InputLabel>
    <Select
      value={item.secondaryLocation || ''}
      label="Alternative Location"
      onChange={(e) => onChange({ ...item, secondaryLocation: e.target.value as typeof LOCATIONS[number] | undefined })}
    >
      <MenuItem value="N/A">N/A</MenuItem>
      {LOCATIONS.map((loc) => (
        <MenuItem 
          key={loc} 
          value={loc}
          disabled={loc === item.primaryLocation}
        >
          {loc}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

        {/* Purchase Info */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="date"
            label="Purchase Date"
            value={item.purchaseDate}
            onChange={(e) => onChange({ ...item, purchaseDate: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            freeSolo
            options={VENDORS}
            value={item.vendor || ''}
            onChange={(_, newValue) => onChange({ 
              ...item, 
              vendor: newValue as typeof VENDORS[number] | undefined 
            })}
            renderInput={(params) => <TextField {...params} label="Vendor" />}
          />
        </Grid>

        {/* Value and Insurance */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Purchase Value"
            value={item.purchaseValue}
            onChange={(e) => onChange({ 
              ...item, 
              purchaseValue: parseFloat(e.target.value),
              isInsured: parseFloat(e.target.value) >= 500
            })}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Current Value"
            value={item.currentValue}
            onChange={(e) => onChange({ ...item, currentValue: parseFloat(e.target.value) })}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {(item.purchaseValue || 0) >= 500 && (
            <FormControlLabel
              control={
                <Switch
                  checked={item.isInsured}
                  onChange={(e) => onChange({ ...item, isInsured: e.target.checked })}
                />
              }
              label="Insured"
            />
          )}
        </Grid>

        {/* Serial Number */}
        {shouldShowSerialNumber(item.category) && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Serial Number"
              value={item.serialNumber}
              onChange={(e) => onChange({ ...item, serialNumber: e.target.value })}
            />
          </Grid>
        )}

        {/* Power Requirements */}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={item.requiresPower}
                onChange={(e) => onChange({ ...item, requiresPower: e.target.checked })}
              />
            }
            label="Requires Power"
          />
        </Grid>
        {item.requiresPower && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Power Location</InputLabel>
              <Select
                value={item.powerLocation || ''}
                label="Power Location"
                onChange={(e) => onChange({ ...item, powerLocation: e.target.value as typeof LOCATIONS[number] | undefined })}
              >
                {LOCATIONS.map((loc) => (
                  <MenuItem key={loc} value={loc}>{loc}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Additional Info */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            label="Quantity"
            value={item.quantity}
            onChange={(e) => onChange({ ...item, quantity: parseInt(e.target.value) })}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Tags"
            value={item.tags.join(', ')}
            onChange={(e) => onChange({
              ...item,
              tags: e.target.value.split(',').map(tag => tag.trim())
            })}
            helperText="Separate tags with commas"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Notes"
            value={item.notes}
            onChange={(e) => onChange({ ...item, notes: e.target.value })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditForm;