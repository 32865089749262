import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Stack,
  Alert,
  LinearProgress,
  ButtonGroup
} from '@mui/material';
import {
  Save as SaveIcon,
  Add as AddIcon,
  Warning as WarningIcon,
  CloudDownload as LoadIcon
} from '@mui/icons-material';
import AdminExpenseManager from '../components/AdminExpenseManager';
import AdminAARAnalysis from '../components/budgets/sections/AdminAARAnalysis';
import axiosInstance from '../utils/axiosConfig';
import BudgetAnalysis from '../components/budgets/BudgetAnalysis';
import { ADMIN_CATEGORIES } from '../constants/adminCategories';



interface AdminExpenseLine {
  id: string;
  code: string;
  frequency: 'Monthly' | 'Quarterly' | 'Annually' | 'Bi-Monthly' | 'Weekly';
  
  qty: number;
  cost: number;
  sub: number;
  value: number;
  total: number;
  notes?: string;
}

interface AdminBudgetData {
  _id?: string;
  year: number;
  adminExpenseLines: AdminExpenseLine[];
  totalValue: number;
  status: string;
  createdBy?: string;
  updatedBy?: string;
  version?: number;
}

const initialBudgetData: AdminBudgetData = {
  year: 2025, // Changed to 2025 as per requirement
  adminExpenseLines: [],
  totalValue: 0,
  status: 'Draft'
};

const AdminBudgetPage = () => {
  const [budgetData, setBudgetData] = useState<AdminBudgetData>(initialBudgetData);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const handleLoadBudget = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setLoadingMessage('Loading 2025 budget...');

      const response = await axiosInstance.get('/api/adminbudget/2025');
      
      if (response.data) {
        setBudgetData(response.data);
        setHasUnsavedChanges(false);
      } else {
        setError('No budget data found for 2025');
      }
    } catch (err) {
      setError('Error loading 2025 admin budget');
      console.error(err);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setLoadingMessage('Saving budget...');
  
      const payload = {
        year: budgetData.year,
        adminExpenseLines: budgetData.adminExpenseLines,
        totalValue: budgetData.totalValue,
        status: budgetData.status,
        updatedBy: 'admin_user_id', // Replace with actual user ID
      };
  
      // If we have an _id, update the existing budget
      if (budgetData._id) {
        const response = await axiosInstance.put(`/api/adminbudget/${budgetData._id}`, payload);
        setBudgetData(response.data);
      } else {
        // Check if 2025 budget exists
        const existingBudget = await axiosInstance.get('/api/adminbudget/year/2025');
        
        if (existingBudget.data) {
          // Update existing 2025 budget
          const response = await axiosInstance.put(`/api/adminbudget/${existingBudget.data._id}`, payload);
          setBudgetData(response.data);
        } else {
          // Create new only if no 2025 budget exists
          const response = await axiosInstance.post('/api/adminbudget', payload);
          setBudgetData(response.data);
        }
      }
  
      setHasUnsavedChanges(false);
  
    } catch (err) {
      setError('Error saving admin budget');
      console.error(err);
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleDataUpdate = (lines: AdminExpenseLine[]) => {
    setBudgetData(prev => ({
      ...prev,
      adminExpenseLines: lines,
      totalValue: lines.reduce((sum, line) => sum + (line.total || 0), 0) // Changed to use total instead of value
    }));
    setHasUnsavedChanges(true);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%', p: 3, overflowX: 'auto' }}>
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography variant="h4">
          Administrative Budget {budgetData.year}
        </Typography>
        <ButtonGroup>
          <Button
            variant="outlined"
            startIcon={<LoadIcon />}
            onClick={handleLoadBudget}
            disabled={isLoading}
          >
            Load 2025 Budget
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={!hasUnsavedChanges || isLoading}
          >
            Save Changes
          </Button>
        </ButtonGroup>
      </Box>

      {isLoading && (
        <Box sx={{ mb: 2 }}>
          <LinearProgress />
          {loadingMessage && (
            <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
              {loadingMessage}
            </Typography>
          )}
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {hasUnsavedChanges && (
        <Alert severity="warning" icon={<WarningIcon />}>
          You have unsaved changes
        </Alert>
      )}

      {/* Main Content */}
      <Paper sx={{ p: 3, bgcolor: 'background.default' }}>
        <AdminExpenseManager
          lines={budgetData.adminExpenseLines}
          onUpdate={handleDataUpdate}
        />
      </Paper>

      <Paper sx={{ mt: 3, p: 3 }}>
  <Typography variant="h5" gutterBottom>
    Budget Analysis
  </Typography>
  <BudgetAnalysis 
    budgetLines={budgetData.adminExpenseLines} 
    />
</Paper>

{/* Add AAR Analysis here */}
<Paper sx={{ mt: 3, p: 3 }}>
  <Typography variant="h5" gutterBottom>
    AAR Analysis
  </Typography>
  <AdminAARAnalysis 
  budget={{
    _id: budgetData._id || '',
    year: budgetData.year,
    adminExpenseLines: budgetData.adminExpenseLines.map(line => ({
      code: line.code,
      total: line.total,
      notes: line.notes
    })),
    totalValue: budgetData.totalValue,
    status: budgetData.status,
    version: budgetData.version || 1,
    updatedBy: budgetData.updatedBy || ''
  }} 
/>
</Paper>

      {/* Footer Section */}
      <Paper sx={{ mt: 3, p: 2, bgcolor: 'primary.main', color: 'white' }}>
        <Typography variant="h6">
          Total Administrative Budget: €{budgetData.totalValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </Typography>
      </Paper>
    </Box>
  );
};

export default AdminBudgetPage;