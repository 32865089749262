// dropdownOptions.ts

export interface DropdownOption {
  value: string;
  label: string;
}

export const dropdownOptions: DropdownOption[] = [
  { value: "3868359858", label: "Better Live" },
  { value: "5873430916", label: "24 Administration" },
  { value: "5929889122", label: "Resources" },
  { value: "5929931052", label: "Music Alliance Ireland 2024" },
  { value: "5929931848", label: "Europe Jazz Network" },
  { value: "5929954077", label: "Irish Jazz Forum" },
  { value: "5929960524", label: "IMC Board" },
  { value: "5929972528", label: "Improvising Across Boundaries Research" },
  { value: "5960360285", label: "2024 Cooler CPD" },
  { value: "6488981469", label: "Cooler Build" },
  { value: "6616138149", label: "Conferences 2024" },
  { value: "6109572941", label: "Better Live Training" },
  { value: "5929819422", label: "Cooler Clinic Jan Online" },
  { value: "5153526703", label: "BAN BAM Premieres Dublin" },
  { value: "5929805918", label: "BAN BAM Tour 24" },
  { value: "5929816234", label: "Aleka TBC" },
  { value: "5929892388", label: "Navigator Letterkenny An Grianan" },
  { value: "5929932510", label: "Jazzahead 2024" },
  { value: "5929946138", label: "DCU Star Platform" },
  { value: "6109571119", label: "EJN Social Inclusion Seminar" },
  { value: "6557782309", label: "Aengus Hackett Trio" },
  { value: "5929902412", label: "Navigator Garter Lane Waterford" },
  { value: "5929898165", label: "Navigator Belfast The Crescent" },
  { value: "5929851897", label: "12 Points Tampere 25-28 Sep" },
  { value: "5929918285", label: "Jazz Futures Tampere 25-28 Sep" },
  { value: "5929926058", label: "Fun Size Jazz 2024 - Sligo" },
  { value: "5929893519", label: "Navigator Tinahely Courthouse" },
  { value: "6811571988", label: "Better Live Meeting Lyon" },
  { value: "5929890966", label: "Navigator Ballina" },
  { value: "5929891365", label: "Navigator Thurles The Source" },
  { value: "5929906763", label: "Navigator Kildare Riverbank" },
  { value: "5929970325", label: "Jazz Camp for Girls - DCU" },
  { value: "5929939421", label: "Cooler Clinic Sept" },
  { value: "5929970811", label: "Jazz Camp for Girls - UCD" },
  { value: "5929971418", label: "Jazz Camp for Girls - CSM" },
  { value: "5929942950", label: "Cooler Clinic October" },
  { value: "5929952799", label: "Liv Andrea Hauge Trio Dublin" },
  { value: "5929943428", label: "Cooler Clinic November" },
  { value: "5929944116", label: "Cooler Clinic December" },
  { value: "6616139975", label: "JPN Conference" },
  { value: "1683885960", label: "National Jazz Forum and Music Ireland Alliance" },
  { value: "1694153060", label: "IMC Website Development" },
  { value: "1684910201", label: "Administration" },
  { value: "1684945035", label: "Europe Jazz Network" },
  { value: "1685096270", label: "Music Alliance Ireland" },
  { value: "1685339564", label: "IMC Board" },
  { value: "1694089969", label: "Fundraising RAISE" },
  { value: "1860499434", label: "Child Protection" },
  { value: "1860499738", label: "Charities Regulator" },
  { value: "3884347595", label: "Irish Jazz Forum" },
  { value: "3908525796", label: "Arts Council 70th Anniversary Event" },
  { value: "4131508205", label: "Incubator Filming in Phoenix Park" },
  { value: "4132848989", label: "Incubator Video Releases" },
  { value: "1683885964", label: "Incubator - Creator Investment Initiative" },
  { value: "1683885969", label: "Write. Record. Perform. (in partnership with Triskel Arts Centre)" },
  { value: "4131501659", label: "Pathways Trio at The Cooler" },
  { value: "3884316439", label: "Navigator - Ballina Arts" },
  { value: "4065891966", label: "EJN - AEP - Strasbourg" },
  { value: "4140327234", label: "Jazz Camp Training Day" },
  { value: "4140553820", label: "Ban Bam Screenings at New Music Dublin" },
  { value: "4140491850", label: "Bianca Gannon at Hugh Lane Gallery" },
  { value: "4190256725", label: "Capacity Building Grant for Cooler" },
  { value: "4131507179", label: "Scott Flanigan at Arthurs" },
  { value: "4131506317", label: "Fixity at Arthurs" },
  { value: "4605518906", label: "Cooler Jazz Jam" },
  { value: "3884316829", label: "Navigator - Source Thurles" },
  { value: "4506550506", label: "Paul Dunlea Quintet" },
  { value: "3884317207", label: "Navigator - Grianan Donegal" },
  { value: "1683885915", label: "Jazz Camp for Girls - Dublin Edition" },
  { value: "4448394717", label: "EJN - Octopus Project - Schaffhausen" },
  { value: "4432795988", label: "EJN - Board Meeting - Bolzano" },
  { value: "4997045907", label: "Cooler Jazz Jam August" },
  { value: "4971735278", label: "Paul Tynan & Limerick Jazz" },
  { value: "4997051676", label: "Culture Night" },
  { value: "3884325259", label: "Navigator - Courthouse Tinahely" },
  { value: "3884325970", label: "Navigator - Crescent Belfast" },
  { value: "3884329774", label: "Navigator - Riverbank Kildare" },
  { value: "5499412864", label: "Navigator - Garter Lane Waterford" },
  { value: "3884330323", label: "Navigator - Garter Lane Waterford" },
  { value: "5531174523", label: "Lauren Kinsella Trio Hugh Lane Nov 23" },
  { value: "5599818884", label: "London Jazz Festival - Jazz from Ireland" },
  { value: "5336622517", label: "Navigator - Tinahely Co. Wicklow" },
  { value: "1918164864", label: "Afrobeat - Yankari & Selu" },
  { value: "1837476750", label: "Craig Taborn - Shadow Play - Solo Concert" },
  { value: "1767483802", label: "Joe O'Callaghan Quartet - February 17th" },
  { value: "1683885956", label: "Jazzahead International Tradefair and Showcase Platform" },
  { value: "3573433310", label: "Christine Tobin - Returning Weather" },
  { value: "4185050284", label: "WRP Ajudication" },
  { value: "4471842046", label: "European Jazz Conference (Marseille, France)" },
  { value: "1683884189", label: "Jazz Connector CPD - Season 1" },
  { value: "1683885216", label: "12 Points Festival" },
  { value: "1683885947", label: "Young Irish Jazz Musician of the Year in assoc with Limerick Jazz Society and Festival" },
  { value: "1683885953", label: "Process Project - (Creative About Music Podcast, Int'l Jazz Day and more)" },
  { value: "1683885958", label: "The Space Programme" },
  { value: "1685096119", label: "Irish Jazz Forum" },
  { value: "1694089781", label: "Venue pencils, pricing, database" },
  { value: "2029486812", label: "Capacity Building Grant" },
  { value: "2138259897", label: "Afrobeat - Manden & Elikya" },
  { value: "2165079794", label: "Robson-Levy Quartet" },
  { value: "2483408743", label: "IAF Event" },
  { value: "2544330435", label: "Belgian Jazz Meeting" },
  { value: "2544363444", label: "EJN AEP Cully" },
  { value: "2629168863", label: "EJN - Beyond Europe - Sarajevo" },
  { value: "2853603084", label: "Contra" },
  { value: "2998486047", label: "JPN Conference" },
  { value: "5240965888", label: "EJC - Marseilles" },
  { value: "1756663403", label: "Fun-Size Jazz! - Sligo" },
];