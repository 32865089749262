import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Alert,
  TextField
} from '@mui/material';
import { Download } from 'lucide-react';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';

interface AdminBudgetLine {
    code: string;
    total: number;
    notes?: string;
  }

interface AdminBudget {
    _id?: string;
    year: number;
    adminExpenseLines: AdminBudgetLine[];
    totalValue: number;
    status: string;
    version?: number;
    updatedBy?: string;
  }

interface AdminExpenseTotals {
  'Salary - Director / Artistic Director / CEO': number;
  'Salary - Artistic Director / Executive Director / Executive Producer / General Manager': number;
  'Salaries - all other staff': number;
  'Rent/Mortgage': number;
  'Maintenance/repairs': number;
  'Utilities': number;
  'Bank Charges / Interest': number;
  'Accounting / professional service fees': number;
  'General marketing / PR': number;
  'General fundraising / Development costs': number;
  'Office admin / postage / IT / phone': number;
  'Cost of merchandising / bar sales': number;
  'Insurance': number;
  'Miscellaneous expenses': number;
  'Other costs (please specify)': number;
}

interface StaffTotals {
  artisticStaff: number;
  nonArtisticStaff: number;
  communityEmployment: number;
}

interface OtherItem {
  code: string;
  amount: number;
  notes?: string;
  label?: string;
}

interface AdminAARAnalysisProps {
  budget: AdminBudget;
}

const AdminAARAnalysis: React.FC<AdminAARAnalysisProps> = ({ budget }) => {
  const [staffTotals, setStaffTotals] = useState<StaffTotals>({
    artisticStaff: 0,
    nonArtisticStaff: 0,
    communityEmployment: 0
  });

  useEffect(() => {
    console.log('Admin Budget data:', budget);
    console.log('Admin Expense lines:', budget.adminExpenseLines);
  }, [budget]);

  const calculateExpenseTotals = (): AdminExpenseTotals => {
    const totals: AdminExpenseTotals = {
      'Salary - Director / Artistic Director / CEO': 0,
      'Salary - Artistic Director / Executive Director / Executive Producer / General Manager': 0,
      'Salaries - all other staff': 0,
      'Rent/Mortgage': 0,
      'Maintenance/repairs': 0,
      'Utilities': 0,
      'Bank Charges / Interest': 0,
      'Accounting / professional service fees': 0,
      'General marketing / PR': 0,
      'General fundraising / Development costs': 0,
      'Office admin / postage / IT / phone': 0,
      'Cost of merchandising / bar sales': 0,
      'Insurance': 0,
      'Miscellaneous expenses': 0,
      'Other costs (please specify)': 0
    };
  
    budget.adminExpenseLines.forEach((line) => {
        const matchingCodeEntry = Object.entries(IMC_EXPENSE_CODES).find(
          ([_, codeData]) => codeData.code === line.code
        );
    
        if (matchingCodeEntry) {
          const [_, codeData] = matchingCodeEntry;
          const categoryKey = Object.keys(totals).find(key => 
            key.toLowerCase() === codeData.acCode.toLowerCase()
          ) as keyof AdminExpenseTotals;
          
          if (categoryKey && line.total) {
            totals[categoryKey] += line.total;
          }
        }
      });
    
      return totals;
    };


  const getOtherCosts = (): OtherItem[] => {
    return budget.adminExpenseLines.filter(line => {
      const matchingCodeEntry = Object.entries(IMC_EXPENSE_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return matchingCodeEntry && matchingCodeEntry[1].acCode === 'Other costs (please specify)';
    }).map(line => {
      const matchingCode = Object.entries(IMC_EXPENSE_CODES).find(
        ([_, codeData]) => codeData.code === line.code
      );
      return {
        code: line.code,
        amount: line.total,
        notes: line.notes,
        label: matchingCode ? matchingCode[1].label : undefined
      };
    });
  };

  const expenseTotals = calculateExpenseTotals();
  const otherCosts = getOtherCosts();

  const expenseTotal = Object.values(expenseTotals).reduce((a, b) => a + b, 0);

  const hasTotalMismatch = Math.abs(expenseTotal - budget.totalValue) > 0.01;

  const exportToCSV = () => {
    const rows = [
      ['Core and administrative expenditure'],
      ['Year', budget.year],
      ['Status', budget.status],
      [''],
      ...Object.entries(expenseTotals).map(([category, amount]) => [
        category,
        amount.toFixed(2)
      ]),
      ['Total Expenses (Calculated)', expenseTotal.toFixed(2)],
      ['Total Expenses (Budget)', budget.totalValue.toFixed(2)],
      [''],
      ['Whole-time equivalent staff'],
      ['Number of artistic staff', staffTotals.artisticStaff],
      ['Number of non-artistic staff', staffTotals.nonArtisticStaff],
      ['Number of community employment scheme', staffTotals.communityEmployment]
    ];

    if (otherCosts.length > 0) {
      rows.push(
        [''],
        ['Other Costs Breakdown'],
        ['Code', 'Label', 'Amount', 'Notes'],
        ...otherCosts.map(cost => [
          cost.code,
          cost.label || '',
          cost.amount.toFixed(2),
          cost.notes || ''
        ])
      );
    }

    const csvContent = rows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Admin_Budget_${budget.year}_AAR.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Box sx={{ p: 3 }}>
      {hasTotalMismatch && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          The AAR totals don't match the budget totals. This may be due to unmapped codes.
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableBody>
            {/* Header */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Core and administrative expenditure</Typography>
                <Typography variant="body2" color="text.secondary">
                  Any on-going expenditure that must be met, regardless of how many individual activities your organisation is undertaking at any given time.
                </Typography>
              </TableCell>
            </TableRow>

            {/* Admin Expenses */}
            {Object.entries(expenseTotals).map(([category, amount]) => (
              <TableRow key={category}>
                <TableCell>
                  {category}
                  {category === 'Other costs (please specify)' && otherCosts.length > 0 && (
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Codes: {otherCosts.map(c => `${c.code} (${c.label})`).join(', ')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>€{amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell><strong>Total Costs (Calculated)</strong></TableCell>
              <TableCell><strong>€{expenseTotal.toFixed(2)}</strong></TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Total Costs (Budget)</strong></TableCell>
              <TableCell><strong>€{budget.totalValue.toFixed(2)}</strong></TableCell>
            </TableRow>

            {/* Staff Totals */}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6" sx={{ mt: 3 }}>Whole-time equivalent staff</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of artistic staff</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={staffTotals.artisticStaff}
                  onChange={(e) => setStaffTotals(prev => ({
                    ...prev,
                    artisticStaff: Number(e.target.value)
                  }))}
                  sx={{ width: 100 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of non-artistic staff</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={staffTotals.nonArtisticStaff}
                  onChange={(e) => setStaffTotals(prev => ({
                    ...prev,
                    nonArtisticStaff: Number(e.target.value)
                  }))}
                  sx={{ width: 100 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of community employment scheme</TableCell>
              <TableCell>
                <TextField
                  type="number"
                  value={staffTotals.communityEmployment}
                  onChange={(e) => setStaffTotals(prev => ({
                    ...prev,
                    communityEmployment: Number(e.target.value)
                  }))}
                  sx={{ width: 100 }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<Download size={18} />}
          onClick={exportToCSV}
        >
          Export to CSV
        </Button>
      </Box>
    </Box>
  );
};

export default AdminAARAnalysis;