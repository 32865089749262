import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Alert } from '@mui/material';
import { Performance, Event, Venue, BandRecommendation, Staff } from '../../types/mongodb';

interface BandEventInfoProps {
  performance: Performance;
}

const BandEventInfo: React.FC<BandEventInfoProps> = ({ performance }) => {
  const eventCountdown = () => {
    if (!performance.startTime) return 'Date not set';
    const eventDate = new Date(performance.startTime).getTime();
    const currentDate = new Date().getTime();
    const daysLeft = Math.ceil((eventDate - currentDate) / (1000 * 60 * 60 * 24));
    return daysLeft < 0 ? 'Event has passed' : `${daysLeft} days left until event`;
  };

  const getArtistName = () => {
    try {
      // First check for simpleBandName
      if (performance.simpleBandName) {
        return performance.simpleBandName;
      }
      
      // If no simpleBandName, check for band.artistName
      const band = performance.band as BandRecommendation;
      if (!band) return 'Artist TBA';
      return typeof band === 'string' ? 'Unknown Band' : (band.artistName || 'Artist TBA');
    } catch (error) {
      console.error('Error getting artist name:', error);
      return 'Artist TBA';
    }
  };

  const getVenueName = (venue: string | Venue | undefined) => {
    try {
      if (!venue) return 'Venue TBA';
      if (typeof venue === 'string') return 'Venue details pending';
      return venue.name || 'Venue TBA';
    } catch (error) {
      console.error('Error getting venue name:', error);
      return 'Venue TBA';
    }
  };
  
  const getEventInfo = () => {
    try {
      // First check for strandId
      if (performance.strandId) {
        if (typeof performance.strandId === 'string') {
          return (
            <Typography variant="subtitle1" color="text.secondary">
              This performance is part of strand ID: {performance.strandId}
            </Typography>
          );
        }
    
        return (
          <>
            <Typography variant="subtitle1" color="text.secondary">
              Strand: {performance.strandId.name || 'Name pending'}
            </Typography>
            {performance.strandId.startDate && performance.strandId.endDate && (
              <Typography variant="subtitle1" color="text.secondary">
                Period: {new Date(performance.strandId.startDate).toLocaleDateString()} - 
                      {new Date(performance.strandId.endDate).toLocaleDateString()}
              </Typography>
            )}
          </>
        );
      }
    
      // Handle event case or no assignment case
      const event = performance.event as Event;
      if (!event || typeof event === 'string') {
        return (
          <Typography variant="subtitle1" color="text.secondary">
            No event or strand information available
          </Typography>
        );
      }
      
      return (
        <>
          <Typography variant="subtitle1" color="text.secondary">
            Event: {event.name || 'Name pending'}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Date: {performance.startTime ? new Date(performance.startTime).toLocaleDateString() : 'TBA'}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Venue: {getVenueName(event.venue)}
          </Typography>
          <Typography variant="h6" color="primary">
            {eventCountdown()}
          </Typography>
        </>
      );
    } catch (error) {
      console.error('Error getting event info:', error);
      return (
        <Alert severity="warning">
          Event information unavailable
        </Alert>
      );
    }
  };

  const formatTime = (time: Date | string | undefined) => {
    try {
      if (!time) return 'TBA';
      const date = time instanceof Date ? time : new Date(time);
      if (isNaN(date.getTime())) return 'Invalid date';
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'TBA';
    }
  };

  const renderStaff = () => {
    try {
      if (!performance.staff || !Array.isArray(performance.staff) || performance.staff.length === 0) {
        return <Typography color="text.secondary">No staff assigned yet</Typography>;
      }

      return (
        <List>
          {performance.staff.map((staffMember, index) => {
            try {
              const isString = typeof staffMember === 'string';
              const key = isString ? staffMember : ((staffMember as Staff)._id || `staff-${index}`);
              const name = isString ? `Staff ID: ${staffMember}` : ((staffMember as Staff).name || 'Name pending');
              const role = isString ? 'Role pending' : ((staffMember as Staff).role || 'Role pending');
              
              return (
                <ListItem key={key}>
                  <ListItemText 
                    primary={name}
                    secondary={role}
                  />
                </ListItem>
              );
            } catch (error) {
              console.error('Error rendering staff member:', error);
              return null;
            }
          }).filter(Boolean)}
        </List>
      );
    } catch (error) {
      console.error('Error rendering staff list:', error);
      return (
        <Alert severity="warning">
          Unable to display staff information
        </Alert>
      );
    }
  };

  if (!performance) {
    return (
      <Card>
        <CardContent>
          <Alert severity="error">
            No performance data available
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {getArtistName()}
        </Typography>
        {getEventInfo()}
        <Typography variant="subtitle1" color="text.secondary">
          Start Time: {formatTime(performance.startTime)}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          End Time: {formatTime(performance.endTime)}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Status: {performance.status || 'Status pending'}
        </Typography>
        
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Assigned Staff
        </Typography>
        {renderStaff()}
      </CardContent>
    </Card>
  );
};

export default BandEventInfo;