// src/components/ideas/IdeaCard.tsx
import React from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Chip,
  Button
} from '@mui/material';
import { Mail, Music, Link as LinkIcon, Hash, Bookmark } from 'lucide-react';
import { Idea } from '../../types/Idea';

interface IdeaCardProps {
  idea: Idea;
  strandName?: string; // Add strand name prop
  onEdit: (idea: Idea) => void;
  onDelete: (idea: Idea) => void;
  onConvert: (idea: Idea) => void;
}

const IdeaCard: React.FC<IdeaCardProps> = ({ 
  idea, 
  strandName, 
  onEdit, 
  onDelete, 
  onConvert 
}) => {
  const getSourceIcon = () => {
    switch (idea.sourceType) {
      case 'email': return <Mail size={20} />;
      case 'spotify': return <Music size={20} />;
      case 'website': return <LinkIcon size={20} />;
      default: return <Hash size={20} />;
    }
  };

  const getStatusColor = () => {
    switch (idea.status) {
      case 'new': return 'primary';
      case 'reviewing': return 'warning';
      case 'converted': return 'success';
      default: return 'default';
    }
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box display="flex" alignItems="center" gap={1}>
            {getSourceIcon()}
            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
              {idea.title}
            </Typography>
          </Box>
          <Chip 
            label={idea.status} 
            color={getStatusColor()}
            size="small"
          />
        </Box>

         {/* Add Strand Chip if available */}
         {strandName && (
          <Box mt={1}>
            <Chip
              icon={<Bookmark size={16} />}
              label={strandName}
              size="small"
              variant="outlined"
              color="secondary"
            />
          </Box>
        )}

        <Typography 
          variant="body2" 
          color="text.secondary" 
          mt={1}
          sx={{ wordBreak: 'break-word' }}
        >
          {idea.notes}
        </Typography>

        {idea.sourceUrl && (
          <Typography 
            variant="body2" 
            color="primary" 
            mt={1}
            component="a"
            href={idea.sourceUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ wordBreak: 'break-all' }}
          >
            {idea.sourceUrl}
          </Typography>
        )}

        <Box mt={2} display="flex" gap={1} flexWrap="wrap">
          {idea.tags?.map(tag => (
            <Chip key={tag} label={tag} size="small" />
          ))}
        </Box>

        <Box mt={2} display="flex" gap={1}>
          <Button size="small" onClick={() => onEdit(idea)}>Edit</Button>
          <Button 
            size="small" 
            color="primary"
            onClick={() => onConvert(idea)}
          >
            Convert
          </Button>
          <Button 
            size="small" 
            color="secondary"
            onClick={() => onDelete(idea)}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default IdeaCard;