import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Modal,
} from '@mui/material';
import {
    ManageAccounts,
    Computer,
    Business,
    Gavel,
    Campaign,
    Subscriptions,
    Construction,
    Build,
    DirectionsCar,
    Group,
    BusinessCenter,
  } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ShowRunnrCalc from '../components/ShowRunnrCalc'; // Correctly imported as a component
import { IMC_EXPENSE_CODES } from '../constants/imcCodes';

interface AdminCategory {
    title: string;
    codes: string[]; // Changed from string[] | [] to just string[]
    icon: React.ReactNode; // Update this to accept a JSX element
    description: string;
}

// Group the expense codes by category
const ADMIN_CATEGORIES: Record<string, AdminCategory> = {
    SALARIES: {
      title: 'Salaries & Benefits',
      codes: ['301', '302', '303-1', '303-2', '304', '305', '306', '307', '310'],
      icon: <ManageAccounts />, // Material UI icon for people management
      description: 'Compensation and benefits for staff and executives.',
    },
    IT: {
      title: 'IT & Software',
      codes: Object.values(IMC_EXPENSE_CODES)
              .filter(code => (code.subCategories as unknown as string[]).includes('it'))
              .map(code => code.code),
      icon: <Computer/>, // Represents IT-related tasks
      description: 'Software subscriptions, cloud services, and IT-related expenses.',
    },
    OFFICE: {
        title: 'Office & Building',
        codes: Object.values(IMC_EXPENSE_CODES)
                  .filter((code) =>
                    Array.isArray(code.subCategories) &&
                    ['office', 'building'].some((subCat) =>
                      (code.subCategories as unknown as string[]).includes(subCat)
                    )
                  )
                  .map((code) => code.code),
        icon: <Business />,
        description: 'Expenses related to office supplies, building maintenance, and utilities.',
      },
    PROFESSIONAL_FEES: {
      title: 'Professional Services',
      codes: ['356','357', '360', '362', '363', '364'],
      icon: <Gavel/>, // Represents legal or professional services
      description: 'Accounting, legal, consulting, and professional fees.',
    },
    MARKETING: {
      title: 'Marketing & Advertising',
      codes: ['318', '342-1', '342-2'],
      icon: <Campaign/>, // For marketing activities
      description: 'General marketing expenses, web design, and digital media.',
    },
    SUBSCRIPTIONS: {
      title: 'Subscriptions & Media',
      codes: ['361', '361-2', '361-3', '361-4', '361-5', '365-1', '365-2'],
      icon: <Subscriptions/>, // A subscription icon
      description: 'Subscription-based services and media platforms.',
    },
    EQUIPMENT: {
      title: 'Equipment & Leasing',
      codes: [ '345-2', '345-3', '346', '347'],
      icon: <Build/>, // For tools, equipment, and leasing
      description: 'Leasing, maintenance, and disposal of equipment.',
    },
    TRANSPORTATION: {
      title: 'Transportation & Vehicles',
      codes: ['320',  '345-1'],
      icon: <DirectionsCar/>, // For vehicle-related costs
      description: 'Vehicle leasing, fuel, and other transportation costs.',
    },
    STAFF_EXPENSES: {
      title: 'Staff Expenses',
      codes: ['315', '316', '317','323', '324', '325', '326', '327', '328'],
      icon: <Group/>, // For staff-related categories
      description: 'Training, recruitment, and travel-related staff expenses.',
    },
    COMPANY_EXPENSES: {
      title: 'Company & General Expenses',
      codes: ['319', '330', '349', '350', '366', '371','374'],
      icon: <BusinessCenter/>, // For generic company-related expenses
      description: 'General company expenses, gifts, and miscellaneous costs.',
    },
  };

interface AdminExpenseLine {
  id: string;
  code: string;
  frequency: 'Monthly' | 'Quarterly' | 'Annually' | 'Bi-Monthly' | 'Weekly';
  qty: number;
  cost: number;
  value: number;
  sub: number;
  total: number;
  notes?: string;
}

interface Props {
  lines: AdminExpenseLine[];
  onUpdate: (lines: AdminExpenseLine[]) => void;
}

const AdminExpenseManager: React.FC<Props> = ({ lines, onUpdate }) => {
    const [localLines, setLocalLines] = useState<AdminExpenseLine[]>([]);
    const [expandedCategory, setExpandedCategory] = useState<string | false>('SALARIES');
  
    const multiply = (a: number, b: number): number => a * b;
    const sum = (numbers: number[]): number => numbers.reduce((acc, curr) => acc + curr, 0);
    const calculateCategoryTotal = (categoryCode: keyof typeof ADMIN_CATEGORIES): number => {
      const categoryLines = localLines.filter(line => 
        ADMIN_CATEGORIES[categoryCode].codes.includes(line.code)
      );
      return categoryLines.reduce((sum, line) => sum + line.total, 0);
    };
  
    const formatNumber = (num: number): string => num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    const getFrequencyMultiplier = (frequency: string): number => {
      switch (frequency) {
        case 'Monthly':
          return 12;
        case 'Quarterly':
          return 4;
        case 'Annually':
          return 1;
        case 'Bi-Monthly':
          return 6;
        case 'Weekly':
          return 52;
        default:
          return 1;
      }
    };
  
    const calculateLine = (line: AdminExpenseLine): AdminExpenseLine => {
      const multiplier = getFrequencyMultiplier(line.frequency);
      const sub = multiply(line.cost, line.qty);
      const total = multiply(sub, multiplier);
      return { ...line, sub, total };
    };
  
    const calculatePRSI = (lines: AdminExpenseLine[]): number => {
      const relevantCodes = ['301', '305', '302', '307', '306'];
      const relevantLines = lines.filter(line => relevantCodes.includes(line.code));
      const totalBase = relevantLines.reduce((sum, line) => sum + line.total, 0);
      return totalBase * 0.1105;
    };
  
    const handleUpdate = (id: string | undefined, field: keyof AdminExpenseLine, value: any) => {
        if (!id) {
          console.error('No ID provided for update!');
          return;
        }
      
        console.log('Update triggered for ID:', id, 'field:', field, 'value:', value);
      
        setLocalLines(prevLines => {
          return prevLines.map(line => {
            // Make sure we have both IDs and they match
            if (!line.id) {
              console.error('Line missing ID:', line);
              return line;
            }
      
            if (line.id === id) {
              console.log('Updating line:', line.id);
              const updatedLine = { ...line, [field]: value };
              return calculateLine(updatedLine);
            }
            return line;
          });
        });
      };


    const handleFrequencyChange = (id: string, event: SelectChangeEvent) => {
      const value = event.target.value;
      if (['Monthly', 'Quarterly', 'Annually', 'Bi-Monthly', 'Weekly'].includes(value)) {
        handleUpdate(id, 'frequency', value);
      } else {
        console.error(`Invalid frequency value: ${value}`);
      }
    };
  
    const handleDelete = (id: string) => {
      const updatedLines = localLines.filter((line) => line.id !== id);
      setLocalLines(updatedLines);
      onUpdate(updatedLines);
    };
  
    const addLine = (code: string) => {
        const newId = `admin_${code}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        console.log('Creating new line with ID:', newId); // Debug log
        
        const newLine: AdminExpenseLine = {
          id: newId, // Ensure unique ID
          code,
          frequency: 'Annually',
          qty: 1,
          cost: 0,
          sub: 0,
          value: 0,
          total: 0,
        };
        setLocalLines(prev => [...prev, calculateLine(newLine)]);
      };
  
    const calculateTotal = (lines: AdminExpenseLine[]): number => {
      return sum(lines.map((line) => line.total));
    };
  
    useEffect(() => {
        // Ensure each line has an ID when first mapping
        const calculatedLines = lines.map(line => ({
          ...calculateLine(line),
          id: line.id || `admin_${line.code}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`
        }));
      
        // If we're doing the initial load and there's no PRSI line yet
        if (!calculatedLines.find(line => line.code === '310')) {
          const prsiAmount = calculatePRSI(calculatedLines);
          calculatedLines.push({
            id: `admin_310_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
            code: '310',
            frequency: 'Annually',
            qty: 1,
            cost: prsiAmount,
            value: prsiAmount,
            sub: prsiAmount,
            total: prsiAmount,
            notes: 'Automatically calculated as 11.05% of relevant salaries.',
          });
        } 
        // If we're updating and PRSI line exists
        else {
          const prsiAmount = calculatePRSI(calculatedLines);
          const prsiLineIndex = calculatedLines.findIndex(line => line.code === '310');
          if (prsiLineIndex !== -1) {
            calculatedLines[prsiLineIndex] = {
              ...calculatedLines[prsiLineIndex],
              cost: prsiAmount,
              sub: prsiAmount,
              total: prsiAmount
            };
          }
        }
      
        setLocalLines(calculatedLines);
      }, [lines]);
  
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Administrative Expenses
        </Typography>
  
        {Object.entries(ADMIN_CATEGORIES).map(([category, { title, icon, description, codes }]) => {
          const categoryTotal = calculateCategoryTotal(category as keyof typeof ADMIN_CATEGORIES);
          
          return (
            <Accordion
              key={category}
              expanded={expandedCategory === category}
              onChange={() => setExpandedCategory(expandedCategory === category ? false : category)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                  justifyContent: 'space-between'
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1">
                        {title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {description}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    padding: '4px 12px',
                    borderRadius: 1,
                    marginRight: 2
                  }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                      €{formatNumber(categoryTotal)}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
  
              <AccordionDetails>
                <Grid container spacing={1}>
                  {codes.map((code) => {
                    const line = localLines.find((l) => l.code === code);
                    const expenseCode = Object.values(IMC_EXPENSE_CODES).find(
                      (e) => e.code === code
                    );
  
                    return line ? (
                      <Grid item xs={12} key={line.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                          }}
                        >
                          <TextField
                            size="small"
                            label="Code"
                            value={line.code}
                            disabled
                            sx={{ width: '60px' }}
                          />
                          <TextField
                            size="small"
                            label="Description"
                            value={expenseCode?.label || ''}
                            disabled
                            sx={{ flex: 1 }}
                          />
                          <Select
                            size="small"
                            value={line.frequency}
                            onChange={(e) => handleFrequencyChange(line.id, e)}
                            sx={{ width: '120px' }}
                          >
                            <MenuItem value="Monthly">Monthly</MenuItem>
                            <MenuItem value="Quarterly">Quarterly</MenuItem>
                            <MenuItem value="Annually">Annually</MenuItem>
                            <MenuItem value="Bi-Monthly">Bi-Monthly</MenuItem>
                            <MenuItem value="Weekly">Weekly</MenuItem>
                          </Select>
                          <TextField
                            size="small"
                            type="number"
                            label="Qty"
                            value={line.qty}
                            onChange={(e) =>
                              handleUpdate(line.id, 'qty', Number(e.target.value))
                            }
                            sx={{ width: '70px' }}
                          />
<TextField
  size="small"
  type="number"
  label="Cost"
  value={line.cost}
  onChange={(e) => {
    console.log('TextField onChange - line ID:', line.id); // Add this debug
    const inputValue = e.target.value;
    const updatedValue = inputValue === '' ? '' : Number(inputValue);
    handleUpdate(line.id, 'cost', updatedValue);
  }}
  sx={{ width: '120px' }}
  InputProps={{
    startAdornment: <InputAdornment position="start">€</InputAdornment>,
  }}
/>
                          <Typography sx={{ width: '100px' }}>
                            €{formatNumber(line.sub)}
                          </Typography>
                          <Typography sx={{ width: '100px' }}>
                            €{formatNumber(line.total)}
                          </Typography>
                          <IconButton
                            onClick={() => handleDelete(line.id)}
                            color="error"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12} key={code}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <Typography>{expenseCode?.label}</Typography>
                          <IconButton onClick={() => addLine(code)} color="primary">
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
  
        <Paper sx={{ p: 2, mt: 2, bgcolor: 'primary.main', color: 'white' }}>
          <Typography variant="h6">
            Total Administrative Expenses: €{formatNumber(calculateTotal(localLines))}
          </Typography>
        </Paper>
      </Box>
    );
  };
  
  export default AdminExpenseManager;