import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Box, Typography, Grid, TextField, FormControl, InputLabel, 
  Select, MenuItem, FormControlLabel, Checkbox, Paper, 
  InputAdornment, Alert, SelectChangeEvent 
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';
import { ArtistDetails, BudgetItem } from '../../../types/Budget';
import { BandRecommendation } from '../../../types/mongodb';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import axiosInstance from '../../../utils/axiosConfig';
import { 
  EuropeanCountry, 
  NonEuropeanCountry, 
  TravelCostsToIreland 
} from '../../../types/Country';
import { selectArtistDetails } from '../../../store/selectors';
import { updateArtistDetails } from '../../../store/budgetSlice';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HotelIcon from '@mui/icons-material/Hotel';
import RestaurantIcon from '@mui/icons-material/Restaurant';

interface FormData extends Omit<ArtistDetails, 'costs'> {
  newBandName?: string;
}

interface Rates {
  accommodation: number;
  perDiem: number;
  flight: number;
  groundTransport: number;
}

const DEFAULT_RATES: Rates = {
  accommodation: 140,
  perDiem: 25,
  flight: 250,
  groundTransport: 150
};

const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return isFinite(value) ? value : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2
  }).format(amount);
};

const ArtistTravelPlanner: React.FC = () => {
  const dispatch = useDispatch();
  const artistDetails = useSelector(selectArtistDetails);
  
  const [bands, setBands] = useState<BandRecommendation[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [useExistingBand, setUseExistingBand] = useState<boolean>(!!artistDetails?.bandId);

  const [formData, setFormData] = useState<FormData>(() => ({
    bandId: artistDetails?.bandId || '',
    fee: artistDetails?.fee || 0,
    numberOfPeople: artistDetails?.numberOfPeople || 0,
    numberOfNights: artistDetails?.numberOfNights || 0,
    origin: artistDetails?.origin || '',
    arrivalDate: artistDetails?.arrivalDate ? dayjs(artistDetails.arrivalDate).toDate() : undefined,
    departureDate: artistDetails?.departureDate ? dayjs(artistDetails.departureDate).toDate() : undefined,
    newBandName: '',
  }));

  const [rates, setRates] = useState<Rates>(() => {
    const costs = artistDetails?.costs;
    if (!costs) return DEFAULT_RATES;

    return {
      accommodation: costs.accommodation?.amount 
        ? costs.accommodation.amount / ((artistDetails?.numberOfPeople ?? 1) * (artistDetails?.numberOfNights ?? 1))
        : costs.accommodation?.amount || DEFAULT_RATES.accommodation,
      perDiem: costs.perDiems?.amount
        ? costs.perDiems.amount / ((artistDetails?.numberOfPeople ?? 1) * ((artistDetails?.numberOfNights ?? 1) ))
        : costs.perDiems?.amount || DEFAULT_RATES.perDiem,
      flight: costs.travel?.amount
        ? (costs.travel.amount - DEFAULT_RATES.groundTransport) / (artistDetails?.numberOfPeople ?? 1)
        : 0,  // Don't use DEFAULT_RATES.flight if no existing value
      groundTransport: costs.travel?.amount
        ? DEFAULT_RATES.groundTransport
        : 0,  // Don't use DEFAULT_RATES.groundTransport if no existing value
    };
});

  useEffect(() => {
    const fetchBands = async () => {
      try {
        const response = await axiosInstance.get<BandRecommendation[]>('/api/bandRecommendations');
        setBands(response.data);
      } catch (err) {
        console.error('Error fetching bands:', err);
        setError('Failed to fetch band data');
      }
    };
    fetchBands();
  }, []);

  const calculateCosts = useCallback((): ArtistDetails['costs'] => {
    const createBudgetItem = (code: keyof typeof IMC_EXPENSE_CODES, amount: number): BudgetItem => ({
      code: IMC_EXPENSE_CODES[code].code,
      label: IMC_EXPENSE_CODES[code].label,
      amount: safeNumber(amount),
      description: '',
      isEstimate: true,
      status: 'draft',
      category: 'expense',
    });

    const artistFees = createBudgetItem(
      formData.origin === 'Ireland' ? 'ARTISTS_FEES_DOMESTIC_JAZZ' : 'ARTISTS_FEES_INTERNATIONAL_JAZZ',
      safeNumber(formData.fee)
    );

    const accommodationTotal = safeNumber(formData.numberOfPeople) * 
                             safeNumber(formData.numberOfNights) * 
                             safeNumber(rates.accommodation);
    const accommodation = createBudgetItem('ARTISTS_ACCOMMODATION', accommodationTotal);

    const perDiemTotal = safeNumber(formData.numberOfPeople) * 
                        (safeNumber(formData.numberOfNights)) * 
                        safeNumber(rates.perDiem);
    const perDiems = createBudgetItem('ARTISTS_PER_DIEMS', perDiemTotal);

    const travelTotal = safeNumber(formData.numberOfPeople) * 
                       safeNumber(rates.flight) + 
                       safeNumber(rates.groundTransport);
    const travel = createBudgetItem('ARTISTS_INTERNATIONAL_TRAVEL', travelTotal);

    return {
      artistFees,
      accommodation,
      perDiems,
      travel,
    };
  }, [formData, rates]);

  const debouncedDispatch = useCallback(
    debounce((updateData: ArtistDetails) => {
      dispatch(updateArtistDetails(updateData));
    }, 300),
    []
  );
  useEffect(() => {
    // Force initial calculation when component mounts
    if (formData) {
        const costs = calculateCosts();
        const updateData: ArtistDetails = {
            ...formData,
            costs,
        };
        delete (updateData as any).newBandName;
        dispatch(updateArtistDetails(updateData));
    }
}, []); 

  useEffect(() => {
    const costs = calculateCosts();
    const updateData: ArtistDetails = {
      ...formData,
      costs,
    };
    delete (updateData as any).newBandName;
    
    // Remove the comparison and always dispatch
    debouncedDispatch(updateData);

    return () => {
      debouncedDispatch.cancel();
    };
}, [formData, rates, calculateCosts, debouncedDispatch]);  //

const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: ['fee', 'numberOfPeople', 'numberOfNights'].includes(name)
        ? safeNumber(value)
        : value,
    };
    setFormData(newFormData);
    
    // Immediately calculate and dispatch
    const costs = calculateCosts();
    const updateData: ArtistDetails = {
      ...newFormData,
      costs,
    };
    delete (updateData as any).newBandName;
    dispatch(updateArtistDetails(updateData));
};

const handleRateChange = (type: keyof Rates, value: string) => {
    const numValue = safeNumber(value);
    const newRates = {
      ...rates,
      [type]: numValue
    };
    setRates(newRates);
    
    // Immediately calculate and dispatch
    const costs = calculateCosts();
    const updateData: ArtistDetails = {
      ...formData,
      costs,
    };
    dispatch(updateArtistDetails(updateData));
};

  const handleBandSelect = (e: SelectChangeEvent<string>) => {
    const bandId = e.target.value;
    const selectedBand = bands.find((b) => b._id === bandId);
    if (selectedBand) {
      setFormData((prev) => ({
        ...prev,
        bandId: selectedBand._id,
        numberOfPeople: selectedBand.totalMembers || 0,
        origin: selectedBand.recommenderCountry || '',
        fee: selectedBand.recommendedFee || 0,
      }));
    }
  };

  const handleDateChange = (type: 'arrival' | 'departure', newDate: Date | null) => {
    setFormData((prev) => ({
      ...prev,
      [type === 'arrival' ? 'arrivalDate' : 'departureDate']: newDate
    }));
  };

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const selectedCountry = event.target.value as EuropeanCountry | NonEuropeanCountry;
    const travelCost = TravelCostsToIreland[selectedCountry] || DEFAULT_RATES.flight;
    setFormData((prev) => ({ ...prev, origin: selectedCountry }));
    setRates((prev) => ({ ...prev, flight: travelCost }));
  };

  const calculateTotalCost = useCallback(() => {
    const costs = calculateCosts();
    return Object.values(costs).reduce((sum, item) => 
      safeNumber(sum) + safeNumber(item.amount), 0
    );
  }, [calculateCosts]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Artist & Travel Planning
      </Typography>
  
      {error && <Alert severity="error">{error}</Alert>}
  
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useExistingBand}
                onChange={(e) => setUseExistingBand(e.target.checked)}
              />
            }
            label="Use existing band from database?"
          />
        </Grid>
  
        {useExistingBand ? (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Select Band</InputLabel>
              <Select
                value={formData.bandId}
                onChange={handleBandSelect}
                label="Select Band"
              >
                {bands.map((band) => (
                  <MenuItem key={band._id} value={band._id}>
                    {band.artistName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="New Band Name"
              name="newBandName"
              value={formData.newBandName}
              onChange={handleTextFieldChange}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Artist Fee"
            name="fee"
            type="number"
            value={formData.fee || ''}
            onChange={handleTextFieldChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            inputProps={{ min: 0, step: "0.01" }}
          />
        </Grid>
  
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Origin (Country)</InputLabel>
            <Select
              value={formData.origin}
              onChange={handleCountryChange}
              label="Origin (Country)"
            >
              {Object.values(EuropeanCountry).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
              {Object.values(NonEuropeanCountry).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
  
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Number of People"
            name="numberOfPeople"
            type="number"
            value={formData.numberOfPeople || ''}
            onChange={handleTextFieldChange}
            inputProps={{ min: 0 }}
          />
        </Grid>
  
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Number of Nights"
            name="numberOfNights"
            type="number"
            value={formData.numberOfNights || ''}
            onChange={handleTextFieldChange}
            inputProps={{ min: 0 }}
          />
        </Grid>
  
        <Grid container item spacing={2}>
  <Grid item xs={12} md={6}>
    <DatePicker
      label="Arrival Date"
      value={formData.arrivalDate}
      onChange={(date) => handleDateChange('arrival', date)}
      slotProps={{
        textField: {
          fullWidth: true
        }
      }}
    />
  </Grid>
  <Grid item xs={12} md={6}>
    <DatePicker
      label="Departure Date"
      value={formData.departureDate}
      onChange={(date) => handleDateChange('departure', date)}
      slotProps={{
        textField: {
          fullWidth: true
        }
      }}
    />
  </Grid>
</Grid>
      </Grid>
  
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Cost Breakdown
      </Typography>
  
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <Typography variant="subtitle1">
              <HotelIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Accommodation
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Night"
                  type="number"
                  value={rates.accommodation || ''}
                  onChange={(e) => handleRateChange('accommodation', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Total: {formatCurrency(safeNumber(formData.numberOfPeople) * 
                          safeNumber(formData.numberOfNights) * 
                          safeNumber(rates.accommodation))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 4 }}>
            <Typography variant="subtitle1">
              <RestaurantIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Per Diems
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Rate per Day"
                  type="number"
                  value={rates.perDiem || ''}
                  onChange={(e) => handleRateChange('perDiem', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Total: {formatCurrency(safeNumber(formData.numberOfPeople) * 
                         (safeNumber(formData.numberOfNights)) * 
                         safeNumber(rates.perDiem))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
  <Box sx={{ mb: 3 }}>
    <Typography variant="subtitle1" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
      <FlightIcon sx={{ mr: 2 }} />
      Travel
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Flight Cost per Person
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={rates.flight || ''}
            onChange={(e) => handleRateChange('flight', e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            inputProps={{ min: 0, step: "0.01" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
            <DirectionsCarIcon sx={{ mr: 1 }} />
            Ground Transport
          </Typography>
          <TextField
            fullWidth
            type="number"
            value={rates.groundTransport || ''}
            onChange={(e) => handleRateChange('groundTransport', e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            inputProps={{ min: 0, step: "0.01" }}
          />
        </Box>
      </Grid>
    </Grid>
    <Typography variant="subtitle2" sx={{ mt: 2 }}>
      Total: {formatCurrency(safeNumber(formData.numberOfPeople) * 
              safeNumber(rates.flight) + 
              safeNumber(rates.groundTransport))}
    </Typography>
  </Box>
</Grid>

          {/* Grand Total */}
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 4 }}>
              Total Costs (Including Artist Fee): {formatCurrency(calculateTotalCost())}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ArtistTravelPlanner;