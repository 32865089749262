import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

interface TicketType {
  name: string;
  quantity_sold: number;
}

interface PastEvent {
  id: string;
  title: string;
  start_date: string;
  totalSold: number;
  totalRevenue: number;
  ticketTypes: TicketType[];
}

interface MonthlyData {
  month: string;
  revenue: number;
  tickets: number;
  events: number;
}

interface TicketTypeDistribution {
  earlyBird: number;
  performerPromo: number;
  generalAdmission: number;
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    name: string;
    value: number;
    color: string;
  }>;
  label?: string;
}

const PastEventsCharts: React.FC<{ pastEvents: PastEvent[] }> = ({ pastEvents }) => {
  // Sort events by revenue for better visualization
  const sortedByRevenue = [...pastEvents].sort((a, b) => b.totalRevenue - a.totalRevenue);

  // Calculate monthly aggregates
  const monthlyData = pastEvents.reduce((acc: Record<string, MonthlyData>, event) => {
    const month = new Date(event.start_date).toLocaleString('default', { month: 'short' });
    if (!acc[month]) {
      acc[month] = {
        month,
        revenue: 0,
        tickets: 0,
        events: 0
      };
    }
    acc[month].revenue += event.totalRevenue;
    acc[month].tickets += event.totalSold;
    acc[month].events += 1;
    return acc;
  }, {});

  const monthlyChartData = Object.values(monthlyData);

  // Calculate ticket type distribution
  const ticketTypeData = pastEvents.reduce((acc: TicketTypeDistribution, event) => {
    event.ticketTypes.forEach((type: TicketType) => {
      const category = type.name.toLowerCase();
      if (category.includes('early bird')) {
        acc.earlyBird += type.quantity_sold;
      } else if (category.includes('performer') || category.includes('promo')) {
        acc.performerPromo += type.quantity_sold;
      } else {
        acc.generalAdmission += type.quantity_sold;
      }
    });
    return acc;
  }, { earlyBird: 0, performerPromo: 0, generalAdmission: 0 });

  const pieChartData = [
    { name: 'Early Bird', value: ticketTypeData.earlyBird },
    { name: 'Performer Promo', value: ticketTypeData.performerPromo },
    { name: 'General Admission', value: ticketTypeData.generalAdmission }
  ];

  // Custom colors
  const colors = ['#36B37E', '#FF5630', '#00B8D9', '#6554C0', '#FFAB00'];

  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ p: 1 }}>
          <Typography variant="body2">{label}</Typography>
          {payload.map((entry, index) => (
            <Typography key={index} variant="body2" sx={{ color: entry.color }}>
              {entry.name}: {entry.value}
            </Typography>
          ))}
        </Paper>
      );
    }
    return null;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Revenue Distribution Chart */}
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Revenue Distribution by Event
        </Typography>
        <Box sx={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={sortedByRevenue}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="title" 
                angle={-45} 
                textAnchor="end"
                height={100}
                interval={0}
                fontSize={12}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="totalRevenue" fill="#36B37E" name="Revenue (€)" />
              <Bar dataKey="totalSold" fill="#00B8D9" name="Tickets Sold" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      {/* Ticket Types Distribution */}
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Ticket Types Distribution
        </Typography>
        <Box sx={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#8884d8"
                dataKey="value"
                label={({ name, value, percent }) => 
                  `${name}: ${((percent || 0) * 100).toFixed(1)}%`
                }
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      {/* Monthly Performance Chart */}
      <Paper elevation={2} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Monthly Performance Trends
        </Typography>
        <Box sx={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={monthlyChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line 
                yAxisId="left"
                type="monotone" 
                dataKey="revenue" 
                stroke="#36B37E" 
                name="Revenue (€)"
                strokeWidth={2}
              />
              <Line 
                yAxisId="right"
                type="monotone" 
                dataKey="tickets" 
                stroke="#00B8D9" 
                name="Tickets Sold"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default PastEventsCharts;
