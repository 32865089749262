// src/components/cooler/inventory/components/SmartNameField.tsx

import React from 'react';
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { PRODUCTION_ITEMS, ITEM_NAMES, getItemByName } from '../constants/NamesConstants';

interface SmartNameFieldProps {
  value: string;
  onChange: (value: string, item?: typeof PRODUCTION_ITEMS[number]) => void;
}

export const SmartNameField: React.FC<SmartNameFieldProps> = ({ value, onChange }) => {
  const selectedItem = getItemByName(value);

  return (
    <Autocomplete
      freeSolo
      value={value}
      onChange={(_, newValue) => {
        if (newValue) {
          const item = getItemByName(newValue);
          onChange(newValue, item);
        }
      }}
      options={ITEM_NAMES}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Item Name"
          required
          helperText={selectedItem ? "Using predefined item" : "Custom item name"}
        />
      )}
      renderOption={(props, option) => {
        const item = getItemByName(option);
        if (!item) return null;
        
        const Icon = item.icon;
        
        return (
          <Box 
            component="li" 
            {...props}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
              p: 2,
              '&:hover': {
                backgroundColor: 'action.hover'
              }
            }}
          >
            {Icon && <Icon color="action" />}
            <Box>
              <Typography variant="body1">{item.name}</Typography>
              <Typography variant="caption" color="text.secondary">
                {item.categories.join(' • ')} • {item.tags.join(' • ')}
              </Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
};