// src/pages/PerformanceDetailPage.tsx

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box, 
  Button, 
  CircularProgress, 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Alert 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

import BandEventInfo from '../components/performance/BandEventInfo';
import PerformanceRevenue from '../components/performance/PerformanceRevenue';
import PerformanceCosts from '../components/performance/PerformanceCosts';
import TicketSalesSection from '../components/performance/TicketSalesSection';
import ActionSection from '../components/performance/ActionSection';
import FinancialOverview from '../components/performance/FinancialOverview';
import NotesSection from '../components/performance/NotesSection';
import StaffRoster from '../components/performance/StaffRoster';

import { Performance } from '../types/mongodb';
import type { Strand } from '../types/Strand';

interface CostBreakdown {
  fee: number;
  travel: number;
  accommodation: number;
  perDiem: number;
  equipment: number;
  other: number;
  contingency: number;
}

const isFullStrand = (strand: string | Strand | undefined): strand is Strand => {
  return typeof strand === 'object' && strand !== null && 'startDate' in strand;
};

const PerformanceDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [performance, setPerformance] = useState<Performance | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [profitLoss, setProfitLoss] = useState<number>(0);
  const [ticketsSold, setTicketsSold] = useState<number>(0);
  const [ticketSales, setTicketSales] = useState<number>(0);
  const [costBreakdown, setCostBreakdown] = useState<CostBreakdown>({
    fee: 0,
    travel: 0,
    accommodation: 0,
    perDiem: 0,
    equipment: 0,
    other: 0,
    contingency: 0,
  });

  const calculateTotalCost = (data: Performance): number => {
    const { costs } = data;
    const baseCosts = 
      costs.bandFee +
      costs.travel.flights +
      costs.travel.groundTransport +
      costs.travel.other +
      (costs.accommodation.singleRooms + costs.accommodation.twinRooms * 2) * 
        costs.accommodation.costPerRoom * costs.accommodation.totalNights +
      costs.perDiem.dailyRate * costs.perDiem.numberOfDays +
      costs.equipment.rental +
      costs.equipment.shipping +
      costs.marketing +
      costs.insurance +
      costs.visas +
      costs.other;

    const personnelCosts = Object.values(costs.personnel).reduce((sum, fee) => sum + fee, 0);
    
    return baseCosts + personnelCosts;
  };

  const calculateTotalIncome = (data: Performance): number => {
    const { revenue } = data;
    return revenue.ticketPrice * revenue.expectedOccupancy +
           revenue.sponsorship +
           revenue.merchandiseSales +
           revenue.otherRevenue;
  };

  const fetchPerformance = useCallback(async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      const response = await axios.get<Performance>(
        `${process.env.REACT_APP_API_URL}/api/performances/${id}?populate=staff,strand`
      );
      setPerformance(response.data);
      updateFinancials(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching performance:', error);
      setError('Failed to fetch performance details');
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchPerformance();
  }, [fetchPerformance]);

  const updateFinancials = (data: Performance) => {
    const cost = calculateTotalCost(data);
    const contingencyAmount = cost * (data.contingencyRate / 100);
    const totalWithContingency = cost + contingencyAmount;
    
    setTotalCost(totalWithContingency);
    
    const income = calculateTotalIncome(data);
    setTotalIncome(income);
    setProfitLoss(income - totalWithContingency);

    setCostBreakdown({
      fee: data.costs.bandFee,
      travel: data.costs.travel.flights + data.costs.travel.groundTransport + data.costs.travel.other,
      accommodation: (data.costs.accommodation.singleRooms + data.costs.accommodation.twinRooms * 2) * 
                    data.costs.accommodation.costPerRoom * data.costs.accommodation.totalNights,
      perDiem: data.costs.perDiem.dailyRate * data.costs.perDiem.numberOfDays,
      equipment: data.costs.equipment.rental + data.costs.equipment.shipping,
      other: data.costs.marketing + data.costs.insurance + data.costs.visas + data.costs.other + 
             Object.values(data.costs.personnel).reduce((sum, fee) => sum + fee, 0),
      contingency: contingencyAmount,
    });

    setTicketsSold(Math.floor(data.revenue.expectedOccupancy));
    setTicketSales(data.revenue.ticketPrice * data.revenue.expectedOccupancy);
  };

  const handleStaffAssignment = async (updatedPerformance: Performance) => {
    setPerformance(updatedPerformance);
    updateFinancials(updatedPerformance);
  };

  const handleUpdate = (updatedPerformance: Performance) => {
    setPerformance(updatedPerformance);
    updateFinancials(updatedPerformance);
  };

  const handleSave = async () => {
    if (!performance) return;

    try {
      const response = await axios.put<Performance>(
        `${process.env.REACT_APP_API_URL}/api/performances/${performance._id}`,
        performance
      );
      setPerformance(response.data);
      updateFinancials(response.data);
      alert('Changes saved successfully.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes.');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  if (!performance) {
    return null;
  }

  const hasStrand = performance.strandId !== undefined;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Performance Details
      </Typography>

      {!hasStrand && (
        <Alert severity="info" sx={{ mb: 3 }}>
          This performance is not currently assigned to any strand.
        </Alert>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <BandEventInfo 
            performance={performance}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            {hasStrand && isFullStrand(performance.strandId) && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Strand Dates
                </Typography>
                <Typography>
                  Start: {new Date(performance.strandId.startDate).toLocaleDateString()}
                </Typography>
                <Typography>
                  End: {new Date(performance.strandId.endDate).toLocaleDateString()}
                </Typography>
              </Box>
            )}
            <Box mt={2}>
              <ActionSection />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box mt={4}>
        <FinancialOverview 
          totalIncome={totalIncome}
          totalCost={totalCost}
          profitLoss={profitLoss}
        />
      </Box>

      <Accordion sx={{ mt: 4 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Financial Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <PerformanceRevenue 
                performance={performance}
                onUpdate={handleUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PerformanceCosts
                performance={performance}
                onUpdate={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TicketSalesSection 
                ticketsSold={ticketsSold}
                ticketSales={ticketSales}
                totalIncome={totalIncome}
                totalCost={totalCost}
                costBreakdown={costBreakdown}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <StaffRoster 
            performanceId={performance._id}
            onAssignmentComplete={handleStaffAssignment}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <NotesSection notes={performance.notes || ''} />
        </Grid>
      </Grid>

      <Box sx={{ textAlign: 'right', mt: 4 }}>
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => navigate(`/performances/edit/${performance._id}`)}
        >
          Edit Performance Details
        </Button>
        <Button variant="contained" onClick={handleSave} sx={{ mr: 2 }}>
          Save Changes
        </Button>
        <Button variant="contained" onClick={() => navigate('/admin/performances')}>
          Back to Performances
        </Button>
      </Box>
    </Container>
  );
};

export default PerformanceDetailPage;