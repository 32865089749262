// src/components/cooler/inventory/components/ItemDetails/ViewDetails.tsx

import React from 'react';
import {
  Stack,
  Grid,
  Paper,
  Typography,
  Chip,
  Box,
} from '@mui/material';
import {
  Tag as CategoryIcon,
  MapPin as LocationIcon,
  Euro,
  Power,
  AlertTriangle,
  CheckCircle,
  Clock,
} from 'lucide-react';

import type { InventoryItem } from '../../../types/inventoryTypes';
import { formatCurrency } from './utils';

interface ViewDetailsProps {
  item: InventoryItem;
}

const ViewDetails: React.FC<ViewDetailsProps> = ({ item }) => {
  const getMaintenanceStatusChip = () => {
    const config = {
      'ok': { color: 'success' as const, icon: <CheckCircle size={16} /> },
      'due': { color: 'warning' as const, icon: <Clock size={16} /> },
      'overdue': { color: 'error' as const, icon: <AlertTriangle size={16} /> }
    };

    const status = item.maintenanceStatus;
    if (!status || !config[status]) return null;

    return (
      <Chip
        size="small"
        icon={config[status].icon}
        label={status.toUpperCase()}
        color={config[status].color}
      />
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={3}>
        {/* Category and Location Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              bgcolor: 'background.default'
            }}
          >
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Category
              </Typography>
              <Chip
                icon={<CategoryIcon size={16} />}
                label={item.category}
                size="small"
              />
            </Box>

            <Box>
  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
    Location
  </Typography>
  <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
    {/* Primary Location */}
    <Chip
      icon={<LocationIcon size={16} />}
      label={item.primaryLocation}
      size="small"
    />
    
    {/* Second Location (Shelf/Drawer) */}
    {item.secondLocation && (
      <Chip
        size="small"
        variant="outlined"
        label={item.secondLocation}
        sx={{ 
          borderStyle: 'dashed'
        }}
      />
    )}
    
    {/* Secondary Location (Alternative) */}
    {item.secondaryLocation && item.secondaryLocation !== 'N/A' && (
      <Chip
        icon={<LocationIcon size={16} />}
        label={`Alt: ${item.secondaryLocation}`}
        size="small"
        variant="outlined"
        color="info"
      />
    )}
  </Stack>
</Box>
          </Paper>
        </Grid>

        {/* Status Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              bgcolor: 'background.default'
            }}
          >
            <Box>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Status
              </Typography>
              <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                {getMaintenanceStatusChip()}
                {item.isInsured ? (
                  <Chip
                    size="small"
                    icon={<Euro size={16} />}
                    label="Insured"
                    color="success"
                  />
                ) : (item.purchaseValue || 0) >= 500 ? (
                  <Chip
                    size="small"
                    icon={<AlertTriangle size={16} />}
                    label="Insurance Required"
                    color="error"
                  />
                ) : null}
                {item.requiresPower && (
                  <Chip
                    size="small"
                    icon={<Power size={16} />}
                    label={`Power: ${item.powerLocation || 'Required'}`}
                    color="warning"
                  />
                )}
              </Stack>
            </Box>

            {item.serialNumber && (
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Serial Number
                </Typography>
                <Typography variant="body2">
                  {item.serialNumber}
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Description Section */}
        <Grid item xs={12}>
          <Paper 
            sx={{ 
              p: 3,
              bgcolor: 'background.default'
            }}
          >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1">
              {item.description || 'No description provided'}
            </Typography>
          </Paper>
        </Grid>

        {/* Purchase Info Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              bgcolor: 'background.default'
            }}
          >
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Purchase Info
            </Typography>
            <Stack spacing={2}>
              <Box>
                <Typography variant="body2" color="text.secondary">Purchase Date</Typography>
                <Typography>
                  {item.purchaseDate ? new Date(item.purchaseDate).toLocaleDateString() : 'N/A'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="text.secondary">Vendor</Typography>
                <Typography>{item.vendor || 'N/A'}</Typography>
              </Box>
              {item.purchaseValue && (
                <Box>
                  <Typography variant="body2" color="text.secondary">Purchase Value</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography>{formatCurrency(item.purchaseValue)}</Typography>
                    {!item.isInsured && item.purchaseValue >= 500 && (
                      <Typography 
                        variant="caption" 
                        color="error.main" 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 0.5 
                        }}
                      >
                        <AlertTriangle size={14} />
                        Insurance required
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {item.currentValue && (
                <Box>
                  <Typography variant="body2" color="text.secondary">Current Value</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography>{formatCurrency(item.currentValue)}</Typography>
                    {!item.isInsured && item.currentValue >= 500 && (
                      <Typography 
                        variant="caption" 
                        color="error.main" 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 0.5 
                        }}
                      >
                        <AlertTriangle size={14} />
                        Insurance required
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Stack>
          </Paper>
        </Grid>

        {/* Quantity and Tags Section */}
        <Grid item xs={12} md={6}>
          <Paper 
            sx={{ 
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              bgcolor: 'background.default'
            }}
          >
            {item.quantity > 1 && (
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Quantity
                </Typography>
                <Typography>{item.quantity}</Typography>
              </Box>
            )}
            
            {item.tags && item.tags.length > 0 && (
              <Box>
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Tags
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                  {item.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      size="small"
                      variant="outlined"
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewDetails;