// src/routes/guestRoutes.tsx

import React from 'react';
import { RouteObject } from 'react-router-dom';
import withAuth from '../hoc/withAuth';
import GuestLayout from '../components/layout/GuestLayout';


// Import all guest-accessible components
import Dashboard from '../pages/Dashboard';
import BandListPage from '../pages/BandListPage';
import BandDetailPage from '../pages/BandDetailPage';
import EventListPage from '../pages/EventListPage';
import EventDetailPage from '../pages/EventDetailPage';
import VenueListPage from '../pages/VenueListPage';
import VenueDetailPage from '../pages/VenueDetailPage';
import PerformancesPage from '../pages/PerformanceListPage';
import PerformanceDetailPage from '../pages/PerformanceDetailPage';
import CalendarComponent from '../components/CalendarComponent';
import TitoApiTestPage from '../pages/TitoApiTestPage';
import StrandListPage from '../pages/StrandListPage';
import StrandDetailPage from '../pages/StrandDetailPage';
import PublishedBandListPage from '../pages/PublishedBandListPage';
import StrandBandView from '../components/bandpage/StrandBandView';
import SingleTitoEventPage from '../pages/SingleTitoEventPage';
import SubmissionSuccess from '../pages/SubmissionSuccess';
import CoolerInventoryPage from '../pages/CoolerInventoryPage';
import TaskBoardPage from '../pages/taskboard/TaskBoardPage';
import CoolerDashboardPage from '../pages/CoolerDashboardPage';
import ExpenseListPage from '../pages/ExpenseListPage';
import ExpenseForm from '../components/ExpenseForm';

// Wrap components with withAuth HOC
const ProtectedDashboard = withAuth(Dashboard, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedBandListPage = withAuth(BandListPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedBandDetailPage = withAuth(BandDetailPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedEventListPage = withAuth(EventListPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedEventDetailPage = withAuth(EventDetailPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedVenueListPage = withAuth(VenueListPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedVenueDetailPage = withAuth(VenueDetailPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedPerformancesPage = withAuth(PerformancesPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedPerformanceDetailPage = withAuth(PerformanceDetailPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedCalendarComponent = withAuth(CalendarComponent, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedTitoApiTestPage = withAuth(TitoApiTestPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedStrandListPage = withAuth(StrandListPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedStrandDetailPage = withAuth(StrandDetailPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedCoolerInventoryPage = withAuth(CoolerInventoryPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedTaskBoardPage = withAuth(TaskBoardPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedCoolerDashboardPage = withAuth(CoolerDashboardPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedExpenseListPage = withAuth(ExpenseListPage, { allowedRoles: ['guest', 'domain_user', 'artist'] });
const ProtectedExpenseForm = withAuth(ExpenseForm, { allowedRoles: ['guest', 'domain_user', 'artist'] });

export const guestRoutes: RouteObject[] = [
    {
      element: <GuestLayout />,
      children: [
        { path: '/', element: <ProtectedDashboard /> },
        { path: '/dashboard', element: <ProtectedDashboard /> },
        { path: '/bands', element: <ProtectedBandListPage /> },
        { path: '/bands/:id', element: <ProtectedBandDetailPage /> },
        { path: '/events', element: <ProtectedEventListPage /> },
        { path: '/events/:id', element: <ProtectedEventDetailPage /> },
        { path: '/venues', element: <ProtectedVenueListPage /> },
        { path: '/venues/:id', element: <ProtectedVenueDetailPage /> },
        { path: '/performances', element: <ProtectedPerformancesPage /> },
        { path: '/performances/:id', element: <ProtectedPerformanceDetailPage /> },
        { path: '/calendar', element: <ProtectedCalendarComponent /> },
        { path: '/sales', element: <ProtectedTitoApiTestPage /> },
        { path: '/strands', element: <ProtectedStrandListPage /> },
        { path: '/strands/:id', element: <ProtectedStrandDetailPage /> },
        { path: '/publicstrands', element: <PublishedBandListPage /> },
        { path: '/submission-success', element: <SubmissionSuccess /> },
        { 
            path: '/strands/:strandSlug/band/:bandId', 
            element: <StrandBandView />  // NEW
          },
                  { path: '/event/:slug', element: <SingleTitoEventPage /> },
        { path: '/admin/cooler', element: <ProtectedCoolerDashboardPage /> },
        { path: '/admin/inventory', element: <ProtectedCoolerInventoryPage /> },
        { path: '/admin/taskboard', element: <ProtectedTaskBoardPage /> },
        { path: '/admin/expenses', element: <ProtectedExpenseListPage /> },
        { path: '/admin/expenses/new', element: <ProtectedExpenseForm /> },
      ],
    },
  ];