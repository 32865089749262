// store/budgetSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  ArtistDetails, 
  VenueDetails, 
  ProductionDetails, 
  MarketingDetails, 
  OtherExpensesDetails, 
  IncomeDetails,
  Budget,
  BudgetItem,
  BudgetGroup
} from '../types/Budget';
import { Venue } from '../types/mongodb';  // Add this import

interface BudgetState {
  _id?: string;
  budget: Budget | null;
  artist: ArtistDetails | null;
  venues: Venue[];  // Add this
  venue: VenueDetails | null;
  production: ProductionDetails | null;
  incomeDetails: IncomeDetails;
  associations: { 
    venues: string[]; 
    performances: string[]; 
    personnel: string[]; 
    bands: string[]; 
  };
  marketing: MarketingDetails | null;
  otherExpenses: OtherExpensesDetails | null;
  income: IncomeDetails | null;
  expenseGroups: {
    artistFees: BudgetGroup;
    production: BudgetGroup;
    marketing: BudgetGroup;
    staffing: BudgetGroup;
    venue: BudgetGroup;
    technical: BudgetGroup;
    administrative: BudgetGroup;
  };
  incomeGroups: {
    ticketing: BudgetGroup;
    grants: BudgetGroup;
    sponsorship: BudgetGroup;
    other: BudgetGroup;
  };
  totals: {
    expenses: number;
    income: number;
    profit: number;
    contingency: number;
  };
  ui: {
    activeTab: number;
    unsavedChanges: boolean;
    loading: boolean;
    error: string | null;
    mode: 'create' | 'edit' | 'view';
  };
}

const defaultBudgetGroup: BudgetGroup = {
  name: '',
  items: [],
  total: 0,
  category: 'expense'
};

const initialState: BudgetState = {
    artist: null,
    venue: null,
    venues: [],  // Add this
    production: null,
    marketing: null,
    budget: null,

    expenseGroups: {
        artistFees: { ...defaultBudgetGroup, name: 'Artist Fees' },
        production: { ...defaultBudgetGroup, name: 'Production' },
        marketing: { ...defaultBudgetGroup, name: 'Marketing' },
        staffing: { ...defaultBudgetGroup, name: 'Staffing' },
        venue: { ...defaultBudgetGroup, name: 'Venue' },
        technical: { ...defaultBudgetGroup, name: 'Technical' },
        administrative: { ...defaultBudgetGroup, name: 'Administrative' }
    },
    incomeGroups: {
        ticketing: { ...defaultBudgetGroup, name: 'Ticketing', category: 'income' },
        grants: { ...defaultBudgetGroup, name: 'Grants', category: 'income' },
        sponsorship: { ...defaultBudgetGroup, name: 'Sponsorship', category: 'income' },
        other: { ...defaultBudgetGroup, name: 'Other', category: 'income' }
    },
    totals: {
        expenses: 0,
        income: 0,
        profit: 0,
        contingency: 0
    },
    associations: {
        venues: [],
        performances: [],
        personnel: [],
        bands: []
    },
    otherExpenses: null,
    income: null,
    ui: {
        activeTab: 0,
        unsavedChanges: false,
        loading: true,
        error: null,
        mode: 'create'
    },
    incomeDetails: {
        ticketing: {
            tiers: [],
            totalProjected: 0
        },
        funding: {
            sources: []
        }
    }
};

const calculateExpenseGroups = (state: BudgetState): BudgetState['expenseGroups'] => {
    // Artist fees and travel
    const artistItems: BudgetItem[] = [];
    if (state.artist?.costs) {
      if (state.artist.costs.artistFees) artistItems.push(state.artist.costs.artistFees);
      if (state.artist.costs.travel) artistItems.push(state.artist.costs.travel);
      if (state.artist.costs.accommodation) artistItems.push(state.artist.costs.accommodation);
      if (state.artist.costs.perDiems) artistItems.push(state.artist.costs.perDiems);
    }
  
    // Venue costs
    const venueItems: BudgetItem[] = [];
    if (state.venue?.costs) {
      if (state.venue.costs.venueHire) venueItems.push(state.venue.costs.venueHire);
      if (state.venue.costs.technical) venueItems.push(state.venue.costs.technical);
      if (state.venue.costs.staffing) venueItems.push(state.venue.costs.staffing);
    }
  
    // Production costs
    const productionItems = state.production?.costs?.personnel || [];
  
    // Marketing costs
    const marketingItems: BudgetItem[] = state.marketing?.costs ? 
      Object.values(state.marketing.costs).filter((item): item is BudgetItem => !!item) : 
      [];
  
    return {
        artistFees: {
            name: 'Artist Fees',
            items: artistItems, // Include ALL artist items, not just fees
            total: artistItems.reduce((sum, item) => sum + (item.amount || 0), 0),
            category: 'expense' as const
          },
      production: {
        name: 'Production',
        items: productionItems,
        total: productionItems.reduce((sum, item) => sum + (item.amount || 0), 0),
        category: 'expense' as const
      },
      marketing: {
        name: 'Marketing',
        items: marketingItems,
        total: marketingItems.reduce((sum, item) => sum + (item.amount || 0), 0),
        category: 'expense' as const
      },
      staffing: {
        name: 'Staffing',
        items: [state.venue?.costs?.staffing].filter((item): item is BudgetItem => !!item),
        total: state.venue?.costs?.staffing?.amount || 0,
        category: 'expense' as const
      },
      venue: {
        name: 'Venue',
        items: [state.venue?.costs?.venueHire].filter((item): item is BudgetItem => !!item),
        total: state.venue?.costs?.venueHire?.amount || 0,
        category: 'expense' as const
      },
      technical: {
        name: 'Technical',
        items: [state.venue?.costs?.technical].filter((item): item is BudgetItem => !!item),
        total: state.venue?.costs?.technical?.amount || 0,
        category: 'expense' as const
      },
      administrative: {
        name: 'Administrative',
        items: [],
        total: 0,
        category: 'expense' as const
      }
    };
  };
  
  const calculateIncomeGroups = (state: BudgetState): BudgetState['incomeGroups'] => {
    return {
      ticketing: {
        name: 'Ticketing',
        items: state.income?.ticketing?.tiers?.map(tier => tier.budgetItem) || [],
        total: state.income?.ticketing?.totalProjected || 0,
        category: 'income' as const
      },
      grants: {
        name: 'Grants',
        items: state.income?.funding?.sources
          ?.filter(source => source.agency.includes('Grants'))
          ?.map(source => source.budgetItem) || [],
        total: 0,
        category: 'income' as const
      },
      sponsorship: {
        name: 'Sponsorship',
        items: state.income?.funding?.sources
          ?.filter(source => source.agency.includes('Sponsorship'))
          ?.map(source => source.budgetItem) || [],
        total: 0,
        category: 'income' as const
      },
      other: {
        name: 'Other Income',
        items: [],
        total: 0,
        category: 'income' as const
      }
    };
  };
  
  const calculateTotals = (
    expenseGroups: BudgetState['expenseGroups'], 
    incomeGroups: BudgetState['incomeGroups']
  ): BudgetState['totals'] => {
    const totalExpenses = Object.values(expenseGroups)
      .reduce((sum, group) => sum + group.total, 0);
      
    const totalIncome = Object.values(incomeGroups)
      .reduce((sum, group) => sum + group.total, 0);
  
    return {
      expenses: totalExpenses,
      income: totalIncome,
      profit: totalIncome - totalExpenses,
      contingency: totalExpenses * 0.1 // 10% contingency
    };
  };
  
  const updateBudgetState = (state: BudgetState) => {
    const expenseGroups = calculateExpenseGroups(state);
    const incomeGroups = calculateIncomeGroups(state);
    const totals = calculateTotals(expenseGroups, incomeGroups);
  
    state.expenseGroups = expenseGroups;
    state.incomeGroups = incomeGroups;
    state.totals = totals;
  
    if (state.budget) {
      state.budget = {
        ...state.budget,
        expenseGroups,
        incomeGroups,
        totals
      };
    }
  };

const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setBudget: (state, action: PayloadAction<Budget>) => {
        console.log('Setting budget in reducer:', action.payload);
      state.budget = action.payload;
      state.artist = action.payload.artistDetails;
      state.venue = action.payload.venueDetails;
      state.production = action.payload.productionDetails;
      state.marketing = action.payload.marketingDetails;
      state.otherExpenses = action.payload.otherExpenses;
      state.income = action.payload.incomeDetails;
      
      updateBudgetState(state);
      state.ui.loading = false;
      state.ui.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.ui.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.ui.error = action.payload;
      state.ui.loading = false;
    },
    updateArtistDetails: (state, action: PayloadAction<ArtistDetails>) => {
      state.artist = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    setVenues: (state, action: PayloadAction<Venue[]>) => {
        state.venues = action.payload;
    },
    updateVenueDetails: (state, action: PayloadAction<VenueDetails>) => {
      state.venue = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    updateProductionDetails: (state, action: PayloadAction<ProductionDetails>) => {
      state.production = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    updateMarketingDetails: (state, action: PayloadAction<MarketingDetails>) => {
      state.marketing = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    updateOtherExpenses: (state, action: PayloadAction<OtherExpensesDetails>) => {
      state.otherExpenses = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    updateIncomeDetails: (state, action: PayloadAction<IncomeDetails>) => {
      state.income = action.payload;
      updateBudgetState(state);
      state.ui.unsavedChanges = true;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.ui.activeTab = action.payload;
    },
    saveBudgetSuccess: (state) => {
      state.ui.unsavedChanges = false;
    },
    clearBudget: (state) => {
        return initialState;
      },
    clearBudgetState: (state) => {
        return {
          ...initialState,
          ui: {
            ...initialState.ui,
            loading: false
          }
        };
      },
  
      setMode: (state, action: PayloadAction<'create' | 'edit' | 'view'>) => {
        state.ui.mode = action.payload;
      }
  }
});

export const {
  setBudget,
  setLoading,
  setError,
  setVenues,       // Add this
  updateArtistDetails,
  updateVenueDetails,
  updateProductionDetails,
  updateMarketingDetails,
  updateOtherExpenses,
  updateIncomeDetails,
  setActiveTab,
  saveBudgetSuccess,
  clearBudget,
  clearBudgetState,
  setMode
} = budgetSlice.actions;

export default budgetSlice.reducer;