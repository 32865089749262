import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
  SelectChangeEvent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Snackbar
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CustomNumberInput from './CustomNumberInput';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig';

// Type definitions
interface Band {
  _id: string;
  artistName: string;
  name?: string;
}

interface Performance {
  _id: string;
  band: Band | string;
  name?: string;
}

interface Event {
  _id: string;
  name: string;
}

interface Venue {
  _id: string;
  name: string;
  capacity: number;
}

interface Strand {
  _id: string;
  name: string;
  year: number;
}

interface Prospect {
  _id: string;
  strandId: string;
  date: string;
  eventConcept: string;
  potentialArtists: string[];
  venueIdeas: string[];
  fees: number;
  boxOffice: number;
  expenses: number;
  sponsorship: number;
  net: number;
  notes: string;
  status: string;
}

interface TicketType {
  name: string;
  quantity: number;
  price: number;
  vatRate: number;
  vatExempt: boolean;
  revenueImpact: boolean;
}

const VAT_RATES = {
  STANDARD: 0.23, // 23% VAT rate
  REDUCED: 0.135, // 13.5% VAT rate
  EXEMPT: 0 // VAT exempt
};

const TicketConfigPage: React.FC = () => {
  // State declarations
  const [events, setEvents] = useState<Event[]>([]);
  const [performances, setPerformances] = useState<Performance[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [selectedStrand, setSelectedStrand] = useState<Strand | null>(null);
  const [selectedPerformance, setSelectedPerformance] = useState<Performance | null>(null);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [isSaving, setIsSaving] = useState(false);
const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([
    { 
      name: 'Early Bird', 
      quantity: 0, 
      price: 0, 
      revenueImpact: true,
      vatRate: VAT_RATES.REDUCED,
      vatExempt: false
    },
    { 
      name: 'Performer Promo', 
      quantity: 0, 
      price: 0, 
      revenueImpact: true,
      vatRate: VAT_RATES.REDUCED,
      vatExempt: false
    },
    { 
      name: 'Guests', 
      quantity: 0, 
      price: 0, 
      revenueImpact: false,
      vatRate: VAT_RATES.EXEMPT,
      vatExempt: true
    },
    { 
      name: 'General Admission', 
      quantity: 0, 
      price: 0, 
      revenueImpact: true,
      vatRate: VAT_RATES.REDUCED,
      vatExempt: false
    }
  ]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectionMethod, setSelectionMethod] = useState<'strand' | 'event'>('strand');
  const [prospects, setProspects] = useState<Prospect[]>([]);
  const [selectedProspect, setSelectedProspect] = useState<Prospect | null>(null);
  const [showProspects, setShowProspects] = useState(false);

const calculateVatAmount = (price: number, vatRate: number): number => {
  return price - (price / (1 + vatRate));
};

const calculateNetPrice = (price: number, vatRate: number): number => {
  return price / (1 + vatRate);
};

// Add this function to fetch prospects for a strand
const fetchProspectsByStrand = async (strandId: string) => {
  try {
    const res = await axios.get<Prospect[]>(
      `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/prospects`
    );
    setProspects(res.data);
    setError(null);
  } catch (error) {
    console.error('Error fetching prospects:', error);
    setError('Failed to fetch prospects for the selected strand.');
    setProspects([]);
  }
};

  // Initial data fetch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventsRes, venuesRes, strandsRes] = await Promise.all([
          axios.get<Event[]>(`${process.env.REACT_APP_API_URL}/api/events`),
          axios.get<Venue[]>(`${process.env.REACT_APP_API_URL}/api/venues`),
          axios.get<Strand[]>(`${process.env.REACT_APP_API_URL}/api/strands`)
        ]);
        
        setEvents(eventsRes.data);
        setVenues(venuesRes.data);
        setStrands(strandsRes.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load initial data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const loadExistingConfig = async () => {
      if (selectionMethod === 'strand') {
        if (!selectedStrand || !selectedProspect || !selectedVenue) return;
      } else {
        if (!selectedEvent  || !selectedVenue) return;
      }
  
      const params = new URLSearchParams();
      params.append('selectionMethod', selectionMethod);
      if (selectionMethod === 'strand') {
        if (selectedStrand?._id) params.append('strandId', selectedStrand._id);
        if (selectedProspect?._id) params.append('prospectId', selectedProspect._id);
      } else {
        if (selectedEvent?._id) params.append('eventId', selectedEvent._id);
      }
      if (selectedPerformance?._id) params.append('performanceId', selectedPerformance._id);
      if (selectedVenue?._id) params.append('venueId', selectedVenue._id);
  
      const url = `/api/ticket-configs/search?${params.toString()}`;
      console.log('Searching with URL:', url);
  
      try {
        const res = await axiosInstance.get(url);
        const existingConfig = res.data;
        console.log('Found config:', existingConfig);
        
        if (existingConfig && existingConfig.ticketTypes) {
          setTicketTypes(existingConfig.ticketTypes);
        }
      } catch (error: any) {
        console.error('Error loading existing configuration:', error);
      }
    };
  
    loadExistingConfig();
  }, [selectionMethod, selectedStrand, selectedProspect, selectedEvent, selectedPerformance, selectedVenue]);

  // Add this function for fetching performances for a strand
const fetchPerformancesByStrand = async (strandId: string) => {
  try {
    const res = await axios.get<Performance[]>(
      `${process.env.REACT_APP_API_URL}/api/strands/${strandId}/performances`
    );
    setPerformances(res.data);
    setError(null);
  } catch (error) {
    console.error('Error fetching performances:', error);
    setError('Failed to fetch performances for the selected strand.');
    setPerformances([]);
  }
};

  // Handler for selection method change
  const handleSelectionMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method = event.target.value as 'strand' | 'event';
    setSelectionMethod(method);
    // Reset selections
    setSelectedStrand(null);
    setSelectedEvent(null);
    setSelectedPerformance(null);
    setSelectedVenue(null);
    setPerformances([]);
  };

// Modify your handleStrandChange to include prospect fetching
const handleStrandChange = async (event: SelectChangeEvent<string>) => {
  const strandId = event.target.value;
  const strand = strands.find(s => s._id === strandId) || null;
  setSelectedStrand(strand);
  setSelectedProspect(null);
  setSelectedPerformance(null);
  setSelectedVenue(null);

  if (strand) {
    try {
      await Promise.all([
        fetchPerformancesByStrand(strandId),
        fetchProspectsByStrand(strandId)
      ]);
      setShowProspects(true);
    } catch (error) {
      console.error('Error fetching strand data:', error);
      setError('Failed to fetch strand data.');
    }
  }
};

// Add handler for prospect selection
const handleProspectChange = (event: SelectChangeEvent<string>) => {
  const prospectId = event.target.value;
  const prospect = prospects.find(p => p._id === prospectId) || null;
  setSelectedProspect(prospect);
  setSelectedPerformance(null);
  setSelectedVenue(null);

  // If you want to pre-fill some ticket data based on prospect info:
  if (prospect) {
    const newTicketTypes = ticketTypes.map(ticket => ({
      ...ticket,
      // You could set default prices or quantities based on prospect data
      price: ticket.name === 'General Admission' ? prospect.boxOffice / 100 : ticket.price
    }));
    setTicketTypes(newTicketTypes);
  }
};


  // Handler for event selection
  const handleEventChange = (event: SelectChangeEvent<string>) => {
    const eventId = event.target.value;
    const selectedEvent = events.find(e => e._id === eventId) || null;
    setSelectedEvent(selectedEvent);
    setSelectedPerformance(null);
    setSelectedVenue(null);
    // Here you would typically fetch performances for the selected event
  };

  // Handler for performance selection
  const handlePerformanceChange = (event: SelectChangeEvent<string>) => {
    const performanceId = event.target.value;
    const performance = performances.find(p => p._id === performanceId) || null;
    setSelectedPerformance(performance);
    setSelectedVenue(null);
  };

  // Handler for venue selection
  const handleVenueChange = (event: SelectChangeEvent<string>) => {
    const venueId = event.target.value;
    const venue = venues.find(v => v._id === venueId) || null;
    setSelectedVenue(venue);
    if (venue) {
      autoAllocateGeneralAdmission(ticketTypes, venue.capacity);
    }
  };

  // Helper function to get performance name
  const getPerformanceName = (performance: Performance): string => {
    if (typeof performance.band === 'string') {
      return performance.band;
    }
    return performance.band.artistName || performance.band.name || 'Unnamed Band';
  };

  const autoAllocateGeneralAdmission = (tickets: TicketType[], capacity: number) => {
    // Remove revenueImpact check - we want ALL tickets (including guests) to count against capacity
    const totalAllocated = tickets
      .filter(ticket => ticket.name !== 'General Admission')
      .reduce((sum, ticket) => sum + ticket.quantity, 0);
    
    const generalAdmission = Math.max(0, capacity - totalAllocated);
  
    const updatedTickets = tickets.map(ticket => 
      ticket.name === 'General Admission' 
        ? { ...ticket, quantity: generalAdmission }
        : ticket
    );
  
    setTicketTypes(updatedTickets);
  };

  const handleTicketChange = (index: number, field: keyof TicketType, value: number | boolean) => {
    const newTickets = ticketTypes.map((ticket, i) => {
      if (i === index) {
        return { ...ticket, [field]: value };
      }
      return ticket;
    });
    setTicketTypes(newTickets);
  
    if (selectedVenue) {
      autoAllocateGeneralAdmission(newTickets, selectedVenue.capacity);
    }
  };

  // Calculate total revenue
  const calculateRevenue = () => {
    const totals = ticketTypes
      .filter(ticket => ticket.revenueImpact)  // Only calculate for revenue-impacting tickets
      .reduce((acc, ticket) => {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
        const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
  
        return {
          gross: acc.gross + grossRevenue,
          vat: acc.vat + vatAmount,
          net: acc.net + netRevenue
        };
      }, { gross: 0, vat: 0, net: 0 });
  
    return totals;
  };

  // Calculate total tickets
  const calculateTotalTickets = (): number => {
    return ticketTypes.reduce((sum, ticket) => sum + ticket.quantity, 0);
  };

  const getChartData = () => {
    return ticketTypes
      .filter(ticket => ticket.revenueImpact)
      .map(ticket => {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
        const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
  
        return {
          name: ticket.name,
          'Gross Revenue': grossRevenue,
          'VAT Amount': vatAmount,
          'Net Revenue': netRevenue
        };
      });
  };

  const handleSaveConfig = async () => {
    if (!selectedVenue) {
      setError('You must select a venue before saving.');
      return;
    }
  
    const dataToSave = {
      selectionMethod,
      strandId: selectedStrand?._id || null,
      prospectId: selectedProspect?._id || null,
      eventId: selectedEvent?._id || null,
      performanceId: selectedPerformance?._id || null,
      venueId: selectedVenue._id,
      ticketTypes: ticketTypes
    };
  
    setIsSaving(true);
    setError(null);
    setSuccessMessage(null);
  
    try {
      const res = await axiosInstance.post('/api/ticket-configs', dataToSave);
      setSuccessMessage('Configuration saved successfully!');
    } catch (error: any) {
      console.error('Error saving configuration:', error);
      setError('Failed to save ticket configuration.');
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: '100%', overflow: 'hidden' }}>
      <Typography variant="h4" gutterBottom>
        Ticket Configuration and Revenue Projection
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Selection Method */}
      <FormControl component="fieldset" sx={{ mb: 3 }}>
        <FormLabel component="legend">Select By</FormLabel>
        <RadioGroup
          row
          value={selectionMethod}
          onChange={handleSelectionMethodChange}
        >
          <FormControlLabel value="strand" control={<Radio />} label="Strand" />
          <FormControlLabel value="event" control={<Radio />} label="Event" />
        </RadioGroup>
      </FormControl>

      {/* Strand/Event Selection */}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>
          {selectionMethod === 'strand' ? 'Select Strand' : 'Select Event'}
        </InputLabel>
        <Select
          value={(selectionMethod === 'strand' ? selectedStrand?._id : selectedEvent?._id) || ''}
          onChange={selectionMethod === 'strand' ? handleStrandChange : handleEventChange}
          label={selectionMethod === 'strand' ? 'Select Strand' : 'Select Event'}
        >
          <MenuItem value="">
            <em>Select a {selectionMethod}</em>
          </MenuItem>
          {(selectionMethod === 'strand' ? strands : events).map(item => (
  <MenuItem key={item._id} value={item._id}>
    {selectionMethod === 'strand' 
      ? `${item.name} (${(item as Strand).year})` 
      : item.name
    }
  </MenuItem>
))}
        </Select>
      </FormControl>

      {selectionMethod === 'strand' && showProspects && (
  <FormControl 
    fullWidth 
    sx={{ mb: 2, '& .MuiFormHelperText-root': { mt: 2 } }}
    disabled={!selectedStrand}
  >
    <InputLabel>Select Prospect</InputLabel>
    <Select
      value={selectedProspect?._id || ''}
      onChange={handleProspectChange}
      label="Select Prospect"
    >
      <MenuItem value="">
        <em>Select a prospect</em>
      </MenuItem>
      {prospects.map(prospect => (
        <MenuItem key={prospect._id} value={prospect._id}>
          {prospect.eventConcept} - {new Date(prospect.date).toLocaleDateString()} 
          ({prospect.status})
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>
      {prospects.length === 0 
        ? 'No prospects available' 
        : 'Select a prospect to continue'}
    </FormHelperText>
  </FormControl>
)}

      {/* Performance Selection */}
      <FormControl 
        fullWidth 
        sx={{ mb: 2, '& .MuiFormHelperText-root': { mt: 2 } }}
        disabled={
          (selectionMethod === 'strand' && !selectedStrand) ||
          (selectionMethod === 'event' && !selectedEvent)
        }
      >
        <InputLabel>Select Performance</InputLabel>
        <Select
          value={selectedPerformance?._id || ''}
          onChange={handlePerformanceChange}
          label="Select Performance"
        >
          <MenuItem value="">
            <em>Select a performance</em>
          </MenuItem>
          {performances.map(perf => (
            <MenuItem key={perf._id} value={perf._id}>
              {getPerformanceName(perf)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {performances.length === 0 
            ? 'No performances available' 
            : 'Select a performance to continue'}
        </FormHelperText>
      </FormControl>

      <FormControl 
  fullWidth 
  sx={{ mb: 3 }}
  disabled={!selectedPerformance && !selectedProspect} // Allow either performance OR prospect
>
  <InputLabel>Select Venue</InputLabel>
  <Select
    value={selectedVenue?._id || ''}
    onChange={handleVenueChange}
    label="Select Venue"
  >
    <MenuItem value="">
      <em>Select a venue</em>
    </MenuItem>
    {venues.map(venue => (
      <MenuItem key={venue._id} value={venue._id}>
        {venue.name} (Capacity: {venue.capacity})
      </MenuItem>
    ))}
  </Select>
</FormControl>

{(selectedPerformance || selectedProspect) && selectedVenue && (
  <Paper sx={{ p: 2, mb: 3 }}>
    <Typography variant="h6" gutterBottom>
      {selectionMethod === 'strand' ? selectedStrand?.name : selectedEvent?.name}
    </Typography>
    {selectedPerformance && (
      <Typography>
        Performance: {getPerformanceName(selectedPerformance)}
      </Typography>
    )}
    {selectedProspect && (
      <>
        <Typography variant="subtitle1" color="primary">
          Prospect Details:
        </Typography>
        <Typography variant="body2">
          Event: {selectedProspect.eventConcept}
        </Typography>
        <Typography variant="body2">
          Date: {new Date(selectedProspect.date).toLocaleDateString()}
        </Typography>
        <Typography variant="body2">
          Expected Box Office: €{selectedProspect.boxOffice}
        </Typography>
        <Typography variant="body2">
          Expected Net: €{selectedProspect.net}
        </Typography>
      </>
    )}
    <Typography>
      Venue: {selectedVenue.name} (Capacity: {selectedVenue.capacity})
    </Typography>
  </Paper>
)}


      {/* Ticket Configuration Table */}
      <TableContainer component={Paper} sx={{ mb: 3 }}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Ticket Type</TableCell>
        <TableCell align="right">Quantity</TableCell>
        <TableCell align="right">Gross Price (€)</TableCell>
        <TableCell align="right">VAT Status</TableCell>
        <TableCell align="right">Net Price (€)</TableCell>
        <TableCell align="right">VAT Amount (€)</TableCell>
        <TableCell align="right">Total Revenue (€)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {ticketTypes.map((ticket, index) => {
        const grossRevenue = ticket.quantity * ticket.price;
        const vatAmount = ticket.vatExempt ? 0 : calculateVatAmount(grossRevenue, ticket.vatRate);
        const netRevenue = ticket.vatExempt ? grossRevenue : calculateNetPrice(grossRevenue, ticket.vatRate);
        return (
          <TableRow key={ticket.name}>
            <TableCell>{ticket.name}</TableCell>
            <TableCell align="right">
              <CustomNumberInput
                value={ticket.quantity}
                onChange={(value) => handleTicketChange(index, 'quantity', value)}
                min={0}
                max={selectedVenue?.capacity}
                disabled={ticket.name === 'General Admission'}
              />
            </TableCell>
            <TableCell align="right">
              <CustomNumberInput
                value={ticket.price}
                onChange={(value) => handleTicketChange(index, 'price', value)}
                min={0}
                disabled={!ticket.revenueImpact}
              />
            </TableCell>
            <TableCell align="right">
              <Select
                value={ticket.vatExempt}
                onChange={(e) => handleTicketChange(index, 'vatExempt', e.target.value === 'true')}
                size="small"
              >
                <MenuItem value="false">Reduced VAT</MenuItem>
                <MenuItem value="true">VAT Exempt</MenuItem>
              </Select>
            </TableCell>
            <TableCell align="right">{netRevenue.toFixed(2)}</TableCell>
            <TableCell align="right">{vatAmount.toFixed(2)}</TableCell>
            <TableCell align="right">{grossRevenue.toFixed(2)}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>

{/* Revenue Summary */}
<Box sx={{ mb: 3 }}>
  {(() => {
    const totals = calculateRevenue();
    return (
      <>
        <Typography variant="h6">Revenue Summary</Typography>
        <Typography>Gross Revenue: €{totals.gross.toFixed(2)}</Typography>
        <Typography>VAT Amount: €{totals.vat.toFixed(2)}</Typography>
        <Typography>Net Revenue: €{totals.net.toFixed(2)}</Typography>
      </>
    );
  })()}
</Box>

{/* Revenue Chart */}
<Box sx={{ height: 300, mb: 3 }}>
  <ResponsiveContainer width="100%" height="100%">
    <BarChart data={getChartData()}>
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Gross Revenue" fill="#8884d8" />
      <Bar dataKey="VAT Amount" fill="#82ca9d" />
      <Bar dataKey="Net Revenue" fill="#ffc658" />
    </BarChart>
  </ResponsiveContainer>
</Box>

      {/* Save Button */}
      <Button
  variant="contained"
  color="primary"
  onClick={handleSaveConfig}
  disabled={(!selectedPerformance && !selectedProspect) || !selectedVenue || !!error}
>
  Save Configuration
</Button>
<Snackbar 
  open={!!successMessage} 
  autoHideDuration={3000} 
  onClose={() => setSuccessMessage(null)}
>
  <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
    {successMessage}
  </Alert>
</Snackbar>
    </Box>
  );
};

export default TicketConfigPage;