import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  Card,
  CardContent,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { IMC_EXPENSE_CODES, IMCSubCategory } from '../../constants/imcCodes';
import { SvgIconComponent } from '@mui/icons-material';
import { ADMIN_CATEGORIES } from '../../constants/adminCategories';

interface Props {
    budgetLines: AdminExpenseLine[];
  }

interface AdminExpenseLine {
  id: string;
  code: string;
  frequency: 'Monthly' | 'Quarterly' | 'Annually' | 'Bi-Monthly' | 'Weekly';
  qty: number;
  cost: number;
  value: number;
  sub: number;
  total: number;
  notes?: string;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#a4de6c', '#d0ed57'];

const BudgetAnalysis: React.FC<Props> = ({ budgetLines }) => {
    const [filterType, setFilterType] = useState<'category' | 'subCategory' | 'tag'>('category');
  const [selectedValue, setSelectedValue] = useState<string>('ALL');

  const getFilterOptions = useMemo(() => {
    const options = new Set<string>();
    
    Object.values(IMC_EXPENSE_CODES).forEach(code => {
      if (filterType === 'subCategory') {
        code.subCategories.forEach(sub => options.add(sub));
      } else if (filterType === 'tag') {
        code.tags.forEach(tag => options.add(tag));
      }
    });
    
    return Array.from(options).sort();
  }, [filterType]);

  // Update the filtering logic
  const filteredBudgetLines = useMemo(() => {
    if (selectedValue === 'ALL') {
      return budgetLines;
    }
  
    return budgetLines.filter(line => {
      const expenseCode = Object.values(IMC_EXPENSE_CODES).find(
        code => code.code === line.code
      );
  
      if (!expenseCode) return false;
  
      if (filterType === 'category') {
        const category = ADMIN_CATEGORIES[selectedValue];
        return category?.codes.includes(line.code);
      } else if (filterType === 'subCategory') {
        return (expenseCode.subCategories as readonly string[]).includes(selectedValue);
      } else {
        return (expenseCode.tags as readonly string[]).includes(selectedValue);
      }
    });
  }, [budgetLines, selectedValue, filterType]);

  const acCodeTotals = useMemo(() => {
    const totals = filteredBudgetLines.reduce((acc: Record<string, number>, line) => {
      const expenseCode = Object.entries(IMC_EXPENSE_CODES).find(([_, value]) => 
        value.code === line.code
      )?.[1];
      
      if (expenseCode?.acCode) {
        acc[expenseCode.acCode] = (acc[expenseCode.acCode] || 0) + line.total;
      }
      return acc;
    }, {});

    const totalAmount = filteredBudgetLines.reduce((sum, line) => sum + line.total, 0);

    return Object.entries(totals).map(([name, value]) => ({
      name,
      value,
      percent: ((value / totalAmount) * 100).toFixed(1)
    }));
  }, [filteredBudgetLines]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    }).format(amount);
  };

  const getExpensesByACCode = (acCode: string) => {
    return filteredBudgetLines.filter(line => {
      const expenseCode = Object.entries(IMC_EXPENSE_CODES).find(([_, value]) => 
        value.code === line.code
      )?.[1];
      return expenseCode?.acCode === acCode;
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        {/* Filters */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Filter Type</InputLabel>
                  <Select
                    value={filterType}
                    label="Filter Type"
                    onChange={(e) => {
                      setFilterType(e.target.value as 'category' | 'subCategory' | 'tag');
                      setSelectedValue('ALL');
                    }}
                  >
                    <MenuItem value="category">Budget Categories</MenuItem>
                    <MenuItem value="subCategory">Sub Categories</MenuItem>
                    <MenuItem value="tag">Tags</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth>
                  <InputLabel>Filter By {filterType}</InputLabel>
                  <Select
                    value={selectedValue}
                    label={`Filter By ${filterType}`}
                    onChange={(e) => setSelectedValue(e.target.value)}
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    {filterType === 'category' ? (
                      Object.entries(ADMIN_CATEGORIES).map(([key, category]) => (
                        <MenuItem key={key} value={key}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {category.icon}
                            <Typography>{category.title}</Typography>
                          </Box>
                        </MenuItem>
                      ))
                    ) : (
                      getFilterOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          <Typography sx={{ textTransform: 'capitalize' }}>
                            {option.replace(/_/g, ' ')}
                          </Typography>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Charts */}
        <Grid item xs={12} md={6}>
  <Paper sx={{ p: 2, height: 500 }}>
    <Typography variant="h6" gutterBottom>Budget Distribution</Typography>
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={acCodeTotals}
          dataKey="value"
          nameKey="name"
          cx="40%"
          cy="50%"
          outerRadius={180}
          label={false} // Remove direct labels
        >
          {acCodeTotals.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{
            paddingLeft: '20px',
            maxWidth: '40%',
            fontSize: '12px'
          }}
          formatter={(value) => value.split(' - ')[0]} // Show only main category
        />
        <RechartsTooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return (
                <Box sx={{
                  bgcolor: 'background.paper',
                  p: 2,
                  boxShadow: 2,
                  borderRadius: 1
                }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {payload[0].name}
                  </Typography>
                  <Typography color="primary">
                    {formatCurrency(payload[0].value as number)}
                  </Typography>
                  <Typography variant="body2">
                    {payload[0].payload.percent}% of total
                  </Typography>
                </Box>
              );
            }
            return null;
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  </Paper>
</Grid>

<Grid item xs={12} md={6}>
  <Paper sx={{ p: 2, height: 500 }}>
    <Typography variant="h6" gutterBottom>Category Details</Typography>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart 
        data={acCodeTotals}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis 
          dataKey="name"
          type="category"
          width={140}
          tick={{ fontSize: 12 }}
        />
        <XAxis 
          type="number"
          tickFormatter={(value) => formatCurrency(value)}
        />
        <RechartsTooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box sx={{
                  bgcolor: 'background.paper',
                  p: 2,
                  boxShadow: 2,
                  borderRadius: 1
                }}>
                  <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
                  <Typography color="primary">
                    {formatCurrency(payload[0].value as number)}
                  </Typography>
                  <Typography variant="body2">
                    Click for detailed breakdown
                  </Typography>
                </Box>
              );
            }
            return null;
          }}
        />
        <Bar 
          dataKey="value"
          fill="#8884d8"
          label={{ 
            position: 'right',
            formatter: (value: number) => formatCurrency(value)
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  </Paper>
</Grid>

        {/* Detailed Breakdown */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Detailed Breakdown by Arts Council Categories
          </Typography>
          <Grid container spacing={2}>
            {acCodeTotals.map(({ name, value }) => (
              <Grid item xs={12} key={name}>
                <Card>
                  <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" component="div">
                        {name}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        {formatCurrency(value)}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {getExpensesByACCode(name).map((expense) => {
                        const expenseDetails = Object.entries(IMC_EXPENSE_CODES)
                          .find(([_, value]) => value.code === expense.code)?.[1];
                        
                        return (
                          <Tooltip
                            key={expense.id}
                            title={
                              <Box sx={{ p: 1 }}>
                                <Typography variant="body2">
                                  {expenseDetails?.description}
                                </Typography>
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                  Amount: {formatCurrency(expense.total)}
                                </Typography>
                                <Typography variant="body2">
                                  Frequency: {expense.frequency}
                                </Typography>
                                {expenseDetails?.tags && (
                                  <Typography variant="body2" sx={{ mt: 1 }}>
                                    Tags: {expenseDetails.tags.join(', ')}
                                  </Typography>
                                )}
                              </Box>
                            }
                          >
                            <Chip
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography variant="caption">
                                    {expenseDetails?.label}: {formatCurrency(expense.total)}
                                  </Typography>
                                </Box>
                              }
                              color="primary"
                              variant="outlined"
                              size="small"
                            />
                          </Tooltip>
                        );
                      })}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BudgetAnalysis;