// src/components/cooler/inventory/components/ItemDetails/Documents.tsx

import React from 'react';
import {
  Box,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import { FileText, X as CloseIcon } from 'lucide-react';

import { Upload } from '../../../../shared/Upload';
import type { InventoryItem, DocumentReference } from '../../../types/inventoryTypes';

interface DocumentsProps {
  item: InventoryItem;
  isEditing: boolean;
  onUpdate: (updatedDocuments: DocumentReference[]) => void;
}

const Documents: React.FC<DocumentsProps> = ({ item, isEditing, onUpdate }) => {
  const handleAddDocument = (urls: string[]) => {
    if (urls.length > 0) {
      const newDocRef: DocumentReference = {
        url: urls[0],
        uploadedAt: new Date(),
        uploadedBy: '670561ef3fef62cdc14cf5c8', // TODO: Get from auth context
        originalFilename: urls[0].split('/').pop() || '',
        type: 'invoice' // Default type, could be made selectable
      };

      // Add to existing documents array
      const updatedDocs = [...(item.documents || []), newDocRef];
      onUpdate(updatedDocs);
    }
  };

  const handleRemoveDocument = (docToRemove: DocumentReference) => {
    const updatedDocs = (item.documents || []).filter(doc => doc.url !== docToRemove.url);
    onUpdate(updatedDocs);
  };

  return (
    <Box>
      <Stack spacing={2}>
        {item.documents?.map((doc, index) => (
          <Box 
            key={index} 
            sx={{ 
              position: 'relative', 
              display: 'flex', 
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.04)'
              }
            }}
          >
            <Box 
              onClick={() => window.open(doc.url, '_blank')}
              sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
            >
              <FileText size={32} />
              <Box sx={{ ml: 1, flexGrow: 1 }}>
                <Typography variant="body2">
                  {doc.originalFilename}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {new Date(doc.uploadedAt).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
            {isEditing && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveDocument(doc);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ))}
      </Stack>

      {isEditing && (
        <Box sx={{ mt: 2 }}>
          <Upload
            onUploadComplete={handleAddDocument}
            maxFiles={1}
            accept=".pdf,.doc,.docx"
            type="equipmentDocs"
          />
        </Box>
      )}
    </Box>
  );
};

export default Documents;