import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import axios from 'axios';
import {
  PersonnelFormData,
  PersonnelRole,
  SkillLevel
} from '../types/Personnel';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PersonnelForm: React.FC = () => {
  const [formData, setFormData] = useState<PersonnelFormData>({
    name: '',
    id: '',
    email: '',
    phone: '',
    fee: 0,
    roles: [], // <-- no longer default to [Staff]
    skillLevel: SkillLevel.Beginner,
    experience: 0,
    performances: [],
    availability: {
      from: new Date(),
      to: new Date(),
    },
    specialization: '',
    equipment: [],
    certifications: [],
    bio: '',
    profileImage: '',
    socialMedia: {
      linkedin: '',
      twitter: '',
      instagram: '',
    },
  });

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Determine if we show advanced fields
  const showAdvancedFields = formData.roles.some(
    (role) => role !== PersonnelRole.Staff && role !== PersonnelRole.Volunteer
  );

  useEffect(() => {
    if (id) {
      const fetchPerson = async () => {
        try {
          const response = await axios.get<PersonnelFormData>(`${API_BASE_URL}/api/personnel/${id}`);
          let data = response.data;

          // Ensure 'roles' is always an array:
          if (!Array.isArray(data.roles)) {
            if (typeof data.roles === 'string') {
              data.roles = [data.roles as PersonnelRole];
            } else if (!data.roles) {
              data.roles = [];
            }
          }
          setFormData(data);
        } catch (error) {
          console.error('Error fetching personnel data:', error);
        }
      };
      fetchPerson();
    }
  }, [id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;

    // For fields that store arrays like equipment/certifications, you might handle them differently.
    // For basic text or number fields, just set them normally:
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleChange = (e: SelectChangeEvent<string[]>) => {
    // MUI multiple select returns string[] if your <MenuItem> values are strings
    const selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
    const roles = selectedValues.map((val) => val as PersonnelRole);
    setFormData((prev) => ({
      ...prev,
      roles: roles,
    }));
  };

  const handleArrayChange = (name: keyof PersonnelFormData, value: string) => {
    // For comma-separated input fields like equipment or certifications
    // Transform string => array
    const arrayValue = value.split(',').map((item) => item.trim());
    setFormData((prev) => ({
      ...prev,
      [name]: arrayValue,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.patch(`${API_BASE_URL}/api/personnel/${id}`, formData);
      } else {
        await axios.post(`${API_BASE_URL}/api/personnel`, formData);
      }
      navigate('/admin/personnel');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Name */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Roles (multiple) */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              name="roles"
              label="Roles"
              value={Array.isArray(formData.roles) ? formData.roles : []}
              onChange={handleRoleChange}
              input={<OutlinedInput label="Roles" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {Object.values(PersonnelRole).map((roleOption) => (
                <MenuItem key={roleOption} value={roleOption}>
                  <Checkbox checked={formData.roles.indexOf(roleOption) > -1} />
                  <ListItemText primary={roleOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Skill Level */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Skill Level</InputLabel>
            <Select
              name="skillLevel"
              label="Skill Level"
              value={formData.skillLevel}
              onChange={handleChange}
              required
            >
              {Object.values(SkillLevel).map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Experience */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Experience (years)"
            name="experience"
            type="number"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </Grid>

        {/* Render “advanced” fields only if NOT Staff/Volunteer */}
        {showAdvancedFields && (
          <>
            {/* Specialization */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Specialization"
                name="specialization"
                value={formData.specialization || ''}
                onChange={handleChange}
              />
            </Grid>

            {/* Fee */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fee"
                name="fee"
                type="number"
                value={formData.fee}
                onChange={handleChange}
              />
            </Grid>

            {/* Equipment (comma-separated input) */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Equipment (comma separated)"
                name="equipment"
                value={formData.equipment?.join(', ') || ''}
                onChange={(e) => handleArrayChange('equipment', e.target.value)}
                placeholder="e.g. 'Mixer, Microphone, Cables'"
              />
            </Grid>

            {/* Certifications (comma-separated input) */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Certifications (comma separated)"
                name="certifications"
                value={formData.certifications?.join(', ') || ''}
                onChange={(e) => handleArrayChange('certifications', e.target.value)}
                placeholder="e.g. 'OSHA, Rigging, Safety'"
              />
            </Grid>

            {/* Availability - Just a simple from/to text or date pickers */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Available From"
                InputLabelProps={{ shrink: true }}
                name="availability.from"
                value={
                  formData.availability.from
                    ? new Date(formData.availability.from).toISOString().split('T')[0]
                    : ''
                }
                onChange={(e) =>
                  setFormData((prev: PersonnelFormData) => ({
                    ...prev,
                    availability: {
                      ...prev.availability,
                      from: e.target.value ? new Date(e.target.value) : new Date(),
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Available To"
                InputLabelProps={{ shrink: true }}
                name="availability.to"
                value={
                  formData.availability.to
                    ? new Date(formData.availability.to).toISOString().split('T')[0]
                    : ''
                }
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    availability: {
                      ...formData.availability,
                      to: e.target.value ? new Date(e.target.value) : new Date(),
                    },
                  })
                }
              />
            </Grid>

            {/* Bio */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Bio"
                name="bio"
                value={formData.bio || ''}
                onChange={handleChange}
              />
            </Grid>

            {/* Profile Image URL */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Profile Image URL"
                name="profileImage"
                value={formData.profileImage || ''}
                onChange={handleChange}
              />
            </Grid>

            {/* Social Media Links */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="LinkedIn URL"
                name="linkedin"
                value={formData.socialMedia?.linkedin || ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    socialMedia: {
                      ...prev.socialMedia,
                      linkedin: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Twitter URL"
                name="twitter"
                value={formData.socialMedia?.twitter || ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    socialMedia: {
                      ...prev.socialMedia,
                      twitter: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Instagram URL"
                name="instagram"
                value={formData.socialMedia?.instagram || ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    socialMedia: {
                      ...prev.socialMedia,
                      instagram: e.target.value,
                    },
                  }))
                }
              />
            </Grid>
          </>
        )}

        {/* Submit */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {id ? 'Update' : 'Create'} Personnel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonnelForm;