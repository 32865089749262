// TagCostBarChart.tsx
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { IMC_EXPENSE_CODES } from '../../../../constants/imcCodes';
import { GroupBudgetLine } from '../../../../types/groupBudget';

interface TagCostBarChartProps {
  expenseLines: GroupBudgetLine[];
}

const TagCostBarChart: React.FC<TagCostBarChartProps> = ({ expenseLines }) => {
  const [selectedTag, setSelectedTag] = useState<string>('all');

  const { data, tags } = React.useMemo(() => {
    const tagMap = new Map<string, number>();
    const allTags = new Set<string>();

    expenseLines.forEach(line => {
      const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      if (!code) return;

      const amount = line.quantity * line.value * line.multiplier;
      code.tags.forEach(tag => {
        allTags.add(tag);
        tagMap.set(tag, (tagMap.get(tag) || 0) + amount);
      });
    });

    const sortedData = Array.from(tagMap.entries())
      .map(([tag, value]) => ({ tag, value }))
      .sort((a, b) => b.value - a.value);

    return {
      data: sortedData,
      tags: Array.from(allTags).sort()
    };
  }, [expenseLines]);

  const filteredData = selectedTag === 'all' 
    ? data 
    : data.filter(item => item.tag === selectedTag);

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload?.[0]) return null;
    
    const data = payload[0].payload;
    return (
      <Box sx={{ bgcolor: 'rgba(0,0,0,0.8)', p: 1.5, borderRadius: 1 }}>
        <Typography color="white">{data.tag}</Typography>
        <Typography color="white">
          €{data.value.toLocaleString()}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Cost by Tag</Typography>
        <FormControl size="small" sx={{ width: 200 }}>
          <InputLabel>Filter by Tag</InputLabel>
          <Select
            value={selectedTag}
            label="Filter by Tag"
            onChange={(e) => setSelectedTag(e.target.value)}
          >
            <MenuItem value="all">All Tags</MenuItem>
            {tags.map(tag => (
              <MenuItem key={tag} value={tag}>
                {tag.replace(/_/g, ' ')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={filteredData}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
        >
          <XAxis type="number" />
          <YAxis 
            dataKey="tag" 
            type="category"
            tickFormatter={(value) => value.replace(/_/g, ' ')}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            fill="#8884d8"
            radius={[0, 4, 4, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default TagCostBarChart;