// FormSchema.ts

// Enum for field types
export enum FieldType {
  Text = 'text',
  Email = 'email',
  TextArea = 'textarea',
  Checkbox = 'checkbox',
  Select = 'select',
  Upload = 'upload',
  Url = 'url',
  Sig = 'sig'  // Add this line
  // Add more as needed
}

// Validation rules interface
export interface ValidationRules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  // Add more validation rules as needed
}

// Base interface for form fields
export interface BaseField {
  id: string; // Matches IDs like ID1, ID2, etc.
  type: FieldType;
  name: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  tooltip?: string;
  validation?: ValidationRules;
  strandSpecific?: boolean;
  strands?: string[]; // List of strands the field applies to
  grid?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  
}

export interface SignatureField extends BaseField {
  type: FieldType.Sig;
  signatureConfig?: SignatureConfig;
}

// Interface for text fields
export interface TextField extends BaseField {
  type: FieldType.Text | FieldType.Email | FieldType.TextArea | FieldType.Url;
  defaultValue?: string;
}

// Interface for select fields
export interface SelectField extends BaseField {
  type: FieldType.Select;
  options: { value: string; label: string }[];
}

// Interface for checkbox fields
export interface CheckboxField extends BaseField {
  type: FieldType.Checkbox;
  defaultValue?: boolean;
}

// Interface for upload fields
export interface UploadField extends BaseField {
  type: FieldType.Upload;
  accept: string; // e.g., '.pdf', 'audio/*'
  multiple?: boolean;
}

export interface SignatureConfig {
  penColor: string;
  penSize: number;
  canvasHeight: number;
  backgroundColor: string;
  clearable: boolean;
}

// **Export the FormField type**
export type FormField = TextField | SelectField | CheckboxField | UploadField | SignatureField;


// Form schema interface
export interface FormSchema {
  formId: string;
  title: string;
  description?: string;
  strand: string;
  fields: FormField[];
}

export interface FieldBlock {
    id: string;
    label: string;
    fields: FormField[];
  }

  export interface Strand {
      _id: string;
      year: number;
      name: string;
      slug: string; // Add this line
    blocks: FieldBlock[];
  }