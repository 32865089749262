// src/pages/BudgetPage.tsx

import React, { useState } from 'react';
import { 
  Box,
  Typography,
  Paper,
  Button,
  Alert,
  Breadcrumbs,
  Link as MuiLink
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import BudgetPlanner from '../components/budgets/BudgetPlanner';

const BudgetPage: React.FC = () => {
  const { strandId } = useParams<{ strandId: string }>();
  const [error, setError] = useState<string | null>(null);

  return (
    <Box sx={{ p: 3 }}>
      {/* Breadcrumb Navigation */}
      <Breadcrumbs sx={{ mb: 3 }}>
        <MuiLink component={Link} to="/admin/strands" color="inherit">
          Strands
        </MuiLink>
        <Typography color="textPrimary">Budget Planning</Typography>
      </Breadcrumbs>

      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Budget Planner</Typography>
        <Box>
          <Button
            component={Link}
            to={`/admin/strands/${strandId}`}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Back to Strand
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {/* Save function */}}
          >
            Save Budget
          </Button>
        </Box>
      </Box>

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Main Content */}
      <Paper sx={{ p: 2 }}>
        <BudgetPlanner />
      </Paper>
    </Box>
  );
};

export default BudgetPage;