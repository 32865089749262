import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Alert,
  CircularProgress,
  FormHelperText,
  Autocomplete,
  Stack
} from '@mui/material';
import axiosInstance from '../../../utils/axiosConfig';

// Updated Strand interface to include year
interface Strand {
  _id: string;
  name: string;
  type?: string;
  status?: string;
  year: number;  // Make year required
}

interface GroupBudgetNamingSummaryProps {
  strandId?: string | Strand; // Use the Strand interface here
  name: string;
  eventType: string;
  targetAudience: string;
  budgetType: string;
  vatStatus: string;
  eventCount: number;
  status: string;
  totalIncome: number;
  totalExpenditure: number;
  onUpdate: (field: string, value: string | number) => void;
}

// Constants for the dropdowns
const EVENT_TYPES = [
  'Ticketed Performance or Event',
  'Non-Ticketed Performance or Event',
  'Book Publication',
  'Production and / or Distribution of an Artefact',
  'Broadcast / online / digital distribution of work',
  'Exhibition',
  'Participatory / education / outreach activity',
  'New work development activity',
  'Research/archiving/digitisation',
  'Artist-focused / artform development activity'
] as const;

const TARGET_AUDIENCES = [
  'General audiences',
  'Families',
  'Children (0-15)',
  'Young People (16-23)',
  'Targeted communities',
  'Artists / arts professionals'
] as const;

const BUDGET_TYPES = [
  'Club',
  'Festival',
  'Other',
  'TBC'
] as const;

const VAT_STATUSES = [
  'Wet',
  'Dry',
  'Unknown'
] as const;

const BUDGET_STATUSES = [
  'Not Started',
  'Initial Draft',
  'GM Draft Reviewed',
  'Director Draft Reviewed',
  'Final Reviewed Draft',
  'Budget Submission',
  'Revised Draft',
  'Revised Submission',
  'Periodic Review'
] as const;

const GroupBudgetNamingSummary: React.FC<GroupBudgetNamingSummaryProps> = ({
    strandId,
    name,
    eventType,
    targetAudience,
    budgetType,
    vatStatus,
    eventCount,
    status,
    totalIncome,
    totalExpenditure,
    onUpdate
  }) => {
    // State for strands and filtering
    const [strands, setStrands] = useState<Strand[]>([]);
    const [filteredStrands, setFilteredStrands] = useState<Strand[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedStrand, setSelectedStrand] = useState<Strand | null>(null);
    const [useCustomName, setUseCustomName] = useState(!strandId);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  
    // Get unique years from strands
    const availableYears = React.useMemo(() => {
      const years = [...new Set(strands.map(strand => strand.year))];
      return years.sort((a, b) => b - a); // Sort descending
    }, [strands]);
  
    // Effect to handle year changes
    useEffect(() => {
      if (strands.length > 0) {
        const filtered = strands.filter(strand => strand.year === selectedYear);
        setFilteredStrands(filtered);
        
        // If current selection is not in filtered list, clear it
        if (selectedStrand && selectedStrand.year !== selectedYear) {
          setSelectedStrand(null);
          onUpdate('strandId', '');
          setUseCustomName(true);
        }
      }
    }, [selectedYear, strands]);
  
    // Fetch strands
    const fetchStrands = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get<Strand[]>('/api/strands');
        setStrands(response.data);
        
        // If strandId exists, find and set the selected strand
        if (strandId) {
          const strand = response.data.find(s => s._id === strandId);
          if (strand) {
            setSelectedStrand(strand);
            setSelectedYear(strand.year);
            setUseCustomName(name !== strand.name);
          }
        }
      } catch (error) {
        console.error('Error fetching strands:', error);
        setError('Failed to fetch strands. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchStrands();
    }, []);

// Update the handleStrandChange function
const handleStrandChange = (
    event: React.SyntheticEvent,
    newValue: Strand | null
  ) => {
    setSelectedStrand(newValue);
    if (newValue) {
      onUpdate('strandId', newValue._id);
      // Always update the name with year when strand changes
      onUpdate('name', `${newValue.name} ${selectedYear} Budget`);
    } else {
      onUpdate('strandId', '');
      onUpdate('name', '');  // Clear name when strand is cleared
    }
  };
  
    
   // Update the name change handler
// Update the handleNameChange function to match TextField's onChange type
const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    onUpdate('name', newName);
    
    // Check if we should switch to custom name
    if (selectedStrand) {
      const defaultName = `${selectedStrand.name} ${selectedYear} Budget`;
      setUseCustomName(newName !== defaultName);
    }
  };
    
      // When year changes
      useEffect(() => {
        if (selectedStrand && !useCustomName) {
          // Update the name with the new year
          onUpdate('name', `${selectedStrand.name} ${selectedYear}`);
        }
      }, [selectedYear, selectedStrand]);

      // Handle select changes
  const handleSelectChange = (event: SelectChangeEvent, field: string) => {
    onUpdate(field, event.target.value);
  };

    // Handle number input changes
    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const value = event.target.value === '' ? 0 : parseInt(event.target.value, 10);
        onUpdate(field, value);
      };
    
// Update the handleYearChange function
const handleYearChange = (event: SelectChangeEvent<number>) => {
    const newYear = event.target.value as number;
    setSelectedYear(newYear);
    
    // Update the name with new year if using strand name
    if (selectedStrand && !useCustomName) {
      onUpdate('name', `${selectedStrand.name} ${newYear}`);
    }
  };

      // Format currency
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };
  
    // Customize option rendering for Autocomplete
    const renderStrandOption = (props: React.HTMLAttributes<HTMLLIElement>, strand: Strand) => (
      <Box component="li" {...props}>
        <Typography variant="body1">{strand.name}</Typography>
        {strand.type && (
          <Typography variant="caption" color="text.secondary">
            {strand.type}
          </Typography>
        )}
      </Box>
    );
  
    return (
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Budget Configuration
        </Typography>
  
        <Grid container spacing={3}>
          {/* Year Selection */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                {availableYears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select year to filter strands
              </FormHelperText>
            </FormControl>
          </Grid>
  
{/* Strand Selection */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth>
  <Autocomplete
  options={filteredStrands}
  loading={loading}
  value={selectedStrand}
  onChange={handleStrandChange}  // Now this will work
  getOptionLabel={(option: Strand) => `${option.name}`}
  renderOption={renderStrandOption}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select Strand"
      error={!!error}
      helperText={error || `Showing strands for ${selectedYear}`}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )}
  noOptionsText={`No strands found for ${selectedYear}`}
/>
  </FormControl>
</Grid>
  
{/* Name Field */}
<Grid item xs={12} md={12}>
<TextField
  fullWidth
  label="Budget Name"
  value={name}
  onChange={handleNameChange}  // And this will work
  helperText={selectedStrand ? 
    (useCustomName ? 'Using custom name' : `Using strand name from ${selectedYear}`) : 
    'Enter budget name'
  }
/>
</Grid>
        {/* Event Type Dropdown */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>AC Event Type</InputLabel>
            <Select
              value={eventType}
              label="AC Event Type"
              onChange={(e) => handleSelectChange(e, 'eventType')}
            >
              {EVENT_TYPES.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Target Audience Dropdown */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>AC Target Audience</InputLabel>
            <Select
              value={targetAudience}
              label="AC Target Audience"
              onChange={(e) => handleSelectChange(e, 'targetAudience')}
            >
              {TARGET_AUDIENCES.map((audience) => (
                <MenuItem key={audience} value={audience}>{audience}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Budget Type Dropdown */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Budget Type</InputLabel>
            <Select
              value={budgetType}
              label="Budget Type"
              onChange={(e) => handleSelectChange(e, 'budgetType')}
            >
              {BUDGET_TYPES.map((type) => (
                <MenuItem key={type} value={type}>{type}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* VAT Status Dropdown */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>VAT Status</InputLabel>
            <Select
              value={vatStatus}
              label="VAT Status"
              onChange={(e) => handleSelectChange(e, 'vatStatus')}
            >
              {VAT_STATUSES.map((status) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Event Count Field */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            type="number"
            label="Number of Events"
            value={eventCount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberChange(e, 'eventCount')}
            inputProps={{ min: 0 }}
          />
        </Grid>

        {/* Status Dropdown */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Budget Status</InputLabel>
            <Select
              value={status}
              label="Budget Status"
              onChange={(e) => handleSelectChange(e, 'status')}
            >
              {BUDGET_STATUSES.map((status) => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Financial Summary */}
        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Financial Summary
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText'
                  }}
                >
                  <Typography variant="subtitle2">Total Income</Typography>
                  <Typography variant="h6">{formatCurrency(totalIncome)}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'error.light',
                    color: 'error.contrastText'
                  }}
                >
                  <Typography variant="subtitle2">Total Expenditure</Typography>
                  <Typography variant="h6">{formatCurrency(totalExpenditure)}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: totalIncome - totalExpenditure >= 0 ? 'success.light' : 'warning.light',
                    color: 'white'
                  }}
                >
                  <Typography variant="subtitle2">Cost to Organisation</Typography>
                  <Typography variant="h6">
                    {formatCurrency(totalIncome - totalExpenditure)}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Warning for negative balance */}
        {totalIncome - totalExpenditure < 0 && (
          <Grid item xs={12}>
            <Alert severity="warning">
              This budget is currently showing a deficit of {formatCurrency(Math.abs(totalIncome - totalExpenditure))}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default GroupBudgetNamingSummary;