// src/components/navigation/constants/navigationItems.ts
import {
    Home,
    Calendar,
    Music,
    Building,
    User,
    FileText,
    ScanLine,
    BookOpen,
    BarChart2,
    DollarSign,
    Settings,
    Wrench,
    Users,
    Layout,
    PenTool,
    Mail
  } from 'lucide-react';
  import type { NavigationSection, MenuItem } from '../../../types/navigation';


  
  export const getNavigationItems = (role: string): NavigationSection => {
    switch (role) {
      case 'admin':
        return {
          mainItems: [
            { label: 'Bands', path: '/admin/bands', icon: Music },
            { label: 'Calendar', path: '/calendar', icon: Calendar },
          ],
          dropdowns: {
            'Events & Programming': [
              { 
                label: 'Events',
                children: [
                  { label: 'Events List', path: '/admin/events' },
                  { label: 'Create Event', path: '/admin/events/new' },
                  { label: 'Performances', path: '/admin/performance' }
                ]
              },
              { 
                label: 'Strands',
                children: [
                  { label: 'Strand List', path: '/admin/strands' },
                  { label: 'Create Strand', path: '/strands/new' },
                  { label: 'Published Strands', path: '/publicstrands' }
                ]
              }
            ],
            Finance: [
              {
                label: 'Budgets',
                children: [
                  { label: 'Budget Overview', path: '/admin/budget-overview' },
                  { label: 'Budgets List', path: '/admin/master-budget-list' },
                  { label: 'AAR Budgets', path: '/admin/aar' },
                  { label: 'Budget Manager', path: '/admin/budget-manager' }
                ]
              },
              {
                label: 'Budget Slices',
                children: [
                  { label: 'Marketing Slice', path: '/admin/marketing-budget' },
                  { label: 'Ticketing Slice', path: '/admin/ticketing-budget' },
                  { label: 'Fees Slice', path: '/admin/fees-budget' },
                  { label: 'Production Slice', path: '/admin/production-budget' }
                ]
              },
              {
                label: 'Expenses',
                children: [
                  { label: 'Add Expense', path: '/admin/expenses/new' },
                  { label: 'Expenses List', path: '/admin/expenses' }
                ]
              },
            ],
            'Venues & Production': [
              { label: 'Venue List', path: '/admin/venues', icon: Building },
              { label: 'Add Venue', path: '/admin/venues/new' },
              { label: 'Cooler', path: '/admin/cooler' },
              { label: 'Stage Designer', path: '/stage-designer' },
              { label: 'Band Management', path: '/admin/bands' }
            ],
            'Marketing & Sales': [
              { label: 'Sales Dashboard', path: '/admin/sales', icon: BarChart2 },
              {
                label: 'Ticketing',
                children: [
                  { label: 'Ticket Management', path: '/tix' },
                  { label: 'Ticket List', path: '/tix-list' }
                ]
              },
              { label: 'Promotions', path: '/investor/dashboard' }
            ],
            Applications: [
              { label: 'All Applications', path: '/admin/applications', icon: Mail },
              { label: 'Navigator Applications', path: '/admin/navigator-applications' },
              { label: 'BanBam Applications', path: '/admin/banbam-applications' },
              { label: 'Guidelines Management', path: '/guidelines' }
            ],
            'System Admin': [
              { label: 'User Management', path: '/admin/users', icon: Users },
              { label: 'Domain Management', path: '/admin/domains', icon: Layout },
              { label: 'Personnel', path: '/admin/personnel', icon: Users },
              { label: 'Prospects', path: '/admin/prospects', icon: Users }
            ],
            Tools: [
              { label: 'Pomodoro', path: '/musicadmin', icon: Wrench },
              { label: 'Quick Ideas', path: '/admin/ideas', icon: PenTool },
              { label: 'QuickTasks', path: '/tasks', icon: ScanLine },
              { label: 'Form Builder', path: '/Formbuilder', icon: BookOpen }
            ]
          }
        };
      case 'artist':
        return {
          mainItems: [
            { label: 'My Dashboard', path: '/artist/dashboard', icon: Home },
            { label: 'My Profile', path: '/artist/profile', icon: User },
            { label: 'Submit A New Band', path: '/submit', icon: Music },
          ],
          dropdowns: {
            More: [
              { label: 'My Performances', path: '/artist/performances' },
              { label: 'Calendar', path: '/artist/calendar' },
              { label: 'My Pomodoro', path: '/musicadmin' },
              { label: 'Stage Plot Creator', path: '/stage-designer' },
            ],
          },
        };
      case 'guest':
        return {
          mainItems: [
            { label: 'Dashboard', path: '/dashboard', icon: Home },
            { label: 'Cooler', path: '/admin/cooler' },
            { label: 'Add Expense', path: '/admin/expenses/new' },
            { label: 'Bands', path: '/bands', icon: Music },
            { label: 'Public Strands', path: '/publicstrands', icon: FileText },
          ],
        };
      default:
        return {
          mainItems: [
            { label: 'Published Strands', path: '/publicstrands', icon: FileText },
          ],
        };
    }
  };