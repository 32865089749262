// src/components/layout/GuestLayout.tsx

import React from 'react';
import { Outlet } from 'react-router-dom';
import ResponsiveNav from '../navigation/ResponsiveNav';
import ShowrunnrLogo from '../ShowrunnrLogo'; // wherever your logo is
import Footer from '../Footer';  // Assuming you have a Footer component

const GuestLayout: React.FC = () => {
  return (
    <div className="guest-layout">
      <ResponsiveNav role="guest"   
      logo={<ShowrunnrLogo width={150} height={50} />}
 />
      <main className="guest-main-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default GuestLayout;