import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SelectChangeEvent,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  Radio,
  FormControlLabel,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axiosInstance from '../utils/axiosConfig';
import EventCalculator from './EventCalculator';
import { Link } from 'react-router-dom';

interface Event {
  _id: string;
  name: string;
}

interface Strand {
  _id: string;
  name: string;
  year: number;
}

interface Band {
  _id: string;
  artistName: string;
}

interface CostCategory {
  [key: string]: string;
}

interface Costs {
  travel: {
    flights: string;
    groundTransport: string;
    other: string;
  };
  accommodation: {
    hotelCost: string;
    numberOfNights: string;
  };
  perDiem: {
    dailyRate: string;
    numberOfDays: string;
  };
  equipment: {
    rental: string;
    shipping: string;
  };
  marketing: string;
  insurance: string;
  visas: string;
  other: string;
  bandFee?: string; // Assuming it's a string because the fee is an input field
  totalWithoutFee?: number; // Calculated value
  totalWithFee?: number; // Calculated value
}

interface FormData {
  band: string;
  newBandName: string;
  eventId: string;
  strandId: string;
  startTime: Date | null;
  endTime: Date | null;
  fee: string;
  status: string;
  costs: Costs;
  contingencyRate: string;
  notes: string;
  simpleBandName?: string; // Add this
  needsBandRecord?: boolean; // Add this                  
}

interface CalculatedCosts {
  flightsTotal: number;
  accommodationTotal: number;
  perDiemTotal: number;
  equipmentTotal: number;
  otherCostsTotal: number;
  grandTotal: number;
}

interface PerformanceFormProps {
  eventId?: string;
  strandId?: string;
  onSubmit: (performanceData: any) => void;
  initialData?: any;
  isEdit?: boolean;
}

const PerformanceForm: React.FC<PerformanceFormProps> = ({
  eventId,
  strandId,
  onSubmit,
  initialData,
  isEdit = false,
}) => {
  const [formData, setFormData] = useState<FormData>({
    band: '',
    newBandName: '',
    eventId: eventId || '',
    strandId: strandId || '',
    startTime: new Date(),
    endTime: new Date(),
    fee: '',
    status: 'pending',
    costs: {
      travel: {
        flights: '',
        groundTransport: '',
        other: ''
      },
      accommodation: {
        hotelCost: '',
        numberOfNights: '1'
      },
      perDiem: {
        dailyRate: '',
        numberOfDays: '1'
      },
      equipment: {
        rental: '',
        shipping: ''
      },
      marketing: '',
      insurance: '',
      visas: '',
      other: ''
    },
    contingencyRate: '10',
    notes: ''
  });

  const [events, setEvents] = useState<Event[]>([]);
  const [strands, setStrands] = useState<Strand[]>([]);
  const [bands, setBands] = useState<Band[]>([]);
  const [bandType, setBandType] = useState<'existing' | 'new'>('existing');
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { costs, contingencyRate } = formData;

  useEffect(() => {
    if (initialData) {
      setFormData(prevData => ({
        ...prevData,
        ...initialData,
        startTime: new Date(initialData.startTime),
        endTime: new Date(initialData.endTime),
        eventId: initialData.eventId || eventId || '',
        strandId: initialData.strandId || strandId || ''
      }));
    }
  }, [initialData, eventId, strandId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [eventsRes, strandsRes, bandsRes] = await Promise.all([
          axiosInstance.get('/api/events'),
          axiosInstance.get('/api/strands'),
          axiosInstance.get('/api/bandRecommendations')
        ]);

        setEvents(eventsRes.data);
        setStrands(strandsRes.data);
        setBands(bandsRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load necessary data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNestedChange = (
    category: keyof Costs,
    field: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        [category]:
          typeof prev.costs[category] === 'object'
            ? {
                ...(prev.costs[category] as CostCategory),
                [field]: value
              }
            : value
      }
    }));
  };

  const handleDateChange = (field: 'startTime' | 'endTime') => (newValue: Date | null) => {
    setFormData(prev => ({
      ...prev,
      [field]: newValue
    }));
  };

  const parseNumber = (value: string) => (value === '' ? 0 : parseFloat(value));

  const calculateTotalCosts = () => {
    const { costs, fee } = formData;
    
    // Convert fee to number, default to 0 if empty or invalid
    const bandFee = parseFloat(fee) || 0;
  
    // Calculate travel costs
    const travelCosts =
      parseNumber(costs.travel.flights) +
      parseNumber(costs.travel.groundTransport) +
      parseNumber(costs.travel.other);
  
    // Calculate accommodation costs
    const accommodationCosts =
      parseNumber(costs.accommodation.hotelCost) * parseNumber(costs.accommodation.numberOfNights);
  
    // Calculate per diem costs
    const perDiemCosts =
      parseNumber(costs.perDiem.dailyRate) * parseNumber(costs.perDiem.numberOfDays);
  
    // Calculate equipment costs
    const equipmentCosts =
      parseNumber(costs.equipment.rental) + parseNumber(costs.equipment.shipping);
  
    // Calculate other miscellaneous costs
    const otherCosts =
      parseNumber(costs.marketing) +
      parseNumber(costs.insurance) +
      parseNumber(costs.visas) +
      parseNumber(costs.other);
  
    // Sum all costs including band fee
    const totalWithFee = bandFee + travelCosts + accommodationCosts + 
                        perDiemCosts + equipmentCosts + otherCosts;
  
    return {
      bandFee,
      travelCosts,
      accommodationCosts,
      perDiemCosts,
      equipmentCosts,
      otherCosts,
      totalWithoutFee: travelCosts + accommodationCosts + perDiemCosts + equipmentCosts + otherCosts,
      totalWithFee
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Prepare submission data
      const submissionData = {
        ...formData,
        // If it's a new band, send simpleBandName instead of band
        ...(bandType === 'new' ? {
          simpleBandName: formData.newBandName,
          band: undefined
        } : {
          band: formData.band,
          simpleBandName: undefined
        }),
        event: formData.eventId || undefined,
        strandId: formData.strandId || undefined,
        // Include all other necessary fields
        costs: {
          ...formData.costs,
          bandFee: parseFloat(formData.fee) || 0
        }
      };
  
      onSubmit(submissionData);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit form. Please try again.');
    }
  };
  const {
    bandFee,
    travelCosts,
    accommodationCosts,
    perDiemCosts,
    equipmentCosts,
    otherCosts,
    totalWithoutFee,
    totalWithFee
  } = calculateTotalCosts();
   
  const contingency = totalWithFee * (parseNumber(formData.contingencyRate) / 100);
        const handleCalculatorSubmit = (calculatedCosts: CalculatedCosts) => {
    setFormData(prev => ({
      ...prev,
      costs: {
        ...prev.costs,
        travel: {
          ...prev.costs.travel,
          flights: calculatedCosts.flightsTotal.toString(),
        },
        accommodation: {
          ...prev.costs.accommodation,
          hotelCost: calculatedCosts.accommodationTotal.toString(),
        },
        perDiem: {
          ...prev.costs.perDiem,
          dailyRate: calculatedCosts.perDiemTotal.toString(),
        },
        equipment: {
          ...prev.costs.equipment,
          rental: calculatedCosts.equipmentTotal.toString(),
        },
        other: calculatedCosts.otherCostsTotal.toString(),
      },
    }));
    setCalculatorOpen(false);
  };

  if (loading) {
    return <Box sx={{ p: 4 }}><Typography>Loading...</Typography></Box>;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 4,
          '& .MuiGrid-item': {
            mb: 2
          },
          '& .MuiFormControl-root': {
            mb: 2
          },
          '& .MuiTypography-h6': {
            mb: 2,
            mt: 2
          }
        }}
      >
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
        )}

        <Grid container spacing={3}>
          {/* Event/Strand Selection */}
          <Grid item xs={12}>
            <Typography variant="h6">Event/Strand Association</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Event</InputLabel>
                  <Select
                    name="eventId"
                    value={formData.eventId}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {events.map((event) => (
                      <MenuItem key={event._id} value={event._id}>
                        {event.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Strand</InputLabel>
                  <Select
                    name="strandId"
                    value={formData.strandId}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {strands.map((strand) => (
                      <MenuItem key={strand._id} value={strand._id}>
                        {strand.name} - ({strand.year})
                      </MenuItem> 
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

      {/* Band Selection */}
<Grid item xs={12}>
  <Typography variant="h6">Band Information</Typography>
  <RadioGroup
    value={bandType}
    onChange={(e) => setBandType(e.target.value as 'existing' | 'new')}
    sx={{ mb: 2 }}
  >
    <FormControlLabel value="existing" control={<Radio />} label="Select Existing Band" />
    <FormControlLabel value="new" control={<Radio />} label="Quick Add Band" />
  </RadioGroup>

  {bandType === 'existing' ? (
    <FormControl fullWidth>
      <InputLabel>Band</InputLabel>
      <Select
        name="band"
        value={formData.band}
        onChange={handleChange}
        required={bandType === 'existing'}
      >
        {bands.map((band) => (
          <MenuItem key={band._id} value={band._id}>
            {band.artistName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <TextField
      fullWidth
      name="newBandName"
      label="Band Name"
      value={formData.newBandName}
      onChange={handleChange}
      required={bandType === 'new'}
      helperText="You can create a full band profile later"
    />
  )}
</Grid>
          {/* Date/Time Selection */}
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={handleDateChange('startTime')}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimePicker
              label="End Time"
              value={formData.endTime}
              onChange={handleDateChange('endTime')}
              sx={{ width: '100%' }}
            />
          </Grid>

          {/* Fee */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="fee"
              label="Performance Fee"
              type="text"
              inputMode="numeric"
              value={formData.fee}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Status */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Calculator Button */}
          <Grid item xs={12}>
            <Box sx={{ mb: 3 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setCalculatorOpen(true)}
                fullWidth
              >
                Open Event Calculator
              </Button>
            </Box>
          </Grid>

          {/* Costs Section */}
          <Grid item xs={12}>
            <Accordion defaultExpanded sx={{ mb: 3 }}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  '&.MuiAccordionSummary-root': {
                    minHeight: 64
                  }
                }}
              >
                <Typography variant="h6" sx={{ m: 0 }}>Costs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {/* Travel Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Travel</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Flights"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.flights}
                          onChange={handleNestedChange('travel', 'flights')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Ground Transport"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.groundTransport}
                          onChange={handleNestedChange('travel', 'groundTransport')}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          label="Other Travel Costs"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.travel.other}
                          onChange={handleNestedChange('travel', 'other')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Accommodation Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Accommodation</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Hotel Cost per Night"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.accommodation.hotelCost}
                          onChange={handleNestedChange('accommodation', 'hotelCost')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Number of Nights"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.accommodation.numberOfNights}
                          onChange={handleNestedChange('accommodation', 'numberOfNights')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Per Diem Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Per Diem</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Daily Rate"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.perDiem.dailyRate}
                          onChange={handleNestedChange('perDiem', 'dailyRate')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Number of Days"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.perDiem.numberOfDays}
                          onChange={handleNestedChange('perDiem', 'numberOfDays')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Equipment Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Equipment</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Equipment Rental"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.equipment.rental}
                          onChange={handleNestedChange('equipment', 'rental')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Shipping"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.equipment.shipping}
                          onChange={handleNestedChange('equipment', 'shipping')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Other Costs Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6">Other Costs</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          label="Marketing"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.marketing}
                          onChange={handleNestedChange('marketing', '')}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          label="Insurance"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.insurance}
                          onChange={handleNestedChange('insurance', '')}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          label="Visas"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.visas}
                          onChange={handleNestedChange('visas', '')}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          label="Other"
                          type="text"
                          inputMode="numeric"
                          value={formData.costs.other}
                          onChange={handleNestedChange('other', '')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Cost Summary Section */}
                  <Grid item xs={12}>
  <Box sx={{ 
    bgcolor: 'background.paper', 
    p: 3, 
    borderRadius: 1,
    mt: 3
  }}>
    <Typography variant="h6" gutterBottom>Cost Summary</Typography>
    <Typography>Band Fee: €{parseFloat(costs.travel.flights || '0').toFixed(2)}</Typography>
<Typography>Additional Costs: €{parseFloat(costs.travel.groundTransport || '0').toFixed(2)}</Typography>
<Typography>
  Contingency ({formData.contingencyRate}%): €{contingency.toFixed(2)}
</Typography>
<Typography variant="h6" sx={{ mt: 2 }}>
  Total Including Fee and Contingency: €{(parseFloat(costs.travel.flights || '0') + contingency).toFixed(2)}
</Typography>
  </Box>
</Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Contingency Rate */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="contingencyRate"
              label="Contingency Rate (%)"
              type="text"
              inputMode="numeric"
              value={formData.contingencyRate}
              onChange={handleChange}
            />
          </Grid>

          {/* Notes */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="notes"
              label="Notes"
              multiline
              rows={4}
              value={formData.notes}
              onChange={handleChange}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              size="large"
              fullWidth
              sx={{ mt: 2 }}
            >
              {isEdit ? 'Update Performance' : 'Add Performance'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Event Calculator Dialog */}
      {calculatorOpen && (
        <EventCalculator
          onClose={() => setCalculatorOpen(false)}
          onSubmit={handleCalculatorSubmit}
          defaultCurrency="EUR"
        />
      )}
    </LocalizationProvider>
  );
};

export default PerformanceForm;