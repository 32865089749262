// ProductionBudgetSummary.tsx
import React, { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import ExpensePieChart from './graphs/ExpensePieChart';
import TagCostBarChart from './graphs/TagCostBarChart';
import DetailedCostTable from './DetailedCostTable';
import CodeRangeTreemap from './graphs/CodeRangeTreemap';
import IncomeDistributionTreemap from './graphs/IncomeDistributionTreemap';
import { GroupBudgetLine } from '../../../types/groupBudget';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';

interface ProductionBudgetSummaryProps {
  expenseLines: GroupBudgetLine[];
  incomeLines: GroupBudgetLine[]; // <-- new

}

const ProductionBudgetSummary: React.FC<ProductionBudgetSummaryProps> = ({ expenseLines,incomeLines
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState<string | null>(null);
  const [filter, setFilter] = useState<{ type: string; value: string | null }>({
    type: 'category',
    value: null
  });
  const handleCategorySelect = (category: string | null) => {
    setSelectedCategory(category);
    setSelectedRange(null);
  };

  const handleRangeSelect = (range: string | null) => {
    setSelectedRange(range);
    setSelectedCategory(null);
  };

  const filteredExpenses = expenseLines.filter(line => {
    if (!selectedCategory && !selectedRange) return true;
    if (selectedCategory) {
      const codeDetails = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      return codeDetails?.category === selectedCategory;
    }
    if (selectedRange) {
      const [start] = selectedRange.split('-').map(Number);
      const codeNum = parseInt(line.code);
      return codeNum >= start && codeNum < start + 10;
    }
    return true;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, height: '400px' }}>
        <ExpensePieChart
  expenseLines={expenseLines}
  incomeLines={incomeLines}
/>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 2, height: '400px' }}>
          <TagCostBarChart 
            expenseLines={filteredExpenses}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, height: '300px' }}>
          <CodeRangeTreemap 
  expenseLines={expenseLines}
  onFilterChange={setFilter}
  selectedFilter={filter}
/>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, height: '300px' }}>
          <IncomeDistributionTreemap 
  incomeLines={incomeLines}
  onFilterChange={setFilter}
  selectedFilter={filter}
/>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <DetailedCostTable 
            expenseLines={filteredExpenses}
          />
        </Paper>
      </Grid>

    </Grid>
  );
};

export default ProductionBudgetSummary;