// src/components/ideas/QuickIdeaForm.tsx

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Typography,
  Autocomplete,
  InputAdornment,
  IconButton,
} from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import axiosInstance from '../../utils/axiosConfig';
import axios from 'axios';

interface Strand {
  _id: string;
  name: string;
  year: string;
}

interface QuickIdeaFormProps {
  onSubmit: () => void;
  onError: (error: string) => void;
}

const QuickIdeaForm: React.FC<QuickIdeaFormProps> = ({ onSubmit, onError }) => {
  const [formData, setFormData] = useState({
    title: '',
    sourceType: 'other',
    sourceUrl: '',
    notes: '',
    priority: 'medium',
    tags: [] as string[],
    strandId: '',
  });

  const [strands, setStrands] = useState<Strand[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      let isSubscribed = true;
      
      const fetchStrands = async () => {
        if (!isSubscribed) return;
        setLoading(true);
        try {
          const response = await axiosInstance.get('/api/strands');
          if (!isSubscribed) return;
          
          const mappedStrands = response.data.map((strand: any) => ({
            _id: strand._id,
            name: strand.name,
            year: strand.year?.toString()
          }));
          
          setStrands(mappedStrands);
          setError(null);
        } catch (error) {
          if (!isSubscribed) return;
          console.error('Error fetching strands:', error);
          const errorMessage = axios.isAxiosError(error)
            ? error.response?.data?.message || 'Failed to load strands'
            : 'An unexpected error occurred';
          setError(errorMessage);
          onError(errorMessage);
        } finally {
          if (isSubscribed) setLoading(false);
        }
      };
    
      fetchStrands();
    
      return () => {
        isSubscribed = false;
      };
    }, []); // Remove onError from dependencies

  // Helper function to determine source type based on URL/ID
  const determineSourceType = (url: string): string => {
    if (!url) return 'other';
    const lowerUrl = url.toLowerCase();

    if (lowerUrl.startsWith('message://')) {
      return 'email';
    }
    if (lowerUrl.startsWith('http://') || lowerUrl.startsWith('https://')) {
      if (lowerUrl.includes('spotify.com')) {
        return 'spotify';
      }
      // Add more specific checks for social media platforms if needed
      const socialMediaDomains = ['facebook.com', 'twitter.com', 'instagram.com', 'linkedin.com', 'tiktok.com'];
      for (const domain of socialMediaDomains) {
        if (lowerUrl.includes(domain)) {
          return 'social';
        }
      }
      return 'website';
    }
    return 'other';
  };

  const handleSourceUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    const newSourceType = determineSourceType(newUrl);
    setFormData((prevData) => ({
      ...prevData,
      sourceUrl: newUrl,
      sourceType: newSourceType,
    }));
  };
  

  const handleInsertOlink = async () => {
    const sourceInput = document.querySelector<HTMLInputElement>('input[name="sourceUrl"]');
    if (!sourceInput) return;
  
    sourceInput.focus();
  
    try {
      // Copy "olink" to the clipboard using the Clipboard API
      await navigator.clipboard.writeText('olink');
  
      // Simulate pasting into the input field
      sourceInput.focus();
      const pasteEvent = new ClipboardEvent('paste', { 
        bubbles: true, 
        cancelable: true, 
        clipboardData: new DataTransfer() 
      });
  
      pasteEvent.clipboardData?.setData('text/plain', 'olink');
      sourceInput.dispatchEvent(pasteEvent);
      
      // Update the value of the input to match the pasted content
      sourceInput.value += 'olink'; // Append 'olink' to the current value
      const inputEvent = new Event('input', { bubbles: true });
      sourceInput.dispatchEvent(inputEvent);
      
      // Update React state
      setFormData((prevData) => ({
        ...prevData,
        sourceUrl: sourceInput.value,
        sourceType: 'email',
      }));
  
    } catch (error) {
      console.error('Clipboard API not available', error);
    }
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.title.trim()) {
      setError('Title is required');
      onError('Title is required');
      return;
    }

    try {
      const ideaData = {
        title: formData.title,
        sourceType: formData.sourceType,
        sourceUrl: formData.sourceUrl,
        notes: formData.notes,
        priority: formData.priority,
        tags: formData.tags,
        associations: {
          strandId: formData.strandId,
        },
      };

      await axiosInstance.post('/api/ideas/quick', ideaData);
      onSubmit();
      // Reset form after successful submission
      setFormData({
        title: '',
        sourceType: 'other',
        sourceUrl: '',
        notes: '',
        priority: 'medium',
        tags: [],
        strandId: '',
      });
    } catch (error) {
      console.error('Error saving idea:', error);
      setError('Failed to save idea');
      onError('Failed to save idea');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Quick Title */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Quick Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            required
          />
        </Grid>

        {/* Source Type */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Source Type</InputLabel>
            <Select
              value={formData.sourceType}
              onChange={(e) => setFormData({ ...formData, sourceType: e.target.value })}
              required
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="website">Website</MenuItem>
              <MenuItem value="spotify">Spotify</MenuItem>
              <MenuItem value="social">Social Media</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Associate with Strand */}
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={strands}
            groupBy={(option) => option.year}
            getOptionLabel={(option) => option.name}
            loading={loading}
            onChange={(_, newValue) => {
              setFormData({ ...formData, strandId: newValue?._id || '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Associate with Strand"
                error={!!error}
                helperText={error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <Typography variant="body2">Loading...</Typography> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderGroup={(params) => (
              <div key={params.key}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ pl: 2, fontWeight: 'bold' }}
                >
                  {params.group}
                </Typography>
                {params.children}
              </div>
            )}
          />
        </Grid>

        {/* Source URL/ID with Insert Olink Button */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Source URL/ID"
            value={formData.sourceUrl}
            onChange={handleSourceUrlChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Insert olink"
                    onClick={handleInsertOlink}
                    edge="end"
                    title="Insert 'olink' command"
                  >
                    <InsertLinkIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {/* Quick Notes (Optional) */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Quick Notes"
            multiline
            rows={3}
            value={formData.notes}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button 
            type="submit" 
            variant="contained" 
            fullWidth
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save Idea'}
          </Button>
        </Grid>

        {/* Error Message */}
        {error && (
          <Grid item xs={12}>
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default QuickIdeaForm;