import React, { useState, useEffect, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventDropArg, DateSelectArg, EventClickArg } from '@fullcalendar/core';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Chip,
  Tooltip,
  DialogContentText,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel
} from '@mui/material';
import { AlertCircle } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';

interface DateItem {
  id: string;
  startDate: string;
  endDate: string;
}

interface ProvisionalDateItem extends DateItem {
  status: 'tentative' | 'investigating' | 'confirmed';
  notes?: string;
}

interface ProvisionalDates {
  strandId: string;
  planningDates: ProvisionalDateItem[];
  lastUpdated: Date;
  lastUpdatedBy: {
    _id: string;
    name: string;
    email: string;
  };
}

interface Strand {
  id: string;
  name: string;
  targetDates: number;
  type: StrandType;
  dates?: DateItem[];
}

type StrandType = 'domestic' | 'international' | 'commission' | 'series' | 'trade-show';

interface StrandEvent {
  id: string;
  _id?: string;
  title: string;
  start: string;
  end?: string;
  strandId: string;
  strandName: string;
  color?: string;
  status?: 'tentative' | 'investigating' | 'confirmed';
  notes?: string;
  display?: 'auto' | 'background';
}

interface ConflictMap {
  [key: string]: string[];
}

interface EventPlannerModalProps {
  open: boolean;
  onClose: () => void;
  currentStrand: Strand | null;
  allStrands: Strand[];
  existingEvents?: StrandEvent[];
}

const STRAND_COLORS: Record<StrandType, string> = {
  domestic: '#4CAF50',
  international: '#2196F3',
  commission: '#FF9800',
  series: '#9C27B0',
  'trade-show': '#F44336'
};

const EventPlannerModal = ({
  open,
  onClose,
  currentStrand,
  allStrands,
  existingEvents = []
}: EventPlannerModalProps): JSX.Element => {
  const [events, setEvents] = useState<StrandEvent[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [conflicts, setConflicts] = useState<ConflictMap>({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<StrandEvent | null>(null);
  const [showEventDetails, setShowEventDetails] = useState(false);

  const currentStrandEvents = useMemo(() => 
    events.filter(event => event.strandId === currentStrand?.id),
    [events, currentStrand]
  );

  const remainingEvents = useMemo(() => {
    if (!currentStrand) return 0;
    return currentStrand.targetDates - currentStrandEvents.length;
  }, [currentStrand, currentStrandEvents]);

  useEffect(() => {
    if (!open || !currentStrand) return;
    fetchProvisionalDates();
  }, [open, currentStrand]);

  const fetchProvisionalDates = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/provisional-dates');
      const data = response.data;
      console.log('Raw API response:', data);
  
      const processedEvents: StrandEvent[] = [];
  
      data.forEach((doc: ProvisionalDates | any) => {
        if (Array.isArray(doc.planningDates)) {
          doc.planningDates.forEach((planningDate: any) => {
            const isCurrentStrand = doc.strandId === currentStrand?.id;
            processedEvents.push({
              id: planningDate.id,
              title: allStrands.find(s => s.id === doc.strandId)?.name || 'Unknown Strand',
              start: planningDate.startDate,
              end: planningDate.endDate || planningDate.startDate,
              strandId: doc.strandId,
              strandName: allStrands.find(s => s.id === doc.strandId)?.name || 'Unknown Strand',
              color: isCurrentStrand 
                ? '#FF5722'  // Active strand color
                : STRAND_COLORS[allStrands.find(s => s.id === doc.strandId)?.type || 'domestic'],
              status: planningDate.status || 'tentative',
              notes: planningDate.notes,
              display: 'auto' // Set to 'auto' to ensure visibility
            });
          });
        } else if (doc.start && doc.end) {
          const isCurrentStrand = doc.strandId === currentStrand?.id;
          processedEvents.push({
            id: doc.id,
            title: doc.strandName || 'Unknown Strand',
            start: doc.start,
            end: doc.end,
            strandId: doc.strandId,
            strandName: doc.strandName || 'Unknown Strand',
            color: isCurrentStrand 
              ? '#FF5722'  // Active strand color
              : STRAND_COLORS[doc.strandType as StrandType || 'domestic'],
            status: doc.status || 'tentative',
            notes: doc.notes || '',
            display: 'auto'
          });
        } else {
          console.warn(`Invalid or unrecognized document format: ${JSON.stringify(doc)}`);
        }
      });
  
      console.log('Processed events:', processedEvents);
      setEvents(processedEvents);
  
    } catch (error) {
      console.error('Failed to fetch provisional dates:', error);
      setError('Failed to load provisional dates');
    } finally {
      setLoading(false);
    }
  };

  const checkForConflicts = (newEvent: StrandEvent): StrandEvent[] => {
    return events.filter(event => {
      const eventStart = new Date(event.start);
      const eventEnd = event.end ? new Date(event.end) : new Date(event.start);
      const newStart = new Date(newEvent.start);
      const newEnd = newEvent.end ? new Date(newEvent.end) : new Date(newEvent.start);

      return (
        eventStart <= newEnd &&
        eventEnd >= newStart &&
        event.strandId !== newEvent.strandId
      );
    });
  };

  const handleDateSelect = (info: DateSelectArg) => {
    if (!currentStrand || remainingEvents <= 0) return;
  
    // Check if there's already an event on this date for the current strand
    const existingEvent = events.find(event => {
      const eventDate = new Date(event.start).toDateString();
      const selectedDate = new Date(info.startStr).toDateString();
      return eventDate === selectedDate && event.strandId === currentStrand.id;
    });
  
    if (existingEvent) {
      // If there's already an event, delete it
      setEvents(prevEvents => prevEvents.filter(event => event.id !== existingEvent.id));
      
      // Clean up any conflicts
      setConflicts(prev => {
        const newConflicts = { ...prev };
        delete newConflicts[existingEvent.id];
        return newConflicts;
      });
      return;
    }
  
    // If no existing event, create a new one
    const newEvent: StrandEvent = {
      id: `new-${Date.now()}`,
      title: currentStrand.name,
      start: info.startStr,
      end: info.endStr,
      strandId: currentStrand.id,
      strandName: currentStrand.name,
      color: '#FF5722',  // Active strand color
      status: 'tentative',
      display: 'auto'
    };

    const conflictingEvents = checkForConflicts(newEvent);
  if (conflictingEvents.length > 0) {
    setConflicts(prev => ({
      ...prev,
      [newEvent.id]: conflictingEvents.map(e => e.strandName)
    }));
  }

  setEvents(prev => [...prev, newEvent]);
};

  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleEventClick = (clickInfo: EventClickArg) => {
    console.log('Clicked event:', clickInfo.event);
    console.log('Event ID:', clickInfo.event.id);
    console.log('Event strandId:', clickInfo.event.extendedProps.strandId);
    console.log('Current strand ID:', currentStrand?.id);
    
    const clickedEvent = events.find(e => e.id === clickInfo.event.id);
    console.log('Found event:', clickedEvent);
    
    if (clickedEvent && clickedEvent.strandId === currentStrand?.id) {
      console.log('Deleting event');
      setEvents(prevEvents => prevEvents.filter(event => event.id !== clickedEvent.id));
      
      // Clean up any conflicts
      setConflicts(prev => {
        const newConflicts = { ...prev };
        delete newConflicts[clickedEvent.id];
        return newConflicts;
      });
    } else {
      console.log('Event not deleted because:', !clickedEvent ? 'event not found' : 'strandId mismatch');
    }
  };

  const handleEventDrop = async (info: EventDropArg) => {
    const { id, title, extendedProps } = info.event;
  
    // Safely handle dates
    const startDate = info.event.start ? new Date(info.event.start).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const endDate = info.event.end ? new Date(info.event.end).toISOString().split('T')[0] : startDate;
  
    // Find the specific planning date being moved
    const updatedEvent = events.find(event => event.id === id);
    if (!updatedEvent) return;
  
    const movedEvent: StrandEvent = {
      ...updatedEvent,
      start: startDate,
      end: endDate,
    };
  
    // Update only the specific event in state
    setEvents(prevEvents => 
      prevEvents.map(event => event.id === id ? movedEvent : event)
    );
  
    try {
      // Update only this specific planning date
      const response = await axiosInstance.patch(
        `/api/provisional-dates/${movedEvent.strandId}/dates/${movedEvent.id}`,
        {
          startDate,
          endDate,
          status: movedEvent.status,
          notes: movedEvent.notes,
        }
      );
  
      if (response.data) {
        await fetchProvisionalDates(); // Refresh all dates to ensure consistency
      }
    } catch (error) {
      console.error(`Failed to update event ${movedEvent.id}:`, error);
      // Roll back only this specific event
      setEvents(prevEvents =>
        prevEvents.map(event =>
          event.id === id
            ? {
                ...event,
                start: info.oldEvent.start?.toISOString().split('T')[0] || '',
                end: info.oldEvent.end?.toISOString().split('T')[0] || '',
              }
            : event
        )
      );
      setError('Failed to update event. Please try again.');
    }
  };
  

  const handleDeleteConfirm = (confirmed: boolean) => {
    if (confirmed && selectedEvent) {
      setEvents(prevEvents => 
        prevEvents.filter(event => event.id !== selectedEvent.id)
      );
      setConflicts(prev => {
        const newConflicts = { ...prev };
        delete newConflicts[selectedEvent.id];
        return newConflicts;
      });
    }
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    setError(null);
    
    try {
      // Map each planning date individually
      const planningDates = currentStrandEvents.map(event => ({
        id: event.id.startsWith('new-') ? `${Date.now()}` : event.id,
        startDate: new Date(event.start).toISOString().split('T')[0],
        endDate: event.end 
          ? new Date(event.end).toISOString().split('T')[0] 
          : new Date(event.start).toISOString().split('T')[0],
        status: event.status || 'tentative',
        notes: event.notes,
        _id: event._id // Preserve the MongoDB _id if it exists
      }));
  
      await axiosInstance.post(`/api/provisional-dates/${currentStrand?.id}`, { 
        planningDates: planningDates.map(date => ({
          ...date,
          _id: undefined // Remove _id for new entries
        })),
        lastUpdated: new Date().toISOString(),
        lastUpdatedBy: '670561ef3fef62cdc14cf5c8' // Replace with actual user ID
      });
      
      onClose();
    } catch (err) {
      console.error('Failed to save provisional dates:', err);
      setError('Failed to save provisional dates');
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateEventDetails = (
    status: 'tentative' | 'investigating' | 'confirmed',
    notes: string,
    startDate: string,
    endDate: string
  ) => {
    if (!selectedEvent) return;

    setEvents(prevEvents =>
      prevEvents.map(event =>
        event.id === selectedEvent.id
          ? { 
              ...event, 
              status, 
              notes, 
              start: startDate, 
              end: endDate || startDate
            }
          : event
      )
    );

    setSelectedEvent({
      ...selectedEvent, 
      status, 
      notes, 
      start: startDate, 
      end: endDate || startDate
    });

    setShowEventDetails(false);
  };

  const renderEventContent = (eventInfo: { event: any }) => {
    const event = eventInfo.event;
    const hasConflict = conflicts[event.id];
    
    return (
      <Box className="flex items-center gap-1 p-1">
        {hasConflict && (
          <Tooltip title={`Conflicts with: ${conflicts[event.id].join(', ')}`}>
            <AlertCircle size={16} color="error" />
          </Tooltip>
        )}
        <Typography variant="caption" noWrap>
          {event.title}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Dialog 
        open={Boolean(open)}
        onClose={onClose}
        maxWidth={false}
        fullWidth
        PaperProps={{
          sx: { height: '90vh', maxWidth: '95vw', m: 2 }
        }}
      >
        <DialogTitle>
          <Box className="flex justify-between items-center">
            <Typography variant="h5">Event Planner for {currentStrand?.name}</Typography>
            {currentStrand && (
              <Box className="flex items-center gap-2">
                <Chip 
                  label={`Target: ${currentStrand.targetDates} events`}
                  color="primary"
                  variant="outlined"
                />
                <Chip 
                  label={`Planned: ${currentStrandEvents.length} events`}
                  color={currentStrandEvents.length >= (currentStrand?.targetDates || 0) ? "success" : "warning"}
                />
                <Chip 
                  label={`Remaining: ${remainingEvents}`}
                  color={remainingEvents > 0 ? "warning" : "success"}
                />
              </Box>
            )}
          </Box>
        </DialogTitle>
        
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
          )}
          
          <Box sx={{ height: 'calc(100% - 32px)' }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
<FullCalendar
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  initialView="dayGridMonth"
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek'
  }}
  selectable={remainingEvents > 0}
  editable
  events={events}
  select={handleDateSelect}
  eventDrop={handleEventDrop}
  eventClick={handleEventClick}
  eventContent={renderEventContent}
  height="100%"
  expandRows
  dayMaxEventRows={6}
  selectLongPressDelay={10}
  eventBackgroundColor="transparent"
  eventBorderColor="transparent"
  eventDisplay="block" // Ensure events are displayed as blocks
  displayEventEnd={true}
  eventInteractive={true}
  eventOverlap={true} // Allow events to overlap
  slotEventOverlap={true} // Allow events to overlap in time slots
  eventClassNames="event-display"
  eventDidMount={(info) => {
    const eventEl = info.el;
    if (info.event.extendedProps.strandId === currentStrand?.id) {
      eventEl.style.zIndex = '2';
      eventEl.style.opacity = '1';
    } else {
      eventEl.style.zIndex = '1';
      eventEl.style.opacity = '0.7';
    }
    console.log('Event mounted:', {
      id: info.event.id,
      title: info.event.title,
      start: info.event.start,
      end: info.event.end,
      strandId: info.event.extendedProps.strandId
    });
  }}
/>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button 
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showEventDetails}
        onClose={() => setShowEventDetails(false)}
      >
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
          {selectedEvent && (
            <Box sx={{ pt: 2 }}>
              <Typography variant="subtitle1">Start Date</Typography>
              <TextField
                fullWidth
                type="date"
                value={selectedEvent.start ? selectedEvent.start.slice(0, 10) : ''}
                onChange={(e) => handleUpdateEventDetails(
                  selectedEvent.status || 'tentative',
                  selectedEvent.notes || '',
                  e.target.value,
                  selectedEvent.end || ''
                )}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1">End Date</Typography>
              <TextField
                fullWidth
                type="date"
                value={selectedEvent.end ? selectedEvent.end.slice(0, 10) : (selectedEvent.start ? selectedEvent.start.slice(0, 10) : '')}
                onChange={(e) => handleUpdateEventDetails(
                  selectedEvent.status || 'tentative',
                  selectedEvent.notes || '',
                  selectedEvent.start || '',
                  e.target.value
                )}
                sx={{ mb: 2 }}
              />

              {/* Status Dropdown */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedEvent.status ?? 'tentative'}
                  label="Status"
                  onChange={(e) => {
                    const status = e.target.value as 'tentative' | 'investigating' | 'confirmed';
                    handleUpdateEventDetails(
                      status,
                      selectedEvent.notes || '',
                      selectedEvent.start || '',
                      selectedEvent.end || ''
                    );
                  }}
                >
                  <MenuItem value="tentative">Tentative</MenuItem>
                  <MenuItem value="investigating">Investigating</MenuItem>
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                </Select>
              </FormControl>

              {/* Notes Field */}
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Notes"
                defaultValue={selectedEvent.notes}
                onChange={(e) => {
                  const notes = e.target.value;
                  handleUpdateEventDetails(
                    selectedEvent.status ?? 'tentative',
                    notes ?? '',
                    selectedEvent.start ?? '',
                    selectedEvent.end ?? ''
                  );
                }}
              />

              {/* Show any conflicts */}
              {conflicts[selectedEvent.id] && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                  This event conflicts with: {conflicts[selectedEvent.id].join(', ')}
                </Alert>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteConfirm(true)} color="error">
            Delete
          </Button>
          <Button onClick={() => setShowEventDetails(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventPlannerModal;