// CurrentEventsSalesOverview.tsx
import React from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer, 
  CartesianGrid 
} from 'recharts';
import { Box, Typography, useTheme } from '@mui/material';

interface Event {
  title: string;
  totalSold: number;
  totalAvailable: number;
}

interface CurrentEventsSalesOverviewProps {
  events: Event[];
}

const CurrentEventsSalesOverview: React.FC<CurrentEventsSalesOverviewProps> = ({ events }) => {
  const theme = useTheme();

  // Process events data for the chart
  const chartData = events.map((event: Event) => ({
    name: event.title,
    percentage: event.totalAvailable
      ? Math.round((event.totalSold / event.totalAvailable) * 100)
      : 0, // Handle division by zero
    sold: event.totalSold,
    total: event.totalAvailable,
  })).sort((a, b) => b.percentage - a.percentage);

  console.log('Chart Data:', chartData); // Debugging

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box 
          sx={{ 
            backgroundColor: theme.palette.background.paper,
            padding: '12px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[1]
          }}
        >
          <Typography variant="subtitle2" color="text.primary">{label}</Typography>
          <Typography variant="body2" color="text.secondary">
            {payload[0].payload.sold} of {payload[0].payload.total} tickets sold ({payload[0].value}%)
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Typography variant="h5" sx={{ mb: 2, color: theme.palette.text.primary }}>
        Sales Overview
      </Typography>
      <Box
        sx={{
          height: 400, // Fixed height to ensure the chart is visible
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2,
          padding: 2,
          boxShadow: theme.shadows[1],
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            layout="vertical"
            margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} horizontal={false} />
            <XAxis
              type="number"
              domain={[0, 100]}
              tickFormatter={(value) => `${value}%`}
              stroke={theme.palette.text.secondary}
            />
            <YAxis
              type="category"
              dataKey="name"
              width={150}
              stroke={theme.palette.text.secondary}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="percentage"
              fill={theme.palette.primary.main}
              radius={[0, 4, 4, 0]}
              label={{
                position: 'right',
                fill: theme.palette.text.primary,
                formatter: (value: any, entry: any) => {
                    if (entry && entry.payload) {
                      return `${entry.payload.sold}/${entry.payload.total}`;
                    }
                    return '';
                  }
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default CurrentEventsSalesOverview;