// src/components/cooler/inventory/components/ItemDetails/ImageGallery.tsx

import React, { useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import { 
  X as CloseIcon,
  Maximize2,
  Image as ImageIcon,
} from 'lucide-react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

import { Upload } from '../../../../shared/Upload';
import type { ImageReference } from '../../../types/inventoryTypes';
import type { InventoryUpdateData } from '../../../types/inventoryTypes';

interface ImageGalleryProps {
  images: ImageReference[];
  isEditing: boolean;
  onUpdate: (images: ImageReference[]) => void;
  setEditedItem: React.Dispatch<React.SetStateAction<InventoryUpdateData | null>>;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ 
  images, 
  isEditing, 
  onUpdate, 
  setEditedItem 
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleRemoveImage = (index: number) => {
    const newImages = images.filter((_, i) => i !== index);
    onUpdate(newImages);
  };

  const handleImageUpload = async (urls: string[]) => {
    const newImageRefs: ImageReference[] = urls.map(url => ({
      url,
      uploadedAt: new Date(),
      uploadedBy: '670561ef3fef62cdc14cf5c8', // TODO: Get from auth context
      originalFilename: url.split('/').pop() || ''
    }));
    
    setEditedItem(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        images: [...(prev.images || []), ...newImageRefs]
      };
    });
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  if (images.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          p: 4,
          border: '2px dashed',
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <ImageIcon size={48} color="action" />
        <Typography color="text.secondary">
          No images available
        </Typography>
        {isEditing && (
          <Upload
            onUploadComplete={handleImageUpload}
            accept="image/*"
            maxFiles={5}
            type="images"
          />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} flexWrap="wrap" gap={2}>
        {images.map((img, index) => (
          <Box 
            key={index} 
            sx={{ 
              position: 'relative',
              '&:hover .image-overlay': {
                opacity: 1,
              },
            }}
          >
            <Box
              sx={{
                width: 200,
                height: 200,
                borderRadius: 1,
                overflow: 'hidden',
                cursor: 'pointer',
                position: 'relative',
              }}
              onClick={() => handleImageClick(index)}
            >
              <img
                src={img.url}
                alt={`Item Image ${index + 1}`}
                style={{ 
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                className="image-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  bgcolor: 'rgba(0,0,0,0.3)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0,
                  transition: 'opacity 0.2s',
                }}
              >
                <Maximize2 color="white" size={24} />
              </Box>
            </Box>
            {isEditing && (
              <IconButton
                size="small"
                sx={{ 
                  position: 'absolute', 
                  top: 8, 
                  right: 8, 
                  bgcolor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.7)',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveImage(index);
                }}
              >
                <CloseIcon size={16} />
              </IconButton>
            )}
          </Box>
        ))}
      </Stack>
      
      {isEditing && (
        <Box sx={{ mt: 2 }}>
          <Upload
            onUploadComplete={handleImageUpload}
            accept="image/*"
            maxFiles={5}
            type="images"
          />
        </Box>
      )}

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        index={currentImageIndex}
        slides={images.map(img => ({ src: img.url }))}
        plugins={[Zoom]}
        animation={{ zoom: 500 }}
        carousel={{
          spacing: 0,
          padding: 0,
        }}
      />
    </Box>
  );
};

export default ImageGallery;