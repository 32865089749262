// IncomeDistributionTreemap.tsx
import React, { useState } from 'react';
import { Treemap, ResponsiveContainer } from 'recharts';
import { Typography, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { IMC_INCOME_CODES } from '../../../../constants/imcCodes';
import { GroupBudgetLine } from '../../../../types/groupBudget';

interface IncomeDistributionProps {
  /** The array of income lines coming from your budget data */
  incomeLines: GroupBudgetLine[];
  /** Callback to notify the parent about filter changes */
  onFilterChange: (filter: { type: string; value: string | null }) => void;
  /** Current filter state */
  selectedFilter: { type: string; value: string | null };
}

/** The different ways you want to group or color-code the treemap */
type ViewMode = 'imc' | 'subcategory' | 'tags' | 'ac';

const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', 
  '#FFBE0B', '#9B5DE5', '#4A4E69', '#845EC2'
];

/** A helper function for currency formatting. */
const formatCurrency = (amount: number): string =>
  new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(amount);

const IncomeDistributionTreemap: React.FC<IncomeDistributionProps> = ({
  incomeLines,
  onFilterChange,
  selectedFilter
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>('imc');
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  /**
   * Convert the raw income lines into treemap-ready data,
   * grouping them based on the selected `viewMode`.
   */
  const data = React.useMemo(() => {
    const groupedData = new Map<string, {
      value: number;
      codes: string[];
      details: string;
    }>();

    incomeLines.forEach(line => {
      const codeDetails = Object.values(IMC_INCOME_CODES).find(c => c.code === line.code);
      if (!codeDetails) return; // code is unknown, skip

      // Calculate the total € for this line
      const amount =
        (line.quantity || 0) *
        (line.value || 0) *
        (line.multiplier || 0);

      let key: string;
      let details: string;

      switch (viewMode) {
        case 'imc':
          // Example: if label is "INC-01 - Ticket Sales"
          // We'll skip the "INC-01" part, keep "Ticket Sales"
          // or you can just do key = codeDetails.label
          key = codeDetails.label.split(' - ').slice(1).join(' - ');
          details = codeDetails.label;
          break;
        case 'subcategory':
          // If subCategories = ['tickets', 'live']
          key = codeDetails.subCategories[0];
          details = codeDetails.subCategories.join(', ');
          break;
        case 'tags':
          // If tags = ['box-office', 'online']
          key = codeDetails.tags[0];
          details = codeDetails.tags.join(', ');
          break;
        case 'ac':
          // If there's an accounting code or fallback
          key = codeDetails.acCode || 'Uncategorized';
          details = codeDetails.acCode || 'Uncategorized';
          break;
        default:
          // Just fallback to the entire label
          key = codeDetails.label;
          details = codeDetails.label;
      }

      const existing = groupedData.get(key) || { value: 0, codes: [], details };
      existing.value += amount;
      if (!existing.codes.includes(codeDetails.code)) {
        existing.codes.push(codeDetails.code);
      }
      groupedData.set(key, existing);
    });

    // If we're zoomed in on a single item, only show that item.
    if (focusedItem && groupedData.has(focusedItem)) {
      const item = groupedData.get(focusedItem)!;
      return [
        {
          name: focusedItem,
          value: item.value,
          codes: item.codes,
          details: item.details,
          color: COLORS[0]
        }
      ];
    }

    // Otherwise, produce the full list.
    return Array.from(groupedData.entries())
      .map(([name, info], index) => ({
        name,
        value: info.value,
        codes: info.codes,
        details: info.details,
        color: COLORS[index % COLORS.length]
      }))
      .sort((a, b) => b.value - a.value);
  }, [incomeLines, viewMode, focusedItem]);

  /**
   * Handle clicking on a rectangle in the treemap:
   * zoom in if not already zoomed, or zoom out if we're clicking again.
   * Also calls onFilterChange if you want external filtering logic.
   */
  const handleItemClick = (name: string, codes: string[]) => {
    if (focusedItem === name) {
      // Clicking again toggles us back out.
      setFocusedItem(null);
      onFilterChange({ type: viewMode, value: null });
    } else {
      // Focus on this chunk
      setFocusedItem(name);
      // If you want to filter by the first matching code:
      onFilterChange({ type: 'imc', value: codes[0] });
    }
  };

  /**
   * Custom render function for each treemap cell (rectangle).
   * We can display the name, currency, and percentage
   * if there's enough space.
   */
  const CustomContent = (props: any) => {
    const { x, y, width, height, name, value, color, codes } = props;
    if (!name || !value) return null;

    const total = data.reduce((sum, d) => sum + d.value, 0);
    const percentage = (value / total) * 100;

    return (
      <g>
        {/* The clickable rectangle */}
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: color || '#000',
            stroke: '#fff',
            // If the selectedFilter’s value is in this cell’s codes,
            // draw a thicker stroke:
            strokeWidth:
              selectedFilter.value && codes.includes(selectedFilter.value)
                ? 2
                : 1,
            cursor: 'pointer',
          }}
          onClick={() => handleItemClick(name, codes)}
        />

        {/* Only show text if there's enough space */}
        {width > 50 && height > 30 && (
          <>
            <text
              x={x + width / 2}
              y={y + height / 2 - 16}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={12}
              style={{
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial',
              }}
            >
              {name}
            </text>
            <text
              x={x + width / 2}
              y={y + height / 2 + 4}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={11}
              style={{
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial',
              }}
            >
              {formatCurrency(value)}
            </text>
            <text
              x={x + width / 2}
              y={y + height / 2 + 20}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#fff"
              fontSize={10}
              style={{
                pointerEvents: 'none',
                fontWeight: 500,
                textRendering: 'geometricPrecision',
                fontFamily: 'Arial',
              }}
            >
              {percentage.toFixed(1)}%
            </text>
          </>
        )}
      </g>
    );
  };

  /**
   * When the user changes the grouping method (IMC code, subcategory, tags, etc.),
   * reset everything.
   */
  const handleViewChange = (newMode: ViewMode) => {
    setViewMode(newMode);
    setFocusedItem(null);
    onFilterChange({ type: newMode, value: null });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Header + View Mode Selection */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Income Distribution
          {focusedItem && (
            <Typography
              component="span"
              variant="body2"
              sx={{
                ml: 2,
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': { textDecoration: 'underline' },
              }}
              onClick={() => {
                setFocusedItem(null);
                onFilterChange({ type: viewMode, value: null });
              }}
            >
              (Click to zoom out)
            </Typography>
          )}
        </Typography>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>View By</InputLabel>
          <Select
            value={viewMode}
            label="View By"
            onChange={(e) => handleViewChange(e.target.value as ViewMode)}
          >
            <MenuItem value="imc">IMC Codes</MenuItem>
            <MenuItem value="subcategory">Subcategories</MenuItem>
            <MenuItem value="tags">Tags</MenuItem>
            <MenuItem value="ac">AC Codes</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Actual treemap */}
      <Box sx={{ width: '100%', height: 240 }}>
        <ResponsiveContainer>
          <Treemap
            data={data}
            dataKey="value"
            stroke="#fff"
            fill="#8884d8"
            content={<CustomContent />}
            aspectRatio={4 / 3}
          />
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default IncomeDistributionTreemap;