import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Typography,
  Card,
  CardContent,
  Alert,
  Divider
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Treemap,
  Tooltip
} from 'recharts';
import { IMC_EXPENSE_CODES } from '../../../constants/imcCodes';
import _ from 'lodash';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

interface BudgetCostAnalysisProps {
  groupBudgets: Array<{
    _id: string;
    name: string;
    totalIncome: number;
    totalExpenses: number;
    expenseLines: Array<{
      code: string;
      quantity: number;
      value: number;
      multiplier: number;
    }>;
  }>;
}

function computeMedian(nums: number[]): number {
  if (!nums.length) return 0;
  const sorted = [...nums].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 !== 0
    ? sorted[mid]
    : (sorted[mid - 1] + sorted[mid]) / 2;
}

const BudgetCostAnalysis: React.FC<BudgetCostAnalysisProps> = ({ groupBudgets }) => {
  const costAnalysis = useMemo(() => {
    return groupBudgets
      .map(budget => ({
        name: budget.name.length > 25 ? budget.name.substring(0, 25) + '...' : budget.name,
        fullName: budget.name,
        income: budget.totalIncome,
        expenses: budget.totalExpenses,
        costToAC: budget.totalExpenses - budget.totalIncome,
      }))
      .sort((a, b) => b.costToAC - a.costToAC)
      .slice(0, 5);
  }, [groupBudgets]);

  const incomeAnalysis = useMemo(() => {
    return [...groupBudgets]
      .sort((a, b) => b.totalIncome - a.totalIncome)
      .slice(0, 5)
      .map(budget => ({
        name: budget.name.length > 25 ? budget.name.substring(0, 25) + '...' : budget.name,
        fullName: budget.name,
        income: budget.totalIncome,
        expenses: budget.totalExpenses,
        netPosition: budget.totalIncome - budget.totalExpenses
      }));
  }, [groupBudgets]);

  const artistFeesAnalysis = useMemo(() => {
    const artistFees = groupBudgets.flatMap(budget => 
      budget.expenseLines.filter(line => {
        const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
        return Array.from(code?.subCategories ?? []).includes('artist_fees');
      })
    );
    const international = artistFees.filter(line => {
      const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      return Array.from(code?.subCategories ?? []).includes('international');
    }).reduce((sum, line) => sum + (line.quantity * line.value * line.multiplier), 0);

    const domestic = artistFees.filter(line => {
      const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
      return Array.from(code?.subCategories ?? []).includes('domestic');
    }).reduce((sum, line) => sum + (line.quantity * line.value * line.multiplier), 0);

    return { international, domestic, total: international + domestic };
  }, [groupBudgets]);

  const financialMetrics = useMemo(() => {
    const totalIncome = _.sumBy(groupBudgets, 'totalIncome');
    const totalExpenses = _.sumBy(groupBudgets, 'totalExpenses');
    const averageIncome = totalIncome / groupBudgets.length;
    const averageExpenses = totalExpenses / groupBudgets.length;
    const incomeToExpenseRatio = (totalIncome / totalExpenses) * 100;
    return {
      totalIncome,
      totalExpenses,
      averageIncome,
      averageExpenses,
      incomeToExpenseRatio
    };
  }, [groupBudgets]);

  const treemapData = useMemo(() => {
    const allExpenses = groupBudgets.flatMap(budget => budget.expenseLines);
    const categoryTotals = _.chain(allExpenses)
      .groupBy(line => {
        const code = Object.values(IMC_EXPENSE_CODES).find(c => c.code === line.code);
        return code?.subCategories[0] || 'Other';
      })
      .mapValues(lines => 
        lines.reduce((sum, line) => sum + (line.quantity * line.value * line.multiplier), 0)
      )
      .value();

    return {
      name: 'Categories',
      children: Object.entries(categoryTotals)
        .map(([name, value]) => ({
          name: _.startCase(name),
          value,
        }))
        .sort((a, b) => b.value - a.value)
    };
  }, [groupBudgets]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-IE', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(value);
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload?.[0]) {
      return (
        <Card className="bg-white shadow-md">
          <CardContent>
            <Typography>{payload[0].payload.fullName || payload[0].payload.name}</Typography>
            {payload.map((p: any) => (
              <Typography key={p.dataKey} variant="body2" color="text.secondary">
                {_.startCase(p.dataKey)}: {formatCurrency(p.value)}
              </Typography>
            ))}
          </CardContent>
        </Card>
      );
    }
    return null;
  };

  const CustomTreemapContent = ({ x, y, width, height, index, name, value }: any) => {
    const COLORS_LEN = COLORS.length;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: COLORS[index % COLORS_LEN],
            stroke: '#fff',
            strokeWidth: 2,
          }}
        />
        <text
          x={x + width / 2}
          y={y + height / 2 - 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}
        >
          {name}
        </text>
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={12}
        >
          {formatCurrency(value)}
        </text>
      </g>
    );
  };

  // Additional lines using computeMedian
  const medianIncome = computeMedian(groupBudgets.map(b => b.totalIncome));
  const medianExpenses = computeMedian(groupBudgets.map(b => b.totalExpenses));
  const artistFeesToIncomePct = (artistFeesAnalysis.total / financialMetrics.totalIncome) * 100 || 0;
  const netPositiveCount = groupBudgets.filter(b => b.totalIncome > b.totalExpenses).length;
  const netNegativeCount = groupBudgets.length - netPositiveCount;
  const topCategory = treemapData.children[0]?.name || 'N/A';
  const topCategoryValue = treemapData.children[0]?.value || 0;
  const largestPositive = incomeAnalysis[0]
    ? `${incomeAnalysis[0].name} with a surplus of ${formatCurrency(
        incomeAnalysis[0].income - incomeAnalysis[0].expenses
      )}`
    : 'N/A';
  const largestNegative = costAnalysis[0]
    ? `${costAnalysis[0].name} with a shortfall of ${formatCurrency(
        costAnalysis[0].expenses - costAnalysis[0].income
      )}`
    : 'N/A';

  return (
    <Box className="space-y-8">
      <Paper className="p-6">
        <Typography variant="h6" gutterBottom>
          Top 5 Budgets by Cost to Arts Council
        </Typography>
        <Box style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={costAnalysis}
              margin={{ top: 20, right: 30, left: 60, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-35}
                textAnchor="end"
                height={100}
                interval={0}
                tick={{ fill: '#000', fontSize: 12 }}
              />
              <YAxis
                tickFormatter={formatCurrency}
                tick={{ fill: '#000', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="income" fill="#00C49F" stackId="a" name="Income" />
              <Bar dataKey="expenses" fill="#FF8042" stackId="a" name="Expenses" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <Paper className="p-6">
        <Typography variant="h6" gutterBottom>
          Top 5 Income Generating Budgets
        </Typography>
        <Box style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={incomeAnalysis}
              margin={{ top: 20, right: 30, left: 60, bottom: 100 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-35}
                textAnchor="end"
                height={100}
                interval={0}
                tick={{ fill: '#000', fontSize: 12 }}
              />
              <YAxis
                tickFormatter={formatCurrency}
                tick={{ fill: '#000', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="income" fill="#00C49F" name="Income" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <Paper className="p-6">
        <Typography variant="h6" gutterBottom>
          Overall Expense Category Distribution
        </Typography>
        <Box style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <Treemap
              data={[treemapData]}
              dataKey="value"
              stroke="#fff"
              content={<CustomTreemapContent />}
            >
              <Tooltip formatter={(value: any) => formatCurrency(value)} />
            </Treemap>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <Alert severity="info">
        <Typography variant="h4" gutterBottom>
          Key Budget Insights
        </Typography>
        
        <Typography variant="h4" gutterBottom>
          Overview
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 2 }}>
          <li>Total analyzed budgets: {groupBudgets.length}</li>
          <li>Total portfolio value: {formatCurrency(financialMetrics.totalExpenses)}</li>
          <li>Income to expense ratio: {financialMetrics.incomeToExpenseRatio.toFixed(1)}%</li>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h5" gutterBottom>
          Artist Fees Analysis
        </Typography>
        <Box component="ul" sx={{ mt: 1, mb: 2 }}>
          <li>Total artist fees: {formatCurrency(artistFeesAnalysis.total)}</li>
          <li>International artists: {formatCurrency(artistFeesAnalysis.international)} ({((artistFeesAnalysis.international / artistFeesAnalysis.total) * 100).toFixed(1)}%)</li>
          <li>Domestic artists: {formatCurrency(artistFeesAnalysis.domestic)} ({((artistFeesAnalysis.domestic / artistFeesAnalysis.total) * 100).toFixed(1)}%)</li>
          <li>Artist fees as % of total income: {artistFeesToIncomePct.toFixed(1)}%</li>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h5" gutterBottom>
          Financial Performance
        </Typography>
        <Box component="ul" sx={{ mt: 1 }}>
          <li>Average income per budget: {formatCurrency(financialMetrics.averageIncome)}</li>
          <li>Average cost to AC: {formatCurrency(financialMetrics.averageExpenses - financialMetrics.averageIncome)}</li>
          <li>Median income: {formatCurrency(medianIncome)}</li>
          <li>Median expenses: {formatCurrency(medianExpenses)}</li>
          <li>Budgets net positive: {netPositiveCount}, net negative: {netNegativeCount}</li>
          <li>Top expense category: {topCategory} ({formatCurrency(topCategoryValue)})</li>
          <li>
            Largest surplus: {largestPositive}
          </li>
          <li>
            Largest shortfall: {largestNegative}
          </li>
        </Box>
      </Alert>
    </Box>
  );
};

export default BudgetCostAnalysis;