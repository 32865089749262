import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Stack,
  Typography,
  Button,
  IconButton,
  Autocomplete,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import {
  AlertTriangle,
  Plus,
  X as CloseIcon,
  Calendar,
  Wrench,
  Clock,
  User,
} from 'lucide-react';

import { Upload } from '../../../shared/Upload';
import SubtaskForm from './SubtaskForm';
import TaskToolsList from '../shared/TaskToolsList';
import { TaskStatus, TaskPriority, TASK_STATUSES, TASK_PRIORITIES } from '../types/taskTypes';
import axiosInstance from '../../../../utils/axiosConfig';


// Types
export interface TaskTool {
  inventoryItem: string;
  quantity: number;
  notes?: string;
}

export interface Subtask {
  id: string;  // Frontend only - will be stripped before API call
  title: string;
  completed: boolean;
  assignedTo?: string;
  dueDate?: string;
}

export interface TaskFormValues {
  title: string;
  description: string;
  status: TaskStatus;
  priority: TaskPriority;
  category: 
  | 'maintenance' 
  | 'setup' 
  | 'repair' 
  | 'improvement' 
  | 'general'
  | 'bar_area'
  | 'stage'
  | 'organisation'
  | 'cooler_general'
  | 'notice_board'
  | 'outside_press'
  | 'presses_storage'
  | 'cooler_space'
  | 'audience_experience'
  | 'throughput'
  | 'lighting'
  | 'sound'
  | 'projectors'
  | 'security'
  | 'heating'
  | 'rehearsals';
 progress: number;
  dueDate: string;
  estimatedHours?: number;
  actualHours?: number;
  tools: TaskTool[];
  subtasks: Subtask[];
  assignedTo: string;
  watchers: string[];
  linkedInventoryItems: string[];
}

interface TaskFormProps {
  initialValues?: Partial<TaskFormValues>;
  onSubmit: (values: TaskFormValues) => void;
  onCancel: () => void;
  isEditing?: boolean;
  isSubmitting?: boolean;
}

const defaultValues: TaskFormValues = {
  title: '',
  description: '',
  status: 'todo',
  priority: 'medium',
  category: 'cooler_general',
  progress: 0,
  dueDate: new Date().toISOString(),
  tools: [],
  subtasks: [],
  assignedTo: '',
  watchers: [],
  linkedInventoryItems: [],
};

const CATEGORIES = [
    { value: 'maintenance', label: 'Maintenance' },
    { value: 'setup', label: 'Setup' },
    { value: 'repair', label: 'Repair' },
    { value: 'improvement', label: 'Improvement' },
    { value: 'general', label: 'General' },
    { value: 'bar_area', label: 'Bar Area' },
    { value: 'stage', label: 'Stage' },
    { value: 'organisation', label: 'Organisation' },
    { value: 'cooler_general', label: 'Cooler General' },
    { value: 'notice_board', label: 'Notice Board' },
    { value: 'outside_press', label: 'Outside Press' },
    { value: 'presses_storage', label: 'Presses/Storage' },
    { value: 'cooler_space', label: 'Cooler Space' },
    { value: 'audience_experience', label: 'Audience Experience' },
    { value: 'throughput', label: 'Throughput' },
    { value: 'lighting', label: 'Lighting' },
    { value: 'sound', label: 'Sound' },
    { value: 'projectors', label: 'Projectors' },
    { value: 'heating', label: 'Heating' },
    { value: 'rehearsals', label: 'Rehearsals' }
  ] as const;

const TaskForm: React.FC<TaskFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  isEditing = false,
  isSubmitting = false,
}) => {
  const [values, setValues] = useState<TaskFormValues>(() => {
    if (!initialValues) return defaultValues;
    
    return {
      ...defaultValues,
      ...initialValues,
      dueDate: initialValues.dueDate || defaultValues.dueDate,
      subtasks: initialValues.subtasks?.map(subtask => ({
        ...subtask,
        dueDate: subtask.dueDate ? new Date(subtask.dueDate).toISOString() : undefined
      })) || []
    };
  });
  const [errors, setErrors] = useState<Partial<Record<keyof TaskFormValues, string>>>({});
  const [users, setUsers] = useState<Array<{ id: string; name: string }>>([]);
  const [personnel, setPersonnel] = useState<Array<{ id: string; name: string }>>([]);

  useEffect(() => {
    const fetchPersonnel = async () => {
      try {
        const response = await axiosInstance.get('/api/personnel');
        // Transform the personnel data to match the expected format
        const formattedPersonnel = response.data.map((person: any) => ({
          id: person._id,
          name: person.name,
          email: person.email
        }));
        setPersonnel(formattedPersonnel);
      } catch (err) {
        console.error('Failed to fetch personnel:', err);
      }
    };

    fetchPersonnel();
  }, []);

  // Add this effect to fetch users when component mounts
useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/api/personnel');
        setUsers(response.data.map((user: any) => ({
          id: user._id,
          name: user.name
        })));
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };
    
    fetchUsers();
  }, []);


  const validateForm = () => {
    const newErrors: Partial<Record<keyof TaskFormValues, string>> = {};

    if (!values.title.trim()) {
      newErrors.title = 'Title is required';
    }

    if (!values.assignedTo) {
      newErrors.assignedTo = 'Please assign this task to someone';
    }

    if (values.estimatedHours && values.estimatedHours <= 0) {
      newErrors.estimatedHours = 'Estimated hours must be positive';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleTextChange = (field: keyof TaskFormValues) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const handleSelectChange = (field: keyof TaskFormValues) => (
    event: SelectChangeEvent<string>
  ) => {
    setValues((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    if (errors[field]) {
      setErrors((prev) => ({ ...prev, [field]: undefined }));
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValues((prev) => ({
        ...prev,
        dueDate: date.toISOString()
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    console.log('Form submission triggered');
    event.preventDefault();
    if (validateForm()) {
      console.log('Form validation passed');
      const formattedValues = {
        ...values,
        subtasks: values.subtasks.map(subtask => ({
          ...subtask,
          assignedTo: subtask.assignedTo || undefined, // Match the type which allows undefined
          title: subtask.title.trim(),
          completed: subtask.completed || false,
          dueDate: subtask.dueDate || new Date().toISOString()
        }))
      };
      console.log('Submitting values:', formattedValues);
      onSubmit(formattedValues);
    } else {
      console.log('Form validation failed:', errors);
    }
  };

  const handleAddSubtask = () => {
    const newSubtask: Subtask = {
      id: `new-${values.subtasks.length}`,
      title: '',
      completed: false,
      assignedTo: '', // Initialize with empty string
      dueDate: new Date().toISOString()
    };
    setValues((prev) => ({
      ...prev,
      subtasks: [...prev.subtasks, newSubtask]
    }));
  };

  const handleRemoveSubtask = (index: number) => {
    setValues((prev) => ({
      ...prev,
      subtasks: prev.subtasks.filter((_, i) => i !== index),
    }));
  };

  const handleUpdateSubtask = (index: number, subtaskData: Partial<Subtask>) => {
    console.log('Updating subtask:', { index, subtaskData }); // Debug log
    setValues((prev) => {
      const updatedSubtasks = prev.subtasks.map((subtask, i) => {
        if (i === index) {
          const updated = { ...subtask, ...subtaskData };
          console.log('Updated subtask:', updated); // Debug log
          return updated;
        }
        return subtask;
      });
      console.log('All subtasks after update:', updatedSubtasks); // Debug log
      return {
        ...prev,
        subtasks: updatedSubtasks,
      };
    });
  };

  const handleToolsChange = (tools: TaskTool[]) => {
    setValues(prev => ({
      ...prev,
      tools: tools.map(tool => ({
        inventoryItem: tool.inventoryItem,  // Transform here
        quantity: tool.quantity,
        notes: tool.notes
      }))
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={3}>
        {/* Title */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Title"
            value={values.title}
            onChange={handleTextChange('title')}
            error={!!errors.title}
            helperText={errors.title}
            required
          />
        </Grid>

        {/* Description */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={values.description}
            onChange={handleTextChange('description')}
          />
        </Grid>

        <Grid item xs={12}>
  <Autocomplete
    options={users}
    getOptionLabel={(option) => option.name}
    value={users.find(user => user.id === values.assignedTo) || null}
    onChange={(_, newValue) => {
      setValues(prev => ({
        ...prev,
        assignedTo: newValue?.id || ''
      }));
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Assigned To"
        required
        error={!!errors.assignedTo}
        helperText={errors.assignedTo}
        InputProps={{
          ...params.InputProps,
          startAdornment: <User size={20} className="mr-2" />
        }}
      />
    )}
  />
</Grid>

        {/* Status and Priority */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={values.status}
              label="Status"
              onChange={handleSelectChange('status')}
            >
              {Object.entries(TASK_STATUSES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Priority</InputLabel>
            <Select
              value={values.priority}
              label="Priority"
              onChange={handleSelectChange('priority')}
            >
              {Object.entries(TASK_PRIORITIES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Chip
                    size="small"
                    label={label}
                    color={value === 'urgent' ? 'error' : value === 'high' ? 'warning' : value === 'low' ? 'success' : 'default'}
                    sx={{ mr: 1 }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Category and Due Date */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={values.category}
              label="Category"
              onChange={handleSelectChange('category')}
            >
              {CATEGORIES.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Due Date"
              value={new Date(values.dueDate)}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!errors.dueDate,
                  helperText: errors.dueDate,
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        {/* Estimated Hours */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            type="number"
            label="Estimated Hours"
            value={values.estimatedHours || ''}
            onChange={handleTextChange('estimatedHours')}
            error={!!errors.estimatedHours}
            helperText={errors.estimatedHours}
            InputProps={{
              startAdornment: <Clock size={20} className="mr-2" />,
            }}
          />
        </Grid>

        {/* Tools Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            <Wrench size={20} className="inline mr-2" />
            Required Tools
          </Typography>
          <TaskToolsList
            value={values.tools}
            onChange={handleToolsChange}
            error={errors.tools}
          />
        </Grid>

        {/* Subtasks Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Subtasks
          </Typography>
          <Stack spacing={2}>
          {values.subtasks.map((subtask, index) => (
  <Box key={subtask.id} sx={{ position: 'relative' }}>
    <SubtaskForm
      subtask={subtask}
      onChange={(data) => handleUpdateSubtask(index, data)}
      users={personnel.map(p => ({
        id: p.id,
        name: p.name
      }))}
    />
    <IconButton
      size="small"
      onClick={() => handleRemoveSubtask(index)}
      sx={{ position: 'absolute', right: -8, top: -8 }}
    >
      <CloseIcon size={16} />
    </IconButton>
  </Box>
))}
            <Button
              startIcon={<Plus size={20} />}
              onClick={handleAddSubtask}
              variant="outlined"
            >
              Add Subtask
            </Button>
          </Stack>
        </Grid>

        {/* Form Actions */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button 
              onClick={onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
  type="submit"
  variant="contained"
  color="primary"
  disabled={isSubmitting}
  onClick={() => console.log('Submit button clicked')} // Add this
>
  {isEditing 
    ? (isSubmitting ? 'Updating...' : 'Update Task') 
    : (isSubmitting ? 'Creating...' : 'Create Task')}
</Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskForm;