import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
  Autocomplete,
  Box,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Artist {
  _id: string;
  artistName: string;
}

interface Venue {
  _id: string;
  name: string;
}

interface Strand {
  _id: string;
  name: string;
  year: number;
  type: string;
}

interface ProspectFormData {
  _id?: string;
  strandId: string;
  date: Date | null;
  eventConcept: string;
  potentialArtists: Array<string | Artist>;
  venueIdeas: string[];
  fees: number;
  boxOffice: number;
  expenses: number;
  sponsorship: number;
  net: number;
  notes: string;
  status: 'idea' | 'negotiation' | 'prospective' | 'confirmed';
}

interface ProspectFormDialogProps {
  open: boolean;
  onClose: () => void;
  currentProspect: ProspectFormData | null;
  setCurrentProspect: (prospect: ProspectFormData | null) => void;
  artists: Artist[];
  venues: Venue[];
  strands: Strand[];
  newArtistName: string;
  setNewArtistName: (name: string) => void;
  onSave: () => void;
}

const ProspectFormDialog: React.FC<ProspectFormDialogProps> = ({
  open,
  onClose,
  currentProspect,
  setCurrentProspect,
  artists,
  venues,
  strands,
  newArtistName,
  setNewArtistName,
  onSave
}) => {
  const handleAddNewArtist = () => {
    if (!currentProspect || !newArtistName.trim()) return;
    
    setCurrentProspect({
      ...currentProspect,
      potentialArtists: [...currentProspect.potentialArtists, newArtistName]
    });
    setNewArtistName('');
  };

  const calculateNet = (prospect: Partial<ProspectFormData>) => {
    return (
      (Number(prospect.boxOffice) || 0) - 
      (Number(prospect.fees) || 0) - 
      (Number(prospect.expenses) || 0) + 
      (Number(prospect.sponsorship) || 0)
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {currentProspect?._id ? 'Edit Prospect' : 'Add Prospect'}
      </DialogTitle>
      
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {/* Strand Selection */}
          <Grid item xs={12}>
            <TextField
              select
              label="Strand"
              fullWidth
              value={currentProspect?.strandId || ''}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                strandId: e.target.value
              })}
              helperText="Select the strand for this prospect"
            >
              {strands.map((strand) => (
                <MenuItem key={strand._id} value={strand._id}>
                  {strand.name} ({strand.type}) {strand.year}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
          <DatePicker
  label="Date"
  value={currentProspect?.date}
  onChange={(newValue) => {
    if (currentProspect) {
      console.log('New date value:', newValue);
      setCurrentProspect({
        ...currentProspect,
        date: newValue ? new Date(newValue) : null
      });
    }
  }}
/>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Event Concept"
              fullWidth
              multiline
              rows={2}
              value={currentProspect?.eventConcept ?? ''}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                eventConcept: e.target.value
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={artists}
              getOptionLabel={(option) => 
                typeof option === 'string' ? option : option.artistName
              }
              value={currentProspect?.potentialArtists ?? []}
              onChange={(_, newValue) => currentProspect && setCurrentProspect({
                ...currentProspect,
                potentialArtists: newValue
              })}
              renderInput={(params) => (
                <TextField {...params} label="Potential Artists" />
              )}
            />
            <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
              <TextField
                label="Add New Artist"
                value={newArtistName}
                onChange={(e) => setNewArtistName(e.target.value)}
                size="small"
              />
              <Button 
                onClick={handleAddNewArtist}
                variant="outlined"
                disabled={!newArtistName.trim()}
              >
                Add
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={venues}
              getOptionLabel={(option) => option.name}
              value={venues.filter(venue => 
                currentProspect?.venueIdeas.includes(venue._id)
              )}
              onChange={(_, newValue) => currentProspect && setCurrentProspect({
                ...currentProspect,
                venueIdeas: newValue.map(v => v._id)
              })}
              renderInput={(params) => (
                <TextField {...params} label="Venue Ideas" />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Fees"
              type="number"
              fullWidth
              value={currentProspect?.fees ?? 0}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                fees: Number(e.target.value)
              })}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Box Office"
              type="number"
              fullWidth
              value={currentProspect?.boxOffice ?? 0}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                boxOffice: Number(e.target.value)
              })}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Expenses"
              type="number"
              fullWidth
              value={currentProspect?.expenses ?? 0}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                expenses: Number(e.target.value)
              })}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Sponsorship"
              type="number"
              fullWidth
              value={currentProspect?.sponsorship ?? 0}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                sponsorship: Number(e.target.value)
              })}
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Net"
              type="number"
              fullWidth
              value={currentProspect ? calculateNet(currentProspect) : 0}
              disabled
              InputProps={{
                startAdornment: <Typography sx={{ mr: 1 }}>€</Typography>,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={currentProspect?.notes ?? ''}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                notes: e.target.value
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              label="Status"
              fullWidth
              value={currentProspect?.status ?? 'idea'}
              onChange={(e) => currentProspect && setCurrentProspect({
                ...currentProspect,
                status: e.target.value as ProspectFormData['status']
              })}
            >
              <MenuItem value="idea">Idea</MenuItem>
              <MenuItem value="prospective">Prospective</MenuItem>
              <MenuItem value="negotiation">In Negotiation</MenuItem>
              <MenuItem value="confirmed">Confirmed</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={onSave} 
          variant="contained" 
          color="primary"
          disabled={!currentProspect?.strandId}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProspectFormDialog;