// src/components/budgets/sections/IncomePlanner.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Paper,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  SelectChangeEvent,
  Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { IncomeDetails, BudgetItem } from '../../../types/Budget';
import { IMC_INCOME_CODES } from '../../../constants/imcCodes';
import { RootState } from '../../../store/store';
import { setVenues, updateIncomeDetails, updateVenueDetails } from '../../../store/budgetSlice';
import { Venue } from '../../../types/mongodb';
import axiosInstance from '../../../utils/axiosConfig';

// Standardized ticket tier types
const TICKET_TIER_TYPES = [
  'Early Bird',
  'Performer Promo',
  'Standard',
  'Walk Up',
  'Discount 1',
  'Discount 2',
  'Guest',
  'Staff',
  'Band'
] as const;

type TicketTierType = typeof TICKET_TIER_TYPES[number];

interface TicketTier {
  name: TicketTierType;
  price: number;
  capacity: number;
  projectedSales: number;
  budgetItem: BudgetItem;
}

interface FundingSource {
  agency: string;
  amount: number;
  status: 'potential' | 'applied' | 'confirmed';
  budgetItem: BudgetItem;
}

// Utility functions
const safeNumber = (value: number | string | undefined): number => {
  if (typeof value === 'undefined') return 0;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : Number(parsed.toFixed(2));
  }
  return isFinite(value) ? Number(value.toFixed(2)) : 0;
};

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const IncomePlanner: React.FC = () => {
  const dispatch = useDispatch();
  const budget = useSelector((state: RootState) => state.budget);
  const artistDetails = useSelector((state: RootState) => state.budget.artist);
  const productionDetails = useSelector((state: RootState) => state.budget.production);
  const venues = useSelector((state: RootState) => state.budget.venues);
  const [error, setError] = useState<string | null>(null);
  const incomeDetails = useSelector((state: RootState) => 
    state.budget.income || { ticketing: { tiers: [], totalProjected: 0 }, funding: { sources: [] } }
  );

  // Calculate total non-audience capacity
  const nonAudienceCapacity = useMemo(() => {
    const staffCount = productionDetails?.personnel?.frontOfHouse?.length || 0;
    const bandCount = artistDetails?.numberOfPeople || 0;
    const crewCount = (
      (productionDetails?.personnel?.stageHands?.length || 0) +
      (productionDetails?.personnel?.stageManagers?.length || 0) +
      (productionDetails?.personnel?.soundEngineer ? 1 : 0) +
      (productionDetails?.personnel?.lightingTech ? 1 : 0)
    );
    return staffCount + bandCount + crewCount;
  }, [artistDetails, productionDetails]);

  const createBudgetItem = (code: keyof typeof IMC_INCOME_CODES, amount: number): BudgetItem => ({
    code: IMC_INCOME_CODES[code].code,
    label: IMC_INCOME_CODES[code].label,
    amount,
    isEstimate: true,
    status: 'draft',
    category: 'income',
  });


  const FUNDING_SOURCES = Object.entries(IMC_INCOME_CODES)
  .filter(([key]) => key.includes('GRANTS_') || key.includes('SPONSORSHIP_') || key.includes('FUNDRAIS'))
  .map(([code, details]) => ({
    id: code.toLowerCase(),
    name: details.label,
    code: code as keyof typeof IMC_INCOME_CODES,
    numericCode: details.code
  }));

  const addFundingSource = useCallback(() => {
    setLocalIncomeDetails(prev => ({
      ...prev,
      funding: {
        ...prev.funding,
        sources: [
          ...prev.funding.sources,
          {
            agency: '',
            amount: 0,
            status: 'potential' as const,
            budgetItem: {
              code: IMC_INCOME_CODES.GRANTS_ARTS_COUNCIL.code,
              label: IMC_INCOME_CODES.GRANTS_ARTS_COUNCIL.label,
              amount: 0,
              isEstimate: true,
              status: 'draft',
              category: 'income'
            }
          }
        ]
      }
    }));
  }, []);

  const updateFundingSource = useCallback((
    index: number,
    field: keyof FundingSource,
    value: any
  ) => {
    setLocalIncomeDetails(prev => ({
      ...prev,
      funding: {
        ...prev.funding,
        sources: prev.funding.sources.map((source, i) => 
          i === index
            ? { ...source, [field]: value }
            : source
        )
      }
    }));
  }, []);

  const removeFundingSource = useCallback((index: number) => {
    setLocalIncomeDetails(prev => ({
      ...prev,
      funding: {
        ...prev.funding,
        sources: prev.funding.sources.filter((_, i) => i !== index)
      }
    }));
  }, []);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await axiosInstance.get<Venue[]>('/api/venues');
        setVenues(response.data);
      } catch (err) {
        console.error('Error fetching venues:', err);
        setError('Failed to fetch venue data');
      }
    };
    fetchVenues();
  }, []);

  // Calculate venue capacity
// Modify the venueCapacity calculation
const venueCapacity = useMemo(() => {
    if (!budget?.venue) return 0;
    
    if (budget.venue.manualCapacity) {
      return budget.venue.manualCapacity;
    }
  
    if (budget.venue.venueId) {
      const selectedVenue = venues.find(v => v._id === budget.venue?.venueId);
      return selectedVenue?.capacity || 0;
    }
  
    return 0;
  }, [budget?.venue, venues]);

  // Calculate available audience capacity
  const availableCapacity = useMemo(() => {
    return Math.max(0, venueCapacity - nonAudienceCapacity);
  }, [venueCapacity, nonAudienceCapacity]);

  const [localIncomeDetails, setLocalIncomeDetails] = useState<IncomeDetails>(incomeDetails);

  // Handle manual venue capacity update
  const handleManualCapacityChange = (value: string) => {
    if (!budget.venue) return;
    
    dispatch(updateVenueDetails({
      ...budget.venue,
      manualCapacity: parseInt(value) || 0
    }));
  };

  // Calculate break-even price
  const totalCosts = useMemo(() => {
    return Object.values(budget?.expenseGroups || {})
      .reduce((sum, group) => sum + safeNumber(group.total), 0);
  }, [budget?.expenseGroups]);

  const breakEvenPrice = availableCapacity ? totalCosts / (availableCapacity * 0.7) : 0;

  const calculateTotalIncome = useCallback((): number => {
    const ticketingTotal = safeNumber(localIncomeDetails.ticketing.totalProjected);
    const fundingTotal = safeNumber(
      localIncomeDetails.funding.sources.reduce((sum, source) => 
        sum + (source.status === 'confirmed' ? safeNumber(source.amount) : 0), 0
      )
    );
    return safeNumber(ticketingTotal + fundingTotal);
  }, [localIncomeDetails]);

  const calculateTotalProjected = useCallback((tiers: TicketTier[]): number => {
    return safeNumber(tiers.reduce((sum, tier) => 
      sum + (safeNumber(tier.price) * safeNumber(tier.projectedSales)), 0
    ));
  }, []);

  // Ticket tier management
  const addTicketTier = useCallback(() => {
    setLocalIncomeDetails(prev => {
      const newTier: TicketTier = {
        name: 'Standard', // Must be a valid value from TICKET_TIER_TYPES
        price: 0,
        capacity: 0,
        projectedSales: 0,
        budgetItem: createBudgetItem('IMC_TICKET_SALES', 0),
      };
  
      const updatedTiers = [...(prev.ticketing.tiers || []), newTier] as TicketTier[];
      return {
        ...prev,
        ticketing: {
          ...prev.ticketing,
          tiers: updatedTiers,
          totalProjected: calculateTotalProjected(updatedTiers),
        },
      };
    });
  }, [createBudgetItem, calculateTotalProjected]);



  const removeTicketTier = useCallback((index: number) => {
    setLocalIncomeDetails(prev => {
      const updatedTiers = prev.ticketing.tiers.filter((_, i) => i !== index);
      return {
        ...prev,
        ticketing: {
          ...prev.ticketing,
          tiers: updatedTiers,
          totalProjected: calculateTotalProjected(updatedTiers as TicketTier[])
        }
      };
    });
  }, [calculateTotalProjected]);


  const updateTicketTier = useCallback((
    index: number, 
    field: keyof TicketTier, 
    value: string | number
) => {
    setLocalIncomeDetails(prev => {
      const updatedTiers = [...prev.ticketing.tiers];
      
      // Handle numeric fields
      const processedValue = ['price', 'projectedSales'].includes(field)
        ? safeNumber(value)
        : value;

      // When updating projectedSales, automatically set capacity
      if (field === 'projectedSales') {
        updatedTiers[index] = {
          ...updatedTiers[index],
          projectedSales: processedValue as number,
          capacity: processedValue as number // Capacity equals projected sales
        };
      } else {
        updatedTiers[index] = {
          ...updatedTiers[index],
          [field]: processedValue
        };
      }

      // Update budget item if price or projected sales change
      if (field === 'price' || field === 'projectedSales') {
        const tier = updatedTiers[index];
        const amount = safeNumber(tier.price) * safeNumber(tier.projectedSales);
        updatedTiers[index].budgetItem = createBudgetItem('IMC_TICKET_SALES', amount);
      }

      const totalProjected = calculateTotalProjected(updatedTiers as TicketTier[]);

      return {
        ...prev,
        ticketing: {
          ...prev.ticketing,
          tiers: updatedTiers,
          totalProjected
        }
      };
    });
}, [calculateTotalProjected, createBudgetItem]);


const debouncedDispatch = useCallback(
    debounce((updateData: IncomeDetails) => {
      dispatch(updateIncomeDetails(updateData));
    }, 300),
    []
  );

  // Effect to sync with Redux (debounced)
  useEffect(() => {
    const localString = JSON.stringify({
      tiers: localIncomeDetails.ticketing.tiers,
      sources: localIncomeDetails.funding.sources,
      totalProjected: localIncomeDetails.ticketing.totalProjected // Add this
    });
    
    const reduxString = JSON.stringify({
      tiers: incomeDetails.ticketing.tiers,
      sources: incomeDetails.funding.sources,
      totalProjected: incomeDetails.ticketing.totalProjected // Add this
    });
    
    if (localString !== reduxString) {
      debouncedDispatch(localIncomeDetails);
    }
}, [localIncomeDetails, incomeDetails, debouncedDispatch]);


  // Check if total capacity is exceeded
  const totalAllocatedCapacity = useMemo(() => {
    return localIncomeDetails.ticketing.tiers.reduce(
      (sum, tier) => sum + safeNumber(tier.capacity), 
      0
    );
  }, [localIncomeDetails.ticketing.tiers]);

  const isCapacityExceeded = totalAllocatedCapacity > availableCapacity;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Income Planning</Typography>

      {/* Capacity Analysis */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>Venue Capacity Analysis</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {budget?.venue?.venueId ? (
              <Typography>Venue Capacity: {venueCapacity}</Typography>
            ) : (
              <TextField
                fullWidth
                label="Manual Venue Capacity"
                type="number"
                value={budget?.venue?.manualCapacity || ''}
                onChange={(e) => handleManualCapacityChange(e.target.value)}
                inputProps={{ min: 0 }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>
              Non-Audience Members: {nonAudienceCapacity}
              <Tooltip title="Staff, band members, and crew">
                <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>Available for Audience: {availableCapacity}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>Break-even Price: {formatCurrency(breakEvenPrice)}</Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Warning if capacity is exceeded */}
      {isCapacityExceeded && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Total allocated capacity ({totalAllocatedCapacity}) exceeds available capacity ({availableCapacity})
        </Alert>
      )}

      {/* Ticket Tiers */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="subtitle1">Ticket Tiers</Typography>
          <Button 
            startIcon={<AddIcon />} 
            onClick={addTicketTier} 
            variant="contained" 
            size="small"
            disabled={isCapacityExceeded}
          >
            Add Tier
          </Button>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Capacity</TableCell>
                <TableCell align="right">Projected Sales</TableCell>
                <TableCell align="right">Total Revenue</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localIncomeDetails.ticketing.tiers.map((tier, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={tier.name}
                        onChange={(e) => updateTicketTier(index, 'name', e.target.value)}
                      >
                        {TICKET_TIER_TYPES.map((type) => (
                          <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={tier.price || ''}
                      onChange={(e) => updateTicketTier(index, 'price', e.target.value)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      }}
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={tier.capacity || ''}
                      onChange={(e) => updateTicketTier(index, 'capacity', e.target.value)}
                      inputProps={{ min: 0 }}
                      error={safeNumber(tier.capacity) > availableCapacity}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={tier.projectedSales || ''}
                      onChange={(e) => updateTicketTier(index, 'projectedSales', e.target.value)}
                      inputProps={{ 
                        min: 0,
                        step: 1, // Add this
                        max: tier.capacity 
                      }}
                      error={safeNumber(tier.projectedSales) > safeNumber(tier.capacity)}
                    />
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(safeNumber(tier.price) * safeNumber(tier.projectedSales))}
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      onClick={() => removeTicketTier(index)} color="error"> 

                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Capacity utilization */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2">
            Capacity Utilization: {Math.round((totalAllocatedCapacity / availableCapacity) * 100)}%
            ({totalAllocatedCapacity} / {availableCapacity})
          </Typography>
        </Box>
        </Paper>

        {/* Funding Sources */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="subtitle1">Funding Sources</Typography>
          <Button 
            startIcon={<AddIcon />} 
            onClick={addFundingSource} 
            variant="contained" 
            size="small"
          >
            Add Funding Source
          </Button>
        </Box>

        {localIncomeDetails.funding.sources.map((source, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Funding Type</InputLabel>
                  <Select
                    value={source.agency}
                    onChange={(e) => updateFundingSource(index, 'agency', e.target.value)}
                    label="Funding Type"
                  >
                    {FUNDING_SOURCES.map(agency => (
                      <MenuItem key={agency.id} value={agency.name}>
                        {agency.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Amount"
                  type="number"
                  value={source.amount}
                  onChange={(e) => updateFundingSource(index, 'amount', safeNumber(e.target.value))}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>
                  }}
                  inputProps={{ min: 0, step: "0.01" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={source.status}
                    onChange={(e) => updateFundingSource(index, 'status', e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="potential">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Potential
                        <Tooltip title="Identified but not yet applied">
                          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
                        </Tooltip>
                      </Box>
                    </MenuItem>
                    <MenuItem value="applied">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Applied
                        <Tooltip title="Application submitted">
                          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
                        </Tooltip>
                      </Box>
                    </MenuItem>
                    <MenuItem value="confirmed">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Confirmed
                        <Tooltip title="Funding approved">
                          <InfoIcon sx={{ ml: 1, fontSize: 16 }} />
                        </Tooltip>
                      </Box>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <IconButton onClick={() => removeFundingSource(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Paper>

      {/* Income Summary */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Income Summary</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1">Ticket Sales</Typography>
            <Typography variant="h6">
              {formatCurrency(localIncomeDetails.ticketing.totalProjected)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Average ticket price: {formatCurrency(
                localIncomeDetails.ticketing.totalProjected / 
                localIncomeDetails.ticketing.tiers.reduce((sum, tier) => 
                  sum + safeNumber(tier.projectedSales), 0) || 0
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1">Funding</Typography>
            <Typography variant="h6">
              {formatCurrency(
                localIncomeDetails.funding.sources.reduce((sum, source) => 
                  sum + (source.status === 'confirmed' ? safeNumber(source.amount) : 0), 0
                )
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Potential additional: {formatCurrency(
                localIncomeDetails.funding.sources.reduce((sum, source) => 
                  sum + (source.status !== 'confirmed' ? safeNumber(source.amount) : 0), 0
                )
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1">Total Projected Income</Typography>
            <Typography variant="h6">
              {formatCurrency(calculateTotalIncome())}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Break-even coverage: {
                Math.round((calculateTotalIncome() / totalCosts) * 100)
              }%
            </Typography>
          </Grid>

          {calculateTotalIncome() < totalCosts && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Current projected income is {formatCurrency(Math.abs(totalCosts - calculateTotalIncome()))} below total costs.
                Consider adjusting ticket prices, increasing projected sales, or securing additional funding.
              </Alert>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
    
  );
};

export default IncomePlanner;