// src/components/cooler/inventory/components/ItemGrid.tsx

import React from 'react';
import { Grid } from '@mui/material';
import type { InventoryItem } from '../types/inventoryTypes';
import { ItemCard } from './ItemCard';

interface ItemGridProps {
  items: InventoryItem[];
  onItemClick: (item: InventoryItem) => void;
  onMaintenanceClick: (item: InventoryItem) => void;
}

export const ItemGrid: React.FC<ItemGridProps> = ({
  items,
  onItemClick,
  onMaintenanceClick,
}) => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <ItemCard
            item={item}
            onClick={() => onItemClick(item)}
            onMaintenanceClick={(e) => {
              e.stopPropagation();
              onMaintenanceClick(item);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ItemGrid;