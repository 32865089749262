import React, { useState, useEffect } from 'react';
import {
  Box,
  LinearProgress,
  Alert,
  Button,
  Stack
} from '@mui/material';
import {
  Save as SaveIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import ProductionLinesManager from '../components/budgets/groups/ProductionLinesManager';
import axiosInstance from '../utils/axiosConfig';
import { IMC_EXPENSE_CODES } from '../constants/imcCodes';

interface ExpenseLine {
  id: string;
  code: string;
  quantity: number;
  value: number;
  multiplier: number;
  notes: string;
  budgetId: string;
}

interface Budget {
  _id: string;
  name: string;
  expenseLines: ExpenseLine[];
}

const ProductionBudgetPage = () => {
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [changedBudgets, setChangedBudgets] = useState<{ [key: string]: ExpenseLine[] }>({});

  const fetchBudgets = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.get<Budget[]>('/api/group-budgets');
      setBudgets(response.data);
      setChangedBudgets({});
      setHasUnsavedChanges(false);
    } catch (err) {
      console.error('Error fetching budgets:', err);
      setError('Failed to load budgets. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  const handleUpdate = (budgetId: string, updatedLines: ExpenseLine[]) => {
    setChangedBudgets(prev => ({
      ...prev,
      [budgetId]: updatedLines
    }));
    setHasUnsavedChanges(true);

    setBudgets(prevBudgets => prevBudgets.map(budget => 
      budget._id === budgetId ? { ...budget, expenseLines: updatedLines } : budget
    ));
  };

  const handleSaveAll = async () => {
    try {
      setLoading(true);
      setError(null);

      const savePromises = Object.entries(changedBudgets).map(async ([budgetId, updatedLines]) => {
        await axiosInstance.patch(`/api/group-budgets/${budgetId}`, { expenseLines: updatedLines });
      });

      await Promise.all(savePromises);

      setChangedBudgets({});
      setHasUnsavedChanges(false);
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 3000);
    } catch (err) {
      console.error('Error saving budgets:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to refresh?')) {
        fetchBudgets();
      }
    } else {
      fetchBudgets();
    }
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Stack 
        direction="row" 
        spacing={2} 
        alignItems="center" 
        justifyContent="flex-end"
        sx={{ mb: 3 }}
      >
        <Button
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={handleRefresh}
          disabled={loading}
        >
          Refresh
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSaveAll}
          disabled={!hasUnsavedChanges || loading}
        >
          Save All Changes
        </Button>
      </Stack>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {saveSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          All changes saved successfully
        </Alert>
      )}

      {hasUnsavedChanges && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You have unsaved changes
        </Alert>
      )}

      <ProductionLinesManager 
        budgets={budgets}
        onUpdate={handleUpdate}
      />
    </Box>
  );
};

export default ProductionBudgetPage;