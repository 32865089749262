import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import QuickTasks from '../components/QuickTasks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Create a client
const queryClient = new QueryClient();

const TasksPage: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container maxWidth="lg">
        <Box sx={{ py: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Tasks
          </Typography>
          <QuickTasks />
        </Box>
      </Container>
    </QueryClientProvider>
  );
};

export default TasksPage;