// src/components/layout/AdminLayout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import AdminTopNav from '../navigation/AdminTopNav';
import Footer from '../Footer';
import QuickIdeaButton from '../ideas/QuickIdeaButton';


const AdminLayout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AdminTopNav />
      <Container component="main" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
        <Outlet />
        <QuickIdeaButton />
      </Container>
      <Footer />
    </Box>
  );
};

export default AdminLayout;