import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { User, Calendar } from 'lucide-react';
import axiosInstance from '../../../../utils/axiosConfig';
import { Subtask } from '../types/taskTypes';
import { Personnel } from '../../../../types/Personnel';

interface SubtaskListProps {
  subtasks: Subtask[];
  onToggle: (subtaskId: string) => void;
  isUpdating: boolean;
}

interface PersonnelResponse {
  _id: string;
  name: string;
  email: string;
}

const SubtaskList: React.FC<SubtaskListProps> = ({ subtasks, onToggle, isUpdating }) => {
  const [personnelMap, setPersonnelMap] = useState<Record<string, string>>({});

  useEffect(() => {
    // Debug the incoming subtasks
    console.log('Raw subtasks received:', JSON.stringify(subtasks, null, 2));

    const assignedIds = subtasks
      .map(subtask => {
        // Debug each subtask's assignedTo
        console.log('Processing subtask assignedTo:', subtask.assignedTo);
        return subtask.assignedTo;
      })
      .filter((id): id is string => Boolean(id));

    // Debug the extracted IDs
    console.log('Extracted assignedTo IDs:', assignedIds);

    if (assignedIds.length === 0) {
      console.log('No assignedTo IDs found in subtasks');
      return;
    }

    const fetchPersonnel = async () => {
      try {
        console.log('Fetching personnel for IDs:', assignedIds);
        const response = await axiosInstance.post('/api/personnel/bulk', { ids: assignedIds });
        console.log('Personnel API Response:', response.data);

        const map: Record<string, string> = {};
        response.data.forEach((person: PersonnelResponse) => {
          console.log('Processing person:', person);
          map[person._id] = person.name;
        });

        console.log('Final personnel map:', map);
        setPersonnelMap(map);
      } catch (error) {
        console.error('Error fetching personnel:', error);
      }
    };

    fetchPersonnel();
  }, [subtasks]);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
        <colgroup>
          <col style={{ width: '55%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr style={{ height: '40px', borderBottom: '1px solid #ccc' }}>
            <th style={{ textAlign: 'left', padding: '10px' }}>Task</th>
            <th style={{ textAlign: 'left', padding: '10px' }}>Assigned To</th>
            <th style={{ textAlign: 'left', padding: '10px' }}>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {subtasks.map((subtask) => {
            // Debug each subtask as it's being rendered
            console.log('Rendering subtask:', subtask);
            const assignedToId = subtask.assignedTo;
            const assigneeName = assignedToId ? personnelMap[assignedToId] || 'Loading...' : 'Unassigned';
            console.log('Assigned to ID:', assignedToId, 'Name:', assigneeName);

            return (
              <tr key={subtask.id} style={{ height: '40px', borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Checkbox
                      checked={subtask.completed}
                      onChange={() => onToggle(subtask.id)}
                      disabled={isUpdating}
                      size="small"
                    />
                    <span style={{ 
                      textDecoration: subtask.completed ? 'line-through' : 'none', 
                      color: subtask.completed ? '#888' : 'inherit' 
                    }}>
                      {subtask.title}
                    </span>
                  </div>
                </td>
                <td style={{ padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <User size={16} color="#888" />
                    <span>{assigneeName}</span>
                  </div>
                </td>
                <td style={{ padding: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <Calendar size={16} color="#888" />
                    <span>
                      {subtask.dueDate ? new Date(subtask.dueDate).toLocaleDateString() : 'No Date'}
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SubtaskList;