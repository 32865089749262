import type { Performance } from '../types/mongodb';

export const getStatusColor = (status: string): 'success' | 'warning' | 'error' | 'default' => {
  switch (status) {
    case 'confirmed':
      return 'success';
    case 'pending':
      return 'warning';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

export const getArchiveIcon = (isArchived: boolean) => {
  return isArchived ? 'Undo' : 'Archive';
};